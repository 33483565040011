import React from 'react'
import { Alert, Row, Tabs, Tab, Card, Button } from 'react-bootstrap'
import TechDataTrade from './TechDataTrade'
import CommercialTrade from './CommercialTrade'
import { GeneralDataTrade } from './GeneralDataTrade'
import Server from '../server/server'
import { nanoid } from 'nanoid'
import InputTrade from './InputTrade'
import FileNoModal from '../file/FileNoModal'
import AddListForFile from './AddListForFIle'
import QuitModal from '../QuitModal'
import AllModalsFooter from '../assets/AllModalsFooter'
import { AddButton, SaveButtonGreen } from '../assets/BasicButtons'
import { isEqual, result } from 'lodash-es'
import checkTradeName from './checkTradeName'

export default class TradePosition extends React.Component {
  constructor(props) {
    super(props)

    this.arrPromisesAll = [] // собирает промисы для Promise.all

    this.state = {
      language: 'rus',
      promiseAll: [],
      errorNameTrade: false, // повтор названия торг позиции
      dataTradePosition: [], // массив торг позиций, если есть
      showTech: true,
      showFile: false,
      showCommercial: true,
      showDate: false,
      showParticipant: false,
      showFeatures: false,
      showFileList: false,
      showCreateTrade: false, // вкл кнопки - создать торги
      renderTableCompany: [], // рендер таблицы компаний
      trade: {
        tech: [],
        commercial: [],
        nameTrade: '', // название торговой позиции
        keyNameTrade: '',
        organizerId: '',
        priority: [],
        idFile: '', // ID объекта файлов - leo
        fileList: [], // ID объекта списка файлов необходимых для загрузки
        archive: false, // нахождение в архиве (true), доступен(false)
        createDate: '' // дата создания
      },
      dataFromTech: [],
      dataFromCommerce: [],
      activeTab: 'link-0',
      filesNamesArr: [],
      inReadMode: false,
      showQuit: false,
      previousState: []
    }

    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doSaveDataCommercial = this.doSaveDataCommercial.bind(this)
    this.doSaveDataTrade = this.doSaveDataTrade.bind(this)
    this.verificationShow = this.verificationShow.bind(this)
    this.createTradeObject = this.createTradeObject.bind(this)
    this.handleClickCreateTrade = this.handleClickCreateTrade.bind(this)
    this.doLoadTemplate = this.doLoadTemplate.bind(this)
    this.doSavePriority = this.doSavePriority.bind(this)
    this.doChangeValue = this.doChangeValue.bind(this)
    this.doSendDataFromTech = this.doSendDataFromTech.bind(this)
    this.doSendDataFromCommerce = this.doSendDataFromCommerce.bind(this)
    this.toNextTab = this.toNextTab.bind(this)
    this.returnId = this.returnId.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.getFilesNames = this.getFilesNames.bind(this)
    this.onSelectTab = this.onSelectTab.bind(this)

    this.showQuitModal = this.showQuitModal.bind(this)
    this.handleClickAddPosition = this.handleClickAddPosition.bind(this)
    this.addNewList = this.addNewList.bind(this)
    this.deletePosition = this.deletePosition.bind(this)
    this.quitWithSave = this.quitWithSave.bind(this)
    this.quitWithoutSave = this.quitWithoutSave.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
  }

  quitWithSave() {
    this.showQuitModal()
    this.handleClickCreateTrade()
  }

  quitWithoutSave() {
    this.handleClickShadow()
    this.props.openPositionModal()
  }

  // удаление позиции в списке нужных файлов
  deletePosition(id) {
    const dataD = this.state.trade
    const result = dataD.fileList.map((item) => {
      if (item.id == id) {
        item.archive = true
      }
      return item
    })
    dataD.fileList = result
    this.setState({ trade: dataD })
    // console.log(`data List File::: `, dataD); // test
  }

  addNewList(list) {
    const dataD = this.state.trade
    dataD.fileList = list
    // console.log('dataD.fileList:: ', dataD.fileList); // test
    this.setState({ trade: dataD })
  }

  // добавление позиции к списку необходимых файлов
  handleClickAddPosition() {
    this.setState({ showFileList: false })
    try {
      // console.log(`ADD`); // test
      let lengthPos = 0

      if (!this.state.trade.fileList) {
        const dataD = this.state.trade
        dataD.fileList = []
        this.setState({ fileList: dataD })
      }

      if (this.state.trade.fileList) {
        lengthPos = this.state.trade.fileList.length
      }

      const obj = { id: String(lengthPos), description: '', archive: false }
      const tradeD = this.state.trade
      // console.log(obj);
      // console.log(`tradeD:::: `, tradeD); // test
      tradeD.fileList.push(obj)
      this.setState({ trade: tradeD })

      this.setState({ showFileList: true })
    } catch (err) {
      console.log(`Ошибка при добавлении позиции к списку файлов: `, err)
      this.setState({ showFileList: true })
    }
  }

  async getFilesNames(files) {
    await this.setState({
      filesNamesArr: files
    })
  }

  doEmpty() {}

  returnId(id) {
    // console.log(`Вернувшийся ID:::: `, id);
  }

  toNextTab(e) {
    e.preventDefault()

    const keysArray = ['link-0', 'link-1', 'link-2', 'link-3', 'link-4']

    keysArray.forEach((el, idx) => {
      if (this.state.activeTab === el) {
        this.setState({
          activeTab: `link-${idx + 1}`
        })
      }
    })
  }

  doSendDataFromTech(array) {
    this.setState({
      dataFromTech: array
    })
  }

  doSendDataFromCommerce(array) {
    this.setState({
      dataFromCommerce: array
    })
  }

  /**
   * вносим название торговой позиции
   * @param {Object} data
   */
  doChangeValue(data) {
    ;(async () => {
      try {
        if (!this.state.dataTradePosition || this.state.dataTradePosition.length < 1) {
          let dataTP = await Server.searchAllPositions()

          if (dataTP.length < 1) {
            dataTP = [{ nameTrade: 'no name' }]
          }

          this.setState({ dataTradePosition: dataTP })
        }

        const resultCheck = await checkTradeName(data.value, this.state.dataTradePosition)

        if (resultCheck) {
          this.setState({ errorNameTrade: true, showTech: false })
        } else {
          let tradeD = this.state.trade
          tradeD.nameTrade = data.value
          this.setState({ trade: tradeD, errorNameTrade: false })

          if (this.state.trade.nameTrade && this.state.trade.nameTrade.length !== 0) {
            this.setState({
              showTech: true
            })
          }
        }
      } catch (err) {
        console.log(`Ошибка при записи(провекре) названий торговой позиции:`)
      }
    })()
  }

  // получаем и  записываем приоритеты
  doSavePriority(data) {
    let dataState = this.state.trade
    dataState.priority = data

    this.setState({ trade: dataState })
  }

  // выбранный шаблон тех параметров заносим в state
  async doLoadTemplate(data) {
    await this.setState({ showTech: false })
    // console.log(`DATA choice template `, data); // test
    const DATA = this.state.trade
    DATA.tech = data
    await this.setState({ trade: DATA })
    await this.setState({ showTech: true })
  }

  //* создание торгов
  async handleClickCreateTrade() {
    let dateTrade = this.state.trade
    dateTrade.keyNameTrade = nanoid()
    dateTrade.createDate = new Date() // дата создания торговой позиции
    dateTrade.archive = false // нахождение в архиве (false - не в архиве / true - в архиве)
    dateTrade.organizerId = localStorage.getItem('idUser')
    await this.setState({ trade: dateTrade })
    // setTimeout(() => {
    // this.props.onCreateTrade(this.state.trade); //? todo включить
    // console.log(`data for SAVE ::::: `, this.state.trade);
    // новый файл торгов на сервере
    // this.createTradeObject(JSON.stringify(this.state.trade), String(this.state.trade.keyNameTrade), localStorage.getItem('idUser'));//! ID - collection
    await this.createTradeObject(
      this.state.trade,
      String(this.state.trade.keyNameTrade),
      'trade_position'
    ) //todo включить
    setTimeout(() => {
      this.props.readFromServerData(this.state.trade.keyNameTrade)
    })
    setTimeout(() => {
      this.handleClickShadow()
      this.props.openPositionModal()
    }, 1000) //закрывает окно
    // }, 1000);

    this.props.onGetNewPosName && this.props.onGetNewPosName(this.state.trade['nameTrade'])
    this.props.onGetTradeObject && this.props.onGetTradeObject(this.state.trade)
  }

  //* СОЗДАЕТ новый файл торгов на сервере
  async createTradeObject(data, name, id) {
    try {
      const result = await Server.sendDataOnServer(data, name, id) // leo - обращение к другой функции (если вдруг надо будет править)

      if (result.ERROR) throw new Error(result.message) // leo - обработка ошибок
    } catch (err) {
      console.log(`Ошибка при сохранении торговой позиции на сервере: `, err)
    }
  }

  //*проверка на заполнение всех форм для торгов
  verificationShow() {
    let trade = this.state.trade
    if (trade.tech.length > 2 && trade.commercial.length > 3 && trade.nameTrade.length > 0)
      this.setState({ showCreateTrade: true })
  }

  doSaveDataTrade(data) {
    let techData = this.state.trade
    techData.tech = data
    this.setState({ trade: techData })
    // setTimeout(() => { console.log(`TECH TRADE:::: `, this.state.trade); this.verificationShow() })//test
  }

  doSaveDataCommercial(data) {
    let comData = this.state.trade
    comData.commercial = data
    this.setState({ trade: comData })
    // setTimeout(() => { console.log(this.state.trade); this.verificationShow() })//test
  }

  handleClickShadow() {
    this.props.onChangeShowTrade()
  }

  async onSelectTab(k) {
    this.setState({ activeTab: k })
  }

  compare() {
    const prev = this.state.previousState
    const curr = this.state.trade
    const isStateChanged = isEqual(prev, curr)
    if (isStateChanged) {
      return false
    } else {
      return true
    }
  }

  smartWindowFunction() {
    const result = this.compare()
    if (result) {
      this.showQuitModal()
    } else {
      this.quitWithoutSave()
    }
  }

  async componentDidMount() {
    const isDisabled =
      (this.state.trade.nameTrade && this.state.trade.nameTrade.length !== 0) ||
      (this.props.nameTrade && this.props.nameTrade.length !== 0)

    if (!isDisabled) {
      this.setState({
        showTech: false
      })
    }

    if (this.props.serverData && this.props.serverData.length !== 0) {
      const result = {
        tech: this.props.dataTech,
        commercial: this.props.dataCommerce,
        nameTrade: this.props.nameTrade,
        keyNameTrade: this.props.keyNameTrade,
        organizerId: this.props.organizerId,
        priority: this.props.priority,
        idFile: this.props.fileId,
        fileList: this.props.fileList,
        archive: this.props.archive, // нахождение в архиве (true), доступен(false)
        createDate: this.props.createDate // дата создания
      }
      await this.setState({
        trade: result,
        inReadMode: true
      })
    } else {
      // создаем ID  для объекта файлов - leo
      const dataD = this.state.trade
      dataD.idFile = this.props.fileId
      this.setState({ trade: dataD })
      // setTimeout(() => { console.log(`ID ID ID ID >>>> `, this.state.trade.idFile); }) // test
    }
    this.setState({
      showFile: true,
      showFileList: true
    })
    if (this.props.organizerId) {
      const previousState = JSON.parse(JSON.stringify(this.state.trade))
      this.setState({
        previousState
      })
    }
  }

  showQuitModal() {
    this.setState({
      showQuit: !this.state.showQuit
    })
  }

  render() {
    const show = this.props.show
    const isDisabled = this.state.trade.nameTrade && this.state.trade.nameTrade.length !== 0

    return (
      <>
        {this.state.showQuit && isDisabled && (
          <QuitModal
            onQuitWithSave={this.quitWithSave}
            onQuitWithoutSave={this.quitWithoutSave}
            closeQuitModal={this.showQuitModal}
            modalTitle={'Выход'}
            modalBody={'Сохранить изменения?'}
            primaryButton={'Сохранить'}
            secondaryButton={'Выйти без сохранения'}
          />
        )}

        <div>
          <div
            className='shadow_form z_index_20'
            // onClick={isDisabled ? this.showQuitModal : this.smartWindowFunction}
            onClick={isDisabled ? this.smartWindowFunction : this.handleClickShadow}
          ></div>
          <div className='modal_common_1 z_index_20'>
            {this.state.errorNameTrade ? (
              <Alert
                variant='danger'
                onClose={isDisabled ? this.smartWindowFunction : this.handleClickShadow}
                dismissible
              >
                <Alert.Heading>
                Позиция с таким названием уже существует!
                </Alert.Heading>
              </Alert>
            ) : (
              <Alert
                variant='dark'
                onClose={isDisabled ? this.smartWindowFunction : this.handleClickShadow}
                dismissible
              >
                <Alert.Heading>
                  {!this.state.trade.nameTrade
                    ? 'Создание торговых позиций'
                    : 'Редактирование торговых позиций'}
                </Alert.Heading>
              </Alert>
            )}
            <div className='participant_lot_container participant_lot_container_padding'>
              <div>
                <InputTrade
                  className={'p_0'}
                  id={'nameTrade'}
                  show={true}
                  // verify="number"
                  color={'#ccc'}
                  fontSize={'12px'}
                  label='Введите название торговой позиции'
                  placeholder='Введите название торговой позиции'
                  description='Торговая торговой позиция'
                  value={
                    this.state.trade.nameTrade ? this.state.trade.nameTrade : this.props.nameTrade
                  }
                  // value={this.value[1].value}
                  onChangeValue={this.doChangeValue}
                />
              </div>
              <Row>&nbsp;</Row>
              <Tabs
                defaultActiveKey='link-0'
                activeKey={this.state.activeTab}
                onSelect={this.onSelectTab}
              >
                <Tab
                  eventKey='link-0'
                  title='Технические параметры'
                  disabled={isDisabled ? false : true}
                >
                  <Row> &nbsp;</Row>
                  {(this.state.showTech || this.props.showTech) && (
                    <TechDataTrade
                      onSaveDataTrade={this.doSaveDataTrade}
                      value={this.state.trade.tech && this.state.trade.tech}
                      onLoadTemplate={this.doLoadTemplate}
                      onSendDataFromTech={this.doSendDataFromTech}
                      toNextTab={this.toNextTab}
                      disableBtn={false}
                      inReadMode={this.state.inReadMode}
                    />
                  )}
                </Tab>

                <Tab
                  eventKey='link-1'
                  title='Коммерческие параметры'
                  disabled={isDisabled ? false : true}
                >
                  <Row> &nbsp;</Row>
                  {this.state.showCommercial && (
                    <CommercialTrade
                      onSaveDataCommercial={this.doSaveDataCommercial}
                      onSavePriority={this.doSavePriority}
                      value={this.state.trade.commercial && this.state.trade.commercial}
                      onSendDataFromCommerce={this.doSendDataFromCommerce}
                      toNextTab={this.toNextTab}
                      disableBtn={false}
                      inReadMode={this.state.inReadMode}
                    />
                  )}
                </Tab>

                <Tab eventKey='link-2' title='Файлы' disabled={isDisabled ? false : true}>
                  <Row> &nbsp;</Row>
                  <div
                    style={{
                      border: '1px #ccc solid',
                      padding: '0',
                      borderRadius: '5px',
                      marginBottom: '100px'
                    }}
                  >
                    {this.state.showFile && (
                      <FileNoModal
                        onClose={this.doEmpty} // закрытие окна
                        newName={this.state.trade.nameTrade} // название ЛОТа или торговой позиции
                        id={this.state.trade.idFile} // ID масива объектов загруженных файлов
                        // id={this.props.fileId} // ann
                        returnId={this.returnId} // возвращает ID, если его не было при запуске компонента
                        getFilesNames={this.getFilesNames}
                        toNextTab={this.toNextTab}
                        disableBtn={false}
                        showNextBtn={true}
                        document='позиции'
                        showDelIcon={true}
                        showCheckIcon={true}
                        showAddBtn={true}
                        inPositionsModal={true}
                      />
                    )}
                  </div>

                  {/* <Row> &nbsp;</Row> */}
                </Tab>

                <Tab
                  eventKey='link-3'
                  title='Список необходимых файлов'
                  disabled={isDisabled ? false : true}
                >
                  <Row> &nbsp;</Row>
                  <Row> &nbsp;</Row>

                  <Row> &nbsp;</Row>
                  {this.state.showFileList && (
                    <>
                      <Card style={{ border: '1px #ccc solid', marginBottom: '72px' }}>
                        <Card.Header className='trade_card_header' as='h6'>
                          Перечень файлов для получения от участника
                        </Card.Header>

                        {this.state.trade.fileList && this.state.trade.fileList.length ? (
                          <Card.Body>
                            <Row> &nbsp;</Row>
                            <AddListForFile
                              listForFiles={this.state.trade.fileList}
                              addList={this.addNewList}
                              deletePosition={this.deletePosition}
                            />

                            <Row> &nbsp;</Row>
                          </Card.Body>
                        ) : (
                          <Card.Body>
                            <Row> &nbsp;</Row>
                            Перечень файлов отсутствует
                            <Row> &nbsp;</Row>
                          </Card.Body>
                        )}
                      </Card>

                      <Row> &nbsp;</Row>
                      <AllModalsFooter
                        isPosAbsoluteNedded={true}
                        footerContent={
                          <>
                            <AddButton
                              handleSaveClick={this.handleClickAddPosition}
                              needDisable={false}
                              buttonText='новый документ'
                            />

                            <SaveButtonGreen
                              handleSaveClick={this.toNextTab}
                              needDisable={false}
                              // disableCondition,
                              saveButtonText='Далее'
                            />

                            {/* <Button
                              // disabled={this.state.disableDownload}
                              onClick={this.toNextTab}
                              variant='success'
                            >
                              Далее
                            </Button> */}
                          </>
                        }
                      />
                    </>
                  )}
                </Tab>

                <Tab eventKey='link-4' title='Сводные данные' disabled={isDisabled ? false : true}>
                  <Row> &nbsp;</Row>
                  <GeneralDataTrade
                    dataFromTech={this.state.dataFromTech}
                    dataFromCommerce={this.state.dataFromCommerce}
                    name={this.state.trade.nameTrade}
                    handleClickCreateTrade={this.handleClickCreateTrade}
                    onGetNewPosName={this.props.onGetNewPosName}
                    onGetTradeObject={this.props.onGetTradeObject}
                    filesNamesArr={this.state.filesNamesArr}
                    disableBtn={false}
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </>
    )
  }
}
