import React from 'react';
import Server from '../server/server';

export default class ListField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: false,
            listNameForm: ['Main', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Zero'],
            base: [],
            baseData: []
        }
        this.getDataFromServer = this.getDataFromServer.bind(this);
        this.writeParseData = this.writeParseData.bind(this);
        this.readDataFromServer = this.readDataFromServer.bind(this);
        this.orderAllForm = this.orderAllForm.bind(this);
        this.renderAllForm = this.renderAllForm.bind(this);
    }

    //*читаем все формы данного пользователя
    readDataFromServer() {
        this.state.listNameForm.forEach((data) => {
            this.getDataFromServer(data, localStorage.getItem('idUser'));
        })
        setTimeout(() => { this.orderAllForm() }, 2000)
    }

    //*получаем данные формы с сервера
    async getDataFromServer(name, id) {

        new Promise((resolve) => {
            resolve(Server.getDataFromServer(name, id))
        }).then((result) => {
            this.writeParseData(result, name);
        }).catch((result) => {
            console.log(result);
            console.log(' --- ERROR');
        })
        console.log('LOAD DATA FROM SERVER');//test
    }

    //*парсинг данных с сервера
    writeParseData(dataJson, name) {
        let dataNew = [];
        dataNew = [...dataNew, name]
        dataJson.forEach((data) => {
            dataNew = [...dataNew, { id: data.id, fid: data.fid, description: data.description, information: data.information, value: data.value }]
        })
        this.setState({ baseData: [...this.state.baseData, dataNew] })
        console.log(this.state.baseData);
    }

    //выстраиваем формы попорядку
    orderAllForm() {
        this.state.listNameForm.forEach((data) => {
            this.state.baseData.forEach((elem) => {
                if (data === elem[0]) this.renderAllForm(elem); console.log(elem);
            })
        })
    }

    renderAllForm(elem) {
        elem.forEach((data, index) => {
            if (typeof (data) !== 'object') {
                this.setState({ base: [...this.state.base, <h5>{data}</h5>] })
            } else {
                if (data.id) this.setState({
                    base: [...this.state.base, <div>
                        <span style={{ display: 'inline-block', width: '60px', backgroundColor: '#fff', marginRight: '5px', paddingRight: '5px', paddingLeft: '5px' }} >id: {data.id} </span>
                        <span style={{ backgroundColor: '#cbc', marginRight: '5px', paddingRight: '5px', paddingLeft: '5px' }} > fid: {data.fid} </span>
                        <span style={{ backgroundColor: '#dfdfdf', marginRight: '5px', paddingRight: '5px', paddingLeft: '5px' }} > description: {data.description} </span>
                        <span style={{ backgroundColor: '#bccccc', marginRight: '5px', paddingRight: '5px', paddingLeft: '5px' }} > information: {String(data.information)} </span>
                        <span style={{ backgroundColor: '#cca', marginRight: '5px', paddingRight: '5px', paddingLeft: '5px' }} > value: {String(data.value)}</span>
                    </div>]
                })
            }
        })
    }

    componentDidMount() {
        this.readDataFromServer();
    }

    render() {
        return (
            <>
                <div>
                    {this.state.base}
                </div>
            </>
        )
    }
}