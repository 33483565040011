import React from 'react';
import { Modal, Row } from 'react-bootstrap';
import Server from '../server/server';
import FileModal from './FileModal';
import AllModalsFooter from '../assets/AllModalsFooter';
import { AddButton, SaveButtonGreen } from '../assets/BasicButtons';

export default class File extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: true,
            newName: '', // назване лота или торг.позиции и др.
            id: '', // ID массива объектов инф. о файлах (нахвание, путь, примечание)
            dataFiles: [], // массив объектов инф. о файлах
            resultRender: [], // render
            indexFile: 0,
            disableDownload: true,
            isAddBtnDisabled: false,
            test: false
        }
        this.doClose = this.doClose.bind(this);
        this.handleAddNewFile = this.handleAddNewFile.bind(this);
        this.getDataFile = this.getDataFile.bind(this);
        this.doSaveFile = this.doSaveFile.bind(this);
        this.saveOnServer = this.saveOnServer.bind(this);
        this.deletePosition = this.deletePosition.bind(this);
        this.getDisableDownload = this.getDisableDownload.bind(this);
        this.disableAddButton = this.disableAddButton.bind(this)
    }

    getDisableDownload(isDisabled) {
        this.setState({
            disableDownload: isDisabled
        })
    }

    deletePosition(inx) {
        (async () => {
            try {
                this.setState({ show: false })
                let dataD = this.state.dataFiles;

                // console.log(`DELETE POSITION: `, inx); // test

                // dataD.splice(inx, 1) 
                // console.log(`TEST delete file -> change field to TRUE:::  `, dataD[inx]);

                if (dataD[inx].archive === false) {
                    dataD[inx].archive = true
                } else {
                    Object.assign(dataD[inx], { archive: true })
                }
                // console.log(`dataD${inx} to archive:::: `, dataD[inx]); // test

                this.setState({ dataFiles: dataD })

                await this.saveOnServer();
                await this.getDataFile(this.state.id);

                this.setState({ show: true });
            } catch (err) {
                console.log(`Ошибка удаления позиции: `, err);
            }
        })()

    }

    async saveOnServer() {
        try {
            this.props.returnId(this.state.id)

            // console.log(`DATA for file::::::: `, this.state.dataFiles); // test
            // console.log(`ID FILE DATA:::: `, this.state.id); // test

            const result = await Server.sendDataOnServer(this.state.dataFiles, this.state.id, 'trade_file');

            if (result.ERROR) throw new Error(result.message)
            this.props.getFilesNames(this.state.dataFiles)

            // console.log(`RESULT DATA: `, this.state.id, this.state.dataFiles); // test
        } catch (err) {
            console.log(`Ошибка: `, err);
        }
    }

    doSaveFile(fileInf, inx) {
        (async () => {
            try {
                let newData = this.state.dataFiles;
                newData[inx] = fileInf;
                await this.setState({ dataFiles: newData })
                await this.saveOnServer();
                this.disableAddButton(inx)
                await this.getDataFile(this.props.id)

            } catch (err) {
                console.log(`Ошибка передачи данных от дочернего компонента`);
            }
        })();

    }

    disableAddButton(idx) {
        if (this.state.indexFile > idx) {
            this.setState({
                isAddBtnDisabled: false
            })
        }
    }


    // загружаем информацию о файлах с сервера
    async getDataFile(id) {
        try {
            const result = await Server.getDataFromServer(id, 'trade_file')

            if (result.ERROR) throw new Error(result.mesage);

            await this.setState({ dataFiles: result })
            const resultRender = result.map((data, index) => {

                // console.log(`NAME FILE: oldNameFile`, data.oldNameFile);
                // console.log(`NAME FILE: newName`, this.props.newName);
                // console.log(`NAME FILE: newNameFile`, data.newNameFile);
                // console.log(`NAME FILE: copyNameFile`, data.copyNameFile);

                if (!data.archive) {
                    // console.log(`ARCHIVE::: `, data.archive);
                    this.setState({ indexFile: index + 1 })
                    return (
                        <React.Fragment key={index}>
                            <FileModal
                                index={index}
                                oldNameFile={data.oldNameFile}
                                newName={this.props.newName}
                                newNameFile={data.newNameFile}
                                copyNameFile={data.copyNameFile}
                                pathFile={data.pathFile}
                                typeFile={data.type}
                                size={data.size}
                                descriptionFile={data.descriptionFile}
                                onSaveFile={this.doSaveFile}
                                deletePosition={this.deletePosition}
                                toNextTab={this.props.toNextTab}
                                getDisableDownload={this.getDisableDownload}
                                table={true} // при TRUE меняет верстку
                                showCheckIcon={this.props.showCheckIcon}
                                showDelIcon={this.props.showDelIcon}
                                asCustomer={this.props.asCustomer}
                            />
                        </React.Fragment>
                    )
                }
            })

            this.setState({ resultRender: resultRender })

        } catch (err) {
            console.log(`Ошибка, загрузка информации с сервера о файлах: `, err);
            this.handleAddNewFile();
        }

    }

    handleAddNewFile() {
        // console.log(`Добавить новый файл`); // test
        // TODO: Добавить форму для сохранения файла
        this.setState({
            resultRender: [
                <React.Fragment key={this.state.indexFile}>
                    <FileModal
                        index={this.state.indexFile}
                        oldNameFile={''}
                        newName={this.props.newName}
                        newNameFile={''}
                        copyNameFile={''}
                        pathFile={''}
                        typeFile={''}
                        size={''}
                        descriptionFile={''}
                        onSaveFile={this.doSaveFile}
                        deletePosition={this.deletePosition}
                        toNextTab={this.props.toNextTab}
                        getDisableDownload={this.getDisableDownload}
                        width={this.props.showNextBtn}
                        showCheckIcon={this.props.showCheckIcon}
                        showDelIcon={this.props.showDelIcon}
                        asCustomer={this.props.asCustomer}
                    />
                </React.Fragment>,
                ...this.state.resultRender
            ],
            indexFile: this.state.indexFile + 1
        })
        this.setState({
            isAddBtnDisabled: true
        })
    }

    doClose() {
        // TODO: перед выходом получить подтверждение

        this.props.onClose(); // пропс для закрытия формы

    }

    async componentDidMount() {
        this.setState({
            newName: this.props.newName,  // название ЛОТа или торг.позиции   
        });

        // console.log(`PROPS ID >>>>>> `, this.props.id); // test 

        if (this.props.id) {
            // console.log(`PROPS ID >>>>>>>> `, this.props.id); // test
            this.setState({ id: this.props.id })
            await this.getDataFile(this.props.id) // загрузка инф. о файлах
        } else {
            console.error(`ID должен приходить от родителя`);
        }


    }


    render() {
        // console.log('i am in fileNoModal - ', this.state.dataFiles);

        return (
            <>

                {this.props.inPositionsModal &&
                    <Modal.Header style={{ backgroundColor: '#F0F1F2', fontWeight: 'bolder' }}>
                        Файлы к позиции
                    </Modal.Header>}

                <Modal.Body
                style={{ marginBottom: this.props.isMarginBottomNeeded && '40px'}}
                    // className={`${this.props.showNextBtn && 'height_350'}`}
                    // style={{
                    //     padding: '10px 35px 15px 35px',
                    //     // overflowX: 'auto',
                    //     marginBottom: this.props.inPositionsModal ? '0' : '72px'
                    // }}
                    >
                    <Row> &nbsp; </Row>

                    {this.state.show && this.state.resultRender}

                </Modal.Body>
                <Row> &nbsp; </Row>
                <AllModalsFooter
                    isPosAbsoluteNedded={true}
                    footerContent={<>
                        {this.props.showAddBtn && 
                        <AddButton
                        handleSaveClick={this.handleAddNewFile} 
                        needDisable={false} 
                        buttonText='новый файл' />
                        // <Button
                        //     variant='primary'
                        //     onClick={this.handleAddNewFile}
                        //     disabled={this.state.isAddBtnDisabled}
                        // >
                        //     {/* <AddOutlinedIcon className='pb_3' />  */}
                        //     Добавить новый файл
                        // </Button>
                        }

                        {this.props.showNextBtn &&
                        <SaveButtonGreen
                        handleSaveClick={this.props.toNextTab} 
                        needDisable={false} 
                        // disableCondition, 
                        saveButtonText='Далее'
                        />
                            // <Button
                            //     // disabled={this.state.disableDownload}
                            //     onClick={this.props.toNextTab}
                            //     variant='success'>
                            //     Далее
                            // </Button>
                            }
                    </>}
                />
            </>
        )
    }
}