import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import {
  commonParametersLeft,
  commonParametersRight,
  materialDesignApparatus,
  heatExchangePipe,
  tubeSheets,
  partitions,
  sealing,
  deliveryCostAndProductionTime,
  manufacturingCostCalculation,
  materials
} from './constants/namePositionCoastHeatExchagers'
import FormControlInputOrSelect from '../assets/FormControlInputOrSelect'
import FormMaterials from './FormMaterials'
import server from '../server/server'
import { apiSTHE } from './constants/constAPIforSTHE'
import LoaderPage from '../assets/loaderPage'
import collectData from './collectData'
import RenderResultSTHE from './RenderResultSTHE'
import { LoadButton, SaveCurrentDataButton, SendButton } from '../assets/BasicButtons'

const FormCoast = () => {
  const [data, setData] = useState({})
  const [showLoader, setShowLoader] = useState(false)
  const [objResult, setObjResult] = useState({})
  const [showResult, setShowResult] = useState(false)
  const [saveGeneralParams, setSaveGeneralParams] = useState(false)

  const setGeneralParamsSaved = () => {
    setSaveGeneralParams(true)
    setTimeout(() => {
      setSaveGeneralParams(false)
    }, 1500)
  }

  const doCommonObject = (obj) => {
    Object.assign(data, obj)
    console.log(`DATA :::: `, data)
    setData(data)
  }

  const handleSendObject = () => {
    ;(async () => {
      try {
        setShowLoader(true)
        // todo: Перед отправкой необходимо преобразовать в объект STHE
        const objSTHE = collectData(data)
        console.log(`OBJ::::: `, objSTHE) // test
        const resultSTHE = await server.apiSTHEpost_send(objSTHE)
        setShowLoader(false)
        setObjResult(resultSTHE)
        setShowResult(true)
        console.log(`RESULT: `, resultSTHE) // test
      } catch (err) {
        console.log(`Ошиба запроса STHE: `, err)
      }
    })()
  }

  const handleClickSave = () => {
    ;(async () => {
      try {
        if (!data.application) {
          throw new Error('Нет номера указанной заявки!!!')
        }
        setShowLoader(true)
        await server.sendDataOnServer(data, data.application, 'sthe_data')
        setShowLoader(false)
      } catch (err) {
        console.log(`Ошибка при сохранении данных STHE: `, err)
      }
    })()
  }

  const handleClickLoad = () => {
    ;(async () => {
      try {
        if (!data.application) {
          throw new Error('Нет номера указанной заявки!!!')
        }
        setShowLoader(true)
        const result = await server.getDataFromServer(data.application, 'sthe_data')
        setData(result)
        setShowLoader(false)
        // setGeneralParamsSaved()
        console.log(`LOAD RESULT:::: `, result) // test
      } catch (err) {
        console.log(`Ошибка при загрузки данных STHE: `, err)
      }
    })()
  }

  return (
    <>
      <Form
        style={{
          backgroundColor: '#fff',
          padding: '20px 30px',
          borderRadius: '5px'
        }}
      >
        <h4 className='filter_box_h5'>Расчет стоимости теплообменника</h4>
        <h5 className='filter_box_h5'>1. Общие параметры:</h5>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <div style={{ width: '50%', padding: '10px' }}>
            <FormControlInputOrSelect
              arr={commonParametersLeft}
              returnObject={doCommonObject}
              data={data}
            />
          </div>
          <div style={{ width: '50%', padding: '10px' }}>
            <FormControlInputOrSelect
              arr={commonParametersRight}
              returnObject={doCommonObject}
              data={data}
            />
          </div>
        </div>
        <div className='button_container'>
          {/* <SaveCurrentDataButton
            handleSaveClick={handleClickLoad}
            needDisable={false}
            savedDataMessage={saveGeneralParams}
            buttonTextDefault={'Загрузить'}
            buttonTextSaved='Данные загружены'
            needCustomIcon={false}
          /> */}
          <LoadButton
            handleSaveClick={handleClickLoad}
            needDisable={false}
            disableCondition={false}
            buttonText={'Загрузить'}
          />
        </div>
        <h5 className='filter_box_h5'>2. Укажите материальное исполнение аппарата:</h5>
        <div>
          <FormControlInputOrSelect
            arr={materialDesignApparatus}
            returnObject={doCommonObject}
            data={data}
          />
        </div>

        <h5 className='filter_box_h5'>3. Труба теплообменная:</h5>
        <div>
          <FormControlInputOrSelect
            arr={heatExchangePipe}
            returnObject={doCommonObject}
            data={data}
          />
        </div>

        <h5 className='filter_box_h5'>4. Трубные решетки:</h5>
        <div>
          <FormControlInputOrSelect arr={tubeSheets} returnObject={doCommonObject} data={data} />
        </div>

        <h5 className='filter_box_h5'>5. Перегородки:</h5>
        <div>
          <FormControlInputOrSelect arr={partitions} returnObject={doCommonObject} data={data} />
        </div>

        <h5 className='filter_box_h5'>6. Материалы:</h5>
        <div>
          <FormMaterials arr={materials} returnObject={doCommonObject} data={data} />
        </div>

        <h5 className='filter_box_h5'>7. Уплотнение:</h5>
        <div>
          <FormControlInputOrSelect arr={sealing} returnObject={doCommonObject} data={data} />
        </div>

        <h5 className='filter_box_h5'>8. Для расчета стоимости изготовления:</h5>
        <div>
          <FormControlInputOrSelect
            arr={manufacturingCostCalculation}
            returnObject={doCommonObject}
            data={data}
          />
        </div>

        <h5 className='filter_box_h5'>9. Стоимость доставки и срок изготовления:</h5>
        <div>
          <FormControlInputOrSelect
            arr={deliveryCostAndProductionTime}
            returnObject={doCommonObject}
            data={data}
          />
        </div>

        <div className='button_container'>
          {/* <Button variant='info' onClick={handleClickSave}>
            SAVE
          </Button> */}

          <SaveCurrentDataButton
            handleSaveClick={handleClickSave}
            needDisable={false}
            savedDataMessage={''}
            buttonTextDefault={'Сохранить'}
            buttonTextSaved={'Данные сохранены'}
            needCustomIcon={false}
            // customIcon,
            needCustomStyle={false}
            // customStyle
          />
        </div>

        <h5 className='filter_box_h5'>10. Результат расчета:</h5>

        {/* <div className='button_container'>
          <Button onClick={handleSendObject}>SEND OBJECT to STHE</Button>
        </div> */}

        <SendButton
          handleSaveClick={handleSendObject}
          needDisable={false}
          variant='primary'
          additionalText='в STHE'
        />
        {showResult && (
          <div style={{ width: '100%', backgroundColor: '#ccc' }}>
            &nbsp;
            <RenderResultSTHE obj={objResult} />
          </div>
        )}
        {showLoader && (
          <div style={{ position: 'fixed', top: '50%', left: '50%' }}>
            <LoaderPage />
          </div>
        )}
      </Form>
    </>
  )
}

export default FormCoast
