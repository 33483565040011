import changeTimeToLocal from "../assets/changeTimeToLocal";
import getWinnerData from "../assets/getWinnerData";
import { toMoscowTime } from "../trade/processTradeObject";

/**
 * Сортирует все ЛОТы по датам
 * @param {Object} tradeLot Массив объектов ЛОТов
 * @param {Array} lotsPast массив
 * @param {String} timezone Часовой пояс, строка
 * @returns Array[object] - Массив отобранных объектов ЛОТов / 
 *  dateTechOld: время согласований прошло, торги не наступили (плановые) / 
        dateTech: наступило время согласований (на согласовании) / 
        dateTrade: торги должны начаться в эти сутки (текущие) / 
        dateOld: торги прошли (завершенные) / 
        current: торги идут (Идущие)
 */



export const doDateData = (lots, lotsPast, timezone) => {

    // console.log(`LOTS::::: `, lots); // test
    // console.log(`LOTS::::: `, lotsPast);  // test
    try {
        if (!lotsPast) lotsPast = [];

        const date = new Date();
        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        const tradeLot = lots.map(lot => {
            const date = new Date(lot.dateTrade)
            const newDateRead = `${Number(date.getDate()) < 10 ? 0 : ''}${date.getDate()}-${(Number(date.getMonth()) + 1) < 10 ? 0 : ''}${date.getMonth() + 1}-${date.getFullYear()}`

            let confirmation;
            lot.participantConditions.forEach((item, index) => {

                if (item.id === localStorage.getItem('idUser')) {
                    // confirmation = lot.participantConditions[index].confirmation
                    confirmation = (lot.participantConditions[index].confirmation ? lot.participantConditions[index].confirmation : 'На подтверждении')
                }
            })

            return Object.assign(lot, { dateTradeRead: newDateRead }, { confirmation: confirmation })
        })

        const tradeLotPast = lotsPast.map(lot => {
            const date = new Date(lot.dateTrade)
            const newDateRead = `${Number(date.getDate()) < 10 ? 0 : ''}${date.getDate()}-${(Number(date.getMonth()) + 1) < 10 ? 0 : ''}${date.getMonth() + 1}-${date.getFullYear()}`

            let confirmation;
            lot.participantConditions.forEach((item, index) => {

                if (item.id === localStorage.getItem('idUser')) {
                    // confirmation = lot.participantConditions[index].confirmation
                    confirmation = (lot.participantConditions[index].confirmation ? lot.participantConditions[index].confirmation : 'На подтверждении')

                }
            })

            return Object.assign(lot, { dateTradeRead: newDateRead }, { confirmation: confirmation })
        })

        //* время согласований прошло, торги не наступили (плановые)
        let dateTechOld = [];
        tradeLot.forEach(el => {
            const dateT = +new Date(el.dateTech)

            if ((dateT) > Date.now()) dateTechOld.push(el)
            // console.log(`DATE tech:::: ${dateT} - ${Date.now()} = ${dateT + (24 * 60 * 60 * 1000) - Date.now()}`); // test
        })

        //* наступило время согласований (на согласовании)
        let dateTech = [];
        tradeLot.forEach(el => {
            const dateTS = +new Date(el.dateTechStart)
            const dateT = +new Date(el.dateTech)

            if (dateTS <= Date.now() && (dateT + (1000 * 60 * 60 * 24)) >= (Date.now())) dateTech.push(el)
        })
        // console.log(`dateTech:::::::::::::::::::::: `, dateTech); // test

        //* торги должны начаться в эти сутки (текущие торги)
        let dateTrade = [];
        tradeLot.forEach(el => {
            const dateTr = +new Date(el.dateTrade)

            if ((dateTr - 1000 * 60 * 60 * 24) < Date.now() && (dateTr + (1000 * 60 * 60 * 24)) > Date.now()) dateTrade.push(el)
            // console.log(`date trade::: ${(dateTr - (1000 * 60 * 60 * 24))} < ${Date.now()} = ${(dateTr - (1000 * 60 * 60 * 24)) - Date.now()}`); // test
            // console.log(`${dateTr + (1000 * 60 * 60 * 24)} > ${Date.now()} = ${dateTr + (1000 * 60 * 60 * 24) - Date.now()}`); // test
        })

        // торги идут
        let current = [];
        tradeLot.forEach(el => {
            // console.log(`el ====>>>> `, el.nameLot); // test
            // console.log(`el.timeTrade, timezone ===: `, el.timeTrade, timezone); // test
            const dateTrade = Date.parse(el.dateTrade)
            const timeLocal = changeTimeToLocal(el.timeTrade, timezone)
            const arrTimeTrade = timeLocal.split(':') // todo: перевести время timeTrade в локальное (el.timeTrade)
            const timeH = arrTimeTrade[0] * 60 * 60 * 1000;
            const timeM = arrTimeTrade[1] * 60 * 1000
            const startTime = dateTrade + timeH + timeM
            const endTime = startTime + (Number(el.timer) * 60 * 1000)

            // console.log(`timezone::: `, timezone); // test
            // console.log(`timeLocal >>> `, timeLocal); // test
            // console.log(`START TIME: ${startTime} = time now: ${Date.now()} = time END: ${endTime}`); // test
            if (startTime < Date.now() && Date.now() < endTime) current.push(el);
        })

        //* торги прошли (завершенные)
        let dateOld = tradeLotPast;

        // console.log(`do date data:::: `, {
        //     dateTechOld: dateTechOld,
        //     dateTech: dateTech,
        //     dateTrade: dateTrade,
        //     dateOld: dateOld,
        //     tradeLot: tradeLot,
        //     current: current,
        // }); // test

        return {
            dateTechOld: dateTechOld,
            dateTech: dateTech,
            dateTrade: dateTrade,
            dateOld: dateOld,
            tradeLot: tradeLot,
            current: current,
        }

    } catch (err) {
        console.log(`Ошибка в отборе даты: `, err);
    }

}

// ************

const getStatus = (lot) => {
    const date = new Date(lot.dateTrade)

    const newDateRead = `${Number(date.getDate()) < 10 ? 0 : ''}${date.getDate()}-${(Number(date.getMonth()) + 1) < 10 ? 0 : ''}${date.getMonth() + 1}-${date.getFullYear()}`

    let confirmation;
    // console.log(lot.participantConditions);
    lot.participantConditions.forEach((item, index) => {

        if (item.id === localStorage.getItem('idUser')) {
            confirmation = (lot.participantConditions[index].confirmation ? lot.participantConditions[index].confirmation : 'На подтверждении')
        }
    })
    return Object.assign(lot, { dateTradeRead: newDateRead }, { confirmation: confirmation })
}

export const categorizeTrades = async (fullTradesArray) => {
    // console.log(fullTradesArray);
    try {
        const all = fullTradesArray.slice()
        const invitations = []
        const drafts = []
        const sent = []
        const current = []
        const finishedNoWinner = []
        const finishedWithWinning = []
        const canceled = []

        const array = fullTradesArray?.slice()



        for (const trade of array) {
            const { dateTrade, timer, timeTrade } = trade
            //дата начала
            const tradeStartDate = new Date(dateTrade);
            //время начала
            const tradeStartTimeParts = timeTrade?.split(':');
            if (tradeStartTimeParts && tradeStartTimeParts.length === 2) {
                tradeStartDate.setHours(parseInt(tradeStartTimeParts[0]), parseInt(tradeStartTimeParts[1]), 0, 0);
            }
            //длительность в минутах
            const tradeDurationMinutes = parseInt(timer, 10);
            //дата конца
            const tradeEndDate = new Date(tradeStartDate.getTime() + tradeDurationMinutes * 60000);
            //текущее московское время
            const currentMoscowTime = toMoscowTime(new Date());
            //победитель
            const winnerObject = await getWinnerData(trade.id)
            const winnerId = winnerObject !== null ? winnerObject.participantCompany : null;
            const amIWinner = winnerId === localStorage.getItem('idUser') ? true : false

            //статус заполнения
            getStatus(trade)
            const confirmation = trade.confirmation

            //    console.log(trade);

            //текущие
            if (currentMoscowTime >= tradeStartDate && currentMoscowTime < tradeEndDate) {
                current.push(trade)
                //подведение итогов
            } else if (currentMoscowTime > tradeEndDate && !amIWinner && confirmation !== 'На подтверждении') {
                finishedNoWinner.push(trade)
                //победитель
            } else if (currentMoscowTime > tradeEndDate && amIWinner && confirmation !== 'На подтверждении') {
                finishedWithWinning.push(trade)
                //черновики
            } else if (!dateTrade || !timer || !timeTrade) {
                drafts.push(trade)
                //приглашения
            } else if (confirmation === 'На подтверждении') {
                invitations.push(trade)
                //предложение подано
            } else if (confirmation === 'Заполнено') {
                sent.push(trade)
            }
        }

        return {
            all,
            invitations,
            drafts,
            sent,
            current,
            finishedNoWinner,
            finishedWithWinning,
            canceled
        }

    } catch (err) {
        console.log(`Ошибка при определении категорий торгов у заказчика`, err);
    }
}
