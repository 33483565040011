import React from 'react';
import { Alert, Container, Form, Row } from 'react-bootstrap';
import ListCheckFormNew from '../../questionnaire/ListCheckFormNew';
import { FLANGES } from '../../questionnaire/ConstMaterial';
// import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { CloseBtnForFilter } from '../../assets/CloseBtnForFilter';
import Server from '../../server/server';
import { ButtonGroupForFilterForms } from '../../assets/ButtonGroupForFilterForms';

export default class FormFlanges extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: false,
            nameForm: 'FormFlanges',
            maxId: 90,
            dataValue: [],
            dataOnServer: [],
            dataFromServer: [],
            lengthDataFromServer: 0,
            showQuit: false,
            showVariants: true
        }
        this.doChangeValue = this.doChangeValue.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
        // this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this);
        this.handleClickMaterieals = this.handleClickMaterieals.bind(this);

        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
        this.sendProductDataOnServer = this.sendProductDataOnServer.bind(this)
    }

    async clearFields() {
        await this.setState({
            showVariants: false
        })

        this.setState({
            showVariants: true
        })
    }

    handleClickMaterieals() {
        this.props.onRunMaterials('Four_FormFlanges');
    }

    //* empty
    doEmpty() {
    }


    //запуск выхода из формы
    handleClickShadow() {
        this.setState({ showQuit: true })
    }

    //выход с сохранением
    doQuitWithSave() {
        this.setState({ showQuit: false })
        this.handleClickSave();
        this.props.onChangeView(this.state.nameForm);
    }
    //выход без сохранения
    doQuitWithoutSave() {
        this.setState({ showQuit: false })
        this.props.onChangeView(this.state.nameForm);
    }

    //*формируем данные в массив объектов для отправки на сервер
    async handleClickSave(e) {
        e.preventDefault()
        let data = this.state.dataOnServer;

        for (let i = 0; i <= (this.state.maxId); i++) {

            //todo создаем позицию с пустым объектом
            data[i] = { id: '', fid: '', description: '', information: '', value: '' }

            //todo заполняем объект данными с сервера
            this.state.dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
            this.state.dataValue.forEach((dataE) => {
                if (+dataE.id === i) {
                    data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                }
            })

        }
        await this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере

        this.props.getChosenFiltersData(data)
        //отправляем родителю имя этой формы
        // this.props.getFormName(this.state.nameForm)
        //берем данные с сервера
        this.props.saveChosenFilters()


        const result = await Server.filterProductionCompany(data, this.state.nameForm)
        await this.props.sendSearchResultData(result)
        this.props.openResultTable()
        this.props.closeModal(e)
    }

    //* данные с полей формы, формируются в массив объектов
    doChangeValue(data) {
        // console.log(data);//test
        this.setState({ dataValue: [...this.state.dataValue, data] })
        // setTimeout(() => { console.log(this.state.dataValue) })//test
    }

    onKeyPressHandler(event) {
        event.target.dataset.position = 21
        if (event.keyCode === 13) {
            this.handleClickSave(event)
        }
    }

    async sendProductDataOnServer() {
        const productData = {
            product: this.props.product,
            formName: this.state.nameForm
        }
        await Server.sendDataOnServer(productData, 'product', localStorage.getItem('idUser'))
    }

    async componentDidMount() {
        // setTimeout(() => {
        await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer });
        // console.log('DATA on dataFromServer > ', this.props.data);//test
        // }, 1000);

        document.addEventListener('keydown', this.onKeyPressHandler)

        await this.sendProductDataOnServer()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPressHandler)
    }

    render() {

        const flanges = ["Dy, мм", "Py, МПа"];
        const view = this.props.view;
        this.value = this.props.data;
        const viewQuest = this.props.viewQuest;
        // const SIZES = ['мин диаметр днища, мм', 'макс диаметр днища, мм', 'Макс толщ днища, мм', 'Мин толщ днища, мм'];
        return (
            <>

                <div className="modal_window" style={{ display: view ? 'block' : 'none' }} >
                    <div className={viewQuest ? "shadow_form" : "shadow_form_off"} onClick={this.props.closeModal}  ></div>
                    <Form className={this.props.chosenCompaniesFromProductionSearch && this.props.chosenCompaniesFromProductionSearch.length >= 0 ? "form_main" : "form_main_1"}  >
                        <Alert
                            variant="dark"
                            className="filter_alert"
                        >
                            <Alert.Heading >Поставка фланцев и заготовок для фланцев </Alert.Heading>
                            <CloseBtnForFilter dataId={21} closeModal={this.props.closeModal} />
                        </Alert>
                        <div>
                             <Container className="form_container">
                            <Form.Label classNAme='f_size_1em'>
                                Размеры фланцев и заготовок для фланцев
                            </Form.Label>
                            <Row> &nbsp;</Row>

                            {this.state.showVariants &&
                                <ListCheckFormNew
                                    id={1}
                                    width={3}
                                    widthSize={6}
                                    fontSize={'12px'}
                                    showSizeForm={true}
                                    materials={FLANGES}//кол-во позиций
                                    value={this.value}
                                    placeholder={flanges}//размеры (до 4-х)
                                    palceholderCheck={['Готовые фланцы', 'Заготовки для фланцев']}
                                    description="Фланцы"
                                    onChangeValue={this.doChangeValue}
                                    nameForm={this.state.nameForm}
                                    onCheck={true}
                                />}

                            <Row> &nbsp;</Row>

                            <Row>&nbsp;</Row>

                        </Container>
                            <ButtonGroupForFilterForms
                                dataId={21}
                                handleClickSave={this.handleClickSave}
                                clearFields={this.clearFields}
                                savedFiltersData={this.props.savedFiltersData}
                            />
                        </div>
                       
                    </Form>
                </div>
            </>
        )
    }
}