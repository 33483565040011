import React from 'react'
import { Form, ListGroup } from 'react-bootstrap'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';


export default function ListGroupCustomer({ table, index, oldNameFile, descriptionFile, handleClickSave,
    handleClickReadFile, handleClickDel, pathFile, handleChangeDescription, showDelIcon, showCheckIcon, asCustomer }) {


    const shortenWords = (word, letterCount) => {
        if (word && word.length > 0) {
            if (word.length > letterCount) {
                return `${word.substring(0, letterCount)}...`
            } else {
                return word
            }
        }
    }

    // console.log(dontShowDelIcon);


    const border = '1px solid rgba(0,0,0,.125)'
    const height = '60px'
    return (

        <ListGroup>
            <div style={{ display: 'flex' }}>
                <ListGroup.Item style={{ width: '8%', border, height }} >
                    {table && <span sm={1} style={{ marginRight: '-50px', marginTop: '25px' }} > {index + 1} </span>}
                </ListGroup.Item>

                <ListGroup.Item style={{ width: '20%', border, height }} title={oldNameFile}>
                    {shortenWords(oldNameFile, 11)}
                </ListGroup.Item>

                <ListGroup.Item 
                style={{ width: showDelIcon ? '42%' : '60%', border, height }} 
                title={descriptionFile}>
                    <Form.Group style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Form.Control
                            style={{ border: pathFile && pathFile.length !== 0 && '0', cursor: 'text' }}
                            type="text"
                            placeholder="Описание файла"
                            onChange={handleChangeDescription}
                            value={descriptionFile && descriptionFile}
                            readOnly={asCustomer}
                        />
                    </Form.Group>
                </ListGroup.Item>

                {showCheckIcon &&
                    <ListGroup.Item
                        title={!pathFile && `Сохраните файл, чтобы прикрепить его к текущему документу`}
                        className={'list_item_stile'}
                        as="li"
                        action
                        onClick={handleClickSave}
                        style={{ height }}
                    >
                        {pathFile && pathFile.length !== 0 ?
                            <CheckOutlinedIcon color={'success'} /> :
                            <SaveOutlinedIcon color={'success'} />}
                    </ListGroup.Item>}

                {pathFile && pathFile.length !== 0 && <>
                <ListGroup.Item
                    className={'list_item_stile'}
                    as="li"
                    action
                    onClick={() => handleClickReadFile(pathFile)}
                >
                    <DownloadOutlinedIcon color={'primary'} />
                </ListGroup.Item>

                    {showDelIcon &&
                        <ListGroup.Item
                            className={'list_item_stile'}
                            color="action" as="li"
                            action
                            onClick={handleClickDel}
                            style={{ height }}
                        >
                            <DeleteForeverOutlinedIcon style={{ color: '#dc3545' }} />
                        </ListGroup.Item>}

                </>}
            </div>
        </ListGroup>

    )
}
