import React, { useState, useEffect } from 'react'
import { Form, Table } from 'react-bootstrap'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import { nanoid } from 'nanoid'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'

/**
 * Загрузка одного файла на сервер
 * @param {*} param0
 * @returns
 */
const LoadFile = ({
  item,
  index,
  keyConst,
  onSendDataFile,
  idCard,
  dataDocum,
  readFileDocument
}) => {
  const [file, setFile] = useState()
  const [fileName, setFileName] = useState()
  const [fileExt, setFileExt] = useState()
  const [fileExc, setFileExc] = useState()
  const [newNameFile, setNewNameFile] = useState()
  const [datasetId, setDatasetId] = useState()
  const [datasetDescription, setDatasetDescription] = useState()

  console.log('tyytyty', dataDocum)

  const onChangeFile = (e) => {
    // console.log(`file::: `, e.target.files[0]); // test

    setTimeout(() => {
      //   console.log(`FILES::::: `, e.target.files) // test
    }, 1000)

    setFile(e.target.files[0]) //приводим к blob значению
    setFileName(e.target.files[0].name.split('.')[0]) //разделение по точке
    setFileExt(e.target.files[0].type) // тип файла / расширение файла
    setFileExc(e.target.files[0].name.split('.').pop()) //разделение по точке
    setDatasetId(e.target.dataset.id) // ID
    setDatasetDescription(e.target.dataset.description) // description
  }

  useEffect(() => {
    if (file) {
      // console.log(`SEARCH DATA FILE >>>>>>>>>>> : `, file);//test

      if (file.size > 1024) console.log('SIZE file: ' + file.size)
      if (!fileName) setFileExc('') //????????

      // console.log(fileName + ' --- ' + fileExc);//получаем fileName и ext(расширение) // ! fileExt -> fileExc

      // формируем новое имя файла для хранения в DB и на сервере
      if (fileExt) {
        setNewNameFile(`${nanoid()}-${datasetId}_-${fileName}.${fileExc}`) //уникальное имя файла
      } else {
        setNewNameFile(`${nanoid()}-${datasetId}_-${fileName}`) //уникальное имя файла
      }
    }
  }, [file, datasetDescription, datasetId, fileExc, fileName, fileExt])

  useEffect(() => {
    if (newNameFile) {
      ;(async () => {
        try {
          //* наполняем запрос к серверу
          let newDataForm = new FormData()
          newDataForm.append('key', 'weifeiph7Pie') //todo kye - constanta
          newDataForm.append('filename', newNameFile) //todo создать уникальный
          newDataForm.append('ext', fileExt)
          newDataForm.append('session', sessionStorage.getItem('sessionId'))
          newDataForm.append('login', sessionStorage.getItem('login'))
          newDataForm.append('userid', localStorage.getItem('idUser')) //
          newDataForm.append('idCard', idCard) // ID Карточки аппарата
          newDataForm.append('file', file, fileName) //в тело объекта новой формы добавили INPUT

          makeArrayDataFile(datasetId, fileName, newNameFile, datasetDescription, newDataForm)
        } catch (err) {
          console.log(`Ошибка при создании объекта: `, err)
        }
      })()
    }
  }, [newNameFile])

  /**
   * формируем объект загруженного файла
   * @param {String} id - index
   * @param {String} oldNameFile - старое названи файла
   * @param {String} description - ключ, описание раздела
   * @param {Object} newDataForm - объект файла
   */
  const makeArrayDataFile = (id, oldNameFile, newNameFile, description, newDataForm) => {
    let newData = {
      id: id,
      idCard: idCard,
      description: description,
      information: newNameFile,
      value: oldNameFile
    }

    onSendDataFile(newDataForm, newData) //todo отправляем объект с файлом SEND DATA (форма, объект, maxID)
  }

  let timeStamp = dataDocum?.dateOfCreation
  let date = new Date(timeStamp)
  let formattedDate =
    ('0' + date.getDate()).slice(-2) +
    '/' +
    ('0' + (date.getMonth() + 1)).slice(-2) +
    '/' +
    date.getFullYear()

  return (
    <React.Fragment key={index}>
      <Form action='/server_data/file_documentation' method='post' encType='multipart/form-data'>
        <>
          <div style={{ padding: '6px 12px', fontWeight: 'bolder' }}>{`${index+1}. ${item}`}</div>
          <Table bordered>
            <thead style={{ color: '#777' }}>
              <th className='width_50px'>ПП</th>
              <th>Дата</th>
              <th>Имя файла</th>
              <th className='width_50px'></th>
            </thead>

            <tbody>
              <td>1.</td>
              <td>{dataDocum?.originalName ? formattedDate : ''}</td>
              <td
                onClick={() => {
                  readFileDocument(`${dataDocum?.path}`)
                }}
                style={{ cursor: 'pointer', color: 'blue' }}
              >
                <b>{dataDocum?.fileName.split('-').pop()}</b>
              </td>
              <td>
                {dataDocum?.originalName ?  <button className='btn_delete'>
                  <DeleteForeverOutlinedIcon sx={{ color: '#ED4880' }} />
                </button> : ''}
              </td>
            </tbody>
          </Table>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Form.File
              style={{ display: 'none' }}
              id={index + 1}
              onChange={onChangeFile}
              accept={item}
              data-id={index + 1}
              // data-fid={`${this.props.nameForm}_${index + 1}`}
              type='file'
              name='file'
              data-description={keyConst}
              // value={this.value[index + 1] ? this.value[index + 1].value : ''}
            />
            <label for={index + 1} className='custom_file_upload'>
              <AddBoxOutlinedIcon /> Выберите файл
            </label>
          </div>
        </>
      </Form>
    </React.Fragment>
  )
}
export default LoadFile
