import Server from "./server/server";

/**
 * Проверка наличия указанного логина в базе
 * @param {String} login 
 * @returns {boolean} result: (true / false)
 */
const checkForErasePassword = async (login) => {
    try {
        const result = await Server.getDataFromServer(login, 'registration')

        if (result) {
            return true
        } else {
            return false
        }

    } catch (err) {
        console.log(`Ошибка при проверке наличия данного логина в базе: `, err);
        return false
    }

}

export default checkForErasePassword;