import Server from '../server/server'

/**
 * Получаем данные победителя
 * @param {String} id - ID Лота
 * @returns 
 */
const getWinnerData = async (id) => {
    try {
        const winnerData = await Server.getDataFromServer(id, 'winner')

        if (!winnerData) return null

        return winnerData
    } catch (err) {
        console.log(`Ошибка при получении данных победителя: `, err);
        return null
    }

}

export default getWinnerData;