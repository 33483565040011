


export const apiSTHE = {
    'UserAnswers': {
        'date_dp': null,
        'D_apparat': 159, // 1 - диаметр корпуса
        'revision': 6, // 1 - номер ТП
        'D_pipe': 20, // диаметр трубы наружный ?
        'izm_user': 'Borodin',
        'L_pipe': 3000, // 1 - длина пучка
        'tube_sheets': [{
            'tube_sheet_D': 300, // 4- диаметр мм
            'tube_sheet_S': 33, // 4 - площадь мм
            'tube_sheet_N': 2, // 4 - кол-во
            'tube_sheet_type': 'Лист' // 4 - тип изготовления
        },
        {
            'tube_sheet_D': null,
            'tube_sheet_S': null,
            'tube_sheet_N': null,
            'tube_sheet_type': 'Лист'
        }],
        'dizm': null, // ? ********
        'delivery': {
            'address': '', // 9 - адресс
            'cost': null, // 9 - стоимость
            'cost_comment': '', // 9- комментарий к стоимости
            'days': null, // 9 - срок доставки
            'days_comment': '' // 9 - комментарий к сроку доставки
        },
        'production_params': {
            'cost_human': null, // ? ********
            'L': 3000, // длина пучка - 1
            'TUBE_PN_dd': '4.0', // расчетное давление - 8
            'SHELL_T': 100, // расчетная температура - 8 межтрубном
            'climate': 'На улице',  //? ********
            'D': '159',// 1 - диаметр корпуса
            'tube_pass': 1, // кол-во ходов - 8
            'TEMA': 'BEM', // ТЕМА
            'material': '09Г2С', //? материал корпуса??????? **********
            'Massa': 300, // масса корпуса - 1
            'SHELL_PN_dd': '0.6', // давление в межтрубном пространстве - 8
            'tube_install_type': 'Сварка с развальцовкой', //? ************ где взять?
            'N': 1, // кол-во аппартов
            'hard_coef': false, // повышенная сложность изготовления
            'TUBE_T': 100 // расчетная температура - 8 трубном
        },
        'cross_partition_material': 'Ст3', // материал перегородок - 5
        'tube_sheet_material': '09Г2С', // материал решеток - 2
        'materials_user_price': { // формируем из 6 - Материалы
            'Труба теплообменная_Сталь 20': null,  // 6 - Материалы
            'Поковки_09Г2С': null, // 6 - Материалы
            'Крепеж_40Х': null, // 6 - Материалы
            'Листовой прокат из углеродистой стали_Ст3сп5': null, // 6 - Материалы
            'Листовой прокат из углеродистой стали_09Г2С': null, // 6 - Материалы
            'Трубные решетки_09Г2С': null, // материал решеток - 2
            'Поперечные перегородки_Ст3': null, // из раздела 5 - материал перегородок
            'Сортовой прокат из углеродистой стали_Сталь 20': null // 6 - Материалы
        },
        'cross_partition_th': 8, // толщина поперечных перегородок - 5
        'quantity_cross_partition': 21, // кол-во поперечных перегродок - 5
        'N_pipe': 13, // кол-во теплообменных труб - 3
        'reload_prices': false,
        'pvyrez_cross_partition': 20, // процент выреза в перегородке
        'shell_material': '09Г2С', // материа корпуса
        'Massa': 300, // масса корпуса
        'S_pipe': 2, // толщина стенки трубы - 3
        'pipe_material': 'Сталь 20', // материал труб - 2
        'camera_material': '09Г2С', // материал камеры - 2
        'CaseNum': 1, //? кол-во корпусов
        'tema': 'M',
        'izm': 2, // номер измерений - 1
        'apparatus': '23606046', // заявка - 1
        'seal_type_dd': 'нет', // уплотнение - sealing
        'Materials': [{ // 6 - Материалы
            'category': 'Листовой прокат из углеродистой стали',
            'steel': 'Ст3сп5',
            'weight': 10,
            'comment': ''
        },
        {
            'category': 'Сортовой прокат из углеродистой стали',
            'steel': 'Сталь 20',
            'weight': 78,
            'comment': ''
        },
        { 'category': 'Поковки', 'steel': '09Г2С', 'weight': 56, 'comment': '' },
        {
            'category': 'Листовой прокат из углеродистой стали',
            'steel': '09Г2С',
            'weight': 11,
            'comment': ''
        },
        { 'category': 'Крепеж', 'steel': '40Х', 'weight': 23, 'comment': '' },
        {
            'category': 'Стандартные изделия из углеродистой стали',
            'steel': '09Г2С',
            'weight': 8,
            'comment': ''
        }],
        'user_last_name': 'Бородин',
        'margin': 30, // допуск
        'cap_material': '09Г2С' // материал крышки - 2
    },
    'key': 'zaetha8Ii5vohl3joochae0e' // константа
}


export const apiSTHEnull = {
    'key': 'zaetha8Ii5vohl3joochae0e',
    'UserAnswers': {
        'date_dp': null,
        'D_apparat': null,
        'revision': null,
        'D_pipe': null,
        'izm_user': '',
        'L_pipe': null,
        'tube_sheets': [{
            'tube_sheet_D': null,
            'tube_sheet_S': null,
            'tube_sheet_N': null,
            'tube_sheet_type': ''
        },
        {
            'tube_sheet_D': null,
            'tube_sheet_S': null,
            'tube_sheet_N': null,
            'tube_sheet_type': 'Лист'
        }],
        'dizm': null,
        'delivery': {
            'address': '',
            'cost': null,
            'cost_comment': '',
            'days': null,
            'days_comment': ''
        },
        'production_params': {
            'cost_human': null,
            'L': null,
            'TUBE_PN_dd': '',
            'SHELL_T': null,
            'climate': 'На улице',
            'D': '',
            'tube_pass': null,
            'TEMA': '',
            'material': '',
            'Massa': null,
            'SHELL_PN_dd': '',
            'tube_install_type': 'Сварка с развальцовкой',
            'N': null,
            'hard_coef': false,
            'TUBE_T': null
        },
        'cross_partition_material': '',
        'tube_sheet_material': '',
        'materials_user_price': {
            // 'Труба теплообменная_Сталь 20': null,
            // 'Поковки_09Г2С': null,
            // 'Крепеж_40Х': null,
            // 'Листовой прокат из углеродистой стали_Ст3сп5': null,
            // 'Листовой прокат из углеродистой стали_09Г2С': null,
            // 'Трубные решетки_09Г2С': null,
            // 'Поперечные перегородки_Ст3': null,
            // 'Сортовой прокат из углеродистой стали_Сталь 20': null
        },
        'cross_partition_th': null,
        'quantity_cross_partition': null,
        'N_pipe': null,
        'reload_prices': false,
        'pvyrez_cross_partition': null,
        'shell_material': '',
        'Massa': null,
        'S_pipe': null,
        'pipe_material': '',
        'camera_material': '',
        'CaseNum': null,
        'tema': 'M',
        'izm': null,
        'apparatus': '',
        'seal_type_dd': 'нет',
        'Materials': [{
            //     'category': 'Листовой прокат из углеродистой стали',
            //     'steel': 'Ст3сп5',
            //     'weight': 10,
            //     'comment': ''
            // },
            // {
            //     'category': 'Сортовой прокат из углеродистой стали',
            //     'steel': 'Сталь 20',
            //     'weight': 78,
            //     'comment': ''
            // },
            // { 'category': 'Поковки', 'steel': '09Г2С', 'weight': 56, 'comment': '' },
            // {
            //     'category': 'Листовой прокат из углеродистой стали',
            //     'steel': '09Г2С',
            //     'weight': 11,
            //     'comment': ''
            // },
            // { 'category': 'Крепеж', 'steel': '40Х', 'weight': 23, 'comment': '' },
            // {
            //     'category': 'Стандартные изделия из углеродистой стали',
            //     'steel': '09Г2С',
            //     'weight': 8,
            //     'comment': ''
        }],
        'user_last_name': '',
        'margin': 30,
        'cap_material': ''
    },
}