import React, { Component } from 'react'
import { Col, Container, Form, Row, Alert } from 'react-bootstrap'
import { EmployeesPhoneForm } from './EmployeesForms/EmployeesPhoneForm'
import { nanoid } from 'nanoid'
import AllModalsFooter from '../assets/AllModalsFooter'
import QuitModal from '../QuitModal'
import { ChooseAccessTypeButton, SaveCurrentDataButton } from '../assets/BasicButtons'
import RadioButtonThree from '../questionnaire/RadioButtonThree'
import RadioButton from './EmployeesForms/RadioButton'
import { indicateEmptyFields, validateFormData } from '../assets/fieldValidator'

export class EmployeesModalBig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // showOptionsModal: false,
      showQuit: false,
      id: '',
      name: '',
      surname: '',
      thirdName: '',
      gender: '',
      tel: '',
      email: '',
      position: '',
      access: [],
      alert: [],
      resultData: {},
      message: false,
      colorError: '#fff',
      saveModal: false,
      nameError: false,
      telError: false,
      emailError: false,
      accessError: false,
      isSaved: false,
      isChanged: false,
      dataIsSaved: false,
      requiredFields: {
        surname: true,
        name: true,
        thirdName: true,
        tel: true,
        email: true,
        position: true
      }
    }
    this.handleDataSave = this.handleDataSave.bind(this)
    this.handleChangeEmployeeData = this.handleChangeEmployeeData.bind(this)
    this.doChangeValue = this.doChangeValue.bind(this)
    this.emailValidate = this.emailValidate.bind(this)
    this.openQuitModal = this.openQuitModal.bind(this)
    this.closeAllModals = this.closeAllModals.bind(this)
    this.handleClickQuitWithSave = this.handleClickQuitWithSave.bind(this)
    this.checkFilledPositions = this.checkFilledPositions.bind(this)
    this.setDataIsSavedMessage = this.setDataIsSavedMessage.bind(this)
  }

  doChangeVisionBlock = () => {
    // empty
  }

  setDataIsSavedMessage = () => {
    this.setState({
      dataIsSaved: true
    })

    setTimeout(() => {
      this.setState({
        dataIsSaved: false
      })
    }, 1500)
  }

  checkFilledPositions() {
    const s = this.state
    const condition = s.name && s.surname && s.thirdName && s.tel && s.email && s.position

    if (condition) {
      return true
    }

    if (!condition) {
      return false
    }
  }

  handleClickQuitWithSave() {
    this.handleDataSave()
    this.closeAllModals()
  }

  openQuitModal() {
    if (this.state.isChanged && !this.state.isSaved) {
      this.setState({
        showQuit: true
      })
    } else {
      this.closeAllModals()
    }
  }

  closeAllModals() {
    this.setState({
      showQuit: false,
      isSaved: false
    })
    this.props.onModalToggle()
  }

  async handleDataSave() {
    //here fileds are checked if every single field is filled
    const { surname, name, thirdName, tel, email, position } = this.state
    const formData = {
      surname,
      name,
      thirdName,
      tel,
      email,
      position
    }
    const { requiredFields, isFormValid } = validateFormData(formData)
    await this.setState({ requiredFields })
    //if every single field is filled then submit happens:
    if (isFormValid) {
      // console.log('here');
      this.setDataIsSavedMessage()
      const dataState = {
        id: this.state.id,
        inn: localStorage.getItem('idUser'),
        name: this.state.name,
        surname: this.state.surname,
        thirdName: this.state.thirdName,
        gender: this.state.gender,
        tel: this.state.tel,
        email: this.state.email,
        position: this.state.position,
        access: this.state.access,
        alert: this.state.alert
      }

      if (!dataState.access) {
        Object.assign(dataState, { access: ['Нет доступа'] })
      }

      if (!dataState.alert) {
        Object.assign(dataState, { alert: [] })
      }

      await this.props.onSendData(dataState)
      setTimeout(() => {
        this.props.sendDataServer('employees', localStorage.getItem('idUser'), dataState)
      })

      this.setState({
        isSaved: true,
        isChanged: false
      })

      console.log(`ВЫБРАТЬ допуск сотрудника: `, dataState) // test
    }
  }

  handleChangeEmployeeData(e) {
    //here fialds are being validated, and set to new state:
    const newState = indicateEmptyFields(this.state, e)
    this.setState(newState)

    console.log(e.description)
    let data = this.state.resultData

    if (e.description) {
      this.setState({
        [e.description]: e.value,
        isChanged: true,
        isSaved: false
      })
      Object.assign(data, { [e.description]: e.value })
    } else {
      this.setState({
        [e.target.dataset.position]: e.target.value,
        isChanged: true,
        isSaved: false
      })

      Object.assign(data, { [e.target.dataset.position]: e.target.value })
    }

    if (!data.access) {
      Object.assign(data, { access: ['Нет доступа'] })
    }

    if (!data.alert) {
      Object.assign(data, { alert: [] })
    }

    this.setState({ resultData: data })
    this.props.data(data)

    this.checkFilledPositions()
  }

  emailValidate() {
    if (this.state.email) {
      const re = /^[\w]{1}[\w-.]*@[\w-]+\.[a-z]{2,4}$/i
      const valid = re.test(this.state.email)

      if (!valid) {
        this.setState({
          colorError: '#E87C86'
        })
        setTimeout(
          () =>
            this.setState({
              colorError: '#fff',
              email: ''
            }),
          1500
        )
      }
    }
  }

  doChangeValue(data) {
    // const {value} = e.target
    try {
      this.setState((prevState) => ({
        formData: {
          ...prevState,
          tel: data
        }
      }))
      this.setState({
        tel: data.value
      })
    } catch (err) {
      console.log(err)
    }
  }

  async componentDidMount() {
    await this.setState({
      id: this.props.resultData.id,
      inn: this.props.resultData.inn,
      name: this.props.resultData.name,
      surname: this.props.resultData.surname,
      thirdName: this.props.resultData.thirdName,
      gender: this.props.resultData.gender,
      tel: this.props.resultData.tel,
      email: this.props.resultData.email,
      position: this.props.resultData.position,
      access: this.props.resultData.access,
      alert: this.props.resultData.alert,
      show: true
    })
    if (!this.props.resultData.id) {
      this.setState({
        id: nanoid()
      })
    }

    // this.initializeFields()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.access !== this.props.access) {
      this.setState({
        access: this.props.access
      })
    }

    if (prevProps.alert !== this.props.alert) {
      this.setState({
        alert: this.props.alert
      })
    }
  }

  render() {
    console.log(this.state.requiredFields)
    return (
      <>
        <div className='shadow' onClick={this.openQuitModal}></div>
        <div className={'employees_modal_big z_index_19 p_40'}>
          {this.state.showQuit && (
            <QuitModal
              onQuitWithSave={this.handleClickQuitWithSave}
              onQuitWithoutSave={this.closeAllModals}
              closeQuitModal={() => this.setState({ showQuit: false })}
              modalTitle='Выход'
              modalBody='Сохранить изменения?'
              primaryButton='Сохранить'
              secondaryButton='Отменить'
            />
          )}
          {this.state.show ? (
            <Form onKeyUp={this.onKeyPressHandler}>
              <Alert
                variant='dark'
                className='header_alert_common'
                onClick={this.openQuitModal}
                dismissible
              >
                <Alert.Heading>Информация о сотруднике</Alert.Heading>
              </Alert>
              <div>
                <Container className='container_mt_2_mb_0' style={{ marginBottom: '73px' }}>
                  <Form.Group>
                    <Row> &nbsp;</Row>
                    <Form.Label className='employee_fio_label'>ФИО сотрудника</Form.Label>
                    <Form.Group className='employee_fio_group'>
                      <Row sm={9} className='employee_fio_row'>
                        <Col>
                          <Form.Control
                            id={1}
                            data-position='surname'
                            placeholder='Фамилия'
                            description='Сотрудники'
                            // value={this.state.surname}
                            value={this.state.surname}
                            onChange={this.handleChangeEmployeeData}
                            isInvalid={this.state.requiredFields.surname ? false : true}
                          />
                        </Col>

                        <Col>
                          <Form.Control
                            id={2}
                            data-position='name'
                            placeholder='Имя'
                            description='Сотрудники'
                            value={this.state.name}
                            onChange={this.handleChangeEmployeeData}
                            isInvalid={this.state.requiredFields.name ? false : true}
                          />
                        </Col>
                        {/* сomment */}
                        <Col>
                          <Form.Control
                            id={3}
                            data-position='thirdName'
                            placeholder='Отчество'
                            description='Сотрудники'
                            value={this.state.thirdName}
                            onChange={this.handleChangeEmployeeData}
                            isInvalid={this.state.requiredFields.thirdName ? false : true}
                          />
                        </Col>
                      </Row>
                      <Row className='employee_space'> &nbsp;</Row>
                      <Row>
                        {/* <Col> */}
                        <RadioButton
                          id={7}
                          width={2}
                          show={true}
                          title='Пол:'
                          label1='муж'
                          label2='жен'
                          // label3="Прочее"
                          placeholder='Пол'
                          description='gender'
                          // value={this.value[12] ? this.value[12].value : ''}
                          // value={this.props?.resultData?.access?.includes('Админ') ? 'Админ' : this.props?.resultData?.access?.includes('Прочее') ? 'Прочее' : 'Нет доступа'}
                          value={this.state.gender}
                          onChangeValue={this.handleChangeEmployeeData}
                          onChangeVisionBlock={this.doChangeVisionBlock}
                          // nameForm={this.state.nameForm}
                        />
                        {/* </Col> */}
                      </Row>
                      <Row> &nbsp;</Row>
                    </Form.Group>

                    {/* <Row className='employee_space'> &nbsp;</Row> */}
                    <Form.Label className='employee_contact_info_label'>
                      Контактная информация
                    </Form.Label>

                    <Form.Group className='employee_contact_info_row'>
                      <EmployeesPhoneForm
                        id={4}
                        width={12}
                        show={true}
                        label='Телефон'
                        placeholder='Телефон'
                        description='Сотрудники'
                        data-position='tel'
                        value={this.state.tel}
                        onChangeValue={this.doChangeValue}
                        // onChangeValue={this.handleChangeEmployeeData}
                        isInvalid={this.state.requiredFields.tel ? false : true}
                      />

                      <Form.Control
                        style={{ backgroundColor: this.state.colorError }}
                        id={5}
                        data-position='email'
                        placeholder='Email'
                        description='Сотрудники'
                        value={this.state.email}
                        onChange={this.handleChangeEmployeeData}
                        onBlur={this.emailValidate}
                        isInvalid={this.state.requiredFields.email ? false : true}
                      />

                      <Row className='employee_space'> &nbsp;</Row>
                    </Form.Group>

                    <Form.Group>
                      <Form.Label className='employee_position_label'>Должность</Form.Label>

                      <Form.Control
                        id={6}
                        data-position='position'
                        placeholder='Должность'
                        description='Сотрудники'
                        value={this.state.position}
                        onChange={this.handleChangeEmployeeData}
                        isInvalid={this.state.requiredFields.position ? false : true}
                      />

                      <Row className='employee_space'> &nbsp;</Row>
                    </Form.Group>
                  </Form.Group>
                </Container>
                <AllModalsFooter
                  isPosAbsoluteNedded={true}
                  footerContent={
                    <>
                      <ChooseAccessTypeButton
                        handleSaveClick={this.props.toggleOptionsModal}
                        needDisable={false}
                      />

                      <SaveCurrentDataButton
                        handleSaveClick={this.handleDataSave}
                        needDisable={false}
                        // disableCondition={this.checkFilledPositions() ? false : true}
                        savedDataMessage={this.state.dataIsSaved}
                        buttonTextDefault={'Сохранить'}
                        buttonTextSaved={'Данные сохранены'}
                        needCustomIcon={false}
                      />
                    </>
                  }
                />
              </div>
            </Form>
          ) : (
            ''
          )}
        </div>
      </>
    )
  }
}
