import React from "react";
import { Spinner } from "react-bootstrap";

//w={ширина}
//minH={минимальная высота}

export default function LoaderPage({minH, w}) {
  return <div
  style={{
    width: w,
    minHeight: minH,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }}
>
  <Spinner animation='border' variant='secondary' />
</div>;
}
