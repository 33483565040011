import InvertColorsOutlinedIcon from '@mui/icons-material/InvertColorsOutlined';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import FlareOutlinedIcon from '@mui/icons-material/FlareOutlined';
import DeviceThermostatOutlinedIcon from '@mui/icons-material/DeviceThermostatOutlined';
import BiotechOutlinedIcon from '@mui/icons-material/BiotechOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CommuteOutlinedIcon from '@mui/icons-material/CommuteOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import MovingOutlinedIcon from '@mui/icons-material/MovingOutlined';
import SettingsInputComponentOutlinedIcon from '@mui/icons-material/SettingsInputComponentOutlined';
import AlignVerticalBottomOutlinedIcon from '@mui/icons-material/AlignVerticalBottomOutlined';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import AltRouteOutlinedIcon from '@mui/icons-material/AltRouteOutlined';
import WbIridescentOutlinedIcon from '@mui/icons-material/WbIridescentOutlined';
import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import OutdoorGrillOutlinedIcon from '@mui/icons-material/OutdoorGrillOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import ExpandIcon from '@mui/icons-material/Expand';
import AllOutIcon from '@mui/icons-material/AllOut';
import EarbudsIcon from '@mui/icons-material/Earbuds';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import TuneIcon from '@mui/icons-material/Tune';
// import FlourescentIcon from '@mui/icons-material/Flourescent';
import FilterBAndWOutlinedIcon from '@mui/icons-material/FilterBAndWOutlined';
import HorizontalSplitOutlinedIcon from '@mui/icons-material/HorizontalSplitOutlined';
import LayersOutlinedIcon from '@mui/icons-material/LayersOutlined';

export const icons = [
    <ContactMailOutlinedIcon sx={{ fontSize: 30 }} />, //контактная инф
    <SettingsOutlinedIcon sx={{ fontSize: 30 }} />, //перечень оборудования на производственном предприятии
    <LocalGroceryStoreOutlinedIcon sx={{ fontSize: 30 }} />, //материалы
    <DesignServicesOutlinedIcon sx={{ fontSize: 30 }} />, //заготовительное производство
    <HandymanOutlinedIcon sx={{ fontSize: 30 }} />, //механическая обработка
    <MenuOpenIcon sx={{ fontSize: 30 }} />, //развальцовка
    <MiscellaneousServicesIcon sx={{ fontSize: 30 }} />, //комплектующие (гнутые трубы, днища, крепеж)
    <FlareOutlinedIcon sx={{ fontSize: 30 }} />, //сварка
    <DeviceThermostatOutlinedIcon sx={{ fontSize: 30 }} />, //термическая обработка
    <BiotechOutlinedIcon sx={{ fontSize: 30 }} />, //испытания
    <InvertColorsOutlinedIcon sx={{ fontSize: 30 }} />, //окраска поверхностей
    <ExpandIcon sx={{ fontSize: 30 }} />, //грузоподъемное оборудование
    <AssignmentOutlinedIcon sx={{ fontSize: 30 }} />, //разработка РКД
    <BorderColorOutlinedIcon sx={{ fontSize: 30 }} />, //система технического контроля
    <ScienceOutlinedIcon sx={{ fontSize: 30 }} />, //лаборатория контроля качества
    <CommuteOutlinedIcon sx={{ fontSize: 30 }} />, //грузоперевозки
    <InsertDriveFileOutlinedIcon sx={{ fontSize: 30 }} />, //разрешительная документация
    <MovingOutlinedIcon sx={{ fontSize: 30 }} />, //квалификация поставщика
    <SettingsInputComponentOutlinedIcon sx={{ fontSize: 30 }} />, //текущее состояние предприятия
    <AlignVerticalBottomOutlinedIcon sx={{ fontSize: 30 }} />, //профиль деятельности предприятия
    <AllOutIcon sx={{ fontSize: 30 }} />, //гнутые трубы, змеевки
    <CalendarViewWeekOutlinedIcon sx={{ fontSize: 30 }} />, //поставка днищ
    <EarbudsIcon sx={{ fontSize: 30 }} />, //линзовые компенсаторы
    <LinearScaleIcon sx={{ fontSize: 30 }} />, //фланцы и заготовки для фланцев
    <EarbudsIcon sx={{ fontSize: 30 }} />, //поковки
    <AltRouteOutlinedIcon sx={{ fontSize: 30 }} />, //отводы, перходы, тройники
    <TuneIcon sx={{ fontSize: 30 }} />, //метизы
    <WbIridescentOutlinedIcon sx={{ fontSize: 30 }} />, //вальцовка
    <InvertColorsOutlinedIcon sx={{ fontSize: 30 }} />, //окраска изделий
    <ContentCutOutlinedIcon sx={{ fontSize: 30 }} />, //услуги по резке и гибке металла
    <OutdoorGrillOutlinedIcon sx={{ fontSize: 30 }} />, //услуги по термической обработке
    <EngineeringOutlinedIcon sx={{ fontSize: 30 }} />,//поставка сварочных материалов
    <FilterBAndWOutlinedIcon sx={{ fontSize: 30 }} />, //поставка сортового проката
    <HorizontalSplitOutlinedIcon sx={{ fontSize: 30 }} />,//поставка трубного проката
    <LayersOutlinedIcon sx={{ fontSize: 30 }} />//поставка листового проката
];