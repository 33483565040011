/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import InputForm from './InputForm'
import ModalQuit from '../ModalQuit'
import ExpandIcon from '@mui/icons-material/Expand'
import CommonAlert from './CommonAlert'
import AllModalsFooter from '../assets/AllModalsFooter'
import { SaveCurrentDataButton } from '../assets/BasicButtons'
import { createData } from './functionsForm'
import { isEqual } from 'lodash-es'

export default class Form_Ten extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'rus',
      dataValue: [],
      maxId: 1,
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      show: false,
      showQuit: false,
      previousState: [],
      savedDataMessage: false,
      dataBeenSaved: false
    }

    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this)
    this.doEmpty = this.doEmpty.bind(this)

    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.hideContent = this.hideContent.bind(this)
    this.setCompanyName = this.setCompanyName.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
  }

  saveDataWithNotification() {
    this.handleClickSave()

    this.setState({
      savedDataMessage: true,
      dataBeenSaved: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }

  hideContent() {
    this.setState({ showContent: false })
  }

  setCompanyName() {
    if (this.props.companyName && this.props.companyName.length > 0) {
      this.setState({
        companyName: this.props.companyName
      })
    }
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })
  }

  //*Пустышка
  doEmpty() {}

  //* меняем видимость блока при выборе ДА / НЕТ
  doChangeVisionBlock(data) {}

  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  doQuitWithSave() {
    this.setState({ showQuit: false })
    this.handleClickSave()
    this.props.onChangeView('Ten')
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView('Ten')
  }

  //*формируем данные в массив объектов для отправки на сервер
  handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach((dataD) => {
        if (+dataD.id === i) {
          data[i] = { id: dataD.id, description: dataD.description, information: dataD.information, value: dataD.value }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach((dataE) => {
        if (+dataE.id === i) {
          data[i] = { id: dataE.id, description: dataE.description, information: dataE.information, value: dataE.value }
        }
      })
    }
    this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере
    if (this.state.lengthDataFromServer < 1) {
      console.log('CREATE DATA')
      this.props.onCreateDataServer(data, 'Ten', localStorage.getItem('idUser'))
    } else {
      console.log('UPDATE DATA')
      this.props.onUpdateDataOnServer(data, 'Ten', localStorage.getItem('idUser'))
    }
    setTimeout(() => {
      console.log(this.state.dataOnServer)
    }) //test
    // this.handleClickShadow();
  }

  //* данные с полей формы, формируются в массив объектов
  doChangeValue(data) {
    console.log(data) //test
    this.setState({ dataValue: [...this.state.dataValue, data] })
    this.setState({
      dataBeenSaved: false
    })
  }

  compare() {
    const prev = this.state.previousState
    const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)

    const isStateEqual = isEqual(prev, curr)
    if (isStateEqual) {
      return true
    }
    return false
  }

  smartWindowFunction() {
    const isStateEqual = this.compare()
    const { dataBeenSaved, dataValue } = this.state
    if (isStateEqual || dataBeenSaved || dataValue.length === 0) {
      this.props.onChangeView('Ten')
    } else {
      this.handleClickShadow()
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer })
      console.log('DATA on dataFromServer')
      console.log(this.props.data)
      // this.forceUpdate();
    }, 1000)

    this.setCompanyName()

    if (this.props.data.length) {
      const previousState = JSON.parse(JSON.stringify(this.props.data))
      this.setState({ previousState: this.props.data })
    }
  }

  render() {
    // console.log('RENDER', this.state.dataValue)

    const view = this.props.view
    this.value = this.props.data
    const viewQuest = this.props.viewQuest

    return (
      <>
        {this.state.showQuit ? <ModalQuit onQuitWithSave={this.doQuitWithSave} onQuitWithoutSave={this.doQuitWithoutSave} closeQuitModal={this.closeQuitModal} /> : ''}

        <div className='modal_window' style={{ display: view ? 'block' : 'none' }}>
          <div
            className={viewQuest ? 'shadow_form' : 'shadow_form_off'}
            // onClick={this.handleClickShadow}
            onClick={this.smartWindowFunction}
          ></div>
          <Form className={viewQuest ? 'form_main' : 'form_main_view'}>
            <CommonAlert
              // inReadMode={inReadMode}
              handleClickHeader={this.smartWindowFunction}
              // handleClickShadow={this.handleClickShadow}
              handleClickShadow={this.smartWindowFunction}
              hideContent={this.hideContent}
              icon={<ExpandIcon fontSize='large' className='icon_primary' />}
              companyName={this.state.companyName}
              sectionName='Грузоподъемное оборудование'
            />
            <div>
              <Container className='form_container'>
                <Row> &nbsp; </Row>
                <Form.Group>
                  <Row>
                    <Col>Максимальная грузоподъемность оборудования в производственных помещениях:</Col>
                  </Row>
                  <Row>
                    <InputForm
                      id={1}
                      width={12}
                      show={true}
                      // verify="number"
                      label=''
                      placeholder='Укажите максимальную грузоподъемность, тн'
                      description='Грузоподъемное оборудование'
                      value={this.value[1] ? this.value[1].value : ''}
                      onChangeValue={this.doChangeValue}
                    />
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row> &nbsp; </Row>
                </Form.Group>
              </Container>
              <AllModalsFooter
                footerContent={
                  //     <SaveButtonGreen
                  //     handleSaveClick={this.handleClickSave}
                  //     needDisable={false}
                  //     saveButtonText='Сохранить'
                  //   />

                  <SaveCurrentDataButton handleSaveClick={this.saveDataWithNotification} needDisable={false} savedDataMessage={this.state.savedDataMessage} buttonTextDefault={'Сохранить'} buttonTextSaved={'Данные сохранены'} needCustomIcon={false} />

                  // <Button variant="success" onClick={this.handleClickSave}>Сохранить</Button>
                }
              />
            </div>
          </Form>
        </div>
      </>
    )
  }
}
