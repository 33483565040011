export const PIPESSUPPLY = [{
    name: 'Поставка трубного проката',
    form: {
        dimension: ['Макс длина, мм', 'Макс наружный диаметр, мм', 'Мин наружный диаметр, мм', 'Макс толщ стенки, мм', 'Мин толщ стенки, мм']
    },
    normDoc: {
        gost: ['ГОСТ 10706', 'ТУ 14-3-624', 'ГОСТ 20295', 'ТУ 14-4Р-55', 'ТУ 14-3-460', 'ГОСТ 32528', 'ГОСТ 32678', 'ГОСТ 8731', 'ГОСТ 8733', 'ГОСТ 550', 'ТУ 14-3-1128', 'ТУ 14-3Р-1128', 'ТУ 14-159-1128', 'ТУ 14-3-1652', 'ТУ 14-3-Р-54', 'ТУ 14-3-460', 'ГОСТ 31447', 'ТУ 13-3Р-62', 'ТУ 14-3-457', 'ТУ 14-3-1905', 'ГОСТ 9940', 'ГОСТ 9941', 'ТУ 14-3-1322', 'ТУ 14-3-1323', 'ТУ 14-3-1596', 'ТУ 14-3-415', 'ГОСТ Р 56594', 'ТУ 14-3-396', 'ТУ 14-3-1348', 'ТУ 14-3-1357', 'ТУ 14-3-1024', 'ТУ 14-3-1391', 'ТУ 14-3-1401', 'ТУ 14-3-1339', 'ТУ 14-3-751', 'ТУ 14-3-694', 'ТУ 14-3-696', 'ТУ 14-3-1201', 'ТУ 14-3-763', 'ТУ 14-3-822', 'ТУ 14-3-949', 'ТУ 14-3-310', 'ТУ 1320-003-18648658']
    }
}]


export const PIPESSUPPLY_FOR_FILTER = [{
    name: 'Поставка трубного проката',
    form: {
        dimension: ['Длина, мм', 'Наружный диаметр, мм', 'Толщ стенки, мм']
    },
    normDoc: {
        gost: ['ГОСТ 10706', 'ТУ 14-3-624', 'ГОСТ 20295', 'ТУ 14-4Р-55', 'ТУ 14-3-460', 'ГОСТ 32528', 'ГОСТ 32678', 'ГОСТ 8731', 'ГОСТ 8733', 'ГОСТ 550', 'ТУ 14-3-1128', 'ТУ 14-3Р-1128', 'ТУ 14-159-1128', 'ТУ 14-3-1652', 'ТУ 14-3-Р-54', 'ТУ 14-3-460', 'ГОСТ 31447', 'ТУ 13-3Р-62', 'ТУ 14-3-457', 'ТУ 14-3-1905', 'ГОСТ 9940', 'ГОСТ 9941', 'ТУ 14-3-1322', 'ТУ 14-3-1323', 'ТУ 14-3-1596', 'ТУ 14-3-415', 'ГОСТ Р 56594', 'ТУ 14-3-396', 'ТУ 14-3-1348', 'ТУ 14-3-1357', 'ТУ 14-3-1024', 'ТУ 14-3-1391', 'ТУ 14-3-1401', 'ТУ 14-3-1339', 'ТУ 14-3-751', 'ТУ 14-3-694', 'ТУ 14-3-696', 'ТУ 14-3-1201', 'ТУ 14-3-763', 'ТУ 14-3-822', 'ТУ 14-3-949', 'ТУ 14-3-310', 'ТУ 1320-003-18648658']
    }
}]