/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import CheckForm from './CheckForm'
import Form_Five_Termo from './Form_Five_Termo'
import SizeForm from './SizeForm'
import { TERMO_PRODUCTION } from './ConstProduction'
import FormMaterList from './FormMaterList'
import ModalQuit from '../ModalQuit'
import SizeFormBig from './SizeFormBig'
import InputForm from './InputForm'
import TextareaForm from './TextareaForm'
import OutdoorGrillOutlinedIcon from '@mui/icons-material/OutdoorGrillOutlined'
import { HandleAllCheckBoxesBtn } from '../assets/HandleAllCheckboxesBtn'
import { nanoid } from 'nanoid'
import CommonAlert from './CommonAlert'
import AllModalsFooter from '../assets/AllModalsFooter'
import { MaterialsButton, SaveCurrentDataButton } from '../assets/BasicButtons'
import { isEqual } from 'lodash-es'
import { createData } from './functionsForm'

export default class FormHeatTreatment extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'rus',
      dataValue: [],
      id: 11,
      maxId: 21,
      nameForm: 'FormHeatTreatment',
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      base: [],
      show: false,
      heatTreatmentProducts: [false, false, false, false],
      idState: [2, 8, 14, 18],
      showQuit: false,
      selectCheck: true,
      subMaterials: true,
      showContent: true,
      previousState: [],
      savedDataMessage: false,
      dataBeenSaved: false
    }

    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.createBlockForm = this.createBlockForm.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this)
    this.onRunBlock = this.onRunBlock.bind(this)
    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)
    this.handleClickMaterieals = this.handleClickMaterieals.bind(this)
    this.handleCheck = this.handleCheck.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.hideContent = this.hideContent.bind(this)
    this.setCompanyName = this.setCompanyName.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
  }

  saveDataWithNotification() {
    this.handleClickSave()

    this.setState({
      savedDataMessage: true,
      dataBeenSaved: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }

  hideContent() {
    this.setState({ showContent: false })
  }

  setCompanyName() {
    if (this.props.companyName && this.props.companyName.length > 0) {
      this.setState({
        companyName: this.props.companyName
      })
    }
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })
  }

  async handleCheck() {
    await this.setState({
      selectCheck: !this.state.selectCheck,
      subMaterials: false
    })
    this.setState({
      subMaterials: true
    })
  }

  // handleAllChecked(e) {
  //     let arr = this.state.showAllCheckBoxes //массив отображения секции с неосновными чекбоксами (изначально они все true)
  //     const pos = Number(e.target.dataset.id) //берем dataset у элемента, на который кликаем
  //     arr[pos] = false //у массива с неосновными чекбоксами меняем одно значение, он становится таким: [false, true, true, ....]
  //     const posSet = this.state.selectCheck //массив конкретно самих чекбоксов секции неосновных чекбоксов (изначально они все true)
  //     posSet[pos] = !posSet[pos] //тоглим выбранную позицию у массива в конкретно самих чекбоксов с true на false
  //     // из всего вышеперечисленного получаем 2 массива: [false, true, true, ....] и [true, true, true...] тоглим на [false, false, false]
  //     this.setState({
  //         selectCheck: posSet,
  //         showAllCheckBoxes: arr
  //     })
  //     arr = this.state.showAllCheckBoxes; //отдаем масиву дефолтное значение
  //     setTimeout(() => {
  //         arr[pos] = true; // меняем позицию по setTimeout тк иначе он не успевает менять значения
  //         this.setState({
  //             showAllCheckBoxes: arr
  //         })
  //     })
  //     // то массив неосновных чекбоксов снова отображается
  // }

  //*Пустышка
  doEmpty() {}

  handleClickMaterieals() {
    this.props.onRunMaterials('Four_FormHeatTreatment')
  }

  //*включаем блоки материалов при загрузке
  onRunBlock(data) {
    data.forEach((elem) => {
      if (elem) this.doChangeVisionBlock(elem.value, +elem.id)
    })
  }

  doChangeVisionBlock(e, id) {
    let visibleState = this.state.heatTreatmentProducts
    let idState = this.state.idState
    idState.forEach((data, index) => {
      if (data === +id) visibleState[index] = e
    })
    this.setState({ heatTreatmentProducts: visibleState })
  }
  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  doQuitWithSave() {
    this.setState({ showQuit: false })
    this.handleClickSave()
    this.props.onChangeView('FormHeatTreatment')
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView('FormHeatTreatment')
  }

  //*формируем данные в массив объектов для отправки на сервер
  handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach((dataD) => {
        if (+dataD.id === i) {
          data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach((dataE) => {
        if (+dataE.id === i) {
          data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
        }
      })
    }
    this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере
    if (this.state.lengthDataFromServer < 1) {
      console.log('CREATE DATA')
      this.props.onCreateDataServer(data, 'FormHeatTreatment', localStorage.getItem('idUser'))
    } else {
      console.log('UPDATE DATA')
      this.props.onUpdateDataOnServer(data, 'FormHeatTreatment', localStorage.getItem('idUser'))
    }
    setTimeout(() => {
      console.log(this.state.dataOnServer)
    }) //test
    // this.handleClickShadow();
  }

  //* данные с полей формы, формируются в массив объектов
  doChangeValue(data) {
    this.setState({ dataValue: [...this.state.dataValue, data] })
    this.setState({
      dataBeenSaved: false
    })
  }

  //*создаем необходимое количество блоков
  createBlockForm() {}

  compare() {
    const prev = this.state.previousState
    const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)

    const isStateEqual = isEqual(prev, curr)
    if (isStateEqual) {
      return true
    }
    return false
  }

  smartWindowFunction() {
    const isStateEqual = this.compare()
    const { dataBeenSaved, dataValue } = this.state
    if (isStateEqual || dataBeenSaved || dataValue.length <= 1) {
      this.props.onChangeView(this.state.nameForm)
    } else {
      this.handleClickShadow()
    }
  }

  async componentDidMount() {
    await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer })
    await this.onRunBlock(this.props.data)

    this.setCompanyName()

    if (this.props.data.length) {
      const previousState = JSON.parse(JSON.stringify(this.props.data))
      this.setState({ previousState })
    }
  }

  render() {
    const view = this.props.view
    this.value = this.props.data
    const viewQuest = this.props.viewQuest
    const quenchSizes = ['Макс длина, мм', 'Макс ширина, мм', 'Макс высота, мм', 'Макс масса, тн']

    //условие по которому компонент определяет находится ли он в формате чтения
    const inReadMode = this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined

    // console.log(this.state.dataValue)

    return (
      <>
        {inReadMode && <>{this.state.showQuit ? <ModalQuit onQuitWithSave={this.doQuitWithSave} onQuitWithoutSave={this.doQuitWithoutSave} closeQuitModal={this.closeQuitModal} /> : ''}</>}

        {this.state.showContent && (
          <div className='modal_window' style={{ display: view ? 'block' : 'none' }}>
            <div
              className={viewQuest ? 'shadow_form' : 'shadow_form_off'}
              // onClick={this.handleClickShadow}
              onClick={this.smartWindowFunction}
            ></div>
            <Form className={viewQuest ? 'form_main' : 'form_main_view'}>
              <CommonAlert
                inReadMode={inReadMode}
                handleClickHeader={this.handleClickHeader}
                // handleClickShadow={this.handleClickShadow}
                handleClickShadow={this.smartWindowFunction}
                hideContent={this.hideContent}
                icon={<OutdoorGrillOutlinedIcon fontSize='large' className='icon_primary' />}
                companyName={this.state.companyName}
                sectionName='Термическая обработка'
              />
              <div>
                <Container className='form_container'>
                  <Form.Group>
                    <Row>Возможность термической обработки:</Row>
                    <Form.Group>
                      <Row>&nbsp;</Row>
                      <>{!this.value[1] && <HandleAllCheckBoxesBtn handleAllChecked={this.handleCheck} selectCheck={this.state.selectCheck} />}</>
                      <Row>
                        {this.state.subMaterials && (
                          <FormMaterList
                            name={nanoid()}
                            id={1}
                            width={4}
                            rowView={false}
                            materials={TERMO_PRODUCTION}
                            value={this.value[1] ? this.value[1].value : ''}
                            description='Термическая обработка'
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            selectCheck={this.state.selectCheck}
                            readOnly={!inReadMode}
                          />
                        )}
                      </Row>

                      <Row>&nbsp;</Row>
                      <Row>
                        <Col>Укажите максимальные габариты термообрабатываемых изделий:</Col>
                      </Row>

                      <Row>
                        <CheckForm
                          name={nanoid()}
                          id={2}
                          width={4}
                          show={true}
                          placeholder='Печная'
                          description='Собственная термическая обработка'
                          value={this.value[2] ? this.value[2].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doChangeVisionBlock}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                      <Form_Five_Termo id={3} show={this.state.heatTreatmentProducts[0]} description={'Собственная термическая обработка'} value={this.value} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!inReadMode} />
                      <Row>
                        <CheckForm
                          name={nanoid()}
                          id={8}
                          width={4}
                          show={true}
                          placeholder='Внепечная'
                          description='Собственная термическая обработка'
                          value={this.value[8] ? this.value[8].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doChangeVisionBlock}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                      <SizeForm
                        id={9}
                        width={2}
                        fontSize={'12px'}
                        show={this.state.heatTreatmentProducts[1]}
                        value={this.value}
                        description='Собственная термическая обработка'
                        placeholder={['Мах длина, мм', 'Мах ширина,мм', 'Мах высота, мм', 'Температура, t С']}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />

                      <Row>
                        <CheckForm
                          name={nanoid()}
                          id={14}
                          width={4}
                          show={true}
                          placeholder='Закалочные ванны'
                          description='Собственная термическая обработка'
                          value={this.value[14] ? this.value[14].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doChangeVisionBlock}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>

                      {this.state.heatTreatmentProducts[2] && (
                        <>
                          <SizeFormBig
                            id={15}
                            width={2}
                            show={true}
                            verify='number'
                            fontSize={'12px'}
                            placeholder={quenchSizes}
                            description='Собственная термическая обработка'
                            value={this.value}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.props.nameForm}
                            readOnly={!inReadMode}
                          />
                          <Row>&nbsp;</Row>
                          <Row>
                            <Col>Охлаждающие жидкости:</Col>
                          </Row>

                          <Row>
                            <CheckForm
                              name={nanoid()}
                              id={16}
                              width={6}
                              show={true}
                              placeholder='Вода'
                              description='Собственная термическая обработка'
                              value={this.value[16] && this.value[16].value}
                              onChangeValue={this.doChangeValue}
                              onChangeVisionBlock={this.doEmpty}
                              nameForm={this.state.nameForm}
                              readOnly={!inReadMode}
                            />
                          </Row>

                          <Row>
                            <CheckForm
                              name={nanoid()}
                              id={17}
                              width={6}
                              show={true}
                              placeholder='Масло'
                              description='Собственная термическая обработка'
                              value={this.value[17] && this.value[17].value}
                              onChangeValue={this.doChangeValue}
                              onChangeVisionBlock={this.doEmpty}
                              nameForm={this.state.nameForm}
                              readOnly={!inReadMode}
                            />
                          </Row>

                          <Row>
                            <CheckForm
                              name={nanoid()}
                              id={18}
                              width={6}
                              show={true}
                              placeholder='Прочее'
                              description='Собственная термическая обработка'
                              value={this.value[18] && this.value[18].value}
                              onChangeValue={this.doChangeValue}
                              onChangeVisionBlock={this.doChangeVisionBlock}
                              nameForm={this.state.nameForm}
                              readOnly={!inReadMode}
                            />
                          </Row>

                          {this.state.heatTreatmentProducts[3] && (
                            <InputForm
                              id={19}
                              width={6}
                              show={true}
                              // verify="number"
                              label={''}
                              fontSize={12}
                              placeholder='Прочее:'
                              description={this.props.description}
                              value={this.value[19] ? this.value[19].value : ''}
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                              readOnly={!inReadMode}
                            />
                          )}
                        </>
                      )}
                    </Form.Group>

                    <Form.Group>
                      {inReadMode && (
                        <>
                          <Row> &nbsp;</Row>
                          <Row> &nbsp;</Row>
                          <InputForm
                            id={20}
                            width={12}
                            show={true}
                            // verify="number"
                            label={''}
                            fontSize={12}
                            placeholder='Комментарий:'
                            description={this.props.description}
                            value={this.value[20] ? this.value[20].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                          />

                          <TextareaForm
                            id={21}
                            width={12}
                            label=''
                            description='Дополнения к данному разделу'
                            placeholder='Дополнительная информация по данному виду деятельности (оборудование, технологии, автоматизация и др.'
                            value={this.value[21] ? this.value[21].value : ''}
                            show={true}
                            rows={3}
                            onChangeValue={this.doChangeValue}
                          />
                        </>
                      )}
                      <Row> &nbsp;</Row>
                    </Form.Group>
                  </Form.Group>
                </Container>
                <AllModalsFooter
                  footerContent={
                    <>
                      {/* <Button variant="primary" onClick={this.handleClickMaterieals} >Используемые материалы</Button> */}
                      <MaterialsButton handleSaveClick={this.handleClickMaterieals} needDisable={false} />
                      {inReadMode && (
                        // <Button variant="success" onClick={this.handleClickSave}> Сохранить</Button>
                        // <SaveButtonGreen handleSaveClick={this.handleClickSave} needDisable={false} saveButtonText='Сохранить' />
                        <SaveCurrentDataButton
                          handleSaveClick={this.saveDataWithNotification}
                          needDisable={false}
                          savedDataMessage={this.state.savedDataMessage}
                          buttonTextDefault={'Сохранить'}
                          buttonTextSaved={'Данные сохранены'}
                          needCustomIcon={false}
                        />
                      )}
                    </>
                  }
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}
