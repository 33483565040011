import React from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import ModalQuit from '../ModalQuit'
import TextareaForm from './TextareaForm'
import InputForm from './InputForm'
import { LONGPRODUCTS } from './ConstLongProducts'
import CheckForm from './CheckForm'
import FormRolledDimensions from './FormRolledDimensions'
import FilterBAndWOutlinedIcon from '@mui/icons-material/FilterBAndWOutlined'
import { nanoid } from 'nanoid'
import CommonAlert from './CommonAlert'
import AllModalsFooter from '../assets/AllModalsFooter'
import { MaterialsButton, SaveCurrentDataButton } from '../assets/BasicButtons'
import { createData } from './functionsForm'
import { isEqual } from 'lodash-es'

export default class FormRolled extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      show: true,
      nameForm: 'FormRolled',
      maxId: 211,
      dataValue: [],
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      showQuit: false,
      base: [],
      baseForm: [],
      showGost: [false, false, false, false, false, false, false],
      showContent: true,
      inReadMode: this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined,
      previousState: [],
      savedDataMessage: false,
      dataBeenSaved: false
    }
    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.handleClickMaterieals = this.handleClickMaterieals.bind(this)

    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)

    this.renderDataConst = this.renderDataConst.bind(this)
    this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.hideContent = this.hideContent.bind(this)
    this.setCompanyName = this.setCompanyName.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
  }

  saveDataWithNotification() {
    this.handleClickSave()

    this.setState({
      savedDataMessage: true,
      dataBeenSaved: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }

  hideContent() {
    this.setState({ showContent: false })
  }

  setCompanyName() {
    if (this.props.companyName && this.props.companyName.length > 0) {
      this.setState({
        companyName: this.props.companyName
      })
    }
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })
  }

  handleClickMaterieals() {
    this.props.onRunMaterials('Four_FormRolled')
  }

  //* empty
  doEmpty() {}

  async doChangeVisionBlock(e, id) {
    // console.log(`CHECK -> CLICK -> ${e} - ${id}`); // e = true/false

    const position = [1, 31, 61, 91, 121, 151, 181].indexOf(Number(id))
    let showPosition = this.state.showGost
    showPosition[position] = e

    await this.setState({ showGost: showPosition })
    // console.log(this.state.showGost);//test

    this.renderDataConst(LONGPRODUCTS)
  }

  //*рендер ГОСТ (кореные данные)
  async renderDataConst(DATA) {
    await this.setState({ maxId: DATA.length * 30 + 1 })

    this.dataBase = DATA.map((data, index) => {
      return (
        <>
          <React.Fragment key={index * 30 + 1}>
            <CheckForm
              name={nanoid()}
              id={index * 30 + 1}
              width={4}
              show={true}
              placeholder={this.state.showGost[index] ? <b>{data.name}</b> : <>{data.name}</>} //* подпись LABEL для чекбокса
              description={'Сортовой прокат'}
              value={this.value[index * 30 + 1] ? this.value[index * 30 + 1].value : false} //* при первом запуске - false
              onChangeVisionBlock={this.doChangeVisionBlock}
              onChangeValue={this.doChangeValue}
              nameForm={this.state.nameForm}
              readOnly={!this.state.inReadMode}
            />

            {this.state.showGost[index] ? (
              <FormRolledDimensions id={index * 30 + 2} arrayConst={LONGPRODUCTS[index]} description={'Сортовой прокат'} onChangeValue={this.doChangeValue} value={this.value} nameForm={this.state.nameForm} readOnly={!this.state.inReadMode} />
            ) : (
              ''
            )}
            {this.state.showGost[index] ? <Row className='border_bottom_underline'> &nbsp; </Row> : ''}
          </React.Fragment>
        </>
      )
    })

    this.setState({
      base: this.dataBase,
      show: true
    })
  }

  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  doQuitWithSave() {
    this.setState({ showQuit: false })
    this.handleClickSave()
    this.props.onChangeView(this.state.nameForm)
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView(this.state.nameForm)
  }

  //*формируем данные в массив объектов для отправки на сервер
  handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach((dataD) => {
        if (+dataD.id === i) {
          data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach((dataE) => {
        if (+dataE.id === i) {
          data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
        }
      })
    }
    this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере
    if (this.state.lengthDataFromServer < 1) {
      console.log('CREATE DATA')
      this.props.onCreateDataServer(data, this.state.nameForm, localStorage.getItem('idUser'))
    } else {
      console.log('UPDATE DATA')
      this.props.onUpdateDataOnServer(data, this.state.nameForm, localStorage.getItem('idUser'))
    }
    setTimeout(() => {
      console.log(this.state.dataOnServer)
    }) //test
    // this.handleClickShadow();
  }

  //* данные с полей формы, формируются в массив объектов
  async doChangeValue(data) {
    console.log(data) //test
    await this.setState({ dataValue: [...this.state.dataValue, data] })
    this.setState({
      dataBeenSaved: false
    })

    // this.checkOnList(); //! отключен
  }

  compare() {
    const prev = this.state.previousState
    const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)

    const isStateEqual = isEqual(prev, curr)
    if (isStateEqual) {
      return true
    }
    return false
  }

  smartWindowFunction() {
    const isStateEqual = this.compare()
    const { dataBeenSaved, dataValue } = this.state
    if (isStateEqual || dataBeenSaved || dataValue.length === 0) {
      this.props.onChangeView(this.state.nameForm)
    } else {
      this.handleClickShadow()
    }
  }

  async componentDidMount() {
    await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer })
    this.props.data.forEach((data, index) => {
      this.doChangeVisionBlock(data.value, index)
    })
    this.renderDataConst(LONGPRODUCTS)
    this.setCompanyName()

    if (this.props.data.length) {
      const previousState = JSON.parse(JSON.stringify(this.props.data))
      this.setState({ previousState })
    }
  }

  render() {
    const view = this.props.view
    this.value = this.props.data
    const viewQuest = this.props.viewQuest

    //условие по которому компонент определяет находится ли он в формате чтения
    const inReadMode = this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined

    console.log(this.state.dataValue)

    return (
      <>
        {inReadMode && <>{this.state.showQuit ? <ModalQuit onQuitWithSave={this.doQuitWithSave} onQuitWithoutSave={this.doQuitWithoutSave} closeQuitModal={this.closeQuitModal} /> : ''}</>}

        {this.state.showContent && (
          <div className='modal_window' style={{ display: view ? 'block' : 'none' }}>
            <div
              className={viewQuest ? 'shadow_form' : 'shadow_form_off'}
              // onClick={this.handleClickShadow}
              onClick={this.smartWindowFunction}
            ></div>
            <Form className={viewQuest ? 'form_main' : 'form_main_view'}>
              <CommonAlert
                inReadMode={inReadMode}
                handleClickHeader={this.handleClickHeader}
                // handleClickShadow={this.handleClickShadow}
                handleClickShadow={this.smartWindowFunction}
                hideContent={this.hideContent}
                icon={<FilterBAndWOutlinedIcon fontSize='large' className='icon_primary' />}
                companyName={this.state.companyName}
                sectionName='Поставка сортового проката'
              />
              <div>
                <Container className='form_container'>
                  <Row>
                    <Col>Форма сортового и фассонного проката</Col>
                  </Row>

                  <Form.Group>{this.state.show ? this.state.base : ''}</Form.Group>

                  <Row> &nbsp;</Row>
                  <Form.Group>
                    {inReadMode && (
                      <>
                        {' '}
                        {this.state.show ? (
                          <>
                            <InputForm
                              id={210}
                              width={12}
                              show={true}
                              // verify="number"
                              label={''}
                              fontSize={12}
                              placeholder='Комментарий:'
                              description={this.props.description}
                              value={this.value[210] ? this.value[210].value : ''}
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                            />

                            <TextareaForm
                              id={211}
                              width={12}
                              label=''
                              description='Дополнения к данному разделу'
                              placeholder='Дополнительная информация по данному виду деятельности (оборудование, технологии, автоматизация и др.'
                              value={this.value[211] ? this.value[211].value : ''}
                              show={true}
                              rows={3}
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                            />
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                    <Row>&nbsp;</Row>
                  </Form.Group>
                  <Row>&nbsp;</Row>
                </Container>
                <AllModalsFooter
                  footerContent={
                    <>
                      <MaterialsButton handleSaveClick={this.handleClickMaterieals} needDisable={false} />
                      {/* <Button variant="primary" onClick={this.handleClickMaterieals} >Используемые материалы</Button> */}
                      {inReadMode && (
                        // <Button variant="success" onClick={this.handleClickSave} >Сохранить</Button>
                        //     <SaveButtonGreen
                        //     handleSaveClick={this.handleClickSave}
                        //     needDisable={false}
                        //     saveButtonText='Сохранить'
                        //   />
                        <SaveCurrentDataButton
                          handleSaveClick={this.saveDataWithNotification}
                          needDisable={false}
                          savedDataMessage={this.state.savedDataMessage}
                          buttonTextDefault={'Сохранить'}
                          buttonTextSaved={'Данные сохранены'}
                          needCustomIcon={false}
                        />
                      )}
                    </>
                  }
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}
