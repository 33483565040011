/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Container, Form, Row, Button, Col } from 'react-bootstrap'
import { MECH_PRODUCTION } from './ConstProduction'
import CheckForm from './CheckForm'
import SizeFormBig from './SizeFormBig'
import InputForm from './InputForm'
import RadioButtonForm from './RadioButtonForm'
import ModalQuit from '../ModalQuit'
import TextareaForm from './TextareaForm'
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined'
import { nanoid } from 'nanoid'
import CommonAlert from './CommonAlert'
import AllModalsFooter from '../assets/AllModalsFooter'
import { MaterialsButton, SaveButtonGreen, SaveCurrentDataButton } from '../assets/BasicButtons'
import { createData } from './functionsForm'
import { isEqual } from 'lodash-es'

export default class Form_Two extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'rus',
      dataValue: [],
      id: 1,
      nameForm: 'Two',
      maxId: 32,
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      base: [],
      sizes: {
        0: ['Макс диаметр, мм', 'Макс длина, мм', 'Макс масса заготовки, тн'],
        1: ['Макс диаметр, мм', 'Макс высота, мм', 'Макс масса заготовки, тн'],
        2: ['Макс диаметр отверстия, мм', 'Мекс длина заготовки, мм', 'Макс ширина заготовки, мм', 'Макс высота заготовки, мм', 'Макс толщ заготовки, мм', 'Макс масса заготовки, тн'],
        3: ['Макс длина, мм', 'Макс высота, мм', 'Макс ширина, мм', 'Макс масса заготовки, тн'],
        4: ['Макс длина, мм', 'Макс высота, мм', 'Макс ширина, мм', 'Макс масса заготовки, тн']
      },
      show: [false, false, false, false, false],
      showRender: true,
      showQuit: false,
      showContent: true,
      inReadMode: true,
      previousState: [],
      savedDataMessage: false,
      dataBeenSaved: false
    }

    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this)
    this.renderCheckData = this.renderCheckData.bind(this)
    this.runRenderData = this.runRenderData.bind(this)
    this.doEmpty = this.doEmpty.bind(this)

    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)
    this.handleClickMaterieals = this.handleClickMaterieals.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.setCompanyName = this.setCompanyName.bind(this)
    this.hideContent = this.hideContent.bind(this)

    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
  }

  saveDataWithNotification() {
    this.handleClickSave()

    this.setState({
      savedDataMessage: true,
      dataBeenSaved: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }

  hideContent() {
    this.setState({ showContent: false })
  }

  setCompanyName() {
    if (this.props.companyName && this.props.companyName.length > 0) {
      this.setState({
        companyName: this.props.companyName
      })
    }
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })
  }
  //*empty
  doEmpty() {}

  handleClickMaterieals() {
    this.props.onRunMaterials('Four_Two')
  }

  doChangeVisionBlock(data, id) {
    this.show = this.state.show
    // console.log(data);//test
    // console.log(id);//test
    if (+id === 1) this.show[0] = data
    if (+id === 6) this.show[1] = data
    if (+id === 11) this.show[2] = data
    if (+id === 19) this.show[3] = data
    if (+id === 25) this.show[4] = data
    this.setState({ show: this.show })
    setTimeout(() => {
      this.renderCheckData()
    })
  }

  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  doQuitWithSave() {
    this.setState({ showQuit: false })
    this.handleClickSave()
    this.props.onChangeView('Two')
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView('Two')
  }

  //*формируем данные в массив объектов для отправки на сервер
  handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach((dataD) => {
        if (+dataD.id === i) {
          data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach((dataE) => {
        if (+dataE.id === i) {
          data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
        }
      })
    }
    this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере
    if (this.state.lengthDataFromServer < 1) {
      console.log('CREATE DATA')
      this.props.onCreateDataServer(data, 'Two', localStorage.getItem('idUser'))
    } else {
      console.log('UPDATE DATA')
      this.props.onUpdateDataOnServer(data, 'Two', localStorage.getItem('idUser'))
    }
    setTimeout(() => {
      console.log(this.state.dataOnServer)
    }) //test
    // this.handleClickShadow();
  }

  //* данные с полей формы, формируются в массив объектов
  doChangeValue(data) {
    // console.log(data) //test
    this.setState({ dataValue: [...this.state.dataValue, data] })
    this.setState({
      dataBeenSaved: false
    })
  }

  renderCheckData() {
    this.dataD = []
    this.idRealy = this.state.id
    this.sizes = this.state.sizes
    this.show = this.state.show

    MECH_PRODUCTION.forEach((data, index) => {
      this.dataD = [
        ...this.dataD,
        <React.Fragment key={+this.id + index}>
          <CheckForm
            name={nanoid()}
            id={+this.idRealy}
            width={4}
            show={!this.state.inReadMode && !this.value[+this.idRealy] ? false : true}
            placeholder={data}
            description='Механическая обработка'
            value={this.value[+this.idRealy] ? this.value[+this.idRealy].value : ''}
            onChangeValue={this.doChangeValue}
            onChangeVisionBlock={this.doChangeVisionBlock}
            nameForm={this.state.nameForm}
            readOnly={!this.state.inReadMode}
          />

          {this.show[index] ? (
            <>
              <Form.Group>
                <Row>
                  <RadioButtonForm
                    id={+this.idRealy + 1}
                    width={12}
                    show={true}
                    title='Обработка ЧПУ? '
                    label1='Да'
                    label2='Нет'
                    placeholder='Обработка ЧПУ'
                    description='Обработка ЧПУ'
                    value={this.value[2] ? this.value[2].value : ''}
                    onChangeValue={this.doChangeValue}
                    onChangeVisionBlock={this.doEmpty}
                    nameForm={this.state.nameForm}
                    readOnly={!this.state.inReadMode}
                  />
                </Row>
              </Form.Group>
              <SizeFormBig
                id={+this.idRealy + 2}
                width={2}
                fontSize={'12px'}
                show={!this.show[index] || (!this.state.inReadMode && !this.value) ? false : true}
                value={this.value}
                description='Механическая обработка'
                placeholder={this.sizes[index]}
                onChangeValue={this.doChangeValue}
                nameForm={this.state.nameForm}
                readOnly={!this.state.inReadMode}
              />
            </>
          ) : (
            ''
          )}
        </React.Fragment>
      ]
      this.idRealy = this.idRealy + 2 + this.sizes[index].length
    })
    this.setState({ base: this.dataD })
  }

  runRenderData() {
    this.props.data.forEach((elem) => {
      this.doChangeVisionBlock(elem.value, elem.id)
      // console.log(elem.value + ' ==== ' + elem.id);//test
    })
    setTimeout(() => {
      this.renderCheckData()
    })
  }

  async compare() {
    const prev = await this.state.previousState
    const curr = await createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)

    const isStateEqual = isEqual(prev, curr)

    // console.log('prev', prev);
    // console.log('curr', curr);
    // console.log('is equal?', isStateEqual);

    if (isStateEqual) {
      return true
    }
    return false
  }

  async smartWindowFunction() {
    const isStateEqual = await this.compare()
    const { dataBeenSaved, dataValue } = this.state
    if (isStateEqual || dataBeenSaved || dataValue.length === 0) {
      this.props.onChangeView('Two')
    } else {
      this.handleClickShadow()
    }
  }

  async componentDidMount() {
    // if (!this.props.data && this.props.data.length === 0) {
    //   this.handleClickSave()
    // }
    // setTimeout(() => {
    await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer })
    // }, 500)

    setTimeout(() => {
      this.runRenderData()
    }, 1000)

    this.setState({
      inReadMode: this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined
    })
    await this.setCompanyName()

    if (this.props.data.length) {
      // const previousState = JSON.parse(JSON.stringify(this.props.data))
      // const previousState = Object.assign({}, this.props.data)
      this.setState({ previousState: this.props.data })
    }
  }

  render() {
    // console.log('RENDER', this.state.dataValue);//test

    this.id = this.state.id
    const view = this.props.view
    this.value = this.props.data
    const viewQuest = this.props.viewQuest

    //условие по которому компонент определяет находится ли он в формате чтения
    const inReadMode = this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined

    return (
      <>
        {inReadMode && <>{this.state.showQuit ? <ModalQuit onQuitWithSave={this.doQuitWithSave} onQuitWithoutSave={this.doQuitWithoutSave} closeQuitModal={this.closeQuitModal} /> : ''}</>}

        {this.state.showContent && (
          <div className='modal_window' style={{ display: view ? 'block' : 'none' }}>
            <div
              className={viewQuest ? 'shadow_form' : 'shadow_form_off'}
              // onClick={this.handleClickShadow}
              onClick={this.smartWindowFunction}
            ></div>
            <Form className={viewQuest ? 'form_main' : 'form_main_view'}>
              <CommonAlert
                inReadMode={inReadMode}
                handleClickHeader={this.handleClickHeader}
                // handleClickShadow={this.handleClickShadow}
                handleClickShadow={this.smartWindowFunction}
                hideContent={this.hideContent}
                icon={<HandymanOutlinedIcon fontSize='large' className='icon_primary' />}
                companyName={this.state.companyName}
                sectionName='Механическая обработка'
              />
              <div>
                <Container className='form_container'>
                  <Row>
                    <Col>Максимальные размеры обрабатываемой детали:</Col>
                  </Row>

                  <Form.Group>{this.state.showRender ? this.state.base : ''}</Form.Group>

                  <Form.Group>
                    {inReadMode && (
                      <>
                        <Row>&nbsp;</Row>
                        <Row> &nbsp;</Row>
                        <InputForm
                          id={31}
                          width={12}
                          show={true}
                          // verify="number"
                          label=''
                          placeholder='Добавить вид механической обработки'
                          description='Механическая обработка'
                          value={this.value[31] ? this.value[31].value : ''}
                          // value={this.value[13].value}
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                        />

                        <TextareaForm
                          id={32}
                          width={12}
                          label=''
                          description='Дополнения к данному разделу'
                          placeholder='Дополнительная информация по данному виду деятельности (оборудование, технологии, автоматизация и др.'
                          value={this.value[32] ? this.value[32].value : ''}
                          show={true}
                          rows={3}
                          onChangeValue={this.doChangeValue}
                        />
                      </>
                    )}
                    <Row>&nbsp;</Row>
                    <Row>&nbsp;</Row>
                  </Form.Group>
                </Container>

                <AllModalsFooter
                  footerContent={
                    <>
                      <MaterialsButton handleSaveClick={this.handleClickMaterieals} needDisable={false} />
                      {/* <Button variant="primary" onClick={this.handleClickMaterieals} >Используемые материалы</Button> */}
                      {
                        inReadMode && (
                          // <SaveButtonGreen handleSaveClick={this.handleClickSave} needDisable={false} saveButtonText='Сохранить' />
                          <SaveCurrentDataButton
                            handleSaveClick={this.saveDataWithNotification}
                            needDisable={false}
                            savedDataMessage={this.state.savedDataMessage}
                            buttonTextDefault={'Сохранить'}
                            buttonTextSaved={'Данные сохранены'}
                            needCustomIcon={false}
                          />
                        )

                        // <Button variant="success" onClick={this.handleClickSave}>Сохранить</Button>
                      }
                    </>
                  }
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}
