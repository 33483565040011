

/**
 * Сравнивает два массива значений TOTAL
 * @param {Array} tableOld Значение созданное при запуске компонента
 * @param {Array} tableNew Значение полученное спустя время
 * @returns {Boolean} true - нет изменений / false - есть изменения
 */
const compareTradeTables = (tableOld, tableNew) => {
    const resultCompare = tableOld.map(itm => {
        const result = tableNew.find(item => item.companyInn === item.companyInn && item.total === itm.total)

        if (result) {
            return true
        } else {
            return false
        }
    })
    const compare = resultCompare.includes(false)
    // console.log(`array compare::: `, resultCompare); // test

    if (!compare) {
        return true
    } else {
        return false
    }
}

export default compareTradeTables;