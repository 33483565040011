// import parseDate from '../assets/parseDate'
import Server from '../server/server'

const getWeight = async (arrObj) => {

    const arrInn = arrObj.map(item => {
        return item.inn
    })
    try {
        return (async () => {
            const promiseCapacity = await arrInn.map(inn => {
                const result = Server.getDataFromServer('requiredWeight', inn)

                return result
            })

            const arrCapacity = await Promise.allSettled(promiseCapacity)

            const resultArr = arrCapacity.map(item => {
            // console.log(item)
                return (
                    item?.value ? item.value.weightValue : 'Не указана'
                )
            })

            // console.log(`RESULT capacity::: `, resultArr); // test

            return resultArr
        })()
    } catch (err) {
        console.log(`Ошибка получение данных о загрузке предприятия: `, err);
        return {}
    }



}

export default getWeight;