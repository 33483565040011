import Server from '../server/server'


/**
 * Загружает Объект с новыми занчениями торгов (trade_table_new)
 * @param {String} id ID Обновленного объекта торгов с новыми занчениями (trade_table_new)
 * @returns 
 */
const loadNewTradeTable = async (id) => {
    try {
        // console.log(`ID >>>>>>>>>>>> `, id); //test
        const result = await Server.getDataFromServer(id, 'trade_table_new')

        if (result) {
            // console.log(`result::: `, result); // test
            return result
        } else {
            return null
        }

    } catch (err) {
        console.log(`Ошибка при загрузке новых данных торгов: `, err);
    }
}

export default loadNewTradeTable;