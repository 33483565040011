import React from 'react';
import { Row } from 'react-bootstrap';
import SizeFormBig from './SizeFormBig';
import CheckForm from './CheckForm';

export default class FormForgingsDimensions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            showBase: true,
            showSize: [],
            base: [],
            showSquare: false,
            readOnly: false // false - поле включено / true - выключено
        }

        this.doChangeValue = this.doChangeValue.bind(this);
        this.renderDataForm = this.renderDataForm.bind(this);
        this.doEnpty = this.doEnpty.bind(this);
        this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this);
    }
    doEnpty() {
        //пустая функция
    }

    async doChangeVisionBlock(e, id) {
        console.log(`DATA click - ${e} - ${id}`);

        if (e) {
            await this.setState({ showSize: [...this.state.showSize, id] })

        } else {
            let arr = this.state.showSize;
            arr.splice(arr.indexOf(id), 1);
            await this.setState({ showSize: arr });
        }
        console.log('ARRAY -> ', this.state.showSize);
        this.renderDataForm(this.props.forgings, this.props.id);
    }

    doChangeValue(e) {
        this.props.onChangeValue(e);
    }

    //* рендер размеры из массива
    renderDataForm(DATA, id) {
        console.log(`DATA `, DATA);//test
        this.formName = ['Прямоугольная', 'Круглая', 'Кольцо', 'Диск'];
        this.baseData = ['square', 'round', 'ring', 'disk'].map((data, index) => {
            return (
                <React.Fragment key={+id + index * 6}>
                    <Row> &nbsp; </Row>
                    {index === 0 ? 'Форма поковок:' : ''}

                    <CheckForm
                        name={`${this.props.name}_${id}_${index * 6}`}
                        id={+id + index * 6}
                        width={12}
                        show={true}
                        placeholder={this.formName[index]}
                        description={`форма: ${this.formName[index]}, по ${DATA.name}`}
                        value={this.value[+id + index] ? this.value[+id + index].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.props.nameForm}
                        readOnly={this.props.readOnly}
                    />
                    {(this.state.showSize.includes(String(+id + index * 6))) ?
                        <SizeFormBig
                            id={+id + 1 + index * 6}
                            width={2}
                            fontSize={'12px'}
                            show={true}
                            value={this.value}
                            description={`Поковки по: ${DATA.name}, форма: ${data}`}
                            placeholder={DATA.form[data]}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.props.nameForm}
                            readOnly={this.state.readOnly}
                            // readOnly1={this.props.readOnly} //в режиме чтения, когда пользователь просматривает данные о компании в поиске
                        /> : ''}
                </React.Fragment>
            )
        })

        this.setState({
            base: this.baseData
        })
    }
    componentDidMount() {
        this.renderDataForm(this.props.forgings, this.props.id);
    }

    render() {

        // const show = this.props.show;
        this.value = this.props.value;

        const showBase = this.state.showBase;

        return (
            <>
                {showBase ? this.state.base : ''}
                <Row className='border_bottom_underline' > &nbsp; </Row>
            </>
        )
    }
}