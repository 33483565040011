import React from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';

// toggleModal = {закрывает модалку на крестик}
// alertHeading = {заглавие модалки}
// modalContent = {содержимое модалки, все что внутри}

/**
 * Выбор данных для редактирования
 * @param {*} param0 
 * @returns 
 */
const ModalSelectCardEdit = ({ toggleModal, alertHeading, doEditCardEquipment, doLoadFiles, doShowEquipmentInUse, doShowRosTechReg }) => {

    return (
        <>
            <div className="shadow_form" onClick={toggleModal} ></div>
            <div className="modal_common_2 z_index_1000" style={{width: '45%', minWidth: '700px'}}>
                <Alert variant="dark" onClose={toggleModal} dismissible >
                    <Alert.Heading >
                        {alertHeading}
                    </Alert.Heading>
                </Alert>
                <Container style={{minHeight: '300px', height: '35vh', overflowY: 'auto', display: 'flex', flexDirection: 'column', gap: '15px', padding: '0px 50px 0px 50px', alignItems: 'center', marginTop: '50px'}}>

                    {new Array}

                        <Button
                            onClick={doEditCardEquipment}
                            style={{width: '85%'}}
                        >
                            <EditOutlinedIcon/> Редактирование карточки аппарата
                        </Button>

                        <Button
                            onClick={doLoadFiles}
                            style={{width: '85%'}}
                        >
                          <DescriptionOutlinedIcon/>  Файлы документации по аппарату
                        </Button>

                        <Button
                            onClick={doShowRosTechReg}
                            style={{width: '85%'}}
                        >
                           <HomeWorkOutlinedIcon/> Обновить регистрацию в Ростехнадзоре
                           {/* Редактирование данных по регистрации в Ростехнадзоре */}
                           
                        </Button>

                        <Button
                            onClick={doShowEquipmentInUse}
                            style={{width: '85%'}}
                        >
                            <TroubleshootIcon/> Контроль работы аппарата
                        </Button>


                </Container>
            </div>
        </>
    )
}
export default ModalSelectCardEdit;