import React from 'react';
import CheckForm from './CheckForm';

export default class Form_Four_Mater extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            materials: [],
            id: 1,
            base: []
        }

        this.renderListMaterials = this.renderListMaterials.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
        this.doChangeValue = this.doChangeValue.bind(this);
    }

    doChangeValue(e) {
        this.props.onChangeValue(e);
        console.log(e);
    }

    doEmpty(e, id) {

    }

    renderListMaterials(elem) {
        this.dataM = [];

        elem.forEach((data, index) => {

            //* при первом запуске сохраняем все материалы как - true
            if (!this.value[+this.props.id + index]) this.doChangeValue({ id: (+this.props.id + index), description: this.props.description, information: data, value: true })

            this.dataM = [...this.dataM,
            <React.Fragment key={+this.props.id + index}>
                <CheckForm
                    name={`${this.props.id}_${index}`}
                    id={+this.props.id + index}
                    width={this.props.width}
                    show={!this.props.readOnly || this.value[+this.props.id + index].value ? true : false}
                    placeholder={data} //* подпись LABEL для чекбокса
                    description={this.props.description}
                    value={this.value[+this.props.id + index] ? this.value[+this.props.id + index].value : true}//* при первом запуске - true
                    onChangeVisionBlock={this.doEmpty}
                    onChangeValue={this.doChangeValue}
                    nameForm={this.props.nameForm}
                    readOnly={this.props.readOnly}
                />
            </React.Fragment>
            ]
        })
        this.setState({ base: this.dataM });
    }

    componentDidMount() {
        this.setState({ materials: this.props.materials, id: this.props.id })
        setTimeout(() => { this.renderListMaterials(this.props.materials) })
    }

    render() {

        this.value = this.props.value

        return (
            <>
                {this.state.base}
            </>
        )
    }
}