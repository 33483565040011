import React, { useState, useEffect } from 'react'
import InputFormEconomic from './InputFormEconomic';
import { Card, Form, Row } from 'react-bootstrap';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';


// idsArrayToCheck - массив id которые необходимо проверить (не больше 100% пердоплат ect)
// массив тех id которые передаст родитель (в соответствии со своими id и maxId)
// firstBlockNumber - номер первого раздела
// secondBlockNumber - номер второго раздела
// thirdBlockNumber - номер третьего раздела
// guaranteeAfterLaunch - гарантия после пуска
// guaranteeAfterShipment - гарантия после отгрузки
// prepayFromServer - предоплата
// beforePayFromServer - доплата перед отгрузкой
// afterPayFromServer - доплата после отгрузки
// termsOfAfterpayment - срок доплаты после отгрузки

export function EconomicComponent({
    idsArrayToCheck,
    firstBlockNumber,
    secondBlockNumber,

    guaranteeAfterLaunch, // гарантия после пуска
    guaranteeAfterShipment, // гарантия после отгрузки
    prepayFromServer, // предоплата
    beforePayFromServer, // доплата перед отгрузкой
    afterPayFromServer, //доплата после отгрузки
    totalProcent,

    getEconomicData,
    isLotNew

}) {

    const [dataValue, setDataValue] = useState([])
    const [show] = useState(true)
    const [msgMoreThan100, setMsgMoreThan100] = useState(false)
    const [msgLessThan100, setMsgLessThan100] = useState(false)
    const [msgGuarantee, setMsgGuarantee] = useState(false)
    const [totalPercent, setTotalPercent] = useState(null)
    const [launchGuarantee, setLaunchGuarantee] = useState(null)
    const [afterGuarantee, setAfterGuarantee] = useState(null)
    const [prepay, setPrepay] = useState(null)
    const [beforePay, setBeforePay] = useState(null)
    const [afterPay, setAfterPay] = useState(null)


    useEffect(() => {

        if (isLotNew) {
            setLaunchGuarantee(+guaranteeAfterLaunch)
            setAfterGuarantee(+guaranteeAfterShipment)
            setPrepay(+prepayFromServer)
            setBeforePay(+beforePayFromServer)
            setAfterPay(+afterPayFromServer)
            setTotalPercent(+totalProcent)
        }

    }, [totalProcent])


    const doChangeValue = async (data_) => {
        setFieldsToState(data_)
        if (prepay && beforePay && afterPay) {
            setTotalPercent(prepay + afterPay + beforePay)
        }

        setDataValue([...dataValue, data_])


    }

    const setFieldsToState = (economicObj) => {

        const ids = idsArrayToCheck
        const funcs = [setLaunchGuarantee, setAfterGuarantee, setPrepay, setBeforePay, setAfterPay]


        ids.forEach((id, ind) => {
            funcs.forEach((f, idx) => {
                if (id === economicObj.id && ind === idx) {
                    f(+economicObj.value)
                }
            })
        })

    }


    const veryfyProcent = () => {
        const condition = (prepay || prepay === 0) && (beforePay || beforePay === 0) && (afterPay || afterPay === 0)

        if (!prepay) {
            setPrepay(0)
        }
        if (!afterPay) {
            setAfterPay(0)
        }
        if (!beforePay) {
            setBeforePay(0)
        }

        if (condition) {
            setTotalPercent(prepay + afterPay + beforePay)
        }

        if (prepay + afterPay + beforePay > 100) {
            setMsgMoreThan100(true)
        }

        if (condition && (prepay + afterPay + beforePay < 100)) {
            setMsgLessThan100(true)
        }

        if ((launchGuarantee && afterGuarantee) || (launchGuarantee !== 0 && afterGuarantee !== 0)) {
            if (launchGuarantee > afterGuarantee) {
                setMsgGuarantee(true)
            }
        }

        getEconomicData(dataValue)
    }

    const setCardHeader = () => {
        if (msgMoreThan100 && totalPercent > 100) {
            return <Card.Header as='h6' className='trade_card_header_error'>
                <div> {secondBlockNumber} Условия оплаты </div>
                <div style={{ fontSize: '12px' }}> <ErrorOutlineOutlinedIcon style={{ fontSize: '15px' }} className='pb_3' />{' '}Сумма оплаты превышает 100%</div>
            </Card.Header>
        }

        if (msgLessThan100 && totalPercent < 100 && totalPercent !== 0 ) {
            return <Card.Header as='h6' className='trade_card_header_error'>

                <div> {secondBlockNumber} Условия оплаты </div>
                <div style={{ fontSize: '12px' }}> <ErrorOutlineOutlinedIcon style={{ fontSize: '15px' }} className='pb_3' />{' '}Сумма оплаты менее 100%</div>
            </Card.Header>
        }

        if (totalPercent === 0 || totalPercent === 100 || (!msgLessThan100 && !msgMoreThan100)) {
            return <Card.Header as='h6' className='trade_card_header'>{secondBlockNumber} Условия оплаты </Card.Header>
        }

    }

    return (
        <div>
            <Form>
                {show && <>
                    <Card style={{ border: '1px #ccc solid' }} className='box_shadow card_block'>
                        {msgGuarantee && afterGuarantee < launchGuarantee ?
                            <Card.Header as='h6' className='trade_card_header_error'>
                                <div>{firstBlockNumber} Гарантия качества</div>
                                <div style={{ fontSize: '12px' }}>
                                    <ErrorOutlineOutlinedIcon style={{ fontSize: '15px' }} className='pb_3' />{' '}
                                    Гарантия после <span style={{ fontWeight: 'bold' }}>пуска</span> не может превышать гарантию после <span style={{ fontWeight: 'bold' }}>отгрузки</span> </div>

                            </Card.Header>
                            :
                            <Card.Header as='h6' className='trade_card_header'>{firstBlockNumber} Гарантия качества </Card.Header>
                        }
                        <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                            <InputFormEconomic
                                id={'guaranteeAfterLaunch'}
                                width={10}
                                show={true}
                                label="Гарантия после пуска: "
                                placeholder="Гарантия, мес."
                                description="Экономическая информация"
                                value={guaranteeAfterLaunch}
                                onChangeValue={doChangeValue}
                                nameForm={'EconomicData'}
                                verify='number'
                                upperLabel={'Гарантия, мес.'}
                                veryfyProcent={veryfyProcent}
                            />
                            <Row> &nbsp;</Row>

                            <InputFormEconomic
                                id={'guaranteeAfterShipment'}
                                width={10}
                                show={true}
                                label="Гарантия после отгрузки: "
                                placeholder="Гарантия, мес."
                                description="Экономическая информация"
                                value={guaranteeAfterShipment}
                                onChangeValue={doChangeValue}
                                nameForm={'EconomicData'}
                                verify='number'
                                upperLabel={'Гарантия, мес.'}
                                veryfyProcent={veryfyProcent}
                            />
                            <Row> &nbsp;</Row>

                        </Card.Body>
                    </Card>
                    <Row> &nbsp;</Row>

                    <Card style={{ border: '1px #ccc solid' }} className='box_shadow card_block'>
                       
                        {setCardHeader()}

                        <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                            <InputFormEconomic
                                id={'prepayFromServer'}
                                width={10}
                                show={true}
                                label="Предоплата: "
                                placeholder="Предоплата, %"
                                description="Экономическая информация"
                                value={prepayFromServer}
                                onChangeValue={doChangeValue}
                                nameForm={'EconomicData'}
                                verify='number'
                                upperLabel={'Предоплата, %'}
                                veryfyProcent={veryfyProcent}
                            />
                            <Row> &nbsp;</Row>

                            <InputFormEconomic
                                id={'beforePayFromServer'}
                                width={10}
                                show={true}
                                label="Оплата перед отгрузкой: "
                                placeholder="Оплата перед отгрузкой, %"
                                description="Экономическая информация"
                                value={beforePayFromServer}
                                onChangeValue={doChangeValue}
                                nameForm={'EconomicData'}
                                verify='number'
                                upperLabel={'Оплата перед отгрузкой, %'}
                                veryfyProcent={veryfyProcent}
                            />
                            <Row> &nbsp;</Row>
                            <InputFormEconomic
                                id={'afterPayFromServer'}
                                width={10}
                                show={true}
                                label="Оплата после отгрузки: "
                                placeholder="Оплата после отгрузки, %"
                                description="Экономическая информация"
                                value={afterPayFromServer}
                                onChangeValue={doChangeValue}
                                nameForm={'EconomicData'}
                                verify='number'
                                upperLabel={'Оплата после отгрузки, %'}
                                veryfyProcent={veryfyProcent}
                            />
                            <Row> &nbsp;</Row>

                            {<span style={{ display: 'flex', justifyContent: 'flex-end' }}>Итого:{' '}{+totalPercent}%</span>}

                            <Row> &nbsp;</Row>

                        </Card.Body>
                    </Card>
                </>}
            </Form>

        </div>
    )
}

