import Server from '../../server/server'


/**
 * Обновление данных на сервере
 * @param {*} data - объект с новыми данными
 * @param {*} id  - ID документа
 */
const updateRostech = async (data, id) => {
    try {
        const result = await Server.sendDataOnServer(data, `registration_${id}`, localStorage.getItem('idUser'))
        console.log(`RESSULT >>>> `, result); // test
    } catch (err) {
        console.log(`Ошибка обновления карточки: `, err);
    }
}

export default updateRostech;