import React, { useState, useEffect, useMemo } from 'react'
import { Row, Card, Table, Badge, Form, Spinner } from 'react-bootstrap'
import { CURRENCY } from '../assets/currency'
import UniversalTable from '../assets/UniversalTable'
import server from '../server/server'
import { ListGroupParticipant } from './ListGroupParticipant'
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import PresentToAllOutlinedIcon from '@mui/icons-material/PresentToAllOutlined'
import FileNoModal from '../file/FileNoModal'
import AddDocuments from './AddDocuments'
import QuitModal from '../QuitModal'
import QuestionToChatBtn from './QuestionToChatBtn'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import AllModalsFooter from '../assets/AllModalsFooter'
import { ChatButton, SaveCurrentDataButton } from '../assets/BasicButtons'
import LoaderPage from '../assets/loaderPage'

const ParticipantLot = ({
  lot,
  productionArray,
  choicePositionId,
  sendResultConfirmationLot,
  value,
  sum,
  idFile,
  quitModalFlag,
  toggleConfirmationLot,
  runChat,
  compareConfirmationResult,
  handleClickChat,
  toggleChatModal,
  fireSaveFn,
  runSaveResult,
  getCurrData,
  savedDataOnButton, //ann
  setStateBeensaved,
  getIdFileParticipant
}) => {
  const [positions, setPositions] = useState([])
  const [showPositions, setShowPositions] = useState(false)
  const [checkboxResult, setCheckboxResult] = useState([])
  const [showFiles, setShowFiles] = useState(false)
  const [files, setFiles] = useState([])
  const [resultData, setResultData] = useState([{ id: '0', description: '', value: '' }])
  // const [setChatPreMessage] = useState('') // todo: запуск чата
  const [idFileParticipant, setIdFileParticipant] = useState(String(Date.now())) // ID FILES from PARTICIPANT
  const [showFileId, setShowFileId] = useState(false)
  const [errShowGuarant, setErrShowGuarant] = useState(true)
  const [errShowPayment, setErrShowPayment] = useState(true)
  const [guaranteeNotSpecifiedWarn, setGuaranteeNotSpecifiedWarn] = useState(false)
  const [showQuitModal, setShowQuitModal] = useState(false)
  const [localQuitModalFlag, setLocalQuitModalFlag] = useState(false)
  const [idFileList, setIdFileList] = useState()
  const [valueFileList, setValueFileList] = useState([])
  const [resultSave, setResultSave] = useState(false)
  const [savedDataMessage, setSaveDataMessage] = useState(false)
  const [sendDataMessage, setSendDataMessage] = useState(false)
  const [fst, setFst] = useState([])
  const [showLot, setShowLot] = useState(false)
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    if (!runSaveResult) {
      // console.log(`CLOSE in participantLot: `, runSaveResult);
      setTimeout(() => {
        sendResult()
      }, 1000)
    }
  }, [runSaveResult])

  useEffect(() => {
    if (resultSave !== fireSaveFn) {
      setResultSave(!resultSave)
      sendResult()
    }
  }, [fireSaveFn])

  useEffect(() => {
    if (lot.position && lot.position.length > 0) {
      setIdFileList(`${lot.id}__files__${localStorage.getItem('idUser')}`)
      setPositions(lot.position)
      setShowPositions(true)
    }
  }, [lot.position])

  useEffect(() => {
    ; (async () => {
      const result = await server.getDataFromServer(lot.idFile, 'trade_file')

      if (result) {
        await setFiles(result)
        setShowFiles(true)
      }
    })()
  }, [lot.idFile])

  useEffect(() => {
    if (!value) {
      createIdFile(idFile)
    }

    if (value) {
      setResultData(value.data)
      // setSuccess(true)
      createIdFile(value.idFile)
      getFileList(value.idFileList)
      // console.log(`VALUE::: `, value); // test
      // console.log(`POSITION:::: `, positions); // test
      addNewPrice(positions, value)

      lookErrorGuarant(value)
      // lookPayment(value)
    }
  }, [value])

  useEffect(() => {
    // console.log(`>>>>>>>>>>>> `, idFileParticipant); // test
  }, [idFileParticipant])

  useMemo(() => {
    changeViewTable()
  }, [positions])

  //* верификация полей
  useMemo(() => {
    lookErrorGuarant(resultData)
    // lookPayment(resultData)
    // lookPayment()
    getCurrData(resultData)
  }, [resultData])

  useEffect(() => {
    if (localQuitModalFlag !== quitModalFlag) {
      setShowQuitModal(!showQuitModal)
    }
  }, [quitModalFlag, localQuitModalFlag])

  useEffect(() => {
    handleClickChat(runChat)
  }, [runChat])

  //loader
  useEffect(() => {
    if (!Object.keys(lot).length) {
      setShowLoader(true)
    } else {
      setShowLoader(false)
    }
  }, [lot])

  const closeQuitModal = () => {
    setShowQuitModal(!showQuitModal)
    setLocalQuitModalFlag(!localQuitModalFlag)
  }

  const saveAndQuit = async () => {
    await sendResult()
    setShowQuitModal(false)
    toggleConfirmationLot()
  }

  const quitNoSave = () => {
    toggleConfirmationLot()
    setShowQuitModal(false)
  }

  //* генерируем ID FILE
  const createIdFile = (id) => {
    setIdFileParticipant(id)

    setTimeout(() => {
      setShowFileId(true)
    }, 1000)
  }

  function changeViewTable() {
    setShowPositions(false)
    setTimeout(() => {
      setShowPositions(true)
    })
  }

  //* добавляем новую цену в таблицу позиций
  const addNewPrice = (position, value) => {
    const newPositions = position.map((data) => {
      const choicePos = value.tradePosition.find((item) => item.idPosition === data.keyNameTrade)
      const price = choicePos && choicePos.dataComm.find((itm) => itm.id === '10')
      const quantity = choicePos && choicePos.dataComm.find((itm) => itm.id === '6')
      let newPrice

      if (price && quantity && typeof quantity.value === 'string') {
        newPrice = Number(price.value) * Number(quantity.value)
      }

      if (price && quantity && typeof quantity.value === 'boolean') {
        newPrice = Number(price.value) * 1
      }

      // console.log(`NEW PRICE::::::: `, newPrice, price.value, quantity.value); // test
      return Object.assign(data, { newPrice: newPrice })
    })

    // console.log(`NEW POSITION >>>>>>>>>>>>>> `, newPositions); // test
    setPositions(newPositions)
  }

  //* ожидаем массив объектов загруженных файлов
  const getIdFileNameParticipant = (name) => {
    // console.log(`GET ID FILE NAME PARTICPANT - RUN`); // test
    // console.log(`GET ID FILE NAME PARTICPANT::::: `, name); // test
  }

  const getCheckboxResult = (obj) => {
    const indexResult = checkboxResult.findIndex((item) => item.id === obj.id)
    let result = checkboxResult

    if (indexResult >= 0) {
      result.splice(indexResult, 1)
    }

    result.push(obj)
    setCheckboxResult(result)
    // console.log(`STATE::: `, checkboxResult); // test
  }

  const empty = () => { }

  //* возвращает ID файлов для ЛОТа
  const returnId = (idFiles) => {
    // console.log(`return ID::::::: `, idFiles);
  }

  const choicePosition = async (e) => {
    // repair (async + await)
    // console.log(`ID выбранной позиции:`, e);
    await choicePositionId(e)
  }

  const downloadFile = async (fileName) => {
    const result = await server.readFileFromServer(fileName)

    if (result) {
      const linkBlob = window.URL.createObjectURL(result)
      const link = document.createElement('a')
      link.href = linkBlob
      link.download = fileName.split('_').slice(-1)[0] //! меняем имя файла при загрузке
      document.body.appendChild(link)
      link.click()
      link.remove()
    }
  }

  const doChangeVisibleChat = (a, b) => {
    toggleChatModal()
    handleClickChat(a, b)
  }

  //* возвращаем value
  const returnValue = (id) => {
    if (resultData.find((item) => item.id === String(id))) {
      // console.log((resultData.find(item => item.id === String(id))).value); // test
      return resultData.find((item) => item.id === String(id)).value
    }
  }

  //* отслеживаем правильность ввода гарантийных сроков
  function lookErrorGuarant(arr) {
    let first
    let second

    if (arr.length) {
      first = arr.find((item) => item.id === '1')
      second = arr.find((item) => item.id === '2')
    }

    if (first && second) {
      if (first.value && second.value) {
        setGuaranteeNotSpecifiedWarn(false)
      }

      if (Number(first.value) <= Number(second.value)) {
        setErrShowGuarant(false)
      }
    } else {
      setErrShowGuarant(true)
      setGuaranteeNotSpecifiedWarn(true)
    }
  }

  function getPaymentData() {
    let first
    let second
    let third

    if (resultData.length) {
      first = resultData.find((item) => item.id === '3')
      second = resultData.find((item) => item.id === '4')
      third = resultData.find((item) => item.id === '5')
    }

    return {
      first,
      second,
      third
    }
  }

  //убирает ошибки по предоплатам(п.5)
  useEffect(() => {
    const { first, second, third } = getPaymentData()

    if (
      first &&
      second &&
      third &&
      Number(first.value) + Number(second.value) + Number(third.value) === 100
    ) {
      setErrShowPayment(false)
    }
  }, [resultData])

  /**
   * Проверка на 100%
   */
  function lookPayment() {
    const { first, second, third } = getPaymentData()

    if (
      first &&
      second &&
      third &&
      Number(first.value) + Number(second.value) + Number(third.value) < 100
    ) {
      setErrShowPayment(true)
    }

    if (
      first &&
      second &&
      third &&
      Number(first.value) + Number(second.value) + Number(third.value) > 100
    ) {
      setErrShowPayment(true)
    }

    setFst([first, second, third])
  }

  useEffect(() => {
    addPayment()
  }, [fst])

  /**
   * Добавляем объекты платежа в массив Лота
   */
  function addPayment() {
    let newR = []

    const first = fst.find((item) => item?.id === '3')
    const second = fst.find((item) => item?.id === '4')
    const third = fst.find((item) => item?.id === '5')

    console.log(`FST >>> `, fst) // test

    if (first?.value === '100') {
      console.log(`first step`) // test
      newR = resultData.filter((item) => item.id !== '4' && item.id !== '5')
      newR.push({ id: '4', description: 'Оплата перед отгрузкой', value: '0' })
      newR.push({ id: '5', description: 'Оплата после отгрузки', value: '0' })
    }

    if (second?.value === '100') {
      console.log(`second step`) // test
      newR = resultData.filter((item) => item.id !== '3' && item.id !== '5')
      newR.push({ id: '3', description: 'Предоплата', value: '0' })
      newR.push({ id: '5', description: 'Оплата после отгрузки', value: '0' })
    }

    if (third?.value === '100') {
      console.log(`third step`) //test
      newR = resultData.filter((item) => item.id !== '3' && item.id !== '4')
      newR.push({ id: '3', description: 'Предоплата', value: '0' })
      newR.push({ id: '4', description: 'Оплата перед отгрузкой', value: '0' })
    }

    setTimeout(() => {
      console.log(`NEW RESULT::: `, newR)
    }, 1000) // test

    if (newR?.length > 0) {
      setResultData(newR)
    }
  }

  // TODO: создание объектов результата ввода
  const handleChange = async (e) => {
    let val = e.target.value
    const stateCopy = [...resultData]

    if (e.target.type === 'checkbox') {
      val = e.target.checked
    }

    const result = {
      id: e.target.dataset.id,
      description: e.target.dataset.description,
      value: val
    }

    const newResult = stateCopy.filter((item) => item.id !== e.target.dataset.id)

    newResult.push(result)

    setResultData(newResult)

    setStateBeensaved(false) //дает понять, что после того как нажали на "сохранить" в стейте что-то поменялось и нужно снова вызвать модалку подтверждения

    lookPayment()

    // console.log('changed here - ', newResult);
  }

  const saveDataWithNotification = () => {
    sendResult()
    savedDataOnButton(true) //ann
    setSaveDataMessage(true)

    setTimeout(() => {
      setSaveDataMessage(false)
    }, 2000)
  }

  const sendDataWithNotification = () => {
    sendResultData()
    setSendDataMessage(true)
    setTimeout(() => {
      setSendDataMessage(false)
    }, 2000)
  }

  // сохранение результата подтверждений
  const sendResult = async () => {
    const serialArr = resultData.sort((a, b) => {
      return Number(a.id) - Number(b.id)
    })

    setResultData(serialArr)

    const obj = {
      idLot: lot.id,
      participantCompany: localStorage.getItem('idUser'),
      idFile: idFileParticipant,
      idFileList: idFileList,
      data: serialArr
    }
    // console.log(`obj :::::::::::::::: `, obj); // test
    sendResultConfirmationLot(obj)
  }

  // загрузка файлов по списку
  const getFileList = (id) => {
    ; (async () => {
      try {
        const result = await server.getDataFromServer(id, 'trade_file')
        // console.log(`RESULT LOAD OBJ::::::::::id >>>>  `, id); // test
        if (!result) throw new Error('Нет нужных данных в BD')
        setValueFileList(result)
      } catch (err) {
        console.log(`Ошибка при загрузке объекта файлов по списку (ParticipantLot): `, err)
      }
    })()
  }

  // объект файлов загруженных по списку
  const sendObj = (data) => {
    ; (async () => {
      try {
        //* сохранение объекта в trade_file
        const result = await server.sendDataOnServer(
          data,
          `${lot.id}__files__${localStorage.getItem('idUser')}`,
          'trade_file'
        )
        // console.log(`RESULT SAVE OBJ::::: `, result); // test

        setIdFileList(`${lot.id}__files__${localStorage.getItem('idUser')}`)
      } catch (err) {
        console.log(`Ошибка при сохранении файлов по списку (ParticipantLot): `, err)
      }
    })()
  }

  // * запуск сравнения результата подтверждения
  const sendResultData = async () => {
    // console.log(`ОТПРАВКА РЕЗУЛЬТАТОВ ПОДТВЕРЖДЕНИЯ ЗАКАЗЧИКУ, lot ID::: `, lot.id); // test
    compareConfirmationResult(lot.id)
  }

  /**
   * Из Уральского в московское время
   * @param {string} time уральское время
   * @returns {string} московское время
   */
  const doTimeMoscow = (time) => {
    let hour = Number(time?.split(':')[0]) - 2
    const minutes = time?.split(':')[1]

    if (hour === -1) hour = 23
    if (hour === -2) hour = '00'

    return `${hour}:${minutes}`
  }

  const dateTechStart = new Date(lot.dateTechStart)
  const dateTech = new Date(lot.dateTech)
  const dateTrade = new Date(lot.dateTrade)
  const agreementConclusionDate = new Date(lot.agreementConclusionDate)
  const deliveryTime = new Date(lot.deliveryTime)

  const time = `${new Date(
    Date.parse(agreementConclusionDate) + Number(returnValue(7) * (1000 * 60 * 60 * 24))
  ).getDate() < 10
    ? 0
    : ''
    }${new Date(
      Date.parse(agreementConclusionDate) + Number(returnValue(7) * (1000 * 60 * 60 * 24))
    ).getDate()}-${new Date(
      Date.parse(agreementConclusionDate) + Number(returnValue(7) * (1000 * 60 * 60 * 24))
    ).getMonth() +
      1 <
      10
      ? 0
      : ''
    }${new Date(
      Date.parse(agreementConclusionDate) + Number(returnValue(7) * (1000 * 60 * 60 * 24))
    ).getMonth() + 1
    }-${new Date(
      Date.parse(agreementConclusionDate) + Number(returnValue(7) * (1000 * 60 * 60 * 24))
    ).getFullYear()}`

  const numberOfLot = lot.numberLot ? `лот № ${lot.numberLot};` : ''
  // console.log(`LOT:::: `, lot.typeTrade); // test

  const ndsPrice = Number(lot.ndsPrice)
  const ndsPriceToLocalString = ndsPrice && ndsPrice.toLocaleString()

  const lotPrice = Number(lot.price)
  const lotPriceToLocalString = lotPrice && lotPrice.toLocaleString('ru')

  const sumToLocalString = sum && sum.toLocaleString('ru')

  // console.log(Object.keys(lot).length)

  // console.log(Object.keys({}));

  return (
    <>
      {showQuitModal && (
        <QuitModal
          onQuitWithSave={saveAndQuit}
          onQuitWithoutSave={quitNoSave}
          closeQuitModal={closeQuitModal}
          modalTitle={'Выход'}
          modalBody={'Сохранить данные?'}
          primaryButton={'Сохранить'}
          secondaryButton={'Выйти без сохранения'}
          icon={<ExitToAppSharpIcon fontSize='large' className='icon_leave_modal' />}
          input={false}
        />
      )}
      {!showLoader ? (
        <div>
          <div className='participant_lot_container'>
            <h4 style={{ textAlign: 'center' }}> ЛОТ: №{lot.numberLot}</h4>
            <h5 style={{ textAlign: 'center' }}>
              {' '}
              Тип торгов: {lot.typeTrade === 'reduction' ? 'Редукцион' : 'Обычный'}{' '}
            </h5>
            <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
              <Card.Header as='h6' className='trade_card_header'>
                1. Наименование лота{' '}
              </Card.Header>

              <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                <Table bordered style={{ backgroundColor: '#fff' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '50px' }}>1.1</td>
                      <td style={{ width: '180px' }}>Название лота</td>
                      <td>{lot.nameLot}</td>
                    </tr>
                    <tr>
                      <td>1.2</td>
                      <td>Номер лота</td>
                      <td>{lot.numberLot}</td>
                    </tr>
                    <tr>
                      <td>1.3</td>
                      <td>Тип лота</td>
                      <td>{lot.tradeKind}</td>
                    </tr>
                    <tr>
                      <td>1.4</td>
                      <td>Категории лота</td>
                      <td>
                        {productionArray.map((el, index) => (
                          <React.Fragment key={'A' + index}>
                            <Badge pill variant='secondary' key={el}>
                              {el}
                            </Badge>{' '}
                          </React.Fragment>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Row> &nbsp; </Row>

            <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
              <Card.Header as='h6' className='trade_card_header'>
                2. Организатор лота{' '}
              </Card.Header>

              <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                <Table bordered style={{ backgroundColor: '#fff' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '50px' }}>2.1</td>
                      <td style={{ width: '30%' }}>
                        <b>Организатор лота</b>
                      </td>
                      <td>
                        <b>{lot.organizerName}</b>
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Row> &nbsp; </Row>
              </Card.Body>
            </Card>
            <Row> &nbsp; </Row>
            <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
              <Card.Header as='h6' className='trade_card_header'>
                3. Позиции лота{' '}
              </Card.Header>

              <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                {showPositions && (
                  <UniversalTable
                    isRowGreen={false}
                    selectable1={false}
                    selectable2={true}
                    removable={false}
                    selectable={false}
                    favourite={false}
                    tableName={'Позиции лота'}
                    titleObjectData={{
                      numbered: 'п/п',
                      nameTrade: 'Название',
                      comm: 'НМЦ, руб.',
                      newPrice: 'Ваша стоимость, руб.'
                    }}
                    renderedArray={positions}
                    renderedArrayWithAdded={empty}
                    chosenObjectsArray={empty}
                    chosenIdsArray={empty}
                    chosenObjectId={empty}
                    handleCancel={empty}
                    handleModalToggle={empty}
                    openQuestionnairy={choicePosition}
                    participanCheckbox={false}
                    sendCheckboxResult={getCheckboxResult}
                  />
                )}
              </Card.Body>
            </Card>
            <Row> &nbsp; </Row>

            <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
              <Card.Header
                as='h6'
                className='trade_card_header'
                style={{
                  backgroundColor: errShowGuarant ? '#e87c86' : '',
                  color: errShowGuarant ? '#fff' : '#000'
                }}
              >
                {errShowGuarant ? (
                  <>
                    <div> 4. Гарантия качества</div>
                    {guaranteeNotSpecifiedWarn ? (
                      <div style={{ fontSize: '12px' }}>
                        <WarningAmberOutlinedIcon style={{ fontSize: '15px' }} className='pb_3' />{' '}
                        Укажите свои значения
                        {/* Гарантия после <span style={{ fontWeight: 'bold' }}>пуска</span> не может превышать гарантию после <span style={{ fontWeight: 'bold' }}>отгрузки</span> */}
                      </div>
                    ) : (
                      <div style={{ fontSize: '12px' }}>
                        <ErrorOutlineOutlinedIcon style={{ fontSize: '15px' }} className='pb_3' />{' '}
                        Гарантия после <span style={{ fontWeight: 'bold' }}>пуска</span> не может
                        превышать гарантию после{' '}
                        <span style={{ fontWeight: 'bold' }}>отгрузки</span>
                      </div>
                    )}
                  </>
                ) : (
                  <div>4. Гарантия качества</div>
                )}
              </Card.Header>

              <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                <Table bordered style={{ backgroundColor: '#fff' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '50px' }}>4.1</td>
                      <td style={{ width: '30%' }}>Гарантия после пуска, мес.</td>
                      <td>{lot.guaranteeAfterLaunch}</td>
                      <td>
                        <Form.Control
                          type='text'
                          placeholder='Указать свои значения'
                          data-id={1}
                          data-description='Гарантия после пуска, мес.'
                          onChange={handleChange}
                          value={returnValue(1)}
                          onKeyDown={lookErrorGuarant}
                        />
                      </td>
                      <td>
                        <QuestionToChatBtn
                          handleClick={() =>
                            doChangeVisibleChat(
                              `
                                                ${numberOfLot}
                                                пункт 4.1.;
                                                "Гарантия после пуска - ${lot.guaranteeAfterLaunch} мес."`,
                              lot.organizerName
                            )
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '50px' }}>4.2</td>
                      <td style={{ width: '30%' }}>Гарантия после отгрузки, мес.</td>
                      <td>{lot.guaranteeAfterShipment}</td>
                      <td>
                        <Form.Control
                          type='text'
                          placeholder='Указать свои значения'
                          data-id={2}
                          data-description='Гарантия после отгрузки, мес.'
                          onChange={handleChange}
                          value={returnValue(2)}
                          onKeyDown={lookErrorGuarant}
                        />
                      </td>
                      <td style={{ width: '60px' }}>
                        <QuestionToChatBtn
                          handleClick={() =>
                            doChangeVisibleChat(
                              `${numberOfLot}
                                            пункт 4.2.; 
                                            "Гарантия после отгрузки - ${lot.guaranteeAfterShipment} мес.`,
                              lot.organizerName
                            )
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Row> &nbsp; </Row>

            <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
              <Card.Header
                as='h6'
                className='trade_card_header'
                style={{
                  backgroundColor: errShowPayment ? '#e87c86' : '',
                  color: errShowPayment ? '#fff' : '#000'
                }}
              >
                {errShowPayment ? (
                  <>
                    <div>5. Условия оплаты</div>
                    <div style={{ fontSize: '12px' }}>
                      <ErrorOutlineOutlinedIcon style={{ fontSize: '15px' }} className='pb_3' />{' '}
                      Сумма оплат не равна 100%
                    </div>
                  </>
                ) : (
                  <div>5. Условия оплаты</div>
                )}
              </Card.Header>

              <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                <Table bordered style={{ backgroundColor: '#fff' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '50px' }}>5.1</td>
                      <td style={{ width: '30%' }}>Предоплата</td>
                      <td>{lot.prepayFromServer} %</td>
                      <td>
                        <Form.Control
                          type='text'
                          placeholder='Указать свои значения'
                          data-id={3}
                          data-description='Предоплата'
                          onChange={handleChange}
                          value={returnValue(3)}
                          onKeyUp={lookPayment}
                        />
                      </td>
                      <td style={{ width: '60px' }}>
                        <QuestionToChatBtn
                          handleClick={() =>
                            doChangeVisibleChat(
                              `
                                            ${numberOfLot}
                                            пункт 5.1;
                                            "Условия оплаты, Предоплата - ${lot.prepayFromServer}"`,
                              lot.organizerName
                            )
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '50px' }}>5.2</td>
                      <td style={{ width: '30%' }}>Оплата перед отгрузкой</td>
                      <td>{lot.beforePayFromServer} %</td>
                      <td>
                        <Form.Control
                          type='text'
                          placeholder='Указать свои значения'
                          data-id={4}
                          data-description='Оплата перед отгрузкой'
                          onChange={handleChange}
                          value={returnValue(4)}
                          onKeyUp={lookPayment}
                        />
                      </td>
                      <td style={{ width: '60px' }}>
                        <QuestionToChatBtn
                          handleClick={() =>
                            doChangeVisibleChat(
                              `
                                            ${numberOfLot}
                                            пункт 5.2;
                                            "Условия оплаты, Оплата перед отгрузкой - ${lot.beforePayFromServer}%"`,
                              lot.organizerName
                            )
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '50px' }}>5.3</td>
                      <td style={{ width: '30%' }}>Оплата после отгрузки</td>
                      <td>{lot.afterPayFromServer} %</td>
                      <td>
                        <Form.Control
                          type='text'
                          placeholder='Указать свои значения'
                          data-id={5}
                          data-description='Оплата после отгрузки'
                          onChange={handleChange}
                          value={returnValue(5)}
                          onKeyUp={lookPayment}
                        />
                      </td>
                      <td style={{ width: '60px' }}>
                        <QuestionToChatBtn
                          handleClick={() =>
                            doChangeVisibleChat(
                              ` ${numberOfLot} пункт 5.3.; "Условия оплаты, Оплата после отгрузки - ${lot.afterPayFromServer}%"`,
                              lot.organizerName
                            )
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                ИТОГО:{' '}
                <span>
                  {' '}
                  {`${Number(returnValue(3)) + Number(returnValue(4)) + Number(returnValue(5))
                    } %`}{' '}
                </span>
              </Card.Footer>
            </Card>

            <Row> &nbsp; </Row>

            <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
              <Card.Header as='h6' className='trade_card_header'>
                6. Срок оплаты{' '}
              </Card.Header>

              <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                <Table bordered style={{ backgroundColor: '#fff' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '50px' }}>6.1</td>
                      <td style={{ width: '30%' }}>Срок оплаты после отгрузки, дней</td>
                      <td>{lot.termsOfAfterpayment}</td>
                      <td>
                        <Form.Control
                          type='text'
                          placeholder='Указать свои значения'
                          data-id={6}
                          data-description='Срок оплаты после отгрузки'
                          onChange={handleChange}
                          value={returnValue(6)}
                        />
                      </td>
                      <td style={{ width: '60px' }}>
                        <QuestionToChatBtn
                          handleClick={() =>
                            doChangeVisibleChat(
                              `${numberOfLot}
                                                пункт 6.;
                                                "Срок оплаты, Срок оплаты после отгрузки - ${lot.termsOfAfterpayment}`,
                              lot.organizerName
                            )
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Row> &nbsp; </Row>

            <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
              <Card.Header as='h6' className='trade_card_header'>
                7. Условия поставки{' '}
              </Card.Header>

              <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                <Table bordered style={{ backgroundColor: '#fff' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '50px' }}>7.1</td>
                      <td style={{ width: '30%' }}>Планируемая дата заключения договора</td>
                      <td>{`${agreementConclusionDate.getDate() < 10 ? 0 : ''
                        }${agreementConclusionDate.getDate()}-${agreementConclusionDate.getMonth() + 1 < 10 ? 0 : ''
                        }${agreementConclusionDate.getMonth() + 1}-${dateTrade.getFullYear()}
                    `}</td>
                    </tr>
                    <tr>
                      <td style={{ width: '50px' }}>7.2</td>
                      <td style={{ width: '30%' }}>Желаемый срок поставки</td>
                      <td>{`${deliveryTime.getDate() < 10 ? 0 : ''}${deliveryTime.getDate()}-${deliveryTime.getMonth() + 1 < 10 ? 0 : ''
                        }${deliveryTime.getMonth() + 1}-${dateTrade.getFullYear()}
                    `}</td>

                      <td>
                        <Form.Control
                          type='text'
                          placeholder='Срок поставки, дней'
                          data-id={7}
                          data-description='Срок поставки, дней'
                          onChange={handleChange}
                          value={returnValue(7)}
                        />
                      </td>
                      <td style={{ width: '60px' }}>
                        <QuestionToChatBtn
                          handleClick={() =>
                            doChangeVisibleChat(
                              `
                                            ${numberOfLot}
                                            пункт 7.2.;
                                            "Срок поставки - ${deliveryTime.getDate() < 10 ? 0 : ''
                              }${deliveryTime.getDate()}-${deliveryTime.getMonth() + 1 < 10 ? 0 : ''
                              }${deliveryTime.getMonth() + 1}-${dateTrade.getFullYear()}"`,
                              lot.organizerName
                            )
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
              <Card.Footer>
                Дата поставки в перерасчете с дней: &nbsp;
                {returnValue(7) ? (
                  <span>
                    <b> {time} </b>
                  </span>
                ) : (
                  ' '
                )}
              </Card.Footer>
            </Card>

            <Row> &nbsp; </Row>

            <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
              <Card.Header as='h6' className='trade_card_header'>
                8. Период сбора заявок на торги{' '}
              </Card.Header>

              <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                <Table bordered style={{ backgroundColor: '#fff' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '50px' }}>8.1</td>
                      <td style={{ width: '30%' }}>Дата начала согласований</td>
                      <td>{`
                        ${dateTechStart.getDate() < 10 ? 0 : ''}${dateTechStart.getDate()}-${dateTechStart.getMonth() + 1 < 10 ? 0 : ''
                        }${dateTechStart.getMonth() + 1}-${dateTechStart.getFullYear()}
                        `}</td>
                    </tr>
                    <tr>
                      <td style={{ width: '50px' }}>8.2</td>
                      <td style={{ width: '30%' }}>Дата окончания согласований</td>
                      <td>{`
                    ${dateTech.getDate() < 10 ? 0 : ''}${dateTech.getDate()}-${dateTech.getMonth() + 1 < 10 ? 0 : ''
                        }${dateTech.getMonth() + 1}-${dateTech.getFullYear()}
                    `}</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Row> &nbsp; </Row>

            <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
              <Card.Header as='h6' className='trade_card_header'>
                9. Параметры торгов{' '}
              </Card.Header>

              <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                <Table bordered style={{ backgroundColor: '#fff' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '50px' }}>9.1</td>
                      <td style={{ width: '30%' }}>Дата проведения торгов</td>
                      <td>{`
                    ${dateTrade.getDate() < 10 ? 0 : ''}${dateTrade.getDate()}-${dateTrade.getMonth() + 1 < 10 ? 0 : ''
                        }${dateTrade.getMonth() + 1}-${dateTrade.getFullYear()}
                    `}</td>
                    </tr>
                    <tr>
                      <td style={{ width: '50px' }}>9.2</td>
                      <td style={{ width: '30%' }}>Время проведения торгов (МСК):</td>
                      {/* <td>{doTimeMoscow(lot.timeTrade)}</td>                     */}
                      <td>{lot.timeTrade}</td>
                    </tr>
                    <tr>
                      <td style={{ width: '50px' }}>9.3</td>
                      <td style={{ width: '30%' }}>Время на принятие решения</td>
                      <td>{lot.timer} мин.</td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Row> &nbsp; </Row>

            <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
              <Card.Header as='h6' className='trade_card_header'>
                10. Дополнительные требования{' '}
              </Card.Header>

              <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                <Table bordered style={{ backgroundColor: '#fff' }}>
                  <tbody>
                    <tr style={{ textAlign: 'center' }}>
                      <td style={{ width: '50px' }}>п/п</td>
                      <td>Наименование гарантии</td>
                      <td>Наличие гарантии</td>
                      <td style={{ width: '50px' }}>Согласие</td>
                      <td>Задать вопрос</td>
                    </tr>
                    <tr>
                      <td style={{ width: '50px' }}>10.1</td>
                      <td style={{ width: '30%' }}>Банковская гарантия на исполнение договора</td>
                      <td>
                        {lot.bankContractExecutionGuaranteeInput
                          ? `${lot.bankContractExecutionGuaranteeInput} %`
                          : 'Нет'}
                      </td>
                      <td>
                        <Form.Control
                          type='checkbox'
                          placeholder='Банковская гарантия на исполнение договора'
                          data-id={8}
                          data-description='Банковская гарантия на исполнение договора'
                          onChange={handleChange}
                          value={returnValue(8)}
                          checked={returnValue(8)}
                        />
                      </td>
                      <td style={{ width: '60px' }}>
                        <QuestionToChatBtn
                          handleClick={() =>
                            doChangeVisibleChat(
                              `
                                            ${numberOfLot}
                                            пункт 10.1.; 
                                            "Банковская гарантия на исполнение договора - ${lot.bankContractExecutionGuaranteeInput
                                ? `${lot.bankContractExecutionGuaranteeInput} %`
                                : 'Нет'
                              }"`,
                              lot.organizerName
                            )
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '50px' }}>10.2</td>
                      <td style={{ width: '30%' }}>Банковская гарантия на предоплату</td>
                      <td>{lot.bankPrepayGuarantee ? 'Да' : 'Нет'}</td>
                      <td>
                        <Form.Control
                          type='checkbox'
                          placeholder='Банковская гарантия на предоплату'
                          data-id={9}
                          data-description='Банковская гарантия на предоплату'
                          onChange={handleChange}
                          value={returnValue(9)}
                          checked={returnValue(9)}
                        />
                      </td>
                      <td style={{ width: '60px' }}>
                        <QuestionToChatBtn
                          handleClick={() =>
                            doChangeVisibleChat(
                              ` ${numberOfLot}
                                            пункт 10.2.; 
                                            "Банковская гарантия на предоплату - ${lot.bankPrepayGuarantee ? 'Да' : 'Нет'
                              }"`,
                              lot.organizerName
                            )
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '50px' }}>10.3</td>
                      <td style={{ width: '30%' }}>
                        Удержание суммы на исполнение гарантийных обязательств либо банковская
                        гарантия
                      </td>
                      <td>
                        {lot.bankGuaranteeExecutionInput
                          ? `${lot.bankGuaranteeExecutionInput} %`
                          : 'Нет'}
                      </td>
                      <td>
                        <Form.Control
                          type='checkbox'
                          placeholder='Удержание суммы на исполнение гарантийных обязательств либо банковская гарантия'
                          data-id={10}
                          data-description='Удержание суммы на исполнение гарантийных обязательств либо банковская гарантия'
                          onChange={handleChange}
                          value={returnValue(10)}
                          checked={returnValue(10)}
                        />
                      </td>
                      <td
                        style={{
                          position: 'relative',
                          border: '0',
                          cursor: 'pointer'
                        }}
                      >
                        <QuestionToChatBtn
                          handleClick={() =>
                            doChangeVisibleChat(
                              `
                                             ${numberOfLot}
                                            пункт 10.3.; 
                                            "Удержание суммы на исполнение гарантийных обязательств либо банковская гарантия - ${lot.bankGuaranteeExecutionInput
                                ? `${lot.bankGuaranteeExecutionInput} %`
                                : 'Нет'
                              }"`,
                              lot.organizerName
                            )
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '50px' }}>10.4</td>
                      <td style={{ width: '30%' }}>Согласие с условием договора</td>
                      <td>{lot.contractAgreement ? 'Да' : 'Нет'}</td>
                      <td>
                        <Form.Control
                          type='checkbox'
                          placeholder='Согласие с условием договора'
                          data-id={11}
                          data-description='Согласие с условием договора'
                          onChange={handleChange}
                          value={returnValue(11)}
                          checked={returnValue(11)}
                        />
                      </td>
                      <td style={{ width: '60px' }}>
                        <QuestionToChatBtn
                          handleClick={() =>
                            doChangeVisibleChat(
                              `
                                            ${numberOfLot}
                                            пункт 10.4.;
                                            "Согласие с условием договора - ${lot.contractAgreement ? 'Да' : 'Нет'
                              }"`,
                              lot.organizerName
                            )
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Row> &nbsp; </Row>

            <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
              <Card.Header as='h6' className='trade_card_header'>
                11. Условия доставки по Инкотермс{' '}
              </Card.Header>

              <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                <Table bordered style={{ backgroundColor: '#fff' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '50px' }}>11.1</td>
                      <td>
                        {lot.deliveryCondition ? lot.deliveryCondition : 'Условия не указаны'}
                      </td>
                      {lot.deliveryCondition && (
                        <>
                          <td>
                            <Form.Control
                              type='checkbox'
                              placeholder='Условия доставки по Инкотермс'
                              data-id={12}
                              data-description='Условия доставки по Инкотермс'
                              onChange={handleChange}
                              value={returnValue(12)}
                              checked={returnValue(12)}
                            />
                          </td>

                          <td
                            style={{
                              position: 'relative',
                              border: '0',
                              cursor: 'pointer',
                              width: '60px'
                            }}
                          >
                            <QuestionToChatBtn
                              handleClick={() =>
                                doChangeVisibleChat(
                                  `
                                                     ${numberOfLot}
                                                    пункт 11.1.; 
                                                    "Условия доставки по Инкотермс - ${lot.deliveryCondition
                                    ? lot.deliveryCondition
                                    : 'Условия не указаны'
                                  }"`,
                                  lot.organizerName
                                )
                              }
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Row> &nbsp;</Row>

            <ListGroupParticipant
              showRenderedArray={showFiles}
              renderedArray={files}
              downloadFunction={downloadFile}
              sectionNum={'12.'}
              document={'лоту'}
              headerName={'Файлы заказчика'}
              runChat={handleClickChat}
              organizerName={lot.organizerName}
              lotNum={lot.numberLot}
            />

            <Row> &nbsp; </Row>

            <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
              <Card.Header as='h6' className='trade_card_header'>
                13. Файлы необходимые для торгов{' '}
              </Card.Header>

              <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                <AddDocuments
                  idLot={lot.numberLot}
                  listForFiles={lot.listForFiles}
                  valueFileList={valueFileList}
                  addList={empty}
                  sendObj={sendObj}
                  runChat={handleClickChat}
                  organizerName={lot.organizerName}
                  showDelIcon={true}
                  showCheckIcon={true}
                  showAddBtn={true}
                  participant={true}
                />
              </Card.Body>
            </Card>

            <Row> &nbsp;</Row>

            <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
              <Card.Header as='h6' className='trade_card_header'>
                14. НМЦ (начальная максимальная цена) лота{' '}
              </Card.Header>

              <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                <Table bordered style={{ backgroundColor: '#fff' }}>
                  <tbody>
                    <tr>
                      <td style={{ width: '50px' }}>14.1</td>
                      <td style={{ width: '30%' }}>НМЦ лота с НДС</td>
                      <td>
                        {ndsPriceToLocalString}{' '}
                        {lot.currency ? CURRENCY[lot.currency][0] : CURRENCY['euro'][0]}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: '50px' }}>14.2</td>
                      <td style={{ width: '30%' }}>НМЦ лота без НДС</td>
                      <td>
                        {lotPriceToLocalString}{' '}
                        {lot.currency ? CURRENCY[lot.currency][0] : CURRENCY['euro'][0]}
                      </td>
                      <td style={{ padding: '5px ' }}>
                        <Form.Control
                          style={{ background: 'none', border: 'none', fontWeight: '300' }}
                          type='text'
                          placeholder={`Ваша цена лота без НДС, ${lot.currency ? CURRENCY[lot.currency][0] : CURRENCY['euro'][0]
                            }`}
                          data-id={12}
                          data-description={`НМЦ лота без НДС, ${lot.currency ? CURRENCY[lot.currency][0] : CURRENCY['euro'][0]
                            }`}
                          onChange={handleChange}
                          // value={returnValue(12)}
                          value={`${sumToLocalString}  ${lot.currency ? CURRENCY[lot.currency][0] : CURRENCY['euro'][0]
                            }`}
                          readOnly={true}
                        />
                      </td>
                      <td style={{ width: '60px' }}>
                        <QuestionToChatBtn
                          handleClick={() =>
                            doChangeVisibleChat(
                              `
                                              ${numberOfLot}
                                            пункт 14.2.; 
                                            "НМЦ лота без НДС - ${lotPriceToLocalString} ${lot.currency ? CURRENCY[lot.currency][0] : CURRENCY['euro'][0]
                              }"`,
                              lot.organizerName
                            )
                          }
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Row> &nbsp; </Row>

            {showFileId && (
              <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
                <Card.Header className='trade_card_header' as='h6'>
                  15. Дополнительные файлы
                </Card.Header>
                <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                  <FileNoModal
                    onClose={empty} // закрытие окна
                    newName={lot.numberLot} // номер ЛОТа или торговой позиции
                    id={idFileParticipant} // ID масива объектов загруженных файлов
                    // id={this.props.fileId} // ann
                    returnId={returnId} // возвращает ID, если его не было при запуске компонента
                    getFilesNames={getIdFileNameParticipant} // массив объектов, инф о файлах (выход инф)
                    toNextTab={empty}
                    disableBtn={false}
                    showNextBtn={false}
                    showDelIcon={true}
                    showCheckIcon={true}
                    showAddBtn={true}
                    asCustomer={false}
                  />
                  <Row> &nbsp;</Row>
                </Card.Body>
              </Card>
            )}

            <Row> &nbsp; </Row>
          </div>

          <Row> &nbsp; </Row>
          <Row> &nbsp; </Row>
          <Row> &nbsp; </Row>
          <AllModalsFooter
            isPosAbsoluteNedded={true}
            footerContent={
              <>
                <ChatButton
                  handleSaveClick={() => doChangeVisibleChat('', '')}
                  needDisable={false}
                />
                {/* <SaveButton handleSaveClick={sendResult} needDisable={false} saveButtonText='Сохранить изменения' /> */}

                <SaveCurrentDataButton
                  // handleSaveClick={sendResult}
                  handleSaveClick={saveDataWithNotification}
                  needDisable={true}
                  disableCondition={errShowGuarant || errShowPayment ? true : false}
                  savedDataMessage={savedDataMessage}
                  buttonTextDefault={'Сохранить'}
                  buttonTextSaved={'Данные сохранены'}
                />

                <SaveCurrentDataButton
                  // handleSaveClick={sendResult}
                  handleSaveClick={sendDataWithNotification}
                  needDisable={true}
                  disableCondition={errShowGuarant || errShowPayment ? true : false}
                  savedDataMessage={sendDataMessage}
                  buttonTextDefault={'Отправить'}
                  buttonTextSaved={'Данные отправлены'}
                  needCustomIcon={true}
                  customIcon={<PresentToAllOutlinedIcon style={{ marginRight: '5px' }} />}
                />

                {/* <SendButton handleSaveClick={sendResultData} needDisable={false} /> */}
              </>
            }
          />
        </div>
      ) : (
        <LoaderPage w='100%' minH='50vh' />
      )}
    </>
  )
}

export default ParticipantLot
