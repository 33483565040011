

/**
 * Меняем стоимость на минимальную - указаный шаг
 * @param {Object} obj новый объект торгов
 * @param {Number} stepTrade шаг торгов
 * @param {Array} minPrice  объект с указанием на минимальную цену
 */
const changeToStepPrice = (obj, stepTrade, minPrice) => {

    console.log(`look trade position:::: `, obj); // test
    console.log(`MIN PRICE >>>>> `, minPrice[0]); // test
    console.log(`STEP:::: `, stepTrade); // test

    // *: пройти по торговым позициям этого obj и изменить цену в торг.позициях в соответсвии с minPrice
    // *: 1. получить минимальные цены торговых позиций с привязкой к ID позиции
    const minPriceObj = minPrice[0].trade.map(item => {
        const priceObj = item.dataComm.find(itm => itm.id === '10')

        return { idPosition: item.idPosition, price: Math.round(Number(priceObj?.value)) }
    })

    // *: 2. пройтись по объекту и изменить стоимость в торговых позициях операясь на ID торг.позиции
    console.log(`minPriceObj:::: `, minPriceObj); // test
    // мапаемся с целью получить торговые позиции и изменить в них цены
    const newTradePosition = minPriceObj.map(item => {
        let tradeP = obj.tradePosition.find(itm => itm.idPosition === item.idPosition) // торговые позиции

        const index = tradeP.dataComm.findIndex(e => e.id === '10')
        console.log(`index >>>>>> `, index); // test
        tradeP.dataComm[index].value = Math.round(Number(item.price) - Number(item.price) * stepTrade)

        return tradeP
    })
    console.log(`newTradePosition:::: `, newTradePosition); // test

    return newTradePosition
}

export default changeToStepPrice;