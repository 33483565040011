/* eslint-disable default-case */

import React from 'react';
import { Container, Spinner } from 'react-bootstrap';

export default class ModalLoad extends React.Component {
    constructor(props) {
        super(props);

        this.arrData = [];

        this.state = {
            show: true
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ show: false })
        }, 3000) // this.props.stopLooking
    }

    render() {

        const show = this.state.show;

        return (
            <>
                <div className="modal_window" style={{ display: show ? 'block' : 'none' }} >
                    <div className="shadow_form"></div>
                    <Container className='modal_main_loading' style={{ backgroundColor: '#dc3545', color: '#fff', zIndex: '999999999', width: '300px' }} >

                        <Spinner animation="border" variant="light" />
                        <div className='f_size_09em'>Ошибка аутентификации! </div>
                    </Container>
                </div>
            </>
        )
    }
}




