import React from 'react';
import { Alert, Container, Form, Row, Col } from 'react-bootstrap';
import SizeFormBig from '../../questionnaire/SizeFormBig';
// import EarbudsIcon from '@mui/icons-material/Earbuds';
import { CloseBtnForFilter } from '../../assets/CloseBtnForFilter';
import Server from '../../server/server';
import { ButtonGroupForFilterForms } from '../../assets/ButtonGroupForFilterForms';

export default class FormLensCompensators extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: false,
            nameForm: 'FormLensCompensators',
            maxId: 5,
            dataValue: [],
            dataOnServer: [],
            dataFromServer: [],
            lengthDataFromServer: 0,
            showQuit: false,
            showSizes: true
        }
        this.doChangeValue = this.doChangeValue.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
        // this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this);
        this.handleClickMaterieals = this.handleClickMaterieals.bind(this);

        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
        this.sendProductDataOnServer = this.sendProductDataOnServer.bind(this)
    }

    async clearFields() {
        console.log(this.state.dataValue);
        await this.setState({
            showSizes: false
        })

        this.setState({
            showSizes: true
        })
    }

    handleClickMaterieals() {
        this.props.onRunMaterials('Four_FormLensCompensators');
    }

    //* empty
    doEmpty() {
    }


    //запуск выхода из формы
    handleClickShadow() {
        this.setState({ showQuit: true })
    }

    //выход с сохранением
    doQuitWithSave() {
        this.setState({ showQuit: false })
        this.handleClickSave();
        this.props.onChangeView(this.state.nameForm);
    }
    //выход без сохранения
    doQuitWithoutSave() {
        this.setState({ showQuit: false })
        this.props.onChangeView(this.state.nameForm);
    }

    //*формируем данные в массив объектов для отправки на сервер
    async handleClickSave(e) {
        e.preventDefault()
        let data = this.state.dataOnServer;

        for (let i = 0; i <= (this.state.maxId); i++) {

            //todo создаем позицию с пустым объектом
            data[i] = { id: '', fid: '', description: '', information: '', value: '' }

            //todo заполняем объект данными с сервера
            this.state.dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
            this.state.dataValue.forEach((dataE) => {
                if (+dataE.id === i) {
                    data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                }
            })

        }
        await this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере

        this.props.getChosenFiltersData(data)
        //отправляем родителю имя этой формы
        // this.props.getFormName(this.state.nameForm)
        //берем данные с сервера
        this.props.saveChosenFilters()


        const result = await Server.filterProductionCompany(data, this.state.nameForm)
        await this.props.sendSearchResultData(result)
        this.props.openResultTable()
        this.props.closeModal(e)
    }

    //* данные с полей формы, формируются в массив объектов
    doChangeValue(data) {
        console.log(data);//test
        this.setState({ dataValue: [...this.state.dataValue, data] })
        setTimeout(() => { console.log(this.state.dataValue) })//test
    }

    onKeyPressHandler(event) {
        event.target.dataset.position = 20
        if (event.keyCode === 13) {
            this.handleClickSave(event)
            console.log(event);
        }
    }

    async sendProductDataOnServer() {
        const productData = {
            product: this.props.product,
            formName: this.state.nameForm
        }
        await Server.sendDataOnServer(productData, 'product', localStorage.getItem('idUser'))
    }

    async componentDidMount() {
        // setTimeout(() => {
        await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer });
        // console.log('DATA on dataFromServer > ', this.props.data);//test
        // }, 1000);

        document.addEventListener('keydown', this.onKeyPressHandler)

        await this.sendProductDataOnServer()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPressHandler)
    }


    render() {

        const view = this.props.view;
        this.value = this.props.data;
        const viewQuest = this.props.viewQuest;
        const SIZES = ['Диаметр, мм', 'Толщина, мм'];
        return (
            <>
                <div className="modal_window" style={{ display: view ? 'block' : 'none' }} >
                    <div className={viewQuest ? "shadow_form" : "shadow_form_off"} onClick={this.props.closeModal}  ></div>
                    <Form className={this.props.chosenCompaniesFromProductionSearch && this.props.chosenCompaniesFromProductionSearch.length >= 0 ? "form_main" : "form_main_1"}  >
                        <Alert
                            variant="dark"
                            className="filter_alert"
                        >
                            <Alert.Heading >Линзовые компенсаторы </Alert.Heading>
                            <CloseBtnForFilter dataId={20} closeModal={this.props.closeModal} />
                        </Alert>
                        <div>
                              <Container className="form_container">
                            <Form.Group>
                                <Row>
                                    <Col>
                                        Размеры линзовых компенсаторов:
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group>
                                {this.state.showSizes && <SizeFormBig
                                    id={1}
                                    width={4}
                                    show={true}
                                    value={this.value}
                                    placeholder={SIZES}//кол-во позиций согласно длине массива
                                    description="Линзовые компенсаторы"
                                    fontSize={12}
                                    onChangeValue={this.doChangeValue}
                                    nameForm={this.state.nameForm}
                                />}
                            </Form.Group>

                            <Row> &nbsp;</Row>
                            <Row>&nbsp;</Row>
                        </Container>
                         <ButtonGroupForFilterForms
                                    dataId={20}
                                    handleClickSave={this.handleClickSave}
                                    clearFields={this.clearFields}
                                    savedFiltersData={this.props.savedFiltersData}
                                />
                        </div>
                      
                    </Form>
                </div>
            </>
        )
    }
}