import React from 'react';
import { Row, Col } from 'react-bootstrap';
import InputForm from '../questionnaire/InputForm';

export default class CommercialTradeAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }

        this.doChangeValue = this.doChangeValue.bind(this);
    }

    doChangeValue(data) {
        this.props.onChangeValue(data);
    }

    render() {

        const id = this.props.id;
        this.value = this.props.value;

        const result = Math.ceil((id - 10) / 2)

        return (
            <>
                <Row>
                    <Col
                        sm={1}
                        style={{
                            marginTop: '40px',
                            marginRight: '-60px'
                        }}
                    >
                        {result + 2}.
                    </Col>
                    <Col style={{ padding: '10px 0px 0px 0px' }}>
                        <InputForm
                            id={+id}
                            // width={12}
                            show={true}
                            // verify="number"
                            label=""
                            placeholder="Название доп. параметра"
                            description="Коммерческие параметры торгов"
                            value={this.value[+id] ? this.value[+id].value : ''}
                            onChangeValue={this.doChangeValue}
                            readOnly={this.props.readOnly}
                        />
                    </Col>
                    <Col style={{ padding: '10px 0px 0px 0px' }}>
                        <InputForm
                            id={+id + 1}
                            width={12}
                            show={true}
                            // verify="number"
                            label=""
                            placeholder="Требование к доп. параметру"
                            description="Коммерческие параметры торгов"
                            value={this.value[+id + 1] ? this.value[+id + 1].value : ''}
                            onChangeValue={this.doChangeValue}
                            readOnly={this.props.readOnly}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}