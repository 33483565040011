import React from 'react';
import { Container, Row } from 'react-bootstrap';
// import Server from '../server/server';
// import InputForm from '../questionnaire/InputForm';

export default class Laboratory extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {

        return (
            <>
                <Container>
                    <Row> &nbsp; </Row>
                    <Row> &nbsp; </Row>
                    <Row> &nbsp; </Row>
                    <Row>
                        <b> Страница в разработке </b>
                    </Row>
                </Container>
            </>
        )
    }
}