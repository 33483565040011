import React, { useEffect, useState } from 'react'
import { Alert, Form, Badge } from 'react-bootstrap'
import Server from '../server/server'
import Questionnaire from '../questionnaire/Questionnaire'
import { shortenName } from '../assets/shortenStringsFunction'

export function QuestionnairyModal({
  closeCompanyDetailsModal,
  companyName,
  infoBlock,
  onUpInfoBlock,
  viewQuest,
  innFromUniversalTable,
  getEconomicData
}) {
  const [dataUser, setDataUser] = useState({})

  useEffect(() => {
    // console.log(`INN Company::: `, innFromUniversalTable)
    // todo: сделать обращение к серверу с ИНН компани, что бы получить данные о пользователе, который зарегистрировал данную компанию
    ;(async () => {
      try {
        const result = await Server.getUserOnINN(innFromUniversalTable)
        console.log(`RESULT:::: `, result)
        setDataUser(result)
      } catch (err) {
        console.log(`Ошибка получения данных ползователя зарегестрировавшего компанию: `, err)
      }
    })()
  }, [])

  // console.log(infoBlock)

  const { surname, patronage, name, login, phone } = dataUser
  const processedCompanyName = shortenName(companyName, 20)
  const processedCompanyNameLonger = shortenName(companyName, 60)

  return (
    <div className='modal_window'>
      <div className={'shadow_quest'}></div>
      <Form className={'form_main_1'} style={{ backgroundColor: '#F4F6F9' }}>
        <Alert
          variant='dark'
          className='filter_alert'
          dismissible
          onClick={closeCompanyDetailsModal}
          style={{
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <h4>Карточка компании</h4>
          <Alert.Heading>
            <div style={{ display: 'flex', gap: '10px' }}>
              {dataUser?.login?.length ? (
                <Badge variant='info' className='company_representetive_in_common_alert'>{`${
                  surname ? surname : ''
                } ${name ? name[0] : ''}.${
                  patronage ? patronage[0] : ''
                }. ${phone} ${login}`}</Badge>
              ) : (
                ''
              )}
              <Badge variant='info' className='company_name_in_common_alert' title={companyName}>
                {companyName && processedCompanyName}
              </Badge>
            </div>
          </Alert.Heading>
        </Alert>

        <div className='form_container'>
          {companyName !== '' && (
            <h5 style={{ textAlign: 'center', color: '#343A40' }}>
              Данные компании: {companyName}
            </h5>
          )}
          <Questionnaire
            infoBlock={infoBlock}
            onUpInfoBlock={onUpInfoBlock}
            viewQuest={viewQuest}
            innFromUniversalTable={innFromUniversalTable}
            getEconomicData={getEconomicData}
            companyName={companyName}
          />
        </div>
      </Form>
    </div>
  )
}
