import React from 'react'
import { Button, Row, Col, Table, Card, Form, Badge } from 'react-bootstrap'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined'
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined'
import ArchiveIcon from '@mui/icons-material/Archive'
import Server from '../server/server'
// import ModalInformation from '../ModalInformation'
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined'

/**
 * titleObjectData - объект: ключ-значение (Object)
 * значение - заглавие таблицы
 * по ключу - строится таблица с данными
 * renderedArray - массив объектов - данные для верстки талицы (Object)
 * id - ОБЯЗАТЕЛЬНОЕ, уникальное поле в renderedArray (Number, String)
 * selectable - true / false - отвечает за просмотр данных (true/false)
 * selectable1 - true / false - отвечает за видимость выбора
 * selectable2 - директит на анкету в режиме чтения (нужно в поиске)
 * rate - рейтинг
 * favourite - избранное
 * TODO: addArchive - иконка добавления в архив (ХЗ пока как организовать)
 * removable - (boolean) включает/отключает ячейку с иконкой удаления
 * mainButtonName - текст кнопки, (String)
 * tableName - название таблицы, (String)
 * editLot - функция, которая редактирует черновики лотов
 * Данные на выходе:
 * renderedArrayWithAdded - (Array of Object) массив объектов с полем added
 * chosenObjectsArray - (Array of Object) массив только выбранных объектов
 * chosenIdsArray - (Array, Object) - массив ID выбранных объектов и выбранный объект
 * chosenObjectId - (Number, String) - ID выбранного объекта для просмотра
 * showMainButton - (boolean) - отображет или скрывает основную кнопку (на которой вмсит событие handleClick)
 * cancelButtonName - текст кнопки отмены ("отменить", "назад" и проч.)
 * showCancelButton - (boolean) - отображет или скрывает кнопку отмены, если она нужна
 * handleCancel - (function) - функция для кнопки отмены
 * showAdditionalButton - (boolean) - отображет или скрывает кнопку дополнительную кнопку
 * handleAdditionalFunctionality - (function) - функция для дополнительной кнопки
 * additionalButtonName - текст дополнительной кнопки
 * handleModalToggle - (function) - обрабатывает закрытие/открытие модалки, если таблица внутри модалки (если нет, передаем empty())
 * viewID - включает возможность получения ID выбранной позиции
 * onChosenIdOnlyOne(e); // выводим пропсом ID выбранной позиции
 * showHowMuchFilled - отображает иконку, по клику на которую заказчик просматривает заполнение формы участником
 * offset - для нумерации страниц, в случае, если отсчет на каждой новой странице начинается заново. Это текущая стр. умноженая на размер стр.
 */

export default class UniversalTable extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      idUser: localStorage.getItem('idUser'),
      language: 'rus',
      show: false,
      text: '',
      showBase: true,
      // colorAdd: false,
      selectable: false, //посмотреть данные
      selectable1: false, //добавить в список
      selectable2: false, //открыть анкету в режиме чтения
      selectable3: false, //редактировать лот (если он в черновиках или плановых)
      selectableCheck: false, // добавляет check Box
      // rate: '',
      favourite: '',
      base: [],
      tableName: '', //смысловая нагрузк в англ - на последнем слове во фразовой конструкции, перевод - "название таблицы"
      titleObjectData: [],
      renderedArray: [], // объект, который рендерится
      addArchive: false, //todo: иконка добалвения в архив
      removable: false,
      isRowGreen: false,
      isNumbered: false,
      starsArray: [],
      heartColor: false,
      stateTest: [],
      participanCheckbox: false,
      starsCountArray: [], //массив для выведения разного кол-ва звезд
      test: '',
      sorted: 'asc'
    }
    this.renderTable = this.renderTable.bind(this)
    this.runRenderTable = this.runRenderTable.bind(this)
    this.openCompanyDataModal = this.openCompanyDataModal.bind(this)
    this.addCompany = this.addCompany.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClickHeader = this.handleClickHeader.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this.selectToFave = this.selectToFave.bind(this)
    this.addArchive = this.addArchive.bind(this)
    this.handleCheckbox = this.handleCheckbox.bind(this)
    this.randomizeStars = this.randomizeStars.bind(this)

    this.handleClickCell = this.handleClickCell.bind(this)
    // this.startModalInformation = this.startModalInformation.bind(this)
    this.setDataStyle = this.setDataStyle.bind(this)
    this.setTableHeaderStyle = this.setTableHeaderStyle.bind(this)
    this.setCellDataStyle = this.setCellDataStyle.bind(this)

    this.addTitleCheckbox = this.addTitleCheckbox.bind(this)
  }

  // * модалка информационная
  // startModalInformation(text) {
  //   this.setState({
  //     text: text
  //   })
  // }

  // todo: Снимаем клик с ячейки
  // * добавлен пропс -> this.props.tableFunction === 'participant'
  handleClickCell(position, data) {
    // console.log(`CLICK CELL id :::::: ${position} => `, data)
    // console.log(`${position}`)
    switch (position) {
      case 'confirmation':
        if (this.props.tableFunction && this.props.tableFunction === 'participant') {
          this.props.openQuestionnairy(data.id)
        } else {
          this.props.toggleFormsFilledByParticipant(data.id)
        }

        break

      case 'nameCompany':
      case 'nameTrade':
      case 'newPrice':
      case 'comm':
        this.props.openQuestionnairy(data.id)
        break

      case 'nameLot':
        if (this.props.tableFunction && this.props.tableFunction === 'participant') {
          this.props.openQuestionnairy(data.id)
        } else {
          this.props.editLot(data.keyNameTrade)
        }
        break

      case 'equipmentName':
      case 'consumerEnterpriseName':
        // case 'manufacturerName':
        // case 'installationName':
        // case 'registrationNumberInDatabase':
        // case 'tubeEnvironmentName':
        // this.props.handleClickPosition(data.id, position, data)
        this.props.handleClickPosition(data.id)
        break
      case 'customer':
        this.props.openQuestionnairy(data.innCustomer, data.id, position)
        break

      case 'manufacturer':
        this.props.openQuestionnairy(data.innManufacturer, data.id, position)
        break

      default:
        break
    }
  }

  async handleCheckbox(e) {
    // console.log(`>>>>>`, e.target.dataset.id, e.target.checked);
    const obj = {
      id: e.target.dataset.id,
      checked: e.target.checked
    }
    this.props.sendCheckboxResult(obj)
  }

  // помещаем в архив
  async addArchive(data) {
    try {
      if (!data.id) {
        throw new Error(`Нет ID у выбранной позиции`)
      }
      this.setState({ showBase: false })
      const resultTrade = await Server.getDataFromServer(data.id, 'trade_position')
      resultTrade.archive = true
      const result = await Server.sendDataOnServer(resultTrade, data.id, 'trade_position')

      const renderNewArray = this.state.renderedArray.map((item) => {
        if (item.id === data.id) {
          item.archive = true
        }
        return item
      })

      const renderNew = renderNewArray.filter((itm) => itm.archive !== true)
      // console.log('renderNew >>> ', renderNew);

      this.setState({ renderedArray: renderNew })

      setTimeout(() => {
        this.runRenderTable()
      })
      setTimeout(() => {
        this.setState({ showBase: true })
      })

      if (result.ERROR) throw new Error(result.message)
    } catch (err) {
      console.log(`Ошибка при добавлении торговой позиции в архив: `, err)
    }
  }

  // возвращаем выбранные данные
  async handleClick() {
    // e.preventDefault()
    // console.log(`CLICK BUTTON`); // test
    const resultChoiceObject = [...this.state.renderedArray].filter((item) => item.added === true) // массив выбранных объектов
    const resultId = resultChoiceObject.map((data) => {
      return data.id
    }) // массив ID выбранных позиций
    // console.log(`FILTER -> `, resultChoiceObject); // test
    // console.log(`ID -> `, resultId); // test

    this.props.renderedArrayWithAdded && this.props.renderedArrayWithAdded(this.state.renderedArray) // все объекты с результатами отбора

    this.props.chosenObjectsArray && this.props.chosenObjectsArray(resultChoiceObject) // только выбранные объекты
    this.props.chosenIdsArray && this.props.chosenIdsArray(resultId)

    this.props.handleModalToggle() //обрабатывает закрытие/открытие модалки, если таблица внутри модалки (если нет, передаем empty())

    if (this.state.renderedArray.some((el) => el.comm)) {
      await Server.sendDataOnServer(this.state.renderedArray, 'productionArray', this.state.idUser)
    } else {
      await Server.sendDataOnServer(resultChoiceObject, 'updatedChosenCompanies', this.state.idUser)
      await Server.sendDataOnServer(
        this.state.renderedArray,
        'companiesArrayFromServer',
        this.state.idUser
      )
    }
  }

  async removeItem(item) {
    // console.log(this.state.renderedArray);
    const newArray = this.state.renderedArray.filter((el) => el.id !== item.id)
    this.props.chosenObjectsArray(newArray)
    const ids = newArray.map((item) => item.id)
    this.props.chosenIdsArray(ids, item)

    const resultAllCompanies = await Server.getDataFromServer(
      'companiesArrayFromServer',
      this.state.idUser
    )
    // console.log('компании - ', resultAllCompanies);
    const resultAllProducts = await Server.getDataFromServer('productionArray', this.state.idUser)
    // console.log('позиции - ', resultAllProducts);

    if (resultAllCompanies && resultAllCompanies.length !== 0 && !resultAllCompanies.ERROR) {
      resultAllCompanies.forEach((el) => {
        if (el.id === item.id) {
          el.added = false
        }
      })
    }

    if (resultAllProducts && resultAllProducts.length !== 0 && !resultAllProducts.ERROR) {
      resultAllProducts.forEach((el) => {
        if (el.id === item.id) {
          el.added = false
        }
      })
    }

    // console.log(resultAllCompanies);
    // console.log(resultAllProducts);

    await Server.sendDataOnServer(resultAllCompanies, 'companiesArrayFromServer', this.state.idUser)
    await Server.sendDataOnServer(resultAllProducts, 'productionArray', this.state.idUser)
  }

  //click on header
  handleClickHeader(title, key) {
    if (title === 'Название') {
      this.setState({ showBase: false })
      const sortDirection = this.state.sorted === 'asc' ? 'desc' : 'asc'
      const sorted = [...this.state.renderedArray].sort((a, b) =>
        sortDirection === 'asc' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key])
      )
      this.setState(
        {
          renderedArray: sorted,
          sorted: sortDirection
        },
        () => {
          this.runRenderTable()
          this.setState({
            showBase: true
          })
        }
      )
    }
  }

  // выбор компаний
  addCompany(e) {
    ; (async () => {
      if (this.props.viewID) {
        this.props.onChosenIdOnlyOne(e) // выводим пропсом ID выбранной позиции
      }

      const newView = this.state.renderedArray.map((data) => {
        if (data.id == e) {
          return Object.assign(data, { added: !data.added })
        } else {
          return data
        }
      })
      await this.setState({ renderedArray: newView })

      this.props.updateObjectWithAdded && this.props.updateObjectWithAdded(this.state.renderedArray)

      const result = this.renderTable()

      this.setState({ base: result })
    })()

    if (this.props.test) {
      const resultChoiceObject = this.state.renderedArray.filter((item) => item.added === true) // массив выбранных объектов
      this.props.chosenObjectsArray && this.props.chosenObjectsArray(resultChoiceObject) // только выбранные объекты
    }
  }

  // компонент ZOOM, просмотр, получает ID
  openCompanyDataModal(id) {
    console.log(`ID >>>>>>> `, id) // test
    this.props.chosenObjectId(id)
  }

  async runRenderTable() {
    //TODO сделать проверку на наличие - added

    if (
      this.state.renderedArray &&
      this.state.renderedArray.length > 0 &&
      this.state.renderedArray[0].added === undefined
    ) {
      const arrData = [...this.state.renderedArray]
      const resultArr = arrData.map((data, index) => {
        return Object.assign(data, { added: false })
      })

      await this.setState({ renderedArray: resultArr })
    }
    const result = this.renderTable()

    await this.setState({ base: result })
  }

  async selectToFave(id) {
    this.state.renderedArray.map((el) => {
      if (el.id === id) {
        return Object.assign(el, { isFave: !el.isFave })
      } else {
        return el
      }
    })
    this.runRenderTable()
  }

  async randomizeStars() {
    let array = []

    this.state.renderedArray.forEach((el, idx) => {
      if (this.state.renderedArray.length <= idx) {
        array.push(Math.ceil(Math.random() * (4 - 1) + 1))
      }
    })

    await this.setState({
      starsCountArray: array
    })
  }

  setDataStyle(data) {
    if (data === 'На заполнении') {
      return <Badge variant='primary'>{data}</Badge>
    } else if (data === 'Заполнено') {
      return <Badge variant='success'>{data}</Badge>
    } else if (data === 'На подтверждении') {
      return <Badge variant='secondary'>{data}</Badge>
    } else if (data === 'Несогласие') {
      return <Badge variant='warning'>{data}</Badge>
    } else if (data === 'Отказ') {
      return <Badge variant='danger'>{data}</Badge>
    } else {
      if (data && data.length && typeof data !== 'number' && data.length >= 70) {
        const res = data.slice(0, 40)
        return `${res}...`
      }

      if (typeof data === 'number') {
        const num = data.toLocaleString('ru')
        return num
      } else {
        return data
      }
    }
  }

  setTableHeaderStyle(thData) {
    if (thData === 'п/п') {
      return 'fw_bolder text_align_center width_50px'
    }
    if (thData === 'Сводная таблица лота' || thData === 'Начальная максимальная цена, руб.') {
      return 'fw_bolder text_align_center width_200px'
    } else {
      return 'fw_bolder text_align_center'
    }
  }

  /**
   * Добавляет иконку открытия для указанных ключей
   * @param {String} item
   * @returns {Boolean}
   */
  setCellDataStyle(item) {
    const array = [
      'equipmentName',
      'consumerEnterpriseName',
      'confirmation',
      'nameCompany',
      'nameLot',
      'nameTrade',
      'comm',
      'newPrice',
      'customer',
      'manufacturer'
    ]
    if (array.includes(item)) {
      return true
    }
    return false
  }

  renderTable() {
    const arrNameHeader = Object.values(this.state.titleObjectData) // массив значений
    const arrKeyHeader = Object.keys(this.state.titleObjectData) // массив ключей
    // ArrowDropUpIcon
    // ArrowDropDownOutlinedIcon

    // рендер заглавия таблицы
    const renderHeader = arrNameHeader.map((data, index) => {
      return (
        <React.Fragment key={arrKeyHeader[index] + 'A'}>
          <th
            className={this.setTableHeaderStyle(data)}
            style={{ cursor: data === 'Название' ? 'pointer' : '' }}
            colspan={data === 'check' && '2'}
            key={arrKeyHeader[index]}
            data-header={arrKeyHeader[index]}
            onClick={() => this.handleClickHeader(data, arrKeyHeader[index])}
          >
            {data}
            {data == 'Название' && this.state.sorted === 'asc' && <ArrowDropDownOutlinedIcon />}
            {data == 'Название' && this.state.sorted === 'desc' && <ArrowDropUpIcon />}
          </th>
        </React.Fragment>
      )
    })

    // рендер данных таблицы
    // console.log(`ARRAY:::: `, this.state.renderedArray); // test

    const renderData = [...this.state.renderedArray].reverse().map((data, index) => {
      // console.log(this.state.renderedArray);
      Object.assign(
        data,
        { numbered: index + 1 + (this.props.offset ? this.props.offset : 0) },
        { rate: `${Math.abs(index - 2)}/5 ` }
      )

      if (this.state.selectableCheck || this.state.selectable1) {
        Object.assign(data, { check: '' })
      }

      // console.log(`DATA >>>>> `, data); //test
      return (
        <React.Fragment key={String(index)}>
          <tr
            className={
              this.state.isRowGreen && data.added ? 'addedToTable cursor_pointer' : 'cursor_pointer'
            }
          >
            {arrKeyHeader.map((item, index) => {
              // console.log(`data[item]: ${data[item]} - ${item}`); // test
              // console.log('here', typeof data[item]);
              return (
                <React.Fragment key={String(`${data[item]}${index}`)}>
                  <td
                    style={{ position: 'relative' }}
                    title={typeof data[item] === 'object' ? '' : data[item]}
                    // className={this.setCellDataStyle(item) ? 'table_td_hover_style' : 'cursor_no_pointer'}
                    className={
                      item === 'titleCheck'
                        ? 'width_50px text_align_center'
                        : this.setCellDataStyle(item)
                          ? 'table_td_hover_style'
                          : 'cursor_no_pointer'
                    }
                    onClick={() => {
                      this.handleClickCell(item, data)
                    }}
                  >
                    {this.setCellDataStyle(item) && (
                      <LaunchOutlinedIcon
                        className='new_window_icon'
                        fontSize='small'
                        color='primary'
                      />
                    )}
                    {this.setDataStyle(data[item])}

                    {this.state.selectableCheck && item === 'titleCheck' && (
                      <input
                        type='checkbox'
                        onChange={() => this.addCompany(data.id)}
                        title='Добавить компанию в список'
                      />
                    )}
                  </td>
                </React.Fragment>
              )
            })}

            {/* {this.state.selectable2 && <td className='width_50 text_align_center' title='Просмотр данных' onClick={() => this.props.openQuestionnairy(data.id)}>
                            <TopicOutlinedIcon
                                // sx={{ color: data.added ? '#28a745' : '#17a2b8' }} 
                                sx={{ color: '#17a2b8' }}
                            />
                        </td>} */}
            {/* {this.state.selectable && <td className='width_50 text_align_center' title='Просмотр данных___' onClick={() => this.openCompanyDataModal(data.id)}>
                            <TopicOutlinedIcon sx={{ color: data.added ? '#28a745' : '#17a2b8' }} />
                        </td>} */}
            {/* {this.state.selectable3 && <td className='width_50 text_align_center' title='Редактировать' onClick={() => this.props.editLot(data.keyNameTrade)}>
                            <EditOutlinedIcon sx={{ color: data.added ? '#28a745' : '#17a2b8' }} />
                        </td>} */}

            {this.state.selectable1 &&
              (!data.added ? (
                <td
                  onClick={() => this.addCompany(data.id)}
                  className='width_50px text_align_center'
                  title='Добавить компанию в список'
                >
                  <AddOutlinedIcon sx={{ color: '#28a745' }} />
                </td>
              ) : (
                <td
                  className='width_50px text_align_center'
                  onClick={() => this.addCompany(data.id)}
                  title='Чтобы удалить компанию, нажмите на галочку'
                >
                  <CheckOutlinedIcon sx={{ color: '#28a745' }} />
                </td>
              ))}

            {this.state.favourite &&
              (data.isFave ? (
                <td
                  className='width_50px text_align_center'
                  onClick={() => this.selectToFave(data.id)}
                  title='Добавить в избранное'
                >
                  <BookmarkOutlinedIcon style={{ color: '#28A745' }} />
                </td>
              ) : (
                <td
                  className='width_50px text_align_center'
                  onClick={() => this.selectToFave(data.id)}
                  title='Добавить в избранное'
                >
                  <BookmarkBorderOutlinedIcon style={{ color: '#28A745' }} />
                </td>
              ))}

            {this.props.showStatus && (
              <>
                {/* <td className='width_50 text_align_center' title='Не просмотрено'>
                                    <CheckBoxOutlineBlankOutlinedIcon style={{ color: '#17a2b8' }} />
                                </td> */}

                {/* <td className='width_50 text_align_center' title='Просмотрено'>
                                    <CheckBoxOutlinedIcon style={{ color: '#17a2b8' }} />
                                </td> */}
                {/* <td className='width_50 text_align_center' title='Технические параметры приняты'>
                                    <EditAttributesOutlinedIcon style={{ color: '#17a2b8' }} />
                                </td> */}
                {/* <td className='width_50 text_align_center' title='Принято'>
                                    <DoneAllOutlinedIcon style={{ color: '#28a745' }} />
                                </td> */}
                {/* <td className='width_50 text_align_center' title='Отклонено'>
                                    <CloseOutlinedIcon style={{ color: '#ED4880' }} />
                                </td> */}
              </>
            )}

            {/* {
                            this.props.showHowMuchFilled &&
                            <td className='width_50 text_align_center' title='Посмотреть заполненность данных участником' onClick={() => this.props.toggleFormsFilledByParticipant(data.id)}>
                                <PendingActionsOutlinedIcon color="primary" />
                            </td>
                        } */}

            {this.state.removable && (
              <td
                className='width_50px text_align_center'
                onClick={() => {
                  this.removeItem(data)
                }}
              >
                <DeleteForeverOutlinedIcon sx={{ color: '#ED4880' }} />
              </td>
            )}

            {this.state.addArchive && (
              <td
                className='width_50px text_align_center'
                onClick={() => {
                  this.addArchive(data)
                }}
                title='Отправить в архив'
              >
                <ArchiveIcon sx={{ color: '#ED4880' }} />
              </td>
            )}

            {this.state.participanCheckbox && (
              <td className='width_50px text_align_center' title='Согласие с условием'>
                <Form.Check data-id={data.id} onChange={this.handleCheckbox} />
              </td>
            )}
          </tr>
        </React.Fragment>
      )
    })
    // console.log(arrNameHeader[0]);
    // совмещаем статичный рендер с динамичным
    const renderTable = (
      <>
        <Card.Header className='mt_10 width_100' as='h6'>
          {' '}
          {this.state.tableName}{' '}
        </Card.Header>
        <Table
          hover
          bordered
          size='sm'
          variant='Light'
          style={{ backgroundColor: '#fff' }}
          className='universal_table'
        >
          <thead style={{ border: '1px #000 solid' }}>
            <tr>{renderHeader}</tr>
          </thead>
          <tbody>{renderData}</tbody>
        </Table>
      </>
    )
    return renderTable
  }

  addTitleCheckbox() {
    if (this.state.selectable3 || this.state.selectableCheck) {
      const titleCheck = this.state.titleObjectData

      Object.assign(titleCheck, { titleCheck: 'Добавить в список' })
      this.setState({ titleObjectData: titleCheck })
    }
  }

  async componentDidMount() {
    await this.setState({
      tableName: this.props.tableName,
      titleObjectData: this.props.titleObjectData,
      renderedArray: this.props.renderedArray,
      selectable: this.props.selectable,
      selectable1: this.props.selectable1,
      selectable2: this.props.selectable2,
      selectable3: this.props.selectable3,
      selectableCheck: this.props.selectableCheck,
      rate: this.props.rate,
      favourite: this.props.favourite,
      removable: this.props.removable,
      addArchive: this.props.addArchive,
      isRowGreen: this.props.isRowGreen,
      isNumbered: this.props.isNumbered,
      participanCheckbox: this.props.participanCheckbox
    })

    this.addTitleCheckbox()
    await this.runRenderTable()
    await this.randomizeStars()

    // console.log(this.props.renderedArray); // test
  }

  async componentDidUpdate(prevProps) {
    if (this.props.isAnotherBtnPressed !== prevProps.isAnotherBtnPressed) {
      this.handleClick()
    }
  }

  render() {
    const showBase = this.state.showBase
    const disabled = this.state.renderedArray.map((el) => el.added)

    return (
      <>
        {showBase && this.state.base}
        <Row>
          <Col className='btn_modal_group'>
            {this.props.showMainButton && (
              <Button
                onClick={this.handleClick}
                variant='success'
                disabled={disabled.find((c) => c === true) ? false : true}
              >
                {this.props.mainButtonName}
              </Button>
            )}

            {this.props.showCancelButton && (
              <Button onClick={this.props.handleCancel} variant='secondary'>
                {this.props.cancelButtonName}
              </Button>
            )}

            {this.props.showAdditionalButton && (
              <Button onClick={this.props.handleAdditionalFunctionality} variant='info'>
                <AddOutlinedIcon className='pb_3' /> {this.props.additionalButtonName}
              </Button>
            )}
          </Col>
        </Row>
      </>
    )
  }
}
