/* eslint-disable default-case */

import React from 'react';
import { Container, Spinner } from 'react-bootstrap';

export default class ModalSave extends React.Component {
    constructor(props) {
        super(props);

        this.arrData = [];

        this.state = {
            show: false
        }
    }

    render() {

        const show = this.props.show;

        return (
            <>
                <div className="modal_window" style={{ display: show ? 'block' : 'none' }} >
                    <div className="shadow_form"></div>
                    <Container className='modal_main_saving'>
                        <Spinner animation="border" variant="secondary" />
                        <div className='f_size_09em'>Сохранение... </div>
                    </Container>
                </div>
            </>
        )
    }
}
