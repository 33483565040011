import React from 'react';
import { Alert, Container, Form, Row } from 'react-bootstrap';
import SizeFormBig from '../../questionnaire/SizeFormBig';
import RadioButtonForm from '../../questionnaire/RadioButtonForm';
// import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import { CloseBtnForFilter } from '../../assets/CloseBtnForFilter';
import Server from '../../server/server';
import { ButtonGroupForFilterForms } from '../../assets/ButtonGroupForFilterForms';

export default class FormBottoms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: false,
            nameForm: 'FormBottoms',
            maxId: 9,
            dataValue: [],
            dataOnServer: [],
            dataFromServer: [],
            lengthDataFromServer: 0,
            showQuit: false,
            showVariants: true
        }
        this.doChangeValue = this.doChangeValue.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
        // this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this);
        this.handleClickMaterieals = this.handleClickMaterieals.bind(this);

        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
        this.sendProductDataOnServer = this.sendProductDataOnServer.bind(this)
    }

    async clearFields() {
        await this.setState({
            showVariants: false
        })

        this.setState({
            showVariants: true
        })
    }

    handleClickMaterieals() {
        this.props.onRunMaterials('Four_FormBottoms');
    }

    //* empty
    doEmpty() {
    }

    //запуск выхода из формы
    handleClickShadow() {
        this.setState({ showQuit: true })
    }

    //выход с сохранением
    doQuitWithSave() {
        this.setState({ showQuit: false })
        this.handleClickSave();
        this.props.onChangeView(this.state.nameForm);
    }
    //выход без сохранения
    doQuitWithoutSave() {
        this.setState({ showQuit: false })
        this.props.onChangeView(this.state.nameForm);
    }

    //*формируем данные в массив объектов для отправки на сервер
    async handleClickSave(e) {
        e.preventDefault()
        let data = this.state.dataOnServer;

        for (let i = 0; i <= (this.state.maxId); i++) {

            //todo создаем позицию с пустым объектом
            data[i] = { id: '', fid: '', description: '', information: '', value: '' }

            //todo заполняем объект данными с сервера
            this.state.dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
            this.state.dataValue.forEach((dataE) => {
                if (+dataE.id === i) {
                    data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                }
            })
        }
        await this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере

        this.props.getChosenFiltersData(data)
        //отправляем родителю имя этой формы
        // this.props.getFormName(this.state.nameForm)
        //берем данные с сервера
        this.props.saveChosenFilters()


        const result = await Server.filterProductionCompany(data, this.state.nameForm)
        await this.props.sendSearchResultData(result)
        this.props.openResultTable()
        this.props.closeModal(e)

    }

    //* данные с полей формы, формируются в массив объектов
    doChangeValue(data) {
        // console.log(data);//test
        this.setState({ dataValue: [...this.state.dataValue, data] })
        // setTimeout(() => { console.log(this.state.dataValue) })//test
    }

    onKeyPressHandler(event) {
        event.target.dataset.position = 19
        if (event.keyCode === 13) {
            this.handleClickSave(event)
        }
    }

    async sendProductDataOnServer() {
        const productData = {
            product: this.props.product,
            formName: this.state.nameForm
        }
        await Server.sendDataOnServer(productData, 'product', localStorage.getItem('idUser'))
    }

    async componentDidMount() {
        // setTimeout(() => {
        await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer });
        // console.log('DATA on dataFromServer > ', this.props.data);//test
        // }, 1000);

        document.addEventListener('keydown', this.onKeyPressHandler)

        await this.sendProductDataOnServer()

    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPressHandler)
    }

    render() {

        const view = this.props.view;
        this.value = this.props.data;
        const viewQuest = this.props.viewQuest;
        const SIZES = ['Диаметр днища, мм', 'Толщина днища, мм'];
        return (
            <>

                <div className="modal_window" style={{ display: view ? 'block' : 'none' }} >
                    <div className={viewQuest ? "shadow_form" : "shadow_form_off"} onClick={this.props.closeModal}  ></div>
                    <Form className={this.props.chosenCompaniesFromProductionSearch && this.props.chosenCompaniesFromProductionSearch.length >= 0 ? "form_main" : "form_main_1"}  >
                        <Alert
                            variant="dark"
                            className="filter_alert"
                        >
                            <Alert.Heading > Поставка днищ </Alert.Heading>
                            <CloseBtnForFilter dataId={19} closeModal={this.props.closeModal} />
                        </Alert>
                        <div>
                            <Container className="form_container">

                                <Form.Label>
                                    Размеры днищ:
                                </Form.Label>

                                <Form.Group>
                                    {this.state.showVariants && <SizeFormBig
                                        id={1}
                                        width={4}
                                        show={true}
                                        value={this.value}
                                        placeholder={SIZES}//кол-во позиций согласно длине массива
                                        description="Поставка днища"
                                        fontSize={12}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />}
                                </Form.Group>
                                <Row>&nbsp;</Row>
                                <Form.Label>
                                    Имеется ли возможность:
                                </Form.Label>
                                {this.state.showVariants && <><Form.Group>
                                    <RadioButtonForm
                                        id={6}
                                        width={12}
                                        show={true}
                                        title="термической обработки днища: "
                                        label1="Да"
                                        label2="Нет"
                                        placeholder="возможность термической обработки днища"
                                        description="Поставка днища"
                                        value={this.value[6] ? this.value[6].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        onChangeVisionBlock={this.doEmpty}
                                        nameForm={this.state.nameForm}
                                    />
                                </Form.Group>
                                    <Form.Group>
                                        <RadioButtonForm
                                            id={7}
                                            width={12}
                                            show={true}
                                            title="проверки материалов, сварных соединений, готовых изделий методами РМК и НРМК: "
                                            label1="Да"
                                            label2="Нет"
                                            placeholder="Проверка материалов, сварных соединений, готовых изделий методами РМК и НРМК: "
                                            description="Поставка днища"
                                            value={this.value[7] ? this.value[7].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doEmpty}
                                            nameForm={this.state.nameForm}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <RadioButtonForm
                                            id={8}
                                            width={12}
                                            show={true}
                                            title='обрезки борта днища:'
                                            label1="Да"
                                            label2="Нет"
                                            placeholder="Обрезка борта днища"
                                            description="Поставка днища"
                                            value={this.value[8] ? this.value[8].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doEmpty}
                                            nameForm={this.state.nameForm}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <RadioButtonForm
                                            id={9}
                                            width={12}
                                            show={true}
                                            title="механической обработки кромки днища:"
                                            label1="Да"
                                            label2="Нет"
                                            placeholder="Механическая обработка кромки днища"
                                            description="Поставка днища"
                                            value={this.value[9] ? this.value[9].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doEmpty}
                                            nameForm={this.state.nameForm}
                                        />
                                    </Form.Group></>}

                                <Row> &nbsp;</Row>
                                <Row>&nbsp;</Row>

                            </Container>
                            <ButtonGroupForFilterForms
                                dataId={19}
                                handleClickSave={this.handleClickSave}
                                clearFields={this.clearFields}
                                savedFiltersData={this.props.savedFiltersData}
                            />
                        </div>

                    </Form>
                    <Row>&nbsp;</Row>
                </div>
            </>
        )
    }
}