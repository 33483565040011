import React from 'react';
import { Container, Form, Row, Button } from 'react-bootstrap';
import ModalQuit from '../ModalQuit';
import SizeFormBig from './SizeFormBig';
import RadioButtonForm from './RadioButtonForm';
import TextareaForm from './TextareaForm';
import CalendarViewWeekOutlinedIcon from '@mui/icons-material/CalendarViewWeekOutlined';
import InputForm from './InputForm';
import CommonAlert from './CommonAlert';
import AllModalsFooter from '../assets/AllModalsFooter';
import { MaterialsButton, SaveButtonGreen, SaveCurrentDataButton } from '../assets/BasicButtons';
import { createData } from './functionsForm';
import { isEqual } from 'lodash-es';

export default class FormBottoms extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: false,
            nameForm: 'FormBottoms',
            maxId: 11,
            dataValue: [],
            dataOnServer: [],
            dataFromServer: [],
            lengthDataFromServer: 0,
            showQuit: false,
            showContent: true,
            previousState: [],
            savedDataMessage: false,
            dataBeenSaved: false
        }
        this.doChangeValue = this.doChangeValue.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
        // this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this);
        this.handleClickMaterieals = this.handleClickMaterieals.bind(this);

        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);
        this.closeQuitModal = this.closeQuitModal.bind(this);
        this.hideContent = this.hideContent.bind(this);
        this.setCompanyName = this.setCompanyName.bind(this);
        this.compare = this.compare.bind(this)
        this.smartWindowFunction = this.smartWindowFunction.bind(this)
        this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
    }

    saveDataWithNotification() {
        this.handleClickSave()
    
        this.setState({
          savedDataMessage: true,
          dataBeenSaved: true
        })
        setTimeout(() => {
          this.setState({
            savedDataMessage: false
          })
        }, 2000)
      }

    hideContent() {
        this.setState({ showContent: false })
    }


    setCompanyName() {
        if (this.props.companyName && this.props.companyName.length > 0) {
            this.setState({
                companyName: this.props.companyName
            })
        }
    }

    closeQuitModal() {
        this.setState({
            showQuit: false
        })
    }

    handleClickMaterieals() {
        this.props.onRunMaterials('Four_FormBottoms');
    }

    //* empty
    doEmpty() {
    }

    //*
    // doChangeVisionBlock(e) {
    //     if (e === 'Да') {
    //         this.setState({ show: true })
    //     }
    //     if (e === 'Нет') {
    //         this.setState({ show: false })
    //     }
    // }

    //запуск выхода из формы
    handleClickShadow() {
        this.setState({ showQuit: true })
    }

    //выход с сохранением
    doQuitWithSave() {
        this.setState({ showQuit: false })
        this.handleClickSave();
        this.props.onChangeView(this.state.nameForm);
    }
    //выход без сохранения
    doQuitWithoutSave() {
        this.setState({ showQuit: false })
        this.props.onChangeView(this.state.nameForm);
    }

    //*формируем данные в массив объектов для отправки на сервер
    handleClickSave() {
        let data = this.state.dataOnServer;

        for (let i = 0; i <= (this.state.maxId); i++) {

            //todo создаем позицию с пустым объектом
            data[i] = { id: '', fid: '', description: '', information: '', value: '' }

            //todo заполняем объект данными с сервера
            this.state.dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
            this.state.dataValue.forEach((dataE) => {
                if (+dataE.id === i) {
                    data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                }
            })
        }
        this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере
        if (this.state.lengthDataFromServer < 1) {
            // console.log('CREATE DATA');
            this.props.onCreateDataServer(data, this.state.nameForm, localStorage.getItem('idUser'));
        } else {
            // console.log('UPDATE DATA');
            this.props.onUpdateDataOnServer(data, this.state.nameForm, localStorage.getItem('idUser'));
        }
        setTimeout(() => { console.log(this.state.dataOnServer) })//test
        // this.handleClickShadow();
    }

    //* данные с полей формы, формируются в массив объектов
    doChangeValue(data) {
        console.log(data);//test
        this.setState({ dataValue: [...this.state.dataValue, data] })
        this.setState({
            dataBeenSaved: false
          })
    }

    compare() {
        const prev = this.state.previousState
        const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)
    
        const isStateEqual = isEqual(prev, curr)
        if (isStateEqual) {
          return true
        }
        return false
      }
    
      smartWindowFunction() {
        const isStateEqual = this.compare()
        const { dataBeenSaved,  dataValue} = this.state
        if (isStateEqual || dataBeenSaved || dataValue.length === 0) {
          this.props.onChangeView(this.state.nameForm)
        } else {
          this.handleClickShadow()
        }
      }

    async componentDidMount() {
        // setTimeout(() => {
        await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer });
        // console.log('DATA on dataFromServer > ', this.props.data);//test
        // }, 1000);
        this.setCompanyName()

        if (this.props.data.length) {
            const previousState = JSON.parse(JSON.stringify(this.props.data))
            this.setState({ previousState })
          }
    }

    render() {

        const view = this.props.view;
        this.value = this.props.data;
        const viewQuest = this.props.viewQuest;
        const SIZES = ['Мин диаметр днища, мм', 'Макс диаметр днища, мм', 'Макс толщ днища, мм', 'Мин толщ днища, мм'];

        //условие по которому компонент определяет находится ли он в формате чтения
        const inReadMode = this.props.innFromUniversalTable === null ||
            this.props.innFromUniversalTable === '' ||
            this.props.innFromUniversalTable === undefined

        console.log(this.state.dataValue);
        return (
            <>
                {inReadMode &&
                    <>{this.state.showQuit ?
                        <ModalQuit
                            onQuitWithSave={this.doQuitWithSave}
                            onQuitWithoutSave={this.doQuitWithoutSave}
                            closeQuitModal={this.closeQuitModal}
                        />
                        : ''}</>}

                {this.state.showContent &&
                    <div className="modal_window" style={{ display: view ? 'block' : 'none' }} >
                        <div className={viewQuest ? "shadow_form" : "shadow_form_off"} 
                        // onClick={this.handleClickShadow}  
                        onClick={this.smartWindowFunction}
                        ></div>
                        <Form className={viewQuest ? "form_main" : "form_main_view"} >

                            <CommonAlert
                                inReadMode={inReadMode}
                                handleClickHeader={this.handleClickHeader}
                                // handleClickShadow={this.handleClickShadow}
                                handleClickShadow={this.smartWindowFunction}
                                hideContent={this.hideContent}
                                icon={<CalendarViewWeekOutlinedIcon fontSize="large" className='icon_primary' />}
                                companyName={this.state.companyName}
                                sectionName='Поставка днищ'

                            />
                            <div>
                                <Container className="form_container">

                                    <Form.Label>
                                        Размеры днищ:
                                    </Form.Label>

                                    <Form.Group>
                                        <SizeFormBig
                                            id={1}
                                            width={3}
                                            // show={true}
                                            value={this.value}
                                            placeholder={SIZES}//кол-во позиций согласно длине массива
                                            description="Поставка днища"
                                            fontSize={12}
                                            onChangeValue={this.doChangeValue}
                                            nameForm={this.state.nameForm}
                                            readOnly={!inReadMode}
                                        />
                                    </Form.Group>
                                    <Row>&nbsp;</Row>
                                    <Form.Label>
                                        Имеется ли возможность:
                                    </Form.Label>
                                    <Form.Group>
                                        <RadioButtonForm
                                            id={6}
                                            width={12}
                                            show={true}
                                            title="термической обработки днища: "
                                            label1="Да"
                                            label2="Нет"
                                            placeholder="возможность термической обработки днища"
                                            description="Поставка днища"
                                            value={this.value[6] ? this.value[6].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doEmpty}
                                            nameForm={this.state.nameForm}
                                            readOnly={!inReadMode}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <RadioButtonForm
                                            id={7}
                                            width={12}
                                            show={true}
                                            title="проверки материалов, сварных соединений, готовых изделий методами РМК и НРМК: "
                                            label1="Да"
                                            label2="Нет"
                                            placeholder="Проверка материалов, сварных соединений, готовых изделий методами РМК и НРМК: "
                                            description="Поставка днища"
                                            value={this.value[7] ? this.value[7].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doEmpty}
                                            nameForm={this.state.nameForm}
                                            readOnly={!inReadMode}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <RadioButtonForm
                                            id={8}
                                            width={12}
                                            show={true}
                                            title='обрезки борта днища:'
                                            label1="Да"
                                            label2="Нет"
                                            placeholder="Обрезка борта днища"
                                            description="Поставка днища"
                                            value={this.value[8] ? this.value[8].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doEmpty}
                                            nameForm={this.state.nameForm}
                                            readOnly={!inReadMode}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <RadioButtonForm
                                            id={9}
                                            width={12}
                                            show={true}
                                            title="механической обработки кромки днища:"
                                            label1="Да"
                                            label2="Нет"
                                            placeholder="Механическая обработка кромки днища"
                                            description="Поставка днища"
                                            value={this.value[9] ? this.value[9].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doEmpty}
                                            nameForm={this.state.nameForm}
                                            readOnly={!inReadMode}
                                        />
                                    </Form.Group>

                                    <Form.Group>
                                        {inReadMode &&
                                            <>
                                                <Row> &nbsp;</Row>
                                                <Row> &nbsp;</Row>
                                                <InputForm
                                                    id={10}
                                                    width={12}
                                                    show={true}
                                                    // verify="number"
                                                    label={""}
                                                    fontSize={12}
                                                    placeholder="Комментарий:"
                                                    description={this.props.description}
                                                    value={this.value[10] ? this.value[10].value : ''}
                                                    onChangeValue={this.doChangeValue}
                                                    nameForm={this.state.nameForm}
                                                />

                                                <TextareaForm
                                                    id={11}
                                                    width={12}
                                                    label=""
                                                    description="Дополнения к данному разделу"
                                                    placeholder="Дополнительная информация по данному виду деятельности (оборудование, технологии, автоматизация и др."
                                                    value={this.value[11] ? this.value[11].value : ''}
                                                    show={true}
                                                    rows={3}
                                                    onChangeValue={this.doChangeValue}
                                                />
                                            </>}
                                        <Row> &nbsp;</Row>
                                        <Row>&nbsp;</Row>



                                    </Form.Group>
                                    <Row>&nbsp;</Row>

                                </Container>
                                <AllModalsFooter
                                    footerContent={<>
                                        {/* <Button variant="primary" onClick={this.handleClickMaterieals} >Используемые материалы</Button> */}
                                        <MaterialsButton handleSaveClick={this.handleClickMaterieals} needDisable={false} />
                                        {inReadMode &&
                                        //   <SaveButtonGreen
                                        //   handleSaveClick={this.handleClickSave}
                                        //   needDisable={false}
                                        //   saveButtonText='Сохранить'
                                        // />
                                        <SaveCurrentDataButton
                          handleSaveClick={this.saveDataWithNotification}
                          needDisable={false}
                          savedDataMessage={this.state.savedDataMessage}
                          buttonTextDefault={'Сохранить'}
                          buttonTextSaved={'Данные сохранены'}
                          needCustomIcon={false}
                        />
                    
                                            // <Button variant="success" onClick={this.handleClickSave} >Сохранить</Button>
                                        }
                                    </>}
                                />
                            </div>

                        </Form>
                    </div>}

            </>
        )
    }
}