/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Form, Row } from 'react-bootstrap';
import InputForm from './InputForm';
import SelectForm from './SelectForm';
import SelectForm_City from './SelectForm_City';
import { CITY } from './ConstCity';

export default class Form_Main_Add extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            language: 'rus'
        }

        this.doChangeValue = this.doChangeValue.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
    }

    //* Пустышка
    doEmpty() {

    }

    //* данные с полей формы, формируются в массив объектов
    doChangeValue(data) {
        this.props.onChangeValue(data);
    }

    componentDidMount() {

    }

    render() {

        const id = this.props.id;
        this.value = this.props.value;
        const city = CITY;

        return (

            <React.Fragment>
                <Form.Group>
                    <Row>&nbsp;</Row>
                    {`Филиал ${Math.round((((+id / 60) - 1) * 10) + 1)}:`}
                    <Row>
                        <InputForm
                            id={+id}
                            width={4}
                            show={true}
                            verify="number"
                            label=""
                            placeholder="Индекс"
                            description="Предприятие, филиал адрес"
                            value={this.value[+id] ? this.value[+id].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.props.nameForm}
                        />
                        <SelectForm
                            id={+id + 1}
                            width={4}
                            show={true}
                            label=""
                            placeholder="Страна"
                            description="Предприятие, филиал адрес"
                            option="Россия, не Россия"
                            value={this.value[+id + 1] ? this.value[+id + 1].value : ''}
                            onChangeValue={this.doChangeValue}
                            onChangeVisionBlock={this.doEmpty}
                            nameForm={this.props.nameForm}
                        />
                        <SelectForm_City
                            id={+id + 2}
                            width={4}
                            show={true}
                            label=""
                            placeholder="Город"
                            description="Предприятие, филиал адрес"
                            option={city}
                            value={this.value[+id + 2] ? this.value[+id + 2].value : ''}
                            onChangeValue={this.doChangeValue}
                            onChangeVisionBlock={this.doEmpty}
                            nameForm={this.props.nameForm}
                        />
                    </Row>
                    <Row>
                        <InputForm
                            id={+id + 3}
                            width={6}
                            show={true}
                            // verify="number"
                            label=""
                            placeholder="Улица"
                            description="Предприятие, филиал адрес"
                            value={this.value[+id + 3] ? this.value[+id + 3].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.props.nameForm}
                        />
                        <InputForm
                            id={+id + 4}
                            width={3}
                            show={true}
                            verify="number"
                            label=""
                            placeholder="Дом"
                            description="Предприятие, филиал адрес"
                            value={this.value[+id + 4] ? this.value[+id + 4].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.props.nameForm}
                        />
                        <InputForm
                            id={+id + 5}
                            width={3}
                            show={true}
                            // verify="number"
                            label=""
                            placeholder="Корпус"
                            description="Предприятие, филиал адрес"
                            value={this.value[+id + 5] ? this.value[+id + 5].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.props.nameForm}
                        />
                    </Row>
                </Form.Group>
            </React.Fragment>

        )
    }
}