import React, { useState, useEffect } from 'react'
import Server from '../server/server'
import { Form } from 'react-bootstrap'
import { TIMEZONE } from '../assets/timezone'

/**
 * Рендерит Timezone
 * @returns
 */
const Timezone = () => {
    const [timezone, setTimezone] = useState()
    let tzD = 'Москва UTC+3 (MSK)'

    useEffect(() => {
        ; (async () => {
            try {
                // const tz = await Server.getDataFromServer('timezone', localStorage.getItem('idUser'))
                // console.log(`TIMEZONE::::::: `, Intl.DateTimeFormat().resolvedOptions().timeZone) // test
                const offset = - (new Date().getTimezoneOffset()) / 60;
                // console.log(`offset:::: `, offset); // test

                const textTZ = String(Intl.DateTimeFormat().resolvedOptions().timeZone)
                const tz = { timezone: `${textTZ} UTC+${offset} (MSK${(offset - 3) > 0 ? '+' : ''}${(offset - 3) === 0 ? '' : (offset - 3)})` }
                tzD = tz.timezone
                // console.log(`>>>>>>>>>>>>>>>>>>>>>>>>>> TIMEZONE result::::::::::::: `, tz) // test
                setTimezone(tz.timezone)
                newTimezone() // todo: Принудительно определяем и переписываем часовой пояс

            } catch (err) {
                console.log(`Ошибка при загрузке временной зоны: `, err)
                newTimezone()
            }
        })()
    }, [])

    /**
     * Если Часовой пояс не указан
     * @returns 
     */
    const newTimezone = () => [
        (async () => {
            try {
                setTimezone(tzD)
                const data = { timezone: tzD };
                await Server.sendDataOnServer(data, 'timezone', localStorage.getItem('idUser'))

            } catch (err) {
                console.log(`Ошибка записи часовой зоны по умолчанию: `, err);
            }
        })()
    ]


    // const onChangeHandler = (e) => {
    //     (async () => {
    //         try {
    //             const { value } = e.target
    //             setTimezone(value)
    //             console.log(`timezone new:::: `, value); // test
    //             const data = { timezone: value };
    //             await Server.sendDataOnServer(data, 'timezone', localStorage.getItem('idUser'))

    //         } catch (err) {
    //             console.log(`Ошибка при сохранении нового часового пояса: `, err);
    //         }
    //     })()
    // }

    return (
        <>
            <Form.Control
                style={{ width: '300px' }}
                name={''}
                // as='select'
                placeholder={``}
                value={timezone}
                disabled
            // onChange={onChangeHandler}
            >
                {/* <option style={{ color: '#ccc' }} value='' disabled selected hidden>
                    Выберите часовой пояс
                </option>
                {TIMEZONE.map((tz) => {
                    return <option key={tz}>{tz}</option>
                })} */}
            </Form.Control>
        </>
    )
}
export default Timezone
