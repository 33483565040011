import React, { useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import GeneralCharacteristicsTable from './GeneralCharacteristicsTable'
import GeneralTable from './GeneralTable'
import Server from '../../server/server'

export default function PipeStateModal({ alertHeading, toggleModal, content, id, }) {

  const [savedData, setSavedData] = useState([])

  const setPrefix = () => {
    if (content === 'Межтрубное пространство') {
      return 'SHELL_'
    }
    if (content === 'Трубное пространство') {
      return 'TUBE_'
    }
    if (content === 'Общие характеристики') {
      return 'GENERAL_'
    }
  }

  const currentId = `${setPrefix()}${id}`



  const doGetTableDataSHELL = (tableData) => {
    ; (async () => {
      const id = `SHELL_${tableData[0].id}`
      // console.log(`ID: ${id}`) // test
      sendDataOnServer(id, tableData)
    })()
  }

  const doGetTableDataTUBE = (tableData) => {
    ; (async () => {
      const id = `TUBE_${tableData[0].id}`
      // console.log(`ID: ${id}`) // test
      sendDataOnServer(id, tableData)
    })()
  }

  const doGetTableDataGENERAL = (tableData) => {
    ; (async () => {
      const id = `GENERAL_${tableData[0].id}`
      // console.log(`ID: ${id}`) // test
      sendDataOnServer(id, tableData)
    })()
  }

  /**
   * Функция отрпавки данных на сервер, обновляет данные целиком
   * @param {*} id ID объекта
   * @param {*} data данные объекта
   */
  const sendDataOnServer = async (id, data) => {
    try {
      const result = await Server.sendDataOnServer(data, id, localStorage.getItem('idUser'))
      // console.log(`RESULT: `, result) // test
      // console.log(id)
    } catch (err) {
      console.log(`Ошибка сохранения данных на сервере: `, err)
    }
  }

  const getDataFromServer = async (id) => {
    try {
      const data = await Server.getDataFromServer(id, localStorage.getItem('idUser'))
      // здесь обновите состояние своего компонента с полученными данными, если требуется
      // todo: Требуется обработка ошибок
      // console.log(data);
      setSavedData(data)
    } catch (err) {
      console.log(`Ошибка получения данных с сервера: `, err)
    }
  }

  useEffect(() => {
    getDataFromServer(currentId)
  }, [currentId])

  // console.log(currentId);

  return (
    <>
      <div className='shadow_form' style={{ zIndex: '1001' }} onClick={toggleModal}></div>
      <div className='modal_common_table' style={{ zIndex: '1002' }}>
        <Alert variant='dark' onClose={toggleModal} dismissible>
          <Alert.Heading>{alertHeading}</Alert.Heading>
        </Alert>

        {content === 'Межтрубное пространство' && (
          <GeneralTable id={id} doGetTableData={doGetTableDataSHELL} savedData={savedData} />
        )}
        {content === 'Трубное пространство' && (
          <GeneralTable id={id} doGetTableData={doGetTableDataTUBE} savedData={savedData} />
        )}
        {content === 'Общие характеристики' && (
          <GeneralCharacteristicsTable id={id} doGetTableData={doGetTableDataGENERAL} savedData={savedData} />
        )}
      </div>
    </>

    
  )
}
