import React, { useState, useEffect } from 'react'
import { Card, Row, Table } from 'react-bootstrap'
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined'
import CheckTable from './CheckTable'
import { isEqual } from 'lodash-es'

export const GeneralDataTradeParticipant = ({
  dataFromTech,
  dataFromCommerce,
  name,
  filesNamesArr,
  sendResultData,
  keyNameTrade,
  value,
  runChat,
  organizerName,
  lotNum,
  disableParticipantData,
//   currentState
}) => {
  const [baseCommerce, setBaseCommerce] = useState('')
  const [baseTech, setBaseTech] = useState('')
  const [resultDataTech, setResultDataTech] = useState([])
  const [resultDataComm, setResultDataComm] = useState([])
  const [toggleValue, setToggleValue] = useState(true)

//   const [previousState, setPreviousState] = useState([])

//   useEffect(() => {
//     const previous = JSON.parse(JSON.stringify(value))
//     if (value.idLot) {
//       setPreviousState(previous.tradePosition[1])
//     }
//   }, [value.idLot])


  useEffect(() => {
    if (dataFromCommerce.length !== 0 && dataFromTech.length !== 0) {
      loadGeneralData(dataFromCommerce, 11)
      loadGeneralData(dataFromTech, 1)
    }
  }, [dataFromTech, dataFromCommerce])

  useEffect(() => {
    doResultData(resultDataTech, resultDataComm)
  }, [resultDataTech, resultDataComm])

  useEffect(() => {
    if (value && keyNameTrade) {
      const valuePosition = value.tradePosition.find(item => item.idPosition === keyNameTrade) // получаем нужную  position

      if (valuePosition) {
        changeDataPosition(valuePosition.dataTech, valuePosition.dataComm)
      }
    }
  }, [value, keyNameTrade])

  const changeDataPosition = (tech, comm) => {
    setResultDataTech(tech)
    setResultDataComm(comm)
  }

//   const compare = () => {
//     const isStateChanged = isEqual(previousState, currentState)
//     if (isStateChanged) {
//       return true
//     }
//     return false
//   }

//   const smartWindowFunction = () => {
//     const isStateChanged = compare()
//     if(isStateChanged){
//         console.log('вызвать подтверждающую модалку');
//     } else {
//         console.log('просто закрыть модалку');
//     }
//   }

  //* возвращаем value Tech
  const returnValueTech = id => {
    if (resultDataTech.find(item => item.id === String(id))) {
      return resultDataTech.find(item => item.id === String(id)).value
    }
  }

  //* возвращаем value Comm
  const returnValueComm = id => {
    if (resultDataComm.find(item => item.id === String(id))) {
      return resultDataComm.find(item => item.id === String(id)).value
    }
  }

  const doResultData = (tech, comm) => {
    const obj = {
      idPosition: keyNameTrade,
      namePosition: name,
      idFilePosition: `${keyNameTrade}_user_${localStorage.getItem('idUser')}`, // ! ******************
      dataTech: tech,
      dataComm: comm
    }

    sendResultData(obj)
  }

  const loadGeneralData = (array, sliceIdx) => {
    if (array) {
      const result = array.slice(sliceIdx)
      let renderedArray = []
      let data = {}

      result.forEach((el, index) => {
        if (el) {
          if (+el.id % 2 !== 0) {
            data = {
              key: el.value,
              value: result[index + 1] && result[index + 1].value !== '' ? result[index + 1].value : <i>no data</i>
            }

            renderedArray.push(data)
          }

          if (+el.id % 2 === 0) {
            data = {
              key: result[index - 1] ? result[index - 1].value : <i>no data</i>,
              value: el.value
            }
            renderedArray.push(data)

            const uniqueArray = renderedArray.filter((element, index) => {
              return (
                index ===
                renderedArray.findIndex(obj => {
                  return JSON.stringify(obj) === JSON.stringify(element)
                })
              )
            })

            renderedArray = uniqueArray
          }
        }
      })
      if (sliceIdx === 11) {
        setBaseCommerce(renderedArray)
      } else {
        setBaseTech(renderedArray)
      }
    }
  }

  // изменения значений
  const handleChange = e => {
    let val

    if (e.target.type === 'text' && isFinite(e.target.value)) {
      val = e.target.value
    } else {
      val = 0
    }

    if (e.target.type === 'checkbox' && e.target.dataset.description === 'Количество штук') {
      setToggleValue(!toggleValue)

      if (toggleValue) {
        val = e.target.value
      } else {
        val = 0
      }
    } else if (e.target.type === 'checkbox' && e.target.dataset.description !== 'Количество штук') {
      val = e.target.checked
    }

    const result = {
      id: e.target.dataset.id,
      description: e.target.dataset.description,
      value: val
    }

    // console.log(`RESULT:::::::: `, result); // test

    if (e.target.dataset.position === 'tech') {
      const newResultTech = resultDataTech.filter(item => item.id !== e.target.dataset.id)
      newResultTech.push(result)

      const serialArrTech = newResultTech.sort((a, b) => {
        return Number(a.id) - Number(b.id)
      })

      setResultDataTech(serialArrTech)
    }

    if (e.target.dataset.position === 'commerce') {
      const newResultComm = resultDataComm.filter(item => item.id !== e.target.dataset.id)
      newResultComm.push(result)

      const serialArrComm = newResultComm.sort((a, b) => {
        return Number(a.id) - Number(b.id)
      })

      setResultDataComm(serialArrComm)
    }

    
  }

  // переход к чату
  const handleClickChat = (text, recipient) => {
    // console.log(` CHAT -> TEXT: `, text); // test
    runChat(text, recipient)
  }

  // console.log(lotNum);

  // const test = Number(dataFromCommerce[10] && dataFromCommerce[10].value)
  // const newTest = test && test.toLocalString()

  return (
    <>
      <Row>&nbsp;</Row>

      <div className='overflow_x_hidden' style={{ padding: '0 15px', height: '500px', marginBottom: '50px' }}>
        <Card.Header className='box_shadow text_align_center trade_card_header fw_bold_color_dark_grey'>
          Название позиции: <b>{name}</b>
        </Card.Header>
        <Row> &nbsp;</Row>
        <Card className='box_shadow'>
          <Card.Header className='width_100pr text_align_center trade_card_header fw_bold_color_dark_grey'>
            Технические требования
          </Card.Header>
          <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
            <Table hover style={{ backgroundColor: '#fff' }} bordered size='sm'>
              <thead>
                <tr style={{ textAlign: 'center' }}>
                  <th className='fw_bolder' style={{ width: '5%' }}>
                    п/п
                  </th>
                  <th className='fw_bolder' style={{ width: '40%' }}>
                    Название параметра
                  </th>
                  <th className='fw_bolder' style={{ width: '40%' }}>
                    Требование к параметру
                  </th>
                  <th className='fw_bolder' style={{ width: '7.5%' }}>
                    Согласие с условиями
                  </th>
                  <th className='fw_bolder' style={{ width: '7.5%' }}>
                    Задать вопрос
                  </th>
                </tr>
              </thead>
              <tbody>
                {baseTech &&
                  baseTech.map((el, idx) => (
                    <React.Fragment key={el.key}>
                      <tr key={idx}>
                        <td style={{ textAlign: 'center' }}>{idx + 1}</td>
                        <td>{el.key}</td>
                        <td>{el.value}</td>

                        <CheckTable
                          type='checkbox'
                          id={idx}
                          position='tech'
                          plsh={`Указать свои значения`}
                          description={el.key}
                          secondDescription={el.value}
                          handleChange={handleChange}
                          handleClickChat={handleClickChat}
                          checked={returnValueTech(idx)}
                          organizerName={organizerName}
                          number={idx + 1}
                          lotNum={lotNum}
                          name={name}
                          disableParticipantData={disableParticipantData}
                        />
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Row> &nbsp;</Row>

        <Card className='box_shadow'>
          <Card.Header className='width_100pr text_align_center trade_card_header fw_bold_color_dark_grey'>
            Коммерческие требования
          </Card.Header>
          <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
            <Table hover style={{ backgroundColor: '#fff' }} bordered size='sm'>
              <thead>
                <tr style={{ textAlign: 'center' }}>
                  <th className='fw_bolder' style={{ width: '5%' }}>
                    п/п
                  </th>
                  <th className='fw_bolder' style={{ width: '40%' }}>
                    Параметры
                  </th>
                  <th className='fw_bolder' style={{ width: '30%' }}>
                    Требования к параметрам
                  </th>
                  <th className='fw_bolder' style={{ width: '17.5%' }}>
                    Согласие с условиями
                  </th>
                  <th className='fw_bolder' style={{ width: '7.5%' }}>
                    {' '}
                    &nbsp;
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataFromCommerce && dataFromCommerce.length !== 0 && (
                  <>
                    <tr>
                      <td style={{ textAlign: 'center' }}>{1}</td>
                      <td>Количество штук: </td>
                      <td>{dataFromCommerce[6] ? dataFromCommerce[6].value : 1}</td>

                      <CheckTable
                        type='checkbox'
                        // type='text'
                        id={6}
                        data-id='6'
                        position='commerce'
                        plsh={`Указать свои значения`}
                        description={`Количество штук`}
                        secondDescription={dataFromCommerce[6] ? dataFromCommerce[6].value : 1}
                        handleChange={handleChange}
                        handleClickChat={handleClickChat}
                        checked={returnValueComm(6) ? true : false}
                        // value={returnValueComm(6)}
                        value={dataFromCommerce[6] ? dataFromCommerce[6].value : 1}
                        organizerName={organizerName}
                        number={'1'}
                        lotNum={lotNum}
                        name={name}
                        disableParticipantData={disableParticipantData}
                      />
                    </tr>
                    <tr>
                      <td style={{ textAlign: 'center' }}>{2}</td>
                      <td>Начальная минимальная цена за штуку, руб.: </td>
                      <td>{dataFromCommerce[10] ? Number(dataFromCommerce[10].value).toLocaleString() : <i>no data</i>}</td>

                      <CheckTable
                        type='text'
                        id={10}
                        data-id='10'
                        position='commerce'
                        plsh={`Указать свои значения`}
                        description={`НМЦ позиции`}
                        secondDescription={dataFromCommerce[10] ? dataFromCommerce[10].value : <i>1</i>}
                        handleChange={handleChange}
                        handleClickChat={handleClickChat}
                        value={returnValueComm(10)}
                        organizerName={organizerName}
                        number={'2'}
                        lotNum={lotNum}
                        name={name}
                        disableParticipantData={disableParticipantData}
                      />
                    </tr>
                  </>
                )}

                {baseCommerce &&
                  baseCommerce.map((el, idx) => (
                    <tr key={idx}>
                      <td style={{ textAlign: 'center' }}>{idx + 3}</td>
                      <td>{el.key}</td>
                      <td>{el.value}</td>

                      <CheckTable
                        type='checkbox'
                        id={idx}
                        position='commerce'
                        plsh={`Указать свои значения`}
                        description={el.key}
                        secondDescription={el.value}
                        handleChange={handleChange}
                        handleClickChat={handleClickChat}
                        checked={returnValueComm(idx)}
                        organizerName={organizerName}
                        number={`${idx + 3}`}
                        lotNum={lotNum}
                        name={name}
                        disableParticipantData={disableParticipantData}
                      />
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <Row>&nbsp;</Row>

        {filesNamesArr && filesNamesArr.length !== 0 ? (
          <>
            <p style={{ fontWeight: 'bolder' }}>Прикрепленные файлы: </p>
            <ol>
              {filesNamesArr.map((el, idx) => (
                <li key={idx}>{`${el.descriptionFile ? el.descriptionFile : 'Название файла отсутствует'} (${
                  el.oldNameFile
                })`}</li>
              ))}
            </ol>
          </>
        ) : (
          <p style={{ fontWeight: 'bolder' }}>
            <PriorityHighOutlinedIcon className='pb_3' fontSize='small' color='action' />
            Нет прикрепленных файлов
          </p>
        )}
      </div>
      {/* <AllModalsFooter
            isPosAbsoluteNedded={true}
            footerContent={
                // <>ggg</>
               <SaveCurrentDataButton
               handleSaveClick={handleClickSaveData}
               needDisable={false}
            //    disableCondition={}
               savedDataMessage={savedDataMessage}
               buttonTextDefault={'Сохранить'}
               buttonTextSaved={'Данные сохранены'}
               
               />
            }
            /> */}
    </>
  )
}
