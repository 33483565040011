import React from 'react';
import InputMask from 'react-input-mask';

//* https://www.npmjs.com/package/react-input-mask - масска для всего

export class EmployeesPhoneForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            language: 'rus',
            id: '',
            fid: '',
            description: '',
            information: '',
            value: '',
            colorError: '#fff'
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

    }

    async handleBlur(e) {
        await this.setState({
            id: e.target.dataset.id,
            fid: `${this.props.nameForm}_${this.props.id}`,
            description: e.target.dataset.description,
            information: e.target.dataset.information
        })
        this.props.onChangeValue(this.state)
    }

    handleChange(e) {
        e.preventDefault();
        this.setState({ value: e.target.value })
    }


    async componentDidMount() {
        await this.setState({ value: this.props.value })
    }

    render() {
        const id = this.props.id;
        const description = this.props.description;
        const placeholder = this.props.placeholder;
        const value = this.state.value; // **********



        return (
            <React.Fragment key={id}>
                <InputMask
                    className="form-control phone_form phone_mask"
                    mask="+7(999)999-9999"
                    maskChar="_"
                    data-id={id}
                    data-fid={`${this.props.nameForm}_${id}`}
                    placeholder={placeholder}
                    data-information={placeholder}
                    data-description={description}
                    value={value}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    style={{borderColor: this.props.isInvalid ? 'red' : ''}}
                />
            </React.Fragment>
        )
    }
}


