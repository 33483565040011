import React from 'react'
import { Card, ListGroup } from 'react-bootstrap'
// import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
// import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import QuestionToChatBtn from './QuestionToChatBtn';

export function ListGroupParticipant({ sectionNum, showRenderedArray, renderedArray, downloadFunction, document, headerName, runChat, organizerName, lotNum, name }) {



    const numberOfLot = lotNum ? `лот № ${lotNum};` : ''
    const nameOfPosition = name ? `название позиции - "${name}";` : ''


    const shortenWords = (word, letterCount) => {
        if (word && word.length > 0) {
            if (word.length > letterCount) {
                return `${word.substring(0, letterCount)}...`
            } else {
                return word
            }
        }
    }

    const handleClickChat = (text, recipient) => {
        // console.log(`TEXT CHAT:::: `, text);
        runChat(text, recipient);
    }

    const border = '1px solid rgba(0,0,0,.125)'
    return (
        <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
            <Card.Header as='h6' className='trade_card_header'>{sectionNum} {headerName ? headerName : 'Файлы лота'} </Card.Header>

            {renderedArray && renderedArray.length > 0 ? <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                <ListGroup>
                    <div style={{ display: 'flex' }}>
                        <ListGroup.Item style={{ width: '8%', border }} ></ListGroup.Item>

                        <ListGroup.Item style={{ width: '20%', border }} >Название файла</ListGroup.Item>

                        <ListGroup.Item style={{ width: '50%', border }}>Описание файла</ListGroup.Item>

                        <ListGroup.Item style={{ width: '12%', border }}>Скачать файл</ListGroup.Item>
                        <ListGroup.Item style={{ width: '10%', border }}>Вопрос в чат</ListGroup.Item>
                    </div>
                </ListGroup>
                <ListGroup style={{ border: '1px solid rgba(0,0,0,.125)' }}>
                    {showRenderedArray && renderedArray.length > 0 && 
                        renderedArray.filter(el => el).map((item, idx) => (

                            <div key={idx} style={{ display: 'flex' }}>
                                <ListGroup.Item style={{ width: '8%', border }} >{idx + 1}</ListGroup.Item>

                                <ListGroup.Item title={item.oldNameFile} style={{ width: '20%', border }} >{shortenWords(item.oldNameFile, 20)}</ListGroup.Item>


                                <ListGroup.Item title={item.descriptionFile} style={{ width: '50%', border }}>{item.descriptionFile ? shortenWords(item.descriptionFile, 30) : <i>Описание файла отсутствует</i>}</ListGroup.Item>

                                <ListGroup.Item style={{ width: '12%', border, textAlign: 'center' }} action onClick={() => downloadFunction(item.pathFile)} ><DownloadOutlinedIcon color="primary" /></ListGroup.Item>

                                <ListGroup.Item style={{ width: '10%', border, textAlign: 'center' }}>
                                    <QuestionToChatBtn
                                        handleClick={handleClickChat}
                                        details={{
                                            param: `
                                            ${numberOfLot}  
                                            ${nameOfPosition} 
                                            пункт № 12;  
                                            к файлу № ${idx + 1}, ${item.descriptionFile ? `"${item.descriptionFile}"` : `${item.oldNameFile}`}`,
                                            recipient: organizerName
                                        }}
                                    />
                                </ListGroup.Item>
                            </div>
                        ))
                    }
                </ListGroup>
            </Card.Body> : <Card.Body>{`Приложенные к ${document} файлы отсутствуют`}</Card.Body>}
        </Card>
    )
}
