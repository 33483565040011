import React from 'react';
import { Alert, Container, Form, Row } from 'react-bootstrap';
import CheckForm from '../../questionnaire/CheckForm';
// import FlareOutlinedIcon from '@mui/icons-material/FlareOutlined';
import { CloseBtnForFilter } from '../../assets/CloseBtnForFilter';
import Server from '../../server/server';
import { ButtonGroupForFilterForms } from '../../assets/ButtonGroupForFilterForms';



export default class FormWelding extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: true,
            nameForm: 'FormWelding',
            maxId: 6,
            dataValue: [],
            dataOnServer: [],
            dataFromServer: [],
            lengthDataFromServer: 0,
            showStud: false,
            showNut: false,
            showBolt: false,
            showShayba: false,
            showVariants: true
        }

        this.doChangeValue = this.doChangeValue.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
        this.sendProductDataOnServer = this.sendProductDataOnServer.bind(this)
    }

    async clearFields() {

        await this.setState({
            showVariants: false
        })

        this.setState({
            showVariants: true
        })
    }

    //* empty
    doEmpty() {
    }

    //запуск выхода из формы
    handleClickShadow() {
        this.setState({ showQuit: true })
    }

    //выход с сохранением
    doQuitWithSave() {
        this.setState({ showQuit: false })
        this.handleClickSave();
        this.props.onChangeView(this.state.nameForm);
    }
    //выход без сохранения
    doQuitWithoutSave() {
        this.setState({ showQuit: false })
        this.props.onChangeView(this.state.nameForm);
    }

    //*формируем данные в массив объектов для отправки на сервер
    async handleClickSave(e) {
        e.preventDefault()
        let data = this.state.dataOnServer;

        for (let i = 0; i <= (this.state.maxId); i++) {

            //todo создаем позицию с пустым объектом
            data[i] = { id: '', fid: '', description: '', information: '', value: '' }

            //todo заполняем объект данными с сервера
            this.state.dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
            this.state.dataValue.forEach((dataE) => {
                if (+dataE.id === i) {
                    data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                }
            })

        }
        await this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере

        this.props.getChosenFiltersData(data)
        //отправляем родителю имя этой формы
        // this.props.getFormName(this.state.nameForm)
        //берем данные с сервера
        this.props.saveChosenFilters()

        this.props.openResultTable()
        this.props.closeModal(e)

        const result = await Server.filterProductionCompany(data, this.state.nameForm)
        await this.props.sendSearchResultData(result)

    }

    //* данные с полей формы, формируются в массив объектов
    async doChangeValue(data) {
        await this.setState({ dataValue: [...this.state.dataValue, data] })
    }

    onKeyPressHandler(event) {
        event.target.dataset.position = 29
        if (event.keyCode === 13) {
            this.handleClickSave(event)
        }
    }

    async sendProductDataOnServer() {
        const productData = {
            product: this.props.product,
            formName: this.state.nameForm
        }
        await Server.sendDataOnServer(productData, 'product', localStorage.getItem('idUser'))
    }

    async componentDidMount() {
        await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer });

        document.addEventListener('keydown', this.onKeyPressHandler)

        await this.sendProductDataOnServer()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPressHandler)
    }

    render() {

        const view = this.props.view;
        this.value = this.props.data;
        const viewQuest = this.props.viewQuest;


        return (
            <>
                <div className="modal_window" style={{ display: view ? 'block' : 'none' }} >
                    <div className={viewQuest ? "shadow_form" : "shadow_form_off"} onClick={this.props.closeModal}></div>
                    <Form className={this.props.chosenCompaniesFromProductionSearch && this.props.chosenCompaniesFromProductionSearch.length >= 0 ? "form_main" : "form_main_1"}  >
                        <Alert
                            variant="dark"
                            className="filter_alert"
                        >
                            <Alert.Heading >Поставка сварочных материалов</Alert.Heading>
                            <CloseBtnForFilter dataId={29} closeModal={this.props.closeModal} />
                        </Alert>
                        <div>
                             <Container className="form_container">
                            <Row> &nbsp; </Row>
                            <Form.Label className='f_size_1em'>
                                Материалы используемые производством
                            </Form.Label>

                            {this.state.showVariants && <><Form.Group>
                                <Row>
                                    <CheckForm
                                        id={1}
                                        width={6}
                                        show={true}
                                        placeholder="Проволока сварочная"
                                        description="Поставка сварочных материалов"
                                        value={this.value[1] ? this.value[1].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        onChangeVisionBlock={this.doEmpty}
                                        nameForm={this.state.nameForm}
                                    />
                                </Row>
                            </Form.Group>

                                <Form.Group>
                                    <Row>
                                        <CheckForm
                                            id={2}
                                            width={6}
                                            show={true}
                                            placeholder="Прутки присадочные"
                                            description="Поставка сварочных материалов"
                                            value={this.value[2] ? this.value[2].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doEmpty}
                                            nameForm={this.state.nameForm}
                                        />
                                    </Row>
                                </Form.Group>

                                <Form.Group>
                                    <Row>
                                        <CheckForm
                                            id={3}
                                            width={6}
                                            show={true}
                                            placeholder="Флюсы сварочные"
                                            description="Поставка сварочных материалов"
                                            value={this.value[3] ? this.value[3].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doEmpty}
                                            nameForm={this.state.nameForm}
                                        />
                                    </Row>
                                </Form.Group>

                                <Form.Group>
                                    <Row>
                                        <CheckForm
                                            id={4}
                                            width={6}
                                            show={true}
                                            placeholder="Электроды сварочные"
                                            description="Поставка сварочных материалов"
                                            value={this.value[4] ? this.value[4].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doEmpty}
                                            nameForm={this.state.nameForm}
                                        />
                                    </Row>
                                </Form.Group>

                                <Form.Group>
                                    <Row>
                                        <CheckForm
                                            id={5}
                                            width={6}
                                            show={true}
                                            placeholder="Угольные электроды для воздушно-дуговой строжки и резки"
                                            description="Поставка сварочных материалов"
                                            value={this.value[5] ? this.value[5].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doEmpty}
                                            nameForm={this.state.nameForm}
                                        />
                                    </Row>
                                </Form.Group>

                                <Form.Group>
                                    <Row>
                                        <CheckForm
                                            id={6}
                                            width={6}
                                            show={true}
                                            placeholder="Вольфрамовые электроды"
                                            description="Поставка сварочных материалов"
                                            value={this.value[6] ? this.value[6].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doEmpty}
                                            nameForm={this.state.nameForm}
                                        />
                                    </Row>
                                </Form.Group>
                            </>}
                            <Row> &nbsp; </Row>
                            <Row>&nbsp;</Row>

                        </Container>
                          <ButtonGroupForFilterForms
                                    dataId={29}
                                    handleClickSave={this.handleClickSave}
                                    clearFields={this.clearFields}
                                    savedFiltersData={this.props.savedFiltersData}
                                />
                        </div>
                       
                    </Form>
                </div>
            </>
        )
    }
}