/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Alert, Container, Form, Row, Col } from 'react-bootstrap';
import CheckForm from '../../questionnaire/CheckForm';
import Form_Five_Termo from '../../questionnaire/Form_Five_Termo';
import SizeForm from '../../questionnaire/SizeForm';
import { TERMO_PRODUCTION } from '../../questionnaire/ConstProduction';
import FormMaterList from '../../questionnaire/FormMaterList';
// import DeviceThermostatOutlinedIcon from '@mui/icons-material/DeviceThermostatOutlined';
import { CloseBtnForFilter } from '../../assets/CloseBtnForFilter';
import Server from '../../server/server';
import { ButtonGroupForFilterForms } from '../../assets/ButtonGroupForFilterForms';



export default class Five extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            language: 'rus',
            dataValue: [],
            id: 11,
            maxId: 36,
            nameForm: 'Five',
            dataOnServer: [],
            dataFromServer: [],
            lengthDataFromServer: 0,
            base: [],
            show: false,
            showMain: false,
            showAut: false,
            showBox: false,
            showNotBox: false,
            showMat: false,
            showMat1: false,
            showBoxAut: false,
            showNotBoxAut: false,
            showMatAut: false,
            showMatAut1: false,
            showQuit: false,
            selectCheck: true,
            showVariants: true
        }

        this.doChangeValue = this.doChangeValue.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.createBlockForm = this.createBlockForm.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
        this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this);
        this.onRunBlock = this.onRunBlock.bind(this);

        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
        this.sendProductDataOnServer = this.sendProductDataOnServer.bind(this)
    }

    async clearFields() {
        await this.setState({
            showVariants: false
        })

        this.setState({
            showVariants: true
        })
    }

    //*Пустышка
    doEmpty() {

    }

    //*включаем блоки материалов при загрузке
    onRunBlock(data) {
        data.forEach(elem => {
            if (elem) this.doChangeVisionBlock(elem.value, +elem.id);
        })
    }

    doChangeVisionBlock(e, id) {
        if (+id === 1) this.setState({ showMain: e });
        if (+id === 2) this.setState({ showBox: e });
        if (+id === 8) this.setState({ showNotBox: e });
        // if (+id === 8) this.setState({ showMat: e });
        if (+id === 13) this.setState({ showMat1: e });
        if (+id === 18) this.setState({ showAut: e });
        if (+id === 19) this.setState({ showBoxAut: e });
        if (+id === 25) this.setState({ showNotBoxAut: e });
        // if (+id === 25) this.setState({ showMatAut: e });
        if (+id === 30) this.setState({ showMatAut1: e });
    }
    //запуск выхода из формы
    handleClickShadow() {
        this.setState({ showQuit: true })
    }

    //выход с сохранением
    doQuitWithSave() {
        this.setState({ showQuit: false })
        this.handleClickSave();
        this.props.onChangeView('Five');
    }
    //выход без сохранения
    doQuitWithoutSave() {
        this.setState({ showQuit: false })
        this.props.onChangeView('Five');
    }

    //*формируем данные в массив объектов для отправки на сервер
    async handleClickSave(e) {
        e.preventDefault()
        let data = this.state.dataOnServer;

        for (let i = 0; i <= (this.state.maxId); i++) {

            //todo создаем позицию с пустым объектом
            data[i] = { id: '', fid: '', description: '', information: '', value: '' }

            //todo заполняем объект данными с сервера
            this.state.dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
            this.state.dataValue.forEach((dataE) => {
                if (+dataE.id === i) {
                    data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                }
            })

        }
        await this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере

        // this.props.getFormName(this.state.nameForm)
        this.props.saveChosenFilters()
        this.props.getChosenFiltersData(data)

        const result = await Server.filterProductionCompany(data, this.state.nameForm)
        await this.props.sendSearchResultData(result)
        this.props.openResultTable()
        this.props.closeModal(e)
    }

    //* данные с полей формы, формируются в массив объектов
    doChangeValue(data) {
        // console.log(data);//test
        this.setState({ dataValue: [...this.state.dataValue, data] })
        // setTimeout(() => { console.log(this.state.dataValue) })//test
    }



    //*создаем необходимое количество блоков
    createBlockForm() {

    }

    onKeyPressHandler(event) {
        event.target.dataset.position = 4
        if (event.keyCode === 13) {
            this.handleClickSave(event)
            // console.log(event);
        }
    }

    async sendProductDataOnServer() {
        const productData = {
            product: this.props.product,
            formName: this.state.nameForm
        }
        await Server.sendDataOnServer(productData, 'product', localStorage.getItem('idUser'))
    }

    async componentDidMount() {
        setTimeout(() => {
            this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer });
            this.onRunBlock(this.props.data);
        }, 1000);

        document.addEventListener('keydown', this.onKeyPressHandler)

        await this.sendProductDataOnServer()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPressHandler)
    }

    render() {
        // console.log('RENDER');

        const view = this.props.view;
        this.value = this.props.data;
        const viewQuest = this.props.viewQuest;
        return (
            <>

                <div className="modal_window" style={{ display: view ? 'block' : 'none' }} >
                    <div className={viewQuest ? "shadow_form" : "shadow_form_off"} onClick={this.props.closeModal}></div>
                    <Form className={this.props.chosenCompaniesFromProductionSearch && this.props.chosenCompaniesFromProductionSearch.length >= 0 ? "form_main" : "form_main_1"}  >
                        <Alert
                            variant="dark"
                            className="filter_alert"
                        >
                            <Alert.Heading > Термическая обработка</Alert.Heading>
                            <CloseBtnForFilter dataId={4} closeModal={this.props.closeModal} />
                        </Alert>
                        <div>
                               {this.state.showVariants && <Container className="form_container">
                            <Form.Group>
                                <Row>
                                    <CheckForm
                                        className={this.state.showMain && 'fw_bolder'}
                                        id={1}
                                        width={4}
                                        show={true}
                                        placeholder="Собственное возможности"
                                        description="Собственная термическая обработка"
                                        value={this.value[1] ? this.value[1].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        onChangeVisionBlock={this.doChangeVisionBlock}
                                        nameForm={this.state.nameForm}
                                    />
                                </Row>
                                <Form.Group style={{ display: this.state.showMain ? 'block' : 'none' }} >

                                    <FormMaterList
                                        id={36}
                                        width={4}
                                        rowView={false}
                                        materials={TERMO_PRODUCTION}
                                        value={this.value[36] ? this.value[36].value : ''}
                                        description="Термическая обработка"
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                        selectCheck={this.state.selectCheck}
                                    />

                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Col>
                                            Максимальные габариты термообрабатываемых изделий:
                                        </Col>
                                    </Row>

                                    <Row>
                                        <CheckForm
                                            id={2}
                                            width={4}
                                            show={true}
                                            placeholder="Печная"
                                            description="Собственная термическая обработка"
                                            value={this.value[2] ? this.value[2].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doChangeVisionBlock}
                                            nameForm={this.state.nameForm}
                                        />
                                    </Row>
                                    <Form_Five_Termo
                                        id={3}
                                        show={this.state.showBox}
                                        description={"Собственная термическая обработка"}
                                        value={this.value}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <CheckForm
                                            id={8}
                                            width={4}
                                            show={true}
                                            placeholder="Внепечная"
                                            description="Собственная термическая обработка"
                                            value={this.value[8] ? this.value[8].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doChangeVisionBlock}
                                            nameForm={this.state.nameForm}
                                        />
                                    </Row>

                                    <SizeForm
                                        id={9}
                                        width={2}
                                        fontSize={'12px'}
                                        show={this.state.showNotBox}
                                        value={this.value}
                                        description="Собственная термическая обработка"
                                        placeholder={["Длина, мм", "Ширина, мм", "Высота, мм", "Температура, С"]}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                    <Row>&nbsp;</Row>
                                    <Row> <div className='border_top_topline_100'></div> </Row>

                                </Form.Group>
                                <Row>
                                    <CheckForm
                                        className={this.state.showAut && 'fw_bolder'}
                                        id={18}
                                        width={4}
                                        show={true}
                                        placeholder="Аутсорсинг"
                                        description="Термическая обработка, аутсорсинг"
                                        value={this.value[18] ? this.value[18].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        onChangeVisionBlock={this.doChangeVisionBlock}
                                        nameForm={this.state.nameForm}
                                    />
                                </Row>
                                <Form.Group style={{ display: this.state.showAut ? 'block' : 'none' }} >
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <Col>
                                            Максимальные габариты термообрабатываемых изделий:
                                        </Col>
                                    </Row>

                                    <Row>
                                        <CheckForm
                                            id={19}
                                            width={4}
                                            show={true}
                                            placeholder="Печная"
                                            description="Термическая обработка, аутсорсинг"
                                            value={this.value[19] ? this.value[19].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doChangeVisionBlock}
                                            nameForm={this.state.nameForm}
                                        />

                                    </Row>
                                    <Form_Five_Termo
                                        id={20}
                                        show={this.state.showBoxAut}
                                        description="Термическая обработка, аутсорсинг"
                                        value={this.value}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                    <Row>&nbsp;</Row>
                                    <Row>
                                        <CheckForm
                                            id={25}
                                            width={4}
                                            show={true}
                                            placeholder="Внепечная"
                                            description="Термическая обработка, аутсорсинг"
                                            value={this.value[25] ? this.value[25].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doChangeVisionBlock}
                                            nameForm={this.state.nameForm}
                                        />
                                    </Row>

                                    <SizeForm
                                        id={26}
                                        width={2}
                                        fontSize={'12px'}
                                        show={this.state.showNotBoxAut}
                                        value={this.value}
                                        description="Собственная термическая обработка"
                                        placeholder={["Длина, мм", "Ширина, мм", "Высота, мм", "Температура, С"]}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                    <Row>&nbsp;</Row>
                                    <Row> <div className='border_top_topline_100'></div> </Row>

                                </Form.Group>
                            </Form.Group>
               
                            <Row> &nbsp; </Row>

                        </Container>}
                            <ButtonGroupForFilterForms
                                dataId={4}
                                handleClickSave={this.handleClickSave}
                                clearFields={this.clearFields}
                                savedFiltersData={this.props.savedFiltersData}
                            />
                        </div>
                     
                    </Form>
                </div>

            </>
        )
    }
}