import React from 'react';
import { Alert, Container, Form, Row } from 'react-bootstrap';
import { PIPESSUPPLY_FOR_FILTER } from '../../questionnaire/ConstPipesSupply'
import SizeFormBig from '../../questionnaire/SizeFormBig';
// import HorizontalSplitOutlinedIcon from '@mui/icons-material/HorizontalSplitOutlined';
import { HandleAllCheckBoxesBtn } from '../../assets/HandleAllCheckboxesBtn';
import FormMaterList from '../../questionnaire/FormMaterList';
import { CloseBtnForFilter } from '../../assets/CloseBtnForFilter';
import Server from '../../server/server';
import { ButtonGroupForFilterForms } from '../../assets/ButtonGroupForFilterForms';

export default class FormPipesSupply extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: true,
            nameForm: 'FormPipesSupply',
            maxId: 7,
            dataValue: [],
            dataOnServer: [],
            dataFromServer: [],
            lengthDataFromServer: 0,
            showQuit: false,
            selectCheck: true,
            showGostSection: true,
            showVariants: true
        }
        this.doChangeValue = this.doChangeValue.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
        this.handleClickMaterieals = this.handleClickMaterieals.bind(this);

        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);
        this.handleAllChecked = this.handleAllChecked.bind(this);
        this.clearFields = this.clearFields.bind(this)
        this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
        this.sendProductDataOnServer = this.sendProductDataOnServer.bind(this)
    }

    async clearFields() {

        this.setState({
            showVariants: false
        })

        const res = await this.state.dataValue.map(el => {
            if (typeof el.value === 'object') {
                this.setState({ selectCheck: true })
            } if (!el) {
                this.setState({
                    showVariants: false
                })
                setTimeout(() => {
                    this.setState({
                        showVariants: true
                    })
                })
            } else {
                return {
                    ...this.state.dataValue,
                    value: ''
                }
            }
        })

        await this.setState({
            dataValue: res
        })

        this.setState({
            showVariants: true
        })
    }


    handleAllChecked() {
        this.setState({
            selectCheck: !this.state.selectCheck,
            showGostSection: false
        })
        setTimeout(() => {
            this.setState({
                showGostSection: true
            })
        })
    }

    handleClickMaterieals() {
        this.props.onRunMaterials('Four_FormPipesSupply');
    }

    //* empty
    doEmpty() {
    }


    //запуск выхода из формы
    handleClickShadow() {
        this.setState({ showQuit: true })
    }

    //выход с сохранением
    doQuitWithSave() {
        this.setState({ showQuit: false })
        this.handleClickSave();
        this.props.onChangeView(this.state.nameForm);
    }
    //выход без сохранения
    doQuitWithoutSave() {
        this.setState({ showQuit: false })
        this.props.onChangeView(this.state.nameForm);
    }

    //*формируем данные в массив объектов для отправки на сервер
    async handleClickSave(e) {
        e.preventDefault()
        let data = this.state.dataOnServer;

        for (let i = 0; i <= (this.state.maxId); i++) {

            //todo создаем позицию с пустым объектом
            data[i] = { id: '', fid: '', description: '', information: '', value: '' }

            //todo заполняем объект данными с сервера
            this.state.dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
            this.state.dataValue.forEach((dataE) => {
                if (+dataE.id === i) {
                    data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                }
            })

        }
        await this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере

        this.props.getChosenFiltersData(data)
        //отправляем родителю имя этой формы
        // this.props.getFormName(this.state.nameForm)
        //берем данные с сервера
        this.props.saveChosenFilters()


        const result = await Server.filterProductionCompany(data, this.state.nameForm)
        await this.props.sendSearchResultData(result)
        this.props.openResultTable()
        this.props.closeModal(e)
    }

    //* данные с полей формы, формируются в массив объектов
    async doChangeValue(data) {
        await this.setState({ dataValue: [...this.state.dataValue, data] })

        // this.checkOnList(); //! отключен
    }

    onKeyPressHandler(event) {
        event.target.dataset.position = 31
        if (event.keyCode === 13) {
            this.handleClickSave(event)
        }
    }

    async sendProductDataOnServer() {
        const productData = {
            product: this.props.product,
            formName: this.state.nameForm
        }
        await Server.sendDataOnServer(productData, 'product', localStorage.getItem('idUser'))
    }

    async componentDidMount() {
        await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer });

        document.addEventListener('keydown', this.onKeyPressHandler)

        await this.sendProductDataOnServer()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPressHandler)
    }


    render() {

        const view = this.props.view;
        this.value = this.props.data;
        const viewQuest = this.props.viewQuest;
        const gosts = PIPESSUPPLY_FOR_FILTER[0].normDoc.gost;

        return (
            <>

                <div className="modal_window" style={{ display: view ? 'block' : 'none' }} >
                    <div className={viewQuest ? "shadow_form" : "shadow_form_off"} onClick={this.props.closeModal}  ></div>
                    <Form className={this.props.chosenCompaniesFromProductionSearch && this.props.chosenCompaniesFromProductionSearch.length >= 0 ? "form_main" : "form_main_1"}  >
                        <Alert
                            variant="dark"
                            className="filter_alert"
                        >
                            <Alert.Heading >Поставка трубного проката </Alert.Heading>
                            <CloseBtnForFilter dataId={31} closeModal={this.props.closeModal} />
                        </Alert>
                        <div>
                            <Container className="form_container">

                                <Form.Group>
                                    {this.state.showVariants && <SizeFormBig
                                        id={1}
                                        width={4}
                                        show={true}
                                        value={this.value}
                                        placeholder={PIPESSUPPLY_FOR_FILTER[0].form.dimension}//кол-во позиций согласно длине массива
                                        description="Трубный прокат"
                                        fontSize={12}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />}
                                </Form.Group>

                                {!this.value[7] && <HandleAllCheckBoxesBtn
                                    handleAllChecked={this.handleAllChecked}
                                    selectCheck={this.state.selectCheck}
                                />}


                                {this.state.showGostSection && <Form.Group className='display_flex container_flex_row_wrap'>
                                    {this.state.showVariants &&
                                        <FormMaterList
                                            id={7}
                                            width={3}
                                            rowView={true}//расположение на экране
                                            materials={gosts}
                                            placeholder={gosts}
                                            value={this.value[7] ? this.value[7].value : ''}
                                            description="Трубный прокат"
                                            onChangeValue={this.doChangeValue}
                                            onChangeVisionBlock={this.doEmpty}
                                            nameForm={this.state.nameForm}
                                            selectCheck={this.state.selectCheck}
                                        />
                                    }
                                </Form.Group>}

                                <Row> &nbsp;</Row>
                                <Form.Group>

                                    <Row>&nbsp;</Row>
                                </Form.Group>

                            </Container>
                            <ButtonGroupForFilterForms
                                dataId={31}
                                handleClickSave={this.handleClickSave}
                                clearFields={this.clearFields} />
                        </div>

                    </Form>
                </div>
            </>
        )
    }
}