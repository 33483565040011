import React from 'react'
import { Alert, Container } from 'react-bootstrap'

// toggleModal = {закрывает модалку на крестик}
// alertHeading = {заглавие модалки}
// modalContent = {содержимое модалки, все что внутри}


export default function ModalWindowMini({ toggleModal, alertHeading, modalContent }) {
    return (
        <>
            <div className="shadow_form" onClick={toggleModal} ></div>
            <div className="modal_common_mini z_index_1000" >
                <Alert variant="dark" onClose={toggleModal} dismissible>
                    <Alert.Heading >
                        {alertHeading}
                    </Alert.Heading>
                </Alert>
                <Container style={{ height: '300px', overflowY: 'auto' }}>
                    {modalContent}
                </Container>
            </div>
        </>
    )
}
