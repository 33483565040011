import React from 'react';
import DatePicker from 'react-date-picker';
// import '../../style/calendarDataPicker.css';

//* https://github.com/wojtekmaj/react-date-picker - GitHub изготовителя

export class CalendarFormTrade extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            language: 'rus',
            id: '',
            fid: '',
            description: '',
            information: '',
            value: '',
            colorError: '#fff',
            show: true,
            minDate: '',
            // disabledCal: false
        }

        this.handleChange = this.handleChange.bind(this);
        this.getMinDate = this.getMinDate.bind(this);

        // this.disableCalendar = this.disableCalendar.bind(this)
    }

    getMinDate() {
        let date = new Date();
        date.setDate(date.getDate());
        this.setState({
            minDate: date
        })
    }

    handleChange(e) {
        // e.preventDefault();

        this.setState({
            // value: new Date(e), 
            value: e,
            id: this.props.id,
            fid: `${this.props.nameForm}_${this.props.id}`,
            description: this.props.description,
            information: this.props.placeholder + ' DATE: ',
            // disabledCal: false
        })


        console.log(e);//test

        setTimeout(() => { this.props.onChangeValue(this.state) })

    }

    componentDidMount() {
        // this.getMinDate()
        if (this.props.value) {
            this.setState({ value: this.props.value })
        } else {
            this.getMinDate()
        }

    }

    render() {

        const id = this.props.id;
        const description = this.props.description;
        const placeholder = this.props.placeholder;

        // console.log(this.props.value);
        // const date = new Date(this.props.value) 


        return (
            <React.Fragment key={id}>
                <div
                    style={{ display: 'inline-block', paddingRight: '0', backgroundColor: '#fff', paddingLeft: '0' }}
                    className={this.props.className !== 'no' ? "calendar" : ''}
                    data-id={id}
                    data-fid={`${this.props.nameForm}_${id}`}
                    placeholder={placeholder}
                    data-information={placeholder}
                    data-description={description}
                >
                    <DatePicker
                        calendarClassName='font_family_for_calendar'
                        // style={{ backgroundColor: '#fff' }}
                        minDate={this.state.minDate}
                        showLeadingZeroes={false}
                        format={"dd/MM/yyyy"}
                        value={this.state.value}
                        // value={date}
                        locale={"ru-RU"}
                        onChange={this.handleChange}

                    />


                </div>
            </React.Fragment>
        )
    }
}