import React from 'react'
import { Container, Alert, Row, Tabs, Tab, Card } from 'react-bootstrap'
// import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
// import ModalCommon from '../ModalCommon';
// import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp';
import server from '../server/server'
import FileDataParticipant from './FileDataParticipant'
import { GeneralDataTradeParticipant } from './GeneralDataTradeParticipant'
import FileNoModal from '../file/FileNoModal'
import QuitModal from '../QuitModal'
import AddDocuments from './AddDocuments'
import AllModalsFooter from '../assets/AllModalsFooter'
import { SaveCurrentDataButton } from '../assets/BasicButtons'
import { isEqual } from 'lodash-es'
// import { nanoid } from 'nanoid';
// import { LocationSearchingSharp } from '@mui/icons-material';

export default class TradePositionChoice extends React.Component {
  constructor(props) {
    super(props)

    this.arrPromisesAll = [] // собирает промисы для Promise.all

    this.state = {
      language: 'rus',
      componentName: 'TradePositionChoice',
      promiseAll: [],
      showTech: true,
      showFile: false,
      showFileParticipant: false,
      showFileList: false,
      showCommercial: true,
      showDate: false,
      showParticipant: false,
      showFeatures: false,
      showCreateTrade: false, // вкл кнопки - создать торги
      renderTableCompany: [], // рендер таблицы компаний
      trade: {
        tech: [],
        commercial: [],
        nameTrade: '', // название торговой позиции
        keyNameTrade: '',
        organizerId: '',
        priority: [],
        idFile: '', // ID объекта файлов - leo
        archive: false, // нахождение в архиве (true), доступен(false)
        fileList: [], // список описания необходимых файлов
        createDate: '' // дата создания
      },
      dataFromTech: [],
      dataFromCommerce: [],
      activeTab: 'link-0',
      filesNamesArr: [],
      inReadMode: false,
      showQuit: false,
      positionFiles: [],
      positionData: [],
      valueFileList: [],
      savedDataMessage: false,
      previousState: []
    }

    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.toNextTab = this.toNextTab.bind(this)
    this.returnId = this.returnId.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.getFilesNames = this.getFilesNames.bind(this)
    this.onSelectTab = this.onSelectTab.bind(this)

    this.showQuitModal = this.showQuitModal.bind(this)
    this.sendResultData = this.sendResultData.bind(this)

    this.returnIdParticipant = this.returnIdParticipant.bind(this)
    this.getIdFileNameParticipant = this.getIdFileNameParticipant.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)

    this.handleClickChat = this.handleClickChat.bind(this)
    this.sendObj = this.sendObj.bind(this)
    this.loadValueFileList = this.loadValueFileList.bind(this)
    this.loadPositionFiles = this.loadPositionFiles.bind(this)
    this.handleRunChat = this.handleRunChat.bind(this)
    this.handleClickSaveData = this.handleClickSaveData.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
  }

  //* запуск чата с текстом
  handleRunChat(text, recipient) {
    this.props.runChat(text, recipient)
  }

  //* получаем объект файлов для сохранения
  sendObj(obj) {
    ;(async () => {
      try {
        const id = `${this.state.trade.keyNameTrade}__files_trade__${localStorage.getItem('idUser')}`
        // console.log(`obj >>>>>> ID: ${id} >>>>>>>>>>> `, obj); // test
        const result = await server.sendDataOnServer(obj, id, 'trade_file')
        // console.log(`RESULT: `, result);
      } catch (err) {
        console.log(`Ошибка, сохранение объекта на сервер: `, err)
      }
    })()
  }

  //* получаем текст для начала чата по лоту
  handleClickChat(e) {
    // console.log(`handleClickChat >>>>>>> `, e);
  }

  //* загрузка объекта файлов списка необходимых файлов
  async loadValueFileList() {
    const valueFileList = await server.getDataFromServer(
      `${this.state.trade.keyNameTrade}__files_trade__${localStorage.getItem('idUser')}`,
      'trade_file'
    )

    if (valueFileList) {
      this.setState({ valueFileList: valueFileList })
    }
  }

  //* загрузка файлов торг позиции
  async loadPositionFiles() {
    const positionFiles = await server.getDataFromServer(this.state.trade.idFile, 'trade_file')

    if (positionFiles) {
      await this.setState({
        positionFiles
      })
    }
  }

  doQuitWithoutSave() {
    this.props.onChangeShowTrade()
    this.setState({
      showQuit: false
    })
  }

  // результат просмотра торговой позиции
  sendResultData(data) {
    this.setState({ positionData: data })
    // console.log(`POSITION DATA::::::: `, data) // test
  }

  async getFilesNames(files) {
    await this.setState({
      filesNamesArr: files
    })
  }

  doEmpty() {}

  getIdFileNameParticipant(objData) {
    // console.log(`objData:::: `, objData);
  }

  returnIdParticipant(id) {
    // console.log(`PARTICIPANT TRADE >>> Вернувшийся ID:::: `, id);
  }

  returnId(id) {
    // console.log(`Вернувшийся ID:::: `, id);
  }

  toNextTab(e) {
    e.preventDefault()

    const keysArray = ['link-0', 'link-1', 'link-2', 'link-3', 'link-4']

    keysArray.forEach((el, idx) => {
      if (this.state.activeTab === el) {
        this.setState({
          activeTab: `link-${idx + 1}`
        })
      }
    })
  }

  handleClickShadow() {
    this.props.onChangeShowTrade()
  }

  async onSelectTab(k) {
    this.setState({ activeTab: k })
  }

  compare() {
    const prev = this.state.previousState
    const curr = this.state.positionData

    // console.log('prev', prev)
    // console.log('curr', curr)

    const isStateChanged = isEqual(prev, curr)
    if (isStateChanged) {
      return true
    }
    return false
  }

  smartWindowFunction() {
    const isStateChanged = this.compare()
    if (isStateChanged) {
      // this.handleClickShadow()
      this.doQuitWithoutSave()
    } else {
      this.setState({
        showQuit: true
      })
    }
  }

  async componentDidMount() {
    const isDisabled =
      (this.state.trade.nameTrade && this.state.trade.nameTrade.length !== 0) ||
      (this.props.nameTrade && this.props.nameTrade.length !== 0)
    // console.log(`POSITION look VALUE:::::: `, this.props.value); // test

    if (!isDisabled) {
      this.setState({
        showTech: false
      })
    }

    if (this.props.serverData && this.props.serverData.length !== 0) {
      const result = {
        tech: this.props.dataTech,
        commercial: this.props.dataCommerce,
        nameTrade: this.props.nameTrade,
        keyNameTrade: this.props.keyNameTrade,
        organizerId: this.props.organizerId,
        priority: this.props.priority,
        idFile: this.props.fileId,
        fileList: this.props.fileList,
        archive: this.props.archive, // нахождение в архиве (true), доступен(false)
        createDate: this.props.createDate // дата создания
      }
      // console.log(result);

      await this.setState({
        trade: result,
        inReadMode: true
      })
    } else {
      // создаем ID  для объекта файлов - leo
      const dataD = this.state.trade
      dataD.idFile = this.props.fileId
      this.setState({ trade: dataD })
    }

    await this.loadPositionFiles()
    await this.loadValueFileList()

    setTimeout(() => {
      // console.log(this.state.positionFiles)
    }, 600)

    this.setState({
      showFile: true,
      showFileParticipant: true,
      showFileList: true
    })


    if (this.props.organizerId) {
      const previous = JSON.parse(JSON.stringify(this.state.positionData))
      this.setState({
        previousState: previous
      })
    }


  }

  showQuitModal() {
    this.props.sendPositionData(this.state.positionData) // Результат подтверждения в <ConfirmationLot>
    // console.log(`CLOSE WINDOW, data go up to < ConfirmationLot > `); // test

    this.setState({
      showQuit: true
    })
  }

  handleClickSaveData() {
    this.setState({
      savedDataMessage: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
      this.handleClickShadow()
      this.showQuitModal()
    }, 1000)
  }

  render() {
    const show = this.props.show
    const isDisabled = this.state.trade.nameTrade && this.state.trade.nameTrade.length !== 0

    return (
      <>
        {this.state.showQuit && (
          <QuitModal
            onQuitWithSave={this.handleClickSaveData}
            onQuitWithoutSave={this.doQuitWithoutSave}
            closeQuitModal={() => this.setState({ showQuit: false })}
            modalTitle='Выход'
            modalBody='Сохранить измененные данные?'
            primaryButton='Сохранить'
            secondaryButton='Отменить'
            //icon - иконка в заголовке
          />
        )}
        {/* {this.state.showQuit && isDisabled && this.handleClickShadow()} */}
        <div
          className='shadow_form z_index_21'
          // onClick={isDisabled ? this.showQuitModal : this.handleClickShadow}
          onClick={this.smartWindowFunction}
        ></div>
        <div
          className='wrapper_lot_participant_position_modal z_index_21'
        >
          {/* <div> */}
          <Alert variant='dark' onClose={isDisabled ? this.smartWindowFunction : this.handleClickShadow} dismissible>
            <Alert.Heading>
              {/* <AddShoppingCartOutlinedIcon fontSize="large" className='icon_primary' /> */}
              {!this.state.trade.nameTrade ? 'Создание торговых позиций' : 'Просмотр / редактирование торговых позиций'}
            </Alert.Heading>
          </Alert>

          <Row>&nbsp;</Row>
          <div className='wrapper_lot_participant_position_modal_content'>
            <Tabs defaultActiveKey='link-0' activeKey={this.state.activeTab} onSelect={this.onSelectTab}>
              <Tab eventKey='link-0' title='Сводные данные' disabled={isDisabled ? false : true}>
                <GeneralDataTradeParticipant
                  dataFromTech={this.state.trade.tech}
                  dataFromCommerce={this.state.trade.commercial}
                  name={this.state.trade.nameTrade}
                  sendResultData={this.sendResultData}
                  keyNameTrade={this.state.trade.keyNameTrade}
                  filesNamesArr={this.state.filesNamesArr}
                  value={this.props.value}
                  runChat={this.handleRunChat}
                  organizerName={this.props.organizerName}
                  lotNum={this.props.lotNum}
                  handleClickSaveData={this.handleClickSaveData}
                  savedDataMessage={this.state.savedDataMessage}
                  // currentState={this.state.positionData}
                />
              </Tab>
              <Tab eventKey='link-1' title='Файлы заказчика' disabled={isDisabled ? false : true}>
                {this.state.showFile && (
                  <FileDataParticipant
                    onClose={this.doEmpty} // закрытие окна
                    newName={this.state.trade.nameTrade} // название ЛОТа или торговой позиции
                    id={this.state.trade.idFile} // ID масива объектов загруженных файлов
                    // id={this.props.fileId} // ann
                    returnId={this.returnId} // возвращает ID, если его не было при запуске компонента
                    getFilesNames={this.getFilesNames}
                    toNextTab={this.toNextTab}
                    disableBtn={false}
                    showNextBtn={true}
                    positionFiles={this.state.positionFiles}
                    // inReadMode={true}
                    showFile={this.state.showFile}
                    runChat={this.handleRunChat}
                    organizerName={this.props.organizerName}
                    name={this.state.trade.nameTrade}
                    lotNum={this.props.lotNum}
                  />
                )}
              </Tab>

              <Tab eventKey='link-2' title='Необходимые файлы' disabled={isDisabled ? false : true}>
                <Row> &nbsp; </Row>
                {this.state.showFileList && (
                  <Card style={{ border: '1px #ccc solid', marginBottom: '50px' }}>
                    <Card.Header className='trade_card_header' as='h6'>
                      Необходимые файлы
                    </Card.Header>
                    <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                      <AddDocuments
                        handleClick={this.handleClickChat}
                        idLot={this.state.trade.nameTrade}
                        listForFiles={this.state.trade.fileList}
                        valueFileList={this.state.valueFileList}
                        addList={this.doEmpty}
                        sendObj={this.sendObj}
                        runChat={this.handleRunChat}
                        organizerName={this.props.organizerName}
                        showDelIcon={this.props.showDelIcon}
                        showCheckIcon={this.props.showCheckIcon}
                        showAddBtn={this.props.showAddBtn}
                        asCustomer={this.props.asCustomer}
                        name={this.state.trade.nameTrade}
                        // lotNum={this.props.lotNum}
                      />

                      <Row> &nbsp;</Row>
                    </Card.Body>
                  </Card>
                )}
              </Tab>

              <Tab eventKey='link-3' title='Дополнительные файлы для загрузки' disabled={isDisabled ? false : true}>
                <Row> &nbsp;</Row>
                {this.state.showFileParticipant && (
                  <Card style={{ border: '1px #ccc solid', marginBottom: '50px' }}>
                    <Card.Header className='trade_card_header' as='h6'>
                      Файлы для загрузки участником
                    </Card.Header>
                    <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                      <FileNoModal
                        onClose={this.doEmpty} // закрытие окна
                        newName={this.state.trade.nameTrade} // номер ЛОТа или торговой позиции
                        id={`${this.state.trade.keyNameTrade}_user_${localStorage.getItem('idUser')} `} // ID масива объектов загруженных файлов
                        // id={this.props.fileId} // ann
                        returnId={this.returnIdParticipant} // возвращает ID, если его не было при запуске компонента
                        getFilesNames={this.getIdFileNameParticipant} // массив объектов, инф о файлах (выход инф)
                        toNextTab={this.doEmpty}
                        disableBtn={false}
                        showNextBtn={false}
                        showDelIcon={this.props.showDelIcon}
                        showCheckIcon={this.props.showCheckIcon}
                        showAddBtn={this.props.showAddBtn}
                        asCustomer={this.props.asCustomer}
                      />
                      <Row> &nbsp;</Row>
                    </Card.Body>
                  </Card>
                )}
              </Tab>
            </Tabs>
          </div>
          <AllModalsFooter
            isPosAbsoluteNedded={true}
            footerContent={
              <SaveCurrentDataButton
                handleSaveClick={this.handleClickSaveData}
                needDisable={false}
                savedDataMessage={this.state.savedDataMessage}
                buttonTextDefault={'Сохранить'}
                buttonTextSaved={'Данные сохранены'}
              />
            }
          />
          <Row> &nbsp; </Row>
          <Row> &nbsp; </Row>
          {/* </ div> */}
        </div>
      </>
    )
  }
}
