import React, { useEffect, useState } from "react";
import { Modal, Alert, Button, Container, Card, Row } from "react-bootstrap";
import server from "../server/server";
import { nanoid } from "nanoid";
import ChatWs from "../chat/ChatWs";
import ParticipantInCustomerLot from "./ParticipantInCustomerLot";
import PositionChoceParticipantModal from "./PositionChoceParticipantModal";
import AllModalsFooter from "../assets/AllModalsFooter";
import { ChatButton } from "../assets/BasicButtons";

const ParticipantConfirmationLot = ({
  idLot,
  toggleConfirmationLot,
  inn,
  companies,
}) => {
  const [dataLot, setDataLot] = useState({});
  const [productionArray, setProductionArray] = useState([]);
  const [show, setShow] = useState(false);
  const [resultPosition, setResultPosition] = useState({});
  const [objConfirmation, setObjConfirmation] = useState({
    idLot: "",
    participantCompany: "",
    idFile: "",
    idFileList: "",
    data: [],
    tradePosition: [],
  });
  const [valueConfirmation, setValueConfirmation] = useState();
  const [sum, setSum] = useState(0);
  const [showQuitModalInParticipantLot, setShowQuitModalInParticipantLot] =
    useState(false);
  const [runChat] = useState();
  const [showChat, setShowChat] = useState(false);
  const [chatPreMessage, setChatPreMessage] = useState("");
  const [recipient, setRecipient] = useState("");
  const [participateRefusal, setParticipateRefusal] = useState();
  const [participantDisagreement, setparticipantDisagreement] = useState();

  useEffect(() => {
    (async () => {
      const refuse = await server.getDataFromServer(
        `${idLot}_refus_${inn}`,
        "trade_confirmation"
      );
      if (refuse && refuse.refus.length) {
        if (refuse.refus === "Отказ") {
          setParticipateRefusal(refuse);
        }
        if (refuse.refus === "Несогласие") {
          setparticipantDisagreement(refuse);
        }
      }
    })();
  }, []);

  // console.log(companies); // test
  // console.log(`inn participant company:::::: `, inn); // test

  useEffect(() => {
    (async () => {
      const result = await server.getDataFromServer(idLot, "trade");
      if (result) {
        setDataLot(result.data);
        setProductionArray(result.dataChoiceProductions);
      }

      // console.log(result); // test

      const valueData = await server.getDataFromServer(
        `${idLot}_user_${inn}`,
        "trade_confirmation"
      );

      if (valueData) {
        if (!valueData.idFilePosition) {
          valueData.idFilePosition = nanoid();
        }

        setValueConfirmation(valueData);
        // console.log(`CONFIRMATION DATA:::: `, valueData); // test
        // console.log(`CONFIRMATION POSITION ARRAY: `, valueData.tradePosition); // test
        addValuePositionInObjConfirmation(valueData.tradePosition);

        if (valueData.tradePosition.length) {
          sumUpPrice(valueData.tradePosition);
        }
      }
    })();
  }, [objConfirmation]);

  useEffect(() => {
    // console.log(`OBJECT CONFIRMATION:::: `, objConfirmation);
    (async () => {
      try {
        // let result;

        if (objConfirmation.idLot.length > 0) {
          await server.sendDataOnServer(
            objConfirmation,
            `${objConfirmation.idLot}_user_${inn}`,
            "trade_confirmation"
          );
          // console.log(`RESULT SAVE on SERVER: `, result);
        }
      } catch (err) {
        console.log(`Ошибка записи на сервер подтверждений: `, err);
      }
    })();
  }, [objConfirmation]);

  //* Запуск сравнения результатов подтверждения
  const compareConfirmationResult = (lotId) => {
    (async () => {
      try {
        const idConfirm = `${lotId}_user_${inn}`;
        // console.log(`compareConfirmationResult CLICK::  ID  :: `, idConfirm); // test
        const result = await server.compareConfirmationResult(idConfirm);

        if (result.ERROR) throw new Error(result.message);
        // console.log(`Result: `, result);
      } catch (err) {
        console.log(
          `Ошибка запуска сравнения результатов ответа участника: `,
          err
        );
      }
    })();
  };

  const launchQuitModalInParticipantLot = () => {
    setShowQuitModalInParticipantLot(!showQuitModalInParticipantLot);
  };

  const addValuePositionInObjConfirmation = (arrPosition) => {
    const resCon = objConfirmation;
    resCon.tradePosition = arrPosition;
    setObjConfirmation(resCon);
  };

  const sendPositionData = (data) => {
    // console.log(`ConfirmationLot, RESULT UP DATA::: `, data); // todo Данные от торговой позиции, формируются при закрытии окна
    const arrayPosition = objConfirmation.tradePosition;
    const resultFilter = arrayPosition.filter(
      (item) => item.idPosition !== data.idPosition
    );
    resultFilter.push(data);
    // TODO: суммируем НМЦ позиций

    // console.log(`objConfirmation::: `, objConfirmation); // test
    const resCon = objConfirmation;
    resCon.tradePosition = resultFilter;
    // console.log(`OBJ::: `, resCon); // test
    setObjConfirmation(resCon);
  };

  const sumUpPrice = (arr) => {
    let sum = 0;
    arr.forEach((position) => {
      if (position.dataComm && position.dataComm.length) {
        const sumPos = Number(
          position.dataComm.find((item) => item.id === "10").value
        );
        const quantity = position.dataComm.find(
          (item) => item.id === "6"
        ).value;

        if (sumPos && quantity && typeof quantity === "string")
          sum = sum + sumPos * Number(quantity);
        if (sumPos && quantity && typeof quantity === "boolean")
          sum = sum + sumPos * 1;
      }
    });
    setSum(sum);
  };

  const choicePositionId = async (e) => {
    const result = await server.getDataFromServer(e, "trade_position");
    setResultPosition(result);

    // console.log(`RESULT POSITION DATA:::: `, result); // test
    setShow(true);
  };

  const onClosePosition = () => {
    setShow(false);
  };

  //собираем результаты подтверждений
  const sendResultConfirmationLot = (obj) => {
    const tradePosition = objConfirmation.tradePosition;
    Object.assign(obj, { tradePosition });
    setObjConfirmation(obj);
  };

  const toggleChatModal = () => {
    setShowChat(!showChat);
  };

  const empty = () => {};

  const handleClickChat = (text, recipient) => {
    setChatPreMessage(text);
    setRecipient(recipient);

    if (text) {
      setShowChat(true);
    }

    console.log(text, recipient);
  };

  const openChat = () => {
    toggleChatModal();
    handleClickChat("", "");
  };

  const refused = participateRefusal && !participantDisagreement;
  const disagred = !participateRefusal && participantDisagreement;
  const allOk =
    !participantDisagreement && !participateRefusal && !participateRefusal;

  return (
    <>
      {show && (
        <PositionChoceParticipantModal
          dataPosition={resultPosition}
          onClosePosition={onClosePosition}
          sendPositionData={sendPositionData}
          value={valueConfirmation}
          runChat={handleClickChat}
          organizerName={dataLot.organizerName}
          iAmCustomerLookingHowParticipantFilledPosition={true}
          numberLot={dataLot.numberLot}
          listForFiles={dataLot.listForFiles}
          disableParticipantData={true}
          inn={inn}
        />
      )}

      {showChat && (
        <ChatWs
          onChangeVisibleChat={toggleChatModal} //открыть/закрыть модалку чата
          onCreateIdChat={empty}
          idChat={dataLot.chat}
          forName="all" // для организатора прокинуть 'all'
          //   forName={dataLot.organizerId} // для участника прокинуть ID организатора
          idCompany={dataLot.participant}
          //   filterAll={[String(dataLot.organizerId)]} // обязательный пропс для участника
          idBox={dataLot.id}
          updateQuantityMessage={empty}
          preText={chatPreMessage} //текст элемента, к которому есть вопрос
          recipient={recipient}
          showChat={showChat}
          //todo: добавить заглавие сообщения при обращении к чату из позиций просмотра лота и торг.позиций
        />
      )}

      {
        <div>
          <div
            className="shadow z_index_20"
            onClick={toggleConfirmationLot}
          ></div>
          <div
            className="modal_common_1 z_index_20"
            // style={{ width: '1040px' }}
          >
            <Alert
              variant="dark"
              className="filter_alert"
              onClick={toggleConfirmationLot}
              dismissible
            >
              <Alert.Heading>
                Информация для заказчика о заполнении участниками лота: "
                {dataLot.nameLot}"
              </Alert.Heading>
            </Alert>

            {refused && (
              <Modal.Body style={{ minHeight: "300px" }}>
                <Container>
                  {/* <Alert variant='danger'> */}
                  <Card style={{ border: "1px solid #dc3545" }}>
                    <Card.Header>Отказ от участия</Card.Header>
                    <Card.Body>
                      <Card.Title>
                        Участник отказался от участия в конкурсе по причине:{" "}
                      </Card.Title>
                      <Card.Text>{participateRefusal.textArea}</Card.Text>
                      <Button onClick={toggleChatModal} variant="info">
                        Открыть чат
                      </Button>
                    </Card.Body>
                  </Card>
                </Container>
              </Modal.Body>
            )}

            {disagred && (
              <Modal.Body style={{ minHeight: "300px" }}>
                <Container>
                  <Card style={{ border: "1px solid #FFD65E" }}>
                    <Card.Header>Несогласие с условием(ями)</Card.Header>
                    <Card.Body>
                      <Card.Title>
                        Участник не согласен со следующим условием(ями)
                        конкурса:{" "}
                      </Card.Title>
                      <Card.Text>{participantDisagreement.textArea}</Card.Text>
                      <Button onClick={toggleChatModal} variant="info">
                        Открыть чат
                      </Button>
                    </Card.Body>
                  </Card>
                </Container>
              </Modal.Body>
            )}
            {allOk && (
              <Modal.Body>
                <ParticipantInCustomerLot
                  lot={dataLot}
                  productionArray={productionArray}
                  choicePositionId={choicePositionId}
                  sendResultConfirmationLot={sendResultConfirmationLot}
                  value={valueConfirmation}
                  sum={sum}
                  idFile={nanoid()}
                  quitModalFlag={showQuitModalInParticipantLot}
                  toggleConfirmationLot={toggleConfirmationLot}
                  runChat={runChat} //текст элемента, к которому есть вопрос
                  compareConfirmationResult={compareConfirmationResult}
                  handleClickChat={handleClickChat}
                  toggleChatModal={toggleChatModal}
                  showChat={showChat}
                  inn={inn}
                  test="test"
                />
              </Modal.Body>
            )}

            {refused && (
              <>
                <Row> &nbsp; </Row>
                <Row> &nbsp; </Row>
              </>
            )}
            {disagred && (
              <>
                <Row> &nbsp; </Row>
                <Row> &nbsp; </Row>
              </>
            )}

            {allOk && (
              <AllModalsFooter
                footerContent={
                  <>
                    <ChatButton
                      handleSaveClick={openChat}
                      needDisable={false}
                    />
                  </>
                }
              />
            )}
          </div>
        </div>
      }
    </>
  );
};

export default ParticipantConfirmationLot;
