

/**
 * Парсит дату и время из new Date() в "DD.MM.YYYY - HH:MM:SS"
 * @param {*} dateOld 
 * @returns 
 */
const parseTime = (dateOld) => {

    const dateAll = new Date(dateOld)
    const dateY = dateAll.getFullYear()
    let dateM = dateAll.getMonth() + 1
    let dateD = dateAll.getDate()
    let dateH = dateAll.getHours()
    let dateMin = dateAll.getMinutes()
    let dateSec = dateAll.getSeconds()

    if (dateM < 10) dateM = `0${dateM}`

    if (dateD < 10) dateD = `0${dateD}`

    if (dateH < 10) dateH = `0${dateH}`

    if (dateMin < 10) dateMin = `0${dateMin}`

    if (dateSec < 10) dateSec = `0${dateSec}`

    return `${dateD}.${dateM}.${dateY} - ${dateH}:${dateMin}:${dateSec}`
}

export default parseTime;