import React, { useState, useEffect, useRef } from 'react'
import { Badge, Button, Form, Modal } from 'react-bootstrap'
import DatePicker from 'react-date-picker'
import Server from '../components/server/server'
import { GiWeight } from 'react-icons/gi'
import { BsCalendar3 } from 'react-icons/bs'

//serverId={id}
//buttonName={buttonName}

export function CapacityButton({ buttonName, id, headerText }) {
  const [showDropDown, setShowDropDown] = useState(false)
  const [dateValue, setDateValue] = useState('')
  const [weightValue, setWeightValue] = useState('')
  const [idUser] = useState(localStorage.getItem('idUser'))
  const [showBadge, setShowBadge] = useState(true)
  const [serverId] = useState(id)

  const modalDialog = useRef(null)

  useEffect(() => {
    if (!showDropDown) return

    const handleOutsideClick = (e) => {
      if (!modalDialog.current) return
      if (!modalDialog.current.contains(e.target)) {
        setShowDropDown(false)
      }
    }
    document.addEventListener('click', handleOutsideClick)
    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [showDropDown, setShowDropDown])

  useEffect(() => {
    ;(async () => {
      try {
        const res = await Server.getDataFromServer(serverId, idUser)

        if (res && !res.ERROR) {
          if (id === 'capacityDate') {
            const resDate = new Date(res.dateValue)
            setDateValue(resDate)
          }
          if (id === 'requiredWeight') {
            setWeightValue(res.weightValue)
          }
        }
      } catch (err) {
        console.log(err)
      }
    })()
  }, [])

  const handleDateChange = async (e) => {
    setDateValue(e)
    setShowBadge(false)
    Server.deleteDocument(serverId)
  }

  const handleWeigthChange = async (e) => {
    setWeightValue(e.target.value)
    setShowBadge(false)
    Server.deleteDocument(serverId)
  }

  const save = async () => {
    id === 'capacityDate' && (await Server.sendDataOnServer({ dateValue }, serverId, idUser))
    id === 'requiredWeight' && (await Server.sendDataOnServer({ weightValue }, serverId, idUser))

    setShowDropDown((prev) => !prev)
    setShowBadge(true)
  }

  const capacityDate = new Date(dateValue).toLocaleDateString()

  return (
    <div>
      <div style={{ position: 'relative', cursor: 'pointer' }} onClick={() => setShowDropDown((prev) => !prev)} title='Загруженность предприятия'>
        {/* <CalendarTodayIcon color="action" /> */}
        <Button>
          {id === 'requiredWeight' && <GiWeight style={{ fontSize: '25px', paddingRight: '5px' }} />}
          {id === 'capacityDate' && <BsCalendar3 style={{ fontSize: '25px', paddingRight: '7px' }} />}
          {buttonName}
        </Button>

        {showBadge && capacityDate && <Badge className='navbar_info_span navbar_info_span_date'>{capacityDate !== 'Invalid Date' && capacityDate}</Badge>}

        {showBadge && weightValue && <Badge className='navbar_info_span navbar_info_span_weight'>{weightValue}</Badge>}
      </div>

      {showDropDown && (
        <div ref={modalDialog}>
          <Modal.Dialog className={id === 'capacityDate' ? 'left_navbar_capacity_modal' : 'right_navbar_capacity_modal'}>
            <Modal.Header>{headerText}</Modal.Header>

            {id === 'capacityDate' && (
              <Modal.Body style={{ display: 'flex', justifyContent: 'center' }}>
                <DatePicker calendarClassName='font_family_for_calendar' format={'dd/MM/yyyy'} value={dateValue} locale={'ru-RU'} onChange={handleDateChange} />
              </Modal.Body>
            )}

            {id === 'requiredWeight' && (
              <Modal.Body style={{ display: 'flex', justifyContent: 'center', padding: '30px' }}>
                <div style={{ position: 'relstive', width: '90%' }}>
                  <p className='upper_label_position_2'>{'Минимальная масса, тн'}</p>
                  <Form.Control value={weightValue} onChange={handleWeigthChange} placeholder='Минимальная масса, тн' />
                </div>
              </Modal.Body>
            )}

            <Modal.Footer>
              <Button onClick={() => setShowDropDown((prev) => !prev)} size='sm' variant='secondary'>
                Отмена
              </Button>
              <Button onClick={save} size='sm'>
                Сохранить
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      )}
    </div>
  )
}
