import React from 'react'
import MenuPositionParent from './MenuPositionParent'
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined'
import BuildIcon from '@mui/icons-material/Build'
import LocalActivityIcon from '@mui/icons-material/LocalActivity'
import PeopleIcon from '@mui/icons-material/People'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'
import SearchIcon from '@mui/icons-material/Search'
import SideBarLogo from './SideBarLogo'
import Server from '../server/server'
import { loadImageURL } from '../settings/loadImageURL'
import { Row } from 'react-bootstrap'
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'
import LeakAddOutlinedIcon from '@mui/icons-material/LeakAddOutlined'
import ApprovalOutlinedIcon from '@mui/icons-material/ApprovalOutlined'
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined'
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined'
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined'
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined'
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined'
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined'
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined'
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined'
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined'
import PermDataSettingOutlinedIcon from '@mui/icons-material/PermDataSettingOutlined'
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined'
import FilePresentOutlinedIcon from '@mui/icons-material/FilePresentOutlined'
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined'
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined'
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined'
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined'
import RuleOutlinedIcon from '@mui/icons-material/RuleOutlined'
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined'
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined'
import SettingsApplicationsOutlinedIcon from '@mui/icons-material/SettingsApplicationsOutlined'
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined'

/**
 * Ключи доступа к меню:
 *  full - полный доступ
 *  zero - начальный доступ, только анкета
 *  one - доступ до программы расчета
 *  two - доступ для производителя ( в т.ч. программа расчета)
 */

export default class SaidBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      access: false,
      view: true,
      icon: '',
      showSidebarIcon: true,
      url: '',
      isSectionOpen: new Array(9).fill(false),
      numberOfSections: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      eventKeyArray: ['0', '1', '2', '3', '4', '5', '6', '7', '8']
    }

    this.handleFocus = this.handleFocus.bind(this)
    this.doTransferUrl = this.doTransferUrl.bind(this)
    this.loadRegKey = this.loadRegKey.bind(this)
    this.doChangeLogo = this.doChangeLogo.bind(this)
    this.updateIcon = this.updateIcon.bind(this)
    this.handleAccordionOpen = this.handleAccordionOpen.bind(this)
  }

  async handleAccordionOpen(idx) {
    const arrCopy = [...this.state.isSectionOpen]
    const newArr = arrCopy.map((_, index) => index === idx)
    await this.setState({ isSectionOpen: newArr }, () => {
      //  console.log(newArr);
    })

    // await  this.setState((prevState) => {
    //     const arrCopy = [...prevState.isSectionOpen];
    //     const newArr = arrCopy.map((item, index) => index === idx);
    //     console.log(newArr);
    //     return { isSectionOpen: newArr };
    //   })

    // const copy = [...this.state.eventKeyArray];
    // const newArr = copy.map((item, index) => index === idx ? '1' : '0');
    // this.setState({
    //   eventKeyArray: newArr,
    // }, () => {
    //   console.log('arr', newArr);
    // });
  }

  updateIcon() {
    this.doChangeLogo()
  }

  async doChangeLogo() {
    this.setState({ icon: await loadImageURL(`icon_logo`) }) // возвращает URL строку для icon
  }

  async loadRegKey() {
    try {
      const login = sessionStorage.getItem('login')
      const result = await Server.getDataFromServer(login, 'registration')
      const inn = localStorage.getItem('idUser')
      let keyAccess = result.key[inn]

      if (!keyAccess) {
        keyAccess = 'zero'
      }

      await this.setState({
        access: keyAccess,
        view: false
      })

      setTimeout(() => {
        this.setState({ view: true })
      }, 500)
    } catch (err) {
      console.log(`Ошибка или нет полного доступа к меню`)
    }
  }

  /**
   * Запуск моудя согласно URL
   * @param {*} url
   */
  doTransferUrl(url) {
    if (url === '/admin' || url === '/logger') {
      const passwordAdmin = prompt('password admin: ')

      //* проверка пароля администратора
      if (passwordAdmin === '12345') {
        // console.log(`transferUrl - ADMIN PANEL RUN::: `, url) // test
      } else {
        url = ''
      }
    }
    // console.log(`transferUrl::: `, url) // test
    this.setState({ url })
    this.props.onTransferUrl(url)
  }

  handleFocus() {
    this.props.onFocusSaidBar()
  }

  async componentDidMount() {
    await this.loadRegKey()
    await this.doChangeLogo()
  }

  render() {
    const show = this.props.show
    const arrFullName = [
      <>
        <BusinessCenterOutlinedIcon fontSize='small' className='style_for_icons' /> Возможности
      </>,
      <>
        <LeakAddOutlinedIcon fontSize='small' className='style_for_icons' /> Референц
      </>,
      <>
        <ApprovalOutlinedIcon fontSize='small' className='style_for_icons' /> Лицензии
      </>,
      <>
        <AnnouncementOutlinedIcon fontSize='small' className='style_for_icons' />
        Новости
      </>,
      <>
        <PublishOutlinedIcon fontSize='small' className='style_for_icons' />
        Загрузка
      </>,
      <>
        <GroupsOutlinedIcon fontSize='small' className='style_for_icons' />
        Сотрудники
      </>,
      <>
        <FolderSharedOutlinedIcon fontSize='small' className='style_for_icons' />
        Анкета
      </>
    ]
    const arrFullLink = [
      '/opportunities',
      '/references',
      '/licenses',
      '/news',
      '/loading',
      '/employees',
      '/questionnaire'
    ]
    const arrName = ['Анкета']
    const arrLink = ['/questionnaire']

    const toolsMenuMin = ['Расчеты т/о LOTUS']
    const toolsLinkMin = ['/calculations']

    this.nameCompany = this.props.nameCompany
    const access = this.state.access

    // onMouseOver = { this.handleFocus } //*SaidBar раскладывался при перемещении мышки, сейчас по клику
    return (
      <>
        <div
          className='said-bar'
          style={{ width: show ? '55px' : '300px' }}
          onClick={this.handleFocus}
        >
          {this.state.showSidebarIcon && (
            <SideBarLogo
              show={show}
              nameCompany={this.nameCompany}
              icon={this.state.icon}
              openSettings={this.props.openSettings}
              updateIcon={this.updateIcon}
              doChangeLogo={this.doChangeLogo}
              showSettings={this.props.showSettings}
              newIcon={this.props.newIcon}
            />
          )}

          {!show && (
            <>
              <Row> &nbsp; </Row>
              <Row> &nbsp; </Row>
              <Row> &nbsp; </Row>
              <Row> &nbsp; </Row>
            </>
          )}

          {(access === 'full' || access === 'two') && (
            <MenuPositionParent
              show={this.props.show}
              icon={<SearchIcon className='m_0_10' />}
              name='Поиск'
              nameMenu={
                access === 'full'
                  ? [
                    <>
                      <SearchIcon fontSize='small' className='style_for_icons' /> Компании
                    </>,
                    <>
                      <SearchIcon fontSize='small' className='style_for_icons' /> Товары/услуги
                    </>
                    // <>
                    //   <GradingOutlinedIcon fontSize='small' className='style_for_icons' />
                    //   Поиск по совпадению
                    // </>
                  ]
                  : [
                    <>
                      <SearchIcon fontSize='small' className='style_for_icons' /> Компании
                    </>,
                    <>
                      <SearchIcon fontSize='small' className='style_for_icons' /> Товары/услуги
                    </>
                  ]
              }
              urlMenu={
                access === 'full'
                  ? ['/company_search', '/production_search', '/search_by_match']
                  : ['/company_search', '/production_search']
              }
              onTransferUrl={this.doTransferUrl}
              // eventKey='0' //раскрытие меню 0-закрыто / 1-раскрыто
              eventKey={this.state.eventKeyArray[0]}
              numberOfSection={this.state.numberOfSections[0]}
              isSectionOpen={this.state.isSectionOpen[0]}
              url={this.state.url}
              onAccordionOpen={this.handleAccordionOpen}
            />
          )}

          {this.state.view && (
            <MenuPositionParent
              show={this.props.show}
              icon={<FindInPageOutlinedIcon className='m_0_10' />}
              name='Данные компании'
              nameMenu={access === 'full' ? arrFullName : arrName}
              urlMenu={access === 'full' ? arrFullLink : arrLink}
              onTransferUrl={this.doTransferUrl}
              // eventKey='0' //раскрытие меню 0-закрыто / 1-раскрыто
              eventKey={this.state.eventKeyArray[1]}
              numberOfSection={this.state.numberOfSections[1]}
              isSectionOpen={this.state.isSectionOpen[1]}
              url={this.state.url}
              onAccordionOpen={this.handleAccordionOpen}
            />
          )}

          {(access === 'full' || access === 'one' || access === 'two') && (
            <MenuPositionParent
              show={this.props.show}
              icon={<BuildIcon className='m_0_10' />}
              name='Инструменты'
              nameMenu={
                access === 'full'
                  ? [
                    <>
                      <BuildCircleOutlinedIcon fontSize='small' className='style_for_icons' />
                      Прочностные
                    </>,
                    <>
                      <DriveFileRenameOutlineOutlinedIcon
                        fontSize='small'
                        className='style_for_icons'
                      />{' '}
                      Быстрый эскиз
                    </>,
                    <>
                      <PermDataSettingOutlinedIcon fontSize='small' className='style_for_icons' />
                      База т/о LOTUS
                    </>,
                    <>
                      <NoteAltOutlinedIcon fontSize='small' className='style_for_icons' />
                      Тех.проекты
                    </>,
                    <>
                      <FilePresentOutlinedIcon fontSize='small' className='style_for_icons' />
                      Презентация LOTUS
                    </>,
                    <>
                      <FilePresentOutlinedIcon fontSize='small' className='style_for_icons' />
                      Презентация STHE
                    </>,
                    <>
                      <PermDataSettingOutlinedIcon fontSize='small' className='style_for_icons' />
                      Расчет массогабаритных характеристик теплообменников
                    </>,
                    <>
                      <DriveFileRenameOutlineOutlinedIcon fontSize='small' className='style_for_icons' />
                      Расчет стоимости теплообменника
                    </>,
                  ]
                  : toolsMenuMin
              }
              urlMenu={
                access === 'full'
                  ? [
                    '/endurance',
                    '/sketch',
                    '/calculations',
                    '/projects',
                    '/lotus_presentation',
                    '/sthe_presentation',
                    '/calculation_heat_exchangers',
                    '/coast_heat_exchengers',
                  ]
                  : toolsLinkMin
              }
              onTransferUrl={this.doTransferUrl}
              // eventKey='0'
              eventKey={this.state.eventKeyArray[2]}
              numberOfSection={this.state.numberOfSections[2]}
              isSectionOpen={this.state.isSectionOpen[2]}
              url={this.state.url}
              onAccordionOpen={this.handleAccordionOpen}
            />
          )}

          {(access === 'full' || access === 'two') && (
            <MenuPositionParent
              show={this.props.show}
              icon={<LocalActivityIcon className='m_0_10' />}
              name='Конкурсы'
              nameMenu={[
                <>
                  <EngineeringOutlinedIcon fontSize='small' className='style_for_icons' />
                  Как участник
                </>,
                <>
                  <RecordVoiceOverOutlinedIcon fontSize='small' className='style_for_icons' />
                  Как заказчик
                </>,
                <>
                  <QueryStatsOutlinedIcon fontSize='small' className='style_for_icons' />
                  Опрос рынка
                </>
              ]}
              urlMenu={['/participant', '/customer', '/survey']}
              onTransferUrl={this.doTransferUrl}
              // eventKey='0'
              eventKey={this.state.eventKeyArray[3]}
              numberOfSection={this.state.numberOfSections[3]}
              isSectionOpen={this.state.isSectionOpen[3]}
              url={this.state.url}
              onAccordionOpen={this.handleAccordionOpen}
            />
          )}

          {/*  */}
          {access === 'full' && (
            <MenuPositionParent
              show={this.props.show}
              icon={<ArticleOutlinedIcon className='m_0_10' />}
              name='Заказ-наряд'
              nameMenu={[
                <>
                  <PostAddOutlinedIcon fontSize='small' className='style_for_icons' />
                  Создать заказ-наряд
                </>,
                <>
                  <ArticleOutlinedIcon fontSize='small' className='style_for_icons' />
                  Заказ-наряды
                </>
              ]}
              urlMenu={['/purchase_order', '/purchase_order_list']}
              onTransferUrl={this.doTransferUrl}
              // eventKey='0'
              eventKey={this.state.eventKeyArray[4]}
              numberOfSection={this.state.numberOfSections[4]}
              isSectionOpen={this.state.isSectionOpen[4]}
              url={this.state.url}
              onAccordionOpen={this.handleAccordionOpen}
            />
          )}
          {/*  */}

          {access === 'full' && (
            <MenuPositionParent
              show={this.props.show}
              icon={<PeopleIcon className='m_0_10' />}
              name='Кооперация'
              nameMenu={[
                <>
                  <BuildCircleOutlinedIcon className='style_for_icons' />
                  Тех.возможности других
                </>,
                <>
                  <CreditScoreOutlinedIcon className='style_for_icons' />
                  Финансирование проектов
                </>,
                <>
                  <SettingsSuggestOutlinedIcon className='style_for_icons' />
                  Производственные услуги
                </>,
                <>
                  <PermIdentityOutlinedIcon className='style_for_icons' />
                  Человеческие ресурсы
                </>
              ]}
              urlMenu={['/tech_others', '/funding', '/production', '/people']}
              onTransferUrl={this.doTransferUrl}
              // eventKey='0'
              numberOfSection={this.state.numberOfSections[5]}
              isSectionOpen={this.state.isSectionOpen[5]}
              eventKey={this.state.eventKeyArray[5]}
              url={this.state.url}
              onAccordionOpen={this.handleAccordionOpen}
            />
          )}

          {access === 'full' && (
            <MenuPositionParent
              show={this.props.show}
              icon={<LocalShippingIcon className='m_0_10' />}
              name='Снабжение'
              nameMenu={[
                <>
                  <Inventory2OutlinedIcon className='style_for_icons' />
                  Складская программа
                </>,
                <>
                  <RuleOutlinedIcon className='style_for_icons' />
                  Остатки производства
                </>,
                <>
                  <FormatListBulletedOutlinedIcon className='style_for_icons' />
                  Потребности
                </>,
                <>
                  <ConstructionOutlinedIcon className='style_for_icons' />
                  Оборудование
                </>,
                <>
                  <SettingsApplicationsOutlinedIcon className='style_for_icons' />
                  Оснастки
                </>
              ]}
              urlMenu={['/warehouse', '/remains', '/needs', '/equipment', '/snap-ins']}
              onTransferUrl={this.doTransferUrl}
              // eventKey='0'
              eventKey={this.state.eventKeyArray[6]}
              numberOfSection={this.state.numberOfSections[6]}
              isSectionOpen={this.state.isSectionOpen[6]}
              url={this.state.url}
              onAccordionOpen={this.handleAccordionOpen}
            />
          )}

          {access === 'full' && (
            <MenuPositionParent
              show={this.props.show}
              icon={<ConnectWithoutContactIcon className='m_0_10' />}
              name='Консультация'
              nameMenu={[
                <>
                  <AddchartOutlinedIcon className='style_for_icons' />
                  Коуч
                </>,
                <>
                  <ScienceOutlinedIcon className='style_for_icons' />
                  Лаборатории
                </>,
                <>
                  <ScienceOutlinedIcon className='style_for_icons' />
                  ADMIN PANEL
                </>,
                <>
                  <ScienceOutlinedIcon className='style_for_icons' />
                  LOGGER
                </>
              ]}
              urlMenu={['/coach', '/laboratory', '/admin', '/logger']}
              onTransferUrl={this.doTransferUrl}
              // eventKey='0'
              eventKey={this.state.eventKeyArray[7]}
              numberOfSection={this.state.numberOfSections[7]}
              isSectionOpen={this.state.isSectionOpen[7]}
              url={this.state.url}
              onAccordionOpen={this.handleAccordionOpen}
            />
          )}

          {(access === 'full' || access === 'one' || access === 'two') && (
            <MenuPositionParent
              show={this.props.show}
              icon={<BuildIcon className='m_0_10' />}
              name='Реестр аппаратов'
              nameMenu={
                access === 'full'
                  ? [
                    <>
                      <BuildCircleOutlinedIcon fontSize='small' className='style_for_icons' />
                      Поиск по параметрам
                    </>,
                    <>
                      <DriveFileRenameOutlineOutlinedIcon
                        fontSize='small'
                        className='style_for_icons'
                      />
                      Поиск по QR-коду
                    </>,
                    <>
                      <PermDataSettingOutlinedIcon fontSize='small' className='style_for_icons' />
                      Жизненный цикл теплообменного аппарата
                    </>
                  ]
                  : []
              }
              urlMenu={
                access === 'full' ? ['/search_options', '/search_qr', '/life_cycle'] : toolsLinkMin
              }
              onTransferUrl={this.doTransferUrl}
              // eventKey='0'
              eventKey={this.state.eventKeyArray[8]}
              numberOfSection={this.state.numberOfSections[8]}
              isSectionOpen={this.state.isSectionOpen[8]}
              url={this.state.url}
              onAccordionOpen={this.handleAccordionOpen}
            />
          )}
        </div>
      </>
    )
  }
}
