import React from 'react'
import { Alert, Container } from 'react-bootstrap'
import EmployeeAlertContex from './EmployeeAlertContex'

// toggleModal = {закрывает модалку на крестик}
// alertHeading = {заглавие модалки}
// modalContent = {содержимое модалки, все что внутри}

/**
 * Рендер выбора оповещений модалка
 * @param {*} param0 
 * @returns 
 */
export default function EmployeeModalMini({ toggleModal, alertHeading, modalContent, sendAlertContex, valueData }) {
    return (
        <>
            <div className="shadow_form" onClick={toggleModal} ></div>
            <div className="modal_common_mini z_index_1000" >
                <Alert variant="dark" onClose={toggleModal} dismissible>
                    <Alert.Heading >
                        {alertHeading}
                    </Alert.Heading>
                </Alert>
                <Container style={{ height: '300px', overflowY: 'auto' }}>
                    <EmployeeAlertContex
                        modalContent={modalContent}
                        sendAlertContex={sendAlertContex}
                        valueData={valueData}
                    />
                </Container>
            </div>
        </>
    )
}
