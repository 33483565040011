import React, { useEffect, useState } from 'react';
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import Server from './server/server';
import {SaveIcon, LeaveIcon } from './CommonIcons';

//***INCOMING elements:***
//onQuitWithSave - выход с сохранением (сохраняет, закрывает все модалки)
//onQuitWithoutSave - выход без сохранения (не сохраняет, закрывает все модалки)
//closeQuitModal - закрывает только модалку выхода
//modalTitle - заголовок модалки выхода, например "Выход"
//modalBody - основной текст, например "Вы действительно хотите выйти из формы"
//primaryButton - гдавная синяя кнопка слева, например, "Сохранить"
//secondaryButton - второстепенная кнопка справа, например "Отменить"
//icon - иконка в заголовке

export default function QuitModal({ onQuitWithSave, onQuitWithoutSave, closeQuitModal, modalTitle, modalBody, primaryButton, secondaryButton, icon, inputF, lot, darkVariant }) {

    const [text, setText] = useState('');

    useEffect(() => {
        const onKeyPressHandler = (event) => {
            if (event.keyCode === 13) {
                handleClickQuitWithSave()
            }
        }
        document.addEventListener('keydown', onKeyPressHandler)
        return () => {
            document.removeEventListener('keydown', onKeyPressHandler);
        }
    }, [])


    // console.log(lot); // test

    const handleClickQuitWithSave = (areaText) => {
        onQuitWithSave();
        (async () => {
            try {

                if (areaText || modalTitle) {
                    let objAreaText;
                    // console.log(`TEXT DATA:::: `, areaText); // test

                    if (modalTitle === 'Отказ от участия' && lot.id) {

                        objAreaText = {
                            idCompany: localStorage.getItem('idUser'),
                            idLot: lot.id,
                            textArea: areaText,
                            refus: 'Отказ'
                        }
                    }

                    if (modalTitle === 'Несогласие с условиями' && lot.id) {

                        objAreaText = {
                            idCompany: localStorage.getItem('idUser'),
                            idLot: lot.id,
                            textArea: areaText,
                            refus: 'Несогласие'
                        }
                    }

                    const id = `${lot.id}_refus_${objAreaText.idCompany}`

                    // console.log(`OBJ refus:::: `, objAreaText); // test                   
                    // console.log(`_id: ${id}`); // test

                    // * отправка причины отказа 

                    if (objAreaText) {
                        const result = await Server.sendDataOnServer(objAreaText, id, 'trade_confirmation')
                        // console.log(`RESULT :::: `, result);
                    }
                }

                onQuitWithSave();

            } catch (err) {
                console.log(`Ошибка отправки причины отказа (QuiteModal.jsx): `, err);

                onQuitWithSave();
            }

        })()
    }

    const handleClickQuitWithoutSave = (areaText) => {
        onQuitWithoutSave();
    }

    const handleChangeInput = (e) => {
        // console.log(`DATA :::: `, e.target.value); // test
        setText(e.target.value)
    }


    // console.log(inputF);

    return (
        <>
            <div 
            // className="modal_window"
            >
                <div className="shadow_form z_index_extra_big"  onClick={closeQuitModal}></div>

                <Modal.Dialog className='quit_modal z_index_extra_big width_500'>
                    <Alert variant={darkVariant} style={{ borderBottom: '1px #dee2e6 solid' }} dismissible onClose={closeQuitModal}>
                        <Modal.Title>{icon ? icon : <ExitToAppSharpIcon fontSize="large" className='icon_leave_modal' />}{' '}{modalTitle}</Modal.Title>
                    </Alert>

                    <Modal.Body>
                        <p>{modalBody}</p>

                        {inputF &&
                            <Form.Control
                                as='textarea'
                                rows={3}
                                onChange={handleChangeInput}
                            />}

                    </Modal.Body>

                    <Modal.Footer>
                        {inputF &&
                            <Button
                                disabled={text && text.length ? false : true}
                                variant={'primary'} onClick={() => { handleClickQuitWithSave(text) }}>
                                    <SaveIcon/>
                                {primaryButton}
                            </Button>}
                        {!inputF &&
                            <Button
                                variant={'primary'} onClick={() => { handleClickQuitWithSave(text) }}>
                                    <SaveOutlinedIcon style={{ marginRight: "5px" }}/>
                                {primaryButton}
                            </Button>}

                        <Button variant="secondary" onClick={handleClickQuitWithoutSave}>
                            <LeaveIcon/>
                            {secondaryButton}
                        </Button>
                    </Modal.Footer>
                </Modal.Dialog>
            </div>
        </>
    )
}
