import React from 'react'
import { Col, Form } from 'react-bootstrap'
import { nanoid } from 'nanoid'

export default class CheckForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      id: '',
      fid: '',
      description: '',
      information: '',
      value: '',
      colorError: '#fff',
      placeholder: '',
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  handleBlur(e) {
    this.setState({
      id: e.target.dataset.id,
      fid: `${this.props.nameForm}_${this.props.id}`,
      description: e.target.dataset.description,
      information: e.target.dataset.information
    })
    setTimeout(() => {
      this.props.onChangeValue(this.state)
    })
  }

  handleChange(e) {
    this.setState({ value: e.target.checked })
    this.props.onChangeVisionBlock(e.target.checked, e.target.dataset.id)
    this.handleBlur(e)
  }

  componentDidMount() {
    this.setState({ value: this.props.value })

    if (typeof (this.props.placeholder) !== 'string') {
      this.setState({ placeholder: this.props.placeholder.props.children })
    } else {
      this.setState({ placeholder: this.props.placeholder })
    }
  }

  render() {
    const width = this.props.width
    // const label = this.props.label;
    const id = this.props.id
    const description = this.props.description
    const placeholder = this.state.placeholder
    const value = this.state.value
    const show = this.props.show
    const className = this.props.className
    const {test} = this.props

    return (
      <React.Fragment key={id}>
        <Col sm={width} style={{ marginRight: `${this.props.marR}`, display: show ? 'block' : 'none' }} className={className ? className : ''}>
          <Form.Check
            id={`${nanoid()}_${this.props.id}_${this.props.name}`}
            type='checkbox'
            label={placeholder}
            data-id={id}
            data-information={placeholder}
            data-description={description}
            checked={value}
            onChange={this.handleChange}
            // onBlur={this.handleBlur}
            className={`pt_25 cursor_pointer`}//* положение checkbox вертикально
            disabled={this.props.readOnly}
          />
        </Col>
      </React.Fragment>
    )
  }
}
