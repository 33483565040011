import React from 'react';
import ReactDOM from 'react-dom';

const ModalOverlay = ({ isVisible, className }) => {
    return ReactDOM.createPortal(
        isVisible && <div className={`modal_overlay ${className}`}></div>,
        document.body
    );
};

export default ModalOverlay;