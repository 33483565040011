import React from 'react';
import CheckForm from './CheckForm';
import { Col } from 'react-bootstrap';

export default class FormMaterList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            id: 1,
            base: [],
            materials: [],
            checkArray: [],
            writeArray: false

        }

        this.renderListMaterials = this.renderListMaterials.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
        this.doChangeValue = this.doChangeValue.bind(this);
        this.writeValueArrayFirstRun = this.writeValueArrayFirstRun.bind(this);
    }

    //*изменение данных
    doChangeValue(e) {

        console.log(e);//test
        console.log(e.id + ' - ' + e.information + ' === ' + e.value);//test
        let checkArr = this.state.checkArray;
        checkArr[+e.id] = e.value;
        this.setState({ checkArray: checkArr });

        setTimeout(() => {
            let changeData = { id: this.props.id, fid: `${this.props.nameForm}_${this.props.id}`, description: this.props.description, information: this.state.materials, value: this.state.checkArray }
            console.log(changeData);//test
            this.props.onChangeValue(changeData);//* возвращает массив information <- materials и массив value
        })
    }

    doEmpty(e, id) {
    }

    //*при первом запуске заполняем массив занчений на true
    writeValueArrayFirstRun(array) {

        let valueArr = [];
        array.forEach(() => {
            valueArr = [...valueArr, !this.props.selectCheck]
        })
        this.setState({ checkArray: valueArr })

        //! сразу создает массив со значениями true вне зависимости от выбора ключевого элемента        
        setTimeout(() => {
            let changeData = { id: this.props.id, fid: `${this.props.nameForm}_${this.props.id}`, description: this.props.description, information: this.state.materials, value: this.state.checkArray }
            // console.log(changeData);//test
            this.props.onChangeValue(changeData);//* возвращает массив information <- materials и массив value
        })
    }


    renderListMaterials(elem) {
        this.dataM = [];

        elem.forEach((data, index) => {

            this.dataM = [...this.dataM,
            <React.Fragment key={index}>
                <CheckForm
                    id={index}
                    width={this.props.width}
                    show={!this.props.readOnly || this.state.checkArray[index] ? true : false}
                    label={this.props.materials}
                    placeholder={data} //* подпись LABEL для чекбокса
                    description={this.props.description}
                    value={this.state.checkArray[index]}
                    onChangeVisionBlock={this.doEmpty}
                    onChangeValue={this.doChangeValue}
                    readOnly={this.props.readOnly}
                />
            </React.Fragment>
            ]
        })
        this.setState({ base: this.dataM });
    }

    componentDidMount() {
        if (typeof (this.props.value) !== 'object') {
            this.writeValueArrayFirstRun(this.props.materials)
        } else {
            this.setState({ checkArray: this.props.value })
        }
        this.setState({
            materials: this.props.materials,
            id: this.props.id,
        });
        setTimeout(() => { this.renderListMaterials(this.props.materials) }, 500)

    }

    render() {

        this.value = this.props.value

        return (
            <>

                {this.props.rowView ?
                    <>
                        {this.state.base}
                    </>
                    :
                    <Col
                        data-id={this.props.id}
                        description={this.props.description}
                        className='display_flex container_flex_row_wrap'
                    >
                        {this.state.base}
                    </Col>}
            </>
        )
    }
}