/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Alert, Container, Form, Row, Button, Table, Badge } from 'react-bootstrap';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import ModalQuit from '../ModalQuit'
import { SaveButton } from '../assets/BasicButtons';

export default class PriorityModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            language: 'rus',
            numPriority: 1,
            show: false,
            base: [],
            dataPriority: [
                { name: "Стоимость изделия/поставки", priority: '' },
                { name: "Условия оплаты", priority: '' },
                { name: "Сроки поставки/изготовления", priority: '' },
                { name: "Срок гарантии", priority: '' },
                { name: "Рейтинг поставщика/производителя", priority: '' }
            ]
        }

        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);
        this.handleClickPriority = this.handleClickPriority.bind(this);
        this.handleSavePriority = this.handleSavePriority.bind(this);
    }

    //сохранение приоритетов
    handleSavePriority() {
        // console.log(`SAVE PRIORITY::::: `, this.state.dataPriority); // todo сделать сохранение
        this.props.onSavePriority(this.state.dataPriority);
        this.doQuitWithSave();
    }

    //присвоение приоритетов
    async handleClickPriority(e) {
        // console.log(e.target.dataset.id);//test
        let data = this.state.dataPriority;
        data[Number(e.target.dataset.id)].priority = this.state.numPriority;

        await this.setState({
            dataPriority: data,
            numPriority: this.state.numPriority + 1
        })

        if (this.state.numPriority > this.state.dataPriority.length) {
            this.setState({ numPriority: 1 })
        }

        // this.renderPriority();
    }

    //запуск выхода из формы
    handleClickShadow() {
        this.setState({ showQuit: true }) // сохранение тут не работает, только выбор 
        // this.doQuitWithoutSave() // выход из формы
    }

    //выход с сохранением
    doQuitWithSave() {
        this.props.onChangeView('PriorityModal');
    }
    //выход без сохранения
    doQuitWithoutSave() {
        // this.setState({ showQuit: false })
        this.props.onChangeView('PriorityModal');
    }

    // renderPriority() {
    //     const rend = this.state.dataPriority.map((data, index) => {
    //         const name = `${data.name} > `;
    //         return (
    //             <React.Fragment key={index}>
    //                 <div
    //                     data-id={index}
    //                     onClick={this.handleClickPriority}
    //                     style={{ cursor: 'pointer' }}
    //                 >
    //                     <span data-id={index} >{name}</span>
    //                     <span data-id={index} >{data.priority}</span>
    //                 </div>
    //                 <div> &nbsp; </div>
    //             </React.Fragment>
    //         )
    //     })
    //     this.setState({ base: rend })
    // }
    // componentDidMount() {
    //     this.renderPriority();
    // }

    render() {
        const view = this.props.view;
        this.value = this.props.data;
        const viewQuest = this.props.viewQuest;

        return (
            <>


                {this.state.showQuit ?
                    <ModalQuit
                        onQuitWithSave={this.doQuitWithSave}
                        onQuitWithoutSave={this.doQuitWithoutSave}
                    />
                    : ''}
                <div className="modal_window z_index_1000" style={{ display: view ? 'block' : 'none', }} >
                    <div className={viewQuest ? "shadow_form z_index_1000" : "shadow_form_off"} onClick={this.handleClickShadow}></div>
                    <Form className={viewQuest ? "modal_common width_60 z_index_1001 p_40" : "form_main_view"} >

                        <Alert
                            
                            variant="dark"
                            className="header_alert_common color_000"
                            onClick={this.handleClickHeader}
                            onClose={() => this.handleClickShadow()}
                            dismissible>
                            <Alert.Heading > <ContactMailOutlinedIcon fontSize="large" className='icon_primary' /> Назначение приоритетов критериям торгов </Alert.Heading>
                        </Alert>
                        <Container className="form_container">

                            <Row> &nbsp;</Row>
                            <Alert variant='info' style={{ fontSize: '.9em' }}>
                                <InfoOutlinedIcon fontSize="small" className='pb_3' /> Для расстановки приоритетов необходимо кликнуть по полю "Наименование"
                            </Alert>
                            
                            <Table bordered hover >
                                <thead>
                                    <tr>
                                        <th className='fw_bolder'><DescriptionOutlinedIcon color="disabled" fontSize="small" className='pb_3' /> Наименование</th>
                                        <th className='fw_bolder'><GradeOutlinedIcon color="disabled" fontSize="small" className='pb_3' /> Приоритет</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.dataPriority.map((p, idx) => (
                                        <tr key={idx}>
                                            <td data-id={idx} onClick={this.handleClickPriority} className='cursor_pointer'>{p.name}</td>
                                            <td data-id={idx} className='cursor_pointer'>
                                                <Badge pill variant={"primary"}>
                                                    {p.priority}
                                                    <GradeOutlinedIcon
                                                        color="disabled"
                                                        fontSize="small"
                                                        className='pb_3' />
                                                </Badge>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <Row> &nbsp;</Row>
                            {/* <Button variant="success" onClick={this.handleSavePriority} > Сохранить </Button> */}
                            <SaveButton
                             handleSaveClick={this.handleSavePriority} needDisable={false} saveButtonText={'Сохранить'}
                            />

                        </Container>
                    </Form>
                </div >
            </>
        )
    }
}