import React, { useState, useEffect } from 'react'
import { Card, Table } from 'react-bootstrap'
import ModalHoverMouse from './ModalHoverMouse'
import UniversalTable from '../../assets/UniversalTable'

/**
 * Таблица Реестр аппратаов
 * @param {*} param0
 * @returns
 */
const TableRegistry = ({
  cardEquipment,
  selectedIdEquipment,
  showTable,
  triggerRender,
  setShowTable,
  offset,
  tableKey
}) => {
  const [equipmentObject, setEquipmentObject] = useState({})
  const [coordinates, setCoordinates] = useState({})
  const [showModal, setShowModal] = useState(false)
  // const [idForQuestionary, setIdForQuestionary] = useState(false)

  useEffect(() => {
    setShowTable(false)
    setTimeout(() => {
      setShowTable(true)
    }, 500)
  }, [triggerRender])

  const handleClickPosition = (id) => {
    selectedIdEquipment(id)
  }

  const handleMouseHover = (e) => {
    // console.log(`pageX::: ${e.pageX}  -  pageY::: ${e.pageY}  - target ID::: ${e.target.dataset.id}`); // test
    const card = cardEquipment.filter((item) => item.id === e.target.dataset.id)

    setEquipmentObject(card[0])
    setCoordinates({ pageX: e.pageX, pageY: e.pageY })
    setShowModal(true)
  }

  const handleMouseOut = () => {
    setShowModal(false)
  }

  // console.log(cardEquipment)

  // { display: 'Наименование предприятия потребителя', key: 'consumerEnterpriseName' },
  // { display: 'Наименование установки', key: 'installationName' },
  // { display: 'Номер технологической позиции', key: 'technologicalPositionNumber' },
  // { display: 'Регистрационный номер в базе', key: 'registrationNumberInDatabase' },
  // { display: 'Наименование оборудования', key: 'equipmentName' },
  // { display: 'Завод-изготовитель', key: 'manufacturerName' },
  // { display: 'Заводской номер аппарата', key: 'deviceSerialNumber' },
  // { display: 'Предприятие поставщик', key: 'supplierEnterpriseName' },
  // { display: 'Наименование рабочей среды в трубном пространстве', key: 'tubeEnvironmentName' },
  // {
  //   display: 'Наименование рабочей среды в межтрубном пространстве',
  //   key: 'intertubeEnvironmentName'
  // },
  // { display: 'Рабочее давление в трубном пространстве', key: 'tubeWorkingPressure' },
  // { display: 'Рабочее давление в межтрубном пространстве', key: 'intertubeWorkingPressure' }

  // console.log(cardEquipment);

  return (
    <div>
      {/* {showModal && <ModalHoverMouse equipmentObject={equipmentObject} coordinates={coordinates} />} */}
      <Card className='text-center box_shadow' style={{ backgroundColor: '#fff' }}>
        <Card.Header>
          <Card.Title style={{ display: 'flex', justifyContent: 'center' }}>
            Реестр аппаратов
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {showTable && cardEquipment.length ? (
            <UniversalTable
              key={tableKey}
              removable={false}
              selectable={false}
              selectable1={false}
              selectable3={false}
              buttomName='Далее'
              tableName={''}
              titleObjectData={{
                numbered: 'п/п',
                consumerEnterpriseName: 'Предприятие потребитель',
                manufacturerName: 'Завод-изготовитель',
                equipmentName: 'Наименование оборудования',
                installationName: 'Наименование установки',
                registrationNumberInDatabase: 'Регистрационный № в базе',
                tubeEnvironmentName: 'Рабочая среда в трубном пространстве'
              }}
              renderedArray={cardEquipment} //массив, который будет рендерить таблица
              renderedArrayWithAdded={() => {}}
              chosenObjectsArray={() => {}} //только выбранные объекты
              chosenIdsArray={() => {}} //массив инн
              chosenObjectId={() => {}}
              handleModalToggle={() => {}}
              isRowGreen={false}
              viewID={false}
              onChosenIdOnlyOne={() => {}}
              editLot={() => {}}
              showStatus={true}
              showTradeButtons={false}
              handleClickPosition={handleClickPosition}
              offset={offset}
              // isCellClickable={true}
            />
          ) : (
            ''
          )}
        </Card.Body>
      </Card>
    </div>
  )
}

export default TableRegistry
