import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

export function ButtonGroupForFilterForms({ dataId, handleClickSave, clearFields, disableClearBtn, savedFiltersData }) {
    return (
        <Modal.Footer>
                <Button data-position={dataId} onClick={handleClickSave} style={{ marginRight: '5px' }}>
                    <ManageSearchOutlinedIcon style={{ marginRight: '5px' }}/>
                    Поиск
                    </Button>

                {(!savedFiltersData || savedFiltersData.length <=0)  && <Button variant="secondary" onClick={clearFields} disabled={disableClearBtn}>
                    <ClearOutlinedIcon style={{ marginRight: '5px' }}/>
                    Очистить поля
                    </Button>}

        </Modal.Footer>
    )
}
