import React from 'react'
import { Form, Table } from 'react-bootstrap'

const ModerationView = ({
  moderationArray,
  handleClickFull,
  handleClickOne,
  handleClickTwo,
  handleClickZero,
  changeKeyModeration,
  changeRegistration,
  sendNotice
}) => {
  const doEmpty = () => {}

  // const handleClickFull = (e) => {
  //     console.log(`CLICK FULL::: `, e.target.dataset.inn); // test
  // }

  // const handleClickOne = (e) => {
  //     console.log(`CLICK ONE::: `, e.target.dataset.inn); // test
  // }

  // const handleClickTwo = (e) => {
  //     console.log(`CLICK TWO::: `, e.target.dataset.inn); // test
  // }

  // const handleClickZero = (e) => {
  //     console.log(`CLICK ZERO::: `, e.target.dataset.inn); // test
  // }
//   console.log(moderationArray)

  const handleChange = async (e) => {
    const accessLevel = e.target.value;
    const { inn, login } = e.target.dataset;

    console.log(e); // test
    changeKeyModeration(inn, accessLevel);
    await changeRegistration(inn, login, accessLevel);
    await sendNotice(login, accessLevel);

  }

  return (
    <>
      {/* <div>
                {
                    moderationArray.map((data, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    width: '70%',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    margin: '5px auto',
                                }}
                            >
                                <div
                                    style={{
                                        width: '30%',
                                    }}
                                > {data.data.login}
                                </div>

                                <div
                                    style={{
                                        width: '20%',
                                    }}
                                > ИНН: &nbsp; <b>{data.data.inn}</b>
                                </div>

                                <div
                                    style={{
                                        width: '10%',
                                        textAlign: 'center',
                                        border: '1px solid #ccc',
                                        margin: ' 0 0 0 5px',
                                        backgroundColor: data.data.key === 'full' ? '#fd7e14' : '#73c586',
                                        cursor: data.data.key === 'full' ? 'default' : 'pointer',
                                    }}
                                    data-inn={data.data.inn}
                                    data-login={data.data.login}
                                    onClick={data.data.key === 'full' ? doEmpty : handleClickFull}
                                > {data.data.key === 'full' ? data.data.key : 'full'}
                                </div>
                                <div
                                    style={{
                                        width: '10%',
                                        textAlign: 'center',
                                        border: '1px solid #ccc',
                                        margin: ' 0 0 0 5px',
                                        backgroundColor: data.data.key === 'one' ? '#fd7e14' : '#73c586',
                                        cursor: data.data.key === 'one' ? 'default' : 'pointer',
                                    }}
                                    data-inn={data.data.inn}
                                    data-login={data.data.login}
                                    onClick={data.data.key === 'one' ? doEmpty : handleClickOne}
                                > {data.data.key === 'one' ? data.data.key : 'one'}
                                </div>
                                <div
                                    style={{
                                        width: '10%',
                                        textAlign: 'center',
                                        border: '1px solid #ccc',
                                        margin: ' 0 0 0 5px',
                                        backgroundColor: data.data.key === 'two' ? '#fd7e14' : '#73c586',
                                        cursor: data.data.key === 'two' ? 'default' : 'pointer',
                                    }}
                                    data-inn={data.data.inn}
                                    data-login={data.data.login}
                                    onClick={data.data.key === 'two' ? doEmpty : handleClickTwo}
                                > {data.data.key === 'two' ? data.data.key : 'two'}
                                </div>

                                <div
                                    style={{
                                        width: '10%',
                                        textAlign: 'center',
                                        border: '1px solid #ccc',
                                        margin: ' 0 0 0 5px',
                                        backgroundColor: data.data.key === 'zero' ? '#fd7e14' : '#73c586',
                                        cursor: data.data.key === 'zero' ? 'default' : 'pointer',
                                    }}
                                    data-inn={data.data.inn}
                                    data-login={data.data.login}
                                    onClick={data.data.key === 'zero' ? doEmpty : handleClickZero}
                                > {data.data.key === 'zero' ? data.data.key : 'zero'}
                                </div>

                            </div>
                        )
                    })
                }
            </div> */}

      <Table bordered style={{backgroundColor: '#fff'}}>
        <thead>
          <tr>
            <th style={{width: '50px'}}>ПП</th>
            <th>Логин</th>
            <th>ИНН</th>
            <th>Доступ</th>
          </tr>
        </thead>
        <tbody>
        {moderationArray.map((data, index) => (
          <tr key={index}>
            <td>{index+1}.</td>
            <td>{data.data.login}</td>
            <td>{data.data.inn}</td>
            <td>
              <Form.Control as='select'
                value={data.data.key}
                data-inn={data.data.inn}
                data-login={data.data.login}
                onChange={handleChange}
              >
                <option value="full"> Полный доступ</option>
                <option value="one">  Анкета, расчеты</option>
                <option value="two">Анкета, расчеты, торги</option>
                <option value="zero">Анкета</option>
              </Form.Control>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </>
  )
}

export default ModerationView
