import { useState } from 'react';
import server from '../server/server'

const Calculations = () => {

    const [show, setShow] = useState(false);
    const [newURL, setNewUrl] = useState('');

    (async () => {
        try {
            const result = await server.getDataFromServer(sessionStorage.getItem('login'), 'registration')

            console.log(`result >>>> `, result); // test

            setNewUrl(`http://sthe.lotus-uems.ru:8085/#token:${result.code}`);
            setShow(true)

            console.log(`URL = `, newURL); // test

        } catch (err) {
            console.log(`Ошибка получения code Пользователя`);
        }
    })()

    return (
        <>
            {show &&
                <div
                    style={{ margin: '50px' }}
                >
                    <iframe
                        src={newURL}
                        width="1440"
                        height="960"
                        aligh="centre"
                    >
                        Ваш браузер не поддерживает плавающие фреймы!
                    </iframe>
                </div>

            }

        </>
    )
}

export default Calculations;