import React from 'react';
import { Alert, Container, Form, Row } from 'react-bootstrap';
import CheckForm from '../../questionnaire/CheckForm';
import FormMaterList from '../../questionnaire/FormMaterList';
import {
    TRANSITION,
    TAPS,
    TROYNICK
} from '../../questionnaire/ConstMaterial';
// import AltRouteOutlinedIcon from '@mui/icons-material/AltRouteOutlined';
import { HandleAllCheckBoxesBtn } from '../../assets/HandleAllCheckboxesBtn';
import { CloseBtnForFilter } from '../../assets/CloseBtnForFilter';
import Server from '../../server/server';
import { ButtonGroupForFilterForms } from '../../assets/ButtonGroupForFilterForms';

export default class FormBends extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: true,
            nameForm: 'FormBends',
            maxId: 6,
            dataValue: [],
            dataOnServer: [],
            dataFromServer: [],
            lengthDataFromServer: 0,
            showTaps: false,
            showTransition: false,
            showTroynick: false,
            selectCheck: true,
            showVariants: true
        }
        this.doChangeValue = this.doChangeValue.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.doEmpty = this.doEmpty.bind(this);

        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);

        // this.renderDataConst = this.renderDataConst.bind(this);
        this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this);
        // this.checkOnList = this.checkOnList.bind(this);
        this.onRunBlock = this.onRunBlock.bind(this)
        this.handleClickMaterieals = this.handleClickMaterieals.bind(this);
        this.handleAllChecked = this.handleAllChecked.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
        this.sendProductDataOnServer = this.sendProductDataOnServer.bind(this)
    }

    async clearFields() {
        this.setState({
            showVariants: false
        })

        const res = await this.state.dataValue.map(el => {
            if (typeof el.value === 'object') {
                this.setState({ selectCheck: true })
            }
            if (!el) {
                this.setState({
                    showVariants: false
                })
                setTimeout(() => {
                    this.setState({
                        showVariants: true
                    })
                });
            } else {
                return {
                    ...this.state.dataValue,
                    value: ''
                }
            }
        })
        this.setState({
            dataValue: res
        })
        this.setState({
            showVariants: true
        })
    }

    handleAllChecked() {
        this.setState({
            selectCheck: !this.state.selectCheck,
            showTroynick: false
        })

        setTimeout(() => {
            this.setState({
                showTroynick: true
            })
        })
    }

    handleClickMaterieals() {
        this.props.onRunMaterials('Four_FormBends');
    }

    //* empty
    doEmpty() {
    }

    doChangeVisionBlock(data, id) {
        if (+id === 1) {
            this.setState({ showTaps: data })
        }
        if (+id === 3) {
            this.setState({ showTransition: data })
        }
        if (+id === 5) {
            this.setState({ showTroynick: data })
        }
    }

    onRunBlock(data) {
        data.forEach(el => {
            if (el) {
                this.doChangeVisionBlock(el.value, +el.id)
            }
        })
    }

    //запуск выхода из формы
    handleClickShadow() {
        this.setState({ showQuit: true })
    }

    //выход с сохранением
    doQuitWithSave() {
        this.setState({ showQuit: false })
        this.handleClickSave();
        this.props.onChangeView(this.state.nameForm);
    }
    //выход без сохранения
    doQuitWithoutSave() {
        this.setState({ showQuit: false })
        this.props.onChangeView(this.state.nameForm);
    }

    //*формируем данные в массив объектов для отправки на сервер
    async handleClickSave(e) {
        e.preventDefault()
        let data = this.state.dataOnServer;

        for (let i = 0; i <= (this.state.maxId); i++) {

            //todo создаем позицию с пустым объектом
            data[i] = { id: '', fid: '', description: '', information: '', value: '' }

            //todo заполняем объект данными с сервера
            this.state.dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
            this.state.dataValue.forEach((dataE) => {
                if (+dataE.id === i) {
                    data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                }
            })

        }
        await this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере

        this.props.getChosenFiltersData(data)
        //отправляем родителю имя этой формы
        // this.props.getFormName(this.state.nameForm)
        //берем данные с сервера
        this.props.saveChosenFilters()


        const result = await Server.filterProductionCompany(data, this.state.nameForm)
        await this.props.sendSearchResultData(result)
        this.props.openResultTable()
        this.props.closeModal(e)
    }

    //* данные с полей формы, формируются в массив объектов
    async doChangeValue(data) {
        await this.setState({ dataValue: [...this.state.dataValue, data] })
    }

    onKeyPressHandler(event) {
        event.target.dataset.position = 23
        if (event.keyCode === 13) {
            this.handleClickSave(event)
        }
    }

    async sendProductDataOnServer() {
        const productData = {
            product: this.props.product,
            formName: this.state.nameForm
        }
        await Server.sendDataOnServer(productData, 'product', localStorage.getItem('idUser'))
    }

    async componentDidMount() {
        await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer });
        this.onRunBlock(this.props.data)

        document.addEventListener('keydown', this.onKeyPressHandler)

        await this.sendProductDataOnServer()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPressHandler)
    }


    render() {

        const view = this.props.view;
        this.value = this.props.data;
        const viewQuest = this.props.viewQuest;

        return (
            <>

                <div className="modal_window" style={{ display: view ? 'block' : 'none' }} >
                    <div className={viewQuest ? "shadow_form" : "shadow_form_off"} onClick={this.props.closeModal}  ></div>
                    <Form className={this.props.chosenCompaniesFromProductionSearch && this.props.chosenCompaniesFromProductionSearch.length >= 0 ? "form_main" : "form_main_1"}  >
                        <Alert
                            variant="dark"
                            className="filter_alert"
                        >
                            <Alert.Heading >Поставка отводов, переходов, тройников </Alert.Heading>
                            <CloseBtnForFilter dataId={23} closeModal={this.props.closeModal} />
                        </Alert>
                        <div>
                            {this.state.showVariants && <Container className="form_container">


                            <Form.Group>
                                <Row>
                                    <CheckForm
                                        className={this.state.showTaps && 'fw_bolder'}
                                        id={1}
                                        width={6}
                                        show={true}
                                        placeholder="Отводы"
                                        description="Отводы и переходы"
                                        value={this.value[1] ? this.value[1].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        onChangeVisionBlock={this.doChangeVisionBlock}
                                        nameForm={this.state.nameForm}
                                    />
                                </Row>
                                <Row>
                                    {this.state.showTaps ?

                                        <FormMaterList
                                            id={2}
                                            width={4}
                                            rowView={false}
                                            materials={TAPS}
                                            value={this.value[2] ? this.value[2].value : ''}
                                            description="Отводы и переходы"
                                            onChangeValue={this.doChangeValue}
                                            nameForm={this.state.nameForm}
                                            selectCheck={true}
                                        />
                                        : ''}
                                </Row>
                            </Form.Group>



                            <Form.Group>
                                <Row>
                                    <CheckForm
                                        className={this.state.showTransition && 'fw_bolder'}
                                        id={3}
                                        width={6}
                                        show={true}
                                        placeholder="Переходы"
                                        description="Отводы и переходы"
                                        value={this.value[3] ? this.value[3].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        onChangeVisionBlock={this.doChangeVisionBlock}
                                        nameForm={this.state.nameForm}
                                    />
                                </Row>
                                <Row>
                                    {this.state.showTransition ?

                                        <FormMaterList
                                            id={4}
                                            width={4}
                                            rowView={false}
                                            materials={TRANSITION}
                                            value={this.value[4] ? this.value[4].value : ''}
                                            description="Отводы и переходы"
                                            onChangeValue={this.doChangeValue}
                                            nameForm={this.state.nameForm}
                                            selectCheck={true}
                                        />
                                        : ''}
                                </Row>
                            </Form.Group>


                            <Form.Group>
                                <Row>
                                    <CheckForm
                                        className={this.state.showTroynick && 'fw_bolder'}
                                        id={5}
                                        width={6}
                                        show={true}
                                        placeholder="Тройники"
                                        description="Отводы и переходы"
                                        value={this.value[5] ? this.value[5].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        onChangeVisionBlock={this.doChangeVisionBlock}
                                        nameForm={this.state.nameForm}
                                    />
                                </Row>
                                <Row> &nbsp;</Row>

                                {this.state.showTroynick &&
                                    <HandleAllCheckBoxesBtn
                                        handleAllChecked={this.handleAllChecked}
                                        selectCheck={this.state.selectCheck}
                                    />
                                }

                                <Row>
                                    {this.state.showTroynick &&
                                        <FormMaterList
                                            id={6}
                                            width={4}
                                            rowView={false}
                                            materials={TROYNICK}
                                            value={this.value[6] ? this.value[6].value : ''}
                                            description="Отводы и переходы"
                                            onChangeValue={this.doChangeValue}
                                            nameForm={this.state.nameForm}
                                            selectCheck={this.state.selectCheck}
                                        />
                                    }
                                </Row>
                            </Form.Group>

                            <Row> &nbsp;</Row>
                            <Row> &nbsp;</Row>
                        </Container>}
                          <ButtonGroupForFilterForms
                                dataId={23}
                                handleClickSave={this.handleClickSave}
                                clearFields={this.clearFields}
                                savedFiltersData={this.props.savedFiltersData}
                            />
                        </div>
                        
                        
                    </Form>
                </div>
            </>
        )
    }
}