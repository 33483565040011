import React from 'react';
import { Form, Col, Row, Container } from 'react-bootstrap';
import { nanoid } from 'nanoid'

export default class RadioButtonForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            id: '',
            fid: '',
            description: '',
            information: '',
            value: ''
        }
        this.handleChahge = this.handleChahge.bind(this);
    }

    async handleChahge(e) {
        // console.log(e);//test
        // console.log(e.target.checked + ' - ' + e.target.nextSibling.innerText);//test

        await this.setState({
            id: e.target.dataset.id,
            fid: `${this.props.nameForm}_${this.props.id}`,
            description: e.target.dataset.description,
            information: e.target.dataset.placeholder,
            value: e.target.nextSibling.innerText
        });

        this.props.onChangeValue(this.state);
        this.props.test && this.props.onChangeVisionBlock(e.target.nextSibling.innerText, e.target.dataset.id)
    }

    async componentDidMount() {
        if (this.props.value) {
            await this.setState({ value: this.props.value })
        }
    }

    render() {

        const id = this.props.id;
        const placeholder = this.props.placeholder;
        const description = this.props.description;
        const label1 = this.props.label1;
        const label2 = this.props.label2;
        const width = this.props.width;
        const show = this.props.show;
        const title = this.props.title;
        const paddingTop = this.props.paddingTop;
        const className = this.props.className

        return (
            <React.Fragment key={id}>
                <Col sm={width} style={{ display: show ? 'block' : 'none', paddingTop: !paddingTop ? '30px' : paddingTop }} className={className ? className : ''} >
                    <Form>
                        <b>{title} &nbsp; &nbsp;</b>
                        <Row> &nbsp; </Row>
                        <Container fluid style={{ paddingLeft: '30px' }}>
                            <Form.Check
                                id={`${nanoid()}_${label1}`}
                                type="radio"
                                data-id={+id}
                                data-fid={`${this.props.nameForm}_${id}`}
                                data-placeholder={placeholder}
                                data-description={description}
                                label={label1}
                                name={placeholder}
                                onChange={this.handleChahge}
                                checked={this.state.value === label1 ? true : false}
                                disabled={this.props.readOnly}
                            />
                            &nbsp; &nbsp;
                            <Form.Check
                                id={`${nanoid()}_${label2}`}
                                type="radio"
                                data-id={+id}
                                data-fid={`${this.props.nameForm}_${id}`}
                                data-placeholder={placeholder}
                                data-description={description}
                                label={label2}
                                name={placeholder}
                                onChange={this.handleChahge}
                                checked={this.state.value === label2 ? true : false}
                                disabled={this.props.readOnly}
                            />
                        </Container>

                    </Form>
                </Col>
            </ React.Fragment>
        )
    }
}