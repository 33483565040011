import React from "react";
import { customAlphabet } from "nanoid";
import { Form, Row, Col } from 'react-bootstrap';
import Server from "../server/server";
// import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
// import FileDownloadDoneOutlinedIcon from '@mui/icons-material/FileDownloadDoneOutlined';
// import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
// import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ListGroupCustomer from "../trade/ListGroupCustomer";
import ListGroupCustomerTwo from "../trade/ListGroupCustomerTwo";

export default class FileModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            choiceFile: false,
            fileData: [], // данные загруженного файла
            descriptionFile: '', // описание к файлу            
            newName: '', // название ЛОТа или торговой позиции
            index: '',
            oldNameFile: '',
            newNameFile: '',  // новое имя файла, для ДБ
            copyNameFile: '', // имя файла для выдачи клиенту
            pathFile: '',
            typeFile: '',
            size: '',
            archive: false, // видимость файла при рендере
            disableDownload: false // для доступной загрузки - false
        }
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleChangeDescription = this.handleChangeDescription.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.handleClickReadFile = this.handleClickReadFile.bind(this);
        this.handleClickDel = this.handleClickDel.bind(this);
    }

    //удаление позиции (файла ?)
    handleClickDel() {
        this.props.deletePosition(this.props.index)
    }

    //todo: читаем ранее загруженный файл
    async handleClickReadFile(fileName) {
        // console.log(`FILE NAME >>>>>>>>>>>> `, fileName);//test
        const result = await Server.readFileFromServer(fileName);

        // console.log(this.state.copyNameFile); // test

        if (result) {
            // console.log(`RESULT !!!!!!!!!!!!!!!!!! >>>>>>>>>>> `, result); // test

            const linkBlob = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = linkBlob
            // link.target = "_blank"
            // link.download = fileName
            link.download = this.state.copyNameFile//! меняем имя файла при загрузке
            // link.download = fileName.split('/').
            document.body.appendChild(link)
            link.click()
            link.remove()
        }

    }

    handleClickSave(e) {
        // console.log(e);
        (async () => {
            try {
                const result = await Server.createTradeFileOnServer(this.state.newDataForm); // сохраняем файл на сервере

                if (result.ERROR) throw new Error(result.message)

                this.setState({ pathFile: result.path })

                const objectFile = {
                    oldNameFile: this.state.oldNameFile,
                    newNameFile: this.state.newNameFile,
                    copyNameFile: this.state.copyNameFile,
                    pathFile: this.state.pathFile,
                    typeFile: this.state.typeFile,
                    size: this.state.size,
                    descriptionFile: this.state.descriptionFile,
                    archive: this.state.archive, // отображение видимости при рендере true - не видим
                }

                // console.log(`SAVE OBJECT: `, objectFile); // test
                // console.log(`SAVE for INDEX:::: `, this.props.index); //test
                this.props.onSaveFile(objectFile, this.props.index);
                this.setState({ show: true, disableDownload: false })

                this.props.getDisableDownload(false)

                // console.log('ggggggggg');
            } catch (err) {
                console.log(`Ошибка сохранения файла: `, err);
            }
        })()

    }

    handleChangeDescription(event) {
        this.setState({
            descriptionFile: event.target.value,
            show: false,
            choiceFile: true
        })
    }

    onChangeFile(e) {
        (async () => {
            try {
                this.file = e.target.files[0]; // из массива один файл, объект
                // console.log(`FILE:::: `, this.file); // test
                this.setState({
                    fileData: this.file,
                    oldNameFile: this.file.name,
                    typeFile: this.file.type,
                    size: this.file.size,
                    show: false,
                    choiceFile: true,
                });

                // TODO: новое имя файла, уникальное
                const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 8);
                const prefix = nanoid();
                const newNameFile = `${this.state.newName}_${prefix}_${this.file.name}`;
                const copyNameFile = `${this.state.newName}_${this.file.name}`;
                this.setState({
                    newNameFile: newNameFile,
                    copyNameFile: copyNameFile,
                })

                setTimeout(() => {
                    // console.log(`PROPS new name FILE >>>>>>> `, this.props.newName); // test
                    // console.log(`NEW NAME :::::::`, this.state.newName);
                    // console.log(`new name FILE:::::: `, newNameFile);
                    // console.log(`NEW NAME FILE in STATE>>>>>> `, this.state.newNameFile);
                })

                // TODO: запрос к серверу, получить обратно путь сохраненного файла, дописать в объект
                // наполняем запрос к серверу
                let newDataForm = new FormData();
                newDataForm.append("key", "weifeiph7Pie");//todo kye - constanta
                newDataForm.append("filename", newNameFile);//todo создать уникальный
                newDataForm.append("session", sessionStorage.getItem('sessionId'));
                newDataForm.append("login", sessionStorage.getItem('login'));
                newDataForm.append("userid", localStorage.getItem('idUser'));
                newDataForm.append("file", this.file, this.file.name);//в тело объекта новой формы добавили INPUT

                this.setState({ newDataForm: newDataForm })

                // const result = await Server.createTradeFileOnServer(newDataForm); // сохраняем файл на сервере

                // if (result.ERROR) throw new Error(result.message)

                // console.log(`RESULT SAVE FILE ON SERVER:::::::: `, result); // test
                // this.setState({ pathFile: result.path })

            } catch (err) {
                console.log(`Ошибка при обработке файла (FileModal.jsx): `, err);
            }
        })();
    }

    componentDidMount() {
        this.setState({
            newName: this.props.newName,
            index: this.props.index,
            oldNameFile: this.props.oldNameFile,
            newNameFile: this.props.newNameFile,
            copyNameFile: this.props.copyNameFile,
            pathFile: this.props.pathFile,
            typeFile: this.props.typeFile,
            size: this.props.size,
            descriptionFile: this.props.descriptionFile,
            archive: this.props.archive,
        })

    }

    render() {
        const index = this.props.index;
        // const show = this.state.show;
        // const choiceFile = this.state.choiceFile;
        return (
            <>

                <React.Fragment key={`file_${index}`}>
                    <div 
                    style={{ padding: this.props.table ? '0px 20px 0px 20px' : '20px 20px 10px 20px', border: this.props.table ? '' : '#eee solid 1px', borderRadius: '5px' }}
                    >
                        {(!this.state.oldNameFile || this.state.oldNameFile.length === 0) &&
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Text className="text-muted">
                                            Описание файла
                                        </Form.Text>
                                        <Form.Control
                                            type="text"
                                            placeholder="Описание файла"
                                            onChange={this.handleChangeDescription}
                                            value={this.state.descriptionFile && this.state.descriptionFile}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col style={{ marginTop: '20px' }}>
                                    <label
                                        className='upoad_file'
                                        htmlFor={index}
                                    >
                                        Выберите файл
                                    </label>
                                    <input
                                        style={{
                                            opacity: '0',
                                            position: 'absolute',
                                            zIndex: '-1'
                                        }}
                                        id={index}
                                        onChange={this.onChangeFile}
                                        data-id={index}
                                        data-fid={`fid_${index}`}
                                        type="file"
                                        name="file"
                                        action="/server_data/file_trade"
                                        method="post"
                                        encType="multipart/form-data"
                                    />
                                </Col>
                            </Row>
                        }

                        {this.state.oldNameFile && !this.props.participant &&
                            <ListGroupCustomer
                                table={this.props.table}
                                index={index}
                                oldNameFile={this.state.oldNameFile}
                                handleChangeDescription={this.handleChangeDescription}
                                descriptionFile={this.state.descriptionFile}
                                pathFile={this.state.pathFile}
                                handleClickSave={this.handleClickSave}
                                handleClickReadFile={this.handleClickReadFile}
                                handleClickDel={this.handleClickDel}
                                showDelIcon={this.props.showDelIcon}
                                showCheckIcon={this.props.showCheckIcon}
                                asCustomer={this.props.asCustomer}
                                showAddBtn={this.props.showAddBtn}
                            />
                        }

                        {this.state.oldNameFile && this.props.participant &&

                            <ListGroupCustomerTwo
                                table={this.props.table}
                                index={index}
                                oldNameFile={this.state.oldNameFile}
                                handleChangeDescription={this.handleChangeDescription}
                                descriptionFile={this.state.descriptionFile}
                                pathFile={this.state.pathFile}
                                handleClickSave={this.handleClickSave}
                                handleClickReadFile={this.handleClickReadFile}
                                handleClickDel={this.handleClickDel}
                            />
                        }

                    </div>
                </React.Fragment>
            </>

        )
    }
}