import React from 'react'
import { Form, Col, Container, Row } from 'react-bootstrap'

export default class RadioButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      id: '',
      fid: '',
      description: '',
      information: '',
      value: ''
    }
    this.handleChahge = this.handleChahge.bind(this)
  }

  async handleChahge(e) {
    // console.log(e);//test
    // console.log(e.target.checked + ' - ' + e.target.nextSibling.innerText);//test

    await this.setState({
      id: e.target.dataset.id,
      fid: `${this.props.nameForm}_${this.props.id}`,
      description: e.target.dataset.description,
      information: e.target.dataset.placeholder,
      value: e.target.nextSibling.innerText
    })
    // console.log(this.state); //test
    this.props.onChangeValue(this.state)
    this.props.onChangeVisionBlock(e.target.nextSibling.innerText, e.target.dataset.id)
  }

  async componentDidMount() {
    await this.setState({ value: this.props.value })

    if (this.props.value === 'Прочее') {
      this.props.onChangeVisionBlock('Прочее')
    }
  }

  render() {
    // const id = this.props.id
    // // this.value = this.props.value;
    // const placeholder = this.props.placeholder
    // const description = this.props.description
    // const label1 = this.props.label1
    // const label2 = this.props.label2
    // const label3 = this.props.label3
    // const label4 = this.props.label4
    // const label5 = this.props.label5
    // const width = this.props.width
    // const show = this.props.show
    // const title = this.props.title

    const {id, placeholder, description, label1, label2, label3, label4, label5, title} = this.props

    return (
      <React.Fragment key={id}>
        {/* <Row sm={width} style={{ display: show ? 'block' : 'none', paddingTop: '0px', marginBottom: '15px' }}> */}
        <Form>
          <div> &nbsp; </div>
          <Container fluid style={{ display: 'flex', gap: '20px' }}>
            {title} &nbsp; &nbsp;
            <Form.Check
              id={`${this.props.id}_${label1}`}
              type='radio'
              data-id={+id}
              data-fid={`${this.props.nameForm}_${id}`}
              data-placeholder={placeholder}
              data-description={description}
              data-position={description}
              label={label1}
              name={placeholder}
              onChange={this.handleChahge}
              checked={this.state.value === label1 ? true : false}
            />
            &nbsp;
            <Form.Check
              id={`${this.props.id}_${label2}`}
              type='radio'
              data-id={+id}
              data-fid={`${this.props.nameForm}_${id}`}
              data-placeholder={placeholder}
              data-description={description}
              data-position={description}
              label={label2}
              name={placeholder}
              onChange={this.handleChahge}
              checked={this.state.value === label2 ? true : false}
            />
            &nbsp;
            <div> &nbsp; </div>
            {label3 && (
              <Form.Check
                id={`${this.props.id}_${label3}`}
                type='radio'
                data-id={+id}
                data-fid={`${this.props.nameForm}_${id}`}
                data-placeholder={placeholder}
                data-description={description}
                data-position={description}
                label={label3}
                name={placeholder}
                onChange={this.handleChahge}
                checked={this.state.value === label3 ? true : false}
              />
            )}
            &nbsp;
            {label4 && (
              <Form.Check
                id={`${this.props.id}_${label4}`}
                type='radio'
                data-id={+id}
                data-fid={`${this.props.nameForm}_${id}`}
                data-placeholder={placeholder}
                data-description={description}
                data-position={description}
                label={label4}
                name={placeholder}
                onChange={this.handleChahge}
                checked={this.state.value === label4 ? true : false}
              />
            )}
            &nbsp;
            {label5 && (
              <Form.Check
                id={`${this.props.id}_${label5}`}
                type='radio'
                data-id={+id}
                data-fid={`${this.props.nameForm}_${id}`}
                data-placeholder={placeholder}
                data-description={description}
                data-position={description}
                label={label5}
                name={placeholder}
                onChange={this.handleChahge}
                checked={this.state.value === label5 ? true : false}
              />
            )}
          </Container>
        </Form>
        {/* </Row> */}
      </React.Fragment>
    )
  }
}
