import React, { useEffect, useState } from 'react'
import server from '../server/server'
import UniversalTable from '../assets/UniversalTable'
import { Card, Container } from 'react-bootstrap'
import OrderModal from './OrderModal'
import LoaderPage from '../assets/loaderPage'
import { QuestionnairyModal } from '../QuestionnairyModal/QuestionnairyModal'
import LeftAndRightArrowsButtons from '../assets/LeftAndRightArrowsButtons'
import { usePagination } from '../assets/usePagination'

export default function PurchaseOrderList({ infoBlock, onUpInfoBlock, viewQuest }) {
  const [allOrders, setAllOrders] = useState([])
  const [showTable, setShowTable] = useState(true)
  const [chosenOrder, setChosenOrder] = useState({})
  const [showOrder, setShowOrder] = useState(false)
  const [showQuestionnairy, setShowQuestionnairy] = useState(false)
  const [companyName, setCompanyName] = useState('')
  const [companyInn, setCompanyInn] = useState('')

  const { currentPage, pageSize, getPagedData, goToNextPage, goToPreviousPage } = usePagination(
    10,
    allOrders
  )
  const offset = currentPage * pageSize

  // const [currentPage, setCurrentPage] = useState(0)
  // const [pageSize, setPageSize] = useState(10)

  // const getPagedData = () => {
  //   const reversedData = allOrders.slice().reverse()
  //   const start = currentPage * pageSize
  //   const end = start + pageSize
  //   return reversedData.slice(start, end).reverse()
  // }

  // const goToNextPage = () => {
  //   setCurrentPage((prevPage) => {
  //     const maxPages = Math.ceil(allOrders.length / pageSize)
  //     if (prevPage < maxPages) {
  //       return prevPage + 1
  //     }
  //     return null
  //   })

  //   setShowTable(false)
  //   setTimeout(() => setShowTable(true))
  // }

  // const goToPreviousPage = () => {
  //   setCurrentPage((prevPage) => {
  //     if (prevPage > 0) {
  //       return currentPage - 1
  //     }
  //     return null
  //   })
  //   setShowTable(false)
  //   setTimeout(() => setShowTable(true))
  // }

  useEffect(() => {
    const getAllPurchaseOrders = async () => {
      try {
        const result = await server.getDataAllCollection('order')
        const transformedArray = result
          .map(({ _id, data }) => ({ _id, ...data }))
          .map(({ _id: id, ...rest }) => ({ id, ...rest }))

        setAllOrders(transformedArray)
      } catch (err) {
        console.log('Ошибка загрузки всех заказ-нарядов', err)
      }
    }

    getAllPurchaseOrders()
  }, [])

  const setOrderId = async (id) => {
    // console.log(`ID::: ${id} >>>`)
    const result = await server.getDataFromServer(id, 'order')
    setChosenOrder(result)
    setShowOrder(true)
  }

  const openQuestionnairy = async (id, orderId, position) => {
    try {
      const result = allOrders.filter((order) => order.id === orderId)

      let companyNameValue = ''

      if (position === 'customer') {
        companyNameValue = result.length === 1 ? result[0].customer : ''
      }

      if (position === 'manufacturer') {
        companyNameValue = result.length === 1 ? result[0].manufacturer : ''
      }

      setCompanyName(companyNameValue)
      setCompanyInn(id)

      localStorage.setItem('idAlien', id)
    } catch (err) {
      console.log('Ошибка при открытии анкеты', err)
    }

    setShowQuestionnairy(true)
  }

  return (
    <div style={{ marginTop: '100px' }}>
      <Container fluid>
        <Card className='text-center box_shadow' style={{ backgroundColor: '#fff' }}>
          <Card.Header>
            <Card.Title style={{ display: 'flex', justifyContent: 'center' }}>
              Реестр заказ-нарядов
            </Card.Title>
          </Card.Header>
          <Card.Body>
            {showTable && allOrders.length ? (
              <UniversalTable
                offset={offset}
                key={currentPage}
                isRowGreen={false}
                removable={false}
                selectable={false}
                selectable1={false}
                selectable2={true} //для открытия анкет
                selectableCheck={false} // Добавляет check box
                openQuestionnairy={openQuestionnairy} //для открытия анкет
                tableName={'Заказ-наряды'}
                titleObjectData={{
                  numbered: 'п/п',
                  customer: 'Заказчик',
                  manufacturer: 'Изготовитель',
                  tradePositionName: 'Название позиции',
                  equipmentName: 'Наименование аппарата',
                  generalDate: 'Страна',
                  generalDate: 'Дата (основания)'
                }}
                // renderedArray={data}
                // renderedArray={allOrders}
                renderedArray={getPagedData()}
                renderedArrayWithAdded={() => {}}
                chosenObjectsArray={() => {}}
                chosenIdsArray={() => {}}
                chosenObjectId={() => {}}
                handleCancel={() => {}}
                handleModalToggle={() => {}}
                updateObjectWithAdded={() => {}}
                handleClickPosition={setOrderId}
              />
            ) : (
              <LoaderPage />
            )}
          </Card.Body>
        </Card>

        {showOrder && (
          <OrderModal
            toggleModal={() => setShowOrder(false)}
            alertHeading='Заказ-наряд'
            order={chosenOrder}
          />
        )}

        {showQuestionnairy && (
          <QuestionnairyModal
            closeCompanyDetailsModal={() => setShowQuestionnairy(false)} // команда на закрытие
            companyName={companyName} // название компании, для заглавия модалки
            infoBlock={infoBlock}
            onUpInfoBlock={onUpInfoBlock}
            viewQuest={viewQuest}
            innFromUniversalTable={companyInn} // передаем полученный ИНН выбранной компании
            getEconomicData={() => {}}
          />
        )}
        { allOrders.length > pageSize && 
          <div style={{ marginTop: '20px' }}>
            <LeftAndRightArrowsButtons
              nextPageClick={goToNextPage}
              prevPageClick={goToPreviousPage}
              needDisableButtons={true}
              disableNextButtonCondition={currentPage >= Math.ceil(allOrders.length / pageSize) - 1}
              disablePrevButtonCondition={currentPage === 0}
            />
          </div>
        }
      </Container>
    </div>
  )
}
