import React from 'react'
import Server from '../server/server'
import ModerationView from './ModerationView';

export default class Admin extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            registrationArray: [],
            moderationArray: [],

        }

        this.getAllUsers = this.getAllUsers.bind(this);
        this.getAllModeration = this.getAllModeration.bind(this);

        this.doHandleCkickFull = this.doHandleCkickFull.bind(this);
        this.doHandleClickOne = this.doHandleClickOne.bind(this);
        this.doHandleClickTwo = this.doHandleClickTwo.bind(this);
        this.doHandleClickZero = this.doHandleClickZero.bind(this);

        this.changeKeyModeration = this.changeKeyModeration.bind(this);
        this.changeRegistration = this.changeRegistration.bind(this);
        this.sendNotice = this.sendNotice.bind(this);
    }

    doHandleCkickFull(e) {
        (async () => {
            console.log(e.target.dataset.inn); // test
            this.changeKeyModeration(e.target.dataset.inn, 'full')
            await this.changeRegistration(e.target.dataset.inn, e.target.dataset.login, 'full')
            await this.sendNotice(e.target.dataset.login, 'full')
        })()

    }

    doHandleClickOne(e) {
        (async () => {
            console.log(e.target.dataset.inn); // test
            this.changeKeyModeration(e.target.dataset.inn, 'one')
            await this.changeRegistration(e.target.dataset.inn, e.target.dataset.login, 'one')
            await this.sendNotice(e.target.dataset.login, 'one')
        })()

    }

    doHandleClickTwo(e) {
        (async () => {
            console.log(e.target.dataset.inn); // test
            this.changeKeyModeration(e.target.dataset.inn, 'two')
            await this.changeRegistration(e.target.dataset.inn, e.target.dataset.login, 'two')
            await this.sendNotice(e.target.dataset.login, 'two')
        })()

    }

    doHandleClickZero(e) {
        (async () => {
            console.log(e.target.dataset.inn); // test
            this.changeKeyModeration(e.target.dataset.inn, 'zero')
            await this.changeRegistration(e.target.dataset.inn, e.target.dataset.login, 'zero')
            await this.sendNotice(e.target.dataset.login, 'zero')
        })()
    }

    /**
     * Отправка письма о смене доступа
     * @param {String} login 
     * @param {String} keyName 
     * @returns {Object} result
     */
    async sendNotice(login, keyName) {
        try {
            const objSend = {
                text: keyName,
                emailInvite: login,
                subjectInvite: 'Изменения доступа модератором'
            }
            const result = await Server.sendEmailKey(objSend);

            console.log(`Результат отправки письма: `, result); // test

        } catch (err) {
            console.log(`Ошибка отправки уведомления о смене доступа: `, err);
        }
    }

    /**
     * Меняем ключ в объекте модерации
     * @param {String} inn 
     * @param {String} key 
     */
    async changeKeyModeration(inn, key) {
        try {
            (async () => {
                const arrModer = this.state.moderationArray.map(elem => {

                    if (elem.data.inn === inn) {
                        elem.data.key = key;
                        return elem
                    }
                    return elem
                })
                this.setState({ moderationArray: arrModer });

                const objModer = this.state.moderationArray.find(item => item._id === inn)

                const result = await Server.updateField('moderation', objModer._id, 'data.key', objModer.data.key)
                await Server.updateField('moderation', objModer._id, 'data.sentOnModertaion', false)
                // console.log(`result:::: `, result); // test

                //*: Сделать поинт для обновления доступа у сотрудников данной компании
                const idCompany = objModer._id; // ИНН компании
                const keyAccess = objModer.data.key // новый ключ доступа
                const resultChange = await Server.changeKeyAccess(idCompany, keyAccess) // todo: дописать поинт

                console.log(`RESULT CHANGE:::: `, resultChange); // test


            })()
        } catch (err) {
            console.log(`Ошибка обновления объекта модерации (Admin.jsx): `, err);
        }
    }

    /**
     * Меняем ключ в регистрационных данных
     * @param {String} inn 
     * @param {String} login 
     * @param {String} key 
     */
    async changeRegistration(inn, login, key) {
        try {
            (async () => {
                let userData = this.state.registrationArray.find(item => item._id === login)
                console.log(`user DATA::: `, userData); // test
                userData.key[inn] = key;

                const result = await Server.updateDataOnServerRegistration(userData)

                console.log(`RESULT::: `, result); // test

            })()
        } catch (err) {
            console.log(`Ошибка обновления регистрационных данных (Admin.jsx): `, err);

        }
    }

    /**
     * Получаем все документы из коллекции - moderation
     * @param {String} collection 
     *  @returns {Array} Array Object Массив объектов модерации
     */
    async getAllModeration(collection) {
        const arrModeration = await Server.getDataAllCollection(collection)

        this.setState({ moderationArray: arrModeration })
        // console.log(`ARR arrModeration:::: `, arrModeration); // test
    }

    /**
     * Получаем все документы из коллекции - registration
     * @param {String} collection 
     * @returns {Array} Array Object Массив объектов регистрации
     */
    async getAllUsers(collection) {
        const arr = await Server.getDataAllCollection(collection)

        this.setState({ registrationArray: arr })
        // console.log(`ARR:::: `, arr); // test
    }

    async componentDidMount() {
        await this.getAllUsers('registration');
        await this.getAllModeration('moderation');
    }

    render() {
        return (
            <>
                <div
                    style={{
                        width: '100%',
                        // height: '80%',
                        // position: 'absolute',
                        // top: '10%',
                        // left: '15%',
                        // backgroundColor: '#fff',
                        // border: '1px solid #000',
                        padding: '10px',
                        zIndex: '100000',
                        overflow: 'auto',
                        marginTop: '60px'
                    }}
                >
                    {/* <div
                        style={{
                            position: 'absolute',
                            top: '10px',
                            right: '10px',
                            cursor: 'pointer',
                        }}
                        onClick={() => this.props.clickClose()}
                    >
                        &#10006;
                    </div>

                    <div>
                        <b>zero</b> - доступ только к анкете
                    </div>
                    <div>
                        <b>one</b> -  доступ к анкете, расчетам
                    </div>
                    <div>
                        <b>two</b> - доступ к анкете, расчетам, торгам
                    </div>
                    <div>
                        <b>full</b> - полный доступ
                    </div> */}


                    <div
                        style={{ padding: '0px 50px ' }}
                    >
                        <ModerationView
                            moderationArray={this.state.moderationArray}
                            handleClickFull={this.doHandleCkickFull}
                            handleClickOne={this.doHandleClickOne}
                            handleClickTwo={this.doHandleClickTwo}
                            handleClickZero={this.doHandleClickZero}
                            changeKeyModeration={this.changeKeyModeration}
                            changeRegistration={this.changeRegistration}
                            sendNotice={this.sendNotice}
                        />
                    </div>
                </div>

            </>
        )
    }

}