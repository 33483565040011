import React from 'react';
import { Form } from 'react-bootstrap';
import QuestionToChatBtn from './QuestionToChatBtn';

const CheckTable = ({ type, id, plsh, description, handleChange, handleClickChat, position, checked, value, organizerName, number, lotNum, name, secondDescription, disableParticipantData }) => {


    const numberOfLot = lotNum ? `лот № ${lotNum};` : ''
    const nameOfPosition = name ? `название позиции - "${name}";` : ''

    // console.log('numberOfLot - ', numberOfLot, 
    // 'nameOfPosition - ', nameOfPosition, 
    // 'number - ', number);

    return (
        <React.Fragment >
            <td>
                <Form.Control
                    type={type}
                    placeholder={plsh}
                    data-id={id}
                    data-position={position}
                    data-description={description}
                    data-quantity={secondDescription}
                    onChange={handleChange}
                    checked={checked}
                    value={value}
                    disabled={disableParticipantData}
                />
            </td>
            <td
                style={{ display: 'flex-block', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }}
            >
                <QuestionToChatBtn
                    handleClick={handleClickChat}
                    details={{
                        param: `${numberOfLot} ${nameOfPosition} пункт № ${number}; указанные значения - "${description} - ${secondDescription}"`, recipient: organizerName
                    }}
                />

            </td>

        </React.Fragment>
    )
}

export default CheckTable