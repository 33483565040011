import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import EmailSend from '../EmailSend';
// import { InviteByEmailButton } from '../assets/BasicButtons';

export function InviteByEmail({
  doSendIdInvite,
  idLot,
  btnText,
  icon,
  fromSearch
}) {
  const [showModal, setShowModal] = useState(false)

  const onModalToggle = () => {
    setShowModal(!showModal)
  }

  return (
    <div className='card_block_no_position'>
      {/* <InviteByEmailButton 
      handleSaveClick={onModalToggle} needDisable={false} 
      /> */}
 
      <Button title='Пригласить компании по email' onClick={onModalToggle} >
        {icon && <ForwardToInboxOutlinedIcon style={{ marginRight: '5px' }}/> }
        {btnText && btnText}
      </Button>

      {showModal &&

        <EmailSend
          lotID={idLot}
          onSendIdInvite={doSendIdInvite}
          formInvite='invite_object' //(all - формирование объекта с ссылкой и отправка, invite_object - формирование объекта с сылкой только)
          closeModal={onModalToggle}
          fromSearch={fromSearch}
        />
      }

    </div>
  )
}
