import Server from '../server/server';

/**
 * Добавляем номе при перетожке
 * @param {String} data (string) номер лота
 * @returns {String} (string) новый номер лота
 */
const addNumberPeretojka = async(data) => {
    try {
        // todo: загрузить все ЛОТы данной компании
        // let dataNumberLot = this.state.data.numberLot; // старт с STATE или с Подгруженного числа из DB
        let dataNumberLot = data
        let arrayQuantityLots = 0

        const result = await Server.searchAllTrade() // все торги данного пользователя

        result.forEach(lots => {
            const lot = lots.data.data;
            const numberLotArray = lot.numberLot.split('-');
            const dataArrNumber = dataNumberLot.split('-')

            if (numberLotArray.length > 1 && numberLotArray[0] === dataArrNumber[0]) {
                arrayQuantityLots++
            }
        })

        if (arrayQuantityLots > 0) {
            const arrayNum = dataNumberLot.split('-');
            dataNumberLot = `${arrayNum}-${arrayQuantityLots}`
        }

        // TODO: начинается добавление числа перетожки
        const arrNumberLot = dataNumberLot.split('_');
        arrNumberLot[0] = `${arrNumberLot[0]}_`;
        arrNumberLot[1] = `${arrNumberLot[1]}_`

        if (isNaN(Number(arrNumberLot[arrNumberLot.length - 1]))) {
            const arrlastNumber = (arrNumberLot[arrNumberLot.length - 1]).split('-');
            let lastNumber = Number(arrlastNumber[arrlastNumber.length - 1])
            arrlastNumber.pop();
            lastNumber++

            arrlastNumber.push(lastNumber)
            const joinArrLastNumber = arrlastNumber.join('-')

            arrNumberLot.pop()
            arrNumberLot.push(joinArrLastNumber)

        } else {
            arrNumberLot.push('-1');
        }
        const newNumberLot = arrNumberLot.join('');

        return newNumberLot

    } catch (err) {
        console.log(`Ошибка получения номера ЛОТа: `, err);
    }
}

export default addNumberPeretojka;