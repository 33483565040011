import Server from '../server/server'


/**
 * Загрузка массива карт аппаратаов из реестра
 * @param {Object} dataRegistry реестр аппаратов
 * @returns 
 */
const getCardEquipment = async (dataRegistry) => {
    try {
        const promiseArr = await dataRegistry.map(item => {
            return (async () => {
                return await Server.getDataFromServer(item.idCard, localStorage.getItem('idUser'))
            })()
        })

        return await Promise.all(promiseArr)

    } catch (err) {
        console.log(`Ошибка при получении карточек аппаратов: `, err);
        return []
    }
}

export default getCardEquipment;