import Server from "../server/server";

export const stopTrade = async(id) => {
    try {
        console.log(`function <stopTrade.js> is run >>> `, id);

        const result = await Server.stopTrade(id)

        if (result.ERROR) throw new Error(result.massage)

        return result
    } catch (err) {
        console.log(`Ошибка при остановке торгов: `, err);
    }

}