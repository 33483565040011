import React from 'react';
import { Row } from 'react-bootstrap';
import InputForm from './InputForm';

export default class Form_Five_Termo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }

        this.doChangeValue = this.doChangeValue.bind(this);
    }

    doChangeValue(e) {
        this.props.onChangeValue(e);
    }

    render() {

        const show = this.props.show;
        this.value = this.props.value;
        const id = this.props.id;

        return (
            <>
                <Row>
                    <InputForm
                        id={+id}
                        width={2}
                        show={show}
                        verify="number"
                        label="Длина, мм"
                        fontSize={'12px'}
                        placeholder="Длина, мм"
                        description={this.props.description}
                        value={this.value[+id] ? this.value[+id].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.props.nameForm}
                        readOnly={this.props.readOnly}
                    />
                    <InputForm
                        id={+id + 1}
                        width={2}
                        show={show}
                        verify="number"
                        label="Ширина, мм"
                        fontSize={'12px'}
                        placeholder="Ширина, мм"
                        description={this.props.description}
                        value={this.value[+id + 1] ? this.value[+id + 1].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.props.nameForm}
                        readOnly={this.props.readOnly}
                    />
                    <InputForm
                        id={+id + 2}
                        width={2}
                        show={show}
                        verify="number"
                        label="Высота, мм"
                        fontSize={'12px'}
                        placeholder="Высота, мм"
                        description={this.props.description}
                        value={this.value[+id + 2] ? this.value[+id + 2].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.props.nameForm}
                        readOnly={this.props.readOnly}
                    />

                    <InputForm
                        id={+id + 3}
                        width={2}
                        show={show}
                        verify="number"
                        label="Температура, t&deg;С"
                        fontSize={'12px'}
                        placeholder="Температура, t&deg;С"
                        description={this.props.description}
                        value={this.value[+id + 3] ? this.value[+id + 3].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.props.nameForm}
                        readOnly={this.props.readOnly}
                    />
                    <InputForm
                        id={+id + 4}
                        width={2}
                        show={show}
                        verify="number"
                        label="Вес изделия, тн"
                        fontSize={'12px'}
                        placeholder="Вес изделия, тн"
                        description={this.props.description}
                        value={this.value[+id + 4] ? this.value[+id + 4].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.props.nameForm}
                        readOnly={this.props.readOnly}
                    />
                </Row>
            </>
        )
    }
}