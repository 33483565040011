import React from 'react'
import { Form, ListGroup } from 'react-bootstrap'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

//! ДЛЯ ОТОБРАЖЕНИЯ ФАЙЛОВ УЧАСТНИКА СО СТОРОНЫ ЗАКАЗЧИКА
export default function ListGroupCustomerTwo({ table, index, oldNameFile, descriptionFile, handleClickSave,
    handleClickReadFile, handleClickDel, pathFile, handleChangeDescription, numbered }) {

    const shortenWords = (word, letterCount) => {
        if (word && word.length > 0) {
            if (word.length > letterCount) {
                return `${word.substring(0, letterCount)}...`
            } else {
                return word
            }
        }
    }


    const border = '1px solid rgba(0,0,0,.125)'
    return (

        <>

            <ListGroup  >
                <div style={{ display: 'flex' }}>
                    {/* {numbered && <ListGroup.Item style={{ width: '8%', border }} >
                    {table && <span sm={1} style={{ marginRight: '-50px', marginTop: '25px' }} > {index + 1} </span>}
                </ListGroup.Item>} */}

                    <ListGroup.Item style={{ width: '45%', border, height: '60px' }} title={oldNameFile}>
                        {shortenWords(oldNameFile, 20)}
                    </ListGroup.Item>

                    <ListGroup.Item style={{ width: '45%', border, height: '60px' }} title={descriptionFile} >
                        <Form.Control
                            style={{ border: pathFile && pathFile.length !== 0 && '0', cursor: 'text' }}
                            type="text"
                            placeholder="Описание файла"
                            onChange={handleChangeDescription}
                            value={descriptionFile && descriptionFile}
                        />
                    </ListGroup.Item>

                    <ListGroup.Item
                        style={{ height: '60px', border }}
                        title={!pathFile ? `Сохраните файл, чтобы прикрепить его к текущему документу` : ' '} // repair
                        className={'list_item_stile'}
                        as="li"
                        action
                        onClick={handleClickSave}
                    >
                        {pathFile && pathFile.length !== 0 ? <CheckOutlinedIcon color={'success'} /> : <SaveOutlinedIcon color={'success'} />}
                    </ListGroup.Item>

                    {pathFile && pathFile.length !== 0 && <><ListGroup.Item
                        className={'list_item_stile'}
                        style={{ height: '60px', border }}
                        as="li"
                        action
                        onClick={() => handleClickReadFile(pathFile)}
                    >
                        <DownloadOutlinedIcon color={'primary'} />
                    </ListGroup.Item>

                        <ListGroup.Item
                            className={'list_item_stile'}
                            style={{ height: '60px', border }}
                            color="action" as="li"
                            action
                            onClick={handleClickDel}
                        >
                            <DeleteForeverOutlinedIcon style={{ color: '#dc3545' }} />
                        </ListGroup.Item>
                    </>}
                </div>
            </ListGroup>

            {/* <Table className='test'>
                <thead>
                    <tr>
                        <td>Название файла</td>
                        <td>Описание файла</td>
                        <td>2t</td>
                        <td>3t</td>
                        <td>4y</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td style={{ width: '45%', border }} title={oldNameFile}>
                            {shortenWords(oldNameFile, 20)}
                        </td>
                        <td style={{ width: '45%', border }} title={descriptionFile}>
                            <Form.Control
                                style={{ border: pathFile && pathFile.length !== 0 && '0', cursor: 'text' }}
                                type="text"
                                placeholder="Описание файла"
                                onChange={handleChangeDescription}
                                value={descriptionFile && descriptionFile}
                            />
                        </td>
                        <td title={!pathFile && `Сохраните файл, чтобы прикрепить его к текущему документу`}
                            // className={'list_item_stile'}
                            as="li"
                            action
                            onClick={handleClickSave}>
                            {pathFile && pathFile.length !== 0 ? <CheckOutlinedIcon color={'success'} /> : <SaveOutlinedIcon color={'success'} />}
                        </td>
                        {pathFile && pathFile.length !== 0 &&
                            <td
                                as="li"
                                action
                                onClick={() => handleClickReadFile(pathFile)} >
                                <DownloadOutlinedIcon color={'primary'} />
                            </td>
                        }

                        <td
                            color="action" as="li"
                            action
                            onClick={handleClickDel}>
                            <DeleteForeverOutlinedIcon style={{ color: '#dc3545' }} />
                        </td>
                    </tr>
                </tbody>
            </Table> */}

        </>



    )
}
