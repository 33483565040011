import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { DOWN_MECH_PROD } from './ConstDownProduction';

export default class SelectFormArr extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            language: 'rus',
            id: '',
            fid: '',
            description: '',
            information: '',
            value: [],
            colorError: '#fff',
            base: [],
            showDown: true
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.viewListOptions = this.viewListOptions.bind(this);
        this.renderValueArray = this.renderValueArray.bind(this);
        this.handleChangeList = this.handleChangeList.bind(this);
        this.handleBlurList = this.handleBlurList.bind(this);
        this.handleClickDel = this.handleClickDel.bind(this);

    }

    //*удаление позиций
    handleClickDel(e) {
        let arrayValue = this.state.value;

        arrayValue.splice(e.target.dataset.id, 1);
        this.renderValueArray();
    }

    handleBlurList(e) {
        setTimeout(() => {
            this.props.onChangeValue(this.state);
        })
    }

    handleChangeList(e) {
        // console.log(e.target.value);//test
        this.setState({ value: [...this.state.value, e.target.value] });
        setTimeout(() => {
            this.renderValueArray();
            this.props.onChangeVisionBlock(e.target.value, e);
        });
    }

    handleBlur(e) {
        this.setState({
            id: e.target.dataset.id,
            fid: e.target.dataset.fid,
            description: e.target.dataset.description,
            information: e.target.value
        })
        setTimeout(() => {
            this.props.onChangeValue(this.state);
            // console.log(this.state);
        })

    }

    handleChange(e) {
        e.preventDefault();
        // console.log(e.target.value);//test
        this.setState({ information: e.target.value })

        setTimeout(() => {
            let option = this.props.option;
            let choice = this.props.arrayChoice;

            let position = option.indexOf(this.state.information);
            if (position !== -1) {
                choice[position] = false
            }
            this.renderListOptions(this.state.information);

            this.props.onChoiceOption(choice);
            this.props.onChangeVisionBlock(e.target.value, e);
        })
    }

    //*рендер деятельности из массива VALUE
    renderValueArray() {
        this.base = [];
        // console.log(this.state.value);//test
        if (this.state.value) {
            this.state.value.forEach((data, index) => {
                this.base = [...this.base,
                <React.Fragment key={index}>

                    <div
                        className="render_value"
                        data-id={index}>
                        <span
                            className="main_down_span"
                            onClick={this.handleClickDel}
                            data-id={index}>
                            &nbsp; &#10006; &nbsp;
                        </span>
                        &nbsp;
                        {data}

                    </div>

                </React.Fragment>
                ]
            })
            this.setState({ base: this.base })
        }

    }

    //*исключаем повторный выбор
    viewListOptions(arr, choice) {
        this.optionArr = [];
        this.setState({ choice: this.props.arrayChoice })
        arr.forEach((data, index) => {
            if (choice[index]) {
                this.optionArr.push(
                    <option key={index} >{data}</option>
                )
            }

        })
    }

    //*рендер списка подменю производства
    renderListOptions(data) {
        this.optionArrList = [];
        this.array = [''];

        if (data === '1.6.2. Машиностроительный завод') this.array = DOWN_MECH_PROD;

        this.array.forEach((data, index) => {
            this.optionArrList.push(
                <option key={index}>{data}</option>
            )
        })
        this.setState({ showDown: false });
        setTimeout(() => { this.setState({ showDown: true }) }, 100)
    }

    componentDidMount() {
        this.setState({
            information: this.props.information,
            id: this.props.id,
            fid: `${this.props.nameForm}_${this.props.id}`,
            description: this.props.description,
            value: this.props.value
        });
        this.viewListOptions(this.props.option, this.props.arrayChoice);
        this.renderListOptions(this.props.information);
        setTimeout(() => { this.renderValueArray() }, 500)
    }

    render() {

        if (this.props.showOn) this.showOn = this.props.showOn;
        const showDown = this.state.showDown;
        const width = this.props.width;
        // const label = this.props.label;
        const label = this.props.placeholder;
        const id = this.props.id;
        const description = this.props.description;
        const placeholder = this.props.placeholder;
        const value = this.state.information;
        const show = this.props.show;
        // const color = this.props.color;


        return (
            <React.Fragment key={id}>
                <Row>
                    <Col sm={width} style={{ visibility: show ? 'visible' : 'collapse' }} >
                        <Form.Label className='select_form_label' >{label}</Form.Label>
                        <Form.Control
                            as="select"
                            type="text"
                            data-id={id}
                            data-fid={`${this.props.nameForm}_${id}`}
                            placeholder={placeholder}
                            data-information={placeholder}
                            data-description={description}
                            value={value}
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                            disabled={this.props.readOnly}
                        >
                            <option key={placeholder}>{placeholder}</option>
                            {this.optionArr}
                        </Form.Control>
                    </Col>
                    <Col sm={width} style={{ display: this.showOn ? 'none' : 'block' }} >
                        {showDown ?
                            <>
                                <Form.Label className='select_form_label' >добавить деятельность</Form.Label>
                                <Form.Control
                                    as="select"
                                    type="text"
                                    // data-id={id}
                                    placeholder="добавить деятельность"
                                    // value={value}
                                    onChange={this.handleChangeList}
                                    onBlur={this.handleBlurList}
                                    disabled={this.props.readOnly}
                                >
                                    <option key={placeholder}>добавить деятельность</option>
                                    {this.optionArrList}
                                </Form.Control>
                            </>
                            : ''}
                    </Col>
                </Row>
                <Col style={{ display: this.showOn ? 'none' : 'block' }} >
                    Деятельность:
                </Col>


                {this.state.base}



            </React.Fragment>
        )
    }
}