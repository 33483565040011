import { COMBINING } from "./combiningMaterials";

// as: text, select, checkbox, disabled

// 1 Общие параметры
export const commonParametersLeft = [
    { display: 'Корпусов:', key: 'body', as: 'text' },
    { display: 'Заявка:', key: 'application', as: 'text' },
    { display: 'Масса корпуса, кг.:', key: 'bodyWeight', as: 'text' },
    { display: 'Длина пучка, мм.:', key: 'beamLength', as: 'text' },

];
const arrCommonParametersRight = [
    'С неподв. тр. реш.',
    'С плав. головкой',
    'U - образный'
]

// 1 Общие параметры
export const commonParametersRight = [
    { display: 'Номер изм.:', key: 'measurementNumber', as: 'text' },
    { display: 'Номер ТР:', key: 'numberTR', as: 'text' },
    { display: 'Номер КР:', key: 'numberKR', as: 'text' },
    { display: 'Диаметр корпуса, мм.:', key: 'bodyDiameter', as: 'text' },
    { display: 'Вид пучка:', key: 'beamType', as: 'select', arr: arrCommonParametersRight },
]

// 2 Материальное исполнение аппарата 
export const materialDesignApparatus = [
    { display: 'Материал труб:', key: 'pipesMaterial', as: 'select', arr: COMBINING },
    { display: 'Материал решеток:', key: 'gratingMaterial', as: 'select', arr: COMBINING },
    { display: 'Материал корпуса:', key: 'bodyMaterial', as: 'select', arr: COMBINING },
    { display: 'Материал камеры:', key: 'cameraMaterial', as: 'select', arr: COMBINING },
    { display: 'Материал крышки:', key: 'coverMaterial', as: 'select', arr: COMBINING },
]

// 3 Труба теплообменная
export const heatExchangePipe = [
    { display: 'Кол-во теплообменных труб:', key: 'numberHeatExchangePipes', as: 'text', },
    { display: 'Наружный диаметр трубы, мм:', key: 'pipeOuterDiameter', as: 'select', arr: [10, 12, 14, 16, 20, 25, 32, 38, 57] },
    { display: 'Толщина стенки трубы, мм:', key: 'pipeWallThickness', as: 'select', arr: ['1.0', '1.5', '2.0', '2.5', '3.0'] },
]

// 4 Трубные решетки
export const tubeSheets = [
    { display: 'Диаметр, мм:', key: 'grateDiameter1', as: 'text', },
    { display: 'Площадь, мм:', key: 'latticeArea1', as: 'text', },
    { display: 'Количество:', key: 'latticeQuantity1', as: 'text', },
    { display: 'Тип заготовки:', key: 'workpieceType1', as: 'select', arr: ['Лист', 'Поковка'] },
    { display: 'Диаметр, мм:', key: 'grateDiameter2', as: 'text', },
    { display: 'Площадь, мм:', key: 'latticeArea2', as: 'text', },
    { display: 'Количество:', key: 'latticeQuantity2', as: 'text', },
    { display: 'Тип заготовки:', key: 'workpieceType2', as: 'select', arr: ['Лист', 'Поковка'] },
]

// 5 Перегородки
export const partitions = [
    { display: 'Материал перегородок', key: 'partitionMaterial', as: 'select', arr: COMBINING },
    { display: 'Кол-во поперечных перегородок, шт.:', key: 'numberTransversePartitions', as: 'text', },
    { display: 'Толщина поперечных перегородок, мм:', key: 'thicknessTransversePartitions', as: 'select', arr: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20] },
    { display: '% выреза в перегородке:', key: 'septumCutPercentage', as: 'text', },
]

// 6 Материалы
const materialsCategoryARR = ['Листовой прокат из углеродистой стали', 'Листовой прокат из нержавеющей стали', 'Сортовой прокат из углеродистой стали', 'Сортовой прокат из нержавеющей стали', 'Фланцы из углеродистой стали', 'Фланцы из нержавеющей стали', 'Стандартные изделия из углеродистой стали', 'Стандартные изделия из нержавеющей стали', 'Поковки', 'Крепеж', 'Прокладки', 'Блоки + гильзы', 'Прочее', 'Покупные изделия']
export const materials = [
    { display: 'Категория:', key: 'category', as: 'select', arr: materialsCategoryARR },
    { display: 'Марка стали:', key: 'steelGrade', as: 'select', arr: [] },
    { display: 'Общий вес(чистовой), кг.:', key: 'totalWeight', as: 'text', },
    { display: 'Примечание:', key: 'noteMaterials', as: 'text', },
]
export const steelARR = [
    ["Ст3 ", "Ст4", "Сталь 10", "Сталь 20", "15К", "16К", "18К", "20К ", "20КА", "20Юч", "20ПВ", "22К", "07ГФБ-У", "09Г2", "09Г2С", "09Г2БТ", "09Г2СЮЧ", "09ГСНБЦ", "09ХГН2АБ", "09ХГ2СЮЧ", "09ХГ2НАБЧ", "10Г2", "10Г2БТ", "10Г2ФБ", "10Г2ФБЮ", "10Г2С1", "10Г2С1Д", "10ХСНД", "13Г1С-У", "14Г2", "15ХСНД", "15Г2СФ", "16ГС", "16ГМЮЧ", "17ГС", "17Г1С", "Е32", "Е36", "Е40", "В", "Д32", "Д36", "Д40", "К52", "12МХ", "12ХМ", "12Х1МФ", "15ХМ", "1Х2М1", "20Х2МА", "15Х2МФА-А", "12Х2МФА", "10Х2М1А-А",
    ],
    [
        "02Х18Н11", "02Х8Н22С6", "03Х18Н11", "03Х17Н14М3", "03Х19АГ3Н10", "03Х21Н21М4ГБ", "07Х13АГ20", "08Х18Н10Т", "08Х18Н12Б", "08Х17Н13М2Т", "08Х17Н15М3Т", "10Х14Г14Н4Т", "10Х17Н13М2Т", "10Х17Н13М3Т", "12Х18Н9Т", "12Х18Н10Т", "12Х18Н12Т",
    ]
]


// 7 Уплотнение
export const sealing = [
    { display: 'Виды материалов', key: 'sealing', as: 'select', arr: ['нет', 'ПОН', 'ПУТГ, МГЛ', 'СНП'] },
]

// 8 Расчет стоимости изготовления
const diametrARR = [159, 219, 273, 325, 377, 400, 426, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 2000, 2200, 2400, 2600, 2800, 3000, 3200]
const temaARR = ['AEM', 'AEU', 'AES', 'AGM', 'AGU', 'AGS', 'AFM', 'AFU', 'AFS', 'AXM', 'AXU', 'AXS', 'BEM', 'BEU', 'BES', 'BGM', 'BGU', 'BGS', 'BFM', 'BFU', 'BFS', 'BXM', 'BXU', 'BXS']
const pipeSpaceARR = ['0.6', '1.0', '1.6', '2.5', '4.0', '6.3', '10.0', '16.0', '25.0', '32.0']
const interpipeSpaceARR = pipeSpaceARR
export const manufacturingCostCalculation = [
    { display: 'Кол-во аппаратов:', key: 'numberDevices', as: 'text', },
    { display: 'Тип по ТЕМА:', key: 'TEMAtype', as: 'select', arr: temaARR },
    { display: 'Диаметр аппарата, мм:', key: 'deviceDiameter', as: 'select', arr: diametrARR },
    { display: 'Расчетное давление, Трубное пространство, МПа:', key: 'designPressurePipeSpace', as: 'select', arr: pipeSpaceARR },
    { display: 'Расчетное давлениеб Межтрубное пространство, МПа:', key: 'designPressureInterpipeSpace', as: 'select', arr: interpipeSpaceARR },
    { display: 'Кол-во ходов:', key: 'numberMoves', as: 'text', arr: [] },
    { display: 'Расчетная температура рабочей среды, Трубное пространство, С:', key: 'designTemperaturePipeSpace', as: 'text', arr: [] },
    { display: 'Расчетная температура рабочей среды, Межтрубное пространство, С:', key: 'designTemperatureInterpipeSpace', as: 'text', arr: [] },
    { display: 'Повышенная сложность изготовления:', key: 'increasedManufacturingComplexity', as: 'checkbox', },
    { display: 'Прогнозируемая стоимость работ (без учета материалов), руб. без НДС:', key: 'projectedCostWork', as: 'text', arr: [] },
]


// 9 Стоимость доставки и срок изготовления
export const deliveryCostAndProductionTime = [
    { display: 'Адрес доставки:', key: 'deliveryAddress', as: 'text', },
    { display: 'Стоимость доставки, руб. без НДС:', key: 'costDelivery', as: 'text', },
    { display: 'Комментарии к стоимости:', key: 'commentOnTheCost', as: 'text', },
    { display: 'Срок доставки дней', key: 'deliveryTime', as: 'text', },
    { display: 'Комментарии к сроку доставки:', key: 'commentOnDeliveryTime', as: 'text', },
]