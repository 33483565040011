import { useEffect, useState } from 'react'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { Badge, ModalFooter } from 'react-bootstrap'
import { Alert, Container } from 'react-bootstrap'
import ChatWs from '../chat/ChatWs'
import { ChatButton, ExitButton, SaveCurrentDataButton } from './BasicButtons'
import RefreshButton from './RefreshButton'
import Timer from './Timer'
import getMainCompany from './getMainCompany'
// import SelectData from '../registryOfEquipment/lifeCycleOfEquipment/SelectData'
import saveWinnerData from './saveWinnerData'
import getWinnerData from './getWinnerData'
import SelectDataShortInput from '../registryOfEquipment/lifeCycleOfEquipment/SelectDataShortInput'
import sendMessageWinner from './sendMessageWinner'
import loadNewTradeTable from './loadNewTradeTable'
import getValueTable from './getValueTable'
import compareTradeTables from './compareTradeTables'
import LoaderPage from './loaderPage'
import { getTradeDuration, toMoscowTime } from '../trade/processTradeObject'
import TimerTimeLeft from './TimerTimeLeft'

// toggleModal = {закрывает модалку на крестик}
// alertHeading = {заглавие модалки}
// modalContent = {содержимое модалки, все что внутри}
// reRender = {функция для ререндера таблицы}
// participant = {участники лота для прокидки в ChatWs}
// asParticipant = {когда заходишь как участник, кнопка выхода из модалки отображается}

export default function ModalWindowTrade({
  toggleModal,
  alertHeading,
  modalContent,
  reRender,
  dateTradeTimer,
  dateTradeMs,
  lot,
  forName,
  idCompany,
  filterAll,
  doStopTrade,
  asParticipant,
  percentErrorPlus,
  percentErrorMinus,
  isOpen
  // testToggle
}) {
  const [showChat, setShowChat] = useState(false)
  const [exitNotice, setExitNotice] = useState(false)
  const [savedNotice, setSavedNotice] = useState(false)
  const [company, setCompany] = useState([])
  const [winner, setWinner] = useState()
  const [winnerOnChange, setWinnerOnChange] = useState({})
  const [isSaved, setIsSaved] = useState(false)
  const [total, setTotal] = useState()
  const [spinnerOn, setSpinnerOn] = useState(false)
  const [content, setContent] = useState(null)
  const [timeBeforeFinish, setTimeBeforeFinish] = useState(0)
  const maxNameLength = 30

  useEffect(() => {
    if (lot) {
      console.log(lot);
      const currentMoscowTime = toMoscowTime(new Date())
      const tradeStartDate = new Date(lot.dateTrade)
      const tradeDurationMinutes = parseInt(lot.timer, 10)
      const tradeEndDate = new Date(tradeStartDate.getTime() + tradeDurationMinutes * 60000)
      const timeLeftMs = tradeEndDate - currentMoscowTime
      const timeLeftSeconds = Math.floor(timeLeftMs / 1000)
      console.log(timeLeftSeconds)
      // if(timeLeftSeconds > 0) {
      setTimeBeforeFinish(timeLeftSeconds)
      // }

      // return timeLeftSeconds
    }
  }, [])

  // console.log(lot)

  // console.log(`LOT::: `, lot) // test
  // *: получить массив участников - форма собственности + название

  useEffect(() => {
    ;(async () => {
      const companies = await getMainCompany(lot?.participant)
      console.log(`company::: `, companies) // test
      setCompany(companies)
    })()
  }, [lot?.participant])

  useEffect(() => {
    ;(async () => {
      try {
        // *: функция загрузки новых данных торгов и преобразования в объект для сравнения изменений
        const newTradeTable = await loadNewTradeTable(lot.id)
        const resultTotal = getValueTable(newTradeTable)
        setTotal(resultTotal)
        console.log(`valueTable::::::: `, resultTotal) // test
      } catch (err) {
        console.log(`Ошибка при загрузки новых данных торгов: `, err)
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      try {
        const idWin = lot?.id
        // console.log(`ID LOT >>> `, idWin) // test
        const result = await getWinnerData(idWin)
        setWinner(result)

        // console.log(`WINNER::: `, winner); // test
      } catch (err) {
        console.log(`Ошибка при получении данных победителя: `, err)
      }
    })()
  }, [])

  // const rerenderContent = () => {
  //   setShowContent(false)
  //   setTimeout(() => {
  //     setShowContent(true)
  //   }, 2000)
  // }

  useEffect(() => {
    if (isOpen) {
      setSpinnerOn(true)
      setContent(null)

      const timer = setTimeout(() => {
        setSpinnerOn(false)
        setContent(modalContent)
        const showContentTimer = setTimeout(() => {}, 1000)
        // reRender(lot)
        return () => clearTimeout(showContentTimer)
      }, 1000)
      return () => clearTimeout(timer)
    }
  }, [isOpen, modalContent])

  const showSavedMessage = () => {
    setIsSaved(true)
    setTimeout(() => {
      setIsSaved(false)
    }, 1500)
  }

  const empty = () => {}

  // * старт функции с указанным шагом в Timer
  const doStepTime = (sec) => {
    // console.log(`step to ::: ${sec}`) // test
    ;(async () => {
      try {
        if (!total) throw new Error('Результат TOTAL еще не загружен')

        // todo: Сделать проверку данных для ре-рендера ( -> reRender(lot))
        const loadTable = await loadNewTradeTable(lot.id)
        const resultValueStep = getValueTable(loadTable)
        const resultCompare = compareTradeTables(total, resultValueStep)
        // console.log(`RESULT COMPARE:::: `, resultCompare) // test
        // console.log('gere', loadTable);

        if (!resultCompare) {
          reRender(lot)
        }
      } catch (err) {
        console.log(`Ошибка при проверке данных для ре-рендера: `, err)
      }
    })()
  }

  /**
   * Определение победителя
   * @param {*} e
   */
  const handleChangeInput = (e) => {
    // console.log(`E ::::: `, e.target.value) // test
    const arrValue = e.target.value.split(' ')
    let value

    if (arrValue.length === 1) {
      value = e.target.value
    } else {
      arrValue.shift()
      value = arrValue.join(' ')
    }
    const companyData = company.find((item) => item.nameCompany.match(value))
    // setWinner(companyData)
    setWinnerOnChange(companyData)
    // console.log(`DATA SET :::::: `, companyData) // test
  }

  /**
   * Сохраняем данные победителя в DB
   */
  const handleClickWinner = () => {
    // console.log(`WINNER COMPANY:::: ${lot.id} >>>>>> `, winner.id) // test

    ;(async () => {
      await saveWinnerData(lot.id, winnerOnChange.id)
      await sendMessageWinner(lot.id, lot.participant, winnerOnChange.id)
    })()
    setWinner(winnerOnChange)
    showSavedMessage()
  }

  const tableExit = () => {
    setExitNotice(true)

    setTimeout(() => {
      toggleModal()
      setExitNotice(false)
    }, 2000)
  }

  const tableSaved = () => {
    setSavedNotice(true)

    setTimeout(() => {
      setSavedNotice(false)
    }, 2000)
  }

  // useEffect(() => {
  //   const result = getTradeDuration(lot?.dateTrade, lot?.timer)
  //   console.log(result)
  //   setIsTradeFinished(result)
  // }, [lot?.dateTrade, lot?.timer])

  return (
    <>
      <div
        className='shadow_form'
        onClick={percentErrorPlus || percentErrorMinus ? empty : toggleModal}
      ></div>
      <div className='modal_common_1_trade z_index_19'>
        {percentErrorPlus && !percentErrorMinus && (
          <Alert className='header_alert_common background_danger color_fff'>
            {' '}
            <Alert.Heading>Сумма предоплат превышает 100% (п.3, п.4, п.5)</Alert.Heading>{' '}
          </Alert>
        )}
        {percentErrorMinus && !percentErrorPlus && (
          <Alert className='header_alert_common background_danger color_fff'>
            <Alert.Heading>Сумма предоплат менее 100% (п.3, п.4, п.5)</Alert.Heading>
          </Alert>
        )}
        {!percentErrorMinus && !percentErrorPlus && (
          <Alert
            variant='dark'
            onClose={toggleModal}
            dismissible
            className='header_alert_common'
            style={{ fontSize: '20px' }}
          >
            {alertHeading} &nbsp;{' '}
            <Badge variant='secondary'>
              тип торгов: {lot?.typeTrade === 'reduction' ? 'Редукцион' : 'Обычные торги'}{' '}
            </Badge>
          </Alert>
        )}

        <div className='timer_bar'>
          <div className='timer_bar_content'>
            <RefreshButton
              handleRefresh={() => {
                reRender(lot)
              }}
            />
            <Timer
              dateTradeTimer={dateTradeTimer}
              dateTradeMs={dateTradeMs}
              doStopTrade={() => doStopTrade(lot)} // *: остановка торгов, передать запуск в родителя
              doStepTime={(sec) => doStepTime(sec)}
            />

            {/* <TimerTimeLeft initialTimeLeft={timeBeforeFinish} /> */}
          </div>
        </div>

        <Container className='modal_trade_content'>
          {/* *** */}
          {spinnerOn ? <LoaderPage w={'100%'} minH={'64vh'} /> : content}

          {/* *** */}

          {/* {spinnerOn ? (
              <LoaderPage w={'100%'} minH={'64vh'} />
            ) : (
              modalContent // Display the content when loading is false
            )} */}
        </Container>

        <ModalFooter
          style={{ display: 'flex', justifyContent: asParticipant ? '' : 'space-between' }}
        >
          {/* {asParticipant ? (
            ''
          ) : ( */}
          <div
            style={{
              display: 'flex',
              gap: '20px',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {!asParticipant && (
              <SelectDataShortInput
                // index={ITEM_LIST_KEY[index]}
                label={` `}
                // placeholder={`Введите: `}
                // text={'Необходимо к заполнению*'}
                arr={company}
                // keyData={ITEM_LIST_KEY[index]}
                handleChangeInput={handleChangeInput}
                disabled={winner ? true : false}
              />
            )}
            {!asParticipant && (
              <SaveCurrentDataButton
                handleSaveClick={handleClickWinner}
                needDisable={true}
                disableCondition={winner || !winnerOnChange?.nameCompany ? true : false}
                savedDataMessage={isSaved}
                buttonTextDefault={'Подтвердить победителя'}
                buttonTextSaved={'Победитель подтвержден'}
                needCustomIcon={true}
                customIcon={<CheckOutlinedIcon style={{ marginRight: '5px' }} />}
                needCustomStyle={false}
              />
            )}
            {winner ? (
              <Alert
                variant='info'
                style={{ display: 'flex', alignItems: 'center', height: '40px', margin: '0' }}
                title={winner?.nameCompany}
              >
                {`Победитель:  `}
                <span style={{ fontWeight: 'bolder', marginLeft: '5px' }}>
                  {winner?.nameCompany?.length > maxNameLength
                    ? ` ${winner.nameCompany.substring(0, maxNameLength)}...`
                    : winner.nameCompany}
                  {/* {winner.nameCompany} */}
                </span>
              </Alert>
            ) : (
              ''
            )}
          </div>
          {/* )} */}

          <div style={{ display: 'flex', gap: '10px' }}>
            <ChatButton handleSaveClick={() => setShowChat(!showChat)} needDisable={false} />
            {asParticipant && (
              <SaveCurrentDataButton
                handleSaveClick={tableSaved}
                needDisable={true}
                disableCondition={percentErrorPlus || percentErrorMinus}
                savedDataMessage={savedNotice}
                buttonTextDefault='Сохранить данные'
                buttonTextSaved='Данные сохранены'
              />
            )}

            {asParticipant && (
              <ExitButton
                handleSaveClick={tableExit}
                needDisable={true}
                disableCondition={percentErrorPlus || percentErrorMinus}
                savedDataMessage={exitNotice}
              />
            )}
          </div>
        </ModalFooter>
      </div>

      {showChat && (
        <ChatWs
          onChangeVisibleChat={() => setShowChat(!showChat)}
          onCreateIdChat={empty}
          idChat={lot && lot.chat}
          forName={forName} // test со стороны участника передать такое dataLot.organizerId
          idCompany={idCompany}
          idBox={lot && lot.id} //keyNameTrade (id лота)
          updateQuantityMessage={empty}
          filterAll={filterAll ? filterAll : []} // обязательный пропс для участника
          modalWidth={'width_50'}
        />
      )}
    </>
  )
}
