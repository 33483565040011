import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Server from './components/server/server'

ReactDOM.render(<React.StrictMode>
    <Suspense fallbsck={<div> ЗАГРУЗКА... </div>}>
        <App />
    </Suspense>
</React.StrictMode>,
    document.getElementById('root'),

    // При закрытии окна (beforeunload - наступает при перезагрузке, unload - наступает при закрытии)
    window.addEventListener('unload', () => {
        (async () => {
            await Server.doCloseSession()
        })()
    })
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();