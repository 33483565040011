/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Alert, Form, Row, Button, Table } from 'react-bootstrap'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import TechDataTradeAdd from './TechDataTradeAdd'
import Server from '../server/server'
import { TechTemplate } from './TechTemplate'
import AllModalsFooter from '../assets/AllModalsFooter'
import { LoadButton } from '../assets/BasicButtons'
// import InputForm from '../questionnaire/InputForm';
// import ModalQuit from '../ModalQuit'

export default class LoadAllTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'rus',
      show: false,
      base: [],
      resultTemplate: '',
      resultFromServer: [],
      showTechTemplate: false,
      resultTemplateBlock: [],
      rowColor: '#fff',
      templateData: ''
    }

    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)
    this.handleClickAdd = this.handleClickAdd.bind(this)

    this.loadArrayTemplate = this.loadArrayTemplate.bind(this)
    // this.renderButtonTemplate = this.renderButtonTemplate.bind(this);
    this.handleClickTemplate = this.handleClickTemplate.bind(this)
    this.handleClickLoad = this.handleClickLoad.bind(this)
    this.handleClickLook = this.handleClickLook.bind(this)
    this.closeTechTemplate = this.closeTechTemplate.bind(this)
  }

  closeTechTemplate() {
    this.setState({ showTechTemplate: false })
  }

  // просмотр выбранного шаблона
  async handleClickLook() {
    // console.log('click - look'); // test просмотр шаблона
    const result = this.state.resultFromServer.find(item => item._id === this.state.resultTemplate)
    // console.log(result); // test  отдать на верску, для просмотра и редактирования
    await this.setState({ resultTemplateBlock: result.data })
    await this.setState({ showTechTemplate: true })
  }

  handleClickLoad() {
    const result = this.state.resultFromServer.find(item => item._id === this.state.resultTemplate)
    // console.log(result); // test загрузить в компонент TechDataTrade
    this.props.onLoadTemplate(result)
    this.doQuitWithoutSave()

    // console.log(result);
  }

  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true }) // сохранение тут не работает, только выбор
    // this.doQuitWithoutSave() // выход из формы
  }

  //выход с сохранением
  doQuitWithSave() {
    this.props.onChangeView('LoadAllTemplate')
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView('LoadAllTemplate')
  }

  async handleClickAdd() {
    if (this.state.nameTemplate.length < 1) {
      this.setState({ errName: true })
      setTimeout(() => {
        this.setState({ errName: false })
      }, 2000)
    } else {
      this.setState({ errName: false })
      await this.setState({
        base: [
          ...this.state.base,
          <React.Fragment key={this.state.id}>
            <TechDataTradeAdd onChangeValue={this.doChangeValue} id={this.state.id} value={this.state.tech} />
          </React.Fragment>
        ],
        id: +this.state.id + 2,
        maxId: +this.state.id + 2
      })
    }
    // setTimeout(() => { console.log(this.state.id + ' <- ADD ID'); }) // test
  }

  async loadArrayTemplate() {
    const result = await Server.searchAllTemplate()
    console.log(`RESULT::::::::::::: `, result) // test
    // this.renderButtonTemplate(result);
    this.setState({ resultFromServer: result })
  }

  async handleClickTemplate(e) {
    const dataT = this.state.resultFromServer.map(data => {
      if (`TradeTemplate_${e.target.dataset.id}` === data._id) {
        this.setState({
          templateData: data
        })
        return {
          _id: data._id,
          data: data.data,
          clicked: true
        }
      } else {
        return {
          _id: data._id,
          data: data.data,
          clicked: false
        }
      }
    })

    await this.setState({
      resultTemplate: `TradeTemplate_${e.target.dataset.id}`,
      resultFromServer: dataT
    })
  }

  async componentDidMount() {
    await this.loadArrayTemplate()
  }

  render() {
    const view = this.props.view
    this.value = this.props.data
    const viewQuest = this.props.viewQuest
    const show = this.state.show

    // console.log(this.state.resultFromServer[1]);

    return (
      <>
        {!show ? (
          <div className='modal_window z_index_1000' style={{ display: view ? 'block' : 'none' }}>
            <div
              className={viewQuest ? 'shadow_form z_index_1000' : 'shadow_form_off'}
              onClick={this.handleClickShadow}
            ></div>
            <Form className={viewQuest ? 'create_template_modal width_60 p_40 z_index_1001' : 'form_main_view'}>
              <Alert
                variant='dark'
                className='header_alert_common color_000'
                onClick={this.doQuitWithoutSave}
                onClose={() => this.handleClickShadow()}
                dismissible
              >
                <Alert.Heading className='create_template_heading'>
                  Загрузить шаблон технических требований{' '}
                </Alert.Heading>
              </Alert>
              <div>
                <div
                  className='form_container'
                  style={{ height: '300px', marginBottom: '40px', marginTop: '30px', overflowY: 'auto' }}
                >
                  <Row> &nbsp;</Row>
                  <Row> &nbsp;</Row>

                  <Table bordered size='sm'>
                    <thead>
                      <tr>
                        <th className='employeeTh' style={{ fontWeight: 'bolder' }}>
                          {' '}
                          Выберите шаблон технических требований:
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.resultFromServer.map(template => {
                        const templateName = template._id.replace('TradeTemplate_', '')
                        return (
                          <tr key={template._id}>
                            <td
                              onClick={this.handleClickTemplate}
                              style={{
                                backgroundColor: template.clicked ? '#73c586' : '#fff',
                                color: template.clicked ? '#fff' : '#000'
                              }}
                              className='cursor_pointer'
                              data-id={templateName}
                            >
                              {templateName}
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>

                  {/* <Row> &nbsp;</Row>
                                <div className='display_flex container_flex_start flex_gap_8 mt_10'>

                                    <Button disabled={this.state.templateData ? false : true} variant="primary" onClick={this.handleClickLoad}>Загрузить шаблон</Button>
                                    <Button disabled={this.state.templateData ? false : true} variant="primary" onClick={this.handleClickLook}>Посмотреть шаблон</Button>
                                </div> */}

                  <Row> &nbsp;</Row>
                </div>
                <AllModalsFooter
                  isPosAbsoluteNedded={true}
                  footerContent={
                    <>
                    <LoadButton
                    handleSaveClick={this.handleClickLoad} 
                    needDisable={true} 
                    disableCondition={this.state.templateData ? false : true} 
                    buttonText='Загрузить шаблон'
                    />
                      {/* <Button
                        disabled={this.state.templateData ? false : true}
                        variant='primary'
                        onClick={this.handleClickLoad}
                      >
                        Загрузить шаблон
                      </Button> */}
                      <Button
                        disabled={this.state.templateData ? false : true}
                        variant='primary'
                        onClick={this.handleClickLook}
                      >
                        <RemoveRedEyeOutlinedIcon style={{marginRight: '5px'}}/>
                        Просмотреть шаблон
                      </Button>
                    </>
                  }
                />
              </div>
            </Form>
          </div>
        ) : (
          ''
        )}
        {this.state.showTechTemplate ? (
          <TechTemplate
            resultTemplateBlock={this.state.resultTemplateBlock}
            closeTechTemplate={this.closeTechTemplate}
            resultTemplate={this.state.resultTemplate}
          />
        ) : (
          ''
        )}
      </>
    )
  }
}
