import React from 'react'
import { Alert, Container, Form } from 'react-bootstrap'

export default function ModalEquipmentLifeCycleInUse({
  toggleModal,
  alertHeading,
  handleClickHeatExchange
}) {
  const handleSelect = (e) => {
    handleClickHeatExchange(e.target.value)
  }

  return (
    <>
      <div className='shadow_form' style={{ zIndex: '1000' }} onClick={toggleModal}></div>
      <div className='modal_common_2 z_index_1000' >
        <Alert variant='dark' onClose={toggleModal} dismissible>
          <Alert.Heading>{alertHeading}</Alert.Heading>
        </Alert>
        <Container
          style={{
            height: '200px',
            overflowY: 'auto',
            padding: '15px 50px 0 50px',
            width: '550px'
          }}
        >
          <Form.Group>
            <Form.Label>Выберете вид теплопередачи через трубопроводы:</Form.Label>
            <Form.Control as='select' onChange={handleSelect}>
              <option></option>
              <option>Межтрубное пространство</option>
              <option>Трубное пространство</option>
              <option>Общие характеристики</option>
            </Form.Control>
          </Form.Group>
        </Container>
      </div>
    </>
  )
}
