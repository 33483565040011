import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'


export const HandleAllCheckBoxesBtn = ({ handleAllChecked, selectCheck, dataId, showRow }) => {
    return (
        <>
            {showRow && <Row>&nbsp;</Row>}
            <Row onClick={handleAllChecked} className='form_four_row' >
                <Col data-id={dataId && dataId} >
                    {
                        selectCheck ?
                            <Button data-id={dataId && dataId} size="sm" variant='light' className='mb_10'>Выделить все </Button> :
                            <Button data-id={dataId && dataId} size="sm" variant='light' className='mb_10'>Снять все </Button>
                    }
                </Col>
            </Row>
        </>
    )
}