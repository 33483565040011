import React from 'react';
import { Alert, Button, Container } from 'react-bootstrap';
import ContentAddNewEquipment from './ContentAddNewEquipment';

// toggleModal = {закрывает модалку на крестик}
// alertHeading = {заглавие модалки}
// modalContent = {содержимое модалки, все что внутри}

/**
 * Модалка добавления новой карты аппарата
 * @param {*} param0 
 * @returns 
 */
const ModalAddNewEquipment = ({ toggleModal, alertHeading, modalContent, doSaveCard, setShowTable }) => {

    return (
        <>
            <div className="shadow_form" onClick={toggleModal} ></div>
            <div className="modal_common_1 z_index_1000" >
                <Alert variant="dark" onClose={toggleModal} dismissible>
                    <Alert.Heading >
                        {alertHeading}
                    </Alert.Heading>
                </Alert>
                <Container style={{ height: '700px', overflowY: 'auto' }}>

                    <ContentAddNewEquipment
                        doSaveCard={doSaveCard}
                        setShowTable={setShowTable}
                    />

                </Container>
            </div>
        </>
    )
}
export default ModalAddNewEquipment;