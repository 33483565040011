export function createData(data, maxId, dataFromServer, dataValue) {

    for (let i = 0; i <= (maxId); i++) {

        //todo создаем позицию с пустым объектом
        data[i] = { id: '', fid: '', description: '', information: '', value: '' }

        //todo заполняем объект данными с сервера
        dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
        dataValue.forEach((dataE) => {
            if (+dataE.id === i) {
                data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
            }
        })
    }
    return data;
}