import React from 'react'
// import { Jumbotron, Container } from 'react-bootstrap';
import Lotuss from './assets/images/Lotus.png'
import skolkovo from '././assets/images/skolkovo.jpg'
import FeedBack from './assets/FeedBack'

export default class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus'
    }
  }

  render() {
    return (
      <>
        <footer
          className={this.props.isFooterLong ? 'footer footer_vert' : 'footer footer_horizontal'}
        >
          <a
            href='https://navigator.sk.ru/orn/1123945'
            className='link_style_footer'
            target='_blank'
            rel='noreferrer'
          >
            <img alt='' src={skolkovo} className='skolkovo_logo_footer' />
            Участник проекта "Сколково"
          </a>
          <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '20px'}}>
            <a
              href='https://www.lotus1.org'
              className='link_style_footer'
              target='_blank'
              rel='noreferrer'
            >
              <img alt='' src={Lotuss} className='lotus_logo_footer' />
              Разработано в LOTUS &copy; 2022
            </a>
            <FeedBack />
          </div>
        </footer>
      </>
    )
  }
}
