import Server from '../server/server'

/**
 * Загрузка с сервера данных (trade, trade_table, trade_table_new) для торгов
 * @param {*} id - ID document
 * @returns 
 */
export const loadTradeTable = async (id) => {
    const renderNothing = `Нет данных!`
    let onLook = true;
    let date;
    let arrTime;
    let time;
    let timer;
    let timeEnd;
    let objTable;
    let objLot;
    let objChange;
    let newObjChange = {};

    try {
        return (async () => {
            if (id) {
                // console.log(`ID::::: `, id); // test
                objLot = await Server.getDataFromServer(id, 'trade');
                // console.log(1); // test
                objTable = await Server.getDataFromServer(id, 'trade_table');
                // console.log(2); // test
                objChange = await Server.getDataFromServer(`${id}`, 'trade_table_new');
                // console.log(3); // test

                // todo: поменять порядок ключей участников в объект objChange согласно итоговой цене лота
                // определение стоимости лота у каждого участника
                for (let key in objChange) {
                    const result = objChange[key].tradePosition.map(data => {
                        let quantity = data.dataComm.find(item => item.id === '6')
                        let price = data.dataComm.find(item => item.id === '10')

                        // console.log(`quantity::::::: ${key} :: `, quantity); // test

                        if (!quantity) {
                            console.log(`quantity NaN`)
                            quantity = { id: "6", description: "Количество штук", value: 0 }
                            console.log(`quantity new >>> `, quantity);
                        }
                        if (!price) {
                            console.log(`price NaN`)
                            price = { id: "10", description: "НМЦ позиции", value: 0 }
                            console.log(`price new >>>> `, price);
                        }

                        return (
                            Number(quantity.value) * Number(price.value)
                        )
                    })

                    let total = 0;
                    result.forEach(itm => {
                        total = itm + total
                    })
                    Object.assign(objChange[key], { 'total': total })
                    Object.assign(objTable[key], { 'total': total })
                }
                // console.log(`objTable >>>> `, objTable); // test

                // преобразуем объект в массив
                let newArr = []
                for (let key in objChange) {
                    newArr.push(objChange[key])
                }

                // сортируем массив
                newArr.sort((prev, next) => prev.total - next.total)

                // определение даты и времени конца торгов
                if (objLot) {
                    date = Date.parse(objLot.data.dateTrade) // дата
                    arrTime = (objLot.data.timeTrade.split(':'))
                    time = (Number(arrTime[0]) * 60 * 60 * 1000) + (Number(arrTime[1]) * 60 * 1000)
                    timer = Number(objLot.data.timer) * 60 * 1000
                    timeEnd = date + time + timer
                }

                if (timeEnd > Date.now()) onLook = false;

                if (!objTable) {
                    return renderNothing;
                }

                if (!objLot) {
                    return
                }

                if (!objChange) {
                    objChange = false
                }

                return { objTable, objLot, objChange, onLook };

            } else {
                return { objTable, objLot, objChange, onLook };
            }

        })()

    } catch (err) {
        console.log(`Ошибка в файле (loadTradeTable.js): `, err);
    }




}