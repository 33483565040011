import React from 'react';
import Navigation from './decoration/Navigation';
import LoginPage from './LoginPage';
import Registration from './Registration';
import Server from './server/server';
import ModalLoad from './ModalLoad';
import PersonalArea from './PersonalArea';
import CheckINN from './CheckINN';
// import CheckINNWarning from './CheckINNWarning';
import ModalError from './ModalError';


export default class Block extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show_LoginPage: true,
            show_Registration: false,
            showNavigation: false,
            showPersonalArea: false,
            showCheckINN: false,
            showFooter: false,
            show_errorRequest: false, // ошибка AUTH
            questionnaireURL: false, // переход прямо в анкету
            chatMessage: '', // отслеживаем наличие сообщений в чате
            innCompany: '',
            data_Main: [],
            loginData: {
                idCompany: [],
                surname: '',
                name: '',
                patronage: '',
                gender: '',
                phone: '',
                login: '',
                timezone: '',
                key: { '12': 'zero' },
            },
            isFooterLong: false,
            lengthDataFromServer: 0,
            lengthDataFromServer_Main: 0,
            infoBlock: {
                language: 'rus',
                cardCompany: [], //карточка предприятия
                mineNumberTrade: [], // имена своих торгов
                otherNumberTrade: [], // имена чужих торгов на которые есть приглашения
                classQuestBlock: ['btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form', 'btn_form'],
                nameQuestBlock: ['Main', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Zero', 'Production', 'FormBendPipe', 'FormBottoms', 'FormLensCompensators', 'FormFlanges', 'FormForgings', 'FormBends', 'FormHardware', 'FormRolling', 'FormProductColoration', 'FormBendingCuttingMetal', 'FormHeatTreatment', 'FormWelding', 'FormRolled', 'FormPipesSupply', 'FormSheetMetalSupply', 'EconomicData']
            }
        }

        this.doChangeShowLoginPage = this.doChangeShowLoginPage.bind(this);
        this.doChangeShowRegistration = this.doChangeShowRegistration.bind(this);
        this.getDataFromServer = this.getDataFromServer.bind(this);
        this.writeParseData = this.writeParseData.bind(this);
        this.doLoadStart = this.doLoadStart.bind(this);
        this.getInfoBlockfromServer = this.getInfoBlockfromServer.bind(this);
        this.doUpInfoBlock = this.doUpInfoBlock.bind(this);
        this.doUpInfoBlockTrade = this.doUpInfoBlockTrade.bind(this);
        this.lookLoad = this.lookLoad.bind(this);
        this.doStartPersonalArea = this.doStartPersonalArea.bind(this);
        this.doStartPersonalAreaMenu = this.doStartPersonalAreaMenu.bind(this);
        this.doUpdateRegistration = this.doUpdateRegistration.bind(this);
        this.doCreateNewCompany = this.doCreateNewCompany.bind(this);
        this.doSendINN = this.doSendINN.bind(this);
        this.closePersonalArea = this.closePersonalArea.bind(this);
        this.parserMainForWriteINN = this.parserMainForWriteINN.bind(this);
        this.getMessageChat = this.getMessageChat.bind(this);
        this.updateQuantityMessage = this.updateQuantityMessage.bind(this);
        this.setFooterSize = this.setFooterSize.bind(this);
        this.toggleCheckInnModal = this.toggleCheckInnModal.bind(this)

        this.handleCloseSession = this.handleCloseSession.bind(this);
    }

    // отслеживаем закрытие окна
    async handleCloseSession() {
        await Server.doCloseSession()

    }

    setFooterSize() {
        this.setState({
            isFooterLong: !this.state.isFooterLong
        })
    }

    //обновляем количество новых сообщений
    updateQuantityMessage() {
        (async () => {
            await this.getMessageChat();
        })()
    }

    // получаем массив объектов не просмотренных сообщений
    async getMessageChat() {
        try {
            const resultChat = await Server.getDataFromServer(localStorage.getItem('idUser'), 'chat_message');

            if (resultChat.ERROR) throw new Error(resultChat.message);

            const messageFalse = resultChat.filter(item => item.lookStatus === false)
            this.setState({ chatMessage: messageFalse });
            // console.log(`BLOCK, chat message::::: `, messageFalse);

        } catch (err) {
            console.log(`Ошибка получения массива полученных сообщений: `, err);
        }

    }

    //* change INN company. Вносим ИНН и новый KEY
    async doSendINN(inn) {
        let data = this.state.loginData;

        if (!data.idCompany.includes(inn)) {
            data.idCompany = [...data.idCompany, inn]
        }

        if (!data.key) {
            Object.assign(data, { key: {} })
        }

        Object.assign(data.key, { [inn]: 'zero' })

        await this.setState({
            showCheckINN: false,
            innCompany: inn,
            loginData: data
        })
        setTimeout(() => {
            let dataUpdate = this.state.loginData;
            // console.log(dataUpdate);//test
            this.doUpdateRegistration(dataUpdate);
            localStorage.setItem('idUser', inn);


            //todo внести ИНН в форму 'Main'

            new Promise(resolve => {
                resolve(
                    this.parserMainForWriteINN(inn)
                )
            }).then(result => {

                if (result.ERROR) {
                    throw new Error(result.message)
                }
                console.log(`Результат созадиня Main с вносом ИНН: ${result}`);
                this.doLoadStart((localStorage.getItem('idUser')));//todo запустить после записи ИНН в 'Main'
            }).catch(err => {
                console.log(`ERROR, внесение ИНН в форму 'Main': ${err}`);
                // console.log(err);
            })

        }, 1000)

        // запуск новой компании
        setTimeout(() => {
            console.log(`INN:::: `, localStorage.getItem('idUser')) // test
            this.doLoadStart(localStorage.getItem('idUser'))
        }, 1500)


    }

    //*Парсим Main, для внесения ИНН
    parserMainForWriteINN(inn) {
        let dataNew = [];

        for (let i = 0; i <= 123; i++) {
            if (i === 6) {
                dataNew[i] = { id: '6', fid: 'Main_6', description: 'Предприятие', information: 'ИНН', value: String(inn) }
            } else {
                dataNew[i] = { id: '', fid: '', description: '', information: '', value: '' }
            }
        }

        // console.log(`PARSE MAIN BLOCK ====> `, dataNew); //test

        new Promise(resolve => {
            resolve(
                // Server.createDataOnServer(JSON.stringify(dataNew), 'Main', inn)
                Server.createDataOnServer(dataNew, 'Main', inn)
                // Server.createDataOnServer(dataNew, 'Main', inn)
            )
        }).then(result => {

            if (result.ERROR) {
                throw new Error(result.message)
            }
            // console.log(`Создана форма Main с полем ИНН: ${result}`);
            return 'ok'
        }).catch(err => {
            console.log(`ERROR, Ошибка при создании формы Main с полем ИНН: ${err}`);
            console.log(err);

            return 'error'
        })
    }

    //* CREATE new Company
    doCreateNewCompany() {
        // console.log('CREATE NEW COMPANY, Block component...');//test
        this.setState({ showPersonalArea: false, showCheckINN: true })

    }

    toggleCheckInnModal() {
        this.setState({
            showCheckINN: !this.state.showCheckINN
        })
    }

    //*UPDATE регистрационных данных, личный кабинет
    doUpdateRegistration(data) {
        this.setState({ loginData: data });
        new Promise((resolve) => {
            resolve(Server.updateDataOnServerRegistration(data))
        }).then((result) => {

            if (result.ERROR) {
                throw new Error(result.message)
            }
            // console.log('RESULT: ', result);
        }).catch((err) => {
            console.log(`ERROR, UPDATE registration data: ${err}`);
            // console.log(err);
        })
    }

    //*запуск формы личного кабинета полсе успешного входа
    async doStartPersonalArea(loginData) {
        console.log(`ВХОД через LOGIN`); // test PersonalArea

        if (loginData.idCompany.length === 1) {
            console.log(`One COMPANY`); // test
            await this.setState({ show_LoginPage: false, loginData: loginData })
            const id = loginData.idCompany[0];
            // console.log(`DATA for START infoblock: (idCompany: ${id})`);
            localStorage.setItem('idUser', String(id))
            document.cookie = `inn=${String(id)}; max-age=86400`;
            // document.cookie = `inn=${String(id)}; max-age=300`;


            await this.doLoadStart(id) // ! start next

        } else {
            console.log('MANY COMPANY'); // test
            await this.setState({ show_LoginPage: false, loginData: loginData })
            await this.setState({ showPersonalArea: true })
        }

    }

    //*запуск формы личного кабинета полсе успешного входа
    async doStartPersonalAreaMenu(loginData) {
        console.log(`ВХОД через МЕНЮ`); // test PersonalArea

        await this.setState({ show_LoginPage: false, loginData: loginData })
        await this.setState({ showPersonalArea: true })
    }

    //* форма загрузки
    lookLoad(time) {
        this.setState({ showLook: true });
        setTimeout(() => {
            this.setState({ showLook: false })
        }, time);
    }


    //*получили обновленный InfoBlock (TRADE) *** универсалшьная функ. для обновленя infoBlock
    doUpInfoBlockTrade(data) {
        this.setState({ infoBlock: data });

        //todo обновить infoBlock на сервере
        setTimeout(() => {
            // console.log(this.state.infoBlock);//test
            this.sendInfoBlockOnServer(this.state.infoBlock, 'start', (localStorage.getItem('idUser')))
            // console.log(this.state); // test
        })
    }

    //*получили обновленный InfoBlock (classQuestBlock)
    doUpInfoBlock(data) {
        this.dataQuest = this.state.infoBlock;
        this.dataQuest.classQuestBlock = data.classQuestBlock;

        this.setState({ infoBlock: this.dataQuest });
        setTimeout(() => {
            // console.log(`change classQuestBlock::: `, this.state.infoBlock);//test
            this.sendInfoBlockOnServer(this.state.infoBlock, 'start', (localStorage.getItem('idUser')))
        })

    }

    //* проверка наличия, создание, загрузка инфоблока
    async doLoadStart(id) {
        // console.log(`doLoadStart:::::: `, id); // test
        await this.setState({
            showPersonalArea: false,
            showNavigation: false,
            showFooter: false
        });
        await this.getInfoBlockfromServer('start', id);
        await this.getMessageChat();

        await Server.deleteDocument('product');
        await Server.deleteDocument('companiesArrayFromServer');
        await Server.deleteDocument('productionArray');

        // * запустим еще раз с задержкой, что бы изменения infoBlock задействовать
        setTimeout(() => {
            this.getInfoBlockfromServer('start', id);

            if (localStorage.getItem('first') === 'true' || localStorage.getItem('first') === true) {
                console.log(`Start FIRST in Block!!!`); // test
                this.setState({
                    showNavigation: true,
                    questionnaireURL: true,
                    showFooter: true
                })
            }

            // console.log(`START:::::: `, id);
        }, 500)

        // this.setState({ showNavigation: true })
        /**
         * let runIntervalInfoBlock = setInterval(() => { console.log(`mSec >> `, Date.now()); }, 5000) //  
         * Необходимо ввести для отслеживания изменения infoBlock, а так же проверки состояния торгов
         * отключение таймера проихводить по выходу из системы. Интервал опроса сделать примерно около 60000 мс
         * Block -> Navigation -> RightMenuBlock -> NaviBar -> handleClickExit - отключение setInterval произвести тут
         */
    }

    closePersonalArea() {
        this.setState({ showPersonalArea: false })
    }

    //* ЗАГРУЖАЕМ с сервера ИНФОБЛОК
    async getInfoBlockfromServer(name, id) {
        this.lookLoad(1750);

        //* Преобразуем строку с запятыми в массив 
        //* Сбрасываем вход до ввода логина и пароля
        if (id && id.includes(',')) {
            this.setState({
                show_LoginPage: true,
                show_Registration: false,
                showNavigation: false,
                showPersonalArea: false,
                showCheckINN: false,
                showFooter: false,
                questionnaireURL: false
            })
            localStorage.removeItem('idUser')
        }

        new Promise((resolve) => {
            resolve(Server.getDataFromServer(name, id)); // idDocument, collection

        }).then((result) => {

            if (result.ERROR) {
                throw new Error(result.message)
            }
            // console.log(result); // test
            this.setState({ lengthDataFromServer: result.classQuestBlock.length });
            this.setState({ infoBlock: result });

            setTimeout(() => {
                this.setState({ showNavigation: true, showFooter: true });
                this.getDataFromServer('Main', (localStorage.getItem('idUser'))); //получаем данные компании
            }, 1000)
            // setTimeout(() => { console.log(this.state) }, 1500)//test

        }).catch((err) => {
            this.setState({ lengthDataFromServer: 0 });

            if (Server.errorRequest()) {
                console.log(`LOOK in BLOCK <<<<<<<<<<<<<<<<<<<<<<<<<<<<< error request`); // test
                this.setState({ show_errorRequest: true })
                setTimeout(() => { this.setState({ show_errorRequest: false }) }, 3000)
            }

            // создаем infoBlock, если его нет на сервере
            //*: Отсечь создание инфоблока для массива из localStroage
            if (localStorage.getItem('idUser') && !localStorage.getItem('idUser').includes(',')) {
                this.createInfoBlockOnServer(this.state.infoBlock, 'start', localStorage.getItem('idUser'));
                console.log('CREATE  INFOBLOCK !!!!!!!!!!!!!!!:::: ', localStorage.getItem('idUser'));//test
            }
            console.log(err);

        })
    }

    //* СОЗДАЕМ на сервере ИНФОБЛОК
    async createInfoBlockOnServer(data, name, id) {

        // console.log('CREATE INFOBLOCK ');//test
        // console.log(name + ' . ' + id + ' <<< BLOCK');
        // console.log(data);
        new Promise((resolve) => {
            // resolve(Server.createDataOnServer(JSON.stringify(data), name, id))
            resolve(Server.createDataOnServer(data, name, id))
        }).then(result => {

            if (result.ERROR) {
                throw new Error(result.message)
            }
            // console.log('SERVER created Info Block, all OK');
            // console.log(result);
        }).catch(err => {
            console.log('SERVER ERROR, not create info block. ERROR: ');
            // console.log(err);
        })
    }

    //* ОБНОВЛЯЕМ на сервере ИНФОБЛОК
    async sendInfoBlockOnServer(data, name, id) {
        // console.log('UPDATE INFOBLOCK');//test
        // console.log(store.getState());
        new Promise((resolve) => {
            // resolve(Server.sendDataOnServer(JSON.stringify(data), name, id))
            resolve(Server.sendDataOnServer(data, name, id))
        }).then(result => {

            if (result.ERROR) {
                throw new Error(result.message)
            }
            // console.log('SERVER update Info Block, all OK');
            // console.log(result); // test
        }).catch(err => {
            console.log('ERROR! Server not update Info Block. ERROR: ');
            // console.log(err);
        })
    }

    //* получаем данные с сервера 
    async getDataFromServer(name, id) {
        this.lookLoad(1750);

        new Promise((resolve) => {
            resolve(Server.getDataFromServer(name, id))
        }).then((result) => {

            if (result.ERROR) {
                throw new Error(result.message)
            }
            this.writeParseData(result, name);
            this.setState({ lengthDataFromServer: result.length });
        }).catch((err) => {
            this.setState({ lengthDataFromServer: 0 });
            console.error(err);
        })
        // console.log('LOAD DATA FROM SERVER');//test
    }

    //*парсинг данных с сервера
    writeParseData(dataJson, name) {
        let dataNew = [];
        dataJson.forEach((data) => {
            dataNew = [...dataNew, { id: data.id, fid: data.fid, description: data.description, information: data.information, value: data.value }]
        })
        if (name === 'Main') {
            this.card = this.state.infoBlock;
            this.card.cardCompany = dataNew;
            this.setState({ infoBlock: this.card, lengthDataFromServer_Main: dataNew.length });
        }
    }

    doChangeShowRegistration() {
        this.setState({ show_Registration: !this.state.show_Registration })
    }

    /**
     * Рестарт приложения UEMS, если уже зашел
     * @param {*} result 
     */
    doChangeShowLoginPage(result) {
        this.setState({ show_LoginPage: !this.state.show_LoginPage })
        this.getDataFromServer('Main', (localStorage.getItem('idUser'))); //получаем название компании

        if (result === true || result === false) {
            this.setState({ show_LoginPage: result })
            // this.getDataFromServer('Main', (localStorage.getItem('idUser')))
        }
    }

    // /**
    //  * Возвращает cookie с указанным ключем
    //  * @param {*} name 
    //  * @returns 
    //  */
    // getCookie(name) {
    //     let matches = document.cookie.match(new RegExp(
    //         "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    //     ));
    //     return matches ? decodeURIComponent(matches[1]) : undefined;
    // }

    async componentDidMount() {
        if (localStorage.getItem('idUser')) {
            this.doLoadStart((localStorage.getItem('idUser')));
        }
        localStorage.removeItem('idAlien')

        window.addEventListener('unload', this.handleCloseSession) // отслеживаем закрытие окна
    }

    componentWillUnmount() {
        window.removeEventListener('unload', this.handleCloseSession)
    }

    render() {

        const showNavigation = this.state.showNavigation;
        const cardCompany = this.state.infoBlock.cardCompany;
        const showPersonalArea = this.state.showPersonalArea;
        const showCheckINN = this.state.showCheckINN;

        // console.log(sessionStorage.getItem('login'));


        return (
            <>
                {this.state.show_errorRequest ?
                    <ModalError />
                    : ''
                }
                {this.state.showLook ?
                    <ModalLoad
                        stopLooking={1500}
                    />
                    : ''}
                {this.state.show_LoginPage ?
                    <LoginPage
                        onChangeShowLoginPage={this.doChangeShowLoginPage}
                        onChangeShowRegistration={this.doChangeShowRegistration}
                        onStartPersonalArea={this.doStartPersonalArea}
                        onLoadStart={this.doLoadStart}// запуск выбранного предприятия.
                    /> : ''}

                {this.state.show_Registration ?
                    <Registration
                        onChangeShowLoginPage={this.doChangeShowLoginPage}
                        onChangeShowRegistration={this.doChangeShowRegistration}
                    /> : ''}
                {showPersonalArea ?
                    <PersonalArea
                        closePersonalArea={this.closePersonalArea} // close form
                        loginData={this.state.loginData} // registration data
                        onReturnIdCompany={this.doLoadStart} // start infoBlock, need ID company
                        onUpdateRegistration={this.doUpdateRegistration}
                        onCreateNewCompany={this.doCreateNewCompany}
                    />
                    :
                    ''
                }
                {showCheckINN ?
                    <CheckINN
                        onSendINN={this.doSendINN}
                        toggleCheckInnModal={this.toggleCheckInnModal}
                    />
                    :
                    ''
                }

                {showNavigation ?
                    <Navigation
                        nameCompany={cardCompany && cardCompany[1]}
                        infoBlock={this.state.infoBlock}
                        onUpInfoBlock={this.doUpInfoBlock}
                        // onCreateTrade={this.doCreateTrade}
                        // keyNameTrade={this.state.infoBlock.mineNumberTrade}
                        // keyOtherNumberTrade={this.state.infoBlock.otherNumberTrade}
                        onStartPersonalArea={this.doStartPersonalAreaMenu}
                        questionnaireURL={this.state.questionnaireURL}
                        chatMessage={this.state.chatMessage}
                        updateQuantityMessage={this.updateQuantityMessage}
                        setFooterSize={this.setFooterSize}
                    // loginData={this.state.loginData}
                    />
                    : ''
                }


            </>
        )
    }
}

