import React, { useState, useEffect } from 'react'
import Server from '../server/server'
import GeneralForm from '../assets/GeneralForm'
import sthe from '../assets/STHEbase.json'
import tender from '../assets/TenderBase.json'
import { renderObject } from './CommonObjects'
import ModalError from '../ModalError'

export default function CreatePurchaseOrder() {
  // renderObject
  // const renderObject = [
  //   { display: 'Уникальный нoмер', key: 'STHEid' },
  //   { display: 'Нoмер конкурса', key: 'tenderId' },

  //   { display: 'Название торговой позиции', key: 'tradePositionName' }, //2
  //   { display: 'Количество изделий в торговой позиции', key: 'numberOfItemsInTradePosition' }, //3
  //   { display: 'Стоимость (цена) торговой позиции', key: 'priceOfTradePosition' }, //4

  //   { display: 'Дата формирования', key: 'dateOfCreation' }, //5
  //   { display: 'Заказчик', key: 'customer' }, //6
  //   { display: 'Ответственный от заказчика', key: 'customerRepresentetive' }, //7
  //   { display: 'Изготовитель', key: 'manufacturer' }, //8
  //   { display: 'Ответственный от изготовителя', key: 'manufacturerRepresentetive' }, //9
  //   { display: 'Наименование аппарата', key: 'equipmentName' },
  //   { display: 'Количество, шт', key: 'quantity' },
  //   { display: 'Сумма заказа, без НДС', key: 'orderPrice' },
  //   { display: 'Основание для запуска в производство', key: 'launchReasoning' },
  //   {
  //     display: 'Дата (основания)',
  //     key: 'generalDate'
  //   },
  //   { display: 'Дата начала исчисления срока изготовления', key: 'commencmentDate' },
  //   { display: 'Дата поставки в адрес Заказчика по договору', key: 'deliveryDate' },
  //   { display: 'Желаемая дата получения оборудования Заказчиком', key: 'preferableReceivingDate' },
  //   { display: 'Вид конструкторской документации (КД)', key: 'CDType' },
  //   { display: 'Изготовитель КД', key: 'CDManufacturer' },
  //   { display: 'Ответственный за КД', key: 'CDRepresentetive' },
  //   { display: 'Дата предоставления КД ', key: 'CDProvidingDate' },
  //   { display: 'Наименование вида аппарата', key: 'equipmentTypename' },

  //   { display: 'Наименование аппарата по ТУ', key: 'TUEquipmentName' },
  //   { display: 'Рабочая среда Трубное', key: 'pipeWorkEnvironment' },
  //   { display: 'Маркировка Трубное', key: 'pipeMarking' },
  //   { display: 'Условное давление, МПа Трубное', key: 'pipePressure' },
  //   { display: 'Количество ходов, шт Трубное', key: 'quantytyOfPipeMethods' },
  //   { display: 'Рабочая среда Межтрубное', key: 'interPipeWorkEnvironment' },
  //   { display: 'Маркировка Межтрубное', key: 'interPipeMarking' },
  //   { display: 'Условное давление, МПа Межтрубное', key: 'interPipePressure' },
  //   { display: 'Количество ходов, шт Межтрубное', key: 'quantytyOfInterPipeMethods' },
  //   { display: 'Диаметр аппарата, мм', key: 'equipmentDiametr' },
  //   { display: 'Тип аппарата по ТУ', key: 'TUEquipmentType' },
  //   { display: 'Материальное исполнение аппарата', key: 'physicalImplementation' },
  //   { display: 'Материал теплообменных труб', key: 'pipeMaterial' },
  //   { display: 'Диаметр теплообменных труб, мм', key: 'pipeDiametr' },
  //   { display: 'Толщина теплообменных труб, мм', key: 'pipeThickness' },
  //   { display: 'Длина теплообменных труб, м', key: 'pipeLength' },
  //   { display: 'Количество корпусов, шт', key: 'quantityOfBlocks' }
  // ]

  // 'consumerEnterpriseName',"Наименование предприятия потребителя",
  // -'consumerResponsibleParty',  "Ответственный со стороны потребителя",
  // 'installationName',  "Наименование установки",
  // 'technologicalPositionNumber',"Номер технологической позиции",
  // 'registrationNumberInDatabase', "Регистрационный номер в базе",
  // 'equipmentName',"Наименование оборудования",
  // 'manufacturerName',  "Завод-изготовитель",
  // 'deviceSerialNumber',  "Заводской номер аппарата",
  // 'supplierEnterpriseName',"Предприятие поставщик (если отличается от пункта 7)",
  // -'supplierResponsibleParty',"Ответственный со стороны поставщика",
  // 'tubeEnvironmentName',  "Наименование рабочей стреды в трубном пространстве",
  // 'intertubeEnvironmentName',"Наименование рабочей среды в межтрубном пространстве",
  // 'tubeWorkingPressure',"Рабочее давление в трубном пространстве",
  // 'intertubeWorkingPressure', "Рабочее давление в межтрубном пространстве"

  const orderDataParams = {
    STHEid: '',
    tenderId: '',

    tradePositionName: '',
    numberOfItemsInTradePosition: '',
    priceOfTradePosition: '',

    dateOfCreation: '',
    customer: '',
    customerRepresentetive: '',
    manufacturer: '',
    manufacturerRepresentetive: '',
    equipmentName: '',
    quantity: '',
    orderPrice: '',
    launchReasoning: '',
    commencmentDate: '',
    deliveryDate: '',
    preferableReceivingDate: '',
    CDType: '',
    CDManufacturer: '',
    CDRepresentetive: '',
    CDProvidingDate: '',
    equipmentTypename: '',

    TUEquipmentName: '',
    pipeWorkEnvironment: '',
    pipeMarking: '',
    pipePressure: '',
    quantytyOfPipeMethods: '',
    interPipeWorkEnvironment: '',
    interPipeMarking: '',
    interPipePressure: '',
    quantytyOfInterPipeMethods: '',
    equipmentDiametr: '',
    TUEquipmentType: '',
    physicalImplementation: '',
    pipeMaterial: '',
    pipeDiametr: '',
    pipeThickness: '',
    pipeLength: '',
    quantityOfBlocks: ''
  }

  useEffect(() => {
    ; (async () => {
      try {
        // *: загрузка данных по торгам
        const allTrade = await Server.getTradeData()
        setTradeNew(allTrade)
        console.log(`ALL TARDE:::: `, allTrade) // test
      } catch (err) {
        if (Server.errorRequest()) {
          console.log(`ERROR AUTH -> CreatePurchaseOrder`);
          setShow_errorRequest(true)
          setTimeout(() => { setShow_errorRequest(false) }, 3000)
        }

        console.log(`Ошибка получения данных о номерах торгов`)
      }
    })()
  }, [])

  useEffect(() => {
    ; (async () => {
      try {
        const result = await Server.searchAllNameCompany()
        // console.log(`result >>>>> `, result) // test
        setCompaniesArray(result)
      } catch (err) {
        if (Server.errorRequest()) {
          console.log(`ERROR AUTH -> CreatePurchaseOrder`);
          setShow_errorRequest(true)
          setTimeout(() => { setShow_errorRequest(false) }, 3000)
        }

        console.log(
          `Ошибка при получении названий всех компаний в базе (ContentAddNewEquipment.jsx): `,
          err
        )
      }
    })()
  }, [])

  const [companiesArray, setCompaniesArray] = useState([])
  const [orderData, setOrderData] = useState({})
  const [showTechParamsModal, setShowTechParamsModal] = useState(false)
  const [tradeNew, setTradeNew] = useState()
  const [orderNumber, setOrderNumber] = useState('') // todo: Номер заказ-наряда
  const [show_errorRequest, setShow_errorRequest] = useState(false); // error AUTH

  /**
   * Сохранение заказ-наряда
   * @param {*} data
   */
  const getOrderData = (data) => {
    (async () => {
      try {
        setOrderData(data)
        console.log(`DATA ORDER:::: `, data); // test
        // *: Сохранение заказ-наряда
        const objPosition = await data?.tradePosition.find(item => item.namePosition === data.tradePositionName)
        const id = `${data.idLot}---${objPosition.idPosition}` //* id заказ-наряда
        Object.assign(data, {
          tradePositionId: objPosition.idPosition,
          orderNumber: orderNumber
        })

        //todo: Внедрить в объект уникальный номер заказ-наряда

        const result = await Server.sendDataOnServer(data, id, 'order')
        console.log(`RESULT SAVE ORDER: `, result); // test

      } catch (err) {
        if (Server.errorRequest()) {
          console.log(`ERROR AUTH -> CreatePurchaseOrder`);
          setShow_errorRequest(true)
          setTimeout(() => { setShow_errorRequest(false) }, 3000)
        }

        console.log(`Ошибка сохранения заказ-наряда: `, err);
      }
    })()
  }

  const doOrderNumber = (obj) => {
    console.log(`DATA ORDER NUMBER:::: `, obj); // test
    (async () => {
      try {
        const result = await Server.doOrderNumber(obj)
        console.log(`NUMBER >>>> order number::: `, result);
        setOrderNumber(result)

      } catch (err) {
        if (Server.errorRequest()) {
          console.log(`ERROR AUTH -> CreatePurchaseOrder`);
          setShow_errorRequest(true)
          setTimeout(() => { setShow_errorRequest(false) }, 3000)
        }

        console.log(`Ошибка при формировании номера заказ-наряда: `, err);
      }
    })()

  }

  return (
    <>
      {show_errorRequest ?
        <ModalError />
        : ''
      }
      <div style={{ padding: '80px 150px 20px 150px' }}>
        <GeneralForm
          renderedArray={renderObject}
          selectData={[
            { select: companiesArray, indexes: [6, 8] },
            { select: tradeNew, indexes: [7, 9] },
            { select: tradeNew, indexes: [2] },
            {
              select: [
                { id: '', nameCompany: 'Гарантийное письмо', ownership: '' },
                { id: '', nameCompany: 'Подписаный договор', ownership: '' },
                { id: '', nameCompany: 'Полученный аванс', ownership: '' }
              ],
              indexes: [13]
            },
            {
              select: [
                { id: '', nameCompany: 'Эскизное решение', ownership: '' },
                { id: '', nameCompany: 'Техпроект', ownership: '' },
                { id: '', nameCompany: 'РКД', ownership: '' }
              ],
              indexes: [18]
            }
          ]}
          selectIndexes={[2, 6, 7, 8, 9, 13, 18]}
          buttons={['saveCurrentData']}
          object={orderDataParams}
          headingText='Создать заказ-наряд'
          sendData={getOrderData}
          toggleTechParamsModal={() => setShowTechParamsModal(!showTechParamsModal)}
          defaultBtnText='Сформировать'
          savedBtnText='Заказ-наряд сформирован'
          dividingIndex={20}
          mainFields={['STHEid', 'tenderId']}
          jsonDataSTHE={sthe}
          jsonDataTender={tradeNew}
          orderNumber={orderNumber}
          doOrderNumber={doOrderNumber}
        />
      </div>
    </>
  )
}
