
/**
 * Список позиций для карточки аппарата
 */
export const ITEM_LIST = [
    'Уникальный нoмер',
    "Наименование предприятия потребителя",
    "Ответственный со стороны потребителя",
    "Наименование установки",
    "Номер технологической позиции",
    "Регистрационный номер в базе",
    "Наименование оборудования",
    "Завод-изготовитель",
    "Заводской номер аппарата",
    "Предприятие поставщик (если отличается от пункта 7)",
    "Ответственный со стороны поставщика",
    "Наименование рабочей стреды в трубном пространстве",
    "Наименование рабочей среды в межтрубном пространстве",
    "Рабочее давление в трубном пространстве",
    "Рабочее давление в межтрубном пространстве"
]

/**
 * Наименование ключей позиций для карточки аппарата
 * соответсвие с ITEM_LIST по индексам
 */
export const ITEM_LIST_KEY = [
    'STHEid',
    'consumerEnterpriseName',
    'consumerResponsibleParty',
    'installationName',
    'technologicalPositionNumber',
    'registrationNumberInDatabase',
    'equipmentName',
    'manufacturerName',
    'deviceSerialNumber',
    'supplierEnterpriseName',
    'supplierResponsibleParty',
    'tubeEnvironmentName',
    'intertubeEnvironmentName',
    'tubeWorkingPressure',
    'intertubeWorkingPressure'
]

/**
 * Объект для заполнения: Карточка аппарата,
 * Название ключеи из ITEM_LIST_KEY
 */
export const CARD_EQUIPMENT = {
    STHEid: '',
    consumerEnterpriseName: '',
    consumerResponsibleParty: '',
    installationName: '',
    technologicalPositionNumber: '',
    registrationNumberInDatabase: '',
    equipmentName: '',
    manufacturerName: '',
    deviceSerialNumber: '',
    supplierEnterpriseName: '',
    supplierResponsibleParty: '',
    tubeEnvironmentName: '',
    intertubeEnvironmentName: '',
    tubeWorkingPressure: '',
    intertubeWorkingPressure: ''
}

// Документация

export const FILES_CALCULATION = [
    'Теплогидравлический расчет',
    'Эскиз аппарата',
    'Ведомость материалов'
]

export const FILES_CALCULATION_KEY = [
    'calculationThermalConductivity',
    'apparatusSketch',
    'sheetMaterial'
]

export const FILES_ENGINEERING = [
    'Чертеж общего вида',
    'Сборочные чертежи основных узлов, с чертежами деталей',
    'Таблица контроля качества основного металла ТБ1',
    'Таблица контроля качества сварных соединений ТБ2',
    'Эскиз швов ЭШ(приложение к паспорту)',
    'Эскиз измерений ЭИ(приложение к паспорту)',
    'Прочностной расчет конструкции(с отчетом)',
    'Руководство по монтажу и эксплуатации',
    '3D модель аппарата (в формате: .stp, .step, .stl, .igs, .iges)',
    'Документация в электронном виде (CAD (Компас или TFlex) файлы модели + PDF)',
]

export const FILES_ENGINEERING_KEY = [
    'drawingGeneralView',
    'assemblyDrawings',
    'qualityControlTableMainMetalTB1',
    'qualityControlTableWeldedJointsTB2',
    'weldsSketchESh',
    'measurementsSketchEI',
    'structuralStrengthCalculation',
    'assemblyAndOperationManual',
    '3DModelOfTheEquipment',
    'ElectronicDocumentation'
]

export const FILES_PRODUCTION = [
    'Отчет о выборе завода изготовителя на конкурсной основе',
    'Отчет преддоговорного аудита Изготовителя на предмет текущей технической, квалификационной и финансовой возможности изготовления оборудования',
    'Поузловой график производства оборудования',
    'Отчеты контроля графика изготовления',
    'Еженедельные фото - отчеты с описанием выполненных работ',
    'План качества изготовления',
    'Указать даты проведения контролей и фамилии того кто провел контроль',
    'Должна быть возможность указать замечания при контроле и получить подтверждения о исправлении замечаний',
    'Прикрепление фотографий с описанием по каждому пункту плана',
    'Акт и видео материалы о гидроиспытаниях',
    'Фото отчеты качества покраски и упаковки',
    'Сводный отчет о входном контроле материалов',
    'Сводный отчет о входном контроле комплектующих изделий',
    'Справки(отчеты) о несоответствиях',
    'Отчеты по механическим испытаниям',
    'Отчеты о визуальном и измерительном контроле',
    'Журнал сварочных работ',
    'Отчеты по ВИК, УЗК, РК, МПД и др.',
    'Отчет о проведении термической обработки',
    'Паспорт на термическое оборудование',
    'Отчеты по механическим испытаниям(по форме завода, согласованной с Покупателем)',
    'Сводный отчет о контроле сварных соединений',
    'Справки(отчеты) о несоответствиях',
    'Протокол гидравлических(пневматических) испытаний(по форме завода)',
    'Отчет о контрольной операции "металлическим калибром”',
    'Отчет измерений толщины покрытия(по форме завода)',
    'Свидетельство о консервации',
    'Акт испытания строповых устройств сосуда(аппарата) на прочность',
    'Акт проверки качества маркировки, упаковки(на окончательно изготовленный узел)',
    'Паспорт на оборудование с отметкой о приемке заводом - изготовителем или Документ о качестве изготовления поставочного узла(для узлов комплектного оборудования)',
    'Сертификаты о соответствии изготовленного оборудования и его комплектующих применимым ТР ТС(032, 010, 012, и др.), другим НТД согласно Техническим требованиям Покупателя',
    'Сопроводительная эксплуатационная документация(согласно ГОСТ 2.601 - 2013 и др.применимым НД) на оборудование и комплектующие изделия(имеющие идентификацию по KKS)',
    'Копии документов, подтверждающих контроль, испытания и качество изготовления(согласно пп.3.1 - 3.9 настоящей Таблицы)',
    'Упаковочные листы; Отгрузочная спецификация; Сертификат качества изготовителя на предъявляемую партию(товарные места); Комплектовочная ведомость; фото товарных мест',
    'План инспекционного контроля и испытаний с подписями всех сторон',
]

export const FILES_PRODUCTION_KEY = [
    'ReportSelectionManufacturer',
    'ReportPre-contractualAuditManufacturer',
    'Knot-by-nodeEquipmentProductionSchedule',
    'ProductionScheduleControlReports',
    'WeeklyPhoto-reportsDescribingWorkPerformed',
    'ProductionQualityPlan',
    'IndicateDatesControls',
    'ItShouldbePossibleRemarks',
    'AttachingPhotosWithDescriptions',
    'ActVideoMaterialsHydraulicTesting',
    'PhotoReportsQualityPaintingAndPackaging',
    'SummaryReportIncomingInspectionMaterials',
    'SummaryReportInputControlComponents',
    'CertificatesOnDiscrepancies',
    'MechanicalTestReports',
    'VisualAndMeasuringInspectionReports',
    'JournalOfWelding',
    'ReportsOn_VIC_UZK_RK_MTD',
    'HeatTreatmentReport',
    'PassportForThermalEquipment',
    'MechanicalTestReports_InTheFormOfTheFactoryAgreedWithTheBuyer',
    'SummaryReportOnTheControlOfWeldedJoints',
    'Certificates_On_Discrepancies',
    'ProtocolOfHydraulicTests',
    'ReportOnTheControlOperation_MetalGauge"',
    'CoatingThicknessMeasurementReport',
    'CertificateOfConservation',
    'TheActOfTestingTheSlingDevicesOfTheVesselForStrength',
    'TheActOfCheckingTheQualityOfMarkingPackaging',
    'PassportForEquipmentWithMarkOfAcceptanceByTheManufacturerOrDocumentOnTheQualityOfManufacturing',
    'CertificatesOfComplianceOfTheManufacturedEquipment',
    'AccompanyingOperationalDocumentation',
    'CopiesDocumentsConfirmingControlTestingAndQualityOfManufacture',
    'PackingLists_ShippingSpecification',
    'PlanOfInspectionControlAndTesting',
]

export const FILES_INSTALLATION = [
    'Акт монтажа',
    'Фотоотчет(JPG + тестовое описание)'
]

export const FILES_INSTALLATION_KEY = [
    'actInstallation',
    'photoReportInstallation'
]

export const FILES_START = [
    'Акт ввода в эксплуатацию',
    'Фотоотчет(JPG + тестовое описание)'
]

export const FILES_START_KEY = [
    'commissioningCertificate',
    'photoReportStart'
]