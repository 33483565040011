import React from "react";
import { Container } from "react-bootstrap";
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import Server from "../server/server";
// import { nanoid } from 'nanoid';
import FileModal from "../file/FileModal";
import server from "../server/server";
import { ListGroupParticipant } from "./ListGroupParticipant";

export default class FileDataParticipant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      newName: "", // назване лота или торг.позиции и др.
      id: "", // ID массива объектов инф. о файлах (нахвание, путь, примечание)
      dataFiles: [], // массив объектов инф. о файлах
      resultRender: [], // render
      indexFile: 0,
      disableDownload: true,
      isAddBtnDisabled: false,
      showFiles: true,
    };
    this.doClose = this.doClose.bind(this);
    this.handleAddNewFile = this.handleAddNewFile.bind(this);
    this.getDataFile = this.getDataFile.bind(this);
    this.doSaveFile = this.doSaveFile.bind(this);
    this.saveOnServer = this.saveOnServer.bind(this);
    this.deletePosition = this.deletePosition.bind(this);
    this.getDisableDownload = this.getDisableDownload.bind(this);
    this.disableAddButton = this.disableAddButton.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
  }

  getDisableDownload(isDisabled) {
    this.setState({
      disableDownload: isDisabled,
    });
  }

  deletePosition(inx) {
    (async () => {
      try {
        this.setState({ show: false });
        let dataD = this.state.dataFiles;

        // console.log(`DELETE POSITION: `, inx); // test

        dataD.splice(inx, 1);
        this.setState({ dataFiles: dataD });

        await this.saveOnServer();
        await this.getDataFile(this.state.id);

        this.setState({ show: true });
      } catch (err) {
        console.log(`Ошибка удаления позиции: `, err);
      }
    })();
  }

  async saveOnServer() {
    try {
      this.props.returnId(this.state.id);

      // console.log(`DATA for file::::::: `, this.state.dataFiles); // test
      // console.log(`ID FILE DATA:::: `, this.state.id); // test

      const result = await Server.sendDataOnServer(
        this.state.dataFiles,
        this.state.id,
        "trade_file"
      );

      if (result.ERROR) throw new Error(result.message);
      this.props.getFilesNames(this.state.dataFiles);

      // console.log(`RESULT DATA: `, this.state.id, this.state.dataFiles); // test
    } catch (err) {
      console.log(`Ошибка: `, err);
    }
  }

  doSaveFile(fileInf, inx) {
    (async () => {
      try {
        // console.log(`>>>>> `, fileInf, inx); // test

        let newData = this.state.dataFiles;

        newData[inx] = fileInf;
        await this.setState({ dataFiles: newData });

        await this.saveOnServer();

        this.disableAddButton(inx);

        this.getDataFile(this.props.id);
      } catch (err) {
        console.log(`Ошибка передачи данных от дочернего компонента`);
      }
    })();
  }

  disableAddButton(idx) {
    if (this.state.indexFile > idx) {
      this.setState({
        isAddBtnDisabled: false,
      });
    }
  }

  // загружаем информацию о файлах с сервера
  async getDataFile(id) {
    try {
      const result = await Server.getDataFromServer(id, "trade_file");

      if (result.ERROR) throw new Error(result.mesage);

      await this.setState({ dataFiles: result });

      const resultRender = result.map((data, index) => {
        this.setState({ indexFile: index + 1 });
        return (
          <React.Fragment key={index}>
            <FileModal
              index={index}
              oldNameFile={data.oldNameFile}
              newName={this.props.newName}
              newNameFile={data.newNameFile}
              copyNameFile={data.copyNameFile}
              pathFile={data.pathFile}
              typeFile={data.type}
              size={data.size}
              descriptionFile={data.descriptionFile}
              onSaveFile={this.doSaveFile}
              deletePosition={this.deletePosition}
              toNextTab={this.props.toNextTab}
              getDisableDownload={this.getDisableDownload}
              table={true} // при TRUE меняет верстку
            />
          </React.Fragment>
        );
      });

      this.setState({ resultRender: resultRender });
    } catch (err) {
      console.log(err);
      this.handleAddNewFile();
    }
  }

  handleAddNewFile() {
    // console.log(`Добавить новый файл`); // test
    // TODO: Добавить форму для сохранения файла
    this.setState({
      resultRender: [
        <React.Fragment key={this.state.indexFile}>
          <FileModal
            index={this.state.indexFile}
            oldNameFile={""}
            newName={this.props.newName}
            newNameFile={""}
            copyNameFile={""}
            pathFile={""}
            typeFile={""}
            size={""}
            descriptionFile={""}
            onSaveFile={this.doSaveFile}
            deletePosition={this.deletePosition}
            toNextTab={this.props.toNextTab}
            getDisableDownload={this.getDisableDownload}
            width={this.props.showNextBtn}
          />
        </React.Fragment>,
        ...this.state.resultRender,
      ],
      indexFile: this.state.indexFile + 1,
    });
    this.setState({
      isAddBtnDisabled: true,
    });
  }

  doClose() {
    // TODO: перед выходом получить подтверждение

    this.props.onClose(); // пропс для закрытия формы
  }

  async downloadFile(fileName) {
    const result = await server.readFileFromServer(fileName);

    if (result) {
      const linkBlob = window.URL.createObjectURL(result);
      const link = document.createElement("a");
      link.href = linkBlob;
      link.download = fileName.split("_").slice(-1)[0]; //! меняем имя файла при загрузке
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  async componentDidMount() {
    this.setState({
      newName: this.props.newName, // название ЛОТа или торг.позиции
    });

    // console.log(`PROPS ID >>>>>> `, this.props.id); // test

    if (this.props.id) {
      this.setState({ id: this.props.id });
      await this.getDataFile(this.props.id); // загрузка инф. о файлах
    } else {
      console.error(`ID должен приходить от родителя`);
    }

    this.saveOnServer();
  }

  render() {
    return (
      <>
        <div style={{ padding: "40px" }}>
          <ListGroupParticipant
            showRenderedArray={this.props.showFile}
            renderedArray={this.props.positionFiles}
            downloadFunction={this.downloadFile}
            document={"позиции"}
            runChat={this.props.runChat}
            organizerName={this.props.organizerName}
            name={this.props.nameTrade}
            lotNum={this.props.lotNum}
          />
        </div>
      </>
    );
  }
}
