export const codes = [
    "01",
    "01.1",
    "01.11",
    "01.11.1",
    "01.11.11",
    "01.11.12",
    "01.11.13",
    "01.11.14",
    "01.11.15",
    "01.11.16",
    "01.11.19",
    "01.11.2",
    "01.11.3",
    "01.11.31",
    "01.11.32",
    "01.11.33",
    "01.11.39",
    "01.12",
    "01.13",
    "01.13.1",
    "01.13.11",
    "01.13.12",
    "01.13.2",
    "01.13.3",
    "01.13.31",
    "01.13.39",
    "01.13.4",
    "01.13.5",
    "01.13.51",
    "01.13.52",
    "01.13.6",
    "01.13.9",
    "01.14",
    "01.15",
    "01.16",
    "01.16.1",
    "01.16.2",
    "01.16.3",
    "01.16.9",
    "01.19",
    "01.19.1",
    "01.19.2",
    "01.19.21",
    "01.19.22",
    "01.19.3",
    "01.19.9",
    "01.2",
    "01.21",
    "01.22",
    "01.23",
    "01.24",
    "01.25",
    "01.25.1",
    "01.25.2",
    "01.25.3",
    "01.26",
    "01.27",
    "01.27.1",
    "01.27.9",
    "01.28",
    "01.28.1",
    "01.28.2",
    "01.28.3",
    "01.29",
    "01.3",
    "01.30",
    "01.4",
    "01.41",
    "01.41.1",
    "01.41.11",
    "01.41.12",
    "01.41.2",
    "01.41.21",
    "01.41.29",
    "01.42",
    "01.42.1",
    "01.42.11",
    "01.42.12",
    "01.42.2",
    "01.43",
    "01.43.1",
    "01.43.2",
    "01.43.3",
    "01.44",
    "01.45",
    "01.45.1",
    "01.45.2",
    "01.45.3",
    "01.45.4",
    "01.46",
    "01.46.1",
    "01.46.11",
    "01.46.12",
    "01.46.2",
    "01.47",
    "01.47.1",
    "01.47.11",
    "01.47.12",
    "01.47.2",
    "01.47.3",
    "01.49",
    "01.49.1",
    "01.49.11",
    "01.49.12",
    "01.49.13",
    "01.49.2",
    "01.49.21",
    "01.49.22",
    "01.49.3",
    "01.49.31",
    "01.49.32",
    "01.49.4",
    "01.49.41",
    "01.49.42",
    "01.49.43",
    "01.49.44",
    "01.49.5",
    "01.49.6",
    "01.49.7",
    "01.49.9",
    "01.5",
    "01.50",
    "01.6",
    "01.61",
    "01.62",
    "01.63",
    "01.64",
    "01.7",
    "01.70",
    "02",
    "02.1",
    "02.10",
    "02.10.1",
    "02.10.11",
    "02.10.19",
    "02.10.2",
    "02.2",
    "02.20",
    "02.3",
    "02.30",
    "02.30.1",
    "02.30.11",
    "02.30.12",
    "02.30.13",
    "02.30.14",
    "02.30.2",
    "02.4",
    "02.40",
    "02.40.1",
    "02.40.2",
    "03",
    "03.1",
    "03.11",
    "03.11.1",
    "03.11.2",
    "03.11.3",
    "03.11.4",
    "03.11.5",
    "03.12",
    "03.12.1",
    "03.12.2",
    "03.12.3",
    "03.12.4",
    "03.2",
    "03.21",
    "03.21.1",
    "03.21.2",
    "03.21.3",
    "03.21.4",
    "03.21.5",
    "03.21.9",
    "03.22",
    "03.22.1",
    "03.22.2",
    "03.22.3",
    "03.22.4",
    "03.22.5",
    "03.22.6",
    "03.22.7",
    "03.22.9",
    "",
    "05",
    "05.1",
    "05.10",
    "05.10.1",
    "05.10.11",
    "05.10.12",
    "05.10.13",
    "05.10.14",
    "05.10.15",
    "05.10.16",
    "05.10.2",
    "05.10.21",
    "05.10.22",
    "05.10.23",
    "05.2",
    "05.20",
    "05.20.1",
    "05.20.11",
    "05.20.12",
    "05.20.2",
    "06",
    "06.1",
    "06.10",
    "06.10.1",
    "06.10.2",
    "06.10.3",
    "06.2",
    "06.20",
    "06.20.1",
    "06.20.2",
    "07",
    "07.1",
    "07.10",
    "07.10.1",
    "07.10.2",
    "07.10.3",
    "07.2",
    "07.21",
    "07.21.1",
    "07.21.11",
    "07.21.12",
    "07.21.2",
    "07.29",
    "07.29.1",
    "07.29.2",
    "07.29.21",
    "07.29.22",
    "07.29.3",
    "07.29.31",
    "07.29.32",
    "07.29.33",
    "07.29.4",
    "07.29.41",
    "07.29.42",
    "07.29.5",
    "07.29.6",
    "07.29.7",
    "07.29.8",
    "07.29.9",
    "07.29.91",
    "07.29.92",
    "07.29.93",
    "07.29.99",
    "08",
    "08.1",
    "08.11",
    "08.11.1",
    "08.11.2",
    "08.11.3",
    "08.11.4",
    "08.12",
    "08.12.1",
    "08.12.2",
    "08.9",
    "08.91",
    "08.92",
    "08.92.1",
    "08.92.2",
    "08.93",
    "08.99",
    "08.99.1",
    "08.99.2",
    "08.99.21",
    "08.99.22",
    "08.99.23",
    "08.99.3",
    "08.99.31",
    "08.99.32",
    "08.99.33",
    "08.99.34",
    "08.99.35",
    "08.99.36",
    "09",
    "09.1",
    "09.10",
    "09.10.1",
    "09.10.2",
    "09.10.3",
    "09.10.4",
    "09.10.9",
    "09.9",
    "09.90",
    "10",
    "10.1",
    "10.11",
    "10.11.1",
    "10.11.2",
    "10.11.3",
    "10.11.4",
    "10.11.5",
    "10.11.6",
    "10.12",
    "10.12.1",
    "10.12.2",
    "10.12.3",
    "10.12.4",
    "10.12.5",
    "10.13",
    "10.13.1",
    "10.13.2",
    "10.13.3",
    "10.13.4",
    "10.13.5",
    "10.13.6",
    "10.13.7",
    "10.13.9",
    "10.2",
    "10.20.1",
    "10.20.2",
    "10.20.3",
    "10.20.4",
    "10.20.5",
    "10.20.9",
    "10.3",
    "10.31",
    "10.32",
    "10.39",
    "10.39.1",
    "10.39.2",
    "10.39.9",
    "10.4",
    "10.41",
    "10.41.1",
    "10.41.2",
    "10.41.21",
    "10.41.22",
    "10.41.23",
    "10.41.24",
    "10.41.25",
    "10.41.26",
    "10.41.27",
    "10.41.28",
    "10.41.29",
    "10.41.3",
    "10.41.4",
    "10.41.5",
    "10.41.51",
    "10.41.52",
    "10.41.53",
    "10.41.54",
    "10.41.55",
    "10.41.56",
    "10.41.57",
    "10.41.58",
    "10.41.59",
    "10.41.6",
    "10.41.7",
    "10.42",
    "10.5",
    "10.51",
    "10.51.1",
    "10.51.2",
    "10.51.3",
    "10.51.4",
    "10.51.9",
    "10.52",
    "10.6",
    "10.61",
    "10.61.1",
    "10.61.2",
    "10.61.3",
    "10.61.4",
    "10.62",
    "10.62.1",
    "10.62.2",
    "10.62.3",
    "10.62.9",
    "10.7",
    "10.71",
    "10.71.1",
    "10.71.2",
    "10.71.3",
    "10.72",
    "10.72.1",
    "10.72.2",
    "10.72.3",
    "10.72.31",
    "10.72.32",
    "10.72.33",
    "10.72.34",
    "10.72.35",
    "10.72.39",
    "10.72.4",
    "10.73",
    "10.73.1",
    "10.73.2",
    "10.73.3",
    "10.8",
    "10.81",
    "10.81.1",
    "10.81.11",
    "10.81.12",
    "10.81.2",
    "10.81.3",
    "10.82",
    "10.82.1",
    "10.82.2",
    "10.82.3",
    "10.82.4",
    "10.82.5",
    "10.82.6",
    "10.83",
    "10.84",
    "10.85",
    "10.86",
    "10.86.1",
    "10.86.11",
    "10.86.12",
    "10.86.2",
    "10.86.3",
    "10.86.4",
    "10.86.5",
    "10.86.6",
    "10.86.61",
    "10.86.62",
    "10.86.63",
    "10.86.64",
    "10.86.69",
    "10.86.7",
    "10.86.8",
    "10.86.9",
    "10.89",
    "10.89.1",
    "10.89.2",
    "10.89.3",
    "10.89.4",
    "10.89.5",
    "10.89.6",
    "10.89.7",
    "10.89.8",
    "10.89.9",
    "10.9",
    "10.91",
    "10.91.1",
    "10.91.2",
    "10.91.3",
    "10.92",
    "11",
    "11.01",
    "11.01.1",
    "11.01.2",
    "11.01.3",
    "11.01.4",
    "11.02",
    "11.03",
    "11.04",
    "11.05",
    "11.06",
    "11.07",
    "11.07.1",
    "11.07.11",
    "11.07.12",
    "11.07.13",
    "11.07.14",
    "11.07.15",
    "11.07.2",
    "12",
    "12.00.1",
    "12.00.2",
    "12.00.3",
    "13",
    "13.1",
    "13.10.1",
    "13.10.2",
    "13.10.3",
    "13.10.4",
    "13.10.5",
    "13.10.6",
    "13.10.9",
    "13.2",
    "13.20.1",
    "13.20.11",
    "13.20.12",
    "13.20.13",
    "13.20.14",
    "13.20.19",
    "13.20.2",
    "13.20.3",
    "13.20.4",
    "13.20.41",
    "13.20.42",
    "13.20.43",
    "13.20.44",
    "13.20.45",
    "13.20.46",
    "13.20.5",
    "13.20.6",
    "13.3",
    "13.30.1",
    "13.30.2",
    "13.30.3",
    "13.30.4",
    "13.30.5",
    "13.9",
    "13.91",
    "13.91.1",
    "13.91.2",
    "13.92",
    "13.92.1",
    "13.92.2",
    "13.93",
    "13.94",
    "13.94.1",
    "13.94.2",
    "13.95",
    "13.96",
    "13.96.1",
    "13.96.2",
    "13.96.3",
    "13.96.4",
    "13.96.5",
    "13.96.6",
    "13.96.7",
    "13.99",
    "13.99.1",
    "13.99.2",
    "13.99.3",
    "13.99.4",
    "13.99.9",
    "14",
    "14.1",
    "14.11",
    "14.11.2",
    "14.11.1",
    "14.12",
    "14.12.1",
    "14.12.2",
    "14.13",
    "14.13.1",
    "14.13.11",
    "14.13.12",
    "14.13.2",
    "14.13.21",
    "14.13.22",
    "14.13.3",
    "14.14",
    "14.14.1",
    "14.14.11",
    "14.14.12",
    "14.14.13",
    "14.14.14",
    "14.14.2",
    "14.14.21",
    "14.14.22",
    "14.14.23",
    "14.14.24",
    "14.14.25",
    "14.14.3",
    "14.14.4",
    "14.19",
    "14.19.1",
    "14.19.11",
    "14.19.12",
    "14.19.13",
    "14.19.19",
    "14.19.2",
    "14.19.21",
    "14.19.22",
    "14.19.23",
    "14.19.3",
    "14.19.31",
    "14.19.32",
    "14.19.4",
    "14.19.5",
    "14.2",
    "14.20.1",
    "14.20.2",
    "14.3",
    "14.31",
    "14.31.1",
    "14.31.2",
    "14.39",
    "14.39.1",
    "14.39.2",
    "15",
    "15.1",
    "15.11",
    "15.11.1",
    "15.11.2",
    "15.11.3",
    "15.11.4",
    "15.11.5",
    "15.11.51",
    "15.11.52",
    "15.12",
    "15.2",
    "15.20.1",
    "15.20.11",
    "15.20.12",
    "15.20.13",
    "15.20.14",
    "15.20.2",
    "15.20.3",
    "15.20.31",
    "15.20.32",
    "15.20.4",
    "15.20.41",
    "15.20.42",
    "15.20.5",
    "16",
    "16.1",
    "16.10.1",
    "16.10.2",
    "16.10.3",
    "16.10.9",
    "16.2",
    "16.21",
    "16.21.1",
    "16.21.11",
    "16.21.12",
    "16.21.13",
    "16.21.2",
    "16.21.21",
    "16.21.22",
    "16.22",
    "16.23",
    "16.23.1",
    "16.23.2",
    "16.24",
    "16.29",
    "16.29.1",
    "16.29.11",
    "16.29.12",
    "16.29.13",
    "16.29.14",
    "16.29.15",
    "16.29.2",
    "16.29.21",
    "16.29.22",
    "16.29.23",
    "16.29.3",
    "17",
    "17.1",
    "17.11",
    "17.11.1",
    "17.11.2",
    "17.11.9",
    "17.12",
    "17.12.1",
    "17.12.2",
    "17.2",
    "17.21",
    "17.22",
    "17.23",
    "17.24",
    "17.29",
    "18",
    "18.1",
    "18.11",
    "18.12",
    "18.13",
    "18.14",
    "18.2",
    "19",
    "19.1",
    "19.2",
    "19.20.1",
    "19.20.2",
    "19.20.9",
    "19.3",
    "19.31",
    "19.32",
    "19.33",
    "19.34",
    "19.34.1",
    "19.34.2",
    "19.34.3",
    "20",
    "20.1",
    "20.11",
    "20.12",
    "20.13",
    "20.14",
    "20.14.1",
    "20.14.2",
    "20.14.3",
    "20.14.4",
    "20.14.5",
    "20.14.6",
    "20.14.7",
    "20.15",
    "20.15.1",
    "20.15.2",
    "20.15.3",
    "20.15.4",
    "20.15.5",
    "20.15.6",
    "20.15.7",
    "20.15.8",
    "20.16",
    "20.17",
    "20.2",
    "20.3",
    "20.30.1",
    "20.30.2",
    "20.4",
    "20.41",
    "20.41.1",
    "20.41.2",
    "20.41.3",
    "20.41.4",
    "20.42",
    "20.5",
    "20.51",
    "20.52",
    "20.53",
    "20.59",
    "20.59.1",
    "20.59.2",
    "20.59.3",
    "20.59.4",
    "20.59.5",
    "20.59.6",
    "20.6",
    "20.60.1",
    "20.60.2",
    "21",
    "21.1",
    "21.2",
    "21.20.1",
    "21.20.2",
    "22",
    "22.1",
    "22.11",
    "22.19",
    "22.19.1",
    "22.19.2",
    "22.19.3",
    "22.19.4",
    "22.19.5",
    "22.19.6",
    "22.19.7",
    "22.2",
    "22.21",
    "22.22",
    "22.23",
    "22.29",
    "22.29.1",
    "22.29.2",
    "22.29.3",
    "22.29.9",
    "23",
    "23.1",
    "23.11",
    "23.11.1",
    "23.11.2",
    "23.11.3",
    "23.11.4",
    "23.11.5",
    "23.11.6",
    "23.11.7",
    "23.11.9",
    "23.12",
    "23.12.1",
    "23.12.2",
    "23.12.3",
    "23.12.4",
    "23.12.5",
    "23.13",
    "23.13.1",
    "23.13.2",
    "23.13.3",
    "23.13.4",
    "23.13.5",
    "23.13.6",
    "23.14",
    "23.19",
    "23.19.1",
    "23.19.2",
    "23.19.3",
    "23.19.4",
    "23.19.5",
    "23.19.6",
    "23.19.7",
    "23.19.9",
    "23.2",
    "23.20.1",
    "23.20.2",
    "23.20.3",
    "23.20.9",
    "23.3",
    "23.31",
    "23.32",
    "23.4",
    "23.41",
    "23.41.1",
    "23.41.2",
    "23.41.3",
    "23.42",
    "23.43",
    "23.44",
    "23.44.1",
    "23.44.2",
    "23.49",
    "23.49.1",
    "23.49.9",
    "23.5",
    "23.51",
    "23.52",
    "23.52.1",
    "23.52.2",
    "23.52.3",
    "23.6",
    "23.61",
    "23.61.1",
    "23.61.2",
    "23.62",
    "23.63",
    "23.64",
    "23.65",
    "23.65.1",
    "23.65.2",
    "23.69",
    "23.7",
    "23.70.1",
    "23.70.2",
    "23.70.3",
    "23.9",
    "23.91",
    "23.99",
    "23.99.1",
    "23.99.2",
    "23.99.3",
    "23.99.4",
    "23.99.5",
    "23.99.6",
    "23.99.61",
    "23.99.62",
    "24",
    "24.1",
    "24.10.1",
    "24.10.11",
    "24.10.12",
    "24.10.13",
    "24.10.14",
    "24.10.2",
    "24.10.3",
    "24.10.4",
    "24.10.5",
    "24.10.6",
    "24.10.7",
    "24.10.9",
    "24.2",
    "24.20.1",
    "24.20.2",
    "24.20.3",
    "24.3",
    "24.31",
    "24.32",
    "24.33",
    "24.34",
    "24.4",
    "24.41",
    "24.42",
    "24.43",
    "24.43.1",
    "24.43.2",
    "24.43.3",
    "24.44",
    "24.45",
    "24.45.1",
    "24.45.2",
    "24.45.3",
    "24.45.4",
    "24.45.5",
    "24.45.6",
    "24.45.7",
    "24.45.8",
    "24.45.9",
    "24.46",
    "24.5",
    "24.51",
    "24.52",
    "24.53",
    "24.54",
    "25",
    "25.1",
    "25.11",
    "25.12",
    "25.2",
    "25.21",
    "25.21.1",
    "25.21.2",
    "25.29",
    "25.3",
    "25.30.1",
    "25.30.2",
    "25.30.21",
    "25.30.22",
    "25.4",
    "25.5",
    "25.50.1",
    "25.50.2",
    "25.6",
    "25.61",
    "25.62",
    "25.7",
    "25.71",
    "25.72",
    "25.73",
    "25.9",
    "25.91",
    "25.92",
    "25.93",
    "25.93.1",
    "25.93.2",
    "25.94",
    "25.99",
    "25.99.1",
    "25.99.11",
    "25.99.12",
    "25.99.2",
    "25.99.21",
    "25.99.22",
    "25.99.23",
    "25.99.24",
    "25.99.25",
    "25.99.26",
    "25.99.27",
    "25.99.29",
    "25.99.3",
    "26",
    "26.1",
    "26.11",
    "26.11.1",
    "26.11.2",
    "26.11.3",
    "26.11.9",
    "26.12",
    "26.2",
    "26.20.1",
    "26.20.2",
    "26.20.3",
    "26.20.4",
    "26.20.9",
    "26.3",
    "26.30.1",
    "26.30.11",
    "26.30.12",
    "26.30.13",
    "26.30.14",
    "26.30.15",
    "26.30.16",
    "26.30.17",
    "26.30.18",
    "26.30.19",
    "26.30.2",
    "26.30.21",
    "26.30.22",
    "26.30.29",
    "26.30.3",
    "26.30.4",
    "26.30.5",
    "26.30.6",
    "26.4",
    "26.40.1",
    "26.40.2",
    "26.40.21",
    "26.40.22",
    "26.40.23",
    "26.40.3",
    "26.40.4",
    "26.40.5",
    "26.5",
    "26.51",
    "26.51.1"
]


