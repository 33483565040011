import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import Modal from '../modals/Modal'
import ModalOverlay from '../modals/ModalOverlay'

export default function Opportunities() {
  const [openModalOne, setOpenModalOne] = useState(false)
  const [openModalTwo, setOpenModalTwo] = useState(false)

  const handleToggleModalOne = () => {
    setOpenModalOne(!openModalOne)
    if (openModalOne) {
      setOpenModalTwo(false)
    }
  }

  const handleToggleModalTwo = () => {
    setOpenModalTwo(!openModalTwo)
  }

  return (
    <div>
      <Button style={{ marginTop: '200px' }} onClick={handleToggleModalOne}>
        Toggle Modal
      </Button>
      {openModalOne && (
        <>
          <ModalOverlay isVisible={openModalOne} />
          <Modal
            size='large'
            isVisible={openModalOne}
            alertText='Modal One'
            onClose={handleToggleModalOne}
          >
            <Button onClick={handleToggleModalTwo}>Open Modal Two</Button>
          </Modal>
        </>
      )}

      {openModalTwo && (
        <>
          <ModalOverlay isVisible={setOpenModalTwo}/>
          <Modal
            size='medium'
            isVisible={openModalTwo}
            alertText='Modal Two'
            onClose={handleToggleModalTwo}
          >
            kkk
          </Modal>
        </>
      )}
    </div>
  )
}
