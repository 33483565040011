import React from 'react';
import { Form } from 'react-bootstrap';


export class InputFormPersonalArea extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            language: 'rus',
            id: '',
            fid: '',
            description: '',
            information: '',
            value: '',
            colorError: '#fff'
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.erasingError = this.erasingError.bind(this);
    }

    async handleBlur(e) {
        await this.setState({
            id: e.target.dataset.id,
            fid: e.target.dataset.fid,
            description: e.target.dataset.description,
            information: e.target.dataset.information
        })
        this.props.onChange(this.state);
    }

    handleChange(e) {
        e.preventDefault();
        // console.log(e.target.value);//test
        if (this.props.verify === 'number') {
            this.verificationNumber(e)
        } else {
            this.setState({ value: e.target.value })
        }
        // console.log(e.target.value);//test
    }

    verificationNumber(e) {
        // todo заменяем запятую на точку .replace(/,/, '.')
        if (isFinite(e.target.value.replace(/,/, '.'))) {
            this.setState({ value: e.target.value.replace(/,/, '.'), colorError: '#fff' })
        } else {

            console.log('error');
            this.setState({ colorError: '#dc3545' })
            this.erasingError();
        }
    }

    erasingError() {
        setTimeout(() => { this.setState({ colorError: '#fff' }) }, 500)
    }

    componentDidMount() {
        this.setState({ value: this.props.value })
    }

    render() {

        const id = this.props.id;
        const description = this.props.description;
        const placeholder = this.props.placeholder;
        const value = this.state.value;

        //${this.props.readOnly ? '#eee' : '#fff'}

        return (
            <React.Fragment key={id}>
                <Form.Control
                    style={{ backgroundColor: `${this.state.colorError} ` }}
                    type="text"
                    data-id={id}
                    data-fid={`${this.props.nameForm}_${this.props.id}`}
                    placeholder={placeholder}
                    data-information={placeholder}
                    data-description={description}
                    value={value}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    readOnly={this.props.readOnly ? true : false}

                />
            </React.Fragment>
        )
    }
}