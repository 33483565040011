import React from 'react'
import { Col, Form } from 'react-bootstrap'

export default class SelectCodesForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      id: '',
      fid: '',
      description: '',
      information: '',
      value: '',
      colorError: '#fff'
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.viewListOptions = this.viewListOptions.bind(this)
  }

  handleBlur(e) {
    this.setState({
      id: e.target.dataset.id,
      fid: `${this.props.nameForm}_${this.props.id}`,
      description: e.target.dataset.description,
      information: e.target.dataset.information
    })
    setTimeout(() => {
      this.props.onChangeValue(this.state)
    })
  }

  handleChange(e) {
    e.preventDefault()
    // console.log(e.target.value);//test
    this.setState({ value: e.target.value })
    this.props.onChangeVisionBlock(e.target.value, e)
  }

  viewListOptions(arr) {
    this.optionArr = []
    arr.forEach((data) => {
      this.optionArr.push(<option key={data}>{data}</option>)
    })
  }

  componentDidMount() {
    this.setState({ value: this.props.value })

    // this.viewListOptions(this.props.option)
  }

  render() {
    this.viewListOptions(this.props.option)

    const width = this.props.width
    // const label = this.props.label;
    const label = this.props.placeholder
    const id = this.props.id
    const description = this.props.description
    const placeholder = this.props.placeholder
    const value = this.state.value
    const show = this.props.show
    const placeholderTextOff = this.props.placeholderTextOff // отключает отбражениепласехолдера в списке выбора (true)

    return (
      <React.Fragment key={id}>
        {/* <Col sm={width} style={{ visibility: show ? 'visible' : 'collapse' }} > */}
        <Col sm={width} style={{ display: show ? 'block' : 'none' }}>
          <Form.Label className='select_form_label'>{label}</Form.Label>
          <Form.Control as='select' type='text' data-id={id} placeholder={placeholder} data-information={placeholder} data-description={description} value={value} onChange={this.handleChange} onBlur={this.handleBlur} disabled={this.props.readOnly}>
            {placeholderTextOff ? <option key={placeholder}></option> : <option key={placeholder}>{placeholder}</option>}
            {this.optionArr}
          </Form.Control>
        </Col>
      </React.Fragment>
    )
  }
}
