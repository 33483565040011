import React from 'react';
import { Row } from 'react-bootstrap';
import InputForm from './InputForm';

export default class SizeFormBig extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            base: []
        }

        this.doChangeValue = this.doChangeValue.bind(this);
        this.renderInputForm = this.renderInputForm.bind(this);
    }

    doChangeValue(e) {
        this.props.onChangeValue(e);
    }

    renderInputForm(array) {
        this.base = [];

        array.forEach((data, index) => {
            this.base = [...this.base,
            <React.Fragment key={index}>
                <InputForm
                    id={+this.props.id + index}
                    width={this.props.width}
                    show={this.props.readOnly && !this.value[+this.props.id + index] ? false : true}
                    // show={this.props.placeholder[index] ? this.props.show : false}
                    verify="number"
                    label={this.props.placeholder[index]}
                    fontSize={this.props.fontSize}
                    placeholder={this.props.placeholder[index]}
                    description={this.props.description}
                    value={this.value[+this.props.id + index] ? this.value[+this.props.id + index].value : ''}
                    onChangeValue={this.doChangeValue}
                    nameForm={this.props.nameForm}
                    readOnly={this.props.readOnly}
                />
            </React.Fragment>
            ]
        })
        this.setState({ base: this.base });
    }

    componentDidMount() {
        this.renderInputForm(this.props.placeholder);
        
    }

    render() {

        this.value = this.props.value;

        return (
            <>
                <Row>
                    {this.state.base}
                </Row>
            </>
        )
    }
}