/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import InputForm from './InputForm'
import SelectForm from './SelectForm'
import PhoneForm from './PhoneForm'
import CheckForm from './CheckForm'
import SelectForm_City from './SelectForm_City'
import { CITY } from './ConstCity'
import { PRODUCTION } from './ConstProduction'
import Form_Main_Add from './Form_Main_Add'
import RadioButtonForm from './RadioButtonForm'
import InputNameForm from './InputNameForm'
import ModalQuit from '../ModalQuit'
import { FIELD } from './ConstField'
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined'
import { nanoid } from 'nanoid'
import { OWN } from './ConstOwn'
import { codes } from './codesList'
import { createData } from './functionsForm'
import CommonAlert from './CommonAlert'
import AllModalsFooter from '../assets/AllModalsFooter'
import { SaveCurrentDataButton } from '../assets/BasicButtons'
import { isEqual } from 'lodash-es'
import { COUNTRY } from './ConstCountry'
import InputMask from 'react-input-mask'
import MaskForm from './MaskForm'
import SelectCodesForm from './SelectCodesForm'

export default class Form_Main extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'rus',
      show: false,
      showAdress: true,
      showAdressRe: true,
      dataValue: [],
      nameForm: 'Main',
      maxId: 123,
      idBranch: 60,
      base: [],
      showQuit: false,
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      showProduction: [true, false, false, false, false],
      showZero: false,
      production: PRODUCTION,
      fieldCheck: false,
      arrayChoice: [true, true, true, true, true, true, true, true, true, true, true, true, true, true],
      // showContent: true.valueOf
      showContent: true,
      previousState: [],
      saveDataMessage: false,
      dataBeenSaved: false,
      codes: [],
      okvedClass: ''
    }

    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this)
    this.handleClickHeader = this.handleClickHeader.bind(this)
    this.doChoiceProduction = this.doChoiceProduction.bind(this)
    this.handleClickAdd = this.handleClickAdd.bind(this)
    this.addForm = this.addForm.bind(this)
    this.createBlockForm = this.createBlockForm.bind(this)
    this.renderProduction = this.renderProduction.bind(this)
    this.choiceProduction = this.choiceProduction.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.writeDataInAdress = this.writeDataInAdress.bind(this)
    this.rewriteListProduction = this.rewriteListProduction.bind(this)
    this.doChoiceOption = this.doChoiceOption.bind(this)

    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)

    this.lookField = this.lookField.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.setCompanyName = this.setCompanyName.bind(this)
    this.hideContent = this.hideContent.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.runCompareAdress = this.runCompareAdress.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
    this.onSelectCode = this.onSelectCode.bind(this)
    this.validateOkved = this.validateOkved.bind(this)

    // this.isFormFilled = this.isFormFilled.bind(this)
  }

  validateOkved(val) {
    // const re = /[0-9]/
    const re = /[A-Za-z]/
    const re1 = /\s/
    if (re.test(val) || re1.test(val)) {
      this.setState({
        okvedClass: 'error_okved'
      })
    }
    else {
      this.setState({
        okvedClass: ''
      })
    }
  }

  saveDataWithNotification() {
    this.handleClickSave()
    this.setState({
      saveDataMessage: true,
      dataBeenSaved: true
    })
    setTimeout(() => {
      this.setState({
        saveDataMessage: false
      })
    }, 2000)
  }

  hideContent() {
    this.setState({ showContent: false })
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })

    // console.log(this.state.showQuit);
  }

  doChoiceOption(choice) {
    console.log(choice) //test
    this.setState({ arrayChoice: choice })
  }

  //*клик на header формы
  handleClickHeader() { }

  //*пустышка
  doEmpty(data, id) {
    console.log(data + ' - ' + id)
  }

  //*записываем фактический адрес, если совпадает с юр.адресом
  async writeDataInAdress() {
    await this.handleClickSave()
    // console.log('yes');//test
    this.dataState = this.state.dataFromServer

    for (let i = 13; i <= 18; i++) {
      let id = +this.props.data[i].id + 17

      let data = {
        id: id,
        fid: `${this.state.nameForm}_${id}`,
        description: 'фактический адрес',
        information: this.props.data[i].information,
        value: this.props.data[i].value
      }
      setTimeout(() => {
        this.doChangeValue(data)
        this.dataState[id] = data
      })
    }
    await this.setState({ dataFromServer: this.dataState })
    await this.handleClickSave()
  }

  doChangeVisionBlock(data, id) {
    // console.log(id + ' - ' + data);//test
    if (+id === 113 && data === 'Да') {
      // this.writeDataInAdress();
      this.setState({ showAdress: false })
      this.writeDataInAdress()
    } else if (+id === 113 && data === 'Нет') {
      this.setState({ showAdress: true })
      // console.log('no');//test
    }
  }

  handleClickAdd() {
    if (this.state.idBranch < 115) this.addForm()
  }

  addForm() {
    this.setState({
      base: [
        ...this.state.base,
        <React.Fragment key={this.state.idBranch}>
          <Form_Main_Add id={this.state.idBranch} value={this.value} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} />
        </React.Fragment>
      ],
      idBranch: +this.state.idBranch + 6,
      maxId: +this.state.maxId + 5
    })
  }

  //*создаем необходимое количество блоков
  createBlockForm() {
    let lengthData = this.state.lengthDataFromServer
    let data = this.state.dataFromServer

    this.addForm()
    for (let i = 0; i < lengthData; i++) {
      if ([66, 72, 78, 84, 90, 96, 102, 108].includes(i) && Number(data[i].id > 0)) {
        this.addForm()
      }
    }
    // console.log('createBlockForm');//test
  }

  //* включаем при рендере производсто
  renderProduction() {
    let id = 51 // начальный ID  первого поля
    for (let i = id; i <= 55; i++) {
      if (this.props.data[i]) this.choiceProduction(this.props.data[i].information, this.props.data[i].id)
    }
  }

  //*при рендере
  choiceProduction(name, id) {
    this.dataP = this.state.showProduction

    if (name !== 'Профиль деятельности') {
      this.dataP[+id - 50] = true
    } else {
      this.dataP[+id - 50] = false
    }
    this.setState({ showProduction: this.dataP })
  }

  //* по данным с формы
  doChoiceProduction(name, data) {
    // this.rewriteListProduction(name, data);
    this.dataP = this.state.showProduction

    console.log(name)
    console.log(data.target.dataset.id)
    if (name !== 'Профиль деятельности') {
      this.dataP[+data.target.dataset.id - 50] = true
      console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>') //test
    } else {
      this.dataP[+data.target.dataset.id - 50] = false
    }
    this.setState({ showProduction: this.dataP })
    // if (name === 'Машиностроительный завод, выпускающий сосуды и аппараты, работающие под давлением, под наливом и вакуумом') this.props.onRunFormZero(); //* запуск формы Zero
  }

  //todo сделать обработу массива профилей производств с удалением выбранного
  rewriteListProduction(name, data) { }

  //запуск выхода из формы
  handleClickShadow() {
    this.runCompareAdress() // заполнение фактического адреса если необходимо
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  async doQuitWithSave() {
    this.setState({ showQuit: false })
    try {
      await this.handleClickSave()
      this.lookField('save')
    } catch (err) {
      console.log(err)
    }

    this.props.onChangeView('Main')
  }

  //выход без сохранения
  doQuitWithoutSave() {
    this.lookField('not save')
    this.setState({ showQuit: false })
    this.props.onChangeView('Main')
  }

  //* определяет заполненность полей согласно массива полей - ConstField.js
  lookField(saveData) {
    this.check = []
    let dataServer

    FIELD.forEach((data) => {
      if (saveData === 'save') {
        dataServer = this.state.dataOnServer
      }
      if (saveData === 'not save') {
        dataServer = this.state.dataFromServer
      }
      // console.log(`RESULT ${data} -> `, dataServer[data]);
      //todo не сделана проверка массива на заполнение всех полей. После обсуждения, для проверки всех полей, сделать через проверку - typeof()
      if (dataServer[data] && dataServer[data].value && dataServer[data].value.length > 0) {
        this.check.push(true)
      } else {
        this.check.push(false)
      }
    })

    if (this.check.includes(false)) {
      this.setState({ fieldCheck: false })
      console.log('Не все данные заполнены!')
    } else {
      console.log('Все данные заполнены!')
      this.setState({ fieldCheck: true }) //! не меняет состояние state
    }
    // console.log(this.check);
    // this.check.includes(false) ? console.log(false) : console.log(true)

    // setTimeout(() => { console.log('FIELD CHECK >>>>>>>>>>>> ' + this.state.fieldCheck) }, 500) //! не меняет состояние state
  }

  //*формируем данные в массив объектов для отправки на сервер
  handleClickSave() {
    const data = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)
    this.setState({ dataOnServer: data })
    if (this.state.lengthDataFromServer < 1) {
      // console.log('CREATE DATA') //todo создаем новый блок данных на сервер
      this.props.onCreateDataServer(data, 'Main', localStorage.getItem('idUser'))
      this.props.onAddNewForm('production')
      setTimeout(() => {
        this.props.onAddNewForm('fifteen')
      })
    } else {
      console.log('UPDATE DATA') //todo перезаписываем существующий блок данных на срвере
      this.props.onUpdateDataOnServer(data, 'Main', localStorage.getItem('idUser'))
    }
  }

  onSelectCode(searchCode) {
    this.state.dataValue.map((el) => {
      if (el.id === '19') {
        el.value = searchCode

        this.setState({
          codes: []
        })
      }
    })

    this.setState({
      codes: []
    })
  }

  //* данные с полей формы, формируются в массив объектов
  doChangeValue(data) {
    // console.log(data) //test
    // this.setState({ dataValue: [...this.state.dataValue, data] })
    // setTimeout(() => { console.log(this.state.dataValue) })//test

    //************************** */

    const stateCopy = [...this.state.dataValue, data]
    this.setState({ dataValue: stateCopy })

    if (data.id === '19') {
      this.validateOkved(data.value)


      // let uniqueResult = [];
      // for (let str of codes) {
      //   if (!uniqueResult.includes(str)) {
      //     uniqueResult.push(str);
      //   }
      // }

      // console.log(uniqueResult);

      const res = codes.map((el) => {
        if (el.startsWith(data.value)) {
          return el
        }
      })
      const filtered = res.filter((el) => el)
      this.setState({
        codes: filtered
      })

      if (data.value === '') {
        this.setState({
          codes: []
        })
      }
    }

    this.setState({
      dataBeenSaved: false
    })



    //************************** */
  }

  setCompanyName() {
    if (this.props.companyName && this.props.companyName.length > 0) {
      this.setState({
        companyName: this.props.companyName
      })
    }
  }

  compare() {
    const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)
    const prev = this.state.previousState
    const isStateChanged = isEqual(prev, curr)
    if (isStateChanged) {
      return false
    }
    return true
  }

  // isFormFilled() {
  //   // const result = this.props.data.filter((el) => el.information !== 'ИНН')
  //   // const newResult = result.every((el) => el.value === '')
  //   this.props.sendFilligInfoMain()
  // }

  smartWindowFunction() {
    // this.props.sendFilligInfoMain()
    // this.props.setSendOnModerationModal()
    this.props.setFillingGuide()
    const result = this.compare()
    if (result && !this.state.dataBeenSaved) {
      this.handleClickShadow()
    } else {
      this.props.onChangeView('Main')
    }
  }

  // перенос данных из юр.адреса в факт.адрес
  runCompareAdress() {
    if ((this.props.data[113] && this.props.data[113].value === 'Нет') || (this.props.data[113] && this.props.data[113].value === 'Да')) {
      this.doChangeVisionBlock(this.props.data[113].value, +this.props.data[113].id)
    }
  }

  async componentDidMount() {

    localStorage.removeItem('nano') // удаляем ключ для первого входа
    await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer })

    if (this.props.data[28]) if (this.props.data[28].value) this.setState({ show: true })

    this.runCompareAdress() // перенос данных из юр.адреса в факт.адрес
    this.createBlockForm() // рендер адреса филиалов
    this.renderProduction() // рендер списка профиля деятельности
    await this.setCompanyName()

    const originalProps = JSON.parse(JSON.stringify(this.props.data))
    this.setState({
      previousState: originalProps
    })
  }

  render() {
    const view = this.props.view
    this.value = this.props.data
    const viewQuest = this.props.viewQuest
    const city = CITY

    const country = COUNTRY.join(', ')
    this.valueTest = 'position-2'

    if (this.value[6]?.value?.length === 12) {
      this.value[100].value = 'ИП'
    }

    // console.log(this.state.dataFromServer);

    // console.log('dataFromServer', this.state.dataFromServer[1] && this.state.dataFromServer[1].value);
    // console.log('dataOnServer', this.state.dataOnServer[1] && this.state.dataOnServer)

    //условие по которому компонент определяет находится ли он в формате чтения
    const inReadMode = this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined

    console.log('from', this.state.companyName)
    console.log('on', this.props.companyName)
    
    return (
      <>
        {inReadMode && <>{this.state.showQuit && <ModalQuit onQuitWithSave={this.doQuitWithSave} onQuitWithoutSave={this.doQuitWithoutSave} closeQuitModal={this.closeQuitModal} />}</>}

        {this.state.showContent && (
          <div className='modal_window' style={{ display: view ? 'block' : 'none' }}>
            <div
              className={viewQuest ? 'shadow_form' : 'shadow_form_off'}
              //  onClick={this.handleClickShadow}
              onClick={this.smartWindowFunction}
            ></div>
            <Form className={viewQuest ? 'form_main' : 'form_main_view'}>
              <CommonAlert
                inReadMode={inReadMode}
                handleClickHeader={this.handleClickHeader}
                handleClickShadow={this.smartWindowFunction}
                hideContent={this.hideContent}
                icon={<ContactMailOutlinedIcon fontSize='large' className='icon_primary' />}
                companyName={this.state.companyName}
                sectionName=' Контактная информация'
              />
              <div>
                <div className='form_container p_40'>
                  <Form.Group>
                    <Form.Group>
                      <Row>
                        <Col>1.0. Форма собственности.</Col>
                      </Row>
                      <Row>
                        {/* <InputForm
                                                id={100}
                                                width={12}
                                                show={true}
                                                // verify="number"
                                                color={'#ccc'}
                                                fontSize={'12px'}
                                                label="Форма собственности"
                                                placeholder="Форма собственности (например, ОАО)"
                                                description="Предприятие"
                                                value={this.value[100] ? this.value[100].value : ''}
                                                // value={this.value[1].value}
                                                onChangeValue={this.doChangeValue}
                                                nameForm={this.state.nameForm}
                                                readOnly={!inReadMode}
                                            /> */}

                        <SelectForm_City
                          id={100}
                          width={12}
                          show={true}
                          label=''
                          placeholder='Форма собственности компании'
                          description='Форма собственности компании'
                          option={OWN}
                          value={this.value[100] ? this.value[100].value : ''}
                          // value={this.value[15].value}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doEmpty}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                    </Form.Group>
                    <Row>
                      <Col>1.1. Наименование предприятия.</Col>
                    </Row>
                    <Row>
                      <InputForm
                        id={1}
                        width={12}
                        show={true}
                        // verify="number"
                        color={'#ccc'}
                        fontSize={'12px'}
                        label='Полное наименование предприятия'
                        placeholder='Полное наименование предприятия без формы собственности'
                        description='Предприятие'
                        value={this.value[1] ? this.value[1].value : ''}
                        // value={this.value[1].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Row>
                      <InputForm
                        id={29}
                        width={12}
                        show={true}
                        // verify="number"
                        color={'#ccc'}
                        fontSize={'12px'}
                        label='Краткое наименование предприятия'
                        placeholder='Краткое наименование предприятия без формы собственности'
                        description='Предприятие'
                        value={this.value[29] ? this.value[29].value : ''}
                        // value={this.value[1].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                  </Form.Group>
                  <Form.Group style={{ position: 'relative' }}>
                    <Row>
                      <Col>1.2. ИНН, КПП, ОГРН, основной код ОКВЭД</Col>
                    </Row>
                    <Row>
                      <InputForm
                        id={6}
                        width={3}
                        show={true}
                        verify='number'
                        label=''
                        placeholder='ИНН'
                        description='Предприятие'
                        value={this.value[6] ? this.value[6].value : ''}
                        // value={this.value[6].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={true} //блокируем запись в поле true / false

                      />
                      {/* <InputForm
                        id={7}
                        width={3}
                        show={true}
                        verify='number'
                        label=''
                        placeholder='КПП'
                        description='Предприятие'
                        value={this.value[7] ? this.value[7].value : ''}
                        // value={this.value[7].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      /> */}

                      <MaskForm
                        id={7}
                        width={3}
                        show={true}
                        label=''
                        mask='999999999'
                        placeholder='КПП'
                        description='КПП предприятия'
                        value={this.value[7] ? this.value[7].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />

                      {/* <InputForm
                        id={8}
                        width={3}
                        show={true}
                        verify='number'
                        label=''
                        placeholder='ОГРН'
                        description='Предприятие'
                        value={this.value[8] ? this.value[8].value : ''}
                        // value={this.value[8].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      /> */}

                      <MaskForm
                        id={8}
                        width={3}
                        show={true}
                        label=''
                        mask='9999999999999'
                        placeholder='ОГРН'
                        description='ОГРН предприятия'
                        value={this.value[8] ? this.value[8].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />

                      {/* <InputForm
                        id={19}
                        width={3}
                        show={true}
                        verify='number'
                        label=''
                        placeholder='основной код ОКВЭД'
                        description='Предприятие'
                        value={this.value[19] ? this.value[19].value : ''}
                        // value={this.value[8].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      /> */}
                      {/* *** */}

                      <InputForm
                        id={19}
                        width={3}
                        show={true}
                        // verify='number'
                        label=''
                        placeholder='основной код ОКВЭД'
                        description='Предприятие'
                        value={this.value[19] ? this.value[19].value : ''}
                        // value={this.value[8].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                        classNameInput={this.state.okvedClass}

                      />

                      <div className='dropdown_codes'>
                        {this.state.codes.map((el) => (
                          <div key={el} onClick={() => this.onSelectCode(el)} className='dropdown_codes-row'>
                            {el}
                          </div>
                        ))}
                      </div>


                      {/* *** */}
                    </Row>
                  </Form.Group>

                  <Form.Group>
                    <Row>
                      <Col>1.3. ФИО и должность руководителя компании.</Col>
                    </Row>
                    <Row>
                      <InputNameForm
                        id={2}
                        width={3}
                        show={true}
                        // verify="number"
                        label=''
                        placeholder='ФИО руководителя'
                        description='Предприятие'
                        value={this.value[2] ? this.value[2].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      {/* <InputForm
                                        id={2}
                                        width={9}
                                        show={true}
                                        // verify="number"
                                        label=""
                                        placeholder="ФИО руководителя"
                                        description="Предприятие"
                                        value={this.value[2] ? this.value[2].value : ''}
                                        // value={this.value[2].value}
                                        onChangeValue={this.doChangeValue}
                                    /> */}
                      <SelectForm
                        id={3}
                        width={3}
                        show={true}
                        label=''
                        placeholder='Должность'
                        description='Предприятие'
                        option='Директор, Генеральный директор, Президент, Управляющий орган' //*список для выбора
                        value={this.value[3] ? this.value[3].value : ''}
                        // value={this.value[3].value}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doEmpty}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                        placeholderTextOff={false} // отключает написание placeholder в спске выбора
                      />
                      <PhoneForm
                        id={111}
                        width={3}
                        show={true}
                        label=''
                        placeholder='Телефон'
                        description='Телефон руководителя компании'
                        value={this.value[111] ? this.value[111].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={112}
                        width={3}
                        show={true}
                        // verify="number"
                        label=''
                        placeholder='Email'
                        description='Email руководителя компании'
                        value={this.value[112] ? this.value[112].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                  </Form.Group>

                  <Form.Group>
                    <Row>
                      <Col>1.4. Адрес:</Col>
                    </Row>
                    <Row>
                      <Col>- юридический</Col>
                    </Row>
                    <Row>
                      {/* <InputForm
                        id={13}
                        width={4}
                        show={true}
                        verify='number'
                        label=''
                        placeholder='Индекс'
                        description='Предприятие, юр. адрес'
                        value={this.value[13] ? this.value[13].value : ''}
                        // value={this.value[13].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      /> */}

                      <MaskForm
                        id={13}
                        width={4}
                        show={true}
                        label=''
                        mask='999999'
                        placeholder='Индекс'
                        description='Индекс предприятия'
                        value={this.value[13] ? this.value[13].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />

                      <SelectForm
                        id={14}
                        width={4}
                        show={true}
                        label=''
                        placeholder='Страна'
                        description='Предприятие, юр. адрес'
                        // option='Россия, не Россия'
                        option={country}
                        value={this.value[14] ? this.value[14].value : ''}
                        // value={this.value[14].value}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doEmpty}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <SelectForm_City
                        id={15}
                        width={4}
                        show={true}
                        label=''
                        placeholder='Город'
                        description='Предприятие, юр. адрес'
                        option={city}
                        value={this.value[15] ? this.value[15].value : ''}
                        // value={this.value[15].value}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doEmpty}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                        labelSmall={true}
                      />
                    </Row>
                    <Row>
                      <InputForm
                        id={16}
                        width={6}
                        show={true}
                        // verify="number"
                        label=''
                        placeholder='Улица'
                        description='Предприятие, юр. адрес'
                        value={this.value[16] ? this.value[16].value : ''}
                        // value={this.value[16].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={17}
                        width={3}
                        show={true}
                        verify='number'
                        label=''
                        placeholder='Дом'
                        description='Предприятие, юр. адрес'
                        value={this.value[17] ? this.value[17].value : ''}
                        // value={this.value[17].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={18}
                        width={3}
                        show={true}
                        // verify="number"
                        label=''
                        placeholder='Корпус'
                        description='Предприятие, юр. адрес'
                        value={this.value[18] ? this.value[18].value : ''}
                        // value={this.value[18].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Row> &nbsp; </Row>
                    <Row>
                      <RadioButtonForm
                        id={113}
                        width={6}
                        show={true}
                        title='- фактический адрес совпадает с юридическим адресом?  '
                        label1='Да'
                        label2='Нет'
                        placeholder='фактический адрес такой же как юр.адрес'
                        description='Предприятие'
                        value={this.value[113] ? this.value[113].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                        test={true}
                      />
                    </Row>

                    {this.state.showAdress && (
                      <Form.Group>
                        <Row>
                          {/* <InputForm
                            id={30}
                            width={4}
                            // show={this.state.showAdress}
                            show={true}
                            verify='number'
                            label=''
                            placeholder='Индекс'
                            description='Предприятие, фактический адрес'
                            value={this.value[30] ? this.value[30].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          /> */}

                          <MaskForm
                            id={30}
                            width={4}
                            show={true}
                            label=''
                            mask='999999'
                            placeholder='Индекс'
                            description='Индекс предприятия, фактический адрес'
                            value={this.value[30] ? this.value[30].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />

                          <SelectForm
                            id={31}
                            width={4}
                            // show={this.state.showAdress}
                            show={true}
                            label=''
                            placeholder='Страна'
                            description='Предприятие, фактический адрес'
                            option='Россия, не Россия'
                            value={this.value[31] ? this.value[31].value : ''}
                            onChangeValue={this.doChangeValue}
                            onChangeVisionBlock={this.doEmpty}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                          <SelectForm_City
                            id={32}
                            width={4}
                            // show={this.state.showAdress}
                            show={true}
                            label=''
                            placeholder='Город'
                            description='Предприятие, фактический адрес'
                            option={city}
                            value={this.value[32] ? this.value[32].value : ''}
                            onChangeValue={this.doChangeValue}
                            onChangeVisionBlock={this.doEmpty}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                            labelSmall={true}
                          />
                        </Row>
                        <Row>
                          <InputForm
                            id={33}
                            width={6}
                            // show={this.state.showAdress}
                            show={true}
                            // verify="number"
                            label=''
                            placeholder='Улица'
                            description='Предприятие, фактический адрес'
                            value={this.value[33] ? this.value[33].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                          <InputForm
                            id={34}
                            width={3}
                            // show={this.state.showAdress}
                            show={true}
                            verify='number'
                            label=''
                            placeholder='Дом'
                            description='Предприятие, фактический адрес'
                            value={this.value[34] ? this.value[34].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                          <InputForm
                            id={35}
                            width={3}
                            // show={this.state.showAdress}
                            show={true}
                            // verify="number"
                            label=''
                            placeholder='Корпус'
                            description='Предприятие, фактический адрес'
                            value={this.value[35] ? this.value[35].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                        </Row>
                      </Form.Group>
                    )}
                  </Form.Group>

                  <Form.Group>
                    <Row>
                      <Col>1.5. Контакты:</Col>
                    </Row>
                    <Row>
                      <Col>1.5.1. Организации</Col>
                    </Row>
                    <Row>
                      <PhoneForm
                        id={4}
                        width={4}
                        show={true}
                        label=''
                        placeholder='Телефон'
                        description='Предприятие, контакты организации'
                        value={this.value[4] ? this.value[4].value : ''}
                        // value={this.value[4].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={5}
                        width={4}
                        show={true}
                        // verify="number"
                        label=''
                        placeholder='E-Mail'
                        description='Предприятие, контакты организации'
                        value={this.value[5] ? this.value[5].value : ''}
                        // value={this.value[5].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={21}
                        width={4}
                        show={true}
                        // verify="number"
                        label=''
                        placeholder='Сайт компании, контакты организации'
                        description='Предприятие'
                        value={this.value[21] ? this.value[21].value : ''}
                        // value={this.value[2].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                  </Form.Group>

                  <Form.Group>
                    <Row> &nbsp; </Row>
                    <Row>
                      <InputForm
                        id={22}
                        width={12}
                        show={true}
                        // verify="number"
                        label='Банковские реквизиты'
                        placeholder='Наименование и адрес Банка'
                        description='Предприятие'
                        value={this.value[22] ? this.value[22].value : ''}
                        // value={this.value[18].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Row>
                      <MaskForm
                        id={23}
                        width={4}
                        show={true}
                        label=''
                        mask='99999999999999999999'
                        placeholder='Номер расчетного счета'
                        description='Банковские реквизиты'
                        value={this.value[23] ? this.value[23].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      {/* <InputForm
                        id={23}
                        width={4}
                        show={true}
                        verify='number'
                        label=''
                        placeholder='Номер расчетного счета'
                        description='Банковские реквизиты'
                        value={this.value[23] ? this.value[23].value : ''}
                        // value={this.value[18].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      /> */}
                      <MaskForm
                        id={24}
                        width={4}
                        show={true}
                        label=''
                        mask='999999999'
                        placeholder='БИК'
                        description='Банковские реквизиты'
                        value={this.value[24] ? this.value[24].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      {/* <InputForm
                        id={24}
                        width={4}
                        show={true}
                        verify='number'
                        label=''
                        placeholder='БИК'
                        description='Банковские реквизиты'
                        value={this.value[24] ? this.value[24].value : ''}
                        // value={this.value[18].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      /> */}
                      <MaskForm
                        id={25}
                        width={4}
                        show={true}
                        label=''
                        mask='99999999999999999999'
                        placeholder='Корреспондентский счет'
                        description='Банковские реквизиты'
                        value={this.value[25] ? this.value[25].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      {/* <InputForm
                        id={25}
                        width={4}
                        show={true}
                        verify='number'
                        label=''
                        placeholder='Корреспондентский счет'
                        description='Банковские реквизиты'
                        value={this.value[25] ? this.value[25].value : ''}
                        // value={this.value[18].value}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      /> */}
                    </Row>
                    <Row> &nbsp; </Row>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={26}
                        width={12}
                        show={true}
                        placeholder='Субъект МСП'
                        description='Предприятие'
                        value={this.value[26] ? this.value[26].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doEmpty}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={20}
                        width={12}
                        show={true}
                        placeholder="Резидент 'Сколково'"
                        description='Предприятие'
                        value={this.value[20] ? this.value[20].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doEmpty}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={27}
                        width={12}
                        show={true}
                        placeholder='Не резидент РФ'
                        description='Предприятие'
                        value={this.value[27] ? this.value[27].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doEmpty}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Row>
                      {/* <CheckForm
                                        id={28}
                                        width={12}
                                        show={true}
                                        placeholder="Согласие на обработку персональных данных (в т.ч. Пользовательское Соглашение, Договор Оферты"
                                        description="Предприятие"
                                        value={this.value[28] ? this.value[28].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        onChangeVisionBlock={this.doChangeVisionBlock}
                                    /> */}
                    </Row>

                    {inReadMode && (
                      <>
                        <Row> &nbsp;</Row>
                        <Row> &nbsp;</Row>
                      </>
                    )}
                  </Form.Group>

                  <Row> &nbsp;</Row>
                </div>
                <AllModalsFooter
                  footerContent={
                    inReadMode && (
                      <Form.Group>
                        {/* <SaveButtonGreen
                          // handleSaveClick={this.handleClickSave}
                          // handleSaveClick={this.handleClickShadow}
                          // handleSaveClick={this.doQuitWithSave}
                          handleSaveClick={this.handleClickSave}
                          needDisable={false}
                          saveButtonText='Сохранить'
                        /> */}

                        <SaveCurrentDataButton
                          handleSaveClick={this.saveDataWithNotification}
                          needDisable={false}
                          savedDataMessage={this.state.saveDataMessage}
                          buttonTextDefault={'Сохранить'}
                          buttonTextSaved={'Данные сохранены'}
                          needCustomIcon={false}
                        />
                      </Form.Group>
                    )
                  }
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}
