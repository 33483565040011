import Server from '../server/server';


/**
 * Отправка письма для обратной связи
 * @param {Object} obj Объект с формы обратной связи
 */
const sendEmailFeedBack = async (obj) => {
    try {
        return (async () => {
            console.log(`SEND: `, obj); //test

            const regEmail = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i

            if (!obj.email || !regEmail.test(obj.email)) {
                return { message: 'Не указан или не верно указан Email', error: true }
            }

            if (!obj.tel) {
                return { message: 'Не указан или не верно указан телефон', error: true }
            }

            // todo: обращение к серверу для отправки письма

            const result = await Server.sendFeedBack(obj)

            console.log(`result >>>> `, result); // test
            return { message: 'ok', error: false }
        })()
    } catch (err) {
        console.log(`Ошибка отправки писма обратной связи: `, err);
        return { server: 'error', error: err }
    }
}

export default sendEmailFeedBack