export const SHEETMETAL = [{
    name: 'Листовой прокат',
    form: {
        dimension: ['Макс длина, мм', 'Макс ширина, мм', 'Мин ширина, мм', 'Макс толщ, мм', 'Мин толщ, мм']
    },
    normDoc: {
        gost: ['ГОСТ 7350', 'ТУ 14-1-2657', 'ТУ 0900-001-33902054', 'ТУ 302.02.014', 'ТУ 108.131', 'ГОСТ 5582', 'ТУ 14-1-2261', 'ТУ 14-1-3342', 'ТУ 14-1-3199', 'ТУ 14-1-4780', 'ТУ 14-1-2542', 'ТУ 14-1-5142', 'ТУ 14-1-3669', 'ТУ 14-1-3199', 'ТУ 14-1-2542', 'ТУ 108-930', 'ТУ 14-1-394', 'ТУ 14-1-5071', 'ТУ 14-1-625', 'ТУ 14-1-2735', 'ГОСТ 14637', 'ГОСТ 9045', 'ГОСТ 1577', 'ГОСТ 3836', 'ТУ 14-1-4088', 'ГОСТ 5520', 'ТУ 302.02.092', 'ТУ 05764417-013', 'ТУ 14-1-4853', 'ГОСТ 19281', 'ТУ 14-1-5265', 'ТУ 14-1-5065', 'ТУ 14-1-5307', 'ТУ 14-105-845', 'ТУ 14-1-4502', 'ТУ 14-1-4083', 'ГОСТ 5521', 'ТУ 14-1-4826', 'ТУ 14-1-5093', 'ТУ 108-1263', 'ТУ 302.02.031', 'ТУ 302.02.121', 'ТУ 302.02.128', 'ГОСТ 10885', 'ТУ 05764417-041', 'ТУ 14-1-4212']
    }
}]


export const SHEETMETAL_FOR_FILTER = [{
    name: 'Листовой прокат',
    form: {
        dimension: ['Длина, мм', 'Ширина, мм', 'Толщ, мм']
    },
    normDoc: {
        gost: ['ГОСТ 7350', 'ТУ 14-1-2657', 'ТУ 0900-001-33902054', 'ТУ 302.02.014', 'ТУ 108.131', 'ГОСТ 5582', 'ТУ 14-1-2261', 'ТУ 14-1-3342', 'ТУ 14-1-3199', 'ТУ 14-1-4780', 'ТУ 14-1-2542', 'ТУ 14-1-5142', 'ТУ 14-1-3669', 'ТУ 14-1-3199', 'ТУ 14-1-2542', 'ТУ 108-930', 'ТУ 14-1-394', 'ТУ 14-1-5071', 'ТУ 14-1-625', 'ТУ 14-1-2735', 'ГОСТ 14637', 'ГОСТ 9045', 'ГОСТ 1577', 'ГОСТ 3836', 'ТУ 14-1-4088', 'ГОСТ 5520', 'ТУ 302.02.092', 'ТУ 05764417-013', 'ТУ 14-1-4853', 'ГОСТ 19281', 'ТУ 14-1-5265', 'ТУ 14-1-5065', 'ТУ 14-1-5307', 'ТУ 14-105-845', 'ТУ 14-1-4502', 'ТУ 14-1-4083', 'ГОСТ 5521', 'ТУ 14-1-4826', 'ТУ 14-1-5093', 'ТУ 108-1263', 'ТУ 302.02.031', 'ТУ 302.02.121', 'ТУ 302.02.128', 'ГОСТ 10885', 'ТУ 05764417-041', 'ТУ 14-1-4212']
    }
}]