//usage rules: 
// 1. craete requiredFields and formData in your state:
//  example: requiredFields: {
//     surname: true,
//     name: true,
//     thirdName: true,
//     tel: true,
//     email: true,
//     position: true
//   },
//   formData: {
//     surname: '',
//     name: '',
//     thirdName: '',
//     tel: '',
//     email: '',
//     position: ''
//   }
// 2. put indicateEmptyFields function in your onChange function which takes the whole state as 1st parameter and event as 2nd parameter
// usage example: const newState = indicateEmptyFields(this.state, e)
// this.setState(newState)
// 3. put validateFormData in your handleSubmit function
// usage example:
// const { requiredFields, isFormValid } = validateFormData(this.state.formData)
// await this.setState({ requiredFields });
// if (isFormValid) {
// ....here submit happens only if isFormValid returns true....
// }
// with Form.Control use isInvalid property on condition. 
// Usage example:
// <Form.Control isInvalid={this.state.requiredFields.name ? false : true} value={this.state.formData.name}/>
// NOTE that value comes from formData object created in 1st step




export function indicateEmptyFields(currentState, e) {
  console.log(e.target);
  if(e.target) {
        const { dataset, value } = e.target
    const field = dataset.position

    let newFormData = {
        ...currentState.formData,
        [field]: value
    }

    return {
        formData: newFormData
    }
  }

}


export function validateFormData(formData) {
    const newRequiredFields = {}
    for (let field in formData) {
      newRequiredFields[field] = !!formData[field]
    }
  
    const isFormValid = Object.values(newRequiredFields).every(Boolean)
  
    return {
      requiredFields: newRequiredFields,
      isFormValid
    }
  }