import React, { useState, useEffect } from 'react'
import { Badge, Card, Form, Row, Spinner } from 'react-bootstrap'
import UniversalTable from '../assets/UniversalTable'
import { CURRENCY } from '../assets/currency'
import { GoToTradeButton } from '../assets/BasicButtons'
import LeftAndRightArrowsButtons from '../assets/LeftAndRightArrowsButtons'
import { usePagination, usePaginationTest } from '../assets/usePagination'
import { processTabledata } from '../trade/processTradeObject'

const WriteTableTrade = ({
  arrAllTrade,
  doQuestionnairy,
  toggleTradesModal,
  doUpdateTable,
  showLoader
}) => {


  const [trade, setTrade] = useState(arrAllTrade)
  const [show, setShow] = useState(true)
  const [currency, setCurrency] = useState(CURRENCY.rub[0])
  const [name, setName] = useState('Все торги')
  
  const [btnNamesArray] = useState([
    { text: 'Все торги', value: 'all' },
    { text: 'Приглашения на торги', value: 'invitations' },
    { text: 'Черновики предложений', value: 'drafts' },
    { text: 'Предложение подано', value: 'sent' },
    { text: 'Торги', value: 'current' },
    { text: 'Подведение итогов', value: 'finishedNoWinner' },
    { text: 'Победитель', value: 'finishedWithWinning' },
    { text: 'Отмененные торги', value: 'canceled' },
    
  ])
console.log(trade);
  console.log(arrAllTrade);

  const newTrade = processTabledata(trade)


  const { currentPage, pageSize, getPagedData, goToNextPage, goToPreviousPage } = usePaginationTest(
    10,
    newTrade
  )

  const renderedTrades = getPagedData()
  const [tableKey, setTableKey] = useState(0)

  const offset = currentPage * pageSize

  const incrementTableKey = () => {
    setTableKey(prevTableKey => prevTableKey + 1)
  };

  const handleSelect = async (e) => {
    setShow(false)
    await setName(e.target.value)
    setShow(true)
  }

  function doEmpty() {
    // console.log(`doEmpty >>>> `, event); // test
  }

  useEffect(() => {
    // console.log(`arrAllTrade::: `, arrAllTrade);
    ;(async () => {
      await btnNamesArray.forEach((el) => {
        if (el.text === name) {
          // if (arrAllTrade[el.value].some((el) => el.tradeBtn)) {
          //   arrAllTrade[el.value].map((el) => {
          //     delete el.tradeBtn
          //   })
          //   setTrade(arrAllTrade[el.value])
          // }
          incrementTableKey()
          const newArray = arrAllTrade && assignTradeButton(arrAllTrade[el.value])
          setTrade(newArray)
   
        }
        // if (el.text === name && el.text === 'Завершенные торги') {
        //   // console.log(arrAllTrade[el.value])
        //   const newArray = assignTradeButton(arrAllTrade[el.value])
        //   setTrade(newArray)
        // }
        // if (el.text === name && el.text === 'Плановые торги') {
        //   // const newArray = assignTradeButton(arrAllTrade[el.value]); // отключаем кнопки где не надо
        //   // setTrade(newArray);
        // }
        // if (el.text === name && el.text === 'Текущие торги') {
        //   //!SWITCHOFF!
        //   // const newArray = assignTradeButton(arrAllTrade[el.value]); // отключаем кнопки где не надо
        //   // setTrade(newArray);
        // }
        // if (el.text === name && el.text === 'Идут торги') {
        //   const newArray = assignTradeButton(arrAllTrade[el.value])
        //   setTrade(newArray)
        // }
      })
    })()
  }, [name])



  const assignTradeButton = (array) => {
    const newArr = []
    array.map((el) => {
      newArr.push(
        Object.assign(el, {
          tradeBtn: (
            <GoToTradeButton handleSaveClick={() => toggleTradesModal(el)} needDisable={false} />
          )
        })
      )
    })
    return newArr
  }

  useEffect(() => {
    setShow(false)
    setCurrency(CURRENCY.rub[0])
    setTimeout(() => {
      setShow(true)
    })

    // console.log(`TRADE:::::: `, trade);  // test
  }, [trade])

  // console.log(arrAllTrade.all.map(el => el.participant));

  // console.log(tableKey);
  return (
    <div className='wrapper_lot_participant_content'>
      <Card className='text-center box_shadow' style={{ backgroundColor: '#fff' }}>
        <Card.Header>
          <Card.Title style={{ display: 'flex' }}>
            <div style={{ width: '100%' }}>Ваши приглашения на торги</div>

            {/* <RefreshButton handleRefresh={updateTable} /> */}
          </Card.Title>
          <Form.Group style={{ display: 'flex', justifyContent: 'center' }}>
            <Row style={{ width: '100%' }}>
              <Form.Control onChange={handleSelect} as='select'>
                {btnNamesArray.map((el, index) => {
                  return (
                      <option value={el.text} name={el.text} key={el.value}>
                        {el.text} 
                        {`(${arrAllTrade && arrAllTrade[el.value]?.length})`}
                      </option>
                  )
                })}
              </Form.Control>
            </Row>
          </Form.Group>
        </Card.Header>
        <Card.Body>
          <Card.Text></Card.Text>
          {showLoader ? (
            <div className='loader_centered'>
              <Spinner animation='border' variant='secondary' />
            </div>
          ) : (
            <>
              {' '}
              {show && (
                <UniversalTable
                  key={`${currentPage}_${tableKey}`}
                  favourite={false}
                  rate={true}
                  isRowGreen={false}
                  removable={false}
                  selectable1={false}
                  selectable2={true}
                  tableName={name}
                  titleObjectData={{
                    numbered: 'п/п',
                    organizerName: 'Компания заказчик',
                    nameLot: 'Название лота',
                    confirmation: 'Статус согласования',
                    // dateTradeRead: 'Дата торгов',
                    tradeStatus: 'Статус торгов',
                    startDate: 'Дата проведения торгов', 
                    startTime: 'Время начала торгов (МСК)',
                    finishTime: 'Время окончания торгов (МСК)',
                    price: `Начальная максимальная цена, ${currency} `,
                    tradeBtn: trade?.some((el) => el.tradeBtn) ? 'Сводная таблица лота' : ''
                  }}
                  openQuestionnairy={doQuestionnairy}
                  // renderedArray={trade}
                  // renderedArray={getPagedData()}
                  renderedArray={renderedTrades ? renderedTrades.reverse() : []}
                  renderedArrayWithAdded={doEmpty}
                  chosenObjectsArray={doEmpty}
                  chosenIdsArray={doEmpty}
                  chosenObjectId={doEmpty}
                  handleCancel={doEmpty}
                  handleModalToggle={doEmpty}
                  iconWithCross={true}
                  tableFunction={'participant'}
                  offset={offset}
                  shouldReverse={false}
                />
              )}
            </>
          )}
          {trade?.length >= pageSize ? (
            <LeftAndRightArrowsButtons
              nextPageClick={goToNextPage}
              prevPageClick={goToPreviousPage}
              needDisableButtons={true}
              disableNextButtonCondition={currentPage >= Math.ceil(trade.length / pageSize)}
              disablePrevButtonCondition={currentPage === 0}
            />
          ) : (
            ''
          )}
        </Card.Body>
      </Card>
    </div>
  )
}

export default WriteTableTrade
