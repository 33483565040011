/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Alert, Container, Form, Row, Button } from 'react-bootstrap'
// import ModalQuit from '../ModalQuit';
import TechDataTradeAdd from './TechDataTradeAdd'
import Server from '../server/server'
import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import InputFormTrade from './InputFormTrade'
import AllModalsFooter from '../assets/AllModalsFooter'
import { AddButton, SaveButtonGreen } from '../assets/BasicButtons'

export default class CreateNewTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'rus',
      show: false,
      errName: false,
      nameTemplate: '',
      dataFromServer: [],
      maxId: 0,
      tech: [],
      dataValue: [],
      dataOnServer: [],
      id: 1,
      base: []
    }

    this.handleClickHeader = this.handleClickHeader.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.doChangeValue = this.doChangeValue.bind(this)
    this.doChangeValueName = this.doChangeValueName.bind(this)
    this.handleClickAdd = this.handleClickAdd.bind(this)
    this.createNewTamplateOnServer = this.createNewTamplateOnServer.bind(this)
  }

  // название шаблона
  doChangeValueName(e) {
    // console.log(e.value);
    this.setState({ nameTemplate: e.value })
  }

  //*клик на header формы
  handleClickHeader() { }

  //*пустышка
  doEmpty(data, id) {
    // console.log(data + ' - ' + id);
  }

  doChangeVisionBlock(data, id) { }

  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  async doQuitWithSave() {
    this.setState({ showQuit: false })
    try {
      await this.handleClickSave()
      // console.log(`SAVE OK`)
    } catch (err) {
      console.log(err)
    }

    this.props.onChangeView('CreateNewTemplate')
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView('CreateNewTemplate') // todo
  }

  //*формируем данные в массив объектов для отправки на сервер
  async handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach(dataD => {
        if (+dataD.id === i) {
          data[i] = {
            id: dataD.id,
            fid: dataD.fid,
            description: dataD.description,
            information: dataD.information,
            value: dataD.value
          }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach(dataE => {
        if (+dataE.id === i) {
          data[i] = {
            id: dataE.id,
            fid: dataE.fid,
            description: dataE.description,
            information: dataE.information,
            value: dataE.value
          }
        }
      })
    }

    data.pop() // ! только для этого случая, убираем последний объект в массиве

    await this.setState({ dataOnServer: data })
    // console.log('DATA from storage -> ', data);

    //todo проверка на наличие данных на сервере
    if (this.state.lengthDataFromServer < 1) {
      // console.log('CREATE DATA');//todo создаем новый блок данных на сервер
      // console.log(data); //! test
      await this.createNewTamplateOnServer(
        data,
        `TradeTemplate_${this.state.nameTemplate}`,
        String(localStorage.getItem('idUser'))
      )
    } else {
      // console.log('UPDATE DATA'); //todo перезаписываем существующий блок данных на срвере
      await this.createNewTamplateOnServer(
        data,
        `TradeTemplate_${this.state.nameTemplate}`,
        String(localStorage.getItem('idUser'))
      )
    }
    // setTimeout(() => { console.log(this.state.dataOnServer) })//test
    // this.handleClickShadow();
    this.props.handleClickNewTemplate()
  }

  // создаем новый шаблон на сервере
  async createNewTamplateOnServer(data, name, id) {
    /**
     * id - _id collection /ИНН компании, id коллекции/
     * name -имя формы /'Main', 'Zero', ... или login при регистрации/
     *  data -  данные отправляемые на сервер
     */
    const result = await Server.createDataOnServer(data, name, id)

    // console.log(`RESULT create file on Server: `, result);
  }

  //* данные с полей формы, формируются в массив объектов
  doChangeValue(data) {
    // console.log(data);//test
    this.setState({ dataValue: [...this.state.dataValue, data] })
    // setTimeout(() => { console.log(`STATE: `, this.state.dataValue); })
  }

  async handleClickAdd() {
    if (this.state.nameTemplate.length < 1) {
      this.setState({ errName: true })
      setTimeout(() => {
        this.setState({ errName: false })
      }, 2000)
    } else {
      this.setState({ errName: false })
      await this.setState({
        base: [
          ...this.state.base,
          <React.Fragment key={this.state.id}>
            <TechDataTradeAdd onChangeValue={this.doChangeValue} id={this.state.id} value={this.state.tech} />
          </React.Fragment>
        ],
        id: +this.state.id + 2,
        maxId: +this.state.id + 2
      })
    }
    // setTimeout(() => { console.log(this.state.id + ' <- ADD ID'); }) // test
  }

  componentDidMount() { }

  render() {
    const view = this.props.view
    this.value = this.props.data
    const viewQuest = this.props.viewQuest

    return (
      <>
        <div
          // className="modal_window z_index_1000"
          style={{ display: view ? 'block' : 'none' }}
        >
          <div
            className={viewQuest ? 'shadow_form z_index_1000' : 'shadow_form_off'}
            onClick={this.handleClickShadow}
          ></div>
          <Form className={viewQuest ? 'create_template_modal p_40 width_60 z_index_1001' : 'form_main_view'}>
            <Alert
              variant='dark'
              className='header_alert_common color_000'
              onClick={this.handleClickHeader}
              onClose={() => this.doQuitWithoutSave()}
              dismissible
            >
              <Alert.Heading className='create_template_heading'>
                {' '}
                Создание шаблона технических требований{' '}
              </Alert.Heading>
            </Alert>
            <div>
              <div
                className='form_container'
                style={{ height: '300px', marginBottom: '40px', marginTop: '30px', overflowY: 'auto' }}
              >
                <Form.Group>
                  <Row> &nbsp;</Row>
                  {this.state.errName ? (
                    <>
                      <Alert variant='warning'>
                        <ReportProblemOutlinedIcon fontSize='small' className='pb_3' /> Введите наименование шаблона!{' '}
                      </Alert>
                    </>
                  ) : (
                    ''
                  )}
                  <Row style={{ margin: '15px 3px' }}>
                    <InputFormTrade
                      id={0}
                      width={10}
                      show={true}
                      // verify="number"
                      color={'#ccc'}
                      fontSize={'12px'}
                      label='Наименование шаблона'
                      placeholder='Наименование шаблона'
                      description='шаблон технических требований'
                      // value={this.value[1] ? this.value[1].value : ''}
                      // value={this.value[1].value}
                      onChangeValue={this.doChangeValueName}
                      nameForm={this.state.nameForm}
                      upperLabel={'Наименование шаблона'}
                    />
                  </Row>

                  <Row> &nbsp;</Row>

                  {this.state.base}
                </Form.Group>

                <Row> &nbsp;</Row>
              </div>
              <AllModalsFooter
                isPosAbsoluteNedded={true}
                footerContent={
                  <>
                    {/* <Button
                      disabled={this.state.nameTemplate ? false : true}
                      onClick={this.handleClickAdd}
                      variant='info'
                    >
                      <AddOutlinedIcon className='p_3' />
                      Добавить позицию
                    </Button> */}

                    <AddButton handleSaveClick={this.handleClickAdd}
                      needDisable={true}
                      disableCondition={this.state.nameTemplate ? false : true}
                      buttonText='позицию'
                    />

                    <SaveButtonGreen
                      handleSaveClick={this.handleClickSave}
                      needDisable={true}
                      saveButtonText='Сохранить'
                      disableCondition={this.state.nameTemplate ? false : true}
                    />

                    {/* <Button
                                        disabled={this.state.nameTemplate ? false : true}
                                        variant='success'
                                        onClick={this.handleClickSave}
                                        className='m_0'
                                    >
                                        Сохранить
                                    </Button> */}
                  </>
                }
              />
            </div>
          </Form>
        </div>
      </>
    )
  }
}
