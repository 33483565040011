import React from 'react'
import SvgAngleDown from '../../icon/Svg_Angle_down'
import SvgAngleLeft from '../../icon/Svg_Angle_left'

export default function ArrowsAnimation({ isOpen }) {
  return (
    <div>
      {isOpen ? (
        <div style={{ position: 'absolute', right: '10px', top: '10px' }}>
          <SvgAngleDown colorSvg='#fff' />
        </div>
      ) : (
        <div style={{ position: 'absolute', right: '10px', top: '10px' }}>
          <SvgAngleLeft colorSvg='#fff' />
        </div>
      )}
    </div>
  )
}
