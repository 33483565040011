import React from 'react'
import { Alert } from 'react-bootstrap'
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';

export default function EmailAlert({ alertTextSent, alertTextCheck }) {
    return (
        <Alert variant='success'>
            {alertTextSent &&
                <>
                    <ForwardToInboxOutlinedIcon style={{ paddingBottom: '3px' }} />{' '}{alertTextSent}
                </>
            }

            {alertTextCheck &&
                <>
                    <MarkEmailUnreadOutlinedIcon style={{ paddingBottom: '3px' }} />{'  '}{alertTextCheck}
                </>
            }
        </Alert>
    )
}
