export const FORGINGS = [{
        name: 'ГОСТ 8479',
        form: {
            square: ['Макс длина, мм', 'Макс ширина, мм', 'Макс высота, мм', 'Макс масса, тн'],
            round: ['Макс длина, мм', 'Макс диаметр, мм', 'Мин диаметр, мм', 'Макс масса, тн'],
            ring: ['Макс наружный диаметр, мм', 'Мин внутренний диаметр, мм', 'Макс толщ, мм', 'Мин толщ, мм', 'Макс масса, тн'],
            disk: ['Макс диаметр, мм', 'Макс толщ, мм', 'Мин толщ, мм', 'Макс масса, тн']
        },
        group: ['Группа I', 'Группа II', 'Группа III', 'Группа IV', 'Группа V']
    },
    {
        name: 'ОСТ 108.030.113',
        form: {
            square: ['Макс длина, мм', 'Макс ширина, мм', 'Макс высота, мм', 'Макс масса, тн'],
            round: ['Макс длина, мм', 'Макс диаметр, мм', 'Мин диаметр, мм', 'Макс масса, тн'],
            ring: ['Макс наружный диаметр, мм', 'Мин внутренний диаметр, мм', 'Макс толщ, мм', 'Мин толщ, мм', 'Макс масса, тн'],
            disk: ['Макс диаметр, мм', 'Макс толщ, мм', 'Мин толщ, мм', 'Макс масса, тн']
        }
    },
    {
        name: 'ТУ 302.02.092',
        form: {
            square: ['Макс длина, мм', 'Макс ширина, мм', 'Макс высота, мм', 'Макс масса, тн'],
            round: ['Макс длина, мм', 'Макс диаметр, мм', 'Мин диаметр, мм', 'Макс масса, тн'],
            ring: ['Макс наружный диаметр, мм', 'Мин внутренний диаметр, мм', 'Макс толщ, мм', 'Мин толщ, мм', 'Макс масса, тн'],
            disk: ['Макс диаметр, мм', 'Макс толщ, мм', 'Мин толщ, мм', 'Макс масса, тн']
        }
    },
    {
        name: 'ТУ 05764417-013',
        form: {
            square: ['Макс длина, мм', 'Макс ширина, мм', 'Макс высота, мм', 'Макс масса, тн'],
            round: ['Макс длина, мм', 'Макс диаметр, мм', 'Мин диаметр, мм', 'Макс масса, тн'],
            ring: ['Макс наружный диаметр, мм', 'Мин внутренний диаметр, мм', 'Макс толщ, мм', 'Мин толщ, мм', 'Макс масса, тн'],
            disk: ['Макс диаметр, мм', 'Макс толщ, мм', 'Мин толщ, мм', 'Макс масса, тн']
        }
    },
    {
        name: 'ТУ 26-0303-1532',
        form: {
            square: ['Макс длина, мм', 'Макс ширина, мм', 'Макс высота, мм', 'Макс масса, тн'],
            round: ['Макс длина, мм', 'Макс диаметр, мм', 'Мин диаметр, мм', 'Макс масса, тн'],
            ring: ['Макс наружный диаметр, мм', 'Мин внутренний диаметр, мм', 'Макс толщ, мм', 'Мин толщ, мм', 'Макс масса, тн'],
            disk: ['Макс диаметр, мм', 'Макс толщ, мм', 'Мин толщ, мм', 'Макс масса, тн']
        }
    },
    {
        name: 'СТО 00220227-006',
        form: {
            square: ['Макс длина, мм', 'Макс ширина, мм', 'Макс высота, мм', 'Макс масса, тн'],
            round: ['Макс длина, мм', 'Макс диаметр, мм', 'Мин диаметр, мм', 'Макс масса, тн'],
            ring: ['Макс наружный диаметр, мм', 'Мин внутренний диаметр, мм', 'Макс толщ, мм', 'Мин толщ, мм', 'Макс масса, тн'],
            disk: ['Макс диаметр, мм', 'Макс толщ, мм', 'Мин толщ, мм', 'Макс масса, тн']
        }
    },
    {
        name: 'ГОСТ 25054',
        form: {
            square: ['Макс длина, мм', 'Макс ширина, мм', 'Макс высота, мм', 'Макс масса, тн'],
            round: ['Макс длина, мм', 'Макс диаметр, мм', 'Мин диаметр, мм', 'Макс масса, тн'],
            ring: ['Макс наружный диаметр, мм', 'Мин внутренний диаметр, мм', 'Макс толщ, мм', 'Мин толщ, мм', 'Макс масса, тн'],
            disk: ['Макс диаметр, мм', 'Макс толщ, мм', 'Мин толщ, мм', 'Макс масса, тн']
        },
        group: ['Группа I', 'Группа II', 'Группа IIK', 'Группа III', 'Группа IIIK', 'Группа IV', 'Группа IVK', 'Группа V', 'Группа VK']
    }

]

export const FORGINGS_FOR_FILTER = [{
    name: 'ГОСТ 8479',
    form: {
        square: ['Длина, мм', 'Ширина, мм', 'Высота, мм', 'Масса, тн'],
        round: ['Длина, мм', 'Диаметр, мм', 'Масса, тн'],
        ring: ['Наружный диаметр, мм', 'Внутренний диаметр, мм', 'Толщина, мм', 'Масса, тн'],
        disk: ['Диаметр, мм', 'Толщина, мм', 'Масса, тн']
    },
    group: ['Группа I', 'Группа II', 'Группа III', 'Группа IV', 'Группа V']
},
{
    name: 'ОСТ 108.030.113',
    form: {
        square: ['Длина, мм', 'Ширина, мм', 'Высота, мм', 'Масса, тн'],
        round: ['Длина, мм', 'Диаметр, мм', 'Масса, тн'],
        ring: ['Наружный диаметр, мм', 'Внутренний диаметр, мм', 'Толщина, мм', 'Масса, тн'],
        disk: ['Диаметр, мм', 'Толщина, мм', 'Масса, тн']
    }
},
{
    name: 'ТУ 302.02.092',
    form: {
        square: ['Длина, мм', 'Ширина, мм', 'Высота, мм', 'Масса, тн'],
        round: ['Длина, мм', 'Диаметр, мм', 'Масса, тн'],
        ring: ['Наружный диаметр, мм', 'Внутренний диаметр, мм', 'Толщина, мм', 'Масса, тн'],
        disk: ['Диаметр, мм', 'Толщина, мм', 'Масса, тн']
    }
},
{
    name: 'ТУ 05764417-013',
    form: {
        square: ['Длина, мм', 'Ширина, мм', 'Высота, мм', 'Масса, тн'],
        round: ['Длина, мм', 'Диаметр, мм', 'Масса, тн'],
        ring: ['Наружный диаметр, мм', 'Внутренний диаметр, мм', 'Толщина, мм', 'Масса, тн'],
        disk: ['Диаметр, мм', 'Толщина, мм', 'Масса, тн']
    }
},
{
    name: 'ТУ 26-0303-1532',
    form: {
        square: ['Длина, мм', 'Ширина, мм', 'Высота, мм', 'Масса, тн'],
        round: ['Длина, мм', 'Диаметр, мм', 'Масса, тн'],
        ring: ['Наружный диаметр, мм', 'Внутренний диаметр, мм', 'Толщина, мм', 'Масса, тн'],
        disk: ['Диаметр, мм', 'Толщина, мм', 'Масса, тн']
    }
},
{
    name: 'СТО 00220227-006',
    form: {
        square: ['Длина, мм', 'Ширина, мм', 'Высота, мм', 'Масса, тн'],
        round: ['Длина, мм', 'Диаметр, мм', 'Масса, тн'],
        ring: ['Наружный диаметр, мм', 'Внутренний диаметр, мм', 'Толщина, мм', 'Масса, тн'],
        disk: ['Диаметр, мм', 'Толщина, мм', 'Масса, тн']
    }
},
{
    name: 'ГОСТ 25054',
    form: {
        square: ['Длина, мм', 'Ширина, мм', 'Высота, мм', 'Масса, тн'],
        round: ['Длина, мм', 'Диаметр, мм', 'Масса, тн'],
        ring: ['Наружный диаметр, мм', 'Внутренний диаметр, мм', 'Толщина, мм', 'Масса, тн'],
        disk: ['Диаметр, мм', 'Толщина, мм', 'Масса, тн']
    },
    group: ['Группа I', 'Группа II', 'Группа IIK', 'Группа III', 'Группа IIIK', 'Группа IV', 'Группа IVK', 'Группа V', 'Группа VK']
}

]
