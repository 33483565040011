import Server from '../server/server'


/**
 * Загрузка одной, выбранной карты аппарата по ID
 * @param {String} idCard реестр аппаратов
 * @returns 
 */
const getOneCardDataEquipment = async (id) => {
    try {
        return await Server.getDataFromServer(id, localStorage.getItem('idUser'))

    } catch (err) {
        console.log(`Ошибка при получении карточки аппарата: `, err);
        return []
    }
}

export default getOneCardDataEquipment;