import React from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import InputForm from './InputForm'
import ModalQuit from '../ModalQuit'
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined'
import CommonAlert from './CommonAlert'
import AllModalsFooter from '../assets/AllModalsFooter'
import { SaveCurrentDataButton } from '../assets/BasicButtons'
import { isEqual } from 'lodash-es'
import { createData } from './functionsForm'

export default class Form_One extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'rus',
      dataValue: [],
      maxId: 15,
      nameForm: 'One',
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      showQuit: false,
      showContent: true,
      previousState: [],
      savedDataMessage: false,
      dataBeenSaved: false
    }

    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doEmpty = this.doEmpty.bind(this)

    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.setCompanyName = this.setCompanyName.bind(this)
    this.hideContent = this.hideContent.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
  }

  saveDataWithNotification(){
    this.handleClickSave()

    this.setState({
      savedDataMessage: true,
      dataBeenSaved: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }

  hideContent() {
    this.setState({ showContent: false })
  }

  setCompanyName() {
    if (this.props.companyName && this.props.companyName.length > 0) {
      this.setState({
        companyName: this.props.companyName
      })
    }
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })
  }

  //* empty
  doEmpty() {}

  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  doQuitWithSave() {
    this.setState({ showQuit: false })
    this.handleClickSave()
    this.props.onChangeView('One')
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView('One')
  }

  //*формируем данные в массив объектов для отправки на сервер
  handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach((dataD) => {
        if (+dataD.id === i) {
          data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach((dataE) => {
        if (+dataE.id === i) {
          data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
        }
      })
    }
    this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере
    if (this.state.lengthDataFromServer < 1) {
      console.log('CREATE DATA')
      this.props.onCreateDataServer(data, 'One', localStorage.getItem('idUser'))
    } else {
      console.log('UPDATE DATA')
      this.props.onUpdateDataOnServer(data, 'One', localStorage.getItem('idUser'))
    }
    setTimeout(() => {
      console.log(this.state.dataOnServer)
    }) //test
    // this.handleClickShadow();
  }

  //* данные с полей формы, формируются в массив объектов
  doChangeValue(data) {
    this.setState({ dataValue: [...this.state.dataValue, data] })
    this.setState({
        dataBeenSaved: false
    })
  }

  compare() {
    const prev = this.state.previousState
    const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)
    const isStateEqual = isEqual(prev, curr)

    if (isStateEqual) {
      return true
    }
    return false
  }

  smartWindowFunction() {
    const isStateEqual = this.compare()
    const { dataBeenSaved, dataValue } = this.state
    if (isStateEqual || dataBeenSaved || dataValue.length === 0) {
      this.props.onChangeView('One')
    } else {
      this.handleClickShadow()
    }
  }

  componentDidMount() {
    // setTimeout(() => {
    this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer })
    // this.forceUpdate();
    // }, 1000);
    this.setCompanyName()

    if (this.props.data.length) {
      const previousState = JSON.parse(JSON.stringify(this.props.data))
      this.setState({
        previousState
      })
    }
  }

  render() {
    const view = this.props.view
    this.value = this.props.data
    const viewQuest = this.props.viewQuest

    //условие по которому компонент определяет находится ли он в формате чтения
    const inReadMode = this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined

    console.log(this.props.data);//test
    console.log(this.state.dataValue);

    return (
      <>
        {inReadMode && <>{this.state.showQuit && <ModalQuit onQuitWithSave={this.doQuitWithSave} onQuitWithoutSave={this.doQuitWithoutSave} closeQuitModal={this.closeQuitModal} />}</>}
        {this.state.showContent && (
          <div className='modal_window' style={{ display: view ? 'block' : 'none' }}>
            <div
              className={viewQuest ? 'shadow_form' : 'shadow_form_off'}
              // onClick={this.handleClickShadow}
              onClick={this.smartWindowFunction}
            ></div>
            <Form className={viewQuest ? 'form_main' : 'form_main_view'}>
              <CommonAlert
                inReadMode={inReadMode}
                handleClickHeader={this.handleClickHeader}
                // handleClickShadow={this.handleClickShadow}
                handleClickShadow={this.smartWindowFunction}
                hideContent={this.hideContent}
                icon={<DesignServicesOutlinedIcon fontSize='large' className='icon_primary' />}
                companyName={this.state.companyName}
                sectionName='Заготовительное производство'
              />
              <div>
                <Container className='form_container'>
                  <Form.Group>
                    <Form.Label className='f_size_1em'>Максимальная толщина резания металла при различных способах и видах резки</Form.Label>

                    <Row>
                      <Col>Ручная резка:</Col>
                    </Row>

                    <Row>
                      <InputForm
                        id={1}
                        width={3}
                        show={!inReadMode && !this.value[1] ? false : true}
                        verify='number'
                        label=''
                        placeholder='Толщина, мм(углер.)'
                        description='Ручная резка'
                        value={this.value[1] ? this.value[1].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={2}
                        width={3}
                        show={!inReadMode && !this.value[2] ? false : true}
                        verify='number'
                        label=''
                        placeholder='Толщина, мм(нерж.)'
                        description='Ручная резка'
                        value={this.value[2] ? this.value[2].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Row> &nbsp; </Row>
                    <Row>
                      <Col>Автоматизированная огневая резка:</Col>
                    </Row>
                    <Row>
                      <InputForm
                        id={3}
                        width={3}
                        show={!inReadMode && !this.value[3] ? false : true}
                        verify='number'
                        label=''
                        placeholder='Длина рабочей зоны, мм'
                        description='Автоматизированная огневая резка'
                        value={this.value[3] ? this.value[3].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={4}
                        width={3}
                        show={!inReadMode && !this.value[4] ? false : true}
                        verify='number'
                        label=''
                        placeholder='Ширина рабочей зоны, мм'
                        description='Автоматизированная огневая резка'
                        value={this.value[4] ? this.value[4].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={5}
                        width={3}
                        show={!inReadMode && !this.value[5] ? false : true}
                        verify='number'
                        label=''
                        placeholder='Толщина, мм(углер.)'
                        description='Автоматизированная огневая резка'
                        value={this.value[5] ? this.value[5].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={6}
                        width={3}
                        show={!inReadMode && !this.value[6] ? false : true}
                        verify='number'
                        label=''
                        placeholder='Толщина, мм(нерж.)'
                        description='Автоматизированная огневая резка'
                        value={this.value[6] ? this.value[6].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Row> &nbsp;</Row>
                    <Row>
                      <Col>Лазерная резка:</Col>
                    </Row>
                    <Row>
                      <InputForm
                        id={7}
                        width={3}
                        show={!inReadMode && !this.value[7] ? false : true}
                        verify='number'
                        label=''
                        placeholder='Длина рабочей зоны, мм'
                        description='Лазерная резка'
                        value={this.value[7] ? this.value[7].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={8}
                        width={3}
                        show={!inReadMode && !this.value[8] ? false : true}
                        verify='number'
                        label=''
                        placeholder='Ширина рабочей зоны, мм'
                        description='Лазерная резка'
                        value={this.value[8] ? this.value[8].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={9}
                        width={3}
                        show={!inReadMode && !this.value[9] ? false : true}
                        verify='number'
                        label=''
                        placeholder='Толщина, мм(углер.)'
                        description='Лазерная резка'
                        value={this.value[9] ? this.value[9].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={10}
                        width={3}
                        show={!inReadMode && !this.value[10] ? false : true}
                        verify='number'
                        label=''
                        placeholder='Толщина, мм(нерж.)'
                        description='Лазерная резка'
                        value={this.value[10] ? this.value[10].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Row> &nbsp;</Row>
                    <Row>
                      <Col>Гидроабразивная резка:</Col>
                    </Row>
                    <Row>
                      <InputForm
                        id={11}
                        width={3}
                        show={!inReadMode && !this.value[11] ? false : true}
                        verify='number'
                        label=''
                        placeholder='Длина рабочей зоны, мм'
                        description='Гидроабразивная резка'
                        value={this.value[11] ? this.value[11].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={12}
                        width={3}
                        show={!inReadMode && !this.value[12] ? false : true}
                        verify='number'
                        label=''
                        placeholder='Ширина рабочей зоны, мм'
                        description='Гидроабразивная резка'
                        value={this.value[12] ? this.value[12].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={13}
                        width={3}
                        show={!inReadMode && !this.value[13] ? false : true}
                        verify='number'
                        label=''
                        placeholder='Толщина, мм(углер.)'
                        description='Гидроабразивная резка'
                        value={this.value[13] ? this.value[13].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={14}
                        width={3}
                        show={!inReadMode && !this.value[14] ? false : true}
                        verify='number'
                        label=''
                        placeholder='Толщина, мм(нерж.)'
                        description='Гидроабразивная резка'
                        value={this.value[14] ? this.value[14].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Row>&nbsp;</Row>
                    {}
                  </Form.Group>
                  <Row>&nbsp;</Row>
                </Container>

                <AllModalsFooter
                  footerContent={
                    inReadMode && (
                      <Form.Group>
                        <Col>
                          {/* <SaveButtonGreen handleSaveClick={this.handleClickSave} needDisable={false} saveButtonText='Сохранить' /> */}
                          <SaveCurrentDataButton
                            handleSaveClick={this.saveDataWithNotification}
                            needDisable={false}
                            savedDataMessage={this.state.savedDataMessage}
                            buttonTextDefault={'Сохранить'}
                            buttonTextSaved={'Данные сохранены'}
                            needCustomIcon={false}
                          />

                          {/* <Button variant="success" onClick={this.handleClickSave}>Сохранить</Button> */}
                        </Col>
                      </Form.Group>
                    )
                  }
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}
