export function getPagedData(data, currentPage, pageSize) {
  const reversedData = data.slice().reverse();
  const start = currentPage * pageSize;
  const end = start + pageSize;
  return reversedData.slice(start, end).reverse();
}

export function goToNextPage(currentState) {
  const { companyDataFromServer, pageSize } = currentState;
  const maxPages = Math.ceil(companyDataFromServer.length / pageSize) - 1;
  if (currentState.currentPage < maxPages) {
    return { currentPage: currentState.currentPage + 1, showTable: false };
  }
  return null;
}

export function goToPreviousPage(currentState) {
  if (currentState.currentPage > 0) {
    return { currentPage: currentState.currentPage - 1, showTable: false };
  }
  return null;
}