import React from 'react'
import { Form, Button, Card, Alert, Row } from 'react-bootstrap'
import Server from './server/server'
import CheckForm from '../components/questionnaire/CheckForm'
// import LockOpenIcon from '@mui/icons-material/LockOpen';
import AllModalsFooter from './assets/AllModalsFooter'
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined'

export default class Registration extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      showOff: false,
      colorErrorPass: '',
      colorErrorMail: '',
      eMail: '',
      password: '',
      rePassword: '',
      errorPass: false,
      errorMail: false,
      show: false,
      errPassword: false,
      showConditions: false,
      showErrorMsg: false,
      showSuccessMsg: false
    }

    this.handleClickRegistration = this.handleClickRegistration.bind(this)
    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleChangeRePassword = this.handleChangeRePassword.bind(this)
    this.sendDataRegistrationUser = this.sendDataRegistrationUser.bind(this)
    this.doChangeValue = this.doChangeValue.bind(this)
    this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this)
    this.verificationNumberPassword = this.verificationNumberPassword.bind(this)
    this.toggleConditions = this.toggleConditions.bind(this)
    this.closeRegistarationForm = this.closeRegistarationForm.bind(this)
    this.setAlert = this.setAlert.bind(this)
  }

  toggleConditions() {
    this.setState({
      showConditions: !this.state.showConditions
    })
  }

  doChangeVisionBlock(data, id) {
    // console.log(data + ' - ' + id);
    this.setState({ show: data })
  }

  doChangeValue(e) {
    // console.log(e);//test
  }

  handleChangePassword(e) {
    // console.log(e.target.value);
    this.setState({ password: e.target.value })
  }

  handleChangeRePassword(e) {
    // console.log(e.target.value);
    this.setState({ rePassword: e.target.value })
  }

  handleChangeEmail(e) {
    // регулярка для email ->  /^[\w]{1}[\w-\.]*@[\w-]+\.[a-z]{2,4}$/i
    // const re = /^[\w]{1}[\w-\.]*@[\w-]+\.[a-z]{2,4}$/i
    // const valid = re.test(e.target.value);
    // console.log(e.target.value + ' Валидность email:: ' + valid); // test
    this.setState({ eMail: e.target.value })
  }

  verificationNumberPassword(pass) {
    if (isNaN(pass)) {
      // console.log(`pass is NaN:: `, isNaN(pass)); // test
      return false
    }
    return true
  }

  //* проверка на правильность ввода данных
  verificationPassword() {
    if (
      this.state.password !== this.state.rePassword ||
      this.verificationNumberPassword(this.state.password) ||
      this.state.password.length < 6 ||
      this.state.rePassword.length < 6
    ) {
      this.setState({
        errorPass: true,
        colorErrorPass: '#E87C86',
        errPassword: true
      })
      // console.log(" verif error pass ");
      return false
    }
    return true
  }

  verificationMail() {
    const re = /^[\w]{1}[\w-.]*@[\w-]+\.[a-z]{2,4}$/i
    const valid = re.test(this.state.eMail)
    // console.log(`EMAIL valid::: `, valid);

    if (!valid) {
      // if (valid) {
      this.setState({
        errorMail: true,
        colorErrorMail: '#E87C86'
      })
      console.log(' verif error mail ')
      return false
    } else {
      console.log(' verif all ok!!! ')
      return true
    }
  }

  /**
   * проверяем и отправляем данные с формы
   * @param {*} e
   */
  handleClickRegistration(e) {
    e.preventDefault()

    let url = new URL(window.location.href)
    const urlAfter = `${url.protocol}//${url.host}` // формируем короткий URL

    if (this.verificationPassword() && this.verificationMail()) {
      ;(async () => {
        try {
          await this.sendDataRegistrationUser(this.state.eMail, this.state.password)

          // this.setState({ showOff: true });
          setTimeout(() => {
            this.setState({ showOff: true })
            this.props.onChangeShowLoginPage()
            this.props.onChangeShowRegistration()
            setTimeout(() => {
              window.location.href = urlAfter
            })
          }, 5750)
          // setTimeout(() => { window.location.href = urlAfter }) // возвращаем обратно короткий URL
        } catch (err) {
          console.log(`Ошибка регистрации пользователя: `, err)
        }
      })()
    } else {
      setTimeout(() => {
        this.setState({
          errorPass: false,
          errorMail: false,
          colorErrorPass: '',
          colorErrorMail: '',
          errPassword: false
        })
      }, 5750)
    }
  }

  async sendDataRegistrationUser(login, password) {
    try {
      const result = await Server.sendDataRegistration(login, password)
      console.log(result) // test

      if (!result) {
        throw new Error('User not on server')
      }

      //todo: Выдать сообщение, модалку о успешной регистрации
      console.log('All OK, new User on server')
      this.setState({
        showSuccessMsg: true
      })
    } catch (err) {
      console.log(err) // test
      // todo: выдать модалку, что такой email уже зарегистрирован
      console.log('Not OK, User not on server')

      this.setState({
        showErrorMsg: true
      })
    }
  }

  closeRegistarationForm() {
    window.location.reload()
  }

  setAlert() {
    if (!this.state.showErrorMsg && !this.state.showSuccessMsg) {
      return (
        <Alert
          variant='dark'
          className='header_alert_common'
          dismissible
          onClose={this.closeRegistarationForm}
        >
          <Alert.Heading>Регистрация в системе</Alert.Heading>
        </Alert>
      )
    }
    if (this.state.showErrorMsg && !this.state.showSuccessMsg) {
      return (
        <Alert
          variant='danger'
          className='header_alert_common'
          dismissible
          onClose={this.closeRegistarationForm}
        >
          <Alert.Heading style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: '20px' }}>Регистрация в системе</span>
            <span style={{ fontSize: '12px' }}>
              Пользователь с таким электронным адресом уже существует
            </span>
          </Alert.Heading>
        </Alert>
      )
    }

    if (!this.state.showErrorMsg && this.state.showSuccessMsg) {
      return (
        <Alert
          variant='success'
          className='header_alert_common'
          dismissible
          onClose={this.closeRegistarationForm}
        >
          <Alert.Heading style={{ display: 'flex', flexDirection: 'column' }}>
            <span style={{ fontSize: '20px' }}>Регистрация в системе</span>
            <span style={{ fontSize: '12px' }}>Пользователь успешно зарегестрирован!</span>
          </Alert.Heading>
        </Alert>
      )
    }
  }

  render() {
    const colorErrorPass = this.state.colorErrorPass
    const colorErrorMail = this.state.colorErrorMail

    return (
      <>
        <Card className='modal_window'>
          <div className='shadow'></div>
          <div
            className={
              this.state.showOff
                ? 'modal_form_off'
                : 'modal_common p_40 z_index_1000 width_40 overflow_y_auto'
            }
          >
            {this.setAlert()}

            <Card.Body
              className={`container_common ${
                this.state.showConditions ? 'height_600' : 'height_450'
              }`}
            >
              <Form.Group controlId='formBasicEmail'>
                <Form.Label>
                  <b>Адрес электронной почты</b>
                </Form.Label>
                <Form.Control
                  type='email'
                  placeholder='Email'
                  onChange={this.handleChangeEmail}
                  style={{ backgroundColor: `${colorErrorMail}` }}
                />
                <Form.Text className='text-muted'>
                  *Мы никогда не будем делиться вашей электронной почтой ни с кем другим.
                </Form.Text>
              </Form.Group>

              <Form.Group controlId='formBasicPassword'>
                <Form.Label>
                  <b>Пароль</b>
                </Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Пароль'
                  onChange={this.handleChangePassword}
                  className='transition_05s'
                  style={{ backgroundColor: `${colorErrorPass}` }}
                />
                <Form.Text className='text-muted'>
                  <span style={{ color: this.state.errPassword ? '#dc3545' : '#343a40' }}>
                    *Пароль должен состоять из 6 знаков минимум и не должен содержать только цифры.
                  </span>
                </Form.Text>
              </Form.Group>

              <Form.Group controlId='formBasicPassword'>
                <Form.Label>
                  <b>Повторите пароль</b>
                </Form.Label>
                <Form.Control
                  type='password'
                  placeholder='Повторите пароль'
                  onChange={this.handleChangeRePassword}
                  className='transition_05s'
                  style={{ backgroundColor: `${colorErrorPass}` }}
                />
              </Form.Group>

              <Form.Group>
                <CheckForm
                  // id={1}
                  width={12}
                  show={true}
                  placeholder={<>Согласие на обработку персональных данных</>}
                  description='Согласие на обработку персональных данных'
                  // value={this.value[1] ? this.value[1].value : ''}
                  onChangeValue={this.doChangeValue}
                  onChangeVisionBlock={this.doChangeVisionBlock}
                />
              </Form.Group>

              <div
                className={
                  this.state.showConditions
                    ? 'read_conditions_style'
                    : 'read_conditions_style dotted_underline'
                }
                onClick={this.toggleConditions}
              >
                {this.state.showConditions ? 'условия соглашения:' : 'читать условия '}
              </div>

              {this.state.showConditions && (
                <p className='conditions_style'>
                  Политика конфиденциальности персональных данных
                  <Row> &nbsp; </Row>
                  Настоящая Политика конфиденциальности персональных данных (далее – Политика
                  конфиденциальности) действует в отношении всей информации, которую Онлайн
                  платформа https://lotus-uems.ru, (далее – Платформа), размещенная по адресу
                  https://lotus-uems.ru, может получить о Пользователе во время использования
                  Платформы, его предложений и его потребностей.
                  <Row> &nbsp; </Row>
                  1. Определение терминов <br></br>
                  1.1 В настоящей Политике конфиденциальности используются следующие термины:
                  <br></br>
                  1.1.1. «Администрация сайта» (далее – Администрация) – уполномоченные сотрудники
                  на управление сайтом Онлайн платформы https://lotus-uems.ru, действующие от имени
                  АО «ЛОТОС», которые организуют и (или) осуществляют обработку персональных данных,
                  а также определяют цели обработки персональных данных, состав персональных данных,
                  подлежащих обработке, действия (операции), совершаемые с персональными данными.
                  1.1.2. «Персональные данные» - любая информация, относящаяся к прямо или косвенно
                  определенному, или определяемому физическому лицу (субъекту персональных данных).{' '}
                  <br></br>
                  1.1.3. «Обработка персональных данных» - любое действие (операция) или
                  совокупность действий (операций), совершаемых с использованием средств
                  автоматизации или без использования таких средств с персональными данными, включая
                  сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
                  изменение), извлечение, использование, передачу (распространение, предоставление,
                  доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.{' '}
                  <br></br>
                  1.1.4. «Конфиденциальность персональных данных» - обязательное для соблюдения
                  Оператором или иным получившим доступ к персональным данным лицом требование не
                  допускать их распространения без согласия субъекта персональных данных или наличия
                  иного законного основания. 1.1.5. «Онлайн платформа https://lotus-uems.ru» - это
                  совокупность, связанных между собой веб-страниц, размещенных в сети Интернет по
                  уникальному адресу: https://lotus-uems.ru. <br></br>
                  1.1.6. «Субдомены» - это страницы или совокупность страниц, расположенные на
                  доменах третьего уровня, принадлежащие Онлайн платформа https://lotus-uems.ru, а
                  также другие временные страницы, внизу который указана контактная информация
                  Администрации 1.1.5. «Пользователь Онлайн платформа https://lotus-uems.ru » (далее
                  Пользователь) – лицо, имеющее доступ к Онлайн платформа https://lotus-uems.ru,
                  посредством сети Интернет и использующее информацию, и возможности Онлайн
                  платформа https://lotus-uems.ru для удовлетворения своих потребностей. <br></br>
                  1.1.7. «Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый
                  на компьютере пользователя, который веб-клиент или веб-браузер каждый раз
                  пересылает веб-серверу в HTTP-запросе при попытке открыть страницу
                  соответствующего сайта. 1.1.8. «IP-адрес» — уникальный сетевой адрес узла в
                  компьютерной сети, через который Пользователь получает доступ на
                  https://lotus-uems.ru. 1.1.9. «Потребность» - различные потребности, которые
                  Пользователь удовлетворяет с помощью Онлайн платформы https://lotus-uems.ru.
                  <Row> &nbsp; </Row>
                  2. Общие положения<br></br>
                  2.1. Использование Онлайн платформы https://lotus-uems.ru Пользователем означает
                  согласие с настоящей Политикой конфиденциальности и условиями обработки
                  персональных данных Пользователя. <br></br>
                  2.2. В случае несогласия с условиями Политики конфиденциальности Пользователь
                  должен прекратить использовать Онлайн платформу https://lotus-uems.ru. <br></br>
                  2.3. Настоящая Политика конфиденциальности применяется к Онлайн платформе
                  https://lotus-uems.ru. Платформа не контролирует и не несет ответственность за
                  сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным на
                  Онлайн платформе https://lotus-uems.ru. <br></br>
                  2.4. Администрация не проверяет достоверность персональных данных, предоставляемых
                  Пользователем.
                  <Row> &nbsp; </Row>
                  3. Предмет политики конфиденциальности<br></br>
                  3.1. Настоящая Политика конфиденциальности устанавливает обязательства
                  Администрации по неразглашению и обеспечению режима защиты конфиденциальности
                  персональных данных, которые Пользователь предоставляет по запросу Администрации
                  при регистрации на Онлайн платформе https://lotus-uems.ru, при подписке на
                  информационную e-mail рассылку или при оформлении заказа на удовлетворение
                  потребности. <br></br>
                  3.2. Персональные данные, разрешённые к обработке в рамках настоящей Политики
                  конфиденциальности, предоставляются Пользователем путём заполнения форм на Онлайн
                  платформе https://lotus-uems.ru и включают в себя следующую информацию: 3.2.1.
                  фамилию, имя, отчество Пользователя; <br></br>
                  3.2.2. контактный телефон Пользователя; <br></br>
                  3.2.3. адрес электронной почты (e-mail); <br></br>
                  3.2.4. фотографию;<br></br>
                  3.2.5. Реквизиты (ИНН, ОГРН, ОГРНИП, Наименование, банковские реквизиты).{' '}
                  <br></br>
                  3.3. Онлайн платформа https://lotus-uems.ru защищает Данные, которые автоматически
                  передаются при посещении страниц: <br></br>- IP адрес; <br></br>- информация из
                  cookies; <br></br>- информация о браузере <br></br>- время доступа; <br></br>-
                  реферер (адрес предыдущей страницы). <br></br>
                  3.3.1. Отключение cookies может повлечь невозможность доступа к частям сайта,
                  требующим авторизации. <br></br>
                  3.3.2. Платформа осуществляет сбор статистики об IP-адресах своих посетителей.
                  Данная информация используется с целью предотвращения, выявления и решения
                  технических проблем. <br></br>
                  3.4. Любая иная персональная информация, не оговоренная выше (история посещения,
                  используемые браузеры, операционные системы и т.д.) подлежит надежному хранению и
                  нераспространению, за исключением случаев, предусмотренных в п.п. 5.2. и 5.3.
                  настоящей Политики конфиденциальности. <br></br>
                  <Row> &nbsp; </Row>
                  4. Цели сбора персональной информации пользователя<br></br>
                  4.1. Персональные данные Пользователя Администрация может использовать в целях:{' '}
                  <br></br>
                  4.1.1. Идентификации Пользователя, зарегистрированного на Онлайн платформе
                  https://lotus-uems.ru для его дальнейшей авторизации, оформления заявки на
                  удовлетворение потребности и других действий. <br></br>
                  4.1.2. Предоставления Пользователю доступа к персонализированным данным сайта
                  Онлайн платформы https://lotus-uems.ru. <br></br>
                  4.1.3. Установления с Пользователем обратной связи, включая направление
                  уведомлений, запросов, касающихся использования Онлайн платформы
                  https://lotus-uems.ru, удовлетворения потребностей Пользователя. <br></br>
                  4.1.4. Определения места нахождения Пользователя для обеспечения безопасности,
                  предотвращения мошенничества. <br></br>
                  4.1.5. Подтверждения достоверности и полноты персональных данных, предоставленных
                  Пользователем. <br></br>
                  4.1.6. Создания учетной записи для использования частей Онлайн платформа
                  https://lotus-uems.ru, если Пользователь дал согласие на создание учетной записи.{' '}
                  <br></br>
                  4.1.7. Уведомления Пользователя по электронной почте. <br></br>
                  4.1.8. Предоставления Пользователю эффективной технической поддержки при
                  возникновении проблем, связанных с использованием Онлайн платформа
                  https://lotus-uems.ru. <br></br>
                  4.1.9. Предоставления Пользователю с его согласия информации о новостной рассылки
                  и иных сведений от имени Онлайн платформа https://lotus-uems.ru. <br></br>
                  4.1.10. Осуществления рекламной деятельности с согласия Пользователя. <br></br>
                  <Row> &nbsp; </Row>
                  5. Способы и сроки обработки персональной информации<br></br>
                  5.1. Обработка персональных данных Пользователя осуществляется без ограничения
                  срока, любым законным способом, в том числе в информационных системах персональных
                  данных с использованием средств автоматизации или без использования таких средств.{' '}
                  <br></br>
                  5.2. Пользователь соглашается с тем, что Администрация вправе передавать
                  персональные данные третьим лицам, в частности, курьерским службам, организациями
                  почтовой связи (в том числе электронной), операторам электросвязи, исключительно в
                  целях выполнения заявки по удовлетворению потребности Пользователя, оформленной на
                  Онлайн платформа https://lotus-uems.ru, включая доставку, документацию или e-mail
                  сообщений. <br></br>
                  5.3. Персональные данные Пользователя могут быть переданы уполномоченным органам
                  государственной власти Российской Федерации только по основаниям и в порядке,
                  установленным законодательством Российской Федерации. <br></br>
                  5.4. При утрате или разглашении персональных данных Администрация вправе не
                  информировать Пользователя об утрате или разглашении персональных данных.{' '}
                  <br></br>
                  5.5. Администрация принимает необходимые организационные и технические меры для
                  защиты персональной информации Пользователя от неправомерного или случайного
                  доступа, уничтожения, изменения, блокирования, копирования, распространения, а
                  также от иных неправомерных действий третьих лиц. <br></br>
                  5.6. Администрация совместно с Пользователем принимает все необходимые меры по
                  предотвращению убытков или иных отрицательных последствий, вызванных утратой или
                  разглашением персональных данных Пользователя.
                  <Row> &nbsp; </Row>
                  6. Права и обязанности сторон<br></br>
                  6.1. Пользователь вправе: <br></br>
                  6.1.1. Принимать свободное решение о предоставлении своих персональных данных,
                  необходимых для использования Онлайн платформа https://lotus-uems.ru, и давать
                  согласие на их обработку. <br></br>
                  6.1.2. Обновить, дополнить предоставленную информацию о персональных данных в
                  случае изменения данной информации. <br></br>
                  6.1.3. Пользователь имеет право на получение у Администрации информации,
                  касающейся обработки его персональных данных, если такое право не ограничено в
                  соответствии с федеральными законами. Пользователь вправе требовать от
                  Администрации уточнения его персональных данных, их блокирования или уничтожения в
                  случае, если персональные данные являются неполными, устаревшими, неточными,
                  незаконно полученными или не являются необходимыми для заявленной цели обработки,
                  а также принимать предусмотренные законом меры по защите своих прав. 6.2.
                  Администрация обязана: <br></br>
                  6.2.1. Использовать полученную информацию исключительно для целей, указанных в п.
                  4 настоящей Политики конфиденциальности. 6.2.2. Обеспечить хранение
                  конфиденциальной информации в тайне, не разглашать без предварительного
                  письменного разрешения Пользователя, а также не осуществлять продажу, обмен,
                  опубликование, либо разглашение иными возможными способами переданных персональных
                  данных Пользователя, за исключением п.п. 5.2 и 5.3. настоящей Политики
                  Конфиденциальности. <br></br>
                  6.2.3. Принимать меры предосторожности для защиты конфиденциальности персональных
                  данных Пользователя согласно порядку, обычно используемого для защиты такого рода
                  информации в существующем деловом обороте. <br></br>
                  6.2.4. Осуществить блокирование персональных данных, относящихся к
                  соответствующему Пользователю, с момента обращения или запроса Пользователя, или
                  его законного представителя либо уполномоченного органа по защите прав субъектов
                  персональных данных на период проверки, в случае выявления недостоверных
                  персональных данных или неправомерных действий.
                  <Row> &nbsp; </Row>
                  7. Ответственность сторон<br></br>
                  7.1. Администрация, не исполнившая свои обязательства, несёт ответственность за
                  убытки, понесённые Пользователем в связи с неправомерным использованием
                  персональных данных, в соответствии с законодательством Российской Федерации, за
                  исключением случаев, предусмотренных п.п. 5.2., 5.3. и 7.2. настоящей Политики
                  Конфиденциальности. 7.2. В случае утраты или разглашения Конфиденциальной
                  информации Администрация не несёт ответственность, если данная конфиденциальная
                  информация: 7.2.1. Стала публичным достоянием до её утраты или разглашения. 7.2.2.
                  Была получена от третьей стороны до момента её получения Администрацией Ресурса.
                  7.2.3. Была разглашена с согласия Пользователя. 7.3. Пользователь несет полную
                  ответственность за соблюдение требований законодательства РФ, в том числе законов
                  о рекламе, о защите авторских и смежных прав, об охране товарных знаков и знаков
                  обслуживания, но не ограничиваясь перечисленным, включая полную ответственность за
                  содержание и форму материалов. 7.4. Пользователь признает, что ответственность за
                  любую информацию (в том числе, но не ограничиваясь: файлы с данными, тексты и т.
                  д.), к которой он может иметь доступ как к части Онлайн платформа
                  https://lotus-uems.ru, несет лицо, предоставившее такую информацию. 7.5.
                  Пользователь соглашается, что информация, предоставленная ему как часть Онлайн
                  платформа https://lotus-uems.ru, может являться объектом интеллектуальной
                  собственности, права на который защищены и принадлежат другим Пользователям,
                  партнерам или рекламодателям, которые размещают такую информацию на Онлайн
                  платформа https://lotus-uems.ru. Пользователь не вправе вносить изменения,
                  передавать в аренду, передавать на условиях займа, продавать, распространять или
                  создавать производные работы на основе такого Содержания (полностью или в части),
                  за исключением случаев, когда такие действия были письменно прямо разрешены
                  собственниками такого Содержания в соответствии с условиями отдельного соглашения.
                  7.6. В отношение текстовых материалов (статей, публикаций, находящихся в свободном
                  публичном доступе на Онлайн платформа https://lotus-uems.ru) допускается их
                  распространение при условии, что будет дана ссылка на адрес Платформы
                  https://lotus-uems.ru. 7.7. Администрация не несет ответственности перед
                  Пользователем за любой убыток или ущерб, понесенный Пользователем в результате
                  удаления, сбоя или невозможности сохранения какого-либо Содержания и иных
                  коммуникационных данных, содержащихся на Онлайн платформа https://lotus-uems.ru
                  или передаваемых через него. 7.8. Администрация не несет ответственности за любые
                  прямые или косвенные убытки, произошедшие из-за: использования либо невозможности
                  использования сайта, либо отдельных сервисов; несанкционированного доступа к
                  коммуникациям Пользователя; заявления или поведение любого третьего лица на сайте.
                  7.9. Администрация не несет ответственность за какую-либо информацию, размещенную
                  пользователем на Онлайн платформа https://lotus-uems.ru, включая, но не
                  ограничиваясь: информацию, защищенную авторским правом, без прямого согласия
                  владельца авторского права.
                  <Row> &nbsp; </Row>
                  8. Разрешение споров 8.1. До обращения в суд с иском по спорам, возникающим из
                  отношений между Пользователем и Администрацией, обязательным является предъявление
                  претензии (письменного предложения или предложения в электронном виде о
                  добровольном урегулировании спора). 8.2. Получатель претензии в течение 30
                  календарных дней со дня получения претензии, письменно или в электронном виде
                  уведомляет заявителя претензии о результатах рассмотрения претензии. 8.3. При не
                  достижении согласия спор будет передан на рассмотрение в суд в г. Екатеринбурге.
                  8.4. К настоящей Политике конфиденциальности и отношениям между Пользователем и
                  Администрацией применяется действующее законодательство Российской Федерации.
                  <Row> &nbsp; </Row>
                  9. Дополнительные условия 9.1. Администрация вправе вносить изменения в настоящую
                  Политику конфиденциальности без согласия Пользователя. 9.2. Новая Политика
                  конфиденциальности вступает в силу с момента ее размещения на Онлайн платформа
                  https://lotus-uems.ru, если иное не предусмотрено новой редакцией Политики
                  конфиденциальности. 9.3. Все предложения или вопросы касательно настоящей Политики
                  конфиденциальности следует сообщать по адресу: info@lotus1.ru 9.4. Действующая
                  Политика конфиденциальности размещена на странице по адресу https://lotus-uems.ru{' '}
                </p>
              )}
            </Card.Body>

            <AllModalsFooter
              isPosAbsoluteNedded={true}
              footerContent={
                <Button
                  variant='success'
                  // className='btn_info_filled'
                  type='submit'
                  onClick={this.handleClickRegistration}
                  disabled={
                    (this.state.show && this.state.eMail && this.state.rePassword)
                      ? false
                      : true
                  }
                >
                  <HowToRegOutlinedIcon /> Зарегистрироваться
                </Button>
              }
            />

            {/* <Card.Footer  style={{display: 'flex', flexDirection: 'flex-end'}}>
                            <Button
                                className='btn_info_filled'
                                type="submit"
                                onClick={this.handleClickRegistration}
                                disabled={this.state.show ? false : true}
                            >
                                Зарегистрироваться
                            </Button>
                        </Card.Footer> */}
          </div>
        </Card>
      </>
    )
  }
}
