import Server from './server/server'


/**
 * Записываем серийный номер в LocalStorage
 * @param {Array} inn - массив из одного значения (ИНН компании)
 */
const sendSerialToLocalStorage = (inn) => {
    (async () => {
        try {
            const serial = await loadPosition(inn[0])
            localStorage.setItem('serial', serial)

        } catch (err) {
            console.log(`Ошибка при записывании серийного номера компании в LocalStorage: `, err);
        }
    })()
}

export default sendSerialToLocalStorage;

/**
 * Загрузка позиции серйного номера из базы
 * @param {String} id  ИНН компании
 * @returns {String} serial
 */
const loadPosition = async (id) => {
    try {
        const positionObject = await Server.searchPosition('global', 'serial', 'inn', id)
        // console.log(`POSITION COMPANY::: `, positionObject); // test

        return (positionObject.serial);
    } catch (err) {
        console.log(`Позиция предприятия не найдена: `, err);
        return 0;
    }
}