/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import CheckForm from './CheckForm'
import Form_Fourteen_Add from './Form_Fourteen_Add'
import ModalQuit from '../ModalQuit'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { nanoid } from 'nanoid'
import CommonAlert from './CommonAlert'
import AllModalsFooter from '../assets/AllModalsFooter'
import { AddButton, SaveCurrentDataButton } from '../assets/BasicButtons'
import { createData } from './functionsForm'
import { isEqual } from 'lodash-es'

export default class Form_Fourteen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'rus',
      dataValue: [],
      id: 2,
      id1: 15,
      id2: 28,
      id3: 41,
      maxId: 53,
      nameForm: 'Fourteen',
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      base: [],
      base1: [],
      base2: [],
      base3: [],
      show: false,
      show1: false,
      show2: false,
      show3: false,
      showQuit: false,
      showContent: true,
      inReadMode: true,
      previousState: [],
      savedDataMessage: false,
      dataBeenSaved: false
    }

    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.handleClickAdd = this.handleClickAdd.bind(this)
    this.handleClickAdd1 = this.handleClickAdd1.bind(this)
    this.handleClickAdd2 = this.handleClickAdd2.bind(this)
    this.handleClickAdd3 = this.handleClickAdd3.bind(this)
    this.addForm = this.addForm.bind(this)
    this.addForm1 = this.addForm1.bind(this)
    this.addForm2 = this.addForm2.bind(this)
    this.addForm3 = this.addForm3.bind(this)
    this.createBlockForm = this.createBlockForm.bind(this)
    this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this)
    this.doChangeVisionBlock1 = this.doChangeVisionBlock1.bind(this)
    this.doChangeVisionBlock2 = this.doChangeVisionBlock2.bind(this)
    this.doChangeVisionBlock3 = this.doChangeVisionBlock3.bind(this)
    this.doEmpty = this.doEmpty.bind(this)

    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.hideContent = this.hideContent.bind(this)
    this.setCompanyName = this.setCompanyName.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
  }

  saveDataWithNotification() {
    this.handleClickSave()

    this.setState({
      savedDataMessage: true,
      dataBeenSaved: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }

  hideContent() {
    this.setState({ showContent: false })
  }

  setCompanyName() {
    if (this.props.companyName && this.props.companyName.length > 0) {
      this.setState({
        companyName: this.props.companyName
      })
    }
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })
  }

  //*Пустышка
  doEmpty() {}

  //* меняем видимость блока при выборе ДА / НЕТ
  doChangeVisionBlock(data) {
    if (this.state.lengthDataFromServer > 0) {
      if (data === true || this.props.data[1].value === true) {
        this.setState({ show: true })
      } else {
        this.setState({ show: false })
      }
    } else {
      if (data === true) {
        this.setState({ show: true })
      } else {
        this.setState({ show: false })
      }
    }
  }

  doChangeVisionBlock1(data) {
    if (this.state.lengthDataFromServer > 0) {
      if (data === true || this.props.data[14].value === true) {
        this.setState({ show1: true })
      } else {
        this.setState({ show1: false })
      }
    } else {
      if (data === true) {
        this.setState({ show1: true })
      } else {
        this.setState({ show1: false })
      }
    }
  }

  doChangeVisionBlock2(data) {
    if (this.state.lengthDataFromServer > 0) {
      if (data === true || this.props.data[27].value === true) {
        this.setState({ show2: true })
      } else {
        this.setState({ show2: false })
      }
    } else {
      if (data === true) {
        this.setState({ show2: true })
      } else {
        this.setState({ show2: false })
      }
    }
  }

  doChangeVisionBlock3(data) {
    if (this.state.lengthDataFromServer > 0) {
      if (data === true || this.props.data[40].value === true) {
        this.setState({ show3: true })
      } else {
        this.setState({ show3: false })
      }
    } else {
      if (data === true) {
        this.setState({ show3: true })
      } else {
        this.setState({ show3: false })
      }
    }
  }

  handleClickAdd() {
    this.addForm()
  }

  handleClickAdd1() {
    this.addForm1()
  }

  handleClickAdd2() {
    this.addForm2()
  }

  handleClickAdd3() {
    this.addForm3()
  }

  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  doQuitWithSave() {
    this.setState({ showQuit: false })
    this.handleClickSave()
    this.props.onChangeView('Fourteen')
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView('Fourteen')
  }

  //*формируем данные в массив объектов для отправки на сервер
  handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach((dataD) => {
        if (+dataD.id === i) {
          data[i] = {
            id: dataD.id,
            fid: dataD.fid,
            description: dataD.description,
            information: dataD.information,
            value: dataD.value
          }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach((dataE) => {
        if (+dataE.id === i) {
          data[i] = {
            id: dataE.id,
            fid: dataE.fid,
            description: dataE.description,
            information: dataE.information,
            value: dataE.value
          }
        }
      })
    }
    this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере
    if (this.state.lengthDataFromServer < 1) {
      console.log('CREATE DATA')
      this.props.onCreateDataServer(data, 'Fourteen', localStorage.getItem('idUser'))
    } else {
      console.log('UPDATE DATA')
      this.props.onUpdateDataOnServer(data, 'Fourteen', localStorage.getItem('idUser'))
    }
    // setTimeout(() => { console.log(this.state.dataOnServer) })//test
    // this.handleClickShadow();
  }

  //* данные с полей формы, формируются в массив объектов
  doChangeValue(data) {
    console.log(data) //test
    this.setState({ dataValue: [...this.state.dataValue, data] })
    setTimeout(() => {
      console.log(this.state.dataValue)
    }) //test
    this.setState({
      dataBeenSaved: false
    })
  }

  addForm() {
    if (this.state.id < this.state.id1 - 2) {
      this.setState({
        base: [
          ...this.state.base,
          <React.Fragment key={this.state.id}>
            <Form_Fourteen_Add id={this.state.id} placeholder='Сертификаты' value={this.value} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!this.state.inReadMode} />
          </React.Fragment>
        ],
        id: +this.state.id + 2
        // maxId: +this.state.maxId + 5
      })
    }
  }

  addForm1() {
    if (this.state.id1 < this.state.id2 - 2) {
      this.setState({
        base1: [
          ...this.state.base1,
          <React.Fragment key={this.state.id1}>
            <Form_Fourteen_Add id={this.state.id1} placeholder='Декларации' value={this.value} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!this.state.inReadMode} />
          </React.Fragment>
        ],
        id1: +this.state.id1 + 2
        // maxId: +this.state.maxId + 5
      })
    }
  }

  addForm2() {
    if (this.state.id2 < this.state.id3 - 2) {
      this.setState({
        base2: [
          ...this.state.base2,
          <React.Fragment key={this.state.id2}>
            <Form_Fourteen_Add id={this.state.id2} placeholder='Аттестации' value={this.value} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!this.state.inReadMode} />
          </React.Fragment>
        ],
        id2: +this.state.id2 + 2
        // maxId: +this.state.maxId + 5
      })
    }
  }

  addForm3() {
    if (this.state.id3 < this.state.maxId) {
      this.setState({
        base3: [
          ...this.state.base3,
          <React.Fragment key={this.state.id3}>
            <Form_Fourteen_Add id={this.state.id3} placeholder='Аккредитации' value={this.value} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!this.state.inReadMode} />
          </React.Fragment>
        ],
        id3: +this.state.id3 + 2
        // maxId: +this.state.maxId + 5
      })
    }
  }

  //*создаем необходимое количество блоков
  createBlockForm() {
    let lengthData = this.state.lengthDataFromServer
    let data = this.state.dataFromServer

    this.addForm()
    this.addForm1()
    this.addForm2()
    this.addForm3()

    for (let i = 0; i < lengthData; i++) {
      if ([4, 6, 8, 10, 12].includes(i) && Number(data[i].id > 0)) {
        this.addForm()
      }

      if ([17, 19, 21, 23, 25].includes(i) && Number(data[i].id > 0)) {
        this.addForm1()
      }

      if ([30, 32, 34, 36, 38].includes(i) && Number(data[i].id > 0)) {
        this.addForm2()
      }

      if ([43, 45, 47, 49, 51].includes(i) && Number(data[i].id > 0)) {
        this.addForm3()
      }
    }
  }

  compare() {
    const prev = this.state.previousState
    const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)

    const isStateEqual = isEqual(prev, curr)
    if (isStateEqual) {
      return true
    }
    return false
  }

  smartWindowFunction() {
    const isStateEqual = this.compare()
    const { dataBeenSaved, dataValue } = this.state
    if (isStateEqual || dataBeenSaved || dataValue.length === 0) {
      this.props.onChangeView('Fourteen')
    } else {
      this.handleClickShadow()
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer })
      console.log('DATA on dataFromServer')
      console.log(this.props.data)
    }, 1000)
    setTimeout(() => {
      this.createBlockForm()
      if (this.props.lengthDataFromServer > 0) {
        this.doChangeVisionBlock()
        this.doChangeVisionBlock1()
        this.doChangeVisionBlock2()
        this.doChangeVisionBlock3()
      }
    }, 1500)

    this.setState({
      inReadMode: this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined
    })

    this.setCompanyName()

    if (this.props.data.length) {
      const previousState = JSON.parse(JSON.stringify(this.props.data))
      this.setState({ previousState })
    }
  }

  render() {
    console.log('RENDER', this.state.dataValue)

    const view = this.props.view
    this.value = this.props.data
    const show = this.state.show
    const show1 = this.state.show1
    const show2 = this.state.show2
    const show3 = this.state.show3
    const viewQuest = this.props.viewQuest

    //условие по которому компонент определяет находится ли он в формате чтения
    const inReadMode = this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined

    return (
      <>
        {inReadMode && <>{this.state.showQuit ? <ModalQuit onQuitWithSave={this.doQuitWithSave} onQuitWithoutSave={this.doQuitWithoutSave} closeQuitModal={this.closeQuitModal} /> : ''}</>}

        {this.state.showContent && (
          <div className='modal_window' style={{ display: view ? 'block' : 'none' }}>
            <div
              className={viewQuest ? 'shadow_form' : 'shadow_form_off'}
              // onClick={this.handleClickShadow}
              onClick={this.smartWindowFunction}
            ></div>
            <Form className={viewQuest ? 'form_main' : 'form_main_view'}>
              <CommonAlert
                inReadMode={inReadMode}
                handleClickHeader={this.handleClickHeader}
                // handleClickShadow={this.handleClickShadow}
                handleClickShadow={this.smartWindowFunction}
                hideContent={this.hideContent}
                icon={<InsertDriveFileOutlinedIcon fontSize='large' className='icon_primary' />}
                companyName={this.state.companyName}
                sectionName='Разрешительная документация '
              />
              <div>
                <Container className='form_container'>
                  <Form.Group>
                    <Row>
                      <Col style={{ paddingTop: '28px' }}>Наличие следующей разрешительной документации:</Col>
                    </Row>
                  </Form.Group>

                  <Container fluid>
                    <Form.Group>
                      <Row>
                        <CheckForm
                          name={nanoid()}
                          className={show && 'fw_bolder'}
                          id={1}
                          width={4}
                          // show={!inReadMode && !this.value[1].value ? false : true}
                          show={!inReadMode ? false : true}
                          placeholder='Сертификаты'
                          description='Разрешительная документация, сертификаты'
                          value={this.value[1] ? this.value[1].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doChangeVisionBlock}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                    </Form.Group>
                  </Container>

                  <Container fluid style={{ display: show ? 'block' : 'none' }}>
                    <Row> &nbsp; </Row>
                    <Form.Group>
                      {this.state.base}
                      {
                        inReadMode && <AddButton handleSaveClick={this.handleClickAdd} needDisable={false} buttonText='позицию' />
                        // <Button
                        //     onClick={this.handleClickAdd}
                        //     className='btn_info_filled'
                        //     variant='info'
                        // >
                        //     <AddOutlinedIcon className='p_3' /> Добавить позицию
                        // </Button>
                      }
                      <Row> &nbsp; </Row>
                    </Form.Group>
                  </Container>
                  {show && (
                    <Row>
                      {' '}
                      <div className='border_top_topline_100 mb_10 mt_10'></div>{' '}
                    </Row>
                  )}
                  <Container fluid>
                    <Form.Group>
                      <Row>
                        <CheckForm
                          name={nanoid()}
                          className={show1 && 'fw_bolder'}
                          id={14}
                          width={4}
                          // show={!inReadMode && !this.value[14].value ? false : true}
                          show={!inReadMode ? false : true}
                          placeholder='Декларации'
                          description='Разрешительная документация, декларации'
                          value={this.value[14] ? this.value[14].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doChangeVisionBlock1}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                    </Form.Group>
                  </Container>

                  <Container fluid style={{ display: show1 ? 'block' : 'none' }}>
                    <Row> &nbsp; </Row>
                    <Form.Group>
                      {this.state.base1}

                      {inReadMode && (
                        <AddButton handleSaveClick={this.handleClickAdd1} needDisable={false} buttonText='позицию' />
                        // <Button variant='info' onClick={this.handleClickAdd1} className='btn_info_filled'>
                        //   <AddOutlinedIcon className='p_3' /> Добавить позицию
                        // </Button>
                      )}
                      <Row> &nbsp; </Row>
                    </Form.Group>
                  </Container>

                  {show1 && (
                    <Row>
                      {' '}
                      <div className='border_top_topline_100 mb_10 mt_10'></div>{' '}
                    </Row>
                  )}

                  <Container fluid>
                    <Form.Group>
                      <Row>
                        <CheckForm
                          name={nanoid()}
                          className={show2 && 'fw_bolder'}
                          id={27}
                          width={4}
                          // show={!inReadMode && !this.value[27].value ? false : true}
                          show={!inReadMode ? false : true}
                          placeholder='Аттестации'
                          description='Разрешительная документация, аттестации'
                          value={this.value[27] ? this.value[27].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doChangeVisionBlock2}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                    </Form.Group>
                  </Container>

                  <Container fluid style={{ display: show2 ? 'block' : 'none' }}>
                    <Row> &nbsp; </Row>
                    <Form.Group>
                      {this.state.base2}

                      {inReadMode && (
                        <AddButton handleSaveClick={this.handleClickAdd2} needDisable={false} buttonText='позицию' />
                        // <Button variant='info' onClick={this.handleClickAdd2} className='btn_info_filled'>
                        //   <AddOutlinedIcon className='p_3' /> Добавить позицию
                        // </Button>
                      )}
                      <Row> &nbsp; </Row>
                    </Form.Group>
                  </Container>
                  {show2 && (
                    <Row>
                      <div className='border_top_topline_100 mb_10 mt_10'></div>
                    </Row>
                  )}
                  <Container fluid>
                    <Form.Group>
                      <Row>
                        <CheckForm
                          name={nanoid()}
                          className={show3 && 'fw_bolder'}
                          id={40}
                          width={4}
                          // show={!inReadMode && !this.value[40].value ? false : true}
                          show={!inReadMode ? false : true}
                          placeholder='Аккредитации'
                          description='Разрешительная документация, аккредитации'
                          value={this.value[40] ? this.value[40].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doChangeVisionBlock3}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                    </Form.Group>
                  </Container>

                  <Container fluid style={{ display: show3 ? 'block' : 'none' }}>
                    <Row> &nbsp; </Row>
                    <Form.Group>
                      {this.state.base3}

                      {
                        inReadMode && <AddButton handleSaveClick={this.handleClickAdd3} needDisable={false} buttonText='позицию' />
                        // <Button
                        //     variant="info"
                        //     onClick={this.handleClickAdd3}
                        //     className='btn_info_filled'
                        // >
                        //     <AddOutlinedIcon className='p_3' /> Добавить позицию
                        // </Button>
                      }
                      <Row> &nbsp; </Row>
                    </Form.Group>
                  </Container>
                  {show3 && (
                    <Row>
                      <div className='border_top_topline_100 mb_10 mt_10'></div>
                    </Row>
                  )}
                  <Row> &nbsp; </Row>
                </Container>
                <AllModalsFooter
                  footerContent={
                    inReadMode && (
                      <SaveCurrentDataButton
                        handleSaveClick={this.saveDataWithNotification}
                        needDisable={false}
                        savedDataMessage={this.state.savedDataMessage}
                        buttonTextDefault={'Сохранить'}
                        buttonTextSaved={'Данные сохранены'}
                        needCustomIcon={false}
                      />
                    )
                    // <Button
                    //     variant="success"
                    //     onClick={this.handleClickSave}
                    // >
                    //     Сохранить
                    // </Button>
                  }
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}
