import React, { useEffect } from 'react'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp'
import { Alert, Button, Modal } from 'react-bootstrap'
import { SaveIcon, LeaveIcon } from './CommonIcons'

//***INCOMING elements:***
//onQuitWithSave - выход с сохранением (сохраняет, закрывает все модалки)
//onQuitWithoutSave - выход без сохранения (не сохраняет, закрывает все модалки)
//closeQuitModal - закрывает только модалку выхода
//modalTitle - заголовок модалки выхода, например "Выход"
//modalBody - основной текст, например "Вы действительно хотите выйти из формы"
//primaryButton - гдавная синяя кнопка слева, например, "Сохранить"
//secondaryButton - второстепенная кнопка справа, например "Отменить"
//icon - иконка в заголовке

export default function QuitForm({
  onQuitWithSave,
  onQuitWithoutSave,
  closeQuitModal,
  modalTitle,
  modalBody,
  primaryButton,
  secondaryButton,
  variant
}) {
  useEffect(() => {
    const onKeyPressHandler = (event) => {
      if (event.keyCode === 13) {
        handleClickQuitWithSave()
      }
    }
    document.addEventListener('keydown', onKeyPressHandler)
    return () => {
      document.removeEventListener('keydown', onKeyPressHandler)
    }
  }, [])

  const handleClickQuitWithSave = () => {
    onQuitWithSave()
  }

  const handleClickQuitWithoutSave = () => {
    onQuitWithoutSave()
  }

  return (
    <>
      <div>
        <div className='shadow z_index_extra_big' onClick={closeQuitModal}></div>

        <Modal.Dialog className='quit_modal z_index_extra_big width_500'>
          <Alert
            variant={variant}
            style={{ borderBottom: '1px #dee2e6 solid' }}
            dismissible
            onClose={closeQuitModal}
          >
            <Modal.Title>
              {<ExitToAppSharpIcon fontSize='large' className='icon_leave_modal' />}{' '}
              {modalTitle}
            </Modal.Title>
          </Alert>

          <Modal.Body>
            <b>{modalBody}</b>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant={'primary'}
              onClick={handleClickQuitWithSave}
            >
              <SaveOutlinedIcon style={{ marginRight: '5px' }} />
              {primaryButton}
            </Button>

            <Button variant='secondary' onClick={handleClickQuitWithoutSave}>
              <LeaveIcon />
              {secondaryButton}
            </Button>
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    </>
  )
}
