

/**
 * Преобразуем объект UEMS в объект STHE
 * @param {Object} objUEMS - объект UEMS
 * @returns {Object} objSTHE - объект STHE
 */
const collectData = (objUEMS) => {
    const p = objUEMS;
    let arrMaterials = []
    let materialsUserPrice = {}

    if (p?.arrMaterials?.length) {
        arrMaterials = p.arrMaterials.map(itm => {
            Object.assign(materialsUserPrice,
                {
                    [`${itm.category}_${itm.steelGrade}`]: null
                }
            )
            return (
                {
                    'category': String(itm.category),
                    'steel': String(itm.steelGrade),
                    'weight': Number(itm.totalWeight),
                    'comment': String(itm.noteMaterials)
                }
            )
        })
    }

    if (p.pipesMaterial) {
        Object.assign(materialsUserPrice,
            { [`Труба теплообменная_${p.pipesMaterial}`]: null })
    }

    if (p.gratingMaterial) {
        Object.assign(materialsUserPrice,
            { [`Трубные решетки_${p.gratingMaterial}`]: null })
    }

    if (p.partitionMaterial) {
        Object.assign(materialsUserPrice,
            { [`Поперечные перегородки_${p.partitionMaterial}`]: null })
    }

    const objSTHE = {
        'key': 'zaetha8Ii5vohl3joochae0e',
        'UserAnswers': {
            'date_dp': null,
            'D_apparat': Number(p.bodyDiameter), // 1 - диаметр корпуса
            'revision': Number(p.numberTR), // 1 - номер ТП
            'D_pipe': Number(p.pipeOuterDiameter), // 3 - диаметр трубы наружный ?
            'izm_user': '',
            'L_pipe': Number(p.beamLength), // 1 - длина пучка
            'tube_sheets': [{
                'tube_sheet_D': Number(p.grateDiameter1), // 4- диаметр мм
                'tube_sheet_S': Number(p.latticeArea1), // 4 - площадь мм
                'tube_sheet_N': Number(p.latticeQuantity1), // 4 - кол-во
                'tube_sheet_type': String(p.workpieceType1) // 4 - тип изготовления
            },
            {
                'tube_sheet_D': Number(p.grateDiameter2), // 4- диаметр мм
                'tube_sheet_S': Number(p.latticeArea2), // 4 - площадь мм
                'tube_sheet_N': Number(p.latticeQuantity2), // 4 - кол-во
                'tube_sheet_type': String(p.workpieceType2) // 4 - тип изготовления
            }],
            'dizm': null, // ? ********
            'delivery': {
                'address': p.deliveryAddress, // 9 - адресс
                'cost': Number(p.costDelivery), // 9 - стоимость
                'cost_comment': p.commentOnTheCost, // 9- комментарий к стоимости
                'days': Number(p.deliveryTime), // 9 - срок доставки
                'days_comment': p.commentOnDeliveryTime // 9 - комментарий к сроку доставки
            },
            'production_params': {
                'cost_human': null, // ? ********
                'L': Number(p.beamLength), // длина пучка - 1
                'TUBE_PN_dd': String(p.designPressurePipeSpace), // расчетное давление трубное - 8
                'SHELL_T': Number(p.designTemperatureInterpipeSpace), // расчетная температура - 8 межтрубном
                'climate': 'На улице',  //? ********
                'D': String(p.bodyDiameter),// 1 - диаметр корпуса
                'tube_pass': Number(p.numberMoves), // кол-во ходов - 8
                'TEMA': String(p.TEMAtype), // ТЕМА - 8
                'material': String(p.bodyMaterial), //? материал корпуса??????? **********
                'Massa': Number(p.bodyWeight), // масса корпуса - 1
                'SHELL_PN_dd': String(p.designPressureInterpipeSpace), // давление в межтрубном пространстве - 8
                'tube_install_type': 'Сварка с развальцовкой', //? ************ где взять?
                'N': Number(p.numberDevices), // кол-во аппартов - 8
                'hard_coef': Boolean(p.increasedManufacturingComplexity), // повышенная сложность изготовления
                'TUBE_T': Number(p.designTemperaturePipeSpace) // расчетная температура - 8 трубном
            },
            'cross_partition_material': String(p.partitionMaterial), // материал перегородок - 5
            'tube_sheet_material': String(p.gratingMaterial), // материал решеток - 2
            'materials_user_price': materialsUserPrice,
            // { // формируем из 6 - Материалы
            // 'Труба теплообменная_Сталь 20': null,  // 6 - Материалы
            // 'Поковки_09Г2С': null, // 6 - Материалы
            // 'Крепеж_40Х': null, // 6 - Материалы
            // 'Листовой прокат из углеродистой стали_Ст3сп5': null, // 6 - Материалы
            // 'Листовой прокат из углеродистой стали_09Г2С': null, // 6 - Материалы
            // 'Трубные решетки_09Г2С': null, // материал решеток - 2
            // 'Поперечные перегородки_Ст3': null, // из раздела 5 - материал перегородок
            // 'Сортовой прокат из углеродистой стали_Сталь 20': null // 6 - Материалы
            // }

            'cross_partition_th': Number(p.thicknessTransversePartitions), // толщина поперечных перегородок - 5
            'quantity_cross_partition': Number(p.numberTransversePartitions), // кол-во поперечных перегродок - 5
            'N_pipe': Number(p.numberHeatExchangePipes), // кол-во теплообменных труб - 3
            'reload_prices': false,
            'pvyrez_cross_partition': Number(p.septumCutPercentage), // процент выреза в перегородке - 5
            'shell_material': String(p.bodyMaterial), // материа корпуса - 2
            'Massa': Number(p.bodyWeight), // масса корпуса - 1
            'S_pipe': Number(p.pipeWallThickness), // толщина стенки трубы - 3
            'pipe_material': String(p.pipesMaterial), // материал труб - 2
            'camera_material': String(p.cameraMaterial), // материал камеры - 2
            'CaseNum': Number(p.body), //? кол-во корпусов - 1
            'tema': 'M', // ? ****
            'izm': Number(p.measurementNumber), // номер измерений - 1
            'apparatus': String(p.application), // заявка - 1
            'seal_type_dd': String(p.sealing), // уплотнение - sealing - 7
            'Materials': arrMaterials, // массив материалов
            'user_last_name': '',
            'margin': 30, //? допуск ****
            'cap_material': String(p.coverMaterial), // материал крышки - 2
        },
    }

    return objSTHE
}

export default collectData;