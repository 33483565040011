import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

export const ExitConfirmationModal = ({ handleClickExit, toggleExitModal }) => {
  useEffect(() => {
    const onKeyPressHandler = event => {
      if (event.keyCode === 13) {
        handleClickExit()
      }
    }
    document.addEventListener('keydown', onKeyPressHandler)
    return () => {
      document.removeEventListener('keydown', onKeyPressHandler)
    }
  })

  return (
    <div className='modal_window'>
      <div className='shadow z_index_extra_big'></div>

      <Modal.Dialog className='modal_common z_index_extra_big width_500'>
        <Modal.Header>
          <Modal.Title>
            <ExitToAppOutlinedIcon fontSize='large' className='icon_leave_modal' /> Выход из системы
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Вы действительно хотите выйти из приложения?</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant='primary' onClick={handleClickExit}>
            <ExitToAppOutlinedIcon  style={{ marginRight: '5px' }} />
            Выйти
          </Button>

          <Button variant='secondary' onClick={toggleExitModal}>
            <HighlightOffOutlinedIcon  style={{ marginRight: '5px' }} />
            Отменить
          </Button>
        </Modal.Footer>
      </Modal.Dialog>
    </div>
  )
}
// onKeyDown = { this.onKeyPressHandler }
