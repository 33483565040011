import React, { useState, useEffect } from 'react'
import { Alert, Col, Container, Form, Row } from 'react-bootstrap'

export const TechTemplate = ({ closeTechTemplate, resultTemplateBlock, resultTemplate }) => {

    const [renderedParams, setRenderedParams] = useState([])


    useEffect(() => {
        if (resultTemplateBlock !== 0) {
            loadGeneralData(resultTemplateBlock)
        }

    }, [])




    const name = resultTemplate.replace('TradeTemplate_', '')
    const empty = () => { }



    const loadGeneralData = (array) => {

        if (array) {
            const result = array.slice(1)
            let renderedArray = [];
            let data = {}

            result.forEach((el, index) => {

                if (el) {
                    if (+el.id % 2 !== 0) {
                        data = {
                            key: el.value,
                            value: result[index + 1] && result[index + 1].value !== '' ? result[index + 1].value : <i>no data</i>
                        }

                        renderedArray.push(data)
                    }

                    if (+el.id % 2 === 0) {
                        data = {
                            key: result[index - 1] ? result[index - 1].value : <i>no data</i>,
                            value: el.value
                        }
                        renderedArray.push(data)

                        const uniqueArray = renderedArray.filter((element, index) => {
                            return index === renderedArray.findIndex(obj => {
                                return JSON.stringify(obj) === JSON.stringify(element);
                            });
                        });

                        renderedArray = uniqueArray
                    }

                }
            })
            setRenderedParams(renderedArray)
        }

    }

    return (
        <>
            <div className="shadow z_index_1000" onClick={closeTechTemplate}></div>
            <div className="modal_common z_index_1000 p_40" style={{ width: '700px' }}>
                <div>
                    <Alert
                        variant="dark"
                        className="header_alert_common"
                        onClick={closeTechTemplate}
                        dismissible>
                        <Alert.Heading as='h5'>
                            {`Параметры шаблона ${name}`}
                        </Alert.Heading>
                    </Alert>
                    <Container>
                        <Row> &nbsp;</Row>
                        <Row> &nbsp;</Row>


                        {renderedParams && renderedParams.length !== 0 ?

                            renderedParams.map((item, idx) => {
                                console.log(resultTemplateBlock);

                                return <Row key={idx} style={{ marginBottom: '20px' }}>
                                    <Col>
                                        <Form.Label className='upper_label_position'>Название параметра</Form.Label>
                                        <Form.Control
                                            style={{ backgroundColor: '#fff' }}
                                            value={item.key}
                                            readOnly={true}
                                        />
                                    </Col>

                                    <Col>
                                        <Form.Label className='upper_label_position'>Требование к параметру</Form.Label>
                                        <Form.Control
                                            style={{ backgroundColor: '#fff' }}
                                            value={item.value}
                                            readOnly={true}
                                        />
                                    </Col>
                                </Row>


                            })
                            :
                            'Данные не заполнены'
                        }
                    </Container>
                </div>
            </div>
        </>
    )

}