import Server from './server/server'
import { TIMEZONE } from './assets/timezone';

/**
 * Проверка / запись временных зон в анкету и сотрудникам компании
 */
const checkTimezone = () => {
    (async () => {
        try {
            console.log(`Добавление / проверка временной зоны у клиента`);
            await Server.checkTimezone(TIMEZONE)

        } catch (err) {
            console.log(`Ошибка в проверке или добавлении временного пояся: `, err);
        }
    })()
}

export default checkTimezone;