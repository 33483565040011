export const PRODUCTION = [
    '1.6.1. Добывающее и/или перерабатывающее предприятие',
    '1.6.2. Машиностроительный завод',
    '1.6.3. Проектировщик оборудования',
    '1.6.4. Проектная организация',
    '1.6.5. Инжиниринговая компания',
    '1.6.6. Поставщик материалов',
    '1.6.7. Поставщик комплектующих',
    '1.6.8. Строительно-монтажная организация',
    '1.6.9. Генеральный подрядчик',
    '1.6.10. Лицензиар',
    '1.6.11. Инвестор',
    '1.6.12. Транспортная компания',
    '1.6.13. Лаборатория неразрушающих и разрушающих методов контроля'
];

export const MECH_PRODUCTION = [
    "Токарная",
    "Токарно-карусельная",
    "Сверловка отверстий",
    "Фрезерная обработка",
    "Расточные операции"
]

export const TERMO_PRODUCTION = [
    "Рекристаллизационный отжиг",
    "Сфероидизирующий отжиг",
    "Стабилизирующий отжиг",
    "Изотермический отжиг",
    "Циклический отжиг",
    "Неполный отжиг",
    "Полный отжиг",
    "Промежуточный отпуск",
    "Высокий отпуск",
    "Низкий отпуск",
    "Нормализация",
    "Аустенизация"
]