import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

export default class SelectFormIncoterms extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      id: '',
      fid: '',
      description: '',
      information: '',
      value: '',
      colorError: '#fff'
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    // this.viewListOptions = this.viewListOptions.bind(this)
  }

  handleBlur(e) {
    this.setState({
      id: e.target.dataset.id,
      fid: `${this.props.nameForm}_${this.props.id}`,
      description: e.target.dataset.description,
      information: e.target.dataset.information
    })
    setTimeout(() => {
      this.props.onChangeValue(this.state)
    })
  }

  handleChange(e) {
    e.preventDefault()
    // console.log(e.target.value);//test
    this.setState({ value: e.target.value })
    this.props.onChangeVisionBlock(e.target.value, e)
  }

  //   viewListOptions(arr) {
  //     this.optionArr = []
  //     arr.split(', ').forEach((data) => {
  //       this.optionArr.push(<option key={data}>{data}</option>)
  //     })
  //   }

  componentDidMount() {
    this.setState({ value: this.props.value })

    // this.viewListOptions(this.props.option)
  }

  render() {
    // this.viewListOptions(this.props.option)

    const value = this.state.value

    const { width, label, id, description, placeholder, show, option, disabledOption } = this.props

    return (
      <React.Fragment key={id}>
        <Row style={{ alignItems: 'center' }}>
          <Col style={{}}>{label}</Col>
          <Col sm={width} style={{ display: show ? 'block' : 'none' }}>
            <Form.Label className='select_form_label'>{label}</Form.Label>
            <Form.Control
              as='select'
              type='text'
              data-id={id}
              placeholder={placeholder}
              data-information={placeholder}
              data-description={description}
              value={value}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              disabled={this.props.readOnly}
            >
              <option value='' disabled selected hidden>{disabledOption}</option>
              {option.map((el) => {
                return <option key={el}>{el}</option>
              })}
            </Form.Control>
          </Col>
        </Row>
      </React.Fragment>
    )
  }
}
