import React from 'react'
import { Table } from 'react-bootstrap'

export const TableSkeleton = ({firstTh, secondTh}) => {
    return (
        <Table bordered style={{ backgroundColor: '#fff' }}>
            <thead>
                <tr>
                    <th style={{ width: '700px' }}>{firstTh}</th>
                    <th>{secondTh}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </Table>
    )
}