import React from 'react';
import { Alert, Container, Form, Row, Col } from 'react-bootstrap';
import { FORGINGS_FOR_FILTER } from '../../questionnaire/ConstForging';
import CheckForm from '../../questionnaire/CheckForm';
import FormForgingsDimensions from '../../questionnaire/FormForgingsDimensions';
// import FlourescentIcon from '@mui/icons-material/Flourescent';
import { CloseBtnForFilter } from '../../assets/CloseBtnForFilter';
import Server from '../../server/server';
import { ButtonGroupForFilterForms } from '../../assets/ButtonGroupForFilterForms';

export default class FormForgings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: true,
            nameForm: 'FormForgings',
            maxId: 209,
            dataValue: [],
            dataOnServer: [],
            dataFromServer: [],
            lengthDataFromServer: 0,
            showQuit: false,
            base: [],
            baseForm: [],
            showGost: [false, false, false, false, false, false, false],
            showGOST8479: false,
            gost8479: false,
            gost25054: false,
            showGroup1: false,
            showGroup2: false,
            showVariants: true
        }
        this.doChangeValue = this.doChangeValue.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
        this.handleClickMaterieals = this.handleClickMaterieals.bind(this);

        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);

        this.renderDataConst = this.renderDataConst.bind(this);
        this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
        this.sendProductDataOnServer = this.sendProductDataOnServer.bind(this)
    }

    async clearFields() {
        await this.setState({
            showVariants: false
        })

        this.setState({
            showVariants: true
        })
    }

    handleClickMaterieals() {
        this.props.onRunMaterials('Four_FormForgings');
    }

    //* empty
    doEmpty() {
    }

    async doChangeVisionBlock(e, id) {
        // console.log(`CHECK -> CLICK -> ${e} - ${id}`); // e = true/false

        const position = [1, 31, 61, 91, 121, 151, 181].indexOf(Number(id));
        let showPosition = this.state.showGost;
        showPosition[position] = e;

        await this.setState({ showGost: showPosition });
        // console.log(this.state.showGost);//test

        // this.checkOnList(); // ! отключен
        this.renderDataConst(FORGINGS_FOR_FILTER);

        const productData = {
            product: this.props.product,
            formName: this.state.nameForm
        }

        await Server.sendDataOnServer(productData, 'product', localStorage.getItem('idUser'))
        
    }


    //*рендер ГОСТ (кореные данные)
    async renderDataConst(DATA) {
        await this.setState({ maxId: (DATA.length * 30 + 1) });

        this.dataBase = DATA.map((data, index) => {
            return (
                <React.Fragment key={index * 30 + 1}>
                    <CheckForm
                        className={this.state.showGost[index] && 'fw_bolder'}
                        id={index * 30 + 1}
                        width={4}
                        show={true}
                        placeholder={data.name} //* подпись LABEL для чекбокса
                        description={"Поковки"}
                        value={this.value[index * 30 + 1] ? this.value[index * 30 + 1].value : false}//* при первом запуске - false
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                    />
                    {this.state.showGost[index] ?
                        <FormForgingsDimensions
                            id={index * 30 + 2}
                            forgings={FORGINGS_FOR_FILTER[index]}
                            description={"Поковки"}
                            onChangeValue={this.doChangeValue}
                            value={this.value}
                            nameForm={this.state.nameForm}
                        /> : ''}

                </React.Fragment>
            )
        })

        this.setState({
            base: this.dataBase,
            show: true
        })
    }

    //запуск выхода из формы
    handleClickShadow() {
        this.setState({ showQuit: true })
    }

    //выход с сохранением
    doQuitWithSave() {
        this.setState({ showQuit: false })
        this.handleClickSave();
        this.props.onChangeView(this.state.nameForm);
    }
    //выход без сохранения
    doQuitWithoutSave() {
        this.setState({ showQuit: false })
        this.props.onChangeView(this.state.nameForm);
    }

    //*формируем данные в массив объектов для отправки на сервер
    async handleClickSave(e) {
        e.preventDefault()
        let data = this.state.dataOnServer;

        for (let i = 0; i <= (this.state.maxId); i++) {

            //todo создаем позицию с пустым объектом
            data[i] = { id: '', fid: '', description: '', information: '', value: '' }

            //todo заполняем объект данными с сервера
            this.state.dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
            this.state.dataValue.forEach((dataE) => {
                if (+dataE.id === i) {
                    data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                }
            })

        }
        await this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере

        this.props.getChosenFiltersData(data)
        //отправляем родителю имя этой формы
        // this.props.getFormName(this.state.nameForm)
        //берем данные с сервера
        this.props.saveChosenFilters()


        const result = await Server.filterProductionCompany(data, this.state.nameForm)
        await this.props.sendSearchResultData(result)
        this.props.openResultTable()
        this.props.closeModal(e)
    }

    //* данные с полей формы, формируются в массив объектов
    async doChangeValue(data) {
        // console.log(data);//test
        await this.setState({ dataValue: [...this.state.dataValue, data] })
        // console.log(`LENGTH -> ${this.state.dataValue.length}`);//test

        // this.checkOnList(); //! отключен
    }

    onKeyPressHandler(event) {
        event.target.dataset.position = 22
        if (event.keyCode === 13) {
            this.handleClickSave(event)
        }
    }

    async sendProductDataOnServer() {
        const productData = {
            product: this.props.product,
            formName: this.state.nameForm
        }
        await Server.sendDataOnServer(productData, 'product', localStorage.getItem('idUser'))
    }

    async componentDidMount() {
        await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer });
        this.props.data.forEach((data, index) => { this.doChangeVisionBlock(data.value, index) })
        this.renderDataConst(FORGINGS_FOR_FILTER);
        // console.log(this.props.data);

        await this.sendProductDataOnServer()

        document.addEventListener('keydown', this.onKeyPressHandler)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPressHandler)
    }


    render() {

        const view = this.props.view;
        this.value = this.props.data;
        const viewQuest = this.props.viewQuest;

        return (
            <>

                <div className="modal_window" style={{ display: view ? 'block' : 'none' }} >
                    <div className={viewQuest ? "shadow_form" : "shadow_form_off"} onClick={this.props.closeModal}  ></div>
                    <Form className={this.props.chosenCompaniesFromProductionSearch && this.props.chosenCompaniesFromProductionSearch.length >= 0 ? "form_main" : "form_main_1"} >
                        <Alert
                            variant="dark"
                            className="filter_alert"
                        >
                            <Alert.Heading >Поставка поковок</Alert.Heading>
                            <CloseBtnForFilter dataId={22} closeModal={this.props.closeModal} />
                        </Alert>
                        <div>
                            <Container className="form_container">
                            <Row>
                                <Col className='f_size_1em'>
                                    Стандарты поковок
                                </Col>
                            </Row>

                            <Form.Group>
                                {this.state.showVariants && <>{this.state.show && this.state.base}</>}

                            </Form.Group>

                            <Row> &nbsp;</Row>

                            <Row>&nbsp;</Row>

                        </Container>
                            <ButtonGroupForFilterForms
                                dataId={22}
                                handleClickSave={this.handleClickSave}
                                clearFields={this.clearFields}
                                savedFiltersData={this.props.savedFiltersData}
                            />
                        </div>
                        
                    </Form>
                </div>
            </>
        )
    }
}