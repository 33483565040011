export const LONGPRODUCTS = [{
        name: 'Круглый',
        form: {
            dimensions: ['Макс длина, мм', 'Макс диаметр, мм', 'Мин диаметр, мм']
        },
        normDoc: {
            gost: ['ГОСТ 535', 'ГОСТ 1050', 'ГОСТ 19281', 'ГОСТ 20072', 'ГОСТ 11036', 'ГОСТ 5949', 'ТУ 14-1-195', 'ТУ 14-1-3812', 'ТУ 14-1-1660', 'ТУ 14-1-1160', 'ТУ 14-1-3303', 'ТУ 14-1-561', 'ТУ 14-1-284']
        }
    },
    {
        name: 'Квадратный',
        form: {
            dimensions: ['Макс длина, мм', 'Макс ширина, мм', 'Мин ширина, мм']
        },
        normDoc: {
            gost: ['ГОСТ 535', 'ГОСТ 1050', 'ГОСТ 19281', 'ГОСТ 20072', 'ГОСТ 11036', 'ГОСТ 5949', 'ТУ 14-1-195', 'ТУ 14-1-3812', 'ТУ 14-1-1660', 'ТУ 14-1-1160', 'ТУ 14-1-3303', 'ТУ 14-1-561', 'ТУ 14-1-284']
        }
    },
    {
        name: 'Шестигранный',
        form: {
            dimensions: ['Макс длина, мм', 'Макс ширина, мм', 'Мин ширина, мм']
        },
        normDoc: {
            gost: ['ГОСТ 535', 'ГОСТ 1050', 'ГОСТ 19281', 'ГОСТ 20072', 'ГОСТ 11036', 'ГОСТ 5949', 'ТУ 14-1-195', 'ТУ 14-1-3812', 'ТУ 14-1-1660', 'ТУ 14-1-1160', 'ТУ 14-1-3303', 'ТУ 14-1-561', 'ТУ 14-1-284']
        }
    },
    {
        name: 'Полосовой',
        form: {
            dimensions: ['Макс длина, мм', 'Макс ширина, мм', 'Мин ширина, мм', 'Макс толщ, мм', 'Мин толщ, мм']
        },
        normDoc: {
            gost: ['ГОСТ 535', 'ГОСТ 1050', 'ГОСТ 19281', 'ГОСТ 20072', 'ГОСТ 11036', 'ГОСТ 5949', 'ТУ 14-1-195', 'ТУ 14-1-3812', 'ТУ 14-1-1660', 'ТУ 14-1-1160', 'ТУ 14-1-3303', 'ТУ 14-1-561', 'ТУ 14-1-284']
        }
    },
    {
        name: 'Двутавр',
        form: {
            dimensions: []
        },
        normDoc: {
            gost: ['ГОСТ 535', 'ГОСТ 1050', 'ГОСТ 19281', 'ГОСТ 20072', 'ГОСТ 11036', 'ГОСТ 5949', 'ТУ 14-1-195', 'ТУ 14-1-3812', 'ТУ 14-1-1660', 'ТУ 14-1-1160', 'ТУ 14-1-3303', 'ТУ 14-1-561', 'ТУ 14-1-284']
        }
    },
    {
        name: 'Швеллер',
        form: {
            dimensions: []
        },
        normDoc: {
            gost: ['ГОСТ 535', 'ГОСТ 1050', 'ГОСТ 19281', 'ГОСТ 20072', 'ГОСТ 11036', 'ГОСТ 5949', 'ТУ 14-1-195', 'ТУ 14-1-3812', 'ТУ 14-1-1660', 'ТУ 14-1-1160', 'ТУ 14-1-3303', 'ТУ 14-1-561', 'ТУ 14-1-284']
        }
    },
    {
        name: 'Уголок',
        form: {
            dimensions: []
        },
        normDoc: {
            gost: ['ГОСТ 535', 'ГОСТ 1050', 'ГОСТ 19281', 'ГОСТ 20072', 'ГОСТ 11036', 'ГОСТ 5949', 'ТУ 14-1-195', 'ТУ 14-1-3812', 'ТУ 14-1-1660', 'ТУ 14-1-1160', 'ТУ 14-1-3303', 'ТУ 14-1-561', 'ТУ 14-1-284']
        }
    }
]

export const LONGPRODUCTS_FOR_FILTER = [{
    name: 'Круглый',
    form: {
        dimensions: ['Длина, мм', 'Диаметр, мм']
    },
    normDoc: {
        gost: ['ГОСТ 535', 'ГОСТ 1050', 'ГОСТ 19281', 'ГОСТ 20072', 'ГОСТ 11036', 'ГОСТ 5949', 'ТУ 14-1-195', 'ТУ 14-1-3812', 'ТУ 14-1-1660', 'ТУ 14-1-1160', 'ТУ 14-1-3303', 'ТУ 14-1-561', 'ТУ 14-1-284']
    }
},
{
    name: 'Квадратный',
    form: {
        dimensions: ['Длина, мм', 'Ширина, мм']
    },
    normDoc: {
        gost: ['ГОСТ 535', 'ГОСТ 1050', 'ГОСТ 19281', 'ГОСТ 20072', 'ГОСТ 11036', 'ГОСТ 5949', 'ТУ 14-1-195', 'ТУ 14-1-3812', 'ТУ 14-1-1660', 'ТУ 14-1-1160', 'ТУ 14-1-3303', 'ТУ 14-1-561', 'ТУ 14-1-284']
    }
},
{
    name: 'Шестигранный',
    form: {
        dimensions: ['Длина, мм', 'Ширина, мм']
    },
    normDoc: {
        gost: ['ГОСТ 535', 'ГОСТ 1050', 'ГОСТ 19281', 'ГОСТ 20072', 'ГОСТ 11036', 'ГОСТ 5949', 'ТУ 14-1-195', 'ТУ 14-1-3812', 'ТУ 14-1-1660', 'ТУ 14-1-1160', 'ТУ 14-1-3303', 'ТУ 14-1-561', 'ТУ 14-1-284']
    }
},
{
    name: 'Полосовой',
    form: {
        dimensions: ['Длина, мм', 'Ширина, мм', 'Толщ, мм']
    },
    normDoc: {
        gost: ['ГОСТ 535', 'ГОСТ 1050', 'ГОСТ 19281', 'ГОСТ 20072', 'ГОСТ 11036', 'ГОСТ 5949', 'ТУ 14-1-195', 'ТУ 14-1-3812', 'ТУ 14-1-1660', 'ТУ 14-1-1160', 'ТУ 14-1-3303', 'ТУ 14-1-561', 'ТУ 14-1-284']
    }
},
{
    name: 'Двутавр',
    form: {
        dimensions: []
    },
    normDoc: {
        gost: ['ГОСТ 535', 'ГОСТ 1050', 'ГОСТ 19281', 'ГОСТ 20072', 'ГОСТ 11036', 'ГОСТ 5949', 'ТУ 14-1-195', 'ТУ 14-1-3812', 'ТУ 14-1-1660', 'ТУ 14-1-1160', 'ТУ 14-1-3303', 'ТУ 14-1-561', 'ТУ 14-1-284']
    }
},
{
    name: 'Швеллер',
    form: {
        dimensions: []
    },
    normDoc: {
        gost: ['ГОСТ 535', 'ГОСТ 1050', 'ГОСТ 19281', 'ГОСТ 20072', 'ГОСТ 11036', 'ГОСТ 5949', 'ТУ 14-1-195', 'ТУ 14-1-3812', 'ТУ 14-1-1660', 'ТУ 14-1-1160', 'ТУ 14-1-3303', 'ТУ 14-1-561', 'ТУ 14-1-284']
    }
},
{
    name: 'Уголок',
    form: {
        dimensions: []
    },
    normDoc: {
        gost: ['ГОСТ 535', 'ГОСТ 1050', 'ГОСТ 19281', 'ГОСТ 20072', 'ГОСТ 11036', 'ГОСТ 5949', 'ТУ 14-1-195', 'ТУ 14-1-3812', 'ТУ 14-1-1660', 'ТУ 14-1-1160', 'ТУ 14-1-3303', 'ТУ 14-1-561', 'ТУ 14-1-284']
    }
}
]