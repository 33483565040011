/* eslint-disable default-case */

import React from 'react'
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined'
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined'
import { Alert, Button, Modal } from 'react-bootstrap'

//closeModal={}
//modalBodyText={'Необходимо заполнить раздел "Контактная информация"'}
//needCustomBtn={}
//customBtn={}

export default class ModalInformation extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    // const text = this.props.text

    return (
      <div className='modal_window'>
        <div className='shadow z_index_extra_big' style={{ zIndex: '20' }} onClick={this.props.closeModal}></div>

        <Modal.Dialog className='modal_common z_index_extra_big width_500 animation_for_modal' style={{ zIndex: '20' }}>
          <Alert style={{ borderBottom: '1px #dee2e6 solid', marginBottom: '0' }} dismissible onClose={this.props.closeModal}>
            <Modal.Title>
              {' '}
              <FolderSharedOutlinedIcon color='primary' fontSize='large' style={{ paddingBottom: '3px' }} /> Заполнение анкеты
            </Modal.Title>
          </Alert>

          <Modal.Body style={{ height: '100px' }}>
            <b>{this.props.modalBodyText}</b>
            {this.props.modalContent}
          </Modal.Body>

          <Modal.Footer>
            {!this.props.needCustomBtn && (
              <Button variant='primary' onClick={this.props.closeModal}>
                <ThumbUpAltOutlinedIcon style={{ marginRight: '5px', paddingBottom: '3px' }} />
                Ок
              </Button>
            )}

          {this.props.customBtn}
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    )
  }
}
