import Server from '../server/server'



/**
 * Функция удаления файла 
 * @param {*} data объект с информацие о файле
 */
const deleteFile = async (data) => {
    try {
        console.log(`path ==>> `, data.path); // test
        console.log(`fileName ==>> `, data.fileName); // test
        console.log(`originalName ==>> `, data.originalName); // test
        console.log(`idCard ==>> `, data.idCard); // test ID document
        console.log(`idx ==>> `, data.idx); // test

        const newData = {
            path: data.path, // путь к файлу
            idCard: data.idCard, // ID документа, где прописан файл
            idx: data.idx, // Индекс в массивах документа, где прописан файл
            description: data.description // уникальный ключ реестра
        }

        const result = await Server.deleteFile(newData)
        console.log(`result::: `, result); // test
        console.log(`Файл: ${data.fileName} удален!!!`); // test
        return result

    } catch (err) {
        console.log(`Ошибка при удалении файла: `, err);
    }

}

export default deleteFile;