/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Container, Form, Row } from 'react-bootstrap'
import InputForm from './InputForm'
import CheckForm from './CheckForm'
import SizeFormBig from './SizeFormBig'
import ModalLoad from '../ModalLoad'
import FormMaterList from './FormMaterList'
import { TANK_WITHOUT_MECH, TANK_WITH_MECH, TANK_WITH_MIXER, TANK_BALL, TANK_TERMO, TANK_SAVE, TANK_ROLL } from './ConstDownProduction'
import ModalQuit from '../ModalQuit'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import { HandleAllCheckBoxesBtn } from '../assets/HandleAllCheckboxesBtn'
import { nanoid } from 'nanoid'
import CommonAlert from './CommonAlert'
import { handleSaveFormInfo } from '../filter/commonFunctions/commonFunctions'
import AllModalsFooter from '../assets/AllModalsFooter'
import { MaterialsButton, SaveButtonGreen, SaveCurrentDataButton } from '../assets/BasicButtons'
import { createData } from './functionsForm'
import { isEqual } from 'lodash-es'

export default class Form_Zero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      show: false,
      dataValue: [],
      maxId: 70,
      nameForm: 'Zero',
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      visible: [false, false, false, false, false, false, false],
      showAllCheckBoxes: [true, true, true, true, true, true, true],
      selectCheck: [true, true, true, true, true, true, true],
      idState: [1, 10, 19, 28, 37, 46, 55],
      showLook: false,
      showQuit: false,

      showCarbon: false,
      showContent: true,
      previousState: [],
      savedDataMessage: false,
      dataBeenSaved: false
    }

    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doEmpty = this.doEmpty.bind(this)

    this.doVisible = this.doVisible.bind(this)
    this.reloadDataonState = this.reloadDataonState.bind(this)

    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)

    this.handleClickMaterieals = this.handleClickMaterieals.bind(this) // новое имя формы материалов
    this.handleAllChecked = this.handleAllChecked.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.setCompanyName = this.setCompanyName.bind(this)
    this.hideContent = this.hideContent.bind(this)
    this.setDataFromServerInForm = this.setDataFromServerInForm.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
  }

  saveDataWithNotification() {
    this.handleClickSave()

    this.setState({
      savedDataMessage: true,
      dataBeenSaved: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }

  setDataFromServerInForm(data) {
    this.setState({ dataOnServer: data })
  }

  setCompanyName() {
    if (this.props.companyName && this.props.companyName.length > 0) {
      this.setState({
        companyName: this.props.companyName
      })
    }
  }

  hideContent() {
    this.setState({ showContent: false })
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })
  }

  handleAllChecked(e) {
    let arr = this.state.showAllCheckBoxes //массив отображения секции с основными чекбоксами (изначально они все true)
    const pos = Number(e.target.dataset.id) //берем dataset у элемента, на который кликаем
    arr[pos] = false //у массива с основными чекбоксами меняем одно значение, он становится таким: [false, true, true, ....]
    const posSet = this.state.selectCheck //массив со значениями checked неосновных чекбоксов
    posSet[pos] = !posSet[pos] //тоглим выбранную позицию у массива в конкретно самих чекбоксов с true на false
    // из всего вышеперечисленного получаем 2 массива: [false, true, true, ....] и [true, true, true...] тоглим на [false, false, false]
    this.setState({
      selectCheck: posSet,
      showAllCheckBoxes: arr
    })
    arr = this.state.showAllCheckBoxes //отдаем масиву дефолтное значение
    setTimeout(() => {
      arr[pos] = true // меняем позицию по setTimeout тк иначе он не успевает менять значения
      this.setState({
        showAllCheckBoxes: arr
      })
    })
    // то массив неосновных чекбоксов снова отображается
  }

  //*видимость размеров при выборе позиции
  doVisible(e, id) {
    // console.log(e + ' - ' + id);
    let visibleState = this.state.visible
    let idState = this.state.idState

    idState.forEach((data, index) => {
      if (data === +id) visibleState[index] = e
    })

    this.setState({ visible: visibleState })
  }

  //*Пустышка
  doEmpty() { }

  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  doQuitWithSave() {
    this.setState({ showQuit: false })
    this.handleClickSave()
    // handleSaveFormInfo(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue, this.state.lengthDataFromServer, this.props.onCreateDataServer, this.props.onUpdateDataOnServer, this.setDataFromServerInForm)

    this.props.onChangeView('Zero')
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView('Zero')
  }

  //*формируем данные в массив объектов для отправки на сервер
  handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach((dataD) => {
        if (+dataD.id === i) {
          data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach((dataE) => {
        if (+dataE.id === i) {
          data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
        }
      })
    }
    this.setState({ dataOnServer: data })

    //todo проверка на наличие данных на сервере
    if (this.state.lengthDataFromServer < 1) {
      console.log('CREATE DATA') //todo создаем новый блок данных на сервер
      this.props.onCreateDataServer(data, 'Zero', localStorage.getItem('idUser'))
    } else {
      console.log('UPDATE DATA') //todo перезаписываем существующий блок данных на срвере
      this.props.onUpdateDataOnServer(data, 'Zero', localStorage.getItem('idUser'))
    }
    setTimeout(() => {
      console.log(this.state.dataOnServer)
    }) //test
    // this.handleClickShadow();
  }

  //* данные с полей формы, формируются в массив объектов
  doChangeValue(data) {
    console.log(`DATA VALUE:::: `, data) //test
    this.setState({ dataValue: [...this.state.dataValue, data] })
    this.setState({
      dataBeenSaved: false
    })
  }

  reloadDataonState() {
    this.props.data.forEach((data) => {
      this.doVisible(data.value, +data.id)
    })
  }
  handleClickMaterieals() {
    this.props.onRunMaterials('Four_Zero')
  }

  compare() {
    const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)
    const prev = this.state.previousState

    const isStateEqual = isEqual(curr, prev)

    // console.log(isStateEqual);

    if (isStateEqual) {
      return true
    }
    return false
  }

  smartWindowFunction() {
    try {
      const { dataBeenSaved, dataValue, idState } = this.state
      const { data } = this.props
      const isStateEqual = this.compare()
      isStateEqual || dataBeenSaved || (data.length === 0 && dataValue.length <= idState.length) ? this.props.onChangeView('Zero') : this.handleClickShadow()
    } catch (err) {
      console.log('Ошибка при сохранении данных: ', err)
    }
  }

  componentDidMount() {
    // this.setState({ showLook: true })
    setTimeout(() => {
      this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer })
      // console.log('DATA on dataFromServer');//test
      this.reloadDataonState()
    }, 1000)
    this.setCompanyName()

    const previousState = JSON.parse(JSON.stringify(this.props.data))
    this.setState({ previousState })
  }

  render() {
    const sizes = ['Макс объем, м3', 'Макс длина, мм', 'Макс ширина, мм', 'Макс высота, мм', 'Макс диаметр, мм', 'Макс рабочее давление, МПа', 'Макс масса, тн']
    const sizeTermo = ['Макс длина, мм', 'Макс диаметр, мм', 'Макс давление, МПа', 'Макс температура, C', 'Макс масса, тн']
    const sizeTank = ['Макс объем, м3', 'Макс рабочая температура, C', 'Макс рабочее давление, МПа']
    const visible = this.state.visible
    const view = this.props.view
    this.value = this.props.data
    const viewQuest = this.props.viewQuest
    const selectCheck = this.state.selectCheck
    const showAllCheckBoxes = this.state.showAllCheckBoxes

    //условие по которому компонент определяет находится ли он в формате чтения
    const inReadMode = !this.props.innFromUniversalTable
    const isFormFilled = this.value && this.value.length !== 0

    return (
      <>
        {inReadMode && <>{this.state.showQuit && <ModalQuit onQuitWithSave={this.doQuitWithSave} onQuitWithoutSave={this.doQuitWithoutSave} closeQuitModal={this.closeQuitModal} />}</>}
        {/* {this.state.showLook ? <ModalLoad stopLooking={1500} /> : ''} */}
        {this.state.showContent && (
          <div className='modal_window' style={{ display: view ? 'block' : 'none' }}>
            <div
              className={viewQuest ? 'shadow_form' : 'shadow_form_off'}
              // onClick={this.handleClickShadow}
              onClick={this.smartWindowFunction}
            ></div>
            <Form className={viewQuest ? 'form_main' : 'form_main_view'}>
              <CommonAlert
                inReadMode={inReadMode}
                handleClickHeader={this.handleClickHeader}
                // handleClickShadow={this.handleClickShadow}
                handleClickShadow={this.smartWindowFunction}
                hideContent={this.hideContent}
                icon={<SettingsOutlinedIcon fontSize='large' className='icon_primary' />}
                companyName={this.state.companyName}
                sectionName='Производственное оборудование производимое на предприятии'
              />
              <div>
                <Container className='form_container'>
                  {inReadMode || isFormFilled ? (
                    <Form.Label className='f_size_1em'>Наименования, максимальные габаритные размеры и максимальное рабочее давление выпускаемого оборудования</Form.Label>
                  ) : (
                    <Form.Label className='f_size_1em'>Данные не заполнены</Form.Label>
                  )}

                  {/* 1.1 */}
                  <Form.Group>
                    <Form.Group>
                      <Row>
                        <CheckForm
                          name={nanoid()}
                          id={1}
                          width={12}
                          show={!inReadMode && !this.value[1] ? false : true}
                          // show={true}
                          placeholder={<div style={{ fontWeight: 'bolder', fontSize: '16px' }}>Емкости без внутренних устройств</div>}
                          description='Производственное оборудование'
                          value={this.value[1] ? this.value[1].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doVisible}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                    </Form.Group>

                    <Form.Group style={{ display: visible[0] ? 'block' : 'none' }}>
                      <Container fluid style={{ paddingLeft: '50px' }}>
                        {visible[0] && (
                          <>
                            <SizeFormBig
                              id={2}
                              width={2}
                              fontSize={'12px'}
                              show={visible[0]}
                              value={this.value}
                              description='Емкости без внутренних устройств'
                              placeholder={sizes}
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                              readOnly={!inReadMode}
                            />
                            <Row>&nbsp;</Row>
                            {!this.value[9] && (
                              <>
                                <HandleAllCheckBoxesBtn handleAllChecked={this.handleAllChecked} selectCheck={selectCheck[0]} dataId={0} />
                              </>
                            )}
                          </>
                        )}
                        {showAllCheckBoxes[0] && (
                          <>
                            <Row>
                              <FormMaterList
                                name={nanoid()}
                                id={9}
                                width={12}
                                materials={TANK_WITHOUT_MECH}
                                value={this.value[9] ? this.value[9].value : ''}
                                description='Емкости без внутренних устройств'
                                onChangeValue={this.doChangeValue}
                                nameForm={this.state.nameForm}
                                selectCheck={selectCheck[0]}
                                readOnly={!inReadMode}
                              />
                            </Row>
                          </>
                        )}
                      </Container>
                      <Row> &nbsp;</Row>
                      {inReadMode && (
                        <Row>
                          <InputForm
                            id={64}
                            width={12}
                            show={true}
                            // verify="number"
                            label=''
                            placeholder='Добавить наименование оборудования'
                            description='Емкости без внутренних устройств'
                            value={this.value[64] ? this.value[64].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                        </Row>
                      )}
                      <Row>
                        {' '}
                        <div className='border_top_topline_100 mb_10 mt_20'></div>
                      </Row>
                    </Form.Group>

                    {/* 1.2 */}
                    <Form.Group>
                      <Row>
                        <CheckForm
                          name={nanoid()}
                          id={10}
                          width={12}
                          // show={true}
                          show={!inReadMode && !this.value[10] ? false : true}
                          // show={true}
                          placeholder={<div style={{ fontWeight: 'bolder' }}>Емкости с внутренними устройствами</div>}
                          description='Производственное оборудование'
                          value={this.value[10] ? this.value[10].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doVisible}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                    </Form.Group>
                    <Form.Group style={{ display: visible[1] ? 'block' : 'none' }}>
                      <Container fluid style={{ paddingLeft: '50px' }}>
                        {visible[1] && (
                          <>
                            <SizeFormBig
                              id={11}
                              width={2}
                              fontSize={'12px'}
                              show={visible[1]}
                              value={this.value}
                              description='Емкости с внутренними устройствами'
                              placeholder={sizes}
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                              readOnly={!inReadMode}
                            />
                            <Row>&nbsp;</Row>
                            {!this.value[18] && (
                              <>
                                <HandleAllCheckBoxesBtn handleAllChecked={this.handleAllChecked} selectCheck={selectCheck[1]} dataId={1} />
                              </>
                            )}
                          </>
                        )}
                        {showAllCheckBoxes[1] && (
                          <Row>
                            <FormMaterList
                              name={nanoid()}
                              id={18}
                              width={12}
                              materials={TANK_WITH_MECH}
                              value={this.value[18] ? this.value[18].value : ''}
                              description='Емкости с внутренними устройствами'
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                              selectCheck={selectCheck[1]}
                              readOnly={!inReadMode}
                            />
                          </Row>
                        )}
                      </Container>
                      <Row> &nbsp;</Row>
                      {inReadMode && (
                        <Row>
                          <InputForm
                            id={65}
                            width={12}
                            show={true}
                            // verify="number"
                            label=''
                            placeholder='Добавить наименование оборудования'
                            description='Емкости с внутренними устройствами'
                            value={this.value[65] ? this.value[65].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                        </Row>
                      )}
                      <Row>
                        {' '}
                        <div className='border_top_topline_100 mb_10 mt_20'></div>{' '}
                      </Row>
                    </Form.Group>
                    {/* 1.3 */}
                    <Form.Group>
                      <Row>
                        <CheckForm
                          name={nanoid()}
                          id={19}
                          width={12}
                          show={!inReadMode && !this.value[19] ? false : true}
                          // show={true}
                          placeholder={<div style={{ fontWeight: 'bolder' }}>Емкости с перемешивающими устройствами</div>}
                          description='Производственное оборудование'
                          value={this.value[19] ? this.value[19].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doVisible}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                    </Form.Group>
                    <Form.Group style={{ display: visible[2] ? 'block' : 'none' }}>

                      <Container fluid style={{ paddingLeft: '50px' }}>
                        {visible[2] && (
                          <>
                            <SizeFormBig
                              id={20}
                              width={2}
                              fontSize={'12px'}
                              show={visible[2]}
                              value={this.value}
                              description='Емкости с перемешивающими устройствами'
                              placeholder={sizes}
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                              readOnly={!inReadMode}
                            />
                            <Row>&nbsp;</Row>
                            {!this.value[27] && (
                              <>
                                <HandleAllCheckBoxesBtn handleAllChecked={this.handleAllChecked} selectCheck={selectCheck[2]} dataId={2} />
                              </>
                            )}
                          </>
                        )}
                        {showAllCheckBoxes[2] && (
                          <Row>
                            <FormMaterList
                              name={nanoid()}
                              id={27}
                              width={12}
                              materials={TANK_WITH_MIXER}
                              value={this.value[27] ? this.value[27].value : ''}
                              description='Емкости с перемешивающими устройствами'
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                              selectCheck={selectCheck[2]}
                              readOnly={!inReadMode}
                            />
                          </Row>
                        )}
                      </Container>
                      <Row> &nbsp;</Row>
                      {inReadMode && (
                        <Row>
                          <InputForm
                            id={66}
                            width={12}
                            show={true}
                            // verify="number"
                            label=''
                            placeholder='Добавить наименование оборудования'
                            description='Емкости с перемешивающими устройствами'
                            value={this.value[66] ? this.value[66].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            selectCheck={this.state.selectCheck}
                            readOnly={!inReadMode}
                          />
                        </Row>
                      )}
                      <Row>
                        {' '}
                        <div className='border_top_topline_100 mb_10 mt_20'></div>{' '}
                      </Row>
                    </Form.Group>
                    {/* 1.4 */}
                    <Form.Group>
                      <Row>
                        <CheckForm
                          name={nanoid()}
                          id={28}
                          width={12}
                          show={!inReadMode && !this.value[28] ? false : true}
                          // show={true}
                          placeholder={<div style={{ fontWeight: 'bolder' }}>Емкости для хранения</div>}
                          description='Производственное оборудование'
                          value={this.value[28] ? this.value[28].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doVisible}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                    </Form.Group>
                    <Form.Group style={{ display: visible[3] ? 'block' : 'none' }}>
                      <Container fluid style={{ paddingLeft: '50px' }}>
                        {visible[3] && (
                          <SizeFormBig
                            id={29}
                            width={2}
                            fontSize={'12px'}
                            show={visible[3]}
                            value={this.value}
                            description='Емкости для хранения'
                            placeholder={sizes}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                        )}
                        <Row>
                          <FormMaterList
                            name={nanoid()}
                            id={36}
                            width={12}
                            materials={TANK_SAVE}
                            value={this.value[36] ? this.value[36].value : ''}
                            description='Емкости для хранения'
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            selectCheck={selectCheck[3]}
                            readOnly={!inReadMode}
                          />
                        </Row>
                      </Container>
                      <Row> &nbsp;</Row>
                      {inReadMode && (
                        <Row>
                          <InputForm
                            id={67}
                            width={12}
                            show={true}
                            // verify="number"
                            label=''
                            placeholder='Добавить наименование оборудования'
                            description='Емкости для хранения'
                            value={this.value[67] ? this.value[67].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                        </Row>
                      )}
                      <Row>
                        {' '}
                        <div className='border_top_topline_100 mb_10 mt_20'></div>{' '}
                      </Row>
                    </Form.Group>
                    {/* 1.5 */}
                    <Form.Group>
                      <Row>
                        <CheckForm
                          name={nanoid()}
                          id={37}
                          width={12}
                          show={!inReadMode && !this.value[37] ? false : true}
                          // show={true}
                          placeholder={<div style={{ fontWeight: 'bolder' }}>Рулонированные сосуды</div>}
                          description='Производственное оборудование'
                          value={this.value[37] ? this.value[37].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doVisible}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                    </Form.Group>
                    <Form.Group style={{ display: visible[4] ? 'block' : 'none' }}>
                      <Container fluid style={{ paddingLeft: '50px' }}>
                        {visible[4] && (
                          <SizeFormBig
                            id={38}
                            width={2}
                            fontSize={'12px'}
                            show={visible[4]}
                            value={this.value}
                            description='Рулонированные сосуды'
                            placeholder={sizeTank}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                        )}
                        <Row>
                          <FormMaterList
                            name={nanoid()}
                            id={45}
                            width={12}
                            materials={TANK_ROLL}
                            value={this.value[45] ? this.value[45].value : ''}
                            description='Рулонированные сосуды'
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            selectCheck={this.state.selectCheck}
                            readOnly={!inReadMode}
                          />
                        </Row>
                      </Container>
                      <Row> &nbsp;</Row>
                      {inReadMode && (
                        <Row>
                          <InputForm
                            id={68}
                            width={12}
                            show={true}
                            // verify="number"
                            label=''
                            placeholder='Добавить наименование оборудования'
                            description='Рулонированные сосуды'
                            value={this.value[68] ? this.value[68].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                        </Row>
                      )}
                      <Row>
                        {' '}
                        <div className='border_top_topline_100 mb_10 mt_20'></div>{' '}
                      </Row>
                    </Form.Group>
                    {/* 1.6 */}
                    <Form.Group>
                      <Row>
                        <CheckForm
                          name={nanoid()}
                          id={46}
                          width={12}
                          show={!inReadMode && !this.value[46] ? false : true}
                          // show={true}
                          placeholder={<div style={{ fontWeight: 'bolder' }}>Шаровые резервуары</div>}
                          description='Производственное оборудование'
                          value={this.value[46] ? this.value[46].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doVisible}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                    </Form.Group>
                    <Form.Group style={{ display: visible[5] ? 'block' : 'none' }}>
                      <Container fluid style={{ paddingLeft: '50px' }}>
                        {visible[5] && (
                          <SizeFormBig
                            id={47}
                            width={2}
                            fontSize={'12px'}
                            show={visible[5]}
                            value={this.value}
                            description='Шаровые резервуары'
                            placeholder={sizeTank}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                        )}
                        <Row>
                          <FormMaterList
                            name={nanoid()}
                            id={54}
                            width={12}
                            materials={TANK_BALL}
                            value={this.value[54] ? this.value[54].value : ''}
                            description='Шаровые резервуары'
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            selectCheck={this.state.selectCheck}
                            readOnly={!inReadMode}
                          />
                        </Row>
                        <Row> &nbsp;</Row>
                      </Container>
                      {inReadMode && (
                        <Row>
                          <InputForm
                            id={69}
                            width={12}
                            show={true}
                            // verify="number"
                            label=''
                            placeholder='Добавить наименование оборудования'
                            description='Шаровые резервуары'
                            value={this.value[69] ? this.value[69].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                        </Row>
                      )}
                      <Row>
                        {' '}
                        <div className='border_top_topline_100 mb_10 mt_20'></div>{' '}
                      </Row>
                    </Form.Group>
                    {/* 1.7 */}
                    <Form.Group>
                      <Row>
                        <CheckForm
                          name={nanoid()}
                          id={55}
                          width={12}
                          show={!inReadMode && !this.value[55] ? false : true}
                          // show={true}
                          placeholder={<div style={{ fontWeight: 'bolder' }}>Кожухотрубное теплообменное оборудование</div>}
                          description='Производственное оборудование'
                          value={this.value[55] ? this.value[55].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doVisible}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                    </Form.Group>
                    <Form.Group style={{ display: visible[6] ? 'block' : 'none' }}>
                      <Container fluid style={{ paddingLeft: '50px' }}>
                        {visible[6] && (
                          <>
                            <SizeFormBig
                              id={56}
                              width={2}
                              fontSize={'12px'}
                              show={visible[6]}
                              value={this.value}
                              description='Кожухотрубное теплообменное оборудование'
                              placeholder={sizeTermo}
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                              readOnly={!inReadMode}
                            />
                            <Row>&nbsp;</Row>

                            {!this.value[63] && <HandleAllCheckBoxesBtn handleAllChecked={this.handleAllChecked} selectCheck={selectCheck[6]} dataId={6} />}
                          </>
                        )}
                        {showAllCheckBoxes[6] && (
                          <Row>
                            <FormMaterList
                              name={nanoid()}
                              id={63}
                              width={12}
                              materials={TANK_TERMO}
                              value={this.value[63] ? this.value[63].value : ''}
                              description='Кожухотрубное теплообменное оборудование'
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                              selectCheck={selectCheck[6]}
                              readOnly={!inReadMode}
                            />
                          </Row>
                        )}
                      </Container>
                      <Row> &nbsp;</Row>
                      {inReadMode && (
                        <Row>
                          <InputForm
                            id={70}
                            width={12}
                            show={true}
                            // verify="number"
                            label=''
                            placeholder='Добавить наименование оборудования'
                            description='Кожухотрубное теплообменное оборудование'
                            value={this.value[70] ? this.value[70].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                        </Row>
                      )}
                    </Form.Group>
                    <Row> &nbsp;</Row>
                    <Row> &nbsp;</Row>
                  </Form.Group>
                  <Row> &nbsp;</Row>
                </Container>
                <AllModalsFooter
                  footerContent={
                    <>
                      <MaterialsButton handleSaveClick={this.handleClickMaterieals} needDisable={false} />
                      {inReadMode && (
                        <SaveCurrentDataButton
                          handleSaveClick={this.saveDataWithNotification}
                          needDisable={false}
                          savedDataMessage={this.state.savedDataMessage}
                          buttonTextDefault={'Сохранить'}
                          buttonTextSaved={'Данные сохранены'}
                          needCustomIcon={false}
                        />
                      )}
                    </>
                  }
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}
