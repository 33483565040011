import React from 'react';
import { Alert, Col, ListGroup } from 'react-bootstrap';

export default class GeneralLotPriceComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            language: 'rus',
            id: '',
            fid: '',
            description: '',
            information: '',
            value: '',
            colorError: '#73c586'
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.erasingError = this.erasingError.bind(this);
    }

    async handleBlur(e) {
        await this.setState({
            id: e.target.dataset.id,
            fid: e.target.dataset.fid,
            description: e.target.dataset.description,
            information: e.target.dataset.information
        })
        this.props.onChangeValue(this.state);
    }

    handleChange(e) {
        e.preventDefault();
        // console.log(e.target.value);//test
        if (this.props.verify === 'number') {
            this.verificationNumber(e)
        } else {
            this.setState({ value: e.target.value })
        }
        // console.log(e.target.value);//test
    }

    verificationNumber(e) {
        // todo заменяем запятую на точку .replace(/,/, '.')
        if (isFinite(e.target.value.replace(/,/, '.'))) {
            this.setState({ value: e.target.value.replace(/,/, '.'), colorError: '#fff' })
        } else {

            console.log('error');
            this.setState({ colorError: '#dc3545' })
            this.erasingError();
        }
    }

    erasingError() {
        setTimeout(() => { this.setState({ colorError: '#fff' }) }, 500)
    }

    componentDidMount() {
        this.setState({
            value: this.props.value,
            nds: this.props.nds // не обязательный пропс
        })
    }

    render() {

        // if (this.props.nameForm) this.nameForm = this.props.nameForm;
        const id = this.props.id;
        const description = this.props.description;
        const placeholder = this.props.placeholder;
        const nds = this.state.nds;
        let value = 0;

        if (nds) {
            value = nds
        } else {
            value = this.state.value
        }

        // console.log(`VALUE >>>> `, typeof this.state.value);

        //${this.props.readOnly ? '#eee' : '#fff'}
        const { withNds, curr, nameForm, currencyPlaceholder, readOnly } = this.props

        return (
            <ListGroup horizontal key={id}>
                <ListGroup.Item
                    style={{ width: '50%' }}
                >
                    Начальная минимальная цена лота, {curr}, ({`${withNds} НДС`}):
                </ListGroup.Item>
                <ListGroup.Item
                    style={{ width: '50%' }}
                    data-description={description}
                    data-id={id}
                    data-fid={`${nameForm}_${this.props.id}`}
                    data-information={placeholder}
                    id={'price'}
                    show={true}
                    verify="number"
                    // readOnly={true}
                    placeholder={currencyPlaceholder} // с учетом валюты
                    description="Общая стоимость лота"
                    onBlur={this.handleBlur}
                    readOnly={readOnly ? true : false}
                    value={value}
                    onChangeValue={this.doChangeValue}
                    nameForm={this.state.nameForm}
                >
                    {value ? value : ' ___________ '} руб.
                </ListGroup.Item>
            </ListGroup>
        )
    }
}


{/* <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>

    {/* <div style={{ marginBottom: '10px' }}>{label}: </div> */}

    // <Alert variant={value ? 'success' : 'info'}
    //     style={{
    //         backgroundColor: value ? `${this.state.colorError}` : '#fff',
    //         margin: '0',
    //         width: '100%',
    //         textAlign: 'center',
    //         color: '#fff',
    //         fontWeight: 'bold',
    //         fontSize: this.props.fontSize
    //     }}
    //     data-id={id}
    //     data-fid={`${this.props.nameForm}_${this.props.id}`}
    //     data-information={placeholder}
    //     data-description={description}
    //     value={value}
    //     onChange={this.handleChange}
    //     onBlur={this.handleBlur}
    //     readOnly={this.props.readOnly ? true : false}
    // >{value ? value : ' ___________ '} руб.</Alert>
// </Col> */}
