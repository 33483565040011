import React, { useState } from 'react'
import InputFormEconomic from './InputFormEconomic'
import Server from '../server/server'
import { Alert, Card, Container, Form, Row } from 'react-bootstrap'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp'
import QuitModal from '../QuitModal'
import AllModalsFooter from '../assets/AllModalsFooter'
import { SaveCurrentDataButton } from '../assets/BasicButtons'
import { createData } from '../questionnaire/functionsForm'
import { isEqual } from 'lodash-es'

export function EconomicContent({
  setShowContentModal,
  showContentModal,
  dataFromServer,
  lookingSave,
  setTotalPercent,
  onChangeView,
  totalPercent,

  afterPay,
  prepay,
  beforePay,
  handleModalToggle,

  setPrepay,
  setBeforePay,
  setAfterPay,

  launchGuarantee,
  afterGuarantee,
  setFieldsToState,
  // setSendOnModerationModal
  setFillingGuide, reRenderQuestBtn,
  changeColorButton
}) {
  const [showQuit, setShowQuit] = useState(false)
  const [dataValue, setDataValue] = useState([])
  const [dataOnServer, setDataOnServer] = useState([])
  const [maxId] = useState(10)
  const [show] = useState(true)
  const [msgMoreThan100, setMsgMoreThan100] = useState(false)
  const [msgGuarantee, setMsgGuarantee] = useState(false)
  const [savedDataMessage, setSavedDataMessage] = useState(false)
  const [dataBeenSaved, setDataBeenSaved] = useState(false)

  //при размонтировании крмпонента очищает массив dataValue
  // useEffect(()=> {
  //   return () => {
  //     setDataValue([])
  //   }
  // }, [showQuit])

  const saveDataWithNotification = async() => {
    await handleClickSave()

    setSavedDataMessage(true)
    setDataBeenSaved(true)
    setTimeout(() => {
      setSavedDataMessage(false)
    }, 2000)

    setTimeout(() => {
      changeColorButton('EconomicData')
    }, 3000)
    
  }

  const compare = () => {
    const prev = dataFromServer
    const curr = createData(dataOnServer, maxId, dataFromServer, dataValue)

    const isStateEqual = isEqual(prev, curr)
    // console.log(isStateEqual)
    if (isStateEqual) {
      return true
    }
    return false
  }

  const smartWindowFunction = () => {
    // changeColorButton('EconomicData')
    setFillingGuide()
    const isStateEqual = compare()
    if (isStateEqual || dataBeenSaved || dataValue.length === 0) {
      doQuitWithoutSave()
    } else {
      showQuitModal()
    }
  }

  const showQuitModal = () => {
    setShowQuit(true)
  }

  const doQuitWithoutSave = () => {
    setShowQuit(false)
    setShowContentModal(!showContentModal)
    setTimeout(() => {
      setDataValue([])
    });
  }

  const fillObjectWithData = () => {
    for (let i = 0; i <= maxId; i++) {
      //todo создаем позицию с пустым объектом
      dataOnServer[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      dataFromServer.forEach((dataD) => {
        if (+dataD.id === i) {
          dataOnServer[i] = {
            id: dataD.id,
            fid: dataD.fid,
            description: dataD.description,
            information: dataD.information,
            value: dataD.value
          }
        }
      })
      //todo заполняем объект данными с формы
      dataValue.forEach((dataE) => {
        if (+dataE.id === i) {
          dataOnServer[i] = {
            id: dataE.id,
            fid: dataE.fid,
            description: dataE.description,
            information: dataE.information,
            value: dataE.value
          }
        }
      })
    }
  }

  const handleClickSave = async (e) => {
    fillObjectWithData()
    await setDataOnServer(dataOnServer) //todo проверка на наличие данных на сервере
    await Server.sendDataOnServer(dataOnServer, 'EconomicData', localStorage.getItem('idUser'))
    lookingSave()
    setTotalPercent(+dataOnServer[6].value + +dataOnServer[7].value + +dataOnServer[8].value)
    
  }


  const doChangeValue = async (data_) => {
    setFieldsToState(data_)

    if (prepay && beforePay && afterPay) {
      setTotalPercent(prepay + afterPay + beforePay)
    }

    setDataValue([...dataValue, data_])
    setDataBeenSaved(false)
  }

  const doQuitWithSave = async () => {
    onChangeView('EconomicData')
    setShowQuit(!showQuit)
    await handleClickSave()
    handleModalToggle()

    //очищает массив dataValue после закрытия окна чтобы запрашивание поддтв-го окна при закрытии запрашивалось корректно
    setTimeout(() => {
      setDataValue([])
    });
    reRenderQuestBtn()
  }


  const veryfyProcent = () => {
    if (!prepay) {
      setPrepay(0)
    }
    if (!afterPay) {
      setAfterPay(0)
    }
    if (!beforePay) {
      setBeforePay(0)
    }

    if ((prepay || prepay === 0) && (beforePay || beforePay === 0) && (afterPay || afterPay === 0)) {
      setTotalPercent(prepay + afterPay + beforePay)
    }
    if (prepay + afterPay + beforePay > 100) {
      setMsgMoreThan100(true)
    }

    if (launchGuarantee !== '' && afterGuarantee !== '') {
      if (launchGuarantee > afterGuarantee) {
        setMsgGuarantee(true)
      }
    }
  }

  const value = dataFromServer

// console.log(dataValue);
  return (
    <div>
      {showQuit && (
        <QuitModal
          onQuitWithSave={doQuitWithSave}
          onQuitWithoutSave={doQuitWithoutSave}
          closeQuitModal={()=> setShowQuit(false)}
          modalTitle={'Выход'}
          modalBody={'Сохранить данные?'}
          primaryButton={'Сохранить'}
          secondaryButton={'Выйти без сохранения'}
          icon={<ExitToAppSharpIcon fontSize='large' className='icon_leave_modal' />}
        />
      )}

      {showContentModal && (
        <div className='modal_window'>
          <div
            className='shadow'
            // onClick={showQuitModal}
            onClick={smartWindowFunction}
          ></div>
          <Form className={'form_main'}>
            <Alert
              variant='dark'
              className='filter_alert'
              dismissible
              // onClose={showQuitModal}
              onClose={smartWindowFunction}
            >
              <Alert.Heading>Экономическая информация</Alert.Heading>
              {/* <CloseBtnForFilter closeModal={showQuitModal} /> */}
            </Alert>
            <div>
              <Container style={{ overflowY: 'auto', height: '530px' }}>
                {show && (
                  <>
                    <Card style={{ border: '1px #ccc solid' }}>
                      <Card.Header as='h6' className='trade_card_header'>
                        1. Основная коммерческая информация{' '}
                      </Card.Header>

                      <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                        <InputFormEconomic
                          id={1}
                          width={10}
                          show={true}
                          label='Средняя стоимость нормо-часа основных рабочих:'
                          placeholder='Нормо-час, руб.'
                          description='Экономическая информация'
                          value={value[1] && value[1].value}
                          onChangeValue={doChangeValue}
                          nameForm={'EconomicData'}
                          verify='number'
                          upperLabel={'Нормо-час, руб.'}
                        />
                        <Row> &nbsp;</Row>

                        <InputFormEconomic
                          id={2}
                          width={10}
                          show={true}
                          label='Общие накладные расходы:'
                          placeholder='Накладные расходы, %'
                          description='Экономическая информация'
                          value={value[2] && value[2].value}
                          onChangeValue={doChangeValue}
                          nameForm={'EconomicData'}
                          verify='number'
                          upperLabel={'Накладные расходы, %'}
                        />
                        <Row> &nbsp;</Row>

                        <InputFormEconomic
                          id={3}
                          width={10}
                          show={true}
                          label=' Плановая рентабельность:'
                          placeholder='Плановая рентабельность, %'
                          description='Экономическая информация'
                          value={value[3] && value[3].value}
                          onChangeValue={doChangeValue}
                          nameForm={'EconomicData'}
                          verify='number'
                          upperLabel={'Плановая рентабельность, %'}
                        />
                        <Row> &nbsp;</Row>
                      </Card.Body>
                    </Card>
                    <Row> &nbsp;</Row>
                    <Row> &nbsp;</Row>

                    <Card style={{ border: '1px #ccc solid' }}>
                      {msgGuarantee && afterGuarantee < launchGuarantee ? (
                        <Card.Header as='h6' className='trade_card_header_error'>
                          <div>2. Гарантия качества</div>
                          <div style={{ fontSize: '12px' }}>
                            {' '}
                            <ErrorOutlineOutlinedIcon style={{ fontSize: '15px' }} className='pb_3' /> Гарантия после <span style={{ fontWeight: 'bold' }}>пуска</span> не может превышать гарантию после{' '}
                            <span style={{ fontWeight: 'bold' }}>отгрузки</span>{' '}
                          </div>
                        </Card.Header>
                      ) : (
                        <Card.Header as='h6' className='trade_card_header'>
                          2. Гарантия качества{' '}
                        </Card.Header>
                      )}
                      <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                        <InputFormEconomic
                          id={4}
                          width={10}
                          show={true}
                          label='Гарантия после пуска: '
                          placeholder='Гарантия, мес.'
                          description='Экономическая информация'
                          value={value[4] && value[4].value}
                          onChangeValue={doChangeValue}
                          nameForm={'EconomicData'}
                          verify='number'
                          upperLabel={'Гарантия, мес.'}
                          veryfyProcent={veryfyProcent}
                        />
                        <Row> &nbsp;</Row>

                        <InputFormEconomic
                          id={5}
                          width={10}
                          show={true}
                          label='Гарантия после отгрузки: '
                          placeholder='Гарантия, мес.'
                          description='Экономическая информация'
                          value={value[5] && value[5].value}
                          onChangeValue={doChangeValue}
                          nameForm={'EconomicData'}
                          verify='number'
                          upperLabel={'Гарантия, мес.'}
                          veryfyProcent={veryfyProcent}
                        />
                        <Row> &nbsp;</Row>
                      </Card.Body>
                    </Card>
                    <Row> &nbsp;</Row>
                    <Row> &nbsp;</Row>

                    <Card style={{ border: '1px #ccc solid' }}>
                      {msgMoreThan100 && totalPercent > 100 ? (
                        <Card.Header as='h6' className='trade_card_header_error'>
                          <div> 3. Условия оплаты </div>
                          <div style={{ fontSize: '12px' }}>
                            {' '}
                            <ErrorOutlineOutlinedIcon style={{ fontSize: '15px' }} className='pb_3' /> Сумма предоплаты превышает 100%
                          </div>
                        </Card.Header>
                      ) : (
                        <Card.Header as='h6' className='trade_card_header'>
                          3. Условия оплаты{' '}
                        </Card.Header>
                      )}

                      <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                        <InputFormEconomic
                          id={6}
                          width={10}
                          show={true}
                          label='Предоплата: '
                          placeholder='Предоплата, %'
                          description='Экономическая информация'
                          value={value[6] && value[6].value}
                          onChangeValue={doChangeValue}
                          nameForm={'EconomicData'}
                          verify='number'
                          upperLabel={'Предоплата, %'}
                          veryfyProcent={veryfyProcent}
                        />
                        <Row> &nbsp;</Row>

                        <InputFormEconomic
                          id={7}
                          width={10}
                          show={true}
                          label='Доплата перед отгрузкой: '
                          placeholder='Доплата перед отгрузкой, %'
                          description='Экономическая информация'
                          value={value[7] && value[7].value}
                          onChangeValue={doChangeValue}
                          nameForm={'EconomicData'}
                          verify='number'
                          upperLabel={'Доплата перед отгрузкой, %'}
                          veryfyProcent={veryfyProcent}
                        />
                        <Row> &nbsp;</Row>
                        <InputFormEconomic
                          id={8}
                          width={10}
                          show={true}
                          label='Доплата после отгрузки: '
                          placeholder='Доплата после отгрузки, %'
                          description='Экономическая информация'
                          value={value[8] && value[8].value}
                          onChangeValue={doChangeValue}
                          nameForm={'EconomicData'}
                          verify='number'
                          upperLabel={'Доплата после отгрузки, %'}
                          veryfyProcent={veryfyProcent}
                        />
                        <Row> &nbsp;</Row>

                        {<span style={{ display: 'flex', justifyContent: 'flex-end' }}>Итого: {+totalPercent}%</span>}

                        <Row> &nbsp;</Row>
                      </Card.Body>
                    </Card>
                  </>
                )}

                <Row> &nbsp;</Row>
                <Row> &nbsp;</Row>

                <Card style={{ border: '1px #ccc solid' }}>
                  <Card.Header as='h6' className='trade_card_header'>
                    4. Сроки доплаты{' '}
                  </Card.Header>

                  <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                    <InputFormEconomic
                      id={9}
                      width={10}
                      show={true}
                      label='Срок доплаты после отгрузки: '
                      placeholder='Срок доплаты после отгрузки'
                      description='Экономическая информация'
                      value={value[9] && value[9].value}
                      onChangeValue={doChangeValue}
                      nameForm={'EconomicData'}
                      verify='number'
                      upperLabel={'Срок доплаты после отгрузки, дней'}
                    />

                    <Row> &nbsp;</Row>
                  </Card.Body>
                </Card>

                <Row> &nbsp;</Row>

                <Row> &nbsp;</Row>
              </Container>

              <AllModalsFooter
                footerContent={

                  <SaveCurrentDataButton handleSaveClick={saveDataWithNotification} needDisable={false} savedDataMessage={savedDataMessage} buttonTextDefault={'Сохранить'} buttonTextSaved={'Данные сохранены'} needCustomIcon={false} />

                }
              />
            </div>
          </Form>
        </div>
      )}
    </div>
  )
}
