import FormCoast from "./FormCoast";



const CoastHeatExchangers = () => {
    console.log(`coast heat exchangers`); // test

    return (
        <>
            <div style={{ padding: '80px 150px 20px 150px' }}>
                <FormCoast />
            </div>
        </>
    )
}

export default CoastHeatExchangers;