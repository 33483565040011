export const CARBON = [
    "Ст3 ",
    "Ст4",
    "Сталь 10",
    "Сталь 20",
    "15К",
    "16К",
    "18К",
    "20К ",
    "20КА",
    "20Юч",
    "20ПВ",
    "22К",
    "AISI 1017",
    "St 37-3",
    "AISI 1020",
    "AISI Grade C",
    "EN 1.0425",
    "EN 1.0426"
];

export const LOWALLOY = [
    "07ГФБ-У", "09Г2", "09Г2С", "09Г2БТ", "09Г2СЮЧ", "09ГСНБЦ", "09ХГН2АБ", "09ХГ2СЮЧ", "09ХГ2НАБЧ", "10Г2", "10Г2БТ", "10Г2ФБ", "10Г2ФБЮ", "10Г2С1", "10Г2С1Д", "10ХСНД", "13Г1С-У", "14Г2", "15ХСНД", "15Г2СФ", "16ГС", "16ГМЮЧ", "17ГС", "17Г1С", "Е32", "Е36", "Е40", "В", "Д32", "Д36", "Д40", "К52",
    "AISI 1513",
    "AISI Grade 6",
    "AISI Grade 70",
    "EN 1.0571"
];

export const CHROMIUM = [
    "12МХ", "12ХМ", "12Х1МФ", "15ХМ", "1Х2М1", "20Х2МА", "15Х2МФА-А", "12Х2МФА", "10Х2М1А-А", "AISI Grade T11"
];

export const MARTENSITIC = [
    "15Х5", "15Х5М", "15Х5ВФ", "12Х8", "12Х8ВФ", "20Х13", "13Х9М", "12Х13", "20Х13", "AISI 410", "EN 1.4006", "AISI F6A"
];

export const FERRITIC = [
    "08Х13", "08Х17Т", "15Х25Т", "AISI 409", "AISI 410S", "AISI 429"
];

export const AUSTENITIC = [
    "02Х18Н11", "02Х8Н22С6", "03Х18Н11", "03Х17Н14М3", "03Х19АГ3Н10", "03Х21Н21М4ГБ", "07Х13АГ20", "08Х18Н10Т", "08Х18Н12Б", "08Х17Н13М2Т", "08Х17Н15М3Т", "10Х14Г14Н4Т", "10Х17Н13М2Т", "10Х17Н13М3Т", "12Х18Н9Т", "12Х18Н10Т", "12Х18Н12Т", "AISI 304", "EN 1.4306", "AISI 316", "EN 1.4404", "AISI 321", "EN 1.4541", "AISI 316L", "EN 1.4571"
];

export const AUST_FERR = [
    "08Х22Н6Т", "08Х21Н6М2Т", "08Х18Г8Н2Т", "15Х18Н12С4ТЮ", "AISI 316Ti"
];

export const AUST_MARTENSITIC = [
    "07Х16Н6"
];

export const IRON_NICKEL = [
    "06ХН28МДТ", "03ХН28МДТ", "05ХН32Т", "AISI N08028", "EN 1.4563"
];

export const TITANIUM = [
    "ВТ1-00", "ВТ1-0", "ОТ4-0"
];

export const COOPER_NICKEL = [
    "МНЖ5-1", "МНЖМц 30-1-1"
];

export const NICKEL = [
    "НМЖМц 28-2,5-1,5",
    "МНЖ5-1",
    "МНЖМц 30-1-1"
];

export const STUD = [
    "ГОСТ 9066-75",
    "ГОСТ 22042-76",
    "ГОСТ 22032-76",
    "ГОСТ 22034-76",
    "ГОСТ 22038-76",
    "ГОСТ 22040-76",
    "ОСТ 26-2040-96",
    "ОСТ 26-2039-96",
    "DIN 835",
    "DIN 938",
    "DIN 939",
    "DIN 940"
];

export const NUT = [
    "ГОСТ 9064-75",
    "ГОСТ ISO 4032-2014",
    "ОСТ 26-2041-96",
    "DIN 934"
];

export const BOLT = [
    "ГОСТ 7796-70",
    "ГОСТ 7798-70",
    "ГОСТ ISO 4014-2013",
    "DIN 931",
    "DIN 933"

];

export const TAPS = [
    "ГОСТ 17375-2001",
    "ГОСТ 30753-2001",
    "ТУ 1468-010-593377520-2003",
    "ТУ 1468-020-20872280-2004",
    "ТУ 1468-284-20872280-2005"
];

export const TRANSITION = [
    "ГОСТ 17378-2001",
    "ТУ 1469-006-82932963-2018",
    "ТУ 1468-030-20872280-2002"
];

export const CARBON_FORGINGS = [
    "Ст3",
    "20",
    "20К",
    "20КА",
    "20Юч",
    "22К",
    "AISI 1017",
    "St 37-3",
    "AISI 1020",
    "AISI Grade C",
    "EN 1.0425",
    "EN 1.0426"
];

export const LOW_ALLOY_FORGINGS = [
    "09Г2С",
    "09ГСНБЦ",
    "09ХГН2АБ",
    "10Г2",
    "16ГС",
    "AISI 1513",
    "AISI Grade 6",
    "AISI Grade 70",
    "EN 1.0571"
];

export const CHROM_FORGINGS = [
    "12МХ",
    "12ХМ",
    "12Х1МФ",
    "15ХМ",
    "20Х2МА",
    "15Х2МФА-А",
    "10Х2М1А-А",
    "AISI Grade T11"
];

export const MARTENSITIC_FORGINGS = [
    "15Х5",
    "15Х5М",
    "15Х5ВФ",
    "12Х13",
    "AISI 410", "EN 1.4006", "AISI F6A"
];

export const FERRITIC_FORGINGS = ["08Х13", "AISI 409", "AISI 410S", "AISI 429"];

export const AUSTENITIC_FORGINGS = [
    "03Х18Н11",
    "03Х17Н14М3",
    "08Х18Н10Т",
    "10Х17Н13М2Т",
    "10Х17Н13М3Т",
    "12Х18Н9Т",
    "12Х18Н10Т",
    "AISI 304", "EN 1.4306", "AISI 316", "EN 1.4404", "AISI 321", "EN 1.4541", "AISI 316L", "EN 1.4571"
];

export const AUST_FERR_FORGINGS = [
    "08Х22Н6Т",
    "08Х21Н6М2Т",
    "AISI 316Ti"
];

export const IRON_NICKEL_FORGINGS = [
    "06ХН28МДТ", "AISI N08028", "EN 1.4563"
];

export const TITANIUM_FORGINGS = [
    "ВТ1-00", "ВТ1-0", "ОТ4-0"
];

export const NICKEL_FORGINGS = [
    "НМЖМц 28-2,5-1,5",
    "МНЖ5-1",
    "МНЖМц 30-1-1"
]


export const FLANGES = [
    "ГОСТ 28759.2-90",
    "ГОСТ 28759.3-90",
    "ГОСТ 28759.4-90",
    "ГОСТ 33259-2015",
    "ГОСТ 9399-81",
    "ОСТ 26-01-1298-81",
    "РД 24.202.01-90",
    "РД 24.202.02-90",
    "DIN EN 1092-1",
    "ASME B16.5"
];

export const LABA = [
    "Магнитный",
    "Визуально-измерительный (оптический)",
    "Электрический, вихретоковый (электромагнитный)",
    "Тепловой, радиоволновой",
    "Ультразвуковой (акустический)",
    "Радиационный (радиографический)",
    "Капиллярный (проникающими веществами)",
    "Вибрационный"
];

export const TROYNICK = [
    "ГОСТ 17376-2001",
    "ГОСТ 22825-83",
    "ГОСТ 22824-83",
    "ГОСТ 22823-83",
    "ГОСТ 22822-83",
    "ГОСТ 22804-83",
    "ГОСТ 22803-83",
    "ГОСТ 22802-83",
    "ГОСТ 22801-83",
    "ОСТ 34.42.673",
    "ОСТ 34.10.511",
    "ОСТ 34.10.510",
    "ОСТ 24.125.17",
    "ОСТ 34.10.433",
    "ОСТ 34.10.432",
    "ОСТ 108.720.01",
    "ОСТ 108.104.04-82"
];

export const SHAYBA = [
    "ГОСТ 11371-78",
    "ГОСТ 9065-75",
    "ОСТ 26-2042-96",
    "DIN 125"
]