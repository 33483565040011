/* eslint-disable default-case */

import { nanoid } from 'nanoid'
import React from 'react'
import { Form, Alert, Button, Row, Col, Dropdown, Toast } from 'react-bootstrap'
import Server from '../server/server'
import SendOutlinedIcon from '@mui/icons-material/SendOutlined'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import avatar from '../assets/images/avatar.jpg'
import PlagiarismOutlinedIcon from '@mui/icons-material/PlagiarismOutlined'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
// import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined'
// import ShortcutOutlinedIcon from '@mui/icons-material/ShortcutOutlined';
// import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { loadImageURL } from '../settings/loadImageURL'
import { io } from 'https://cdn.socket.io/4.4.1/socket.io.esm.min.js'

// onChangeVisibleChat={тоггл модалки чата}
// onCreateIdChat={???}
// idChat={wholeLot && wholeLot.chat id чата в лоте (CreateLot->state->data->chat)}
// forName={со стороны заказчика передать строку 'all', со стороны участника organizerId - в CreateLot->data->organizerId}
// idCompany={со стороны ЗАКАЗЧИКА - массив инн участников: CreateLot->data->participant; со стороны УЧАСТНИКА - организатор лота organizerId}
// idBox={id лота: CreateLot->data->id}
// updateQuantityMessage={???}
// numberLot={числовой номер лота (когда открываешь с навбара, чтобы можно было по этому номеру перейти в лот)}
// filterAll={со стороны ЗАКАЗЧИКА CreateLot->data->participant; }

export default class ChatWs extends React.Component {
  constructor(props) {
    super(props)

    this.arrData = []

    this.state = {
      idChat: '',
      idBox: '', // Передаваемый пропсом ID оболочки нахождения чата (как вариант - ID LOT)
      idCompany: [], // массив ID компаний участвующих в чате (filter) props incoming
      question: [], // массив возможных вопросов из чата (filter)
      participant: [], // массив участников от которых есть сообщение
      choisenSubscriber: 'all', // подписка. Сортировка сообщений для выбранного подписчика
      newChat: false, // определяем нужно ли создавать новый или обновлять имеющийся чат
      show: true,
      // stepInterval: 3000, // шаг таймера опроса новых сообщений
      forName: 'all',
      us: 'От нас: ',
      baseMessage: [],
      message: [],
      filterAll: ['all'], // массив для фильтра сообщений (all или по idCompany)
      filterQuestion: '', // поле в сообщении с вопросом для фильтра - question
      base: [],
      connectedObject: { all: 'Всем' }, // связанный объект ИНН: название компании
      searchTerm: '',
      isClicked: false,
      // wsUrl: 'ws://192.168.1.52:3031', //* WS SOCKET local
      // wsUrl: 'ws://192.168.1.50:3031', //* WS SOCKET local
      // wsUrl: `wss://81.91.59.38:`, // proxy

      // wsUrl: 'ws://localhost:3031', // ** по умолчанию
      wsUrl: `wss://test.chat.lotus-uems.ru`, // *** работает server test 50
      // wsUrl: `wss://chat.lotus-uems.ru`, // *** работает server production 52
      replyArrowArray: [],
      showReplayMessage: false,
      replyMsgText: '',
      replyPeopleArray: [],
      preText: '',
      icon: '',
      messageInInput: ''
    }

    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.renderMessage = this.renderMessage.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleClickSend = this.handleClickSend.bind(this)
    this.runChat = this.runChat.bind(this)
    this.onKeyPressHandler = this.onKeyPressHandler.bind(this)
    this.countQuestion = this.countQuestion.bind(this)
    this.collectNameCompany = this.collectNameCompany.bind(this)
    this.renderConnectedObject = this.renderConnectedObject.bind(this)
    this.handleClickChoiceCompany = this.handleClickChoiceCompany.bind(this)
    this.renderQuestions = this.renderQuestions.bind(this)
    this.handleClickQuestion = this.handleClickQuestion.bind(this)
    this.renderParticipant = this.renderParticipant.bind(this)
    this.handleClickParticipant = this.handleClickParticipant.bind(this)
    this.onSearchCompany = this.onSearchCompany.bind(this)
    this.renderConnectedObjectAll = this.renderConnectedObjectAll.bind(this)
    this.hadleClickIdBox = this.hadleClickIdBox.bind(this)
    this.replyToMessage = this.replyToMessage.bind(this)
    this.handleOnMouseOver = this.handleOnMouseOver.bind(this)
    this.clearStateAfterReply = this.clearStateAfterReply.bind(this)
    this.replyToMessageTest = this.replyToMessageTest.bind(this)
  }

  async doChangeLogo() {
    this.setState({ icon: await loadImageURL(`icon_logo`) }) // возвращает URL строку для icon
  }

  // переход к лоту
  hadleClickIdBox() {
    if (!this.props.idBox) {
      // console.log(`idBox - получен с DB, переход по ссылке БДУЕТ! ${this.state.idBox}`);
      // todo: организовать переход к ЛОТу по ID LOT
      if (this.state.idBox) this.props.onIdLot(this.state.idBox)
    }
  }

  onSearchCompany(e) {
    ; (async () => {
      try {
        await this.setState({ searchTerm: e })

        if (e && e.length > 0) {
          const result = { all: 'Всем' }
          const keysArr = Object.keys(this.state.connectedObject)
          const valuesArr = Object.values(this.state.connectedObject)

          valuesArr.forEach((value, index) => {
            if (value.toLowerCase().includes(e.toLowerCase())) {
              const key = String(keysArr[index])
              Object.assign(result, { [key]: value })
            }
          })
          await this.setState({ connectedObject: result })
        } else {
          await this.collectNameCompany(this.props.idCompany)
        }
      } catch (err) {
        console.log(`Ошибка в применении фильтра: `, err)
      }
    })()
  }

  handleClickParticipant(e) {
    // console.log(`click: `, e.target.dataset.participant);
    this.setState({
      choisenSubscriber: e.target.dataset.participant,
      filterAll: [e.target.dataset.participant]
    })
  }

  handleClickQuestion(e) {
    console.log(`click: `, e.target.dataset.question)
  }

  //click выбор компании для фильтра в чате
  handleClickChoiceCompany(e) {
    // console.log(`E -> choice >>> `, e.target.dataset.id); // test
    let arrName = this.state.filterAll

    if (arrName.includes(e.target.dataset.id)) {
      arrName.splice(arrName.indexOf(e.target.dataset.id), 1) // если есть, то удаляем из массива
    } else {
      arrName.push(e.target.dataset.id) // добавляем в массив
    }

    if (arrName.length > 1 && arrName.includes('all')) {
      arrName.splice(arrName.indexOf('all'), 1) // если есть в массиве компании, то убираем - all
    }

    if (e.target.dataset.id === 'all' || arrName.length < 1) {
      arrName = ['all']
      this.setState({
        isClicked: true
      })
    }

    // console.log('arrName -> ', arrName); // test
    this.setState({ filterAll: arrName })
  }

  // todo: текст сообщений ввод ****************
  handleChange(e) {
    e.preventDefault()
    this.setState({ message: e.target.value })
  }

  onKeyPressHandler(event) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      this.handleClickSend()
      event.preventDefault()
    }
  }

  async replyToMessage(resultLoadAllMessage, text, id) {
    let arr = []
    resultLoadAllMessage.forEach((msg, idx) => {
      if (idx === id) {
        msg.filterAll.forEach((inn) => {
          arr.push(this.state.connectedObject[inn])
        })
      }
    })

    await this.setState({
      showReplayMessage: true,
      replyMsgText: text,
      replyPeopleArray: arr
      // message: `${text}: ${this.state.messageInInput && this.state.messageInInput}`
    })

    const textMessage = `${text}`
    await this.setState({
      preText: textMessage
      // messageInInput: ''
    })
  }

  async replyToMessageTest(resultLoadAllMessage, text, id) {
    let arr = []
    resultLoadAllMessage.forEach((msg, idx) => {
      if (idx === id) {
        msg.filterAll.forEach((inn) => {
          arr.push(this.state.connectedObject[msg.name])
        })
      }
    })

    await this.setState({
      showReplayMessage: true,
      replyMsgText: text,
      replyPeopleArray: arr
      // message: `${text}: ${this.state.messageInInput && this.state.messageInInput}`
      // message: text
    })

    //Сообщение, которое отображается в окне ответа(когда жмешь на стрелку)
    // const textMessage = `Сообщение для: ${arr.map(el => el)}, в ответ на: ${text}`
    const textMessage = `${text}`
    this.setState({
      preText: textMessage,
      messageInInput: ''
    })
  }

  //* сохранение сообщений, отправка на сервер
  async handleClickSend() {
    let message

    if (this.state.preText) {
      const newText = this.state.preText.split(':')
      //Сообщение, которое сливается
      // ? Не используемая переменная!!!
      const res = `Для: ${this.props.recipient ? this.props.recipient : this.state.replyPeopleArray.map((el) => el)
        } в ответ на: "${newText[newText.length - 1]}"`

      message = {
        timeChat: new Date().toLocaleString(),
        name: localStorage.getItem('idUser'),
        forName: this.state.forName, // сообщение для определенной компании
        filterAll: this.state.filterAll, // сообщение всем / не всем (ID company)
        filterQuestion: this.state.filterQuestion, // место для вопроса, запроса (доп. фильтр)
        // message: `${res}: ${this.state.message}`,
        message: `${this.state.message}`,
        preText: this.state.preText,
        idBox: this.state.idBox // ID оболочки нахождения чата (ID LOT)
      }
    } else {
      message = {
        timeChat: new Date().toLocaleString(),
        name: localStorage.getItem('idUser'),
        forName: this.state.forName, // сообщение для определенной компании
        filterAll: this.state.filterAll, // сообщение всем / не всем (ID company)
        filterQuestion: this.state.filterQuestion, // место для вопроса, запроса (доп. фильтр)
        message: this.state.message,
        idBox: this.state.idBox // ID оболочки нахождения чата (ID LOT)
      }
    }

    this.setState({
      message: []
    })

    // console.log(`message::: `, message) // test

    // todo: (рабоатет)
    this.socket?.send(
      JSON.stringify({
        id: this.state.idChat,
        method: 'send_new_message',
        message: 'NEW message from FRONT => ',
        nameInn: localStorage.getItem('idUser'),
        idCompany: this.props.idCompany,
        data: message
      })
    )

    this.socket.on('message-chat', async (chatData) => {
      console.log(`RUN render on handleClickSend`) //test

      if (chatData._id === this.state.idChat) {
        await this.renderMessage(chatData.data) // рендер сообщений
      }
    })

    this.setState({
      showReplayMessage: false
    })

    await this.clearStateAfterReply()
  }

  clearStateAfterReply() {
    this.setState({
      showReplayMessage: false,
      replyMsgText: '',
      replyPeopleArray: [],
      preText: ''
    })
  }

  // отбираем из существующего чата все вопросы
  async countQuestion(arrMessage) {
    const resultQuestion = [] // вопросы
    const filterParticipant = [] // участники от которых есть сообщение
    if (arrMessage) {
      arrMessage.forEach((data) => {
        if (data.filterQuestion && !resultQuestion.includes(data.filterQuestion)) {
          resultQuestion.push(data.filterQuestion)
        }

        if (data.forName && !filterParticipant.includes(data.forName)) {
          filterParticipant.push(data.forName)
        }
        if (data.filterAll) {
          data.filterAll.forEach((elem) => {
            if (!filterParticipant.includes(elem)) filterParticipant.push(elem)
          })
        }
      })

      await this.setState({
        question: resultQuestion,
        participant: filterParticipant
      }) // все вопросы в STATE
    }
  }

  renderQuestions(arr) {
    const render = arr.map((data) => {
      return (
        <React.Fragment key={data}>
          <div>
            <span
              data-question={data}
              onClick={this.handleClickQuestion}
              style={{ cursor: 'pointer' }}
            >
              {data}
            </span>
          </div>
        </React.Fragment>
      )
    })
    return render
  }

  renderParticipant(arr) {
    const render = arr.map((data, idx) => {
      return (
        <React.Fragment key={data}>
          <div
            style={{
              padding: '10px',
              cursor: 'pointer',
              fontWeight: 'bolder',
              backgroundColor: this.state.choisenSubscriber === data ? '#E9ECEF' : '#fff'
            }}
            data-participant={data}
            onClick={this.handleClickParticipant}
          >
            {this.state.connectedObject[data]}
          </div>
        </React.Fragment>
      )
    })

    return render
  }

  handleOnMouseOver(id, resultLoadAllMessage) {
    const res = resultLoadAllMessage.fill(false)
    res.forEach((el, idx) => {
      if (idx === id) {
        res[idx] = true
      }
    })

    this.setState({
      replyArrowArray: res
    })
  }

  //рендер сообщений
  async renderMessage(resultLoadAllMessage) {
    // помещаем в STATE вопросы и участников чата с адресованным сообщением или написавшим сообщение
    await this.countQuestion(resultLoadAllMessage)

    if (this.props.idBox) {
      this.setState({ idBox: this.props.idBox })
    } else {
      if (resultLoadAllMessage && resultLoadAllMessage.length) {
        this.setState({ idBox: resultLoadAllMessage[resultLoadAllMessage.length - 1].idBox })
      }
    }

    if (resultLoadAllMessage) {
      const renderData = resultLoadAllMessage.map((data, index) => {
        if (
          this.state.choisenSubscriber === 'all' ||
          data.filterAll.includes(this.state.choisenSubscriber)
        ) {
          //* условие для участника
          if (this.props.filterAll) {
            //!1
            if (data.name === localStorage.getItem('idUser')) {
              return (
                <div style={{ position: 'relative' }}>
                  <div key={index} className='message_on_hover'>
                    <div className='arrow_right'>
                      <div
                        className='chat_reply_arrow_common chat_reply_arrow_right_'
                        onClick={() =>
                          this.replyToMessage(resultLoadAllMessage, data.message, index)
                        }
                      >
                        <ReplyOutlinedIcon fontSize='small' />
                      </div>

                      <div style={{ color: '#fff' }}>
                        <b>
                          {' '}
                          {data.filterAll.map((data, inx) => {
                            return (
                              <React.Fragment key={String(this.state.connectedObject[data]) + 'A'}>
                                <div>{this.state.connectedObject[data]}</div>
                              </React.Fragment>
                            )
                          })}
                        </b>
                      </div>
                      <div>
                        {data.preText && (
                          <div className='reply_msg_style'>
                            {data.preText && `В ответ на: ${data.preText} `}
                          </div>
                        )}
                        <div>{data.message}</div>
                      </div>
                      {/* {data.message} */}
                    </div>
                    <div className='chat_message_date_style'>{data.timeChat}</div>
                  </div>
                </div>
              )
            }

            if (data.filterAll.includes(localStorage.getItem('idUser'))) {
              console.log(data.filterAll)
              console.log(this.state.connectedObject)
              //!2
              return (
                <div style={{ position: 'relative', marginBottom: '30px' }}>
                  <div key={index} className='message_on_hover'>
                    <div className='arrow_left'>
                      <div
                        className='chat_reply_arrow_common chat_reply_arrow_left_'
                        onClick={() =>
                          this.replyToMessageTest(resultLoadAllMessage, data.message, index)
                        }
                      >
                        <ReplyOutlinedIcon fontSize='small' />
                      </div>

                      <div style={{ color: '#fff' }}>
                        <b>
                          {/* {
                                                    data.filterAll.map((el, inx) => {
                                                        console.log(el);
                                                        return (
                                                            <React.Fragment 
                                                            // key={String(this.state.connectedObject[data.name]) + 'B'} 
                                                            key={`${el}_${inx}`}
                                                            >
                                                                <div >
                                                                    {this.state.connectedObject[data.name]}
                                                                </div>
                                                            </React.Fragment>
                                                        )
                                                    })} */}
                          {data.filterAll.map((el, inx) => {
                            // console.log(el)
                            return (
                              <React.Fragment key={`${el}_${inx}`}>
                                <div>{this.state.connectedObject[el]}</div>
                              </React.Fragment>
                            )
                          })}
                        </b>
                      </div>
                      <div>
                        {data.preText && (
                          <div className='reply_msg_style'>
                            {data.preText && `В ответ на: ${data.preText} `}
                          </div>
                        )}
                        <div>{data.message}</div>
                      </div>
                      {/* {data.message} */}
                    </div>
                    <div
                      style={{ position: 'absolute', bottom: '-20px', left: '200px' }}
                      className='chat_message_date_style'
                    >
                      {data.timeChat}
                    </div>
                  </div>
                </div>
              )
            }

            if (data.filterAll.includes('all')) {
              //!3
              return (
                <div style={{ position: 'relative', marginBottom: '30px' }}>
                  <div key={index} className='message_on_hover'>
                    <div className='arrow_left'>
                      <div
                        className='chat_reply_arrow_common chat_reply_arrow_left_'
                        onClick={() =>
                          this.replyToMessage(resultLoadAllMessage, data.message, index)
                        }
                      >
                        <ReplyOutlinedIcon fontSize='small' />
                      </div>

                      <div style={{ color: '#fff' }}>
                        <b>
                          {' '}
                          {data.filterAll.map((data, inx) => {
                            return (
                              <React.Fragment key={String(this.state.connectedObject[data]) + 'C'}>
                                <div>{this.state.connectedObject[data]}</div>
                              </React.Fragment>
                            )
                          })}
                        </b>
                      </div>

                      <div>
                        {data.preText && (
                          <div className='reply_msg_style'>
                            {data.preText && `В ответ на: ${data.preText} `}
                          </div>
                        )}
                        <div>{data.message}</div>
                      </div>

                      {/* {data.message} */}
                    </div>
                    <div
                      style={{ position: 'absolute', bottom: '-20px', left: '200px' }}
                      className='chat_message_date_style'
                    >
                      {data.timeChat}
                    </div>
                  </div>
                </div>
              )
            }
          } else {
            //* условие для организатора
            //!4
            if (data.name === localStorage.getItem('idUser')) {
              return (
                <div style={{ position: 'relative' }}>
                  <div key={index} className='message_on_hover'>
                    <div className='arrow_right'>
                      <div style={{ color: '#fff' }}>
                        <div
                          className=' chat_reply_arrow_common chat_reply_arrow_right'
                          onClick={() =>
                            this.replyToMessage(resultLoadAllMessage, data.message, index)
                          }
                        >
                          <ReplyOutlinedIcon fontSize='small' />
                        </div>
                        <b>
                          {data.filterAll.map((data) => {
                            return (
                              <React.Fragment key={String(this.state.connectedObject[data]) + 'D'}>
                                <div style={{ marginBottom: '5px' }}>
                                  {this.state.connectedObject[data]}
                                </div>
                              </React.Fragment>
                            )
                          })}
                        </b>
                      </div>
                      <div>
                        {data.preText && (
                          <div className='reply_msg_style'>
                            {data.preText && `В ответ на: ${data.preText} `}
                          </div>
                        )}
                        <div>{data.message}</div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: '0px' }} className='chat_message_date_style'>
                    {data.timeChat}
                  </div>
                </div>
              )
            }

            return (
              //!5
              <div style={{ marginBottom: '30px', position: 'relative' }}>
                <div key={index} className='message_on_hover'>
                  <div className='arrow_left'>
                    <div
                      className='chat_reply_arrow_common chat_reply_arrow_left'
                      onClick={() =>
                        this.replyToMessageTest(resultLoadAllMessage, data.message, index)
                      }
                    >
                      <ReplyOutlinedIcon fontSize='small' />
                    </div>

                    <b>
                      {' '}
                      {data.filterAll.map((el) => {
                        // console.log(this.state.replyPeopleArray);
                        return (
                          <React.Fragment key={String(this.state.connectedObject[data.name]) + 'E'}>
                            <div>{this.state.connectedObject[data.name]}</div>
                          </React.Fragment>
                        )
                      })}
                    </b>
                    <div>
                      {data.preText && (
                        <div className='reply_msg_style'>
                          {data.preText && `В ответ на: ${data.preText} `}
                        </div>
                      )}
                      <div>{data.message}</div>
                    </div>
                    {/* <div>{data.message}</div> */}
                  </div>
                </div>
                <div
                  style={{ position: 'absolute', bottom: '-20px', left: '200px' }}
                  className='chat_message_date_style'
                >
                  {data.timeChat}
                </div>
              </div>
            )
          }
        }
      })
      this.setState({ base: renderData })
    }
  }

  handleClickShadow() {
    this.props.updateQuantityMessage()

    // todo: (рабоатет)
    this.socket?.send(
      JSON.stringify({
        id: this.state.idChat,
        message: 'Сообщение при разрыве подключения',
        nameInn: localStorage.getItem('idUser'),
        idCompany: this.props.idCompany,
        method: 'end_chat'
      })
    )

    console.log(`Message -> disconnect`) // test
    this.socket.close('close', () => {
      console.log(`WS Соединение разорвано`)
    })

    this.props.onChangeVisibleChat()
  }

  // TODO: запуск чата. Создаем WS подключение
  async runChat() {
    try {
      //   console.log(`WS URL::: `, this.state.wsUrl) // test
      //   console.log(`ID CHAT:::: `, this.props.idChat) // test

      //* socket.io подключение
      this.socket = io(this.state.wsUrl)
      //   console.log(`SOCKET:::: `, this.socket) // test

      //** socet.io connecting
      this.socket.on('connect', async () => {
        // console.log(`СОЕДИНЕНИЕ чата СОСТОЯЛОСЬ!`) // WS создание соединения

        this.engine = this.socket.io.engine
        // console.log(`>>>>>>>>>>>>>>>>>>> `, this.engine.transport.name) //test

        this.socket.on('text', (message) => {
          console.log(`FIRST server:::::>> `, message) // todo: работает
        })

        if (this.props.idChat) {
          //todo: (работает) socet.io сообщение для сервера
          this.socket?.send(
            JSON.stringify({
              id: this.state.idChat,
              message: `Подключение, первое сообщение. ID чата есть.`,
              nameInn: localStorage.getItem('idUser'),
              idCompany: this.props.idCompany,
              method: 'connection'
            })
          )
        } else {
          console.log(`ЕСЛИ ID НЕТ!!!`)
          const idNew = nanoid()

          await this.setState({
            idChat: idNew, // id chat
            newChat: true // check for NEW CHAT
          })

          const dataChat = [
            {
              timeChat: Date.now(),
              name: localStorage.getItem('idUser'),
              forName: this.state.forName,
              filterAll: this.state.filterAll, // сообщение всем / не всем (ID company)
              filterQuestion: this.state.filterQuestion, // место для вопроса, запроса (доп. фильтр)
              message: 'Новый чат',
              idBox: this.state.idBox
            }
          ]

          this.socket?.send(
            JSON.stringify({
              id: idNew,
              message: 'Подключение, первое сообщение. ID чата нет! Новый чат!!!!',
              nameInn: localStorage.getItem('idUser'),
              idCompany: this.props.idCompany,
              method: 'create_new_chat',
              data: dataChat
            })
          )
          this.props.onCreateIdChat(this.state.idChat) // передача idChat родителю
        }

        // при подключении получаем данные чата с DB
        this.socket.on('message-chat', async (chatData) => {
          console.log(`RUN render on runChat`) // test

          if (chatData._id === this.state.idChat) {
            await this.renderMessage(chatData.data) // рендер сообщений
          }
        })
      }) // **
    } catch (err) {
      console.log(`Ошибка запуска чата: `, err)
    }
  }

  async collectNameCompany(idCompany) {
    if (idCompany.length < 1) return this.state.connectedObject
    const getForm = await Server.getDataFromServer('Main', idCompany) // array Main
    const connectedObject = this.state.connectedObject
    let getMainForm

    if (getForm && getForm[1].value) {
      getMainForm = [getForm]
    } else {
      getMainForm = getForm
    }

    getMainForm.forEach((data, index) => {
      Object.assign(connectedObject, { [idCompany[index]]: data[1].value }) // связываем в объект {ИНН: название компании}
    })
    this.setState({ connectedObject: connectedObject })
    return connectedObject
  }

  renderConnectedObject(data) {
    let renderData = []

    for (let key in data) {
      renderData.push(
        <React.Fragment key={key}>
          <div
            className={
              this.state.filterAll.includes(key) ? 'recipientStyle' : 'recipientStyleActive'
            }
            style={{ display: data[key] === 'Всем' ? 'none' : 'block', position: 'relative' }}
            key={key}
            data-id={key}
            onClick={this.handleClickChoiceCompany}
          >
            <span
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
              data-id={key}
            >
              <img src={avatar} alt='' className={'avatar'} /> {data[key]}
            </span>
            <span
              style={{
                display: this.state.filterAll.includes(key) ? 'inline' : 'none',
                position: 'absolute',
                left: '50px',
                bottom: '8px'
              }}
            >
              <CheckCircleIcon style={{ color: '#73c586' }} />
            </span>
          </div>
        </React.Fragment>
      )
    }
    return renderData
  }

  renderConnectedObjectAll(data) {
    let renderData = []

    for (let key in data) {
      renderData.push(
        <React.Fragment key={key}>
          <div
            style={{
              padding: '10px',
              cursor: 'pointer',
              fontWeight: 'bolder'
            }}
            key={key}
            data-id={key}
            onClick={this.handleClickChoiceCompany}
          >
            <span
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
              data-id={key}
            >
              {data[key]}
            </span>
          </div>
        </React.Fragment>
      )
    }
    return renderData
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView({ behavior: 'smooth' })
  }

  async componentDidMount() {
    // console.log(`URL WS >>>>>>>>>>>>>>>>>>> `, this.state.wsUrl) // test

    document.addEventListener('keydown', this.onKeyPressHandler)
    this.setState({
      idChat: this.props.idChat
      // replyMsgText: '',
      // showReplayMessage: false
    })

    if (this.props.forName) {
      this.setState({ forName: String(this.props.forName) })
      // console.log(`FOR NAME:::: `, this.props.forName); // test
    }

    if (this.props.filterAll) {
      this.setState({ filterAll: this.props.filterAll })
      // console.log(`filterAll :::: `, this.props.filterAll); // test
    }

    if (this.props.idCompany) {
      await this.collectNameCompany(this.props.idCompany)
      this.setState({ idCompany: this.props.idCompany })
      // console.log(`ID COMPANY:::: `, this.props.idCompany); // test
    }

    if (this.props.preText && this.props.showChat) {
      this.setState({
        preText: `Вопрос о:  ${this.props.preText}`,
        replyMsgText: `Вопрос о:  ${this.props.preText}`,
        showReplayMessage: true
      })
    }

    await this.runChat()
    this.scrollToBottom()
    // TODO: по условию входных пропсов сформировать сообщение с вопросом или темой и отправить в чат
    this.doChangeLogo()
  }

  componentDidUpdate() {
    this.scrollToBottom()
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPressHandler)
  }

  render() {
    const show = this.state.show
    const { modalWidth } = this.props
    // console.log(this.props.recipient);
    // console.log(this.props.preText);
    return (
      <>
        <div style={{ display: show ? 'block' : 'none' }}>
          <div
            className='shadow_form'
            style={{ zIndex: '21' }}
            onClick={this.handleClickShadow}
          ></div>
          <Form className={`modal_chat ${modalWidth}`} style={{ zIndex: '21' }}>
            <div className={'chat_grid_layout'}>
              <Alert onClose={() => this.handleClickShadow()} dismissible className={'chatHeader'}>
                {!this.props.idBox && (
                  <Button style={{ cursor: 'pointer' }} onClick={this.hadleClickIdBox}>
                    Перейти к ЛОТу: №{this.props.numberLot}
                  </Button>
                )}
              </Alert>

              <div className={'leftChatBar'}>
                <div className={'leftBarIcons'}>
                  <div style={{ margin: '20px 0 40px 0', position: 'relative' }}>
                    <img
                      src={this.state.icon}
                      alt=''
                      style={{ width: '50px', height: '50px', borderRadius: '50%' }}
                    />
                    <div className='chat_online_circle'></div>
                  </div>

                  <Dropdown title='Отправить всем'>
                    <Dropdown.Toggle size='sm' className={'leftBarIcon'}>
                      <GroupAddIcon style={{ color: '#adb5bd' }} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <span style={{ backgroundColor: '#fff' }}>
                        {this.renderConnectedObjectAll({ all: 'Всем' })}
                      </span>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown>
                    <Dropdown.Toggle size='sm' autoClose={false} className={'leftBarIcon'}>
                      <FilterAltOutlinedIcon style={{ color: '#adb5bd' }} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>{this.renderParticipant(this.state.participant)}</Dropdown.Menu>
                  </Dropdown>

                  <Dropdown>
                    <Dropdown.Toggle size='sm' autoClose={false} className={'leftBarIcon'}>
                      <PlagiarismOutlinedIcon style={{ color: '#adb5bd' }} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu></Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>

              <aside className={'chatNav'}>
                <span style={{ display: 'flex', justifyContent: 'center' }}>
                  <Form.Control
                    size='sm'
                    className='searchBarStyle'
                    placeholder='Поиск'
                    onChange={(e) => this.onSearchCompany(e.target.value)}
                  />
                </span>

                {this.renderConnectedObject(this.state.connectedObject)}
              </aside>

              <div className={'containerChat'}>
                <div className={'contentArea'}>
                  {this.state.base}
                  <div
                    style={{ float: 'left', clear: 'both' }}
                    ref={(el) => {
                      this.messagesEnd = el
                    }}
                  ></div>
                </div>

                {this.state.showReplayMessage && (
                  <Toast className='chat_toast'>
                    <Toast.Header onClick={this.clearStateAfterReply}>
                      <ReplyOutlinedIcon color='primary' fontSize='small' />

                      {this.props.recipient ? (
                        <strong className='mr-auto'>{this.props.recipient}</strong>
                      ) : (
                        <strong className='mr-auto'>
                          {this.state.replyPeopleArray.map((el) => {
                            return <div style={{ marginLeft: '10px' }}>{el}</div>
                          })}
                        </strong>
                      )}
                    </Toast.Header>
                    <Toast.Body>{this.state.replyMsgText}</Toast.Body>
                  </Toast>
                )}

                <div className={'containerChat enterMessageArea'}>
                  <Row>
                    <Col
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexGrow: '4'
                      }}
                    >
                      <span className={'enterMessageInputSpan'}>
                        <Form.Control
                          style={{
                            backgroundColor: `${this.state.colorError} `,
                            border: 0
                          }}
                          placeholder='Введите сообщение'
                          type='text'
                          // defaultValue={this.state.message}
                          value={this.state.message}
                          onChange={this.handleChange}
                        />
                      </span>

                      <Button
                        onClick={this.handleClickSend}
                        className='btn_chat'
                        disabled={this.state.message.length <= 0 ? true : false}
                      >
                        <SendOutlinedIcon
                          sx={{ fontSize: 35, color: '#5AD18B' }}
                          className='icon_chat'
                        />
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </>
    )
  }
}
