import React from 'react';
import { Row, Col } from 'react-bootstrap';
import CheckForm from './CheckForm';
import SizeForm from './SizeForm';


export default class ListCheckForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: false,
            materials: [],
            id: 1,
            base: []
        }

        this.renderListMaterials = this.renderListMaterials.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
        this.doChangeValue = this.doChangeValue.bind(this);
    }

    doChangeValue(e) {
        this.props.onChangeValue(e);
        // console.log(e);
    }

    doEmpty(e, id) {

    }

    renderListMaterials(elem) {
        this.dataM = [];
        this.idRealy = Number(this.props.id);

        elem.forEach((data, index) => {

            // console.log(`FOR EACH >>>>>>> ${+this.idRealy + 1} >>>> `, this.value[+this.idRealy].value); // test
            //* при первом запуске сохраняем все материалы как - false
            if (!this.value[+this.idRealy]) this.doChangeValue({ id: (+this.idRealy), description: this.props.description, information: data, value: false })

            this.dataM = [...this.dataM,
            <React.Fragment key={+this.props.id + index}>

                <Row>
                    <Col> <h6> {data} </h6></Col>
                </Row>
                <Col>
                    <Row>
                        <CheckForm // 92
                            name={`${this.idRealy + 1}`}
                            id={+this.idRealy + 1}
                            width={this.props.width}
                            show={this.props.onCheck}
                            // show={!this.props.readOnly && this.value[+this.idRealy].value ? false : true}
                            placeholder={this.props.palceholderCheck && this.props.palceholderCheck[0]} //* подпись LABEL для чекбокса
                            // placeholder={data} //* подпись LABEL для чекбокса
                            description={this.props.description}
                            value={this.value[+this.idRealy + 1] ? this.value[+this.idRealy + 1].value : false}//* при первом запуске - false                           
                            onChangeVisionBlock={this.doEmpty}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.props.nameForm}
                            readOnly={this.props.readOnly}
                        />
                        <SizeForm  // 93
                            id={+this.idRealy + 2}
                            width={this.props.widthSize}
                            fontSize={this.fontSize}
                            show={this.props.showSizeForm}
                            // show={!this.props.readOnly ? false : true}
                            value={this.value}
                            description={`${this.props.description} ${data}`}
                            placeholder={this.props.placeholder}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.props.nameForm}
                            readOnly={this.props.readOnly}
                        />
                    </Row>
                    <Row>
                        <CheckForm
                            name={`${this.idRealy + 5}`}
                            id={+this.idRealy + 5}
                            width={+this.props.width}
                            show={this.props.onCheck}
                            placeholder={this.props.palceholderCheck && this.props.palceholderCheck[1]} //* подпись LABEL для чекбокса
                            // placeholder={data} //* подпись LABEL для чекбокса
                            description={this.props.description}
                            value={this.value[+this.idRealy + 5] ? this.value[+this.idRealy + 5].value : false}//* при первом запуске - false
                            onChangeVisionBlock={this.doEmpty}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.props.nameForm}
                            readOnly={this.props.readOnly}
                        />
                        <SizeForm
                            id={+this.idRealy + 6}
                            width={this.props.widthSize}
                            fontSize={this.fontSize}
                            show={this.props.showSizeForm}
                            value={this.value}
                            description={`${this.props.description} ${data}`}
                            placeholder={this.props.placeholder}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.props.nameForm}
                            readOnly={this.props.readOnly}
                        />
                    </Row>
                </Col>
                <Row className='border_bottom_underline' > &nbsp; </Row>
                <Row>&nbsp;</Row>
            </React.Fragment>
            ]
            this.idRealy = this.idRealy + 9;
        })
        this.setState({ base: this.dataM });
    }

    componentDidMount() {
        this.setState({ materials: this.props.materials, id: this.props.id })
        setTimeout(() => { this.renderListMaterials(this.props.materials) })
    }

    render() {

        this.value = this.props.value;
        this.fontSize = this.props.fontSize;

        // console.log(this.state.materials);

        return (
            <>
                {this.state.base}
            </>
        )
    }
}