export const COUNTRY = [
    'Россия',
    'Республика Азербайджан',
    'Республика Армения',
    'Республика Беларусь',
    'Республика Казахстан',
    'Кыргызская Республика',
    'Республика Молдова',
    'Республика Таджикистан',
    'Республика Узбекистан',
]