import React, { useEffect, useState } from 'react'
import Server from '../server/server';
import ViewLogger from './ViewLogger';

const Logger = () => {

    const [loggerData, setLoggerData] = useState()

    useEffect(() => {
        console.log(`GET LOGGER`); // test
        (async () => {
            try {
                const result = await Server.getLogger()
                const data = (result.split('\n'))

                let resultData = []
                data.forEach(str => {
                    if (str) {
                        resultData.push(JSON.parse(str))
                    }
                })
                resultData = resultData.reverse()
                setLoggerData(resultData)
                console.log(`result: `, (resultData)); // test

            } catch (err) {
                console.log(`Ошибка получения логгов: `, err);
                setLoggerData([])
            }
        })()

    }, [])

    return (
        <>
            <ViewLogger
                data={loggerData}
            />
        </>
    )
}

export default Logger;