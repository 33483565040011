import React from 'react';
import { Alert, Container } from 'react-bootstrap';
import ContentLoadFiles from './ContentLoadFiles';

// toggleModal = {закрывает модалку на крестик}
// alertHeading = {заглавие модалки}
// modalContent = {содержимое модалки, все что внутри}
// dataCard - данные по карте аппарата

const ModalLoadFiles = ({ toggleModal, alertHeading, dataCard, doSaveCard }) => {

    return (
        <>
            <div className="shadow_form" onClick={toggleModal} ></div>
            <div className="modal_common_ z_index_1000" style={{width: '1000px'}} >
                <Alert variant="dark" onClose={toggleModal} dismissible>
                    <Alert.Heading >
                        {alertHeading}
                    </Alert.Heading>
                </Alert>
                <Container style={{ height: '300px', overflowY: 'auto', padding: '10px 40px 0 40px' }}>

                    <ContentLoadFiles
                        dataCard={dataCard}
                    />

                </Container>
            </div>
        </>
    )
}
export default ModalLoadFiles;