import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from "react-bootstrap";


/**
 * Рендер INPUT, SELECT or CHECKBOX
 * @param {*} arr - массив объектов для отображения [ { display: 'Марка стали:', key: 'steelGrade:', as: 'select', arr: [] },]
 * as: text, select, checkbox, disabled
 * @returns 
 */
const FormControlInputOrSelect = ({ arr, returnObject, data }) => {

    // const obj = {}
    const [obj, setObj] = useState({})
    const [point, setPoint] = useState()

    useEffect(() => {
        if (data) {
            setObj(data)
        }
    }, [data])

    useEffect(() => {
        console.log(`RE render`);
    }, point)

    const handleChange = (e) => {
        const { name, value, checked } = e.target

        if (e.target.type === 'checkbox') {
            Object.assign(obj, { [name]: checked })
            setObj(obj)
            returnObject(obj)
        } else {
            Object.assign(obj, { [name]: value })
            setObj(obj)
            returnObject(obj)
        }
        setPoint(e)
    }

    return (
        <>
            {arr.map((itm, index) => (
                <React.Fragment key={itm.key}>
                    <Form.Group >
                        <Row>
                            <Col xs={5} style={{ textAlign: 'left', display: 'flex' }}>
                                <span style={{marginRight: '2px'}}>{`${index + 1}.  `}</span><Form.Label>
                                    {itm.display}
                                </Form.Label>
                            </Col>
                            <Col xs={7}>
                                {itm.as === 'select' ?
                                    <Form.Control
                                        width={10}
                                        name={itm.key}
                                        as='select'
                                        placeholder={`Введите ${itm.display.toLowerCase()}`}
                                        onChange={handleChange}
                                        value={obj[itm.key] && obj[itm.key]}
                                    >
                                        <option style={{ color: '#ccc' }} value='' disabled selected hidden>
                                            Выберите {itm.display.toLocaleLowerCase()}
                                        </option>
                                        {itm.arr.map((i, inx) => (
                                            <React.Fragment key={`${itm.display}${inx}`}>
                                                <option> {i} </option>
                                            </React.Fragment>
                                        ))}
                                    </Form.Control>
                                    :
                                    itm.as === 'checkbox' ?
                                        <Form.Check
                                            width={10}
                                            type="checkbox"
                                            name={itm.key}
                                            onChange={handleChange}
                                            checked={typeof (obj[itm.key]) === Boolean && obj[itm.key]}
                                        />
                                        :
                                        <Form.Control
                                            width={10}
                                            name={itm.key}
                                            type={itm.as === 'text' ? itm.as : ''}
                                            disabled={itm.as === 'disabled' ? true : false}
                                            placeholder={`Введите ${itm.display.toLowerCase()}`}
                                            onChange={handleChange}
                                            value={obj[itm.key] && obj[itm.key]}

                                        />
                                }
                            </Col>
                        </Row>
                    </Form.Group>
                </React.Fragment>

            ))
            }

        </>
    )
}

export default FormControlInputOrSelect;