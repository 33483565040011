/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Container, Form, Row, Button, Col } from 'react-bootstrap'
import InputForm from './InputForm'
import CheckForm from './CheckForm'
import SizeForm from './SizeForm'
import {
  STUD,
  NUT,
  BOLT,
  TRANSITION,
  TAPS,
  CARBON_FORGINGS,
  LOW_ALLOY_FORGINGS,
  CHROM_FORGINGS,
  MARTENSITIC_FORGINGS,
  FERRITIC_FORGINGS,
  AUSTENITIC_FORGINGS,
  AUST_FERR_FORGINGS,
  IRON_NICKEL_FORGINGS,
  TITANIUM_FORGINGS,
  NICKEL_FORGINGS,
  FLANGES
} from './ConstMaterial'
import ListCheckForm from './ListCheckForm'
import RadioButtonForm from './RadioButtonForm'
import FormMaterList from './FormMaterList'
import ModalQuit from '../ModalQuit'
import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices'
import { nanoid } from 'nanoid'
import CommonAlert from './CommonAlert'
import AllModalsFooter from '../assets/AllModalsFooter'
import { SaveButtonGreen, SaveCurrentDataButton } from '../assets/BasicButtons'
import { createData } from './functionsForm'
import { isEqual } from 'lodash-es'

export default class Form_Eight extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'rus',
      dataValue: [],
      // id: 2,
      maxId: 198,
      nameForm: 'Eight',
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      base: [],
      show: false,
      show6: false,
      show10: false,
      show14: false,
      show18: false,
      show43: false,
      show47: false,
      show48: false,
      show53: false,
      show70: false,
      show176: false,
      show180: false,
      show189: false,
      showStud: false,
      showNut: false,
      showBolt: false,
      showTransition: false,
      showTaps: false,
      showCarbonForgings: false,
      showLowAlloyForgings: false,
      showChromForgings: false,
      showMartensiticForgings: false,
      showFerriticForgings: false,
      showAusteniticForgings: false,
      showAustFerrFrogings: false,
      showIronNickelForgings: false,
      showTitaniumForgings: false,
      showFlanges: false,
      showDown: false,
      showNickel: false,
      showQuit: false,
      showContent: true,
      previousState: [],
      savedDataMessage: false,
      dataBeenSaved: false
    }

    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this)
    this.renderChoice = this.renderChoice.bind(this)
    this.doEmpty = this.doEmpty.bind(this)

    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.hideContent = this.hideContent.bind(this)
    this.setCompanyName = this.setCompanyName.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
  }

  saveDataWithNotification(){
    this.handleClickSave()

    this.setState({
      savedDataMessage: true,
      dataBeenSaved: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }

  hideContent() {
    this.setState({ showContent: false })
  }

  setCompanyName() {
    if (this.props.companyName && this.props.companyName.length > 0) {
      this.setState({
        companyName: this.props.companyName
      })
    }
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })
  }

  //*Пустышка
  doEmpty() {}

  renderChoice(data) {
    data.forEach((elem) => {
      this.doChangeVisionBlock(elem.value, elem.id)
    })
  }

  //* меняем видимость блока при выборе ДА / НЕТ (false / true)
  doChangeVisionBlock(data, elem) {
    //* IF - защита от данных разного типа
    if (isFinite(+elem)) {
      if (+elem === 6) this.setState({ show6: data })
      if (+elem === 10) this.setState({ show10: data })
      if (+elem === 14) this.setState({ show14: data })
      if (+elem === 19) this.setState({ showStud: data })
      if (+elem === 32) this.setState({ showNut: data })
      if (+elem === 37) this.setState({ showBolt: data })
      if (+elem === 48) this.setState({ show48: data })
      if (+elem === 53) this.setState({ show53: data })
      if (+elem === 60) this.setState({ showTaps: data })
      if (+elem === 66) this.setState({ showTransition: data })
      if (+elem === 71) this.setState({ showCarbonForgings: data })
      if (+elem === 81) this.setState({ showLowAlloyForgings: data })
      if (+elem === 90) this.setState({ showChromForgings: data })
      if (+elem === 101) this.setState({ showMartensiticForgings: data })
      if (+elem === 109) this.setState({ showFerriticForgings: data })
      if (+elem === 114) this.setState({ showAusteniticForgings: data })
      if (+elem === 125) this.setState({ showAustFerrFrogings: data })
      if (+elem === 131) this.setState({ showIronNickelForgings: data })
      if (+elem === 136) this.setState({ showTitaniumForgings: data })
      if (+elem === 176) this.setState({ show176: data })
      if (+elem === 180) this.setState({ show180: data })
      if (+elem === 192) this.setState({ showNickel: data })

      this.id = +elem
      if (data === 'Да' && this.id === 2) this.setState({ show: true })
      if ((data === 'Нет' || data === '') && this.id === 2) this.setState({ show: false })

      if (data === 'Да' && this.id === 18) this.setState({ show18: true })
      if ((data === 'Нет' || data === '') && this.id === 18) this.setState({ show18: false, showStud: false, showNut: false, showBolt: false })

      if (data === 'Да' && this.id === 43) this.setState({ show43: true })
      if ((data === 'Нет' || data === '') && this.id === 43) this.setState({ show43: false })

      if (data === 'Да' && this.id === 47) this.setState({ show47: true })
      if ((data === 'Нет' || data === '') && this.id === 47) this.setState({ show47: false })

      if (data === 'Да' && this.id === 70) this.setState({ show70: true })
      if ((data === 'Нет' || data === '') && this.id === 70) this.setState({ show70: false })

      if (data === 'Да' && this.id === 143) this.setState({ showFlanges: true })
      if ((data === 'Нет' || data === '') && this.id === 143) this.setState({ showFlanges: false })

      if (data === 'Да' && this.id === 175) this.setState({ showDown: true })
      if ((data === 'Нет' || data === '') && this.id === 175) this.setState({ showDown: false })

      if (data === 'Да' && this.id === 189) this.setState({ show189: true })
      if ((data === 'Нет' || data === '') && this.id === 189) this.setState({ show189: false })
    }
  }

  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  doQuitWithSave() {
    this.setState({ showQuit: false })
    this.handleClickSave()
    this.props.onChangeView('Eight')
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView('Eight')
  }

  //*формируем данные в массив объектов для отправки на сервер
  handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach((dataD) => {
        if (+dataD.id === i) {
          data[i] = {
            id: dataD.id,
            fid: dataD.fid,
            description: dataD.description,
            information: dataD.information,
            value: dataD.value
          }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach((dataE) => {
        if (+dataE.id === i) {
          data[i] = {
            id: dataE.id,
            fid: dataE.fid,
            description: dataE.description,
            information: dataE.information,
            value: dataE.value
          }
        }
      })
    }
    this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере
    if (this.state.lengthDataFromServer < 1) {
      console.log('CREATE DATA')
      this.props.onCreateDataServer(data, 'Eight', localStorage.getItem('idUser'))
    } else {
      console.log('UPDATE DATA')
      this.props.onUpdateDataOnServer(data, 'Eight', localStorage.getItem('idUser'))
    }
    setTimeout(() => {
      console.log(this.state.dataOnServer)
    }) //test
    // this.handleClickShadow();
  }

  //* данные с полей формы, формируются в массив объектов
  doChangeValue(data) {
    // console.log(data);//test
    this.setState({ dataValue: [...this.state.dataValue, data] })
    // setTimeout(() => { console.log(this.state.dataValue) })//test
    this.setState({
      dataBeenSaved: false
    })
  }

  compare() {
    const prev = this.state.previousState
    const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)

    const isStateEqual = isEqual(prev, curr)
    if (isStateEqual) {
      return true
    }
    return false
  }

  smartWindowFunction() {
    const isStateEqual = this.compare()
    const { dataBeenSaved, dataValue } = this.state
    if (isStateEqual || dataBeenSaved || dataValue.length === 0) {
      this.props.onChangeView('Eight')
    } else {
      this.handleClickShadow()
    }
  }

  componentDidMount() {
    const { lengthDataFromServer, data,  } = this.props
    setTimeout(() => {
      this.setState({ dataFromServer: data, lengthDataFromServer })
      this.renderChoice(data)
    }, 1500)

    this.setCompanyName()

    if (data.length) {
      const previousState = JSON.parse(JSON.stringify(data))
      this.setState({ previousState })
    }
  }

  render() {
    // console.log('RENDER', this.state.dataValue);//test

    this.view = this.props.view
    this.value = this.props.data
    const viewQuest = this.props.viewQuest
    const placeholder = ['Мин диаметр, мм', 'Макс диаметр, мм', 'Макс толщ, мм']
    const linz = ['Макс диаметр, мм', 'Макс толщ, мм']
    const rolling = ['Макс толщ листа (углер.) при ширине 1.5 м, мм', 'Макс толщ листа (нерж.) при ширине 1.5 м, мм', 'Мин диаметр обечайки, мм', 'Макс диаметр обечайки, мм']
    const rollingMax = ['Макс толщ, мм', 'Длина валка, мм']
    const carbonForgings = ['Макс вес, кг ', 'Макс диаметр, мм', 'Макс толщ, мм']
    const flanges = ['Dy, мм', 'Py, МПа']
    const pokovka = ['Макс длина, мм', 'Макс ширина, мм', 'Макс высота, мм', 'Макс масса, тн']
    const flanegesZ = ['Макс Dy, мм', 'Макс Py, МПа']

    //условие по которому компонент определяет находится ли он в формате чтения
    const inReadMode = this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined

    return (
      <>
        {inReadMode && <>{this.state.showQuit ? <ModalQuit onQuitWithSave={this.doQuitWithSave} onQuitWithoutSave={this.doQuitWithoutSave} closeQuitModal={this.closeQuitModal} /> : ''}</>}

        {this.state.showContent && (
          <div className='modal_window' style={{ display: this.view ? 'block' : 'none' }}>
            <div
              className={viewQuest ? 'shadow_form' : 'shadow_form_off'}
              // onClick={this.handleClickShadow}
              onClick={this.smartWindowFunction}
            ></div>
            <Form className={viewQuest ? 'form_main' : 'form_main_view'}>
              <CommonAlert
                inReadMode={inReadMode}
                handleClickHeader={this.handleClickHeader}
                // handleClickShadow={this.handleClickShadow}
                handleClickShadow={this.smartWindowFunction}
                hideContent={this.hideContent}
                icon={<MiscellaneousServicesIcon fontSize='large' className='icon_primary' />}
                companyName={this.state.companyName}
                sectionName='Комплектующие'
              />

              <div>
                {' '}
                <Container className='form_container'>
                  <Form.Group>
                    <Form.Label className='f_size_1em'>Гнутые трубы и змеевики:</Form.Label>

                    <Row>
                      <RadioButtonForm
                        id={1}
                        width={12}
                        show={!inReadMode && !this.value[1] ? false : true}
                        title='Возможность гибки теплообменных труб: '
                        label1='Да'
                        label2='Нет'
                        placeholder='возможность гибки теплообменных труб'
                        description='Комплектующие'
                        value={this.value[1] ? this.value[1].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doEmpty}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Row> &nbsp; </Row>
                    <Row>
                      <RadioButtonForm
                        id={2}
                        width={12}
                        show={!inReadMode && !this.value[2] ? false : true}
                        title='Возможность гибки листа: '
                        label1='Да'
                        label2='Нет'
                        placeholder='возможность гибки листа'
                        description='Комплектующие'
                        value={this.value[2] ? this.value[2].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Row>
                      <InputForm
                        id={3}
                        width={4}
                        show={!inReadMode && this.state.show && !this.value[3].value ? false : true}
                        verify='number'
                        label='Длина, мм'
                        fontSize={'12px'}
                        placeholder='Длина, мм'
                        description='Гибка листа'
                        value={this.value[3] ? this.value[3].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={4}
                        width={4}
                        show={!inReadMode && this.state.show && !this.value[4].value ? false : true}
                        verify='number'
                        label='Ширина, мм'
                        fontSize={'12px'}
                        placeholder='Ширина, мм'
                        description='Гибка листа'
                        value={this.value[4] ? this.value[4].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={5}
                        width={4}
                        show={!inReadMode && this.state.show && !this.value[5].value ? false : true}
                        verify='number'
                        label='Толщина, мм'
                        fontSize={'12px'}
                        placeholder='Толщина, мм'
                        description='Гибка листа'
                        value={this.value[5] ? this.value[5].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                  </Form.Group>
                  <Row>
                    {' '}
                    <div className='border_top_topline_100 mb_10 mt_10'></div>{' '}
                  </Row>
                  <Row> &nbsp; </Row>
                  <Form.Group>
                    <Form.Label className='f_size_1em'>Днища:</Form.Label>

                    <Row>
                      <RadioButtonForm
                        id={175}
                        width={12}
                        show={!inReadMode && !this.value[175] ? false : true}
                        title='Возможность изготовления днищ: '
                        label1='Да'
                        label2='Нет'
                        placeholder='возможность гибки листа'
                        description='Комплектующие'
                        value={this.value[175] ? this.value[175].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>

                    {this.state.showDown && (
                      <>
                        <Row>
                          <CheckForm
                            name={nanoid()}
                            id={6}
                            width={6}
                            show={!inReadMode && !this.value[6] ? false : true}
                            placeholder='Эллиптические' //* подпись LABEL для чекбокса
                            description='Днища'
                            value={this.value[6] ? this.value[6].value : ''}
                            onChangeValue={this.doChangeValue}
                            onChangeVisionBlock={this.doChangeVisionBlock}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                        </Row>
                        <SizeForm
                          id={7}
                          width={3}
                          fontSize={'12px'}
                          show={this.state.show6}
                          value={this.value}
                          description='Днища, эллиптические'
                          placeholder={placeholder}
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </>
                    )}

                    {this.state.showDown && (
                      <>
                        <Row>
                          <CheckForm
                            name={nanoid()}
                            id={10}
                            width={6}
                            show={!inReadMode && !this.value[10] ? false : true}
                            placeholder='Торосферические'
                            description='Днища'
                            value={this.value[10] ? this.value[10].value : ''}
                            onChangeValue={this.doChangeValue}
                            onChangeVisionBlock={this.doChangeVisionBlock}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                        </Row>

                        <SizeForm
                          id={11}
                          width={3}
                          fontSize={'12px'}
                          show={this.state.show10}
                          value={this.value}
                          description='Днища, торосферические'
                          placeholder={placeholder}
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </>
                    )}

                    {this.state.showDown && (
                      <>
                        <Row>
                          <CheckForm
                            name={nanoid()}
                            id={14}
                            width={6}
                            show={!inReadMode && !this.value[14] ? false : true}
                            placeholder='Сферические'
                            description='Днища'
                            value={this.value[14] ? this.value[14].value : ''}
                            onChangeValue={this.doChangeValue}
                            onChangeVisionBlock={this.doChangeVisionBlock}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                        </Row>

                        <SizeForm
                          id={15}
                          width={3}
                          fontSize={'12px'}
                          show={this.state.show14}
                          value={this.value}
                          description='Днища, сферические'
                          placeholder={placeholder}
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </>
                    )}

                    {this.state.showDown && (
                      <>
                        <Row>
                          <CheckForm
                            name={nanoid()}
                            id={176}
                            width={6}
                            show={!inReadMode && !this.value[176] ? false : true}
                            placeholder='Конические отбортованные'
                            description='Днища'
                            value={this.value[176] ? this.value[176].value : ''}
                            onChangeValue={this.doChangeValue}
                            onChangeVisionBlock={this.doChangeVisionBlock}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                        </Row>

                        <SizeForm
                          id={177}
                          width={3}
                          fontSize={'12px'}
                          show={this.state.show176}
                          value={this.value}
                          description='Конические отбортованные'
                          placeholder={placeholder}
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </>
                    )}

                    {this.state.showDown && (
                      <>
                        <Row>
                          <CheckForm
                            name={nanoid()}
                            id={180}
                            width={6}
                            show={!inReadMode && !this.value[180] ? false : true}
                            placeholder='Плоские отбортованные'
                            description='Днища'
                            value={this.value[180] ? this.value[180].value : ''}
                            onChangeValue={this.doChangeValue}
                            onChangeVisionBlock={this.doChangeVisionBlock}
                            nameForm={this.state.nameForm}
                            readOnly={!inReadMode}
                          />
                        </Row>

                        <SizeForm
                          id={181}
                          width={3}
                          fontSize={'12px'}
                          show={this.state.show180}
                          value={this.value}
                          description='Плоские отбортованные'
                          placeholder={placeholder}
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </>
                    )}
                  </Form.Group>
                  <Row>
                    {' '}
                    <div className='border_top_topline_100 mb_10 mt_10'></div>{' '}
                  </Row>
                  <Row> &nbsp; </Row>
                  <Form.Group>
                    <Form.Label className='f_size_1em'>Крепеж:</Form.Label>

                    <Row>
                      <RadioButtonForm
                        id={18}
                        width={12}
                        show={!inReadMode && !this.value[18] ? false : true}
                        title='Возможность изготовления крепежных изделий?'
                        label1='Да'
                        label2='Нет'
                        placeholder='Крепежные изделия'
                        description='Комплектующие'
                        value={this.value[18] ? this.value[18].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>

                    <CheckForm
                      name={nanoid()}
                      id={19}
                      width={6}
                      show={this.state.show18}
                      placeholder='Шпильки'
                      description='Крепежные изделия'
                      value={this.value[19] ? this.value[19].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                      readOnly={!inReadMode}
                    />

                    <Row>
                      {this.state.showStud ? (
                        <FormMaterList
                          id={20}
                          width={4}
                          rowView={false}
                          materials={STUD}
                          value={this.value[20] ? this.value[20].value : ''}
                          description='Шпильки'
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      ) : (
                        ''
                      )}
                    </Row>

                    <CheckForm
                      name={nanoid()}
                      id={32}
                      width={6}
                      show={this.state.show18}
                      placeholder='Гайки'
                      description='Крепежные изделия'
                      value={this.value[32] ? this.value[32].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                      readOnly={!inReadMode}
                    />

                    <Row>
                      {this.state.showNut ? (
                        <FormMaterList
                          name={nanoid()}
                          id={33}
                          width={4}
                          rowView={false}
                          materials={NUT}
                          value={this.value[33] ? this.value[33].value : ''}
                          description='Гайки'
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      ) : (
                        ''
                      )}
                    </Row>

                    <CheckForm
                      name={nanoid()}
                      id={37}
                      width={6}
                      show={this.state.show18}
                      placeholder='Болты'
                      description='Крепежные изделия'
                      value={this.value[37] ? this.value[37].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                      readOnly={!inReadMode}
                    />

                    <Row>
                      {this.state.showBolt ? (
                        <FormMaterList
                          name={nanoid()}
                          id={38}
                          width={4}
                          rowView={false}
                          materials={BOLT}
                          value={this.value[38] ? this.value[38].value : ''}
                          description='Болты'
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      ) : (
                        ''
                      )}
                    </Row>

                    <Row> &nbsp; </Row>
                  </Form.Group>

                  <Row>
                    {' '}
                    <div className='border_top_topline_100 mb_10 mt_10 pt_10'></div>{' '}
                  </Row>
                  <Row> &nbsp; </Row>
                  <Form.Group>
                    <Form.Label className='f_size_1em'>Линзовые компенсаторы:</Form.Label>

                    <Row>
                      <RadioButtonForm
                        id={43}
                        width={12}
                        show={true}
                        title='Изготавливает ли Ваше предприятие линзовые компенсаторы?'
                        label1='Да'
                        label2='Нет'
                        placeholder='Линзовые компенсаторы'
                        description='Комплектующие'
                        value={this.value[43] ? this.value[43].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <SizeForm
                      id={44}
                      width={3}
                      fontSize={'12px'}
                      show={this.state.show43}
                      value={this.value}
                      description='Днища, эллиптические'
                      placeholder={linz}
                      onChangeValue={this.doChangeValue}
                      nameForm={this.state.nameForm}
                      readOnly={!inReadMode}
                    />
                  </Form.Group>
                  <Row>
                    {' '}
                    <div className='border_top_topline_100 mb_10 mt_10 pt_10'></div>{' '}
                  </Row>
                  <Row> &nbsp; </Row>
                  <Form.Group>
                    <Form.Label className='f_size_1em'>Обечайки:</Form.Label>

                    <Row>
                      <RadioButtonForm
                        id={47}
                        width={12}
                        show={true}
                        title='Возможность изготовления конических обечаек: '
                        label1='Да'
                        label2='Нет'
                        placeholder='Обечайки'
                        description='Комплектующие'
                        value={this.value[47] ? this.value[47].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                  </Form.Group>
                  <Form.Group style={{ display: this.state.show47 ? 'block' : 'none' }}>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={48}
                        width={6}
                        show={true}
                        placeholder='Вальцовка (на холодно)'
                        description='Обечайки, конусы'
                        value={this.value[48] ? this.value[48].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <SizeForm
                      id={49}
                      width={3}
                      fontSize={'10px'}
                      show={this.state.show48}
                      value={this.value}
                      description='Вальцовка (на холодно)'
                      placeholder={rolling}
                      onChangeValue={this.doChangeValue}
                      nameForm={this.state.nameForm}
                      readOnly={!inReadMode}
                    />
                  </Form.Group>
                  <Form.Group style={{ display: this.state.show47 ? 'block' : 'none' }}>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={53}
                        width={6}
                        show={true}
                        placeholder='Вальцовка (на горячо)'
                        description='Обечайки, конусы'
                        value={this.value[53] ? this.value[53].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <SizeForm
                      id={54}
                      width={3}
                      fontSize={'10px'}
                      show={this.state.show53}
                      value={this.value}
                      description='Вальцовка (на горячо)'
                      placeholder={rolling}
                      onChangeValue={this.doChangeValue}
                      nameForm={this.state.nameForm}
                      readOnly={!inReadMode}
                    />
                  </Form.Group>
                  {/* <Row>&nbsp;</Row> */}
                  <Form.Group style={{ display: this.state.show47 ? 'block' : 'none' }}>
                    <Row>
                      <Col>Максимальная толщина вальцовки листа по всей длине валка</Col>
                    </Row>

                    <SizeForm id={58} width={3} fontSize={'12px'} show={true} value={this.value} description='Вальцовка' placeholder={rollingMax} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!inReadMode} />
                  </Form.Group>

                  <Row>
                    {' '}
                    <div className='border_top_topline_100 mb_10 mt_10 pt_10'></div>{' '}
                  </Row>
                  <Row> &nbsp; </Row>
                  <Form.Group>
                    <Form.Label className='f_size_1em'>Отводы и переходы:</Form.Label>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={60}
                        width={6}
                        show={true}
                        placeholder='Возможность изготовления отводов: '
                        description='Отводы и переходы'
                        value={this.value[60] ? this.value[60].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Row>
                      {this.state.showTaps ? (
                        <FormMaterList
                          name={nanoid()}
                          id={61}
                          width={4}
                          rowView={false}
                          materials={TAPS}
                          value={this.value[61] ? this.value[61].value : ''}
                          description='Отводы и переходы'
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      ) : (
                        ''
                      )}
                    </Row>
                  </Form.Group>
                  <Row> &nbsp; </Row>
                  <Form.Group>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={66}
                        width={6}
                        show={true}
                        placeholder='Возможность изготовления переходов?'
                        description='Отводы и переходы'
                        value={this.value[66] ? this.value[66].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Row>
                      {this.state.showTransition ? (
                        <FormMaterList
                          name={nanoid()}
                          id={67}
                          width={4}
                          rowView={false}
                          materials={TRANSITION}
                          value={this.value[67] ? this.value[67].value : ''}
                          description='Отводы и переходы'
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      ) : (
                        ''
                      )}
                    </Row>
                  </Form.Group>

                  <Row>
                    {' '}
                    <div className='border_top_topline_100 mb_10 mt_10 pt_10'></div>{' '}
                  </Row>
                  <Row> &nbsp; </Row>
                  <Form.Group>
                    <Form.Label className='f_size_1em'>Поковки:</Form.Label>

                    <Row>
                      <RadioButtonForm
                        id={70}
                        width={12}
                        show={true}
                        title='Возможность изготовления поковок: '
                        label1='Да'
                        label2='Нет'
                        placeholder='Поковки'
                        description='Комплектующие'
                        value={this.value[70] ? this.value[70].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <SizeForm id={185} width={3} fontSize={'12px'} show={this.state.show70} value={this.value} description='Поковки' placeholder={pokovka} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!inReadMode} />
                  </Form.Group>
                  {/* <Row> &nbsp; </Row> */}
                  <Form.Group style={{ display: this.state.show70 ? 'block' : 'none' }}>
                    <Row>
                      <Col>Класс стали (сплавов) и марок:</Col>
                    </Row>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={71}
                        width={6}
                        show={true}
                        placeholder='Углеродистый'
                        description='Материалы'
                        value={this.value[71] ? this.value[71].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>

                    {this.state.showCarbonForgings ? (
                      <SizeForm id={72} width={3} fontSize={'12px'} show={true} value={this.value} description='Поковки' placeholder={carbonForgings} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!inReadMode} />
                    ) : (
                      ''
                    )}

                    <Row>
                      {this.state.showCarbonForgings ? (
                        <FormMaterList
                          name={nanoid()}
                          id={75}
                          width={4}
                          rowView={true}
                          materials={CARBON_FORGINGS}
                          value={this.value[75] ? this.value[75].value : ''}
                          description='Материалы'
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      ) : (
                        ''
                      )}
                    </Row>
                  </Form.Group>
                  <Form.Group style={{ display: this.state.show70 ? 'block' : 'none' }}>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={81}
                        width={6}
                        show={true}
                        placeholder='Низколегированный марганцовистый, марганцево-кремнистый'
                        description='Материалы'
                        value={this.value[81] ? this.value[81].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>

                    {this.state.showLowAlloyForgings ? (
                      <SizeForm id={82} width={3} fontSize={'12px'} show={true} value={this.value} description='Поковки' placeholder={carbonForgings} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!inReadMode} />
                    ) : (
                      ''
                    )}

                    <Row>
                      {this.state.showLowAlloyForgings ? (
                        <FormMaterList
                          name={nanoid()}
                          id={85}
                          width={4}
                          rowView={true}
                          materials={LOW_ALLOY_FORGINGS}
                          value={this.value[85] ? this.value[85].value : ''}
                          description='Материалы'
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      ) : (
                        ''
                      )}
                    </Row>
                  </Form.Group>
                  <Form.Group style={{ display: this.state.show70 ? 'block' : 'none' }}>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={90}
                        width={6}
                        show={true}
                        placeholder='Хромомолибденовый, хромомолибденованадиевый'
                        description='Материалы'
                        value={this.value[90] ? this.value[90].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>

                    {this.state.showChromForgings ? (
                      <SizeForm id={91} width={3} fontSize={'12px'} show={true} value={this.value} description='Поковки' placeholder={carbonForgings} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!inReadMode} />
                    ) : (
                      ''
                    )}

                    <Row>
                      {this.state.showChromForgings ? (
                        <FormMaterList
                          name={nanoid()}
                          id={94}
                          width={4}
                          rowView={true}
                          materials={CHROM_FORGINGS}
                          value={this.value[94] ? this.value[94].value : ''}
                          description='Материалы'
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      ) : (
                        ''
                      )}
                    </Row>
                  </Form.Group>
                  <Form.Group style={{ display: this.state.show70 ? 'block' : 'none' }}>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={101}
                        width={6}
                        show={true}
                        placeholder='Мартенситный '
                        description='Материалы'
                        value={this.value[101] ? this.value[101].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>

                    {this.state.showMartensiticForgings ? (
                      <SizeForm id={102} width={3} fontSize={'12px'} show={true} value={this.value} description='Поковки' placeholder={carbonForgings} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!inReadMode} />
                    ) : (
                      ''
                    )}

                    <Row>
                      {this.state.showMartensiticForgings ? (
                        <FormMaterList
                          name={nanoid()}
                          id={105}
                          width={4}
                          rowView={true}
                          materials={MARTENSITIC_FORGINGS}
                          value={this.value[105] ? this.value[105].value : ''}
                          description='Материалы'
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      ) : (
                        ''
                      )}
                    </Row>
                  </Form.Group>
                  <Form.Group style={{ display: this.state.show70 ? 'block' : 'none' }}>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={109}
                        width={6}
                        show={true}
                        placeholder='Ферритный  '
                        description='Материалы'
                        value={this.value[109] ? this.value[109].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>

                    {this.state.showFerriticForgings ? (
                      <SizeForm id={110} width={3} fontSize={'12px'} show={true} value={this.value} description='Поковки' placeholder={carbonForgings} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!inReadMode} />
                    ) : (
                      ''
                    )}

                    <Row>
                      {this.state.showFerriticForgings ? (
                        <FormMaterList
                          name={nanoid()}
                          id={113}
                          width={4}
                          rowView={true}
                          materials={FERRITIC_FORGINGS}
                          value={this.value[113] ? this.value[113].value : ''}
                          description='Материалы'
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      ) : (
                        ''
                      )}
                    </Row>
                  </Form.Group>
                  <Form.Group style={{ display: this.state.show70 ? 'block' : 'none' }}>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={114}
                        width={6}
                        show={true}
                        placeholder='Аустенитный  '
                        description='Материалы'
                        value={this.value[114] ? this.value[114].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>

                    {this.state.showAusteniticForgings ? (
                      <SizeForm id={115} width={3} fontSize={'12px'} show={true} value={this.value} description='Поковки' placeholder={carbonForgings} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!inReadMode} />
                    ) : (
                      ''
                    )}

                    <Row>
                      {this.state.showAusteniticForgings ? (
                        <FormMaterList
                          name={nanoid()}
                          id={118}
                          width={4}
                          rowView={true}
                          materials={AUSTENITIC_FORGINGS}
                          value={this.value[118] ? this.value[118].value : ''}
                          description='Материалы'
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      ) : (
                        ''
                      )}
                    </Row>
                  </Form.Group>
                  <Form.Group style={{ display: this.state.show70 ? 'block' : 'none' }}>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={125}
                        width={6}
                        show={true}
                        placeholder='Аустенитно-ферритный'
                        description='Материалы'
                        value={this.value[125] ? this.value[125].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>

                    {this.state.showAustFerrFrogings ? (
                      <SizeForm id={126} width={3} fontSize={'12px'} show={true} value={this.value} description='Поковки' placeholder={carbonForgings} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!inReadMode} />
                    ) : (
                      ''
                    )}

                    <Row>
                      {this.state.showAustFerrFrogings ? (
                        <FormMaterList
                          name={nanoid()}
                          id={129}
                          width={4}
                          rowView={true}
                          materials={AUST_FERR_FORGINGS}
                          value={this.value[129] ? this.value[129].value : ''}
                          description='Материалы'
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      ) : (
                        ''
                      )}
                    </Row>
                  </Form.Group>

                  <Form.Group style={{ display: this.state.show70 ? 'block' : 'none' }}>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={131}
                        width={6}
                        show={true}
                        placeholder='Сплавы на железоникелевой основе'
                        description='Материалы'
                        value={this.value[131] ? this.value[131].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>

                    {this.state.showIronNickelForgings ? (
                      <SizeForm id={132} width={3} fontSize={'12px'} show={true} value={this.value} description='Поковки' placeholder={carbonForgings} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!inReadMode} />
                    ) : (
                      ''
                    )}

                    <Row>
                      {this.state.showIronNickelForgings ? (
                        <FormMaterList
                          name={nanoid()}
                          id={135}
                          width={4}
                          rowView={true}
                          materials={IRON_NICKEL_FORGINGS}
                          value={this.value[135] ? this.value[135].value : ''}
                          description='Материалы'
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      ) : (
                        ''
                      )}
                    </Row>
                  </Form.Group>
                  <Form.Group style={{ display: this.state.show70 ? 'block' : 'none' }}>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={136}
                        width={6}
                        show={true}
                        placeholder='Титановые сплавы'
                        description='Материалы'
                        value={this.value[136] ? this.value[136].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>

                    {this.state.showTitaniumForgings ? (
                      <SizeForm id={137} width={3} fontSize={'12px'} show={true} value={this.value} description='Поковки' placeholder={carbonForgings} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!inReadMode} />
                    ) : (
                      ''
                    )}

                    <Row>
                      {this.state.showTitaniumForgings ? (
                        <FormMaterList
                          name={nanoid()}
                          id={140}
                          width={4}
                          rowView={true}
                          materials={TITANIUM_FORGINGS}
                          value={this.value[140] ? this.value[140].value : ''}
                          description='Материалы'
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      ) : (
                        ''
                      )}
                    </Row>
                  </Form.Group>

                  <Form.Group style={{ display: this.state.show70 ? 'block' : 'none' }}>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={192}
                        width={6}
                        show={true}
                        placeholder='Никелевые сплавы'
                        description='Материалы'
                        value={this.value[192] ? this.value[192].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>

                    {this.state.showNickel ? (
                      <SizeForm id={193} width={3} fontSize={'12px'} show={true} value={this.value} description='Поковки' placeholder={carbonForgings} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!inReadMode} />
                    ) : (
                      ''
                    )}

                    <Row>
                      {this.state.showNickel ? (
                        <FormMaterList
                          name={nanoid()}
                          id={196}
                          width={4}
                          rowView={true}
                          materials={NICKEL_FORGINGS}
                          value={this.value[196] ? this.value[196].value : ''}
                          description='Материалы'
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      ) : (
                        ''
                      )}
                    </Row>
                  </Form.Group>

                  <Row>
                    {' '}
                    <div className='border_top_topline_100 mb_10 mt_10 pt_10'></div>{' '}
                  </Row>
                  <Row> &nbsp; </Row>

                  <Form.Group>
                    <Form.Label className='f_size_1em'>Фланцы:</Form.Label>
                    <Row>
                      <RadioButtonForm
                        id={143}
                        width={12}
                        show={true}
                        title='Возможность изготовления фланцев: '
                        label1='Да'
                        label2='Нет'
                        placeholder='Фланцы'
                        description='Комплектующие'
                        value={this.value[143] ? this.value[143].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Row> &nbsp; </Row>
                    {/** ********************* ФЛАНЦЫ  ********************** */}
                    {this.state.showFlanges ? (
                      <ListCheckForm
                        name={nanoid()}
                        id={144}
                        width={2}
                        widthSize={5}
                        fontSize={'12px'}
                        showSizeForm={true}
                        materials={FLANGES} //кол-во позиций
                        value={this.value}
                        placeholder={flanges} //размеры (до 4-х)
                        palcehoderCheck={['Готовые фланцы', 'Заготовки фланцев']}
                        description='Фланцы'
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        onCheck={false}
                        readOnly={!inReadMode}
                      />
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Row>
                      <RadioButtonForm
                        id={189}
                        width={12}
                        show={true}
                        title='Возможность изготовления заготовок для фланцев: '
                        label1='Да'
                        label2='Нет'
                        placeholder='Фланцы'
                        description='Комплектующие'
                        value={this.value[189] ? this.value[189].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <SizeForm
                      id={190}
                      width={3}
                      fontSize={'10px'}
                      show={this.state.show189}
                      value={this.value}
                      description='Вальцовка (на холодно)'
                      placeholder={flanegesZ}
                      onChangeValue={this.doChangeValue}
                      nameForm={this.state.nameForm}
                      readOnly={!inReadMode}
                    />
                  </Form.Group>
                </Container>
                <AllModalsFooter
                  footerContent={
                    inReadMode && 
                    // <SaveButtonGreen handleSaveClick={this.handleClickSave} needDisable={false} saveButtonText='Сохранить' />
                    // <Button variant="success" onClick={this.handleClickSave}> Сохранить</Button>
                    <SaveCurrentDataButton
                    handleSaveClick={this.saveDataWithNotification}
                    needDisable={false}
                    savedDataMessage={this.state.savedDataMessage}
                    buttonTextDefault={'Сохранить'}
                    buttonTextSaved={'Данные сохранены'}
                    needCustomIcon={false}
                    />
                  }
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}
