import React from 'react'
import { Form } from 'react-bootstrap'
import { occupationTypes, FORM_, all } from '../questionnaire/ConstListCompany'
import Server from '../server/server'
import Zero from './Forms/Zero' //17
// import One from './Forms/One'//0
import Two from './Forms/Two' //1
// import Three from './Forms/Three'//2
// import Four from './Forms/Four'//3
import Five from './Forms/Five' //4
// import Six from './Forms/Six'//5
// import Seven from './Forms/Seven'//6
// import Eight from './Forms/Eight'//7
// import Nine from './Forms/Nine'//8
// import Eleven from './Forms/Eleven'//10
// import Twelve from './Forms/Twelve'//11
// import Thirteen from './Forms/Thirteen'//12
// import Fourteen from './Forms/Fourteen'//13
import FormRolled from './Forms/FormRolled' //30
import FormBendPipe from './Forms/FormBendPipe' //18
import FormBottoms from './Forms/FormBottoms' //19
import FormLensCompensators from './Forms/FormLensCompensators' //20
import FormFlanges from './Forms/FormFlanges' //21
import FormForgings from './Forms/FormForgings' //22
import FormBends from './Forms/FormBends' //23
import FormRolling from './Forms/FormRolling' //25
import FormProductColoration from './Forms/FormProductColoration' //26
import FormBendingCuttingMetal from './Forms/FormBendingCuttingMetal' //27
// import FormHeatTreatment from './Forms/FormHeatTreatment'; //28
import FormWelding from './Forms/FormWelding' //29
import FormPipesSupply from './Forms/FormPipesSupply' //31
import FormSheetMetalSupply from './Forms/FormSheetMetalSupply' //32
import UniversalTable from '../assets/UniversalTable'
// import Questionnaire from '../questionnaire/Questionnaire'
import { SearchInputGroup } from '../search/SearchInputGroup'
import { QuestionnairyModal } from '../QuestionnairyModal/QuestionnairyModal'
import server from '../server/server'
import getCapacity from './getCapacity'
import TransitionToLot from '../assets/TransitionToLot'
import getWeight from './getWeight'

export class ProductionSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nameForm: 'ProductionSearch',
      production: occupationTypes,
      product: '',
      idUser: localStorage.getItem('idUser'),
      // showEquipmentUnderPressure: false,
      equipment: [],
      equipmentItem: '',
      showEquipment: false,
      resultForRender: [],
      productForm: '',
      dataJson: [],
      chosenForm: [],
      allFormsArray: all.fill(false),
      allForms: [
        'One',
        'Two',
        'Three',
        'Four',
        'Five',
        'Six',
        'Seven',
        'Eight',
        'Nine',
        'Ten',
        'Eleven',
        'Twelve',
        'Thirteen',
        'Fourteen',
        'Fifteen',
        'Sixteen',
        'Seventeen',
        'Zero',
        'FormBendPipe',
        'FormBottoms',
        'FormLensCompensators',
        'FormFlanges',
        'FormForgings',
        'FormBends',
        'FormHardware',
        'FormRolling',
        'FormProductColoration',
        'FormBendingCuttingMetal',
        'FormHeatTreatment',
        'FormWelding',
        'FormRolled',
        'FormPipesSupply',
        'FormSheetMetalSupply'
      ], //длина - 33
      onCloseArray: [], //массив по длине all Forms
      singleResult: {},
      arrayForFirstSelectRender: [],
      showTable: false,
      searchResultData: [],
      companyDetailsModal: false,
      companyObject: [],
      companyQuestionnairieId: '',
      innFromUniversalTable: '',
      idAlien: '',
      companyName: '',
      // showButtonInQuestionnairy: true,
      chosenCompaniesArray: [],
      radioResult: '',
      economicData: [],
      chosenFilters: [],
      renderFilters: [],
      filteredTanks: [],
      filteredDimentions: [],
      savedFiltersData: [],
      formName: '', // входящее имя формы (приходит в соответствии с тем, в какой форме нажат Пуск)
      allCompaniesFromSearch: [],
      enterFromLot: false, //флажок для режима при входе в лот с лота
      enterFromSearch: false, //флажок для режима при входе в лот с поиска
      allSearchCompanies: [],
      companiesFromServer: [],
      updatedServerCompanies: [],
      rerenderedProduct: '',
      showFormGroup: true,
      testParent: 'default',
      obj: {}
    }

    this.onProductionChoice = this.onProductionChoice.bind(this)
    this.onProductionChoiceForm = this.onProductionChoiceForm.bind(this)
    this.setEquipment = this.setEquipment.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.fillArray = this.fillArray.bind(this)
    this.checkForFormPresence = this.checkForFormPresence.bind(this)
    this.getSearchResultData = this.getSearchResultData.bind(this)
    this.openResultTable = this.openResultTable.bind(this)
    this.empty = this.empty.bind(this)
    this.openQuestionnairy = this.openQuestionnairy.bind(this)
    this.onChangeLotType = this.onChangeLotType.bind(this)
    this.handleLaunchClick = this.handleLaunchClick.bind(this)
    this.getEconomicDataToState = this.getEconomicDataToState.bind(this)
    this.getChosenFiltersData = this.getChosenFiltersData.bind(this)
    this.saveChosenFilters = this.saveChosenFilters.bind(this)
    // this.getProduct = this.getProduct.bind(this)
    this.getChosenFilters = this.getChosenFilters.bind(this)
    this.getChosenCompanies = this.getChosenCompanies.bind(this)
    this.getAllSearchCompanies = this.getAllSearchCompanies.bind(this)
    this.checkCompaniesDataSource = this.checkCompaniesDataSource.bind(this)

    this.doSendIdInvite = this.doSendIdInvite.bind(this)
    this.updateAllSearchCompanies = this.updateAllSearchCompanies.bind(this)
    this.closeCompanyDetailsModal = this.closeCompanyDetailsModal.bind(this)
    this.setProduct = this.setProduct.bind(this)
  }

  doSendIdInvite(id) {
    this.props.onSendIdInvite(id)
  }

  saveChosenFilters() {
    ; (async () => {
      try {
        const addArray = this.state.chosenFilters
        addArray.push({ formName: this.state.formName })

        await Server.sendDataOnServer(addArray, 'filter', this.state.idUser)
      } catch (err) {
        console.log(`Ошибка загрухки данных о фильтре на сервер: `, err)
      }
    })()
  }

  async getChosenFilters(e) {
    this.onProductionChoice(e)
    const result = await Server.getDataFromServer('filter', this.state.idUser)
    const res = await Server.getDataFromServer('product', this.state.idUser)

    await this.setState({
      savedFiltersData: result
    })

    const namesOfForms = this.state.allForms

    if (res) {
      namesOfForms.forEach((el, idx) => {
        if (el === res.formName) {
          const newArr = this.state.allFormsArray

          newArr[idx] = !newArr[idx]
          this.setState({
            allFormsArray: newArr
          })
        }
      })
      //! check is needed открывет форму но не сохраняет настройки в ней + если у следующего лота та же форма, то настройки последней перейдут к ней (сохраняется только последний фильтр)
    } else {
      namesOfForms.forEach((el, idx) => {
        if (el === this.props.formName) {
          const newArr = this.state.allFormsArray
          newArr[idx] = !newArr[idx]
          this.setState({
            allFormsArray: newArr
          })
        }
      })
    }

    await this.setProduct()
  }

  async getChosenFiltersData(data) {
    this.setState({
      chosenFilters: data
    })
  }

  async getEconomicDataToState() {
    const data = this.state.searchResultData.map(el => el.data)

    const data1 = data.map(el => {
      return {
        nameCompany: `${el[100].value} ${el[1].value}`,
        ownership: el[100].value,
        country: el[14].value,
        region: el[15].value,
        inn: el[6].value,
        id: el[6].value,
        tel: el[4].value
      }
    })

    const dataInn = data1.map(el => el.id)

    const res = await Server.getDataFromServer('EconomicData', dataInn)

    this.setState({
      economicData: res
    })

    // console.log(res);
  }

  //при каждом клике на галочку эта ф-я отдает возвращает все компании с added
  updateAllSearchCompanies(array) {
    this.setState({
      allSearchCompanies: array
    })
  }

  async onChangeLotType(e) {
    await this.setState({
      radioResult: e.target.nextSibling.innerText
    })
  }

  async handleLaunchClick() {
    // console.log(this.props.test);
    //передаем родителю выбранные в этом компоненте днные о типе лота, только выбранных компний и виде деятельности
    this.props.sendDataChoice(this.state.radioResult, this.state.chosenCompaniesArray, this.state.product)

    const data1 = this.state.allSearchCompanies
    await Server.sendDataOnServer(data1, 'companiesArrayFromServer', this.state.idUser)

    this.props.toggleLotModal()
  }

  //получает ВСЕ компании
  async getAllSearchCompanies(array) {
    this.setState({
      allSearchCompanies: array
    })
    this.props.handleCompanyModalQuit()

    await Server.sendDataOnServer(array, 'companiesArrayFromServer', this.state.idUser)
  }

  //получает только выбранные компании
  async getChosenCompanies(array) {
    this.setState({
      chosenCompaniesArray: array
    })
    this.props.sendCompaniesArray && this.props.sendCompaniesArray(array)
  }

  async openQuestionnairy(inn) {
    this.setState({
      innFromUniversalTable: inn
      // showButtonInQuestionnairy: false
    })

    if (!this.state.searchResultData || this.state.searchResultData.length === 0) {
      await this.setState({
        searchResultData: this.props.serverCompanies
      })

      // change map to forEach
      await this.state.searchResultData.forEach(el => {
        if (el.id === inn) {
          this.setState({
            companyQuestionnairieId: el.id,
            companyName: el.nameCompany
          })
        }
      })

      this.setState({
        companyDetailsModal: true
      })
    } else if (
      this.state.searchResultData &&
      this.state.searchResultData.length !== 0 &&
      this.state.searchResultData.some(el => el._id === 'Main')
    ) {
      const data = await this.state.searchResultData.map(el => el.data)
      const data1 = await data.map(el => {
        return {
          nameCompany: el[1].value,
          country: el[14].value,
          region: el[15].value,
          inn: el[6].value,
          id: el[6].value,
          tel: el[4].value
        }
      })
      this.setState({
        companyDetailsModal: true
      })
      // change map to forEach
      await data1.forEach(el => {
        if (el.id === inn) {
          this.setState({
            companyQuestionnairieId: el.id,
            companyName: el.nameCompany
          })
        }
      })
    } else {
      // console.log(`searchResultData::: `, this.state.searchResultData); // test
      this.setState({
        companyDetailsModal: true
      })
      // change map to forEach
      await this.state.searchResultData.forEach(el => {
        if (el.id === inn) {
          this.setState({
            companyQuestionnairieId: el.id,
            companyName: el.nameCompany
          })
        }
      })
    }
    localStorage.setItem('idAlien', this.state.companyQuestionnairieId)
  }

  empty() { }

  async getSearchResultData(result) {
    this.setState({
      searchResultData: result
    })
    await this.checkCompaniesDataSource()

    const capacityArr = await getCapacity(this.state.companiesFromServer)
    const weightArr = await getWeight(this.state.companiesFromServer)

    console.log(this.state.companiesFromServer)

    const capacityCompany = this.state.companiesFromServer.map((item, index) => {
      return (
        Object.assign(item, { capacity: capacityArr[index], weight: weightArr[index] })
      )
    })
    this.setState({ companiesFromServer: capacityCompany })
  }

  openResultTable() {
    this.setState({
      showTable: false
    })

    this.setState({
      showTable: true
    })
  }

  checkForFormPresence() {
    //полный массив форм
    const fullArray = occupationTypes

    //массив только тех, форм, что есть в dataJson
    const notFullArray = this.state.dataJson.map(el => el.render)

    //отфильтровываем из полного массива те элементы, которые содержатся в массиве dataJson
    const finalArray = fullArray.filter(el => notFullArray.some(item => item === el))

    this.setState({
      arrayForFirstSelectRender: finalArray
      // arrayForFirstSelectRender: notFullArray
    })
  }

  fillArray() {
    let array = []
    for (let i = 0; this.state.allForms.length >= i; i++) {
      array.push(i)
    }
    this.setState({
      onCloseArray: array
    })
  }

  async closeModal(e) {
    // change map to forEach
    this.state.onCloseArray.forEach((el, idx) => {
      if (el === +e.target.dataset.position) {
        ; (async () => {
          let newArray = this.state.allFormsArray
          newArray[idx] = false

          await this.setState({
            allFormsArray: newArray
          })
        })()
      }
    })
  }

  setEquipment(result) {
    this.setState({
      equipment: result,
      showEquipment: true
    })
  }

  closeCompanyDetailsModal() {
    this.setState({
      companyDetailsModal: false
    })
  }

  async onProductionChoice(e) {
    e.preventDefault()
    this.checkForFormPresence()

    await this.setState({
      product: e.target.value,
      // showEquipmentUnderPressure: false,
      showEquipment: false,
      savedFiltersData: []
    })

    // определяем выбранный вид деятельности
    this.resultForm = []
    occupationTypes.forEach((name, index) => {
      if (name === e.target.value) {
        // console.log(`INDEX = `, index) // test

        this.resultForm = FORM_[index] // получаем массив форм
      }
    })

    // создаем массив объектов выбранных форм
    const resultName = this.resultForm.map(form => {
      return this.state.dataJson.find(item => item.name === form)
    })
    if (resultName.length === 1) {
      this.setState({ showEquipment: false })
      const obj = this.state.dataJson.find(item => item.render === e.target.value)
      // console.log(obj);
      // change map to forEach
      this.state.allForms.forEach((el, idx) => {
        if (el === obj.name) {
          let newArr = this.state.allFormsArray
          newArr[idx] = true
          this.setState({
            allFormsArray: newArr,
            showEquipment: true
          })
        }
      })
    } else if (e.target.value === 'Сосуды и аппараты работающие под давлением') {
      let newArr = this.state.allFormsArray
      newArr[17] = true
      this.setState({
        allFormsArray: newArr,
        showEquipment: true
      })
    } else {
      // (async () => {
      const objectForServer = [{ formName: this.resultForm[0] }]
      // console.log(`ОТПРАВИТЬ НА СЕРВЕР >>> `, objectForServer); // todo отправить на сервер // test

      const result = await Server.filterProductionCompany(objectForServer) //! result
      // console.log(`REsult:: `, result); // test

      this.setEquipment(result)
      // })()
    }

    this.setState({
      companyDetailsModal: false,
      companyName: ''
    })
    if (!this.state.product) {
      await this.setProduct()
    }
  }

  onProductionChoiceForm(e) {
    this.setState({ showEquipment: false })
    const objectForm = this.state.dataJson.find(item => item.title === e.target.value)
    const name = objectForm.name
    this.state.allForms.forEach((el, idx) => {
      if (el === name) {
        let newAllFormsArray = this.state.allFormsArray
        newAllFormsArray[idx] = !newAllFormsArray[idx]

        this.setState({
          allFormsArray: newAllFormsArray,
          showEquipment: true
        })
      }
    })
  }

  async checkCompaniesDataSource() {
    try {
      if (this.props.serverCompanies && this.props.serverCompanies.length > 0) {
        await this.setState({
          companiesFromServer: this.props.serverCompanies
        })
        // todo: сделать загурзку и дополнение объекта данными о загрузке предприятия (объект в ДБ - capacityDate)

        // console.log(`serverCompanies::: `, this.props.serverCompanies); // test
        this.openResultTable()
      } else {
        const data = this.state.searchResultData.map(el => el.data)
        const data1 = data.map(el => {
          return {
            nameCompany: `${el[100].value} ${el[1].value}`,
            ownership: el[100].value,
            country: el[14].value,
            region: el[15].value,
            inn: el[6].value,
            id: el[6].value,
            tel: el[4].value
          }
        })
        this.setState({
          companiesFromServer: data1
        })
        // console.log(data);
        // todo: сделать загурзку и дополнение объекта данными о загрузке предприятия (объект в ДБ - capacityDate)

      }
    } catch (err) {
      console.log(`Ошибка в (checkCompaniesDataSource): `, err)
    }
  }

  async setProduct() {
    //Если с поиска (в CreateLot отработал compDidMount, который получил данные с сервера)
    // if (this.props.product && this.props.formName) {
    //     this.setState({
    //         product: this.props.product,
    //         formName: this.props.formName
    //     })
    // }
    const res = await server.getDataFromServer('product', localStorage.getItem('idUser'))

    if (this.props.product) {
      this.setState({
        product: this.props.product,
        formName: this.props.formName
      })
      // console.log('i came from props');
    } else {
      if (res) {
        this.setState({
          product: res.product,
          formName: res.formName
        })
      }
      // console.log('i came from server');
    }
  }

  async componentDidMount() {
    let response = await fetch('./data/selectForProductionSearch.json')
    let dataJson = await response.json()
    await this.setState({ dataJson })
    // console.log(dataJson);
    this.fillArray()
    this.checkForFormPresence()
    await this.getEconomicDataToState()
    await this.setProduct()

    await this.checkCompaniesDataSource()
    const capacityArr = await getCapacity(this.state.companiesFromServer)
    const capacityCompany = this.state.companiesFromServer.map((item, index) => {
      return (
        Object.assign(item, { capacity: capacityArr[index] })
      )
    })
    this.setState({ companiesFromServer: capacityCompany })
  }

  async componentWillUnmount() {
    localStorage.removeItem('idAlien')
  }

  render() {
    const inModal = this.props.chosenCompaniesFromProductionSearch
    const forms = this.state.allFormsArray

    return (
      <>
        <div className='filter_box_container_modal card_block_no_position'>
          <Form className='filter_box box_shadow'>
            <h5 className='filter_box_h5'>Поиск по деятельности компании</h5>

            {this.state.showFormGroup && (
              <SearchInputGroup
                onProductionChoice={this.onProductionChoice}
                product={this.state.product}
                formName={this.state.formName}
                arrayForFirstSelectRender={this.state.arrayForFirstSelectRender}
                getChosenFilters={this.getChosenFilters}
                chosenCompaniesFromProductionSearch={this.state.chosenCompaniesFromProductionSearch}
                onSendIdInvite={this.doSendIdInvite}
                idLot={this.props.idLot}
                needTextInButtons={this.props.needTextInButtons}
              />
            )}
          </Form>

          <div>
            {this.state.showTable && (
              <UniversalTable
                favourite={true}
                rate={true}
                test={true}
                isRowGreen={true}
                removable={false}
                selectable={false}
                selectable1={false}
                selectableCheck={true}
                selectable2={true} //для открытия анкет
                openQuestionnairy={this.openQuestionnairy} //для открытия анкет
                tableName={this.state.product && `Результат поиска "${this.state.product.toLowerCase()}"`}
                titleObjectData={{
                  numbered: 'п/п',
                  // ownership: 'Форма собственности',
                  nameCompany: 'Название',
                  capacity: 'Загрузка до',
                  weight: 'Масса до',
                  country: 'Страна',
                  region: 'Город',
                  rate: 'Рейтинг'
                }}
                renderedArray={this.state.companiesFromServer}
                renderedArrayWithAdded={this.getAllSearchCompanies}
                chosenObjectsArray={this.getChosenCompanies}
                chosenIdsArray={this.props.onGetChosenIdies}
                chosenObjectId={this.doEmpty}
                handleCancel={this.doEmpty}
                handleModalToggle={this.props.handleCompanyModalQuit}
                showMainButton={false}
                mainButtonName={''}
                updateObjectWithAdded={this.updateAllSearchCompanies}
                isAnotherBtnPressed={this.props.isAnotherBtnPressed}
              />
            )}

            {inModal && inModal.length >= 0 && (
              <>
                {this.state.chosenCompaniesArray.length > 0 && (
                  <TransitionToLot
                    radioResult={this.state.radioResult} 
                    formName={'production_search'} 
                    handleOnChange={this.onChangeLotType} 
                    handleClick={this.handleLaunchClick}
                  />
                )}
              </>
            )}
          </div>
        </div>

        {this.state.showEquipment && (
          <>
            {forms[17] && (
              <Zero
                view={forms[17]}
                viewQuest={forms[17]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              // sendTest={this.getTest}
              />
            )}

            {forms[1] && (
              <Two
                view={forms[1]}
                viewQuest={forms[1]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              />
            )}

            {forms[4] && (
              <Five
                view={forms[4]}
                viewQuest={forms[4]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                // getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              />
            )}

            {forms[18] && (
              <FormBendPipe
                view={forms[18]}
                viewQuest={forms[18]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                // getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              />
            )}

            {forms[19] && (
              <FormBottoms
                view={forms[19]}
                viewQuest={forms[19]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                // getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              />
            )}

            {forms[20] && (
              <FormLensCompensators
                view={forms[20]}
                viewQuest={forms[20]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                // getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              />
            )}
            {forms[21] && (
              <FormFlanges
                view={forms[21]}
                viewQuest={forms[21]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                // getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              />
            )}

            {forms[22] && (
              <FormForgings
                view={forms[22]}
                viewQuest={forms[22]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                // getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              />
            )}

            {forms[23] && (
              <FormBends
                view={forms[23]}
                viewQuest={forms[23]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                // getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              />
            )}
            {forms[25] && (
              <FormRolling
                view={forms[25]}
                viewQuest={forms[25]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                // getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              />
            )}

            {forms[26] && (
              <FormProductColoration
                view={forms[26]}
                viewQuest={forms[26]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                // getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              />
            )}

            {forms[27] && (
              <FormBendingCuttingMetal
                view={forms[27]}
                viewQuest={forms[27]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                // getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              />
            )}

            {forms[29] && (
              <FormWelding
                view={forms[29]}
                viewQuest={forms[29]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                // getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              />
            )}

            {forms[30] && (
              <FormRolled
                view={forms[30]}
                viewQuest={forms[30]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                // getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              />
            )}

            {forms[31] && (
              <FormPipesSupply
                view={forms[31]}
                viewQuest={forms[31]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                // getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              />
            )}

            {forms[32] && (
              <FormSheetMetalSupply
                view={forms[32]}
                viewQuest={forms[32]}
                closeModal={this.closeModal}
                sendSearchResultData={this.getSearchResultData}
                openResultTable={this.openResultTable}
                data={
                  this.state.savedFiltersData && this.state.savedFiltersData.length > 0
                    ? this.state.savedFiltersData
                    : []
                }
                getChosenFiltersData={this.getChosenFiltersData}
                // getProduct={this.getProduct}
                saveChosenFilters={this.saveChosenFilters}
                savedFiltersData={this.state.savedFiltersData}
                product={this.state.product}
                chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
              />
            )}
          </>
        )}
        {this.state.companyDetailsModal && this.state.companyQuestionnairieId === this.state.innFromUniversalTable && (
          <QuestionnairyModal
            closeCompanyDetailsModal={this.closeCompanyDetailsModal} // команда на закрытие
            companyName={this.state.companyName} // название компании, для заглавия моалки
            infoBlock={this.props.infoBlock}
            onUpInfoBlock={this.props.onUpInfoBlock}
            viewQuest={this.props.viewQuest}
            innFromUniversalTable={this.state.innFromUniversalTable} // передаем полученный ИНН выбранной компании
            getEconomicData={this.props.getEconomicData}
          />
        )}

        {/* <FeedBack/> */}
      </>
    )
  }
}
