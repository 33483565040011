import React from 'react';
import Server from '../server/server';
import UniversalTable from '../assets/UniversalTable';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

export default class LookInviteEmail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
            participantInvite: [],
            dataInvite: [],
            arrInviteForTable: []
        }
        this.getInviteEmail = this.getInviteEmail.bind(this);
        this.createObjectTable = this.createObjectTable.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
    }

    doEmpty() {
    }

    // по массиву ID приглашений, с DB получаем массив приглашений
    async getInviteEmail(id) {
        try {
            const result = await Server.getArrayDocuments('invite', id);

            if (result.ERROR) { throw new Error(result.message) }
            this.setState({ dataInvite: result });
            return result;

        } catch (err) {
            console.log(err);
        }
    }

    async createObjectTable(arrId) {
        try {
            this.setState({ show: false })
            const result = await this.getInviteEmail(arrId);
            // console.log(result);

            const arrInviteForTable = result.map(data => {
                return (
                    {
                        nameCompany: data.nameCompany,
                        inviteOwn: data.inviteOwn,
                        emailInvite: data.emailInvite,
                        invitePhone: data.invitePhone,
                        inviteINN: data.inviteINN
                    }
                )
            })
            this.setState({ arrInviteForTable: arrInviteForTable });
            this.setState({ show: true })

        } catch (err) {
            console.log(`Ошибка в получении данных! (LookInviteEmail.jsx)`);
        }
    }

    async componentDidMount() {
        this.setState({ participantInvite: this.props.participantInvite })
        await this.createObjectTable(this.props.participantInvite)

    }

    render() {
        const show = this.state.show;

        return (
            <>
                {show &&
                        <UniversalTable
                            favourite={false}
                            rate={false}
                            isRowGreen={false}
                            removable={true}
                            selectable1={false}
                            selectable2={false}
                            tableName={ <>
                                <CheckOutlinedIcon
                                  className="pb_3"
                                  color="action"
                                />{" "}
                                Приглашенные компании лота
                              </>}
                            titleObjectData={{
                                numbered: 'п/п',
                                nameCompany: 'Название',
                                inviteOwn: 'Форма собс-ти',
                                emailInvite: 'E-mail',
                                invitePhone: 'Телефон',
                                inviteINN: 'ИНН'
                            }}
                            openQuestionnairy={this.doEmpty}
                            renderedArray={this.state.arrInviteForTable}
                            renderedArrayWithAdded={this.doEmpty}
                            chosenObjectsArray={this.doEmpty}
                            chosenIdsArray={this.doEmpty}
                            chosenObjectId={this.doEmpty}
                            handleCancel={this.doEmpty}
                            handleModalToggle={this.doEmpty}
                        />
                }
            </>
        )
    }
}