import React from 'react';
import { Container } from 'react-bootstrap';
import RightMenuBlock from './RightMenuBlock';
import SaidBar from './SaidBar';

export default class Navigation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: false,
            urlPosition: '',
            showSettings_test: false,
            updatedIcon: 'default',
            newIcon: ''
        }

        this.doChangeShow = this.doChangeShow.bind(this);
        this.doFocusSaidBar = this.doFocusSaidBar.bind(this);
        this.doTransferUrl = this.doTransferUrl.bind(this);
        this.doUpInfoBlock = this.doUpInfoBlock.bind(this);
        this.doCreateTrade = this.doCreateTrade.bind(this);
        this.doChangeURL = this.doChangeURL.bind(this);
        this.openSettings = this.openSettings.bind(this);
        this.setNewIcon = this.setNewIcon.bind(this);
        this.checkAdmin = this.checkAdmin.bind(this);
        this.closeAdminPanel = this.closeAdminPanel.bind(this);
    }

    /**
     * Закрываем панель администратора
     */
    closeAdminPanel() {
        console.log(`close admin panel`);
        this.setState({ urlPosition: '' })
    }

    //* проверка на вход в панель администратора
    async checkAdmin(login, password) {
        try {
            return (async () => {

                if (login === 'admin' && password === '12345') {
                    return true
                }

                return false
            })()
        } catch (err) {
            console.log(`Ошибка входа в панель администратора (Navigation.jsx) : `, err);
            return false
        }

    }

    setNewIcon(newIcon) {
        this.setState({
            newIcon
        })
    }

    openSettings() {
        this.setState({
            showSettings_test: !this.state.showSettings_test
        })
    }

    doChangeURL() {
        this.setState({ urlPosition: '/customer' })
    }

    doCreateTrade(data) {
        this.props.onCreateTrade(data)
    }

    doUpInfoBlock(data) {
        this.props.onUpInfoBlock(data);
    }

    doTransferUrl(url) {
        this.setState({ urlPosition: url })
    }

    doFocusSaidBar() {
        this.setState({ show: false })
    }
    //если шоу фолзится, значит сайдбар открывается
    doChangeShow() {
        console.log('CLICK');
        this.setState({ show: !this.state.show })
        this.props.setFooterSize()
    }
    async componentDidMount() {
        if (this.props.questionnaireURL) {
            this.setState({ urlPosition: '/questionnaire' })
        }

        //* запуск панели администратора по url
        // http://localhost:3000/admin?login=admin&password=12345
        (async () => {
            let url = new URL(window.location.href)

            if (url.pathname === '/admin') {

                const objSearch = url.searchParams
                console.log(`URL:::::: `, objSearch.get('login'), objSearch.get('password')); // test

                const resultIn = await this.checkAdmin(objSearch.get('login'), objSearch.get('password'));
                console.log(`admin >>>>> `, resultIn); // test

                if (resultIn) {
                    this.setState({ urlPosition: '/admin' })
                }

            }

        })()

    }

    render() {

        this.nameCompany = this.props.nameCompany;

        return (
            <>
                <Container className="navigation p_0" fluid  >

                    <SaidBar
                        show={this.state.show}
                        onFocusSaidBar={this.doFocusSaidBar}
                        onTransferUrl={this.doTransferUrl}
                        nameCompany={this.nameCompany}
                        openSettings={this.openSettings}
                        showSettings={this.state.showSettings_test}
                        newIcon={this.state.newIcon}
                    />

                    <RightMenuBlock
                        show={this.state.show}
                        urlPosition={this.state.urlPosition} // позиция в меню для перехода '/questionnaire'
                        closeAdminPanel={this.closeAdminPanel}
                        onChangeShow={this.doChangeShow}
                        infoBlock={this.props.infoBlock}
                        onUpInfoBlock={this.doUpInfoBlock}
                        onCreateTrade={this.doCreateTrade}
                        keyNameTrade={this.props.keyNameTrade}
                        keyOtherNumberTrade={this.props.keyOtherNumberTrade}
                        nameCompany={this.nameCompany}
                        onStartPersonalArea={this.props.onStartPersonalArea}
                        onChangeURL={this.doChangeURL}
                        chatMessage={this.props.chatMessage}
                        openSettings={this.state.showSettings_test}
                        updateQuantityMessage={this.props.updateQuantityMessage}
                        setNewIcon={this.setNewIcon}

                    />
                </Container>
            </>
        )
    }
}