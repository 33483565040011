import React from 'react'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppSharp'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';

export function SaveIcon() {
  return <SaveOutlinedIcon style={{ marginRight: '5px' }} />
}

export function LeaveIcon() {
  return <ExitToAppOutlinedIcon style={{ marginRight: '5px' }} />
}

export function AddIcon() {
  return <AddBoxOutlinedIcon style={{ marginRight: '5px' }} />
}


