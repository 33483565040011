import React from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
// import ModalQuit from '../ModalQuit';
import { COMPANY, disabledCheck } from './ConstListCompany'
import FormMaterListWithDisabled from './FormMaterListWithDisabled'
import AlignVerticalBottomOutlinedIcon from '@mui/icons-material/AlignVerticalBottomOutlined'
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp'
import CommonAlert from './CommonAlert'
import QuitModal from '../QuitModal'
import AllModalsFooter from '../assets/AllModalsFooter'
import { SaveCurrentDataButton } from '../assets/BasicButtons'
import { createData } from './functionsForm'
import { isEqual } from 'lodash-es'

export default class QuestionnaireProduction extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'rus',
      dataValue: [],
      maxId: 2,
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      show: false,
      showQuit: false,
      nameForm: 'Production',
      dataForForm: [],
      showContent: true,
      previousState: {},
      savedDataMessage: false,
      dataBeenSaved: false
    }
    this.doEmpty = this.doEmpty.bind(this)
    this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.doChangeValue = this.doChangeValue.bind(this)
    this.createArrayProduction = this.createArrayProduction.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.hideContent = this.hideContent.bind(this)
    this.setCompanyName = this.setCompanyName.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.currData = this.currData.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
  }

   saveDataWithNotification() {

    this.setState({
      savedDataMessage: true,
      dataBeenSaved: true
    })

    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
      this.handleClickSave()
    }, 2000);

    
  }

  hideContent() {
    this.setState({ showContent: false })
  }

  setCompanyName() {
    if (this.props.companyName && this.props.companyName.length > 0) {
      this.setState({
        companyName: this.props.companyName
      })
    }
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })
  }

  //*Пустышка
  doEmpty() { }

  //* меняем видимость блока при выборе ДА / НЕТ
  doChangeVisionBlock(data, id) {
    if (+id === 2) {
      if (data === 'Да') this.setState({ show: true })
      if (data === 'Нет') this.setState({ show: false })
    }
  }

  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  doQuitWithSave() {
    this.setState({ showQuit: false })
    this.handleClickSave()
    this.props.onChangeView('Production')
    this.props.onRunAccordion()
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView('Production')
    this.props.onRunAccordion()
  }

  currData() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach(dataD => {
        if (+dataD.id === i) {
          data[i] = {
            id: dataD.id,
            fid: dataD.fid,
            description: dataD.description,
            information: dataD.information,
            value: dataD.value
          }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach(dataE => {
        if (+dataE.id === i) {
          data[i] = {
            id: dataE.id,
            fid: dataE.fid,
            description: dataE.description,
            information: dataE.information,
            value: dataE.value
          }
        }
      })
    }

    // console.log(data);

    return data[2].value
  }

  //*формируем данные в массив объектов для отправки на сервер
  handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach(dataD => {
        if (+dataD.id === i) {
          data[i] = {
            id: dataD.id,
            fid: dataD.fid,
            description: dataD.description,
            information: dataD.information,
            value: dataD.value
          }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach(dataE => {
        if (+dataE.id === i) {
          data[i] = {
            id: dataE.id,
            fid: dataE.fid,
            description: dataE.description,
            information: dataE.information,
            value: dataE.value
          }
        }
      })
    }
    this.setState({ dataOnServer: data })
    if (this.state.lengthDataFromServer < 1) {
      // console.log('CREATE DATA');
      this.props.onCreateDataServer(data, 'Production', localStorage.getItem('idUser'))
    } else {
      // console.log('UPDATE DATA');
      this.props.onUpdateDataOnServer(data, 'Production', localStorage.getItem('idUser'))
    }
    setTimeout(() => {
      this.createArrayProduction(data)
    }, 500) // запуск сохранения формы 'data_form'
  }



  //* обработка выбранных видов деятельности, форма 'data_form'
  createArrayProduction(data) {
    const circle = data[2].value
    const inform = data[2].information
    let newArrData = []
    circle.forEach((res, index) => {
      if (res) {
        newArrData = [...newArrData, { id: index, information: inform[index], form: `FORM_${index}`, value: true }]
      } else {
        newArrData = [...newArrData, { id: index, information: inform[index], form: `FORM_${index}`, value: false }]
      }
    })
    this.setState({ dataForForm: newArrData }) // переменная с формой выбора форм :-D

    // console.log(newArrData);//test
    if (this.state.lengthDataFromServer < 1) {
      // console.log('CREATE DATA on SERVER FORM DATA !!!!!!!!!!!!!');
      this.props.onCreateDataServer(newArrData, 'data_form', String(localStorage.getItem('idUser')))
    } else {
      // console.log('UPDATE DATA on SERVER FORM DATdata_form!!!!!');
      this.props.onUpdateDataOnServer(newArrData, 'data_form', String(localStorage.getItem('idUser')))
    }
  }

  //* данные с полей формы, формируются в массив объектов
  doChangeValue(data) {
    // console.log(data);//test

    const stateCopy = [...this.state.dataValue, data]
    this.setState({ dataValue: stateCopy })
    this.setState({ dataBeenSaved: false })
    // setTimeout(() => { console.log(this.state.dataValue) })//test

    // this.props.sendCompanyProductionArray(this.state.dataValue)
  }

  compare(prev) {
    const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)
    const isStateChanged = isEqual(prev, curr)
    if (isStateChanged) {
      return false
    }
    return true
  }

  smartWindowFunction() {
    // this.props.setSendOnModerationModal()
    this.props.setFillingGuide()
    const result = this.compare(this.state.previousState)
    if (result && !this.state.dataBeenSaved) {
      this.handleClickShadow()
      this.props.onRunAccordion()
    } else {
      this.props.onChangeView(this.state.nameForm)
      this.props.onRunAccordion()
    }
  }

  async componentDidMount() {
    // setTimeout(() => {
    await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer })

    this.setCompanyName()

    const previous = JSON.parse(JSON.stringify(this.props.data))
    this.setState({
      previousState: previous
    })

  }

  render() {
    const view = this.props.view
    this.value = this.props.data // данные из формы

    const viewQuest = this.props.viewQuest

    const inReadMode =
      this.props.innFromUniversalTable === null ||
      this.props.innFromUniversalTable === '' ||
      this.props.innFromUniversalTable === undefined

    // const currentState = this.currData()
    // const previousState = this.state.dataFromServer
    // if (currentState[2] && previousState[2]) {
    //   console.log('currentState', currentState[2]['value'])
    //   console.log('previousState', previousState[2]['value'])
    // }

    return (
      <>
        {inReadMode && (
          <>
            {this.state.showQuit && (
              <QuitModal
                onQuitWithSave={this.doQuitWithSave}
                onQuitWithoutSave={this.doQuitWithoutSave}
                closeQuitModal={this.closeQuitModal}
                modalTitle={'Выход'}
                modalBody={'Сохранить данные?'}
                primaryButton={'Сохранить'}
                secondaryButton={'Выйти без сохранения'}
                icon={<ExitToAppSharpIcon fontSize='large' className='icon_leave_modal' />}
              />
            )}
          </>
        )}

        {this.state.showContent && (
          <div className='modal_window' style={{ display: view ? 'block' : 'none' }}>
            <div className={viewQuest ? 'shadow_form' : 'shadow_form_off'} onClick={this.smartWindowFunction}></div>
            <Form className={viewQuest ? 'form_main' : 'form_main_view'}>
              <CommonAlert
                inReadMode={inReadMode}
                handleClickHeader={this.handleClickHeader}
                // handleClickShadow={this.handleClickShadow}
                handleClickShadow={this.smartWindowFunction}
                hideContent={this.hideContent}
                icon={<AlignVerticalBottomOutlinedIcon fontSize='large' className='icon_primary' />}
                companyName={this.state.companyName}
                sectionName='Профиль деятельности предприятия'
              />
              <div>
                <Container className='form_container'>
                  <Form.Group>
                    <Row>
                      <Col>Укажите профиль деятельности регистрируемой компании (можно выбрать несколько).</Col>
                    </Row>

                    <FormMaterListWithDisabled
                      id={2}
                      width={12}
                      rowView={true}
                      materials={COMPANY}
                      value={this.value[2] ? this.value[2].value : ''}
                      description='Профиль деятельности'
                      onChangeValue={this.doChangeValue}
                      nameForm={this.state.nameForm}
                      // selectCheck={this.state.selectCheckLowalloy}
                      selectCheck={true}
                      disable={disabledCheck}
                    />
                  </Form.Group>
                  {inReadMode && <Row> &nbsp; </Row>}

                  <Row> &nbsp; </Row>
                </Container>
                <AllModalsFooter
                  footerContent={
                    inReadMode && (
                      <Form.Group>
                        <Col>
                          {/* <SaveButtonGreen
                            handleSaveClick={this.handleClickSave}
                            needDisable={false}
                            saveButtonText='Сохранить'
                          /> */}
                          <SaveCurrentDataButton

                            handleSaveClick={this.saveDataWithNotification}
                            needDisable={false}
                            savedDataMessage={this.state.savedDataMessage}
                            buttonTextDefault={'Сохранить'}
                            buttonTextSaved={'Данные сохранены'}
                            needCustomIcon={false}
                          />

                          {/* <Button
                                                variant="success"
                                                onClick={this.handleClickSave}
                                            >
                                                Сохранить
                                            </Button> */}
                        </Col>
                      </Form.Group>
                    )
                  }
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}
