import React from 'react'
import { Alert, Container } from 'react-bootstrap'
import GeneralForm from '../assets/GeneralForm'
import { renderObject } from './CommonObjects'

export default function OrderModal({ toggleModal, alertHeading, order }) {
  // console.log(order)
  // {
  //     "STHEid": "140168/2",
  //     "tenderId": "23_0003_0005",
  //     "tradePositionName": "pos_1",
  //     "numberOfItemsInTradePosition": "1",
  //     "priceOfTradePosition": "1",
  //     "dateOfCreation": "2023-09-08",
  //     "customer": "ОАО Chempioil",
  //     "customerRepresentetive": "Mr Someone",
  //     "manufacturer": "ОАО Вкусная котлетка",
  //     "manufacturerRepresentetive": "7655 7 7",
  //     "equipmentName": "Equipment name 1",
  //     "quantity": "Кол-во не определено",
  //     "orderPrice": "не число руб.",
  //     "launchReasoning": "Подписаный договор",
  //     "commencmentDate": "2023-09-08",
  //     "deliveryDate": "2023-09-08",
  //     "preferableReceivingDate": "2023-09-08",
  //     "CDType": "Техпроект",
  //     "CDManufacturer": "doc_11",
  //     "CDRepresentetive": "Mrs Someone",
  //     "CDProvidingDate": "2023-09-08",
  //     "equipmentTypename": "Equipment name",
  //     "TUEquipmentName": "2х700-ТКГИ-1,6-1,6-М10-20-2-8",
  //     "pipeWorkEnvironment": "Техническая вода",
  //     "pipeMarking": "W",
  //     "pipePressure": "1,6",
  //     "quantytyOfPipeMethods": "8",
  //     "interPipeWorkEnvironment": "Нафталин",
  //     "interPipeMarking": "V",
  //     "interPipePressure": "1,6",
  //     "quantytyOfInterPipeMethods": "1",
  //     "equipmentDiametr": "700",
  //     "TUEquipmentType": "ТКГИ",
  //     "physicalImplementation": "M10",
  //     "pipeMaterial": "12Х18Н10Т",
  //     "pipeDiametr": "20",
  //     "pipeThickness": "1,5",
  //     "pipeLength": "2",
  //     "quantityOfBlocks": "2",
  //     "idLot": "mFKvrlEnkeSjQadxUvk3I",
  //     "innCustomer": "4444444444",
  //     "innManufacturer": "2222222222",
  //     "employeesCustomer": null,
  //     "employeesManufacturer": [],
  //     "tradePosition": [
  //         {
  //             "idPosition": "nYOz9K5eQ-ea4CecaWZ5y",
  //             "namePosition": "pos_1",
  //             "quantity": "1",
  //             "price": "1"
  //         }
  //     ],
  //     "generalDate": "2023-09-08"
  // }
  return (
    <>
      <div className='shadow_form' onClick={toggleModal}></div>
      <div className='modal_common_1 z_index_1000'>
        <Alert variant='dark' onClose={toggleModal} dismissible>
          <Alert.Heading>{alertHeading}</Alert.Heading>
        </Alert>
        <Container style={{ height: '700px', overflowY: 'auto' }}>
          {/* <Container style={{ padding: '20px 50px 0px 50px' }}> */}
            <GeneralForm
              renderedArray={renderObject}
              selectData={[]}
              selectIndexes={[]}
              buttons={['']}
              object={order}
              headingText=''
              sendData={()=>{}}
              toggleTechParamsModal={() => {}}
              defaultBtnText='Сформировать'
              savedBtnText='Заказ-наряд сформирован'
              dividingIndex={null}
              mainFields={[]}
              jsonDataSTHE={[]}
              jsonDataTender={[]}
              readOnly={true}
            />
          {/* </Container> */}
        </Container>
      </div>
    </>
  )
}
