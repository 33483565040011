import React from 'react'
import { Form, Row, Col } from 'react-bootstrap'
import { COMPANY } from '../questionnaire/ConstListCompany'
import Server from '../server/server'
import UniversalTable from '../assets/UniversalTable'
import { QuestionnairyModal } from '../QuestionnairyModal/QuestionnairyModal'
import LeftAndRightArrowsButtons from '../assets/LeftAndRightArrowsButtons'
import { SearchButton, ClearButton } from '../assets/BasicButtons'
import { InviteByEmail } from '../InviteCompanies/InviteByEmail'
import TransitionToLot from '../assets/TransitionToLot'
import getCapacity from './getCapacity'
import getWeight from './getWeight'
import { getPagedData, goToNextPage, goToPreviousPage } from '../assets/PaginationFunctions'
import ModalError from '../../components/ModalError'

export class CompanySearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      companyData: {
        nameCompany: '',
        country: '',
        region: '',
        inn: '',
        occupation: '',
        pageNumber: 1
      },
      companyDataFromServer: [], // результат поиска компаний по фильтру
      showTable: true,
      companyDetailsModal: false,
      companyObject: [],
      // companyQuestionnairie: {},
      searchMode: false,
      innFromUniversalTable: '',
      companyQuestionnairieId: '',
      companyName: '',
      radioResult: '',
      chosenCompanies: [],
      currentPage: 0,
      pageSize: 10,
      show_errorRequest: false,
    }

    this.onSearchDataChange = this.onSearchDataChange.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.clearFilterInputs = this.clearFilterInputs.bind(this)

    this.disableCondition = this.disableCondition.bind(this)
    this.openQuestionnairy = this.openQuestionnairy.bind(this)
    this.closeCompanyDetailsModal = this.closeCompanyDetailsModal.bind(this)
    this.reRenderTable = this.reRenderTable.bind(this)
    this.onChangeLotType = this.onChangeLotType.bind(this)
    this.handleLaunchClick = this.handleLaunchClick.bind(this)
    this.updateAllSearchCompanies = this.updateAllSearchCompanies.bind(this)
    // this.getPagedData = this.getPagedData.bind(this)
    // this.goToNextPage = this.goToNextPage.bind(this)
    // this.goToPreviousPage = this.goToPreviousPage.bind(this)
  }

  // getPagedData() {
  //   const { companyDataFromServer, currentPage, pageSize } = this.state

  //   // Копируем и переворачиваем массив
  //   const reversedData = companyDataFromServer.slice().reverse()

  //   // Вырезаем нужный для текущей страницы кусок массива
  //   const start = currentPage * pageSize
  //   const end = start + pageSize

  //   // Снова переворачиваем массив для корректного отображения на странице
  //   return reversedData.slice(start, end).reverse()
  // }
  // goToNextPage() {
  //   this.setState(
  //     (prevState) => {
  //       const maxPages =
  //         Math.ceil(this.state.companyDataFromServer.length / this.state.pageSize) - 1
  //       if (prevState.currentPage < maxPages) {
  //         return { currentPage: prevState.currentPage + 1, showTable: false }
  //       }
  //       return null // Если текущая страница последняя, не делаем обновления состояния
  //     },
  //     () => this.setState({ showTable: true })
  //   )
  // }

  // goToPreviousPage() {
  //   this.setState(
  //     (prevState) => {
  //       if (prevState.currentPage > 0) {
  //         return { currentPage: prevState.currentPage - 1, showTable: false }
  //       }
  //       return null // Если текущая страница первая, не делаем обновления состояния
  //     },
  //     () => this.setState({ showTable: true })
  //   )
  // }

  async updateAllSearchCompanies(array) {
    this.setState({
      chosenCompanies: array
    })
  }

  async handleLaunchClick() {
    try {
      //передаем родителю выбранные в этом компоненте днные о типе лота, только выбранных компний и виде деятельности
      const chosenCompanies = this.state.companyDataFromServer.filter((el) => el.added)
      this.props.sendDataChoice(this.state.radioResult, chosenCompanies, '')
      await Server.sendDataOnServer(
        this.state.companyDataFromServer,
        'companiesArrayFromServer',
        this.state.idUser
      )

      this.props.toggleLotModal()
    } catch (err) {
      console.log(`ERROR: `, err);
    }

  }

  async onChangeLotType(e) {
    await this.setState({
      radioResult: e.target.nextSibling.innerText
    })
  }

  async reRenderTable() {
    this.setState(
      {
        showTable: false
      },
      () =>
        this.setState({
          showTable: true
        })
    )
  }

  closeCompanyDetailsModal() {
    this.setState({
      companyDetailsModal: false
    })
  }

  async openQuestionnairy(inn) {
    this.setState({
      innFromUniversalTable: inn
    })

    await this.state.companyDataFromServer.forEach((el) => {
      if (el.inn === inn) {
        // console.log(el);
        this.setState({
          companyQuestionnairieId: el.inn,
          companyName: el.nameCompany
        })
      }
    })

    this.setState({
      companyDetailsModal: true
    })

    localStorage.setItem('idAlien', this.state.companyQuestionnairieId)
  }

  clearFilterInputs(e) {
    e.preventDefault()
    this.setState({
      companyData: {
        ...this.state.companyData,
        nameCompany: '',
        country: '',
        region: '',
        inn: '',
        occupation: ''
      }
    })
  }

  disableCondition() {
    const data = this.state.companyData
    if (
      data.nameCompany !== '' ||
      data.country !== '' ||
      data.region !== '' ||
      data.inn !== '' ||
      data.occupation !== ''
    ) {
      return false
    }
    return true
  }

  async onSearchDataChange(e) {
    this.setState({
      companyData: {
        ...this.state.companyData,
        [e.target.name]: e.target.value
      }
    })
  }

  async onSubmit(e) {
    try {
      e.preventDefault()
      this.setState({
        showTable: false
      })

      const result = await Server.filterSearchCompany(this.state.companyData)
      console.log(result)

      const companiesWithFilteredData = result.map((el) => {
        return {
          nameCompany: `${el[0].data[100].value} ${el[0].data[1].value}`,
          country: el[0].data[14].value,
          region: el[0].data[15].value,
          inn: el[0].data[6].value,
          id: el[0].data[6].value,
          tel: el[0].data[4].value
        }
      })

      const capacityArr = await getCapacity(companiesWithFilteredData)
      const weightArr = await getWeight(companiesWithFilteredData)

      const companiesWithWeightAndCapacity = companiesWithFilteredData.map((item, index) => {
        return Object.assign(item, { capacity: capacityArr[index], weight: weightArr[index] })
      })

      // console.log(companiesWithWeightAndCapacity);

      this.setState({
        companyDataFromServer: companiesWithWeightAndCapacity
      })

      this.setState({
        showTable: true
      })
    } catch (err) {
      if (Server.errorRequest()) {
        console.log(`ERROR AUTH -> CompanySearch`);
        this.setState({ show_errorRequest: true })
        setTimeout(() => { this.setState({ show_errorRequest: false }) }, 3000)
      }
      console.log(`ERROR: `, err);
    }

  }

  async componentWillUnmount() {
    localStorage.removeItem('idAlien')
  }

  render() {
    // console.log(this.state.companyName);

    console.log(this.currentPage * this.state.pageSize)

    return (
      <>{
        this.state.show_errorRequest ?
          <ModalError />
          : ''
      }
        <div className='filter_box_container' style={{ marginTop: '50px', marginBottom: '80px' }}>
          <Form className='filter_box box_shadow'>
            <h5 className='filter_box_h5'>Поиск компаний</h5>
            <Form.Group>
              <Row className='display_flex'>
                <Col className='filter_col_label'>
                  <Form.Label>Название компании: </Form.Label>
                </Col>
                <Col className='filter_col_input'>
                  <Form.Control
                    // size='sm'
                    width={10}
                    name='nameCompany'
                    type='text'
                    placeholder='Введите название компании'
                    value={this.state.companyData.nameCompany}
                    onChange={this.onSearchDataChange}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row className='display_flex'>
                <Col className='filter_col_label'>
                  <Form.Label>Страна: </Form.Label>
                </Col>
                <Col className='filter_col_input'>
                  <Form.Control
                    // size='sm'
                    name='country'
                    type='text'
                    placeholder='Страна'
                    value={this.state.companyData.country}
                    onChange={this.onSearchDataChange}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row className='display_flex'>
                <Col className='filter_col_label'>
                  <Form.Label>Регион: </Form.Label>
                </Col>
                <Col className='filter_col_input'>
                  <Form.Control
                    // size='sm'
                    name='region'
                    type='text'
                    placeholder='Регион'
                    value={this.state.companyData.region}
                    onChange={this.onSearchDataChange}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row className='display_flex'>
                <Col className='filter_col_label'>
                  <Form.Label>ИНН: </Form.Label>
                </Col>
                <Col className='filter_col_input'>
                  <Form.Control
                    // size='sm'
                    name='inn'
                    type='number'
                    placeholder='Введите ИНН'
                    value={this.state.companyData.inn}
                    onChange={this.onSearchDataChange}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row className='display_flex'>
                <Col className='filter_col_label'>
                  <Form.Label>Вид деятельности: </Form.Label>
                </Col>
                <Col className='filter_col_input'>
                  <Form.Control
                    // size='sm'
                    name='occupation'
                    as='select'
                    value={this.state.companyData.occupation}
                    onChange={this.onSearchDataChange}
                    defaultValue={''}
                  >
                    <option selected>{''}</option>
                    {COMPANY.map((el) => (
                      <option key={el}>{el}</option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </Form.Group>

            <div
              style={{
                display: 'flex',
                gap: '8px',
                marginTop: '10px',
                justifyContent: 'flex-end'
              }}
            >
              <SearchButton
                handleSaveClick={this.onSubmit}
                needDisable={false}
                buttonText={'Искать компании'}
                buttonVariant={'primary'}
              />

              <InviteByEmail icon={true} btnText={'Пригласить компании по email'} fromSearch={true} />

              <ClearButton
                handleSaveClick={this.clearFilterInputs}
                needDisable={true}
                disableCondition={this.disableCondition()}
                saveButtonText={'Очистить поля'}
                buttonVariant='secondary'
              />
            </div>
          </Form>
          {this.state.showTable && (
            <UniversalTable
              key={this.state.currentPage}
              isRowGreen={false}
              removable={false}
              selectable={false}
              selectable1={false}
              selectable2={true} //для открытия анкет
              selectableCheck={true} // Добавляет check box
              openQuestionnairy={this.openQuestionnairy} //для открытия анкет
              tableName={'Результат поиска'}
              titleObjectData={{
                numbered: 'п/п',
                nameCompany: 'Название',
                capacity: 'Загрузка до',
                weight: 'Масса до',
                country: 'Страна',
                region: 'Город',
                inn: 'ИНН'
              }}
              // renderedArray={data}
              // renderedArray={this.state.companyDataFromServer}
              // renderedArray={this.getPagedData()}
              renderedArray={this.state.companyDataFromServer.length ? getPagedData(
                this.state.companyDataFromServer,
                this.state.currentPage,
                this.state.pageSize
              ) : []}
              renderedArrayWithAdded={this.doEmpty}
              chosenObjectsArray={this.doEmpty}
              chosenIdsArray={this.doEmpty}
              chosenObjectId={this.doEmpty}
              handleCancel={this.doEmpty}
              handleModalToggle={this.doEmpty}
              updateObjectWithAdded={this.updateAllSearchCompanies}
              offset={this.state.currentPage * this.state.pageSize}
            />
          )}
          {this.state.chosenCompanies.length > 0 && (
            <TransitionToLot
              radioResult={this.state.radioResult}
              formName={'CompanySearch'}
              handleOnChange={this.onChangeLotType}
              handleClick={this.handleLaunchClick}
            />
          )}
          {this.state.companyDetailsModal &&
            this.state.innFromUniversalTable === this.state.companyQuestionnairieId && (
              <QuestionnairyModal
                closeCompanyDetailsModal={this.closeCompanyDetailsModal} // команда на закрытие
                companyName={this.state.companyName} // название компании, для заглавия модалки
                infoBlock={this.props.infoBlock}
                onUpInfoBlock={this.props.onUpInfoBlock}
                viewQuest={this.props.viewQuest}
                innFromUniversalTable={this.state.innFromUniversalTable} // передаем полученный ИНН выбранной компании
                getEconomicData={this.props.doEmpty}
              />
            )}
          <LeftAndRightArrowsButtons
            // nextPageClick={this.goToNextPage}
            // prevPageClick={this.goToPreviousPage}
            nextPageClick={() => this.setState((prevState) => goToNextPage(prevState), () => this.setState({ showTable: true }))}
            prevPageClick={() => this.setState((prevState) => goToPreviousPage(prevState), () => this.setState({ showTable: true }))}
            needDisableButtons={true}
            disableNextButtonCondition={
              this.state.currentPage >=
              Math.ceil(this.state.companyDataFromServer.length / this.state.pageSize) - 1
            }
            disablePrevButtonCondition={this.state.currentPage === 0}
          />
        </div>
      </>

    )
  }
}
