import React, { useState, useEffect } from 'react'
import GeneralForm from '../../assets/GeneralForm'
import Server from '../../server/server'
import UniversalTable from '../../assets/UniversalTable'
import LeftAndRightArrowsButtons from '../../assets/LeftAndRightArrowsButtons'
import ModalError from '../../ModalError'

const SearchOptions = () => {
  // console.log(`Component: SearchOptions - run`)

  const searchParameters = [
    { display: 'Наименование предприятия потребителя', key: 'consumerEnterpriseName' },
    { display: 'Наименование установки', key: 'installationName' },
    { display: 'Номер технологической позиции', key: 'technologicalPositionNumber' },
    { display: 'Регистрационный номер в базе', key: 'registrationNumberInDatabase' },
    { display: 'Наименование оборудования', key: 'equipmentName' },
    { display: 'Завод-изготовитель', key: 'manufacturerName' },
    { display: 'Заводской номер аппарата', key: 'deviceSerialNumber' },
    { display: 'Предприятие поставщик', key: 'supplierEnterpriseName' },
    { display: 'Наименование рабочей среды в трубном пространстве', key: 'tubeEnvironmentName' },
    {
      display: 'Наименование рабочей среды в межтрубном пространстве',
      key: 'intertubeEnvironmentName'
    },
    { display: 'Рабочее давление в трубном пространстве', key: 'tubeWorkingPressure' },
    { display: 'Рабочее давление в межтрубном пространстве', key: 'intertubeWorkingPressure' }
  ]

  const searchParamsObject = {
    consumerEnterpriseName: '',
    installationName: '',
    technologicalPositionNumber: '',
    registrationNumberInDatabase: '',
    equipmentName: '',
    manufacturerName: '',
    deviceSerialNumber: '',
    supplierEnterpriseName: '',
    tubeEnvironmentName: '',
    intertubeEnvironmentName: '',
    tubeWorkingPressure: '',
    intertubeWorkingPressure: ''
  }

  // const [params, setParams] = useState(searchParamsObject)
  const [companiesArray, setCompaniesArray] = useState([])
  const [searchData, setSearchData] = useState({})
  const [searchResult, setSearchResult] = useState([])

  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [show_errorRequest, setShow_errorRequest] = useState(false); // error AUTH

  // const [idForQuestionary, setIdForQuestionary] = useState(false)

  const getPagedData = () => {
    const newEquipmentArray = searchResult.slice()
    const start = currentPage * pageSize
    const end = start + pageSize
    return newEquipmentArray.slice(start, end)
  }

  const goToNextPage = () => {
    setCurrentPage((prevPage) => {
      const maxPages = Math.ceil(searchResult.length / pageSize)
      if (prevPage < maxPages) {
        return prevPage + 1
      }
      return null
    })
  }

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => {
      if (prevPage > 0) {
        return prevPage - 1
      }
      return null
    })
  }

  useEffect(() => {
    ; (async () => {
      try {
        const result = await Server.searchAllNameCompany()
        // console.log(`result >>>>> `, result) // test
        setCompaniesArray(result)
      } catch (err) {
        if (Server.errorRequest()) {
          console.log(`ERROR AUTH -> SearchOptions`);
          setShow_errorRequest(true)
          setTimeout(() => { setShow_errorRequest(false) }, 3000)
        }

        console.log(
          `Ошибка при получении названий всех компаний в базе (ContentAddNewEquipment.jsx): `,
          err
        )
      }
    })()
  }, [])

  /**
   * Поиск карточки в реестре аппаратов
   * @param {Object} data
   * @returns {Array} result: Массив объектов
   */
  const getSearchData = async (data) => {
    try {
      setSearchData(data)
      // console.log(`DATA::::::::::: `, data); // test

      const result = await Server.searchEquipmentCardData(data)
      // console.log(`RESULT: `, result);
      const newResalt = result.map((el) => el.data)
      // console.log(`RESULT: `, result);
      setSearchResult(newResalt)
      return result
    } catch (err) {
      if (Server.errorRequest()) {
        console.log(`ERROR AUTH -> SearchOptions`);
        setShow_errorRequest(true)
        setTimeout(() => { setShow_errorRequest(false) }, 3000)
      }

      console.log(`Ошибка поиска карточки в реестре аппаратов: `, err)
      return null
    }
  }

  // console.log(`searchResult:::: `, searchResult); // test

  return (
    <>
      {show_errorRequest ?
        <ModalError />
        : ''
      }
      <div style={{ padding: '80px 150px 20px 150px' }}>
        <GeneralForm
          renderedArray={searchParameters}
          // selectData={[0, 5, 8]}
          buttons={['search', 'clear']}
          object={searchParamsObject}
          headingText='Поиск оборудования по параметрам'
          arrayForSelect={companiesArray}
          sendData={getSearchData}
          mainFields={[]}
          selectData={[{ select: companiesArray, indexes: [0, 5, 7] }]}
          selectIndexes={[0, 5, 7]}
          jsonDataSTHE={[]}
          jsonDataTender={[]}
        // autoFields={['STHEid', 'tenderId']}
        />
        &nbsp; &nbsp; &nbsp;
        {searchResult.length ? (
          <UniversalTable
            key={Math.random()}
            isRowGreen={false}
            removable={false}
            selectable={false}
            selectable1={false}
            selectable2={false} //для открытия анкет
            selectableCheck={false} // Добавляет check box
            openQuestionnairy={() => { }} //для открытия анкет
            tableName={'Результат поиска'}
            titleObjectData={{
              numbered: 'п/п',
              consumerEnterpriseName: 'Наименование предприятия потребителя',
              deviceSerialNumber: 'Заводской номер аппарата',
              manufacturerName: 'Завод-изготовитель',
              equipmentName: 'Наименование оборудования',
              registrationNumberInDatabase: 'Регистрационный номер в базе',
              technologicalPositionNumber: 'Номер технологической позиции'
            }}
            // renderedArray={data}
            // renderedArray={searchResult}
            renderedArray={getPagedData()}
            renderedArrayWithAdded={() => { }}
            chosenObjectsArray={() => { }}
            chosenIdsArray={() => { }}
            chosenObjectId={() => { }}
            handleCancel={() => { }}
            handleModalToggle={() => { }}
            updateObjectWithAdded={() => { }}
            handleClickPosition={(e, position, data) => {
              console.log(`ID::: ${e} >>>> ${position} <<<<<< ::::  `, data)
            }}
          />
        ) : (
          ''
        )}
        {searchResult.length ? (
          <LeftAndRightArrowsButtons
            nextPageClick={goToNextPage}
            prevPageClick={goToPreviousPage}
            needDisableButtons={true}
            disableNextButtonCondition={currentPage >= Math.ceil(searchResult.length / pageSize) - 1}
            disablePrevButtonCondition={currentPage === 0}
          />
        ) : (
          ''
        )}
      </div>
    </>
  )
}

export default SearchOptions

// consumerEnterpriseName:"ОАО Chempioil"
// consumerResponsibleParty: "Петр Иванович Петров"
// deviceSerialNumber: "333"
// equipmentName: "Оборудование 3 ПКУ6"
// id: "P257VPyyFQHFOwMmoaSc4"
// installationName: "Установка 3 ПКУ6"
// intertubeEnvironmentName: "Среда мж пртва"
// intertubeWorkingPressure: "56"
// manufacturerName: "ЗАО Мое Предприятие"
// registrationNumberInDatabase: "6565 "
// supplierEnterpriseName: ""
// supplierResponsibleParty: "Иванов Петр Иванович"
// technologicalPositionNumber: "55353 0898"
// tubeEnvironmentName: "Нафталин"
// tubeWorkingPressure: "56"
