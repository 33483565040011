import { Row, Button } from 'react-bootstrap';
import React from 'react';
import Server from './server/server';
import { shortenName } from './assets/shortenStringsFunction';

export default class AccessibleCompany extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: false,
            base: []
        }
        this.handleClickChoiceCompany = this.handleClickChoiceCompany.bind(this);
        this.getNameCompanyFromServer = this.getNameCompanyFromServer.bind(this);
        this.renderButtonCompany = this.renderButtonCompany.bind(this);
        this.arrButtonCompany = this.arrButtonCompany.bind(this);
        this.loadPosition = this.loadPosition.bind(this);
    }



    // загружаем позицию в базе выбранного предприятия
    async loadPosition(id) {
        try {
            const positionObject = await Server.searchPosition('global', 'serial', 'inn', id)
            // console.log(`POSITION COMPANY::: `, positionObject); // test

            return (positionObject.serial);
        } catch (err) {
            console.log(`Позиция предприятия не найдена: `, err);
            return 0;
        }
    }

    async handleClickChoiceCompany(id) {
        // console.log(' click - choce company ', id);//test
        const serial = await this.loadPosition(id);
        localStorage.setItem('serial', serial);
        this.props.onReturnIdCompany(id);
    }

    async getNameCompanyFromServer(id) {
        try {
            const result = await Server.getDataFromServer('Main', id);

            if (id.length === 1) {
                // console.log(' --------------- ', result);//test
                if (result[1].value) {
                    this.arrButtonCompany(result[1].value, id);
                } else {
                    this.arrButtonCompany('нет названия', id);
                }
            }

            // если в запросе более одного предприятия
            if (id.length > 1) {
                result.forEach((arrData, index) => {
                    this.arrButtonCompany(arrData[1].value, id[index])
                })
            }
            await this.renderButtonCompany();

        } catch (err) {
            // console.log(err); // test
            this.arrButtonCompany('нет названия', id);
        }

    }

    arrButtonCompany(name, id) {
        this.arrButton = [...this.arrButton, { nameCompany: name, idCompany: id }]
    }

    renderButtonCompany() {
        this.dataV = [];
        // console.log(`this.arrButton >>>> `, this.arrButton);//test
        this.arrButton.forEach(data => {
            const processedName = shortenName(data.nameCompany, 20)
            this.dataV = [...this.dataV,
            <div key={data.nameCompany} >
                {/* <div > */}
                <Button
                    variant={data.idCompany === localStorage.getItem('idUser') ? "primary" : "outline-primary"}
                    onClick={() => { this.handleClickChoiceCompany(data.idCompany) }}
                    style={{ marginLeft: '6px', minWidth: '150px' }}
                >
                    {processedName}
                </Button>
                {/* </div> */}
                <Row> &nbsp;</Row>
            </div>
            ]
        })

        // console.log(this.dataV);//tast
        this.setState({ base: this.dataV })
    }

    renderCompany(idCompany) {
        this.arrButton = [];

        // console.log(`idCOMPANY:::: `, idCompany); // test

        if (idCompany) {
            this.getNameCompanyFromServer(idCompany);
        }

        // setTimeout(() => { this.renderButtonCompany() }, 2000)
    }

    componentDidMount() {
        this.renderCompany(this.props.idCompany);
    }

    render() {

        // console.log(localStorage.getItem('idUser'));
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {this.state.base}
            </div>
        )
    }
}