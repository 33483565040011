import React from 'react'
import { Row } from 'react-bootstrap'
import ModalWindowTrade from '../assets/ModalWindowTrade'
import Server from '../server/server'
import ConfirmationLot from './ConfirmationLot'
import { categorizeTrades, doDateData } from './doDateData'
import WriteTableTrade from './WriteTableTrade'
import { loadTradeTable } from '../trade/loadTradeTable'
import { RenderModalBody } from '../trade/RenderModalBody'
import { RenderModalBodyReduction } from '../trade/RenderModalBodyReduction'
import changeTimeToLocal from '../assets/changeTimeToLocal'
import ModalError from '../ModalError'

export default class TradeParticipant extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      noShow: true,
      timezone: '',
      tradeLot: [],
      resultTradeDate: [],
      showTable: false,
      showConfirmation: false,
      show_errorRequest: false,
      idLot: '',
      showTradesModal: false,
      modalContent: 'Содержимое модального окна Участника торгов',
      dateTradeTimer: 0,
      dateTradeMs: 0,
      allLotData: {},
      dataStart: false, //! со стороны участника до окончания торгов - false
      count: 450,
      percentErrorPlus: false,
      percentErrorMinus: false,
      showLoader: false
    }

    this.loadDataTrade = this.loadDataTrade.bind(this)
    this.doQuestionnairy = this.doQuestionnairy.bind(this)
    this.toggleConfirmationLot = this.toggleConfirmationLot.bind(this)
    this.toggleTradesModal = this.toggleTradesModal.bind(this)
    this.doReRender = this.doReRender.bind(this)

    this.doStopTrade = this.doStopTrade.bind(this)
    this.renderTradeTable = this.renderTradeTable.bind(this)

    this.doUpdateTable = this.doUpdateTable.bind(this)
    this.setPercentErrorPlus = this.setPercentErrorPlus.bind(this)
    this.setPercentErrorMinus = this.setPercentErrorMinus.bind(this)

    this.rerenderOnBtnClick = this.rerenderOnBtnClick.bind(this)
  }

  /**
   * Перерендер по нажатию кнопки - Стать первым
   * @param {*} lot Объект лота
   */
  rerenderOnBtnClick(lot) {
    this.doReRender(lot)
  }

  setPercentErrorMinus(isLess) {
    this.setState({
      percentErrorMinus: isLess
    })
  }

  setPercentErrorPlus(isMore) {
    this.setState({
      percentErrorPlus: isMore
    })
  }

  // * обновление таблицы
  doUpdateTable() {
    ;(async () => {
      this.setState({ showTable: false })
      await this.loadDataTrade()
      console.log(`UPDATE in TradeParticipant`) // test
    })()
  }

  // *** формирование верски сводной таблицы, торги
  renderTradeTable(objTable, objLot, objChange, onLook, lot, member) {
    // console.log(`----> objTable::: `, objTable); // test
    // console.log(`----> objLot::: `, objLot); // test
    // console.log(`----> objChange::: `, objChange); // test

    // todo: Проверка работы с первым входом в торги
    // *** заполнение objChange при отсутствии данных в нем
    if (!objChange && objChange === false) {
      objChange = JSON.parse(JSON.stringify(objTable)) // клонируем объект
    }

    let lott
    let dateTradeTimer
    let dateTradeMs

    if (objLot) {
      this.setState({
        allLotData: objLot.data
      })
    }

    if (!objLot) {
      lott = lot

      this.setState({
        allLotData: lott
      })
    } else {
      lott = objLot.data

      this.setState({
        allLotData: lott
      })
    }

    if (lott && lott.timeTrade) {
      const dateT = Date.parse(lott.dateTrade)
      // todo: перевести lott.timeTrade к локальному времени клиента
      console.log(`changeTimeToLocal ----------------------------->>>>> `, this.state.timezone) // test
      const localTime = changeTimeToLocal(lott.timeTrade, this.state.timezone) //!
      const arrDate = localTime.split(':')
      // const arrDate = lott.timeTrade.split(':') // todo: отдать время переведенное из МСК в локальное
      const dateHourMs = Number(arrDate[0]) * 60 * 60 * 1000
      const dateMinutMs = Number(arrDate[1]) * 60 * 1000
      dateTradeTimer = Number(lot.timer) * 60 * 1000 // время торгов мс
      dateTradeMs = dateT + dateHourMs + dateMinutMs // время начала торгов мс
    }
    console.log(`type trade::: `, objLot?.data?.typeTrade) // test

    if (objLot?.data?.typeTrade === 'reduction') {
      this.setState({
        dateTradeTimer: dateTradeTimer,
        dateTradeMs: dateTradeMs,
        modalContent: (
          <RenderModalBodyReduction
            obj={objTable} // объект Confirmation
            lotFull={objLot} // объект ЛОТа
            objChange={objChange} // новый объект участника с изменениями
            member={member} // disable input в ячейках (true / false)
            keyBlock={false} // включает отображение (всех или одного) (false / true)
            count={this.state.count} // смещение по оси Х
            dataStart={this.state.dataStart} // * отображение занчений при входе участника у других участников (true по умолчанию) // ! менять при окончании торгов
            setPercentErrorPlus={this.setPercentErrorPlus}
            setPercentErrorMinus={this.setPercentErrorMinus}
            rerenderOnBtnClick={this.rerenderOnBtnClick}
          />
        )
      })
    } else {
      this.setState({
        dateTradeTimer: dateTradeTimer,
        dateTradeMs: dateTradeMs,
        modalContent: (
          <RenderModalBody
            obj={objTable} // объект Confirmation
            lotFull={objLot} // объект ЛОТа
            objChange={objChange} // новый объект участника с изменениями
            member={member} // disable input в ячейках (true / false)
            keyBlock={false} // включает отображение (всех или одного) (false / true)
            count={this.state.count} // смещение по оси Х
            dataStart={this.state.dataStart} // * отображение занчений при входе участника у других участников (true по умолчанию) // ! менять при окончании торгов
            setPercentErrorPlus={this.setPercentErrorPlus}
            setPercentErrorMinus={this.setPercentErrorMinus}
          />
        )
      })
    }
  }

  // todo: процедура остановки торгов по окончании времени торгов
  doStopTrade() {
    ;(async () => {
      try {
        console.log(`STOP TRADE`) // test
        const lot = this.state.allLotData

        this.setState({
          dataStart: true
        })
        setTimeout(() => {
          ;(async () => {
            const { objTable, objLot, objChange, onLook } = await loadTradeTable(lot.id)
            this.renderTradeTable(objTable, objLot, objChange, onLook, lot, true)
          })()
        })
      } catch (err) {
        console.log(`Ошибка остановки торгов (TradeParticipant.jsx): `, err)
      }
    })()
  }

  doReRender(lot) {
    ;(async () => {
      this.setState({ showTradesModal: false })
      // console.log(`Обновить. LOT:::  `, lot) // test
      const { objTable, objLot, objChange, onLook } = await loadTradeTable(lot.id)
      this.renderTradeTable(objTable, objLot, objChange, onLook, lot, false)

      this.setState({ showTradesModal: true })
    })()
  }

  toggleTradesModal(lot) {
    ;(async () => {
      window.scrollTo(0, 0)
      this.setState({
        dataStart: false
      })
      const { objTable, objLot, objChange, onLook } =
        !this.state.showTradesModal && (await loadTradeTable(lot.id))

      this.renderTradeTable(objTable, objLot, objChange, onLook, lot, false)

      this.setState({
        dataStart: false,
        showTradesModal: !this.state.showTradesModal
      })

      // console.log(`TOGGLE in TradeCustomer`); // test
    })()
  }

  toggleConfirmationLot() {
    this.setState({ showConfirmation: !this.state.showConfirmation })
  }

  doQuestionnairy(e) {
    this.setState({
      idLot: e,
      showConfirmation: true
    })
  }

  //todo:  загружаем данные из DB о всех лотах относящихся к этой компании
  /**
   * загружаем данные из DB о всех лотах относящихся к этой компании
   */
  // async loadDataTrade() {
  //   try {
  //     this.setState({
  //       showLoader: true
  //     })
  //     const result = await Server.getParticipantTrade('active') // получаем массив всех ЛОТов, где данная компания приглашена на торги

  //     const resultPast = await Server.getParticipantTrade('past')

  //     const tradeLot = result.map((lot) => {
  //       return lot.data.data
  //     })
  //     const tradeLotsPast = resultPast.map((lot) => {
  //       return lot.data.data
  //     })

  //     const reversedTradeLot = [...tradeLot].reverse()
  //     const reversedTradeLotsPast = [...tradeLotsPast].reverse()

  //     // console.log('tradeLot', tradeLot.reverse());
  //     // console.log('tradeLotsPast', tradeLotsPast.reverse());

  //     if (result) {
  //       this.setState({
  //         noShow: false,
  //         tradeLot: reversedTradeLot,
  //         tradeLotsPast: reversedTradeLotsPast
  //       })
  //     }

  //     tradeLot.reverse()
  //     tradeLotsPast.reverse()

  //     console.log('tradeLot', tradeLot)
  //     console.log('tradeLotsPast', tradeLotsPast)

  //     const combinedArray = [...tradeLot, ...tradeLotsPast]

  //     const timezone = await Server.getDataFromServer('timezone', localStorage.getItem('idUser'))
  //     // console.log(`doDateData time zone::::::::::::::::: `, timezone); // test
  //     // const resultTradeDate = doDateData(tradeLot, tradeLotsPast, timezone)
  //     const resultTradeDate = await categorizeTrades(combinedArray)

  //     this.setState({
  //       resultTradeDate: resultTradeDate,
  //       showTable: true
  //     })

  //     setTimeout(() => {
  //       this.setState({
  //         showLoader: false
  //       })
  //     }, 1000)
  //   } catch (err) {
  //     if (Server.errorRequest()) {
  //       console.log(`ERROR AUTH -> TradeParticipant`)
  //       this.setState({ show_errorRequest: true })
  //       setTimeout(() => {
  //         this.setState({ show_errorRequest: false })
  //       }, 3000)
  //     }

  //     console.log('Ошибка при получении данные о торгах в которых выступает как участник: ', err)
  //   }
  // }

  async loadDataTrade() {
    try {
      this.setState({
        showLoader: true
      });
  
      const result = await Server.getParticipantTrade('active'); // получаем массив всех ЛОТов, где данная компания приглашена на торги
      const resultPast = await Server.getParticipantTrade('past');
  
      const tradeLot = result.map((lot) => lot.data.data);
      const tradeLotsPast = resultPast.map((lot) => lot.data.data);
  
      const reversedTradeLot = [...tradeLot].reverse();
      const reversedTradeLotsPast = [...tradeLotsPast].reverse();
  
      this.setState({
        noShow: false,
        tradeLot: reversedTradeLot,
        tradeLotsPast: reversedTradeLotsPast
      });
  
      const combinedArray = [...reversedTradeLot, ...reversedTradeLotsPast];
  
      // console.log('reversedTradeLot', reversedTradeLot);
      // console.log('reversedTradeLotsPast', reversedTradeLotsPast);
      // console.log('combinedArray', combinedArray);
  
      const timezone = await Server.getDataFromServer('timezone', localStorage.getItem('idUser'));
      const resultTradeDate = await categorizeTrades(combinedArray);

      console.log(resultTradeDate);
  
      this.setState({
        resultTradeDate: resultTradeDate,
        showTable: true
      });
  
      setTimeout(() => {
        this.setState({
          showLoader: false
        });
      }, 1000);
    } catch (err) {
      if (Server.errorRequest()) {
        console.log(`ERROR AUTH -> TradeParticipant`);
        this.setState({ show_errorRequest: true });
        setTimeout(() => {
          this.setState({ show_errorRequest: false });
        }, 3000);
      }
  
      console.log('Ошибка при получении данных о торгах, в которых выступает как участник:', err);
    }
  }

  async componentDidMount() {
    await this.loadDataTrade()
    try {
      const timezone = await Server.getDataFromServer('timezone', localStorage.getItem('idUser'))
      this.setState({ timezone: timezone.timezone })
    } catch (err) {
      if (Server.errorRequest()) {
        console.log(`ERROR AUTH -> TradeParticipant`)
        this.setState({ show_errorRequest: true })
        setTimeout(() => {
          this.setState({ show_errorRequest: false })
        }, 3000)
      }

      console.log(`ERROR: `, err)
    }
  }

  render() {
    const showTable = this.state.showTable
    const organizerId = this.state.allLotData && this.state.allLotData.organizerId

    return (
      <>
        {this.state.show_errorRequest ? <ModalError /> : ''}
        <div className='wrapper_lot_participant'>
          {this.state.showTradesModal && (
            <ModalWindowTrade
              asParticipant={true}
              toggleModal={this.toggleTradesModal}
              alertHeading='Данные торгов'
              modalContent={this.state.modalContent}
              reRender={this.doReRender}
              buttonOn={false}
              dateTradeTimer={this.state.dateTradeTimer}
              dateTradeMs={this.state.dateTradeMs}
              lot={this.state.allLotData && this.state.allLotData}
              forName={organizerId}
              idCompany={[organizerId]}
              // filterAll={[organizerId]}
              filterAll={this.state.allLotData && [String(this.state.allLotData.organizerId)]}
              doStopTrade={this.doStopTrade}
              percentErrorPlus={this.state.percentErrorPlus}
              percentErrorMinus={this.state.percentErrorMinus}
              isOpen={this.state.showTradesModal}
            />
          )}

          <Row> &nbsp; </Row>

          {showTable && (
            <WriteTableTrade
              arrAllTrade={this.state.resultTradeDate}
              doQuestionnairy={this.doQuestionnairy}
              toggleTradesModal={this.toggleTradesModal}
              doUpdateTable={this.doUpdateTable}
              showLoader={this.state.showLoader}
            />
          )}

          {this.state.showConfirmation && (
            <ConfirmationLot
              idLot={this.state.idLot}
              toggleConfirmationLot={this.toggleConfirmationLot}
              doReLoadTable={this.doUpdateTable}
            />
          )}

          {this.state.noShow && (
            <div style={{ color: '#dc3545' }}>У Вас сейчас нет приглашений на торги</div>
          )}
        </div>
      </>
    )
  }
}
