import Server from '../../server/server'


/**
 * Обновление данных на сервере
 * @param {*} data - объект с новыми данными
 * @param {*} id  - ID документа
 */
const updateCard = async (data, id) => {
    try {
        const result = await Server.sendDataOnServer(data, `card_${id}`, localStorage.getItem('idUser'))

    } catch (err) {
        console.log(`Ошибка обновления карточки: `, err);
    }
}

export default updateCard;