import { useState, useEffect } from "react"

function FormattedDate({ dataDocumNew, idx, formattedDate }) {
    const [date, setDate] = useState("")


  
    useEffect(() => {
      setDate(formattedDate(dataDocumNew, idx))
    }, [dataDocumNew, idx]) 
  
    return <>{date} </>
  }

  export default FormattedDate