/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Card } from "react-bootstrap";
import { icons } from "./ConstIcons";
import { CircularProgressWithLabel } from "../assets/CircularProgressWithLabel";
import Server from "../server/server";
import ModalError from '../ModalError'

export default class QuestionnaireButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "rus",
      show: false,
      show_errorRequest: false,
      base: [],
      base1: [],
      choice: "", // выбор вывода кнопок в форму, по производству
      fillingProcent: [], //число, процент на который заполнена анкета
      test: "", //строка, название формы (например: "Main")
      json: [],
      formInfo: [],
      array: [],
      urlBtn: '',
    };
    this.getBtnForQuest = this.getBtnForQuest.bind(this);
    this.renderBtn = this.renderBtn.bind(this);
    this.handleClickView = this.handleClickView.bind(this);
  }

  handleClickView(e) {
    this.props.onClickView(e);
  }

  async getBtnForQuest(choice) {
    try {
      // const URLget = document.location.host;
      // console.log(`URL: `, URLget); // test
      // console.log(`Q_BUTTON, choice::: `, choice);  // test
      // console.log(`this.state.urlBtn:::: `, this.state.urlBtn); // test
      // console.log(`URLget::::: `, URLget);// test
      // this.setState({ urlBtn: `https://${URLget}/data/btnForQuestionnaire.json` })
      let response = await fetch(this.state.urlBtn);
      // console.log(`response>>>>> `, response); // test

      if (!response) throw new Error('https: -> http:')

      let dataJson = await response.json();
      // console.log(`RESULT BTN::: `, dataJson); // test

      this.setState({ json: dataJson });

      const filling = await Server.fillingVolumeForms(choice);

      // console.log(`FILLING::::: `, filling); // test

      if (filling.err) {
        throw new Error(filling.err);
      }

      await this.setState({
        formInfo: filling,
      });

      setTimeout(() => {
        // console.log(`RESULT FILLING: `, filling); // test
        this.renderBtn(dataJson, filling);
      }); // поместим в очередь
    } catch (err) {
      if (Server.errorRequest()) {
        console.log(`ERROR AUTH -> CompanySearch`);
        this.setState({ show_errorRequest: true })
        setTimeout(() => { this.setState({ show_errorRequest: false }) }, 3000)

      } else {
        const URLget = document.location.host;
        this.setState({ urlBtn: `http://${URLget}/data/btnForQuestionnaire.json` })
        await this.getBtnForQuest(this.props.choice);

        console.log(`Переход на http: : `, err);
      }

    }
  }

  renderBtn(dataJson, fill) {
    this.dataD = [];
    this.fillResult = 0;

    // console.log(`this.props.choice >>>>>>> `, this.props.choice); // test
    // this.colorVariant = 'secondary'
    dataJson.forEach((data, index) => {

      if (
        this.props.choice.includes(data.name) ||
        this.props.choice.includes(data.choice)
      ) {
        if (fill && fill.length > 0) {
          // console.log(`FILLING true`); // test

          this.fillResult = 0;
          fill.forEach((item) => {

            if (item) {

              if (Object.keys(item)[0] === data.name) {
                this.fillResult = Object.values(item)[0];

                if (this.fillResult > 100) this.fillResult = 100; // * правка переполнения процента заполнения (костыль!)

                if (data.name === 'EconomicData') {
                  this.props.onSendEconomicFilling(this.fillResult)
                }
                // console.log(`this.fillResult :::${data.name}::: `, this.fillResult); // test

              }
            }
          });
        }
        //сетаем в стейт процент заполненности анкеты

        if (!fill[0] || fill[0] === null || fill[0] === 0) {
          // console.log(`FILLING false`); // test
          this.setState({
            fillingProcent: 0,
          });
        } else {
          this.setState({
            fillingProcent: Object.values(fill[0])[0],
          });
        }

        if (index !== 35) {
          this.dataD = [
            ...this.dataD,
            <Card
              key={index}
              className={`${this.props.classEmpty[data.id]} btn_card_style`}
              data-name={data.name}
              data-index={index}
              onClick={this.handleClickView}
            >
              <Card.Header
                data-name={data.name}
                data-index={index}
                className={
                  this.props.classEmpty[data.id] === "btn_form active"
                    ? "btn_form_header active"
                    : "btn_form_header"
                }
              >
                <div style={{ display: "flex" }} data-name={data.name}>
                  <span data-name={data.name} style={{ marginRight: "auto" }}>{icons[index]}</span>
                  <div data-name={data.name} style={{ position: 'absolute', width: '50px', height: '40px', left: '135px', }}></div>
                </div>
              </Card.Header>

              <Card.Body className="btn_form_body" data-name={data.name}>
                <span className="btn_form_title" data-name={data.name}>
                  {data.title}
                </span>

                <div
                  className="check"
                  data-name={data.name}
                >
                  <CircularProgressWithLabel
                    data={data.name}
                    size={35}
                    value={this.fillResult >= 0 ? this.fillResult : 0}
                  />
                </div>
              </Card.Body>
            </Card>,
          ];
        }
      }
      this.setState({ test: data.name });
    });

    this.setState({ base: this.dataD });
  }

  async componentDidMount() {
    const URLget = document.location.host;
    this.setState({ urlBtn: `https://${URLget}/data/btnForQuestionnaire.json` })

    await this.setState({ choice: this.props.choice });
    await this.getBtnForQuest(this.props.choice);


    //отправляем родителю инф о проценте заполненности анкеты и ее название
    await this.props.getPercentAndFormName(
      this.state.fillingProcent,
      this.state.test
    );
  }

  render() {
    return (
      <>
        {this.state.show_errorRequest ?
          <ModalError />
          : ''
        }
        <div className="container_questionairy_btn">{this.state.base}</div>
      </>
    )
  }
}
