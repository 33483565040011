
/**
 * Формируем массив с объектами итоговой цены
 * @param {Object} objChange 
 * @returns 
 */
const getObjectTotalPrice = (objChange) => {

    console.log(`objChange for change>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> `, objChange); // test

    const totalPrice = Object.keys(objChange).map(key => {

        const position = objChange[key].tradePosition

        return (
            {
                companuInn: key, total: objChange[key].total, trade: position
            }
        )
    })

    const minPrice = _getMinTotalPrice(totalPrice)
    const minPriceCompany = totalPrice.filter(elem => elem.total === minPrice)
    console.log(`RESULT min price:::::::::: `, minPriceCompany); // test
    return minPriceCompany
};

export default getObjectTotalPrice;


/**
 * получаем ИТОГОВУЮ минимальную цену
 * @param {Array} totalPrice 
 */
const _getMinTotalPrice = (totalPrice) => {
    const arrTotal = totalPrice.map(itm => {
        return Number(itm.total)
    })
    return Math.min(...arrTotal)
}