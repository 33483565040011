import React from 'react';
import { Col, Form } from 'react-bootstrap';

export default class InputForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            language: 'rus',
            id: '',
            fid: '',
            description: '',
            information: '',
            value: '',
            colorError: '#fff'
        }
        this.handleChange = this.handleChange.bind(this);
        this.erasingError = this.erasingError.bind(this);
        this.handleFous = this.handleFous.bind(this);
    }

    handleFous() {
        this.props.onFocus();
    }

    async handleChange(e) {
        e.preventDefault();
        if (this.props.verify === 'number') {
            this.verificationNumber(e)
        } else {
            this.setState({ value: e.target.value })
        }

        await this.setState({
            id: e.target.dataset.id,
            fid: e.target.dataset.fid,
            description: e.target.dataset.description,
            information: e.target.dataset.information
        })
        this.props.onChangeValue(this.state);
    }

    verificationNumber(e) {
        // todo заменяем запятую на точку .replace(/,/, '.')
        if (isFinite(e.target.value.replace(/,/, '.'))) {
            this.setState({ value: e.target.value.replace(/,/, '.'), colorError: '#fff' })
        } else {

            console.log('error');
            this.setState({ colorError: '#dc3545' })
            this.erasingError();
        }
    }

    erasingError() {
        setTimeout(() => { this.setState({ colorError: '#fff' }) }, 500)
    }

    componentDidMount() {
        this.setState({ value: this.props.value })
    }

    render() {
        const width = this.props.width;
        const label = this.props.placeholder;
        const id = this.props.id;
        const description = this.props.description;
        const placeholder = this.props.placeholder;
        const value = this.state.value;
        const show = this.props.show;
        const className = this.props.className;

        return (
            <React.Fragment key={id}>
                <Col sm={width} style={{ display: show ? 'block' : 'none' }} className={className ? className : ''}>


                    <Form.Label className='select_form_label'>{label}</Form.Label>
                    <Form.Control
                        style={{
                            backgroundColor: `${this.state.colorError}`
                        }}
                        type="text"
                        data-id={id}
                        data-fid={`${this.props.nameForm}_${this.props.id}`}
                        placeholder={placeholder}
                        data-information={placeholder}
                        data-description={description}
                        value={value}
                        onChange={this.handleChange}
                        onFocus={this.handleFous}
                        readOnly={this.props.readOnly ? true : false}

                    />
                </Col>
            </React.Fragment>
        )
    }
}