import React, { useState, useEffect } from 'react'

const TimerTimeLeft = ({ initialTimeLeft }) => {
  const [timeLeft, setTimeLeft] = useState(initialTimeLeft)

  const convertSecondsToHMS = (seconds) => {
    const hours = Math.floor(seconds / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)
    const remainingSeconds = seconds % 60

    return [hours, minutes, remainingSeconds]
      .map((value) => String(value).padStart(2, '0'))
      .join(':')
  }

  useEffect(() => {
    if (isNaN(timeLeft) || timeLeft <= 0) return

    const intervalId = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timeLeft])

  const formattedTimeLeft = convertSecondsToHMS(timeLeft)

  return <div>{formattedTimeLeft}</div>
}

export default TimerTimeLeft
