import React, { useEffect, useState } from 'react'
import { EMPLOYEE_ALERT_TRADE_TITLE } from '../constAlert'
import CheckForm from '../../questionnaire/CheckForm'

/**
 * Контекст доступа сотрудников
 */
export default function EmployeeAlertContex({ modalContent, valueData, sendAlertContex }) {

    console.log(`VALUE >>>> `, valueData); // test
    const [value, setValue] = useState(valueData?.alert)
    const [show, setShow] = useState(true)

    useEffect(() => {
        if (valueData && valueData.length > 0) {
            setValue(valueData.alert)
            console.log(`is value ->`);
            console.log(`valueDATA::::: `, valueData.alert); // test
        } else {
            setValue(_createObjectFirst())
            sendAlertContex(_createObjectFirst())
            console.log(`no value`); // test
        }

    }, [valueData])


    /**
     * Формирование объекта оповещения при входе
     */
    const _createObjectFirst = () => {
        let resultAlert = []
        let indexG = 0

        EMPLOYEE_ALERT_TRADE_TITLE.forEach((item) => {
            resultAlert.push(
                {
                    description: Object.keys(item)[0],
                    fid: `undefined_${indexG}`,
                    id: `${indexG}`,
                    information: String(Object.values(item)[0]),
                    language: "rus",
                    placeholder: String(Object.values(item)[0]),
                    value: true
                }
            )

            indexG++

            item.list.forEach((itm) => {
                resultAlert.push(
                    {
                        description: Object.keys(itm)[0],
                        fid: `undefined_${indexG}`,
                        id: `${indexG}`,
                        information: String(Object.values(itm)),
                        language: "rus",
                        placeholder: String(Object.values(itm)),
                        value: true
                    }
                )

                indexG++
            })
        })

        console.log(`global RESULT::: `, resultAlert); // test

        return resultAlert
    }

    const doChangeValue = (e) => {
        console.log(`e:::: `, e); // test
        console.log(e.description);
        console.log(e.placeholder);

        const result = value.filter(item => item.id !== e.id)
        result.push(e)
        setValue(result)
        console.log(`result::: `, result); // test
        sendAlertContex(result)
    }

    const doChangeVisionBlock = (e) => {
        console.log(`check::: `, e); // test
        // setShow(e)

    }

    const doChangeVisionBlockEmpty = () => { }

    let indexG = 0;
    console.log(value);
    return (
        
        <>
            {/* <div>
                *** Массив пунктов оповещения с чекбоксами (компонент)
            </div> */}
            <div >
                {
                    EMPLOYEE_ALERT_TRADE_TITLE.map((item, index) => {
                        ++indexG
                        return (
                            <React.Fragment key={index} >
                                <div>
                                    <div
                                        style={{ fontSize: '1.1rem', fontWeight: '400'}}
                                    >
                                        <CheckForm
                                            className={''}
                                            id={indexG - 1}
                                            width={10}
                                            show={true}
                                            placeholder={String(Object.values(item)[0])} //* подпись LABEL для чекбокса
                                            description={Object.keys(item)[0]}
                                            value={value && value[indexG - 1] ? value[indexG - 1].value : true}
                                            onChangeVisionBlock={doChangeVisionBlock}
                                            onChangeValue={doChangeValue}
                                        // nameForm={this.state.nameForm}
                                        />
                                    </div>
                                </div>
                                <div>
                                    {
                                        item.list.map((itm, inx) => {
                                            ++indexG
                                            return (
                                                <React.Fragment key={index + inx}>
                                                    <div
                                                        style={{ marginLeft: '20px'}}
                                                    >
                                                        <CheckForm
                                                            className={''}
                                                            id={indexG - 1}
                                                            width={10}
                                                            show={show}
                                                            placeholder={String(Object.values(itm))} //* подпись LABEL для чекбокса
                                                            description={Object.keys(itm)}
                                                            value={value && value[indexG - 1] ? value[indexG - 1].value : true}
                                                            onChangeVisionBlock={doChangeVisionBlockEmpty}
                                                            onChangeValue={doChangeValue}
                                                        // nameForm={this.state.nameForm}
                                                        />
                                                    </div>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </div>
                            </React.Fragment>

                        )
                    })
                }
            </div>
        </>
    )
}

