
// dateLater - бОльшая дата, из нее вычитаем (не в формате даты, этот процесс происходит в самой функции)
// dateSooner - мЕньшая дата, вычетаем ее из бОльшей (не в формате даты, этот процесс происходит в самой функции)

export const setDaysDifference = (dateLater, dateSooner, datesError) => {
    if (!dateLater || !dateSooner) {
        return {
            stringMessage: 'Итого разница: ___ дней',
            daysDifference: null
        };
    }

    try {
        const biggerDate = new Date(dateLater);
        const smallerDate = new Date(dateSooner);

        if (isNaN(biggerDate.getTime()) || isNaN(smallerDate.getTime())) {
            return {
                stringMessage: 'Invalid date format',
                daysDifference: null
            };
        }

        const timeDiff = Math.abs(smallerDate.getTime() - biggerDate.getTime());
        const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

        let stringMessage = `Итого разница: ${daysDiff}`;
        if (timeDiff === 0) {
            stringMessage = 'Итого разница: 0 дней';
        }

        return {
            stringMessage,
            daysDifference: daysDiff
        };

    } catch (error) {
        console.log('Ошибка при вычислении разницы между датами:', error);
        return {
            stringMessage: 'In an error block',
            daysDifference: null
        };
    }
};

// export const setDaysDifference = (dateLater, dateSooner, datesError) => {

//     if(!dateLater || !dateSooner){
//         return {
//             stringMessage: 'Итого разница: ___ дней',
//             daysDifference: null
//         }
//     }

//     try {
//          const biggerDate = new Date(dateLater)
//         const smallerDate = new Date(dateSooner)

//         const timeDiff = Math.abs(smallerDate.getTime() - biggerDate.getTime())
//         const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))

//     if (biggerDate && smallerDate) {
//       const d = smallerDate.getTime() - biggerDate.getTime()
//       if (d > 0) {
//         return {
//             stringMessage: `Итого разница: ${this.setCorrectWordForm(daysDiff)}`,
//             daysDifference: daysDiff
//         }
//       }
//     }

//     if (datesError) {
//         return {
//             stringMessage: ``,
//             daysDifference: null
//         }
//     }

//     if (!dateLater || !dateSooner) {
//         return {
//             stringMessage: 'Итого разница: ___ дней',
//             daysDifference: null
//         }
//     }

//     if (timeDiff === 0) {
//         return {
//             stringMessage: 'Итого разница: 0 дней',
//             daysDifference: null
//         }
//     }
//     } catch(error){
//         console.log('Ошибка при вычислении разницы между датами:', error);
//         return {
//             stringMessage: 'There seems to be a problem',
//             daysDifference: null
//         }
//     }
   
//   }