import React, { useState, useEffect } from 'react'
import { ITEM_LIST, ITEM_LIST_KEY, CARD_EQUIPMENT } from './constCardEquipment'
import InputDataText from './InputDataText'
import SelectData from './SelectData'
import Server from '../../server/server'
import { SaveCurrentDataButton } from '../../assets/BasicButtons'
import { Container } from 'react-bootstrap'

/**
 * Контент добавления новой карты аппарата
 * @param {*} param0
 * @returns
 */
const ContentAddNewEquipment = ({ doSaveCard, setShowTable }) => {
  const [card, setCard] = useState(CARD_EQUIPMENT)
  const [company, setCompany] = useState()
  const [showSavedNotice, setShowSavedNotice] = useState(false)

  useEffect(() => {
    ; (async () => {
      try {
        const result = await Server.searchAllNameCompany()
        console.log(`result >>>>> `, result) // test
        setCompany(result)
      } catch (err) {
        console.log(
          `Ошибка при получении названий всех компаний в базе (ContentAddNewEquipment.jsx): `,
          err
        )
      }
    })()
  }, [])

  // const handleChangeInput = (e) => {
  //   console.log(e.target.value) // test
  //   card[e.target.dataset.key] = e.target.value
  //   setCard(card)
  // }

  const handleChangeInput = (e) => {
    console.log(`DATA :::::: `, e.target.value) // test
    const arrValue = e.target.value.split(' ')
    let value;

    if (arrValue.length === 1) {
      value = e.target.value
    } else {
      arrValue.shift()
      value = arrValue.join(' ')
    }

    console.log(`company::: `, company); // test
    console.log(`DATA SET :::::: `, company.find(item => (item.nameCompany.match(value)))) // test
    const updatedCard = { ...card, [e.target.dataset.key]: e.target.value }
    setCard(updatedCard)
  }

  const setSavedNoticeInButton = () => {
    setShowSavedNotice(true)
    setTimeout(() => {
      setShowSavedNotice(false)
    }, 1500)
  }

  const handleClickSave = () => {
    // setShowTable(false)
    setSavedNoticeInButton()
    doSaveCard(card)
    // setTimeout(() => {
    //   setShowTable(true)
    // })
  }

  const isAnyFieldFilled = () => {
    for (let key in card) {
      if (card[key]) {
        return true
      }
    }
    return false
  }

  return (
    <Container style={{padding: '20px 50px 0'}}>
      {ITEM_LIST.map((item, index) => {
        return (
          <React.Fragment key={ITEM_LIST_KEY[index]}>
            {index === 1 || index === 6 || index === 8 ? (
              <SelectData
                index={ITEM_LIST_KEY[index]}
                label={`${index + 1}.  ${item}:`}
                placeholder={`Введите: ${item}`}
                text={'Необходимо к заполнению*'}
                arr={company}
                keyData={ITEM_LIST_KEY[index]}
                handleChangeInput={handleChangeInput}
              // value={value} // использовать при редактировании значений
              />
            ) : (
              <InputDataText
                index={ITEM_LIST_KEY[index]}
                label={`${index + 1}.  ${item}:`}
                placeholder={`Введите: ${item}`}
                text={'Необходимо к заполнению*'}
                keyData={ITEM_LIST_KEY[index]}
                handleChangeInput={handleChangeInput}
                idx={index}
              // value={value} // использовать при редактировании значений
              />
            )}
            <div style={{ width: '100%', height: '20px' }}> </div>
          </React.Fragment>
        )
      })}
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
          {/* <SaveButton
            handleSaveClick={handleClickSave}
            needDisable={false}
            saveButtonText='Сохранить'
          /> */}
          <SaveCurrentDataButton
            handleSaveClick={handleClickSave}
            needDisable={true}
            disableCondition={!isAnyFieldFilled()}
            savedDataMessage={showSavedNotice}
            buttonTextDefault={'Сохранить'}
            buttonTextSaved={'Данные сохранены'}
            needCustomIcon={false}
            needCustomStyle={false}
          />
        </div>
      </div>
    </Container>
  )
}

export default ContentAddNewEquipment
