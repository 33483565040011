import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import FormControlInputOrSelect from '../assets/FormControlInputOrSelect'
import { steelARR } from './constants/namePositionCoastHeatExchagers'
import { AddButton, DeleteButton } from '../assets/BasicButtons'

/**
 * Рендер Компонента - Форма материалы
 * @param {*} param0
 * @returns
 */
const FormMaterials = ({ arr, returnObject, data }) => {
  // category, noteMaterials, steelGrade, totalWeight

  const [ARR, setARR] = useState(arr)
  const [obj, setObj] = useState({})
  const [pos, setPos] = useState()
  const [click, setClick] = useState()
  const [arrMaterials, setArrMaterials] = useState([])

  useEffect(() => {
    if (data.arrMaterials) {
      setArrMaterials(data.arrMaterials)
    }
  }, [data])

  useEffect(() => {
    console.log(`RE RENDER`) // test
  }, [pos, click])

  const doCommonObject = (e) => {
    console.log(`E::: `, e) // test
    console.log(`ARR::: `, ARR) // test

    const arrCategory = arr[0].arr
    const position = arrCategory.indexOf(e.category)
    console.log(`POSITION::: `, position) // test

    if ([0, 2, 4, 6, 10, 11].includes(position)) {
      ARR[1].arr = steelARR[0]
      setPos(position)
      setARR(ARR)
    } else if ([1, 3, 5, 7].includes(position)) {
      ARR[1].arr = steelARR[1]
      setPos(position)
      setARR(ARR)
    } else if ([8, 9, 12].includes(position)) {
      ARR[1].arr = steelARR[0].concat(steelARR[1])
      setPos(position)
      setARR(ARR)
    } else if ([13].includes(position)) {
      ARR[1].arr = ['-']
      setPos(position)
      setARR(ARR)
    }

    Object.assign(obj, e)
    setObj(obj)
    // returnObject(obj)
    console.log(`obj in materials: `, obj) // test
    console.log(`DATA in materials: `, data) // test
  }

  const handleClick = () => {
    console.log(`CLICK -> + Добавить`) // test
    const arrObj = {
      category: obj.category,
      noteMaterials: obj.noteMaterials,
      steelGrade: obj.steelGrade,
      totalWeight: obj.totalWeight
    }
    console.log(`MATERIALS::::: `, arrMaterials) // test
    console.log(`arrObj:::: `, arrObj) // test

    arrMaterials.push(arrObj)
    setArrMaterials(arrMaterials)

    returnObject({ arrMaterials: arrMaterials })
    setClick(arrMaterials.length)
  }

  const handleClickDel = (del) => {
    console.log(`DEL index: `, del) // test
    arrMaterials.splice(del, 1)

    returnObject({ arrMaterials: arrMaterials })
    setClick(arrMaterials.length)
  }

  return (
    <>
      <FormControlInputOrSelect arr={ARR} returnObject={doCommonObject} data={data} />

      <div>&nbsp;</div>
      <div className='button_container'>
        {/* <Button
                    onClick={handleClick}
                >
                    + Добавить
                </Button> */}

        <AddButton handleSaveClick={handleClick} needDisable={false} buttonText={''} />
      </div>
      <div>&nbsp;</div>
      <div>
        {arrMaterials
          ? arrMaterials.map((itm, index) => (
              <div>
                <span style={{ display: 'inline-block', width: '3%' }}> {index + 1}. </span>
                <span style={{ display: 'inline-block', width: '50%' }}>
                  {' '}
                  {itm.category}: &nbsp;{' '}
                </span>
                <span style={{ display: 'inline-block', width: '24%' }}>
                  {' '}
                  {itm.steelGrade} &nbsp;{' '}
                </span>
                <span style={{ display: 'inline-block', width: '15%' }}>
                  {' '}
                  &nbsp; <b>{itm.totalWeight} </b> кг. &nbsp;
                </span>
                <span style={{ display: 'inline-block', width: '8%' }}>
                  {/* <Button
                                variant='danger'
                                onClick={() => { handleClickDel(index) }} >
                                DEL
                            </Button> */}
                  <DeleteButton
                    handleSaveClick={() => {
                      handleClickDel(index)
                    }}
                    needDisable={false}
                    buttonText=''
                  />
                </span>
                <div>
                  <span> &nbsp; Примечание: {itm.noteMaterials} </span>
                </div>
              </div>
            ))
          : ''}
      </div>
      <div style={{ textAlign: 'right' }}>
        <span style={{ fontWeight: 'bold', marginRight: '15%' }}>
          {arrMaterials?.length
            ? `ИТОГО: ${arrMaterials.reduce((sum, itm) => sum + Number(itm.totalWeight), 0)} кг.`
            : ''}
        </span>
      </div>
      <div>&nbsp;</div>
    </>
  )
}

export default FormMaterials
