import React from 'react';
import PositionChoiceParticipant from './PositionChoiceParticipant';

const PositionChoceParticipantModal = ({ dataPosition, onClosePosition, sendPositionData, value, runChat, organizerName, iAmCustomerLookingHowParticipantFilledPosition, numberLot, listForFiles, disableParticipantData, inn }) => {

    const doEmpty = () => {

    }

    const handleRunChat = (text, recipient) => {
        runChat(text, recipient)
    }

    // console.log(`INN :::::::::::::: `, inn);

    return (
        <>
            <PositionChoiceParticipant
                show={true}
                nameTrade={dataPosition.nameTrade}
                dataTech={dataPosition.tech}
                dataCommerce={dataPosition.commercial}
                showTech={true}
                showCommercial={true}
                serverData={dataPosition}
                keyNameTrade={dataPosition.keyNameTrade}
                organizerId={dataPosition.organizerId}
                priority={dataPosition.priority}
                fileId={dataPosition.idFile}
                fileList={dataPosition.fileList}
                archive={dataPosition.archive}
                createDate={dataPosition.createDate}
                onChangeShowTrade={onClosePosition} // команда на закрытие компонента
                readFromServerData={doEmpty} // указывает, что создает новый файл торговой позиции, возвращает ID новой позиции
                openPositionModal={doEmpty}
                sendPositionData={sendPositionData}
                value={value}
                runChat={handleRunChat}
                organizerName={organizerName}
                iAmCustomerLookingHowParticipantFilledPosition={iAmCustomerLookingHowParticipantFilledPosition}
                numberLot={numberLot}
                listForFiles={listForFiles}
                disableParticipantData={disableParticipantData}
                inn={inn}
            />
        </>
    )
}

export default PositionChoceParticipantModal;