export const ACCEPT = [
    'application/msword',
    'application/pdf',
    'text/plain',
    'image/jpeg',
    'image/tiff',
    'application/excel',
    'application/x-excel',
    'application/msword'
]

export const ACCEPT_IMAGE = [
    'image/*'
]