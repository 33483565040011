import React, { useState } from 'react'
import { useEffect } from 'react'
import { Button, Table } from 'react-bootstrap'
import LeftAndRightArrowsButtons from '../assets/LeftAndRightArrowsButtons'
import updateTradeTable from './updateTradeTable'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import getObjectTotalPrice from './getObjectTotalPrice'
import changeToStepPrice from './changeToStepPrice'
import lookingObjectChange from './lookingObjChanfe'

export function RenderModalBodyReduction({
  obj, //участники лота
  lotFull,
  member, //bool
  keyBlock,
  objChange,
  dataStart,
  count,
  setPercentErrorPlus,
  setPercentErrorMinus,
  rerenderOnBtnClick
}) {
  const [keyOn, setKeyOn] = useState('')
  const [newObj, setNewObj] = useState({})
  const [newValue, setNewValue] = useState('')
  const [errorColor, setErrorColor] = useState('#fff')
  const [widthMarginLeft, setWidthMarginLeft] = useState(0)
  const [disablePeriodForLeftButton, setDisablePeriodForLeftButton] = useState(false)
  const [disablePeriodForRightButton, setDisablePeriodForRightButton] = useState(false)

  const [prepay, setPrepay] = useState(null)
  const [preShip, setPreShip] = useState(null)
  const [afterShip, setAfterShip] = useState(null)
  // const [percentErrorPlus, setPercentErrorPlus] = useState(false)
  // const [percentErrorMinus, setPercentErrorMinus] = useState(false)

  const [priceTotalMin, setPriceTotal] = useState([]) // храним объект с минмальной ценой и ИНН компании
  const [priceTotalStep, setPriceTotalStep] = useState(0.01)

  const participantsArray = obj && Object.keys(obj)
  const [leftTableWidth, setLeftTableWidth] = useState(
    participantsArray && 250 * participantsArray.length
  )
  let arrData = []
  const greenColor = '#E4FEEA'
  const greyBorder = '1px solid #aaa'
  const fontWeight = '500'

  //*:  для ререндера с совсеми полученными данными
  useEffect(() => {
    (async () => {
      try {
        console.log(`>>> DATA obj:::: `, obj); // test
        console.log(`>>> DATA lotFull:::: `, lotFull); // test
        console.log(`>>> DATA objChange:::: `, objChange); // test
        // *: По сути если objChange === false, то в него создаем клона из obj
        if (!objChange) {
          await setNewObj(lookingObjectChange(objChange, obj, lotFull, newObj, keyOn))
        }

        const arrObjTotalPrice = await getObjectTotalPrice(objChange) // массив с объектами минимальной итоговой цены
        console.log(`arrObjTotalPrice>>> `, arrObjTotalPrice) // test
        await setPriceTotal(arrObjTotalPrice)

        if (lotFull?.data?.tradeStep) {
          await setPriceTotalStep(lotFull.data.tradeStep) // шаг торгов из Лота, если есть, по умолчанию 0,01
        }

      } catch (err) {
        console.log(`Ошибка при ререндере, первом входе: `, err);
      }
    })()

  }, [obj, lotFull, objChange])

  useEffect(() => {
    const sum = +prepay + +preShip + +afterShip

    // if(+prepay  && +preShip && +afterShip){
    if (sum > 100) {
      setPercentErrorPlus(true)
      setPercentErrorMinus(false)
    }
    if (sum < 100) {
      setPercentErrorPlus(false)
      setPercentErrorMinus(true)
    }
    if (sum === 100) {
      setPercentErrorPlus(false)
      setPercentErrorMinus(false)
    }
    // }
  }, [prepay, preShip, afterShip])

  // Обработка итоговой цены для редукциона 
  // todo: При первом входе в торги не получаем минимально цены, кнопка снижения цены не работает. После ренедера все ОК
  useEffect(() => {
    // const arrObjTotalPrice = getObjectTotalPrice(objChange) // массив с объектами минимальной итоговой цены
    // console.log(`arrObjTotalPrice>>> `, arrObjTotalPrice) // test
    // setPriceTotal(arrObjTotalPrice)

    // if (lotFull?.data?.tradeStep) {
    //   setPriceTotalStep(lotFull.data.tradeStep) // шаг торгов из Лота, если есть, по умолчанию 0,01
    // }
  }, [lotFull, objChange, newObj]) // * ***

  useEffect(() => {
    setPercentErrorPlus(false)
    setPercentErrorMinus(false)
  }, [])

  useEffect(() => {
    if (keyBlock) {
      setKeyOn(localStorage.getItem('idUser'))
    } else {
      setKeyOn('')
    }
  }, [keyBlock])

  // * отслеживаем newObj (объект который может быть переписан участником торгов)
  useEffect(() => {
    setWidthMarginLeft(count) // один раз при верске загружаем отступ правого блока
    setNewObj(lookingObjectChange(objChange, obj, lotFull, newObj, keyOn))
  }, [])

  // * сохранение изменений с задержкой
  useEffect(() => {
    arrData.push(newValue)
    localStorage.setItem('arr', newValue) // записываем текущее значение в Local Storage

    const inn = localStorage.getItem('idUser')

    setTimeout(() => {
      // сравниваем занчение с Local Storage
      if (String(newValue) === localStorage.getItem('arr') && lotFull) {
        setTimeout(() => {
          if (newObj && newObj[inn]) Object.assign(newObj[inn], { active: true })

          if (newObj) {
            console.log(`Будем делать сохранение`) // test
              ; (async () => {
                try {
                  const idSend = `${lotFull.data.id}`

                  const resultUpdate = await updateTradeTable(idSend, 'trade_table_new', newObj)
                  console.log(`RESULT UPDATE::: `, resultUpdate, newObj) // test                  
                } catch (err) {
                  console.log(`Ошибка при обновлении (RenderModalBody.jsx): `, err)
                }
              })()
          }
          localStorage.removeItem('arr') // удаляем занчение по ключу в Local Storage
        }, 3000)
      }
    }, 2000)
  }, [newValue, newObj])

  // сдвигаем блок влево
  const doLeft = () => {
    const leftColWidth = 250
    setLeftTableWidth(leftTableWidth - 250)
    setDisablePeriodForLeftButton(true)
    setTimeout(() => {
      setDisablePeriodForLeftButton(false)
    }, 250)

    for (let i = 0; i <= leftColWidth; i = i + 5) {
      setTimeout(() => {
        setWidthMarginLeft(widthMarginLeft - i)
      }, 10)
    }
    // console.log(leftTableWidth)
  }

  // сдвигаем блок вправо
  const doRight = () => {
    setLeftTableWidth(leftTableWidth + 250)
    setDisablePeriodForRightButton(true)
    setTimeout(() => {
      setDisablePeriodForRightButton(false)
    }, 250)
    if (widthMarginLeft >= count) {
      setWidthMarginLeft(count)
    } else {
      for (let i = 0; i <= 250; i = i + 5) {
        setTimeout(() => {
          setWidthMarginLeft(widthMarginLeft + i)
        }, 10)
      }
    }
  }

  try {
    // console.log(`obj:::`, obj); // test

    if (!lotFull) {
      throw new Error('Нет объекта ЛОТа')
    }
    const lot = lotFull.data

    const date = Math.round((Date.parse(lot.deliveryTime) - Date.now()) / 1000 / 60 / 60 / 24)
    const dateAll = new Date(lot.deliveryTime)
    const dateY = dateAll.getFullYear()
    const dateM = dateAll.getMonth() + 1
    const dateD = dateAll.getDate()

    const arrLotNumberPosition = [
      0,
      lot.guaranteeAfterLaunch,
      lot.guaranteeAfterShipment,
      lot.prepayFromServer,
      lot.beforePayFromServer,
      lot.afterPayFromServer,
      lot.termsOfAfterpayment,
      `${date} / (${dateD}.${dateM}.${dateY})`
    ]

    //* Изменение цены за штуку
    const handleChageInput = (e) => {
      if (!isNaN(e.target.value)) {
        setNewValue(e.target.value) // new value

        const keyNew = e.target.dataset.key // company inn
        let resultData = {}
        const value = e.target.value
        resultData = newObj[keyNew].data.find(
          (item) => item.description === e.target.dataset.description
        ) // check on ID trade position

        if (resultData) {
          const arrData = newObj[keyNew].data.map((item) => {
            if (item.id === resultData.id) {
              resultData.value = value
              return resultData
            }
            return item
          })

          newObj[keyNew].data = arrData
          setNewObj(newObj)
        }

        if (!resultData) {
          resultData = newObj[keyNew].tradePosition.find(
            (item) => item.idPosition === e.target.dataset.description
          )

          const arrTradePosition = newObj[keyNew].tradePosition.map((item) => {
            if (item.idPosition === resultData.idPosition) {
              // resultData.dataComm[1].value = value

              const newResultData = resultData.dataComm.map((itm) => {
                if (itm.id === '10') {
                  itm.value = value
                }
                return itm
              })

              resultData.dataComm = newResultData
              return resultData
            }
            return item
          })

          newObj[keyNew].tradePosition = arrTradePosition
          setNewObj(newObj)
        }
        // console.log(resultData); // test
        // console.log(`NEW OBJ: `, newObj); // test

        // console.log(
        //   `Button data::> key: ${keyNew} -- value: ${value} -- description: ${e.target.dataset.description} -- resultData: `,
        //   resultData
        // ) // test

        setErrorColor('#fff')

        const res = newObj[keyNew].data
        res.forEach((el) => {
          if (el.id === '3') {
            setPrepay(el.value)
          }
          if (el.id === '4') {
            setPreShip(el.value)
          }
          if (el.id === '5') {
            setAfterShip(el.value)
          }
        })
      } else {
        setErrorColor('#dc3545')
      }
    }

    //*: стать первым по минимальной цене
    const handleChageButton = (e) => {
      if (true) {
        ; (async () => {
          try {
            // setNewValue(e.target.value) // *: new value  Продумать функцию получения минимальной цены

            const keyNew = e.target.dataset.key // company inn
            let resultData = {}
            const value = e.target.value // *: Минимальное занчение минус шаг торгов (необходимо сделать)
            const dataValue = Number(e.target.dataset.value)
            resultData = newObj[keyNew].data.find(
              (item) => item.description === e.target.dataset.description
            ) // check on ID trade position

            console.log(
              `Button data::> key: ${keyNew} -- dataValue: ${dataValue} -- description: ${e.target.dataset.description} -- resultData: `,
              resultData
            ) // test
            console.log(`total min:::: `, priceTotalMin[0]) // test
            console.log(`company trade::: `, newObj[keyNew]) // test --- торговый объект выбранной компании
            console.log(`company trade without key::: `, newObj[keyNew].tradePosition) // test --- торговый объект выбранной компании
            console.log(`LOT step:::: `, priceTotalStep) // test

            //* исключаем понижение цены у компании с минмальной ценой
            // if (priceTotalMin[0].companuInn !== keyNew) { // *** выключаем проверку первой в позиции

            //*: Внести изменения в newObj, в торговые позиции выбранной компании
            newObj[keyNew].tradePosition = changeToStepPrice(
              newObj[keyNew],
              priceTotalStep,
              priceTotalMin
            )

            // console.log(`newObj =====: `, newObj) // test

            const idSend = `${lotFull.data.id}`

            const resultUpdate = await updateTradeTable(idSend, 'trade_table_new', newObj)
            console.log(`Результат сохранения: `, resultUpdate) // test
            setNewObj(newObj)
            rerenderOnBtnClick(lotFull.data) // перерендер по нажатию кнопки
            // } else { // *** выключаем проверку первой в позиции
            // console.log(`Первый в списке с минимальной ценой`); // test
            // console.log(`priceTotalMin[0].companuInn !== keyNew :::: `, priceTotalMin[0].companuInn !== keyNew); // test
            // console.log(`priceTotalMin == `, priceTotalMin); // test
            // console.log(`keyNew::: `, keyNew); // test
            // } // *** выключаем проверку первой в позиции

            const res = newObj[keyNew].data
            res.forEach((el) => {
              if (el.id === '3') {
                setPrepay(el.value)
              }
              if (el.id === '4') {
                setPreShip(el.value)
              }
              if (el.id === '5') {
                setAfterShip(el.value)
              }
            })
          } catch (err) {
            console.log(`Ошибка при сохранении минимальных ценовых данных: `, err)
          }
        })()
      }
    }

    // let dataLength = 0; // длина массива даных 'data'
    let lengthPosition = lot.position.length // количество торговых позиций

    let bodyData = []
    let bodyDataMain = []
    let bodyPositionMain = []
    let bodyPosition = []
    let bodyTotal = []

    const total = {}
    const newTotal = {}
    let headPart = []
    let lineRender = []
    let lineRenderMain = []

    // преобразуем объект в массив
    let newArrObj = []
    for (let key in objChange) {
      newArrObj.push(objChange[key])
    }

    // *: сортируем массив (создать условие сортировки только для заказчика - member) В Редукционе для всех
    if (true) {
      newArrObj.sort((prev, next) => prev.total - next.total)
      // console.log(`newArrObj >>>>>>>>>>>>>> `, newArrObj); // test
    }

    // верстка шапки участников
    // * участник
    // for (let key in obj)
    // рендер по массиву
    newArrObj.forEach((item, index) => {
      const key = item.participantCompany

      if (keyOn === key || keyOn === '' || lot.organizerId === localStorage.getItem('idUser')) {
        headPart.push(
          <React.Fragment key={`${String(key)}head`}>
            <th
              style={{
                width: '250px',
                height: '7vh',
                fontWeight: `${fontWeight}`
              }}
              title={obj[key].nameCompany}
            >
              <span>{index + 1}.</span>
              &nbsp; &nbsp;
              {obj[key].nameCompany.length > 5 ? `${obj[key].nameCompany.substring(0, 5)}...` : obj[key].nameCompany}
              {item.active && item.active ? (
                // <div className='online_span blur_shadow_green' title='Участник просмотрел информацию'></div>
                <VisibilityOutlinedIcon className='online_span_eye' sx={{ fontSize: 18 }} />
              ) : (
                // <div className='offline_span blur_shadow_grey' title='Участник не просмотрел информацию'></div>
                <VisibilityOffOutlinedIcon
                  fontSize='small'
                  className='offline_span_eye'
                  sx={{ fontSize: 18 }}
                />
              )}
            </th>
          </React.Fragment>
        )
      }
    })

    // *: добавить данные с ЛОТа (убрали позиции с договором и условия доставки)
    // название по объекту data
    // * заказчик
    for (let i = 1; i < 8; i++) {
      let lineName = ''
      lineRender = []
      lineRenderMain = [
        <React.Fragment key={`${i}_iteration`}>
          <td
            style={{
              // width: "200px",
              textAlign: 'center',
              backgroundColor: `${greenColor}`
            }}
          >
            <div
              style={{
                // width: "200px",
                textAlign: 'center',
                height: '28px',
                boxSizing: 'border-box'
              }}
            >
              {arrLotNumberPosition[i]}
            </div>
          </td>
        </React.Fragment>
      ]

      // рендер занчение data
      // * участник
      //for (let key in obj)
      // eslint-disable-next-line no-loop-func
      newArrObj.forEach((itm) => {
        const key = itm.participantCompany

        total[key] = 0 // сбрасываем значения total
        newTotal[key] = 0
        let participantKey = true

        if (key === localStorage.getItem('idUser')) participantKey = false

        if (keyOn === key || keyOn === '' || lot.organizerId === localStorage.getItem('idUser')) {
          if (
            obj[key].data[i] &&
            obj[key].data[i].description !== 'Согласие с условием договора' &&
            obj[key].data[i].description !== 'Условия доставки по Инкотермс'
          ) {
            lineName = obj[key].data[i].description

            // формирование, рендер строк
            // * участник
            lineRender.push(
              <React.Fragment key={`${String(key)} bodyLine`}>
                <td>
                  <div
                    style={{
                      // width: "250px",
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      height: '28px',
                      boxSizing: 'border-box'
                    }}
                  >
                    <div
                      style={{
                        width: '100px',
                        textAlign: 'right',
                        height: '28px',
                        boxSizing: 'border-box'
                      }}
                    >
                      {' '}
                      {obj[key].data[i]
                        ? obj[key].data[i].value === true
                          ? 'Да'
                          : obj[key].data[i].value === false
                            ? 'Нет'
                            : obj[key].data[i].value
                              ? obj[key].data[i].value
                              : '-'
                        : 'Нет данных'}{' '}
                    </div>
                    <div style={{ margin: '0 10px' }}> / </div>
                    <div style={{ width: '100px' }}>
                      <input
                        name='text'
                        size='2'
                        type='number'
                        max='365'
                        min='0'
                        onChange={handleChageInput}
                        style={{
                          border: `${greyBorder}`,
                          backgroundColor: participantKey ? '#ccc' : '#fff'
                        }}
                        disabled={member ? true : participantKey}
                        data-key={key}
                        data-description={lineName}
                        data-arr={i}
                        placeholder={
                          dataStart || (dataStart === false && participantKey === false)
                            ? newObj[key] && newObj[key]?.data[i]?.value
                            : 0
                        }
                      />
                    </div>
                  </div>
                </td>
              </React.Fragment>
            )
          } else {
            lineRender.push(
              <React.Fragment key={`${String(key)}bodyLine`}>
                <td
                  style={{
                    textAlign: 'center',
                    height: '28px',
                    boxSizing: 'border-box'
                  }}
                >
                  <span> {'Нет данных'} </span>
                </td>
              </React.Fragment>
            )
          }
        }
      })

      // рендер название позиций и позиции
      // * заказчик и участник {lineRender}
      bodyDataMain.push(
        <React.Fragment key={`${String(i * 100)}dataLine`}>
          <tr>
            <td
              style={{
                textAlign: 'center',
                backgroundColor: `${greenColor}`
              }}
            >
              {' '}
              {i}{' '}
            </td>
            <td style={{ backgroundColor: `${greenColor}` }}>{lineName}</td>
            {lineRenderMain}
          </tr>
        </React.Fragment>
      )

      bodyData.push(
        <React.Fragment key={`${String(i)}dataLine`}>
          <tr style={{ textAlign: 'center' }}>{lineRender}</tr>
        </React.Fragment>
      )
    }

    let positionNameMain = []
    let positionName = []
    let positionQuantity = []
    let price = []
    let totalPrice = []

    //* рендер торговых позиций
    // * заказчик
    // const lengthArr = newArrObj.length
    for (let i = 0; i < lengthPosition; i++) {
      positionName = []
      positionQuantity = []
      price = []
      totalPrice = []

      // название позиций из ЛОТа
      // console.log(`lot.position ::::${i} ::::::  `, lot.position); // test
      positionNameMain = [
        <React.Fragment key={`${i}_organizer`}>
          <td
            style={{
              textAlign: 'center'
            }}
          >
            {lot.position[i].nameTrade}
          </td>
        </React.Fragment>
      ]

      // кол-во в позициях из ЛОТа
      const comm6 = lot.position[i].commercial.find((item) => {
        if (item && item.id === '6') {
          return item
        }
      })
      // console.log(`COMM6 ===> `, comm6); // test
      const positionQuantityMain = [
        <React.Fragment key={`${i}_comm6`}>
          <td
            style={{
              textAlign: 'center'
            }}
          >
            {comm6 ? comm6.value : 1}
          </td>
        </React.Fragment>
      ]

      // цена позиции из ЛОТа
      const num = Number(lot.position[i].comm) / Number(comm6 ? comm6.value : 1)
      const resultNum = num.toLocaleString()
      const priceMain = [
        <React.Fragment key={`${i}_comm`}>
          <td
            style={{
              textAlign: 'center'
            }}
          >
            {resultNum}
          </td>
        </React.Fragment>
      ]

      // стоимость все позиции из ЛОТа
      // console.log(`COMM6 VALUE >>>>>> `, comm6.value); // test
      const numMain = Number(lot.position[i].comm)
      const resultNumMain = numMain.toLocaleString()
      const totalPriceMain = [
        <React.Fragment key={`${i}_priceMain`}>
          <td
            style={{
              textAlign: 'center'
            }}
          >
            {resultNumMain}
          </td>
        </React.Fragment>
      ]

      // название позиции
      // * участник
      //   for (let key in obj)
      // eslint-disable-next-line no-loop-func
      newArrObj.forEach((item) => {
        const key = item.participantCompany

        if (keyOn === key || keyOn === '' || lot.organizerId === localStorage.getItem('idUser')) {
          const tradePosition = obj[key].tradePosition.find(
            (item) => item.idPosition === lot.position[i].id
          )

          if (tradePosition) {
            positionName.push(
              <React.Fragment
                key={`${tradePosition.idPosition}${tradePosition.idFilePosition}_namePosition`}
              >
                <td
                  style={{
                    textAlign: 'center'
                  }}
                >
                  {tradePosition.namePosition}
                </td>
              </React.Fragment>
            )
          } else {
            positionName.push(
              <React.Fragment
                key={`${tradePosition?.idPosition}${tradePosition?.idFilePosition}_namePosition`}
              >
                <td
                  style={{
                    textAlign: 'center'
                  }}
                >
                  {'Не данных'}
                </td>
              </React.Fragment>
            )
          }
        }
      })

      // кол-во в торг.поз
      // * участник
      // for (let key in obj)
      // eslint-disable-next-line no-loop-func
      newArrObj.forEach((item, index) => {
        const key = item.participantCompany

        if (keyOn === key || keyOn === '' || lot.organizerId === localStorage.getItem('idUser')) {
          const tradeQuantity = obj[key].tradePosition.find(
            (item) => item.idPosition === lot.position[i].id
          )
          const trQ = tradeQuantity?.dataComm.find((item) => item.id === '6')

          if (tradeQuantity) {
            positionQuantity.push(
              <React.Fragment key={`${key}${index}${i}_quantity`}>
                <td
                  style={{
                    textAlign: 'center'
                  }}
                >
                  {trQ?.value}
                </td>
              </React.Fragment>
            )
          } else {
            positionQuantity.push(
              <React.Fragment key={`${key}_commPosition`}>
                <td
                  style={{
                    textAlign: 'center'
                  }}
                >
                  {'Не данных'}
                </td>
              </React.Fragment>
            )
          }
        }
      })

      // цена торг позиции
      // * участник
      // for (let key in obj)
      // eslint-disable-next-line no-loop-func
      newArrObj.forEach((item) => {
        const key = item.participantCompany
        let newTradePrice
        let tP
        let trP
        let participantKey2 = true

        if (key === localStorage.getItem('idUser')) participantKey2 = false

        if (keyOn === key || keyOn === '' || lot.organizerId === localStorage.getItem('idUser')) {
          const tradePrice = obj[key].tradePosition.find(
            (item) => item.idPosition === lot.position[i].id
          )

          if (newObj[key]) {
            newTradePrice = newObj[key].tradePosition.find(
              (item) => item.idPosition === lot.position[i].id
            )

            // console.log(`newObj[key] >>>>>>>>>>>>>>>>>> `, newObj[key]); // test
            tP = tradePrice?.dataComm.find((item) => item.id === '10')
            trP = newTradePrice?.dataComm.find((item) => item.id === '10')
          }

          if (tradePrice) {
            console.log(`Description:::: tradePrice.idPosition::: `, tradePrice.idPosition) // test
            price.push(
              <React.Fragment key={`${tradePrice.idFilePosition}commPrice`}>
                <td>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center',
                      height: '24px'
                    }}
                  >
                    <div style={{ width: '100px', textAlign: 'right' }}>
                      {tP && Number(tP.value).toLocaleString()}
                    </div>
                    <div style={{ margin: '0 10px' }}> / </div>
                    <div style={{ width: '100px' }}>
                      <input
                        name='text'
                        size='9'
                        onChange={handleChageInput}
                        // disabled={member ? true : participantKey2}
                        disabled={true}
                        style={{
                          backgroundColor: participantKey2 ? '#ccc' : errorColor,
                          border: `${greyBorder}`,
                          boxSizing: 'border-box'
                        }}
                        data-key={key}
                        data-description={tradePrice.idPosition}
                        data-arr={i}
                        placeholder={
                          dataStart || (dataStart === false && participantKey2 === false)
                            ? trP && Number(trP.value).toLocaleString()
                            : 0
                        }
                      />
                      {/* <Button
                                                style={{
                                                    width: '90px', height: '25px', fontSize: '14px', padding: '1px'
                                                }}
                                                data-key={key}
                                                data-description={tradePrice.idPosition}
                                                data-arr={i}
                                                onClick={handleChageButton}
                                            >
                                                Первым
                                            </Button> */}
                    </div>
                  </div>
                </td>
              </React.Fragment>
            )
          } else {
            price.push(
              <React.Fragment key={`${key}_commPrice`}>
                <td
                  style={{
                    textAlign: 'center'
                  }}
                >
                  {'Не данных'}
                </td>
              </React.Fragment>
            )
          }
        }
      })

      // стоимость всей позиции
      // * участник
      // for (let key in obj)
      // eslint-disable-next-line no-loop-func
      newArrObj.forEach((item) => {
        const key = item.participantCompany
        let newTradeTotal
        let newTradeQ
        let newTradeP

        if (keyOn === key || keyOn === '' || lot.organizerId === localStorage.getItem('idUser')) {
          const tradeTotal = obj[key].tradePosition.find(
            (item) => item.idPosition === lot.position[i].id
          )
          const totalQ = tradeTotal?.dataComm.find((item) => item.id === '6')
          const totalP = tradeTotal?.dataComm.find((item) => item.id === '10')

          if (newObj[key]) {
            newTradeTotal = newObj[key].tradePosition.find(
              (item) => item.idPosition === lot.position[i].id
            )

            // console.log(`newTradeTotal >>>>>>>>>>>>>>>> `, newTradeTotal.dataComm); // test
            newTradeQ = newTradeTotal?.dataComm.find((item) => item.id === '6')
            newTradeP = newTradeTotal?.dataComm.find((item) => item.id === '10')

            // console.log(`TRADE TOTAL:::::::::::::: `, newTradeTotal); // test
          }

          if (tradeTotal) {
            total[key] = Number(totalP?.value) * Number(totalQ?.value) + total[key]

            if (newTradeTotal) {
              newTotal[key] = Number(newTradeP?.value) * Number(newTradeQ?.value) + newTotal[key]
            }
            let participantKey3 = true

            if (key === localStorage.getItem('idUser')) participantKey3 = false

            totalPrice.push(
              <React.Fragment key={`${tradeTotal.idFilePosition}_totalPrice`}>
                <td>
                  <div
                    style={{
                      fontWeight: `${fontWeight}`,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'center'
                    }}
                  >
                    <div style={{ width: '100px', textAlign: 'right' }}>
                      {tradeTotal &&
                        (Number(totalP?.value) * Number(totalQ?.value)).toLocaleString()}
                    </div>
                    <div style={{ margin: '0 10px' }}> / </div>
                    <div style={{ width: '100px' }}>
                      {dataStart || (dataStart === false && participantKey3 === false)
                        ? newTradeTotal &&
                        (Number(newTradeP?.value) * Number(newTradeQ?.value)).toLocaleString()
                        : 0}
                    </div>
                  </div>
                </td>
              </React.Fragment>
            )
          } else {
            totalPrice.push(
              <React.Fragment key={`${key}_totalPrice`}>
                <td
                  style={{
                    textAlign: 'center'
                  }}
                >
                  {'Не данных'}
                </td>
              </React.Fragment>
            )
          }
        }
      })

      // название строк позиции
      // * заказчик
      bodyPositionMain.push(
        <React.Fragment key={`${i}namePosition`}>
          <tbody
            style={{
              border: `${greyBorder}`,
              backgroundColor: `${greenColor}`
            }}
          >
            <tr>
              <td
                style={{
                  textAlign: 'center'
                }}
              >
                {8 + i + 3 * i}
              </td>
              <td>{'Название позиции'}</td>
              {positionNameMain}
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center'
                }}
              >
                {9 + i + 3 * i}
              </td>
              <td>{'Кол-во, шт'}</td>
              {positionQuantityMain}
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center'
                }}
              >
                {10 + i + 3 * i}
              </td>
              <td>{'Цена за шт, руб:'}</td>
              {priceMain}
            </tr>
            <tr>
              <td
                style={{
                  textAlign: 'center'
                }}
              >
                {11 + i + 3 * i}
              </td>
              <td>{'Стоимость всей позиции, руб:'}</td>
              {totalPriceMain}
            </tr>
          </tbody>
        </React.Fragment>
      )

      bodyPosition.push(
        <React.Fragment key={`${i}namePosition2`}>
          <tbody style={{ border: `${greyBorder}` }}>
            <tr>{positionName}</tr>
            <tr>{positionQuantity}</tr>
            <tr>{price}</tr>
            <tr>{totalPrice}</tr>
          </tbody>
        </React.Fragment>
      )
    }

    // ИТОГОВАЯ стоимость лота
    // * участник
    // for (let key in obj)
    newArrObj.forEach((item) => {
      const key = item.participantCompany
      let participantKey4 = true

      if (key === localStorage.getItem('idUser')) participantKey4 = false

      if (keyOn === key || keyOn === '' || lot.organizerId === localStorage.getItem('idUser')) {
        bodyTotal.push(
          <React.Fragment key={`${key}total`}>
            <td>
              <div
                style={{
                  fontWeight: '700',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  height: '25px'
                }}
              >
                <div style={{ width: '100px', textAlign: 'right' }}>
                  {Number(total[key]).toLocaleString()}
                </div>
                <div style={{ margin: '0 10px' }}> / </div>
                <div style={{ width: '100px' }}>
                  {/* {dataStart || (dataStart === false && participantKey4 === false)
                                        ? Number(newTotal[key]).toLocaleString()
                                        : 0} */}
                  <Button
                    style={{
                      width: '90px',
                      height: '25px',
                      fontSize: '14px',
                      padding: '1px'
                    }}
                    title={'Стать первым'}
                    data-key={key}
                    // data-description={tradePrice.idPosition}
                    // data-arr={i}
                    disabled={key === localStorage.getItem('idUser') && !member ? false : true} //! условия отключения кнопки
                    // disabled={(key === localStorage.getItem('idUser')) ? false : true} // todo: переписать после отладки
                    data-value={
                      dataStart || (dataStart === false && participantKey4 === false)
                        ? Number(newTotal[key])
                        : 0
                    }
                    onClick={handleChageButton}
                  >
                    {dataStart || (dataStart === false && participantKey4 === false)
                      ? Number(newTotal[key]).toLocaleString()
                      : 0}
                  </Button>
                </div>
              </div>
            </td>
          </React.Fragment>
        )
      }
    })

    // сборная таблица
    // * заказчик и участник
    const renderTableMain = (
      <>
        <thead
          style={{
            border: `${greyBorder}`,
            textAlign: 'center',
            fontWeight: `${fontWeight}`
            // width: '500px'
          }}
        >
          <tr className='text_align_center' style={{ backgroundColor: `${greenColor}` }}>
            <th style={{ fontWeight: `${fontWeight}` }}>п/п</th>
            <th style={{ fontWeight: `${fontWeight}` }}>
              {/* <div
                style={{
                  // width: "230px",
                  fontWeight: `${fontWeight}`
                }}
              > */}
              Данные по компаниям
              {/* </div> */}
            </th>
            <th style={{ height: '7vh', fontWeight: `${fontWeight}` }}>
              <div
              // style={{
              //   width: "180px",
              // }}
              >
                Данные заказчика
              </div>
            </th>
          </tr>
        </thead>
        <tbody style={{ border: `${greyBorder}` }}>{bodyDataMain}</tbody>
        {bodyPositionMain}
        <tbody>
          <tr>
            <td style={{ backgroundColor: `${greenColor}` }}>
              <div> </div>
            </td>
            <td style={{ backgroundColor: `${greenColor}` }}>{'ИТОГО, руб:'}</td>
            <td
              style={{
                textAlign: 'center',
                fontWeight: '700',
                backgroundColor: `${greenColor}`
              }}
            >
              {Number(lot.price).toLocaleString()}
            </td>
          </tr>
        </tbody>
      </>
    )

    const renderTable = (
      <>
        <thead style={{ border: `${greyBorder}` }}>
          <tr className='fw_bolder text_align_center'>{headPart}</tr>
        </thead>
        <tbody style={{ border: `${greyBorder}`, textAlign: 'center' }}>{bodyData}</tbody>
        {bodyPosition}
        <tbody>
          <tr>{bodyTotal}</tr>
        </tbody>
      </>
    )
    // console.log('widthMarginLeft', renderTable) // test
    // console.log('headPart', headPart);
    return (
      <>
        <div className='table_container'>
          <div className='main_table'>
            <div className='main_table_left_white_bar'></div>
            <Table
              hover
              bordered
              size='sm'
              variant='Light'
              style={{ backgroundColor: '#fff', whiteSpace: 'nowrap' }}
              className='universal_table'
            >
              {renderTableMain}
            </Table>
          </div>

          <div
            className='secondary_table'
            style={{ marginLeft: `${widthMarginLeft}px` }}
          // style={{ right: widthMarginLeft >=700 ? '14px' : `${widthMarginLeft}px` }}
          >
            <Table
              hover
              bordered
              size='sm'
              variant='Light'
              className='universal_table'
              style={{
                backgroundColor: '#fff'
              }}
            >
              {renderTable}
            </Table>
          </div>
        </div>

        <div className='scroll_area'>
          <LeftAndRightArrowsButtons
            nextPageClick={doLeft}
            prevPageClick={doRight}
            needDisableButtons={true}
            disableNextButtonCondition={
              disablePeriodForLeftButton || leftTableWidth <= 250 ? true : false
            } //condition needed
            disablePrevButtonCondition={
              disablePeriodForRightButton || widthMarginLeft >= count ? true : false
            }
          />
        </div>
      </>
    )
  } catch (err) {
    console.log(`Ошибка в (RenderModalBodyReduction.jsc): `, err)

    return (
      <div>
        <span style={{ fontSize: '1.5rem', fontWeight: `${fontWeight}` }}>Нет данных.</span>
      </div>
    )
  }
}
