import { Badge } from "react-bootstrap";
import TimerTimeLeft from "../assets/TimerTimeLeft";
import getWinnerData from "../assets/getWinnerData";


export const getTradeDuration = (startDate, timer) => {
    if (!startDate || !timer) return true; 

    const tradeDurationMinutes = parseInt(timer, 10);
    const tradeStartDate = new Date(startDate);
    const tradeEndDate = new Date(tradeStartDate.getTime() + tradeDurationMinutes * 60000);

    if (tradeEndDate < new Date()) {
        return false; 
    } else {
        return true; 
    }
}

export const toMoscowTime = (date) => {
    const moscowOffset = 180; 
    const utc = date.getTime() + (date.getTimezoneOffset() * 60000);
    return new Date(utc + (moscowOffset * 60000));
};

export const getTradeStatus = (startDate, timeTrade, timer) => {

    // переводим дату начала в формат даты и добавляем к ней время, введенное пользователем
    const tradeStartDate = new Date(startDate);
    const tradeStartTimeParts = timeTrade?.split(':');
    if (tradeStartTimeParts && tradeStartTimeParts.length === 2) {
        tradeStartDate.setHours(parseInt(tradeStartTimeParts[0]), parseInt(tradeStartTimeParts[1]), 0, 0);
    }

    // Рассчитываем длительность и конец торгов
    const tradeDurationMinutes = parseInt(timer, 10);
    const tradeEndDate = new Date(tradeStartDate.getTime() + tradeDurationMinutes * 60000);

    // Текущее московское время
    const currentMoscowTime = toMoscowTime(new Date());

    // Получаем из дат начала и конца нужный формат
    const formattedDateStart = formatDate(tradeStartDate);
    const formattedDateFinish = formatDate(tradeEndDate);

    // Время в секундах для секундомера
    const timeLeftMs = tradeEndDate - currentMoscowTime;
    const timeLeftSeconds = Math.floor(timeLeftMs / 1000);

    if (currentMoscowTime < tradeStartDate) {
        return {
            status: <Badge variant='primary'>Еще не начались</Badge>,
            startDate: formattedDateStart.date,
            startTime: formattedDateStart.time,
            finishTime: formattedDateFinish.time,
            category: 'has_not_started'
        };
    } else if (currentMoscowTime >= tradeStartDate && currentMoscowTime < tradeEndDate) {
        // console.log();
        return {
            status: <Badge variant='success'>Идут</Badge>,
            startDate: <span>{formattedDateStart.date} до окончания <Badge variant='danger'><TimerTimeLeft initialTimeLeft={timeLeftSeconds}/></Badge></span>,
            startTime: formattedDateStart.time,
            finishTime: formattedDateFinish.time,
            category: 'currently_running'
        };
    } else {
        return {
            status: <Badge variant='secondary'>Закончились</Badge>,
            startDate: formattedDateStart.date,
            startTime: formattedDateStart.time,
            finishTime: formattedDateFinish.time,
            category: 'finished'
        };
    }
};

export function formatDate(date) {
    if (!(date instanceof Date) || isNaN(date)) {
        return 'Дата не указана'; // or any default/fallback string you prefer
    }
    const pad = (num) => num.toString().padStart(2, '0');

    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); 
    const year = date.getFullYear();
    const hours = pad(date.getHours());
    const minutes = pad(date.getMinutes());

    // console.log('date', date );

    return {
        date: `${day}.${month}.${year}`,
        time: `${hours}:${minutes}`,
        dateAndTime: `${day}.${month}.${year}, ${hours}:${minutes}`
    }  
}

export const processTabledata = (tradesArray) => {
try{
    if(tradesArray){
        const updatedTrades = tradesArray?.slice();
        updatedTrades?.map(trade => {
        const status = getTradeStatus(trade.dateTrade, trade.timeTrade, trade.timer);
        trade.tradeStatus = status.status;
        trade.startDate = status.startDate;
        trade.startTime = status.startTime;
        trade.finishTime = status.finishTime;
        trade.category = status.category
    })

  return updatedTrades
}
} catch(error){
    console.log('Error in processTabledata function', error);
}

}

export const categorizeTrades = async (fullTradesArray) => {
    try {
        const all = fullTradesArray
        const drafts = []
        const incomingOffers = []
        const current = []
        const finishedNoWinner = []
        const finishedWinner = []
        const canceled = []

        const array = fullTradesArray?.slice()

        for (const trade of array) {
            // console.log(trade);
            const {dateTrade, timer, timeTrade, active} = trade
            //дата начала
            const tradeStartDate = new Date(dateTrade);
            //время начала
            const tradeStartTimeParts = timeTrade?.split(':');
            if (tradeStartTimeParts && tradeStartTimeParts.length === 2) {
                tradeStartDate.setHours(parseInt(tradeStartTimeParts[0]), parseInt(tradeStartTimeParts[1]), 0, 0);
            }
            //длительность в минутах
            const tradeDurationMinutes = parseInt(timer, 10);
            //дата конца
            const tradeEndDate = new Date(tradeStartDate.getTime() + tradeDurationMinutes * 60000);
            //текущее московское время
            const currentMoscowTime = toMoscowTime(new Date());
            //победитель

            const winnerObject = await getWinnerData(trade.id)
            const winnerName = winnerObject !== null ? winnerObject.nameCompany : 'no_winner'

            if (currentMoscowTime < tradeStartDate) {
                incomingOffers.push(trade)
            } else if (currentMoscowTime >= tradeStartDate && currentMoscowTime < tradeEndDate) {
                current.push(trade)
            } else if (active === true && currentMoscowTime > tradeEndDate && winnerName === 'no_winner') {
                finishedNoWinner.push(trade)
            } else if(active === true && currentMoscowTime > tradeEndDate && winnerName !== 'no_winner'){
                finishedWinner.push(trade)
            } else if(active === false || !dateTrade || !timer || !timeTrade){
                drafts.push(trade)
            }
        }

        return {
            all,
            drafts,
            incomingOffers,
            current,
            finishedNoWinner,
            finishedWinner,
            canceled
        }

    } catch (err) {
        console.log(`Ошибка при определении категорий торгов у заказчика`, err);
    }
}



