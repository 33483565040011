import React, { useState, useEffect } from 'react'
import { ITEM_LIST, ITEM_LIST_KEY, CARD_EQUIPMENT } from './constCardEquipment'
import InputDataText from './InputDataText'
import { Container } from 'react-bootstrap'
import SelectData from './SelectData'
import Server from '../../server/server'
import { SaveCurrentDataButton } from '../../assets/BasicButtons'
import QuitForm from '../../QuitForm'

const ContentEditCardEquipment = ({
  doSaveCard,
  dataCard,
  doChangeData,
  setHasChanges,
  showConfirmModal,
  setShowConfirmModal,
  toggleModal,
  onClose
}) => {
  const [card, setCard] = useState(dataCard)
  const [data, setData] = useState()
  const [company, setCompany] = useState()
  const [dataIsSaved, setDataIsSaved] = useState(false)

  const setDataIsSavedMessage = () => {
    setDataIsSaved(true)
    setTimeout(() => {
      setDataIsSaved(false)
    }, 1500)
  }

  useEffect(() => {
    ;(async () => {
      try {
        const result = await Server.searchAllNameCompany()
        // console.log(`result >>>>> `, result) // test
        setCompany(result)
      } catch (err) {
        console.log(
          `Ошибка при получении названий всех компаний в базе (ContentAddNewEquipment.jsx): `,
          err
        )
      }
    })()
  }, [])

  const handleChangeInput = (e) => {
    card[e.target.dataset.key] = e.target.value
    // const newCard = {
    //   ...card,
    //   [e.target.name] : e.target.value
    // }

    setData(e.target.value)
    // setCard(newCard)
    setCard(card)
    setHasChanges(true)
    // console.log(`E change:::: `, card[e.target.dataset.key]) // test
  }

  const handleClickSave = () => {
    setDataIsSavedMessage()
    doSaveCard(dataCard)
    setHasChanges(false)
  }

  const handleConfirmSave = () => {
    handleClickSave()
    onClose()
    setShowConfirmModal()
  }

  const handleConfirmNoSave = () => {
    onClose()
    setShowConfirmModal()
    setHasChanges(false)
    // await setCard(dataCard)
  }

  

  return (
    <Container style={{ padding: '20px 50px 0px 50px' }}>
      {ITEM_LIST.map((item, index) => {
        return (
          <React.Fragment key={ITEM_LIST_KEY[index]}>
            {index === 0 || index === 6 || index === 8 ? (
              <SelectData
                index={ITEM_LIST_KEY[index]}
                label={`${index + 1}.  ${item}:`}
                placeholder={`Введите: ${item}`}
                text={'должно быть заполнено*'}
                arr={company}
                keyData={ITEM_LIST_KEY[index]}
                handleChangeInput={handleChangeInput}
                value={card[ITEM_LIST_KEY[index]]} // использовать при редактировании значений
                name={ITEM_LIST_KEY[index]}
              />
            ) : (
              <InputDataText
                index={ITEM_LIST_KEY[index]}
                label={`${index + 1}.  ${item}:`}
                placeholder={'не заполнено'}
                text={'должно быть заполнено*'}
                keyData={ITEM_LIST_KEY[index]}
                handleChangeInput={handleChangeInput}
                value={card[ITEM_LIST_KEY[index]]} // использовать при редактировании значений
                name={ITEM_LIST_KEY[index]}
              />
            )}
          </React.Fragment>
        )
      })}
      <div>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '20px' }}>
          <SaveCurrentDataButton
            handleSaveClick={handleClickSave}
            needDisable={false}
            savedDataMessage={dataIsSaved}
            buttonTextDefault={'Сохранить'}
            buttonTextSaved={'Данные сохранены'}
            needCustomIcon={false}
          />
        </div>
      </div>
      {showConfirmModal ? (
        <QuitForm
          onQuitWithSave={handleConfirmSave}
          onQuitWithoutSave={handleConfirmNoSave}
          closeQuitModal={setShowConfirmModal}
          modalTitle={'Выход'}
          modalBody={'Сохранить изменения?'}
          primaryButton={'Сохранить'}
          secondaryButton={'Отмена'}
          variant={'dark'}
        />
      ) : (
        ''
      )}
    </Container>
  )
}

export default ContentEditCardEquipment
