import React, { useRef, useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import PresentToAllOutlinedIcon from '@mui/icons-material/PresentToAllOutlined'
import { AiOutlineMessage } from 'react-icons/ai'
import SvgAngleDown from '../../icon/Svg_Angle_down'
import SvgAngleLeft from '../../icon/Svg_Angle_left'
import sendEmailFeedBack from './sendEmailFeedBack'
import PhoneMaskFeedback from './PhoneMaskFeedback'

export default function FeedBack() {
  const [isClicked, setIsClicked] = useState(false)
  const [position, setPosition] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [telError, setTelError] = useState(false)
  const [email, setEmail] = useState(sessionStorage.getItem('login'))
  const name = useRef(null)
  const tel = useRef(null)
  // const email = useRef(null)
  const message = useRef(null)

  const handleClick = () => {
    setIsClicked((prev) => !prev)
  }

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const clearAllInputs = () => {
    // setEmail('')
    // name.current.value = ''
    // tel.current.value = ''
    // message.current.value = ''

    if (name.current) name.current.value = ''
    if (tel.current) tel.current.value = ''
    if (message.current) message.current.value = ''
  }

  const handleSubmit = async () => {
    const userName = name.current ? name.current.value : ''
    const userTel = tel.current ? tel.current.value : ''
    const userMessage = message.current ? message.current.value : ''

    const userData = {
      name: userName,
      tel: userTel,
      email: email,
      message: userMessage,
      // emailInvite: 'info@lotus1.ru', //! адрес получателя
      emailInvite: 'lotus-uems@bk.ru', //! адрес получателя
      // emailInvite: 'anna.semenova.91@inbox.ru', //! адрес получателя
      subjectInvite: 'Обратная связь'
    }

    // отправка письма
    const resultSend = await sendEmailFeedBack(userData)
    console.log(`RESULT SEND EMAIL: `, resultSend) // test

    // проверка на ошибки ввода телефона и почты
    // закоментировала тк внедрили маску
    // if (resultSend.error && resultSend.message === 'Не указан или не верно указан телефон') {
    //   setTelError(true)
    //   setTimeout(() => {
    //     setTelError(false)
    //   }, 3000)
    // }

    if (resultSend.error && resultSend.message === 'Не указан или не верно указан Email') {
      setEmailError(true)
      setTimeout(() => {
        setEmailError(false)
      }, 3000)
    }

    console.log('userData: ', userData) //test
    clearAllInputs()
  }

  useEffect(() => {
    document.addEventListener('scroll', scrollHandler)

    return () => {
      document.removeEventListener('scroll', scrollHandler)
    }
  }, [])

  const scrollHandler = (e) => {
    if (
      e.target.documentElement.scrollHeight -
        (e.target.documentElement.scrollTop + window.innerHeight) >
      100
    ) {
      setPosition(true)
      // console.log('scrollHeight', e.target.documentElement.scrollHeight); //высота контента в элементе, включая содержимое, невидимое из-за прокрутки
      // console.log('scrollTop', e.target.documentElement.scrollTop); //сколько прокручено от верха элемента
      // console.log('innerHeight', window.innerHeight); //текущий размер окна браузера
    } else {
      setPosition(false)
    }
  }

  return (
    // <div className={`feedback ${isClicked ? '' : 'feedback_down'}`}>
    <div>
      {isClicked && (
        <div className={`feedback`}>
          <Button variant='info' className='feedback_btn' onClick={handleClick}>
            Свяжитесь с нами!
            <span className='feedback_icon'>
              {isClicked ? <SvgAngleDown colorSvg='#fff' /> : <SvgAngleLeft colorSvg='#fff' />}
            </span>
            {(emailError || telError) && isClicked ? (
              <div style={{ color: 'red' }}>Неверный email и(или) телефон</div>
            ) : (
              ''
            )}
          </Button>
          <Form.Group className='feedback_form'>
            <div style={{ position: 'relative' }}>
              <span className='upper_label_position'>Ваше имя</span>
              <Form.Control
                // className='feedback_input'
                type='text'
                placeholder='Введите Ваше имя'
                ref={name}
              ></Form.Control>
            </div>

            <div style={{ position: 'relative' }}>
              <span className='upper_label_position'>Номер телефона</span>
              {/* <Form.Control className={`feedback_input ${telError ? 'input_error' : ''}`} type='text' placeholder='Введите Ваш номер телефона' ref={tel}></Form.Control> */}

              <PhoneMaskFeedback
                id={5}
                width={14}
                show={true}
                label='Номер телефона'
                placeholder='Введите Ваш номер телефона'
                description='invitePhone'
                onChangeValue={() => {}}
                inputRef={tel}
              />
            </div>

            <div style={{ position: 'relative' }}>
              <span className='upper_label_position'>Электронная почта</span>
              <Form.Control
                className={`${emailError ? 'input_error' : ''}`}
                type='email'
                placeholder='Введите Ваш email'
                value={email}
                onChange={handleChange}
              ></Form.Control>
            </div>

            <div style={{ position: 'relative' }}>
              <span className='upper_label_position'>Ваше сообщение</span>
              <Form.Control
                // className='feedback_input'
                as='textarea'
                type='text'
                placeholder='Введите Ваше сообщение'
                rows={4}
                ref={message}
              />
            </div>

            <Button variant='info' className='feedback_submit_btn' onClick={handleSubmit}>
              <PresentToAllOutlinedIcon style={{ position: 'absolute', left: '100px' }} />
              Отправить
            </Button>
          </Form.Group>
        </div>
      )}
      {/* :  */}
      {/* ( */}
      <div
        className={`feedback_down ${position ? 'feedback_down_lower' : 'feedback_down_higher'} ${
          isClicked ? 'feedback_is_open' : ''
        }`}
        onClick={handleClick}
      >
        <AiOutlineMessage />
      </div>
      {/* ) */}

      {/* } */}
    </div>
  )
}
