import React from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp';
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';

export default class ModulQuit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: false,
            showOff: true
        }
        this.handleClickQuitWithSave = this.handleClickQuitWithSave.bind(this);
        this.handleClickQuitWithoutSave = this.handleClickQuitWithoutSave.bind(this);
        this.onKeyPressHandler = this.onKeyPressHandler.bind(this)
    }

    handleClickQuitWithSave() {
        this.props.onQuitWithSave();
    }

    handleClickQuitWithoutSave() {
        this.props.onQuitWithoutSave();
    }

    onKeyPressHandler(event) {
        if (event.keyCode === 13) {
            this.handleClickQuitWithSave()
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.onKeyPressHandler)
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPressHandler)
    }

    render() {
        return (
            <>
                <div className="modal_window">
                    <div className="shadow z_index_extra_big" style={{ zIndex: '20002111' }} onClick={this.props.closeQuitModal}></div>

                    <Modal.Dialog className='modal_common z_index_extra_big width_500' style={{ zIndex: '20002111' }} >
                        <Alert style={{ borderBottom: '1px #dee2e6 solid' }} dismissible onClose={this.props.closeQuitModal}>
                            <Modal.Title><ExitToAppSharpIcon fontSize="large" className='icon_leave_modal' /> Выход из формы</Modal.Title>
                        </Alert>

                        <Modal.Body>
                            <p>Сохранить изменения?</p>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="primary" onClick={this.handleClickQuitWithSave}>
                                <SaveOutlinedIcon style={{ marginRight: "5px" }}/>
                                Сохранить 
                            </Button>

                            <Button variant="secondary" onClick={this.handleClickQuitWithoutSave}>
                                <ExitToAppOutlinedIcon style={{ marginRight: "5px" }}/>
                                Не сохранять
                            </Button>
                        </Modal.Footer>
                    </Modal.Dialog>
                </div>
            </>
        )
    }
}