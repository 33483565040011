import React from 'react'
import { Button } from 'react-bootstrap'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

// nextPageClick={функция для левой кнопки}
// prevPageClick={функция для правой кнопки}
// needDisableButtons={бул - нужен ли дизэйбл кнопок}
// disableNextButtonCondition={условие для дизэйбла левой кнопки}
// disablePrevButtonCondition={условие для дизэйбла правой кнопки}

export default function LeftAndRightArrowsButtons({
  nextPageClick,
  prevPageClick,
  needDisableButtons,
  disableNextButtonCondition,
  disablePrevButtonCondition
}) {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '5px' }}>
      <Button
        variant='secondary'
        size='sm'
        onClick={prevPageClick}
        disabled={needDisableButtons && disablePrevButtonCondition}
        // disabled={needDisableButtons && data.length <= 20 ? true : false}
      >
        <KeyboardArrowLeftIcon />
      </Button>

      <Button
        variant='secondary'
        size='sm'
        // disabled={needDisableButtons && pageNumber === 1 ? true : false}
        disabled={needDisableButtons && disableNextButtonCondition}
        onClick={nextPageClick}
      >
        <ChevronRightIcon />
      </Button>
    </div>
  )
}
