/* eslint-disable no-array-constructor */
export const COMPANY = [
    'Изготовление сосудов и аппаратов работающих под давлением',//1
    'Поставка трубного проката',//2
    'Поставка листового проката',//3
    'Поставка сортового проката',//4
    'Поставка поковок',//5
    'Поставка днищ',//6
    'Поставка линзовых компенсаторов',//7
    'Поставка фланцев и/или фланцевых заготовок',//8
    'Поставка крепежных элементов',//9
    'Поставка обечаек',//10
    'Поставка гнутых труб и/или змеевиков',//11
    'Поставка отводов, переходов',//12
    'Поставка сварочных материалов',//13
    'Предоставление лицензий по переработке углеводородного сырья',//14
    'Предоставление займов и/или кредитов юридическим лицам',//15
    'Проектирование химического оборудования',//16
    'Проектирование промышленных объектов с использованием химического оборудования',//17
    'Строительно-монтажные работы на промышленных объектах',//18
    'Транспортные услуги',//19
    'Услуги генерального подрядчика',//20
    'Услуги лицензирования',//21
    'Услуги по механической обработке металла',//22
    'Услуги неразрушающих и разрушающих методов контроля',//23
    'Услуги обследования работающего оборудования',//24
    'Услуги проведения аудита предприятия',//25
    'Услуги по аттестации технологий сварки',//26
    'Услуги по аттестации технологий развальцовки',//27
    'Услуги по контролю качества изготовления оборудования',//28
    'Услуги по окраске изделий',//29
    'Услуги по резке и гибке листового металлопроката',//30
    'Услуги сертификации',//31
    'Услуги по термической обработке'//32
];


export const equipmentUnderPressure = [
    "Перечень оборудования производимого на предприятии",
    "Заготовительное производство",
    "Услуги по механической обработке металла",
    "Развальцовка",
    "Сварка",
    "Термическая обработка",
    " Испытания ",
    "Окраска поверхностей ",
    "Разработка РКД",
    "Система технологического контроля",
    "Лаборатория контроля качества  ",
    "Комплектующие(Гнутые трубы, Днища, Крепеж)",
    "Разрешительная документация"

]
export const pipeSupply = [
    'Поставка трубного проката'
]

export const sheetMetalSupply = [
    'Поставка листового проката'
]

// массив отключенных к выбору форм в "Профиль деятельности предприятия"
export const disabledCheck = [
    false, false, false, false, false, false, false, false, false, true, false, false, false, true, true, true, true, true, true, true, true, false, true, true, true, true, true, true, false, false, false, false
]

export const companyListExcludeDisabled = [
    'Изготовление сосудов и аппаратов работающих под давлением',//1
    'Поставка поковок',//5
    'Поставка днищ',//6
    'Поставка линзовых компенсаторов',//7
    'Поставка фланцев и/или фланцевых заготовок',//8
    'Поставка крепежных элементов',//9
    'Поставка обечаек',//10
    'Поставка гнутых труб и/или змеевиков',//11
    'Поставка отводов, переходов',//12
    'Проектирование химического оборудования',//16
    'Услуги по механической обработке металла',//22
    'Услуги по окраске изделий',//29
    'Услуги по резке и гибке листового металлопроката',//30
    'Услуги сертификации',//31
    'Услуги по термической обработке'//32
]


export const occupationTypes = [
    'Сосуды и аппараты работающие под давлением',//1
    'Трубный прокат',//2
    'Листовой прокат',//3
    'Сортовой прокат',//4
    'Поковки',//5
    'Днища',//6
    'Линзовые компенсаторы',//7
    'Фланцы и/или фланцевые заготовки',//8
    'Крепежные элементы',//9
    'Обечайки',//10
    'Гнутые трубы и/или змеевики',//11
    'Отводы, переходы',//12
    'Сварочные материалы',//13
    'Лицензии по переработке углеводородного сырья',//14
    'Займы и/или кредты юридическим лицам',//15
    'Проектирование химического оборудования',//16
    'Проектирование промышленных объектов с использованием химического оборудования',//17
    'Строительно-монтажные работы на промышленных объектах',//18
    'Транспортные услуги',//19
    'Услуги генерального подрядчика',//20
    'Лицензирование',//21
    'Механическая обработка металла',//22
    'Неразрушающие и разрушающие методы контроля',//23
    'Обследование работающего оборудования',//24
    'Проведение аудита предприятия',//25
    'Вальцовка',//26
    'Аттестация технологий развальцовки',//27
    'Контроль качества изготовления оборудования',//28
    'Окраска изделий',//29
    'Резка и гибка листового металлопроката',//30
    'Сертификация',//31
    'Термическая обработка',//32
    'Аттестация технологий сварки',//33

];




const chemicalEquipmentManufacturing = ['Zero', 'One', 'Two', 'Seven', 'Three', 'Five', 'Nine', 'Six', 'Eleven', 'Twelve', 'Thirteen', 'Eight', 'Fourteen'];
const forgings = ['FormForgings'];
const bottoms = ['FormBottoms'] //поставка днищ
const lensCompensators = ['FormLensCompensators'] //линзовые компенсаторы
const flanges = ['FormFlanges'];
// const servicesForCuttingAndBendingSheetMetal = ['Four'];
const metalMachiningServices = ['Two']; //механическая обработка
const FormEight = ['Eight'] // todo: обечайки Необходимо сделать отдельную форму для обечаек
const FormBendPipe = ['FormBendPipe']; //поставка гнутых труб и змеевиков
const FormBends = ['FormBends']; //Поставка отводов, переходов
const FormHardware = ['FormHardware']; //метизы
const FormRolling = ['FormRolling'];//вальцовка
const FormProductColoration = ['FormProductColoration'];//окраска изделий
const FormBendingCuttingMetal = ['FormBendingCuttingMetal'];//услуги по гибке и резке листового металла
const FormWelding = ['FormWelding']; //Поставка сварочных материалов
const FormHeatTreatment = ['FormHeatTreatment']//услуги по термической обработке листового металла
const FormRolled = ['FormRolled']//поставка сортового проката
const FormPipesSupply = ['FormPipesSupply']//поставка трубного проката
const FormSheetMetalSupply = ['FormSheetMetalSupply'] //поставка листового проката
export const all = ['One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine', 'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Zero', 'FormBendPipe', 'FormBottoms', 'FormLensCompensators', 'FormFlanges', 'FormForgings', 'FormBends', 'FormHardware', 'FormRolling', 'FormProductColoration', 'FormBendingCuttingMetal', 'FormHeatTreatment', 'FormWelding', 'FormRolled', 'FormPipesSupply', 'FormSheetMetalSupply']


export const FORM_ = new Array(
    chemicalEquipmentManufacturing, //0
    FormPipesSupply,//1
    FormSheetMetalSupply, //2
    FormRolled,//3
    forgings,//4
    bottoms,//5
    lensCompensators,//6
    flanges,//7
    FormHardware, //8
    FormEight,//9 //todo: обечайки - необходимо сделать отдельную форму
    FormBendPipe,//10
    FormBends, //11
    FormWelding,//12
    ['Main'],//13
    ['Main'],//14
    ['Main'],//15
    ['Main'],//16
    ['Main'],//17
    ['Main'],//18
    ['Main'],//19
    ['Main'],//20
    metalMachiningServices, //21
    ['Main'],//22
    ['Main'],//23
    ['Main'],//24
    ['Main'],//25
    FormRolling,//26
    ['Main'],
    FormProductColoration,
    FormBendingCuttingMetal,
    all,
    FormHeatTreatment
);