import React from 'react'


export const CloseBtnForFilter = ({ dataId, closeModal }) => {
    return (
        <div style={{ width: '20px', height: '20px', marginTop: '-10px', paddingTop: '0' }} data-position={dataId}>
            <span
                data-position={dataId ? dataId : ''}
                onClick={closeModal}
                style={{ cursor: 'pointer', fontSize: '2rem', color: '#7b7f83' }}
            >
                &#215;
            </span>
        </div>
    )
}