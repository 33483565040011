import React from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import ModalQuit from '../ModalQuit'
import TextareaForm from './TextareaForm'
import InputForm from './InputForm'
import CheckForm from './CheckForm'
import InvertColorsOutlinedIcon from '@mui/icons-material/InvertColorsOutlined'
import { nanoid } from 'nanoid'
import CommonAlert from './CommonAlert'
import AllModalsFooter from '../assets/AllModalsFooter'
import { SaveCurrentDataButton } from '../assets/BasicButtons'
import { isEqual } from 'lodash-es'
import { createData } from './functionsForm'

export default class FormProductColoration extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      show: true,
      nameForm: 'FormProductColoration',
      maxId: 9,
      dataValue: [],
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      showStud: false,
      showNut: false,
      showBolt: false,
      showShayba: false,
      showContent: true,
      previousState: [],
      savedDataMessage: false,
      dataBeenSaved: false
    }

    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.hideContent = this.hideContent.bind(this)
    this.setCompanyName = this.setCompanyName.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
  }

  saveDataWithNotification() {
    this.handleClickSave()

    this.setState({
      savedDataMessage: true,
      dataBeenSaved: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }

  hideContent() {
    this.setState({ showContent: false })
  }

  setCompanyName() {
    if (this.props.companyName && this.props.companyName.length > 0) {
      this.setState({
        companyName: this.props.companyName
      })
    }
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })
  }

  //* empty
  doEmpty() {}

  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  doQuitWithSave() {
    this.setState({ showQuit: false })
    this.handleClickSave()
    this.props.onChangeView(this.state.nameForm)
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView(this.state.nameForm)
  }

  //*формируем данные в массив объектов для отправки на сервер
  async handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach(dataD => {
        if (+dataD.id === i) {
          data[i] = {
            id: dataD.id,
            fid: dataD.fid,
            description: dataD.description,
            information: dataD.information,
            value: dataD.value
          }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach(dataE => {
        if (+dataE.id === i) {
          data[i] = {
            id: dataE.id,
            fid: dataE.fid,
            description: dataE.description,
            information: dataE.information,
            value: dataE.value
          }
        }
      })
    }
    this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере
    if (this.state.lengthDataFromServer < 1) {
      console.log('CREATE DATA')
      this.props.onCreateDataServer(data, this.state.nameForm, localStorage.getItem('idUser'))
    } else {
      console.log('UPDATE DATA')
      this.props.onUpdateDataOnServer(data, this.state.nameForm, localStorage.getItem('idUser'))
    }
    await console.log(this.state.dataOnServer) //test
    // this.handleClickShadow();
  }

  //* данные с полей формы, формируются в массив объектов
  async doChangeValue(data) {
    await this.setState({ dataValue: [...this.state.dataValue, data] })
    this.setState({
      dataBeenSaved: false
    })
  }

  compare() {
    const prev = this.state.previousState
    const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)

    const isStateEqual = isEqual(prev, curr)
    if (isStateEqual) {
      return true
    }
    return false
  }

  smartWindowFunction() {
    const isStateEqual = this.compare()
const { dataBeenSaved, dataValue } = this.state
    if (isStateEqual || dataBeenSaved || dataValue.length === 0) {
      this.props.onChangeView(this.state.nameForm)
    } else {
      this.handleClickShadow()
    }
  }

  async componentDidMount() {
    await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer })
    this.setCompanyName()
    if (this.props.data.length) {
      // const previousState = JSON.parse(JSON.stringify(this.props.data))
      this.setState({ previousState: this.props.data })
    }
  }

  render() {
    const view = this.props.view
    this.value = this.props.data
    const viewQuest = this.props.viewQuest

    //условие по которому компонент определяет находится ли он в формате чтения
    const inReadMode =
      this.props.innFromUniversalTable === null ||
      this.props.innFromUniversalTable === '' ||
      this.props.innFromUniversalTable === undefined

      // console.log(this.state.dataValue);

    return (
      <>
        {inReadMode && (
          <>
            {this.state.showQuit ? (
              <ModalQuit
                onQuitWithSave={this.doQuitWithSave}
                onQuitWithoutSave={this.doQuitWithoutSave}
                closeQuitModal={this.closeQuitModal}
              />
            ) : (
              ''
            )}
          </>
        )}

        {this.state.showContent && (
          <div className='modal_window' style={{ display: view ? 'block' : 'none' }}>
            <div className={viewQuest ? 'shadow_form' : 'shadow_form_off'} 
            // onClick={this.handleClickShadow}
            onClick={this.smartWindowFunction}
            ></div>
            <Form className={viewQuest ? 'form_main' : 'form_main_view'}>
              <CommonAlert
                inReadMode={inReadMode}
                handleClickHeader={this.handleClickHeader}
                // handleClickShadow={this.handleClickShadow}
                handleClickShadow={this.smartWindowFunction}
                hideContent={this.hideContent}
                icon={<InvertColorsOutlinedIcon fontSize='large' className='icon_primary' />}
                companyName={this.state.companyName}
                sectionName='Услуги по окраске изделий'
              />
              <div>
                <Container className='form_container'>
                  <Row> &nbsp; </Row>
                  <Form.Group style={{ display: this.state.show ? 'block' : 'none' }}>
                    <Row>
                      <Col>Максимальные габариты окрашиваемого изделия:</Col>
                    </Row>
                    <Row>
                      <InputForm
                        id={1}
                        width={3}
                        show={true}
                        verify='number'
                        label='Длина, мм'
                        fontSize={'12px'}
                        placeholder='Длина, мм'
                        description='Работа с ЛКМ, нанесение'
                        value={this.value[1] ? this.value[1].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={2}
                        width={3}
                        show={true}
                        verify='number'
                        label='Ширина, мм'
                        fontSize={'12px'}
                        placeholder='Ширина, мм'
                        description='Работа с ЛКМ, нанесение'
                        value={this.value[2] ? this.value[2].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={3}
                        width={3}
                        show={true}
                        verify='number'
                        label='Высота, мм'
                        fontSize={'12px'}
                        placeholder='Высота, мм'
                        description='Работа с ЛКМ, нанесение'
                        value={this.value[3] ? this.value[3].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <InputForm
                        id={4}
                        width={3}
                        show={true}
                        verify='number'
                        label='Масса изделия, тн'
                        fontSize={'12px'}
                        placeholder='Масса изделия, тн'
                        description='Работа с ЛКМ, нанесение'
                        value={this.value[4] ? this.value[4].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                  </Form.Group>
                  <Row> &nbsp; </Row>
                  <Form.Group>
                    <Row>
                      <Col>Способ нанесения:</Col>
                    </Row>
                  </Form.Group>

                  <Form.Group>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={5}
                        width={6}
                        show={true}
                        placeholder='Безвоздушное нанесение ЛКМ'
                        description='Окраска изделий'
                        value={this.value[5] ? this.value[5].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doEmpty}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                  </Form.Group>

                  <Form.Group>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={6}
                        width={6}
                        show={true}
                        placeholder='Нанесение ПФ эмали'
                        description='Окраска изделий'
                        value={this.value[6] ? this.value[6].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doEmpty}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                  </Form.Group>

                  <Form.Group>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={7}
                        width={6}
                        show={true}
                        placeholder='Порошковое ЛКМ'
                        description='Окраска изделий'
                        value={this.value[7] ? this.value[7].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doEmpty}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                  </Form.Group>

                  <Row> &nbsp; </Row>
                  <Row> &nbsp;</Row>

                  <Form.Group>
                    {inReadMode && (
                      <>
                        <Row> &nbsp; </Row>
                        <Row> &nbsp;</Row>
                        <InputForm
                          id={8}
                          width={12}
                          show={true}
                          // verify="number"
                          label={''}
                          fontSize={12}
                          placeholder='Комментарий:'
                          description={this.props.description}
                          value={this.value[8] ? this.value[8].value : ''}
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                        />
                        <TextareaForm
                          id={9}
                          width={12}
                          label=''
                          description='Дополнения к данному разделу'
                          placeholder='Дополнительная информация по данному виду деятельности (оборудование, технологии, автоматизация и др.'
                          value={this.value[9] ? this.value[9].value : ''}
                          show={true}
                          rows={3}
                          onChangeValue={this.doChangeValue}
                        />
                      </>
                    )}
                    <Row>&nbsp;</Row>
                  </Form.Group>
                  <Row>&nbsp;</Row>
                </Container>
                <AllModalsFooter
                  footerContent={
                    inReadMode && (
                      // <Button variant="success" onClick={this.handleClickSave} >Сохранить</Button>
                      // <SaveButtonGreen
                      //   handleSaveClick={this.handleClickSave}
                      //   needDisable={false}
                      //   saveButtonText='Сохранить'
                      // />
                      <SaveCurrentDataButton
                      handleSaveClick={this.saveDataWithNotification}
                      needDisable={false}
                      savedDataMessage={this.state.savedDataMessage}
                      buttonTextDefault={'Сохранить'}
                      buttonTextSaved={'Данные сохранены'}
                      needCustomIcon={false}
                    />
                    )
                  }
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}
