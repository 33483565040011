// dataOnServer - this.state.dataOnServer
// maxId - this.state.maxId
// dataFromServer - this.state.dataFromServer
// dataValue - this.state.dataValue
// lengthDataFromServer - this.state.lengthDataFromServer
// onCreateDataServer - this.props.onCreateDataServer
// onUpdateDataOnServer - this.props.onUpdateDataOnServer
// setState - this.setState({ dataOnServer: data })



export const handleSaveFormInfo = async (dataOnServer, maxId, dataFromServer, dataValue, lengthDataFromServer, onCreateDataServer, onUpdateDataOnServer, setState) => {

    try {
        let data = dataOnServer;

        for (let i = 0; i <= (maxId); i++) {

            //todo создаем позицию с пустым объектом
            data[i] = { id: '', fid: '', description: '', information: '', value: '' }

            //todo заполняем объект данными с сервера
            dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
            dataValue.forEach((dataE) => {
                if (+dataE.id === i) {
                    data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                }
            })

        }


        await setState()
        // setState(data)

        //todo проверка на наличие данных на сервере
        if (lengthDataFromServer < 1) {
            onCreateDataServer(data, 'Zero', localStorage.getItem('idUser'));
        } else {
            onUpdateDataOnServer(data, 'Zero', localStorage.getItem('idUser'));
        }

        console.log('i am fired from common functions');
    } catch (err) {
        console.log(err);
    }

}

