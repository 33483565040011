
import React from 'react';
import { Form } from 'react-bootstrap';

export default class CheckFormGostMetal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            language: 'rus',
            id: '',
            fid: '',
            description: '',
            information: '',
            value: '',
            colorError: '#fff'
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
    }

    handleBlur(e) {
        this.setState({
            id: e.target.dataset.id,
            fid: `${this.props.nameForm}_${this.props.id}`,
            description: e.target.dataset.description,
            information: e.target.dataset.information
        })
        setTimeout(() => { this.props.onChangeValue(this.state) })
    }

    handleChange(e) {
        this.setState({ value: e.target.checked })
        console.log(e.target.checked);//test
        this.props.onChangeVisionBlock(e.target.checked, e.target.dataset.id);
    }

    componentDidMount() {
        this.setState({ value: this.props.value })
    }

    render() {

        const id = this.props.id;
        const description = this.props.description;
        const placeholder = this.props.placeholder;
        const value = this.state.value;

        return (

            <React.Fragment key={id}>
                <Form.Check
                    id={this.props.name}
                    type="checkbox"
                    label={placeholder}
                    data-id={id}
                    data-information={placeholder}
                    data-description={description}
                    checked={value}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    className='gost_metal_input_style'//* положение checkbox вертикально
                    disabled={this.props.readOnly}
                />
            </React.Fragment>
        )
    }
}