import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

/**
 * Ввод данных из списка, массива
 * @param {*} param0
 * @returns
 */
const SelectData = ({ index, keyData, text, handleChangeInput, label, arr, value, name, disabled }) => {
  return (
    <React.Fragment key={index}>
      <Form.Group>
        <Row>
          <Col>
            <Form.Label>
              <b> {label} </b>
            </Form.Label>
          </Col>
          <Col>
            <Form.Control as='select' data-key={keyData} onChange={handleChangeInput} value={value} name={name} disabled={disabled} >
              <option> Выбрать Компанию... </option>
              {arr?.map((item) => {
                return (
                  <React.Fragment key={item.id}>
                    <option> {`${item.ownership ? item.ownership : ''} ${item.nameCompany}`} </option>
                  </React.Fragment>
                )
              })}
            </Form.Control>
            {/* <Form.Text className='text-muted'>{text}</Form.Text> */}
          </Col>
        </Row>
      </Form.Group>
    </React.Fragment>
  )
}
export default SelectData
