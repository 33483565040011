import React from 'react';
import { Card, Form, InputGroup } from 'react-bootstrap';
import InputWithPrepend from './InputWithPrepend';

export const Bankguarantees = ({
    bankContractExecutionGuarantee,
    bankContractExecutionGuaranteeInput,
    bankPrepayGuarantee,
    // bankPrepayGuaranteeInput,
    bankGuaranteeExecution,
    bankGuaranteeExecutionInput,
    contractAgreement,
    doChangeValueBank,
    doChangeValue }) => {

    return <Card style={{ border: '1px #ccc solid' }} className='box_shadow card_block'>
        <Card.Header className='trade_card_header' as='h6' >
            10. Дополнительные требования
        </Card.Header>
        <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
            <Form>
                <Form.Group>
                    <Form.Check
                        id={'bankContractExecutionGuarantee'}
                        name={'bankContractExecutionGuarantee'}
                        type="checkbox"
                        label="Банковская гарантия на исполнение договора"
                        checked={bankContractExecutionGuarantee}
                        onChange={doChangeValueBank}
                    />

                    {bankContractExecutionGuarantee &&
                        <InputGroup className="mb-2 mt-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputWithPrepend
                                id={'bankContractExecutionGuaranteeInput'}
                                width={3}
                                show={true}
                                verify="number"
                                placeholder="Например, 5%"
                                color={'#ccc'}
                                fontSize={'12px'}
                                value={bankContractExecutionGuaranteeInput}
                                onChangeValue={doChangeValue}
                            />
                        </InputGroup>
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        id={'bankPrepayGuarantee'}
                        name={'bankPrepayGuarantee'}
                        type="checkbox"
                        label="Банковская гарантия на предоплату"
                        checked={bankPrepayGuarantee}
                        onChange={doChangeValueBank}
                    />
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        id={'bankGuaranteeExecution'}
                        name={'bankGuaranteeExecution'}
                        type="checkbox"
                        label="Удержание суммы на исполнение гарантийных обязательств либо банковская гарантия"
                        checked={bankGuaranteeExecution}
                        onChange={doChangeValueBank}
                    />

                    {bankGuaranteeExecution &&
                        <InputGroup className="mb-2 mt-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>%</InputGroup.Text>
                            </InputGroup.Prepend>
                            <InputWithPrepend
                                id={'bankGuaranteeExecutionInput'}
                                width={3}
                                show={true}
                                verify="number"
                                placeholder="Например, 5%"
                                color={'#ccc'}
                                fontSize={'12px'}
                                value={bankGuaranteeExecutionInput}
                                onChangeValue={doChangeValue}
                            />
                        </InputGroup>
                    }
                </Form.Group>

                <Form.Group>
                    <Form.Check
                        id={'contractAgreement'}
                        name={'contractAgreement'}
                        type="checkbox"
                        label="Согласие с условиями договора"
                        checked={contractAgreement}
                        onChange={doChangeValueBank}
                    />
                </Form.Group>
            </Form>
        </Card.Body>
    </Card>

}
