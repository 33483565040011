//* Константы оповещения сотрудников компаний

// список подпунктов оповещения, привязанный к списку заглавий
export const EMPLOYEE_ALERT_TRADE = [
    { newTrade: 'Приглашение на новые торги' },
    { startTrade: 'Начало торгов' },
    { endTrade: 'Конец торгов' },
    { resultTrade: 'Результат торгов' },
]

// test
export const EMPLOYEE_ALERT_TRADE_TEST = [
    { newTrade2: 'Приглашение на новые торги2' },
    { startTrade2: 'Начало торгов2' },
    { endTrade2: 'Конец торгов2' },

]

// Массив для заглавий списка оповещений с прикрепленным списком подпунктов
export const EMPLOYEE_ALERT_TRADE_TITLE = [
    { tradeAlert: 'Оповещение торгов', list: EMPLOYEE_ALERT_TRADE },
    // { tradeAlert2: 'Оповещение торгов2', list: EMPLOYEE_ALERT_TRADE_TEST } // test
]




