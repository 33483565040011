import React, { useState, useEffect } from 'react'
import { Card, Form, Table } from 'react-bootstrap'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined'
import { nanoid } from 'nanoid'
import deleteFile from '../../assets/deleteFile'
import FormattedDate from './FormattedDate'

/**
 * Загрузка несколько файлов на сервер 
 * @param {*} param0
 * @returns
 */
const LoadFilesMultiple = ({
  item,
  index,
  keyConst,
  onSendDataFile,
  idCard,
  dataDocum,
  readFileDocument
}) => {
  const [file, setFile] = useState()
  const [fileName, setFileName] = useState()
  const [fileExt, setFileExt] = useState()
  const [fileExc, setFileExc] = useState()
  const [fileDate, setFileDate] = useState()
  const [newNameFile, setNewNameFile] = useState()
  const [datasetId, setDatasetId] = useState()
  const [datasetDescription, setDatasetDescription] = useState()
  const [dataDocumNew, setDataDocumNew] = useState()

  // console.log(`dataDocum >>>>>>>>>>>>>>>>> `, dataDocum) //test

  useEffect(() => {
    if (dataDocum) {
      setDataDocumNew(dataDocum)
    }
  }, [dataDocum])

  const onChangeFile = (e) => {
    // console.log(`files::: `, e.target.files) // test

    const files = e.target.files

    let arrFileName = []
    let arrFileExt = []
    let arrFileExc = []
    let arrDate = []

    for (let key in files) {
      if (Number.isFinite(Number(key))) {
        // console.log(`ITEM >>>> ${key} :::: `, files[key]) // test

        if (files[key].name.split('.').length <= 2) {
          arrFileName.push(files[key].name.split('.')[0]) //разделение по точке
          arrFileExt.push(files[key].type) // тип файла / расширение файла
          arrFileExc.push(files[key].name.split('.').pop()) //разделение по точке
          arrDate.push(files[key].lastModified) // дата модификации файла в мс

        } else {
          let fileNameNew = files[key].name.split('.')[0]

          for (let i = 1; i < files[key].name.split('.').length - 1; i++) {
            fileNameNew = `${fileNameNew}_${files[key].name.split('.')[i]}`
          }

          arrFileName.push(fileNameNew) //разделение по точке
          arrFileExt.push(files[key].type) // тип файла / расширение файла
          arrFileExc.push(files[key].name.split('.').pop()) //разделение по точке
          arrDate.push(files[key].lastModified) // дата модификации файла в мс
        }

      }
    }

    setFile(e.target.files) //приводим к blob значению
    setDatasetId(e.target.dataset.id) // ID
    setDatasetDescription(e.target.dataset.description) // description
    setFileName(arrFileName) //разделение по точке
    setFileExt(arrFileExt) // тип файла / расширение файла
    setFileExc(arrFileExc) //разделение по точке
    setFileDate(arrDate) // дата модификации файла
  }

  useEffect(() => {
    if (file) {
      // console.log(`SEARCH DATA FILEs >>>>>>>>>>> : `, file) //test

      // формируем новое имя файла для хранения в DB и на сервере
      let arrNewNameFile = []

      for (let key in file) {
        if (Number.isFinite(Number(key))) {
          if (fileExt) {
            arrNewNameFile.push(`${nanoid()}-${datasetId}_-${fileName[key]}.${fileExc[key]}`) //уникальное имя файла
          } else {
            arrNewNameFile.push(`${nanoid()}-${datasetId}_-${fileName[key]}`) //уникальное имя файла
          }

          //   console.log(`ARR NAME::: `, arrNewNameFile) // test
          setNewNameFile(arrNewNameFile)
        }
      }
    }
  }, [fileExt])

  useEffect(() => {
    if (newNameFile) {
      ; (async () => {
        try {
          let newDataForm = []
          for (let key in file) {
            if (Number.isFinite(Number(key))) {
              // console.log(`file ---> ${typeof file[key]} ::: `, file[key]) // test
              //* наполняем запрос к серверу
              newDataForm[key] = new FormData()
              newDataForm[key].append('key', 'weifeiph7Pie') //todo key - constanta
              newDataForm[key].append('filename', newNameFile[key]) //todo создать уникальный
              newDataForm[key].append('ext', fileExt[key])
              newDataForm[key].append('filedate', fileDate[key])
              newDataForm[key].append('session', sessionStorage.getItem('sessionId'))
              newDataForm[key].append('login', sessionStorage.getItem('login'))
              newDataForm[key].append('userid', localStorage.getItem('idUser')) //
              newDataForm[key].append('idCard', idCard) // ID Карточки аппарата
              newDataForm[key].append('file', file[key], fileName[key]) //в тело объекта новой формы добавили INPUT
            }
          }

          makeArrayDataFile(datasetId, fileName, newNameFile, datasetDescription, newDataForm)

        } catch (err) {
          console.log(`Ошибка при создании объекта: `, err)
        }
      })()
    }
    
  }, [newNameFile])

  /**
   * Удаление файла из DB и дискового пространства
   * @param {*} e 
   */
  const handleClickDelete = async (e) => {
    e.preventDefault();

    // todo: написать функцию удаления файла и DB и дискового пространства
    const data = {
      path: e.target.dataset.alfa,
      fileName: e.target.dataset.beta,
      originalName: e.target.dataset.gamma,
      idCard: e.target.dataset.idcard,
      idx: e.target.dataset.idx,
      description: e.target.dataset.description,
    }
   const result = await deleteFile(data) // удаление файла

    if (result) { 
      setDataDocumNew(prevState => {
          const newDataDocumNew = { ...prevState }
          const index = newDataDocumNew.path.findIndex(path => path === data.path)
          if (index > -1) {
              newDataDocumNew.path.splice(index, 1)
              newDataDocumNew.fileName.splice(index, 1)
          }
          return newDataDocumNew
      })
  }

  }

  /**
   * формируем объект загруженного файла
   * @param {String} id - index
   * @param {String} oldNameFile - старое названи файла
   * @param {String} description - ключ, описание раздела
   * @param {Object} newDataForm - объект файла
   */
  const makeArrayDataFile = (id, oldNameFile, newNameFile, description, newDataForm) => {
    let newData = {
      id: id,
      idCard: idCard,
      description: description,
      information: newNameFile,
      value: oldNameFile
    }

    onSendDataFile(newDataForm, newData) //* отправляем объект с файлом SEND DATA (форма, объект, maxID)
  }

  /**
   * формирование даты
   * @param {*} dataDocumNew 
   * @param {*} idx 
   * @returns 
   */

  const formattedDate = (dataDocumNew, idx) => {
    try {
      let timeStamp;
      // console.log(`FORMAT DATE`); // test

      if (dataDocumNew?.fileDate[idx]) {
        // console.log(`IDX::::: `, dataDocumNew?.fileDate[idx]); // test
        timeStamp = Number(dataDocumNew?.fileDate[idx])
      }
    
      let date = new Date(timeStamp)
      if (!timeStamp) date = new Date()

      let day = date.getDate()
      let month = (date.getMonth() + 1)
      if (day < 10) day = `0${day}`
      if (month < 10) month = `0${month}`

      const formattedDate = `${day}/${month}/${date.getFullYear()}`
      return formattedDate

    } catch (err) {
      console.log(`Ошибка формирования даты: `, err);
      let timeStamp2 = dataDocumNew?.dateOfCreation


      let date = new Date(Number(timeStamp2))
      let day = date.getDate()
      let month = (date.getMonth() + 1)
      if (day < 10) day = `0${day}`
      if (month < 10) month = `0${month}`

      const formattedDate = `${day}/${month}/${date.getFullYear()}`

      return formattedDate
    }

  }


  // console.log(`dataDocumNew::::: `, dataDocumNew) // test

  if (typeof (dataDocumNew?.path) === 'string') {
    dataDocumNew.path = [dataDocumNew.path]
    dataDocumNew.fileName = [dataDocumNew.fileName]
  }

  console.log('here', dataDocumNew?.path);
  return (
    <React.Fragment key={index}>
      <Form action='/server_data/file_documentation' method='post' encType='multipart/form-data'>
        <div style={{ padding: '6px 12px', fontWeight: 'bolder' }}>{`${index + 1}. ${item}`}</div>
        <Table bordered>
          <thead style={{ color: '#777' }}>
            <th className='width_50px'>ПП</th>
            <th>Дата</th>
            <th>Имя файла</th>
            <th className='width_50px'></th>
          </thead>
          <tbody>
            {dataDocumNew?.path.map((itm, idx) => {
              return (
                <tr key={`${dataDocumNew.dateOfCreation + idx}`}>
                  <td>{idx + 1}.</td>
                  {/* <td>{dataDocumNew?.fileName[index] ? formattedDate : ''}</td> */}
                  {/* <td>{dataDocumNew?.fileName[index] ? formattedDate(dataDocumNew, idx) : ''}</td> */}
                  <td>{dataDocumNew?.fileName[index] ? <FormattedDate dataDocumNew={dataDocumNew} idx={idx} formattedDate={formattedDate}/>  : formattedDate(dataDocumNew, idx)}</td>
                  <td
                    onClick={() => {
                      readFileDocument(itm)
                    }}
                    style={{ cursor: 'pointer', color: 'blue' }}
                  >
                    {dataDocumNew?.fileName[idx] ? dataDocumNew?.fileName[idx].split('-').pop() : ' '}
                  </td>
                  <td>
                    {dataDocumNew?.fileName[idx] ?
                      <button
                        style={{ cursor: 'pointer' }}
                        data-alfa={itm}
                        data-beta={dataDocumNew?.fileName[idx]}
                        data-gamma={dataDocumNew?.originalName[idx]}
                        data-idx={idx}
                        data-idCard={dataDocumNew?.idCard}
                        data-description={dataDocum?.description}
                        onClick={handleClickDelete}
                        className='btn_delete'
                        title='Delete'
                      >
                        <DeleteForeverOutlinedIcon sx={{ color: '#ED4880' }}
                          data-alfa={itm}
                          data-beta={dataDocumNew?.fileName[idx]}
                          data-gamma={dataDocumNew?.originalName[idx]}
                          data-idcard={dataDocumNew?.idCard}
                          data-description={dataDocum?.description}
                          data-idx={idx}
                          pointerEvents="none"
                        />
                      </button> : ' '}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Form.File
            style={{ display: 'none' }}
            id={index + 1}
            onChange={onChangeFile}
            accept={item}
            data-id={index + 1}
            // data-fid={`${this.props.nameForm}_${index + 1}`}
            multiple
            type='file'
            name='file'
            data-description={keyConst}
          // value={this.value[index + 1] ? this.value[index + 1].value : ''}
          />
          <label for={index + 1} className='custom_file_upload'>
            <AddBoxOutlinedIcon /> Выберите файл
          </label>
        </div>
      </Form>
    </React.Fragment>
  )
}
export default LoadFilesMultiple
