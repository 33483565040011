import React, { Component } from 'react'
import { Alert, Row } from 'react-bootstrap'
import RadioButtonThree from '../questionnaire/RadioButtonThree'
import EmployeeCheckBox from './EmployeesForms/EmployeeCheckBox'
import { companyListExcludeDisabled } from '../questionnaire/ConstListCompany'
import AllModalsFooter from '../assets/AllModalsFooter'
import { SaveCurrentDataButton } from '../assets/BasicButtons'
import EmployeeModalMini from './EmployeesForms/EmployeeModalMini'
import EmployeeAlertContex from './EmployeesForms/EmployeeAlertContex'

export class EmployeesModalSmall extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      showAdmin: false,
      isCheckBoxSectionOpen: false,
      dataCheck: [],
      accessRadioTypes: ['Админ', 'Нет доступа'],
      accessTypes: companyListExcludeDisabled,
      resultData: {},
      dataIsSaved: false
    }

    this.empty = this.empty.bind(this)
    this.handleChangeCheck = this.handleChangeCheck.bind(this)
    this.handleSaveData = this.handleSaveData.bind(this)

    this.doChangeValue = this.doChangeValue.bind(this)
    this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this)

    this.toggleModal = this.toggleModal.bind(this)
    this.setDataIsSavedMessage = this.setDataIsSavedMessage.bind(this)
    this.sendAlertContex = this.sendAlertContex.bind(this)
  }

  setDataIsSavedMessage = () => {
    this.setState({
      dataIsSaved: true
    })

    setTimeout(() => {
      this.setState({
        dataIsSaved: false
      })
    }, 1500)
  }

  toggleModal() {
    this.setState({ showAdmin: false })
  }

  doChangeVisionBlock(e, id) {
    if (e === 'Прочее') {
      this.setState({ isCheckBoxSectionOpen: true })
    }
    if (e === 'Админ' || e === 'Нет доступа') {
      this.setState({ isCheckBoxSectionOpen: false })
    }
  }

  /**
   * Меняет массив access в объекте сотрудника
   * @param {*} e
   */
  doChangeValue(e) {
    if (e.value === 'Админ') {
      console.log(e.value) // test
      this.setState({
        // dataCheck: [...this.state.dataCheck, e.value]
        dataCheck: [e.value]
        // dataCheck: ['Админ']
      })
      // *: Запуск модалки - с выбором пунктов оповещения
      this.setState({ showAdmin: true, isCheckBoxSectionOpen: false })
    }

    if (e.value === 'Нет доступа') {
      console.log(e.value) // test
      this.setState({
        // dataCheck: [...this.state.dataCheck, e.value]
        dataCheck: [e.value]
        // dataCheck: ['Нет доступа']
      })
      this.setState({ showAdmin: false, isCheckBoxSectionOpen: false })
    }

    if (e.value === 'Прочее') {
      this.setState({ showAdmin: false, isCheckBoxSectionOpen: true })
    }

    if (+e.id >= 2) {
      console.log(e.value) // test
      let dataCheckNew = this.state.dataCheck

      if (dataCheckNew.indexOf('Админ') !== -1) {
        dataCheckNew.splice(dataCheckNew.indexOf('Админ'), 1)
      } else if (dataCheckNew.indexOf('Нет доступа') !== -1) {
        dataCheckNew.splice(dataCheckNew.indexOf('Нет доступа'), 1)
      }
      this.setState({ dataCheck: dataCheckNew })

      this.handleChangeCheck(e.information)
    }
  }

  handleSaveData() {
    this.setDataIsSavedMessage()
    this.props.onChangeAccess(this.state.dataCheck)
    // this.props.toggleOptionsModal();
  }

  handleChangeCheck(data) {
    if (data) {
      this.setState({ dataCheck: [...this.state.dataCheck, data] })
    } else {
      let position = this.state.dataCheck.indexOf(data)
      if (position) {
        let dataP = this.state.dataCheck
        dataP.splice(position, 1)
        this.setState({ dataCheck: dataP })
      }
    }
  }

  /**
   * Получение объекта оповещения
   * @param {*} data
   */
  sendAlertContex(data) {
    this.props.sendAlert(data)
  }

  componentDidMount() {
    console.log(`this.props.resultData:::: `, this.props.resultData) // test
    this.setState({
      resultData: this.props.resultData,
      dataCheck: this.props.resultData.access
    })

    //запуск панели оповещения, если допуск - Админ
    if (this.props.resultData?.access?.includes('Админ')) {
      this.setState({ showAdmin: true })
    }
  }

  empty() {}

  render() {
    console.log(
      `>>>> `,
      this.props?.resultData?.access,
      this.props?.resultData?.access?.includes('Админ')
    ) // tests
    return (
      <>
        {/* {this.state.showAdmin &&
                    <EmployeeModalMini
                        toggleModal={this.toggleModal}
                        alertHeading={` Назначить оповещение `}
                        modalContent={`12345`}
                        sendAlertContex={this.sendAlertContex}
                        valueData={this.props.resultData}
                    />
                } */}
        <div onClick={this.props.handleClickShadow} className='shadow_form'></div>
        <div
          className='employees_modal_small p_40 z_index_19'
        //   style={{ marginBottom: '72px' }}
          onChange={this.handleRadioChange}
        >
          <Alert
            variant='dark'
            className='header_alert_common '
            onClick={this.props.toggleOptionsModal}
            dismissible
          >
            <Alert.Heading>Вид доступа сотрудника</Alert.Heading>
          </Alert>
          <RadioButtonThree
            id={1}
            width={12}
            show={true}
            title=''
            label1='Админ'
            label2='Прочее'
            label3='Нет доступа'
            placeholder='Доступ'
            description='Доступ'
            // value={this.value[12] ? this.value[12].value : ''}
            value={
              this.props?.resultData?.access?.includes('Админ')
                ? 'Админ'
                : this.props?.resultData?.access?.includes('Прочее')
                ? 'Прочее'
                : 'Нет доступа'
            }
            onChangeValue={this.doChangeValue}
            onChangeVisionBlock={this.doChangeVisionBlock}
            nameForm={this.state.nameForm}
          />


          {this.state.isCheckBoxSectionOpen && (
            <div className='modal_small_access'>
              {this.state.accessTypes.map((item, index) => (
                <React.Fragment key={index}>
                  <div key={item}>
                    <EmployeeCheckBox
                      id={index + 2}
                      show={true}
                      placeholder={item}
                      description='Допуск сотрудника'
                      value={this.props?.resultData?.access?.includes(item) ? { item } : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                    />
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}

          {this.state.showAdmin && (
            <div className='modal_small_access'>
            <EmployeeAlertContex
              toggleModal={this.toggleModal}
              alertHeading={` Назначить оповещение `}
              modalContent={`12345`}
              sendAlertContex={this.sendAlertContex}
              valueData={this.props.resultData}
            />
            </div>
          )}
          <Row> &nbsp;</Row>
          <AllModalsFooter
            isPosAbsoluteNedded={true}
            footerContent={
              <SaveCurrentDataButton
                handleSaveClick={this.handleSaveData}
                needDisable={false}
                savedDataMessage={this.state.dataIsSaved}
                buttonTextDefault={'Сохранить'}
                buttonTextSaved={'Данные сохранены'}
              />
            }
          />
        </div>
      </>
    )
  }
}
