import React from 'react'
import RefreshIcon from '@mui/icons-material/Refresh';

export default function RefreshButton({ handleRefresh }) {
    return (
        <div onClick={handleRefresh}
            className='update_button'
            title='Обновить'
        >
            <RefreshIcon />
        </div>
    )
}
