import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
// import FileModal from "../file/FileModal";
import server from "../server/server";
import QuestionToChatBtn from "../trade-participant/QuestionToChatBtn";
// import FileModalCustomer from "./FileModalCustomer";
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import FileDownloadOffOutlinedIcon from '@mui/icons-material/FileDownloadOffOutlined';


const CheckDocuments = ({ listForFiles, addList, idLot, sendObj, valueFileList, runChat, organizerName, participant }) => {

    const numberOfLot = idLot ? `лот № ${idLot};` : ''

    const [value, setValue] = useState()
    const [data, setData] = useState([])
    const [show, setShow] = useState(true)

    useEffect(() => {
        setValue(listForFiles)
        // console.log(`CHANGE value `); // test
    }, [listForFiles])

    useEffect(() => {
        setShow(false)
        setData(valueFileList)
        // console.log(`valueFileList:::: `, valueFileList); // test
        setTimeout(() => { setShow(true) }, 1000)
    }, [valueFileList])

    const handleChange = (event) => {
        // console.log(`DATA::::: `, value); // test
        const result = value.map(item => {

            if (item.id === event.target.dataset.id) {
                return (
                    {
                        id: item.id, description: event.target.value
                    }
                )
            } else {
                return (
                    { id: item.id, description: item.description }
                )
            }

        })

        setValue(result)
        addList(result)
    }

    const doSaveFile = async (dataD, index) => {
        let d = data;
        if (data) {
            d[index] = dataD;
            setData(d)
            // console.log(`DATA ARRAY:::: `, d); // test
            sendObj(d)
        }
    }

    const deletePosition = (e) => {
        setShow(false)
        let d = data;
        if (d[e]) {
            delete d[e]
        }
        setData(d)
        // console.log(`DATA DELETE :::: `, d); // test
        sendObj(d)
        setTimeout(() => { setShow(true) }, 250)
    }

    const handleClickChat = (text, recipient) => {
        console.log(`TEXT CHAT::: `, text);
        runChat(text, recipient);
    }

    const doEmpty = (e) => {
        // console.log(`doEmpty - Run >>>> `, e);
    }


    const handleClickReadFile = async (fileName) => {
        // console.log(`FILE NAME >>>>>>>>>>>> `, fileName);//test
        const result = await server.readFileFromServer(fileName);

        // console.log(valueFileList); // test
        // console.log(fileName);

        if (result) {
            // console.log(`RESULT !!!!!!!!!!!!!!!!!! >>>>>>>>>>> `, result); // test

            const linkBlob = window.URL.createObjectURL(result);
            const link = document.createElement('a');
            link.href = linkBlob
            // link.target = "_blank"
            // link.download = fileName
            // link.download = valueFileList.copyNameFile//! меняем имя файла при загрузке
            link.download = valueFileList.map(el => {
                if (el.pathFile === fileName) {
                    return el.pathFile
                }
            })
            // link.download = fileName.split('/').
            document.body.appendChild(link)
            link.click()
            link.remove()
        }

    }

    return (
        <>

     {show &&

                    <Table bordered hover style={{ backgroundColor: '#fff' }}>
                        <thead>
                            <tr style={{ textAlign: 'center' }}>
                                <td style={{ width: '50px' }}>п/п</td>
                                <td>Требуемый документ</td>
                                {/* <td> Загрузка файлов </td> */}
                                <td> Файл к документу</td>
                                <td> Описание файла </td>
                                {participant && <td> Сохранить описание </td>}
                                <td> Скачать файл </td>
                                {participant && <td> Удалить файл </td>}
                                <td>Задать вопрос</td>
                            </tr>
                        </thead>
                        <tbody>
                            {value && value.map((item, index) => {

                                if (item.archive) return <React.Fragment key={index}></React.Fragment>

                                return <React.Fragment key={index}>
                                    <tr>
                                        <td>{index + 1}</td>
                                        <td data-id={item.id}
                                            data-description={item.description}
                                            onChange={handleChange}
                                        >
                                            {item.description}
                                        </td>
                                        <td>
                                            {(data && data[index]) && data[index].oldNameFile ? data[index].oldNameFile : 'Файл не прикреплен'}

                                            {/* <FileModal
                                            index={index}
                                            oldNameFile={(data && data[index]) && data[index].oldNameFile}
                                            newName={idLot}
                                            newNameFile={(data && data[index]) && data[index].newNameFile}
                                            copyNameFile={(data && data[index]) && data[index].copyNameFile}
                                            pathFile={(data && data[index]) && data[index].pathFile}
                                            typeFile={(data && data[index]) && data[index].typeFile}
                                            size={(data && data[index]) && data[index].size}
                                            descriptionFile={(data && data[index]) && data[index].descriptionFile}
                                            onSaveFile={doSaveFile}
                                            deletePosition={deletePosition}
                                            // toNextTab={this.props.toNextTab}
                                            getDisableDownload={doEmpty}
                                            table={true} // при TRUE меняет верстку
                                            customer={customer}
                                        /> */}
                                        </td>
                                        <td>
                                            {data && data[index] && data[index].descriptionFile ? data[index].descriptionFile : 'Описание отсутствует'}
                                        </td>

                                        {participant &&
                                            <td style={{ textAlign: 'center', cursor: 'pointer' }} onClick={doSaveFile}>
                                                {(data && data[index]) && data[index].pathFile && (data && data[index]) && data[index].pathFile.length !== 0 ?
                                                    <CheckOutlinedIcon color={'success'} /> :
                                                    <SaveOutlinedIcon color={'success'} />}
                                            </td>}

                                        {(data && data[index]) && data[index].pathFile ?

                                            <td
                                                style={{ textAlign: 'center', cursor: 'pointer' }}
                                                onClick={() => handleClickReadFile(data[index].pathFile)}>
                                                <DownloadOutlinedIcon color={'primary'} />
                                            </td>
                                            :
                                            <td
                                                style={{ textAlign: 'center' }}>
                                                <FileDownloadOffOutlinedIcon color={'action'} />
                                            </td>

                                        }

                                        {participant &&
                                            <td onClick={deletePosition}
                                                style={{ textAlign: 'center', cursor: 'pointer' }}>
                                                <DeleteForeverOutlinedIcon style={{ color: '#dc3545' }} />
                                            </td>}
                                        <td>
                                            <div
                                                style={{ display: 'flex', height: '100%', justifyContent: 'center' }}
                                            >
                                                <QuestionToChatBtn handleClick={handleClickChat}
                                                    details={{
                                                        param: `
                                                ${numberOfLot}
                                                пункт 13;
                                                к файлу № ${index + 1}, 
                                                ${item.description ? item.description : `фаил ${index + 1}`}`,
                                                        recipient: organizerName
                                                    }} />
                                            </div>


                                        </td>
                                    </tr>
                                </React.Fragment>
                            })}
                        </tbody>
                    </Table>
                }</>
    )
}

export default CheckDocuments;