/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import Form_Three_Add from './Form_Three_Add'
import ModalQuit from '../ModalQuit'
import FlareOutlinedIcon from '@mui/icons-material/FlareOutlined'
import CommonAlert from './CommonAlert'
import AllModalsFooter from '../assets/AllModalsFooter'
import { AddButton, SaveCurrentDataButton } from '../assets/BasicButtons'
import { createData } from './functionsForm'
import { isEqual } from 'lodash-es'

export default class Form_Three extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'rus',
      dataValue: [],
      id: 3,
      maxId: 4,
      nameForm: 'Three',
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      base: [],
      showQuit: false,
      show: false,
      arrayChoice: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
      showContent: true,
      inReadMode: true,
      previousState: [],
      savedDataMessage: false,
      dataBeenSaved: false
    }

    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.handleClickAdd = this.handleClickAdd.bind(this)
    this.addForm = this.addForm.bind(this)
    this.createBlockForm = this.createBlockForm.bind(this)
    this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.doChoiceOption = this.doChoiceOption.bind(this)

    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.setCompanyName = this.setCompanyName.bind(this)
    this.hideContent = this.hideContent.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
  }

  saveDataWithNotification(){
    this.handleClickSave()

    this.setState({
      savedDataMessage: true,
      dataBeenSaved: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }


  setCompanyName() {
    if (this.props.companyName && this.props.companyName.length > 0) {
      this.setState({
        companyName: this.props.companyName
      })
    }
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })
  }

  doChoiceOption(choice) {
    this.setState({ arrayChoice: choice })
  }

  //*Пустышка
  doEmpty() {}

  //* меняем видимость блока при выборе ДА / НЕТ
  doChangeVisionBlock(data, id) {}

  handleClickAdd() {
    this.addForm()
  }

  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  doQuitWithSave() {
    this.setState({ showQuit: false })
    this.handleClickSave()
    this.props.onChangeView('Three')
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView('Three')
  }

  //*формируем данные в массив объектов для отправки на сервер
  handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach((dataD) => {
        if (+dataD.id === i) {
          data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach((dataE) => {
        if (+dataE.id === i) {
          data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
        }
      })
    }
    this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере
    if (this.state.lengthDataFromServer < 1) {
      console.log('CREATE DATA')
      this.props.onCreateDataServer(data, 'Three', localStorage.getItem('idUser'))
    } else {
      console.log('UPDATE DATA')
      this.props.onUpdateDataOnServer(data, 'Three', localStorage.getItem('idUser'))
    }
    setTimeout(() => {
      console.log(this.state.dataOnServer)
    }) //test
    // this.handleClickShadow();
  }

  //* данные с полей формы, формируются в массив объектов
  doChangeValue(data) {
    console.log(data) //test
    this.setState({ dataValue: [...this.state.dataValue, data] })
    setTimeout(() => {
      console.log(this.state.dataValue)
    }) //test

    this.setState({
        dataBeenSaved: false
    })
  }

  addForm() {
    this.setState({
      base: [
        ...this.state.base,
        <React.Fragment key={this.state.id}>
          <Form_Three_Add id={this.state.id} value={this.props.data} onChangeValue={this.doChangeValue} onChoiceOption={this.doChoiceOption} arrayChoice={this.state.arrayChoice} nameForm={this.state.nameForm} readOnly={!this.state.inReadMode} />
        </React.Fragment>
      ],
      id: +this.state.id + 3,
      maxId: +this.state.maxId + 3
    })
  }

  //*создаем необходимое количество блоков
  createBlockForm() {
    let lengthData = this.state.lengthDataFromServer
    let data = this.state.dataFromServer

    this.addForm()

    for (let i = 6; i < lengthData; i++) {
      if ([6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45].includes(i) && Number(data[i].id > 0)) {
        this.addForm()
      }
    }
  }

  hideContent() {
    this.setState({ showContent: false })
  }

  compare() {
    const prev = this.state.previousState
    const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)

    const isStateEqual = isEqual(prev, curr)
    if (isStateEqual) {
      return true
    }
    return false
  }

  smartWindowFunction() {
    const isStateEqual = this.compare()
    const { dataBeenSaved, dataValue } = this.state
    if (isStateEqual || dataBeenSaved || dataValue.length === 0) {
      this.props.onChangeView('Three')
    } else {
      this.handleClickShadow()
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer })
      // this.forceUpdate();
    }, 1000)
    setTimeout(() => {
      this.createBlockForm()
      // this.setState({ id: +this.state.id - 7, maxId: +this.state.maxId - 7 });
    }, 1500)

    this.setState({
      inReadMode: this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined
    })

    this.setCompanyName()

    if (this.props.data.length) {
      const previousState = JSON.parse(JSON.stringify(this.props.data))
      this.setState({ previousState })
    }
  }

  render() {
    // console.log('RENDER', this.state.dataValue);

    const view = this.props.view
    this.value = this.props.data
    const viewQuest = this.props.viewQuest

    //условие по которому компонент определяет находится ли он в формате чтения
    const inReadMode = this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined

    return (
      <>
        {inReadMode && <>{this.state.showQuit ? <ModalQuit onQuitWithSave={this.doQuitWithSave} onQuitWithoutSave={this.doQuitWithoutSave} closeQuitModal={this.closeQuitModal} /> : ''}</>}

        {this.state.showContent && (
          <div className='modal_window' style={{ display: view ? 'block' : 'none' }}>
            <div
              className={viewQuest ? 'shadow_form' : 'shadow_form_off'}
              // onClick={this.handleClickShadow}
              onClick={this.smartWindowFunction}
            ></div>
            <Form className={viewQuest ? 'form_main' : 'form_main_view'}>
              <CommonAlert
                inReadMode={inReadMode}
                handleClickHeader={this.handleClickHeader}
                // handleClickShadow={this.handleClickShadow}
                handleClickShadow={this.smartWindowFunction}
                hideContent={this.hideContent}
                icon={<FlareOutlinedIcon fontSize='large' className='icon_primary' />}
                companyName={this.state.companyName}
                sectionName='Сварка'
              />
              <div>
                <Container className='form_container'>
                  <Form.Group>
                    {/* <Row> &nbsp; </Row> */}
                    <Row>
                      <Col className='f_size_1em mt_10'>Максимальная толщина свариваемых деталей по каждому виду сварки</Col>
                    </Row>
                    <Row> &nbsp; </Row>

                    {this.state.base}

                    {inReadMode && (
                      <>
                        <Row> &nbsp; </Row>
                        <Col>
                          <AddButton handleSaveClick={this.handleClickAdd} needDisable={false} buttonText='позицию' />

                          {/* <Button variant='info' onClick={this.handleClickAdd} className='btn_info_filled'> <AddOutlinedIcon className='p_3' />Добавить позицию </Button> */}
                        </Col>

                        <Row> &nbsp; </Row>
                      </>
                    )}
                  </Form.Group>
                  <Row> &nbsp; </Row>
                </Container>
                <AllModalsFooter
                  footerContent={
                    inReadMode && 
                    // <SaveButtonGreen handleSaveClick={this.handleClickSave} needDisable={false} saveButtonText='Сохранить' />
                    <SaveCurrentDataButton
                    handleSaveClick={this.saveDataWithNotification}
                    needDisable={false}
                    savedDataMessage={this.state.savedDataMessage}
                    buttonTextDefault={'Сохранить'}
                    buttonTextSaved={'Данные сохранены'}
                    needCustomIcon={false}
                    
                    />

                    // <Button variant="success" onClick={this.handleClickSave}> Сохранить</Button>
                  }
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}
