import { useState } from 'react';

export const usePagination = (initialPageSize = 12, initialData = []) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);

  const getPagedData = () => {
    const reversedData = initialData.slice().reverse();
    const start = currentPage * pageSize;
    const end = start + pageSize;
    return reversedData.slice(start, end).reverse();
  }

  const goToNextPage = () => {
    const maxPages = Math.ceil(initialData.length / pageSize);
    if (currentPage < maxPages - 1) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  }

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  }

  return {
    currentPage,
    pageSize,
    setPageSize,
    getPagedData,
    goToNextPage,
    goToPreviousPage
  };
}


export const usePaginationTest = (initialPageSize = 12, initialData = []) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(initialPageSize);

  const getPagedData = () => {
    const reversedData = initialData.slice()
    const start = currentPage * pageSize;
    const end = start + pageSize;
    return reversedData.slice(start, end)
  }

  const goToNextPage = () => {
    const maxPages = Math.ceil(initialData.length / pageSize);
    if (currentPage < maxPages - 1) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  }

  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  }

  return {
    currentPage,
    pageSize,
    setPageSize,
    getPagedData,
    goToNextPage,
    goToPreviousPage
  };
}