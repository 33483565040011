import server from '../server/server'

/**
 * Рассылка сообщений о результате торгов (участникам и победителю)
 * @param {String} idLot ID LOT
 * @param {Array} participant массив участников
 * @param {String} innWinner ИНН победителя
 */
const sendMessageWinner = async (idLot, participant, innWinner) => {
    try {
        const result = await server.inviteAlertEmployeesSendResultTrade(idLot, participant, innWinner)
        console.log(`SEND result trade: `, result); // test

    } catch (err) {
        console.log(`Ошибка при отправке сообщений участникам торгов и победителю: `, err);
    }

}
export default sendMessageWinner;