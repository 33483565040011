import React from 'react';
import { Button } from "react-bootstrap";

const ChatIdLots = ({ arrayAll, resultClickId }) => {

    const handleClick = (e) => {
        resultClickId(e.target.dataset.id)
    }

    console.log(`CHAT SELECT`); // test

    return (
        <>
            <div
                className="navibar_drop_menu_main"
            >
                <div
                    className="navibar_drop_menu"
                >
                    {
                        arrayAll.arrayNameLot.map((item, index) => {
                            return (
                                <Button
                                    key={String(index)}
                                    style={{ margin: '3px' }}
                                    data-id={arrayAll.arrayIdLot[index]}
                                    onClick={(e) => handleClick(e)}
                                >
                                    ЛОТ: № {item}
                                </Button>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
};

export default ChatIdLots;