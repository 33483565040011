import React, { useEffect, useState } from 'react'
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined'
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined'
import { Modal, Alert } from 'react-bootstrap'
import server from '../server/server'
import ParticipantLot from './ParticipantLot'
import PositionChoice from './PositionChoice'
import ChatWs from '../chat/ChatWs'
import { nanoid } from 'nanoid'
import QuitModal from '../QuitModal'
import { isEqual } from 'lodash-es'

const ConfirmationLot = ({ idLot, toggleConfirmationLot, doReLoadTable }) => {
  const [dataLot, setDataLot] = useState({})
  const [productionArray, setProductionArray] = useState([])
  const [show, setShow] = useState(false)
  const [resultPosition, setResultPosition] = useState({})
  const [objConfirmation, setObjConfirmation] = useState({
    idLot: '',
    participantCompany: '',
    idFile: '',
    idFileList: '',
    data: [],
    tradePosition: []
  })
  const [valueConfirmation, setValueConfirmation] = useState()
  const [sum, setSum] = useState(0)
  const [showQuitModalInParticipantLot, setShowQuitModalInParticipantLot] = useState(false)
  const [runChat] = useState()
  const [showChat, setShowChat] = useState(false)
  const [chatPreMessage, setChatPreMessage] = useState('')
  const [recipient, setRecipient] = useState('')
  const [fireSaveFn] = useState(false)
  const [showRejectModal, setRejectModal] = useState(false) //модалка при отказе от участия в лоте (кнопка ВЫХОД)
  const [showUnsuitableConditionModal, setShowUnsuitableConditionModal] = useState(false)
  const [showCondirmModal, setShowCondirmModal] = useState(false)
  const [dataSentToCustomerMsg] = useState(false)
  const [prev, setPrev] = useState([])
  const [curr, setCurr] = useState([])
  const [stateBeenSaved, setStateBeensaved] = useState(false)


  // console.log('prev', prev);
  // console.log('curr', curr);

  //получаем первоначальный стейт при входе для сравнения с текущим для умного окна
  useEffect(() => {
    ; (async () => {
      const valueData = await server.getDataFromServer(
        `${idLot}_user_${localStorage.getItem('idUser')}`,
        'trade_confirmation'
      )
      if (valueData) {
        // const previous = JSON.parse(JSON.stringify(valueData.data))
        setPrev(valueData.data)
      }
    })()
  }, [])

  useEffect(() => {
    ; (async () => {
      const result = await server.getDataFromServer(idLot, 'trade')
      setDataLot(result.data)
      setProductionArray(result.dataChoiceProductions)
      // console.log(result); // test

      const valueData = await server.getDataFromServer(
        `${idLot}_user_${localStorage.getItem('idUser')}`,
        'trade_confirmation'
      )

      if (valueData) {
        if (!valueData.idFilePosition) {
          valueData.idFilePosition = nanoid()
        }

        setValueConfirmation(valueData)
        // console.log(`CONFIRMATION DATA:::: `, valueData); // test
        // console.log(`CONFIRMATION POSITION ARRAY: `, valueData.tradePosition); // test
        addValuePositionInObjConfirmation(valueData.tradePosition)

        // console.log(valueData.tradePosition);
        if (valueData.tradePosition.length) {
          sumUpPrice(valueData.tradePosition)
        }
      }
    })()
  }, [objConfirmation])

  // console.log(dataLot);

  useEffect(() => {
    ; (async () => {
      try {
        if (objConfirmation?.idLot?.length > 0) {
          await server.sendDataOnServer(
            objConfirmation,
            `${objConfirmation.idLot}_user_${localStorage.getItem('idUser')}`,
            'trade_confirmation'
          )

          // TODO: Вносить изменения в ЛОТ, в participantCondition
        }
      } catch (err) {
        console.log(`Ошибка записи на сервер подтверждений: `, err)
      }
    })()
  }, [objConfirmation])

  // const rejectToParticipate = () => {
  //   setRejectModal(true)
  // }

  // const unsuitableCondition = () => {
  //   setShowUnsuitableConditionModal(true)
  // }

  //* Запуск сохранения результатов подтверждения
  const compareConfirmationResult = lotId => {
    ; (async () => {
      try {
        const idConfirm = `${lotId}_user_${localStorage.getItem('idUser')}`
        // console.log(`compareConfirmationResult CLICK::  ID  :: `, idConfirm); // test
        const result = await server.compareConfirmationResult(idConfirm) // * при вервом проходе добавляет объекты
        const result2 = await server.compareConfirmationResult(idConfirm) // * при повторном собирает сводную таблицу

        if (result.ERROR) throw new Error(result.message)
        if (result2.ERROR) throw new Error(result.message)
        // console.log(`Result: `, result);
      } catch (err) {
        console.log(`Ошибка запуска сравнения результатов ответа участника: `, err)
      }
    })()
    // setShowCondirmModal(false)
    // setDataSentToCustomerMsg(true)
    // setTimeout(() => {
    //   setDataSentToCustomerMsg(false)
    // }, 3000)
  }

  const launchQuitModalInParticipantLot = () => {
    setShowQuitModalInParticipantLot(!showQuitModalInParticipantLot)
    console.log(`CLOSE modal`) // test
    doReLoadTable()
  }

  const addValuePositionInObjConfirmation = arrPosition => {
    const resCon = objConfirmation
    resCon.tradePosition = arrPosition
    setObjConfirmation(resCon)
  }

  const sendPositionData = data => {
    // console.log(`ConfirmationLot, RESULT UP DATA::: `, data); // todo Данные от торговой позиции, формируются при закрытии окна
    const arrayPosition = objConfirmation.tradePosition
    const resultFilter = arrayPosition.filter(item => item.idPosition !== data.idPosition)
    resultFilter.push(data)
    // TODO: суммируем НМЦ позиций

    // console.log(`objConfirmation::: `, objConfirmation); // test
    const resCon = objConfirmation
    resCon.tradePosition = resultFilter
    // console.log(`OBJ::: `, resCon); // test
    setObjConfirmation(resCon)
  }

  const sumUpPrice = arr => {
    // console.log(arr);
    try {
      let sum = 0
      arr.forEach(position => {
        if (position.dataComm && position.dataComm.length !== 0) {
          const sumPos = Number(position.dataComm.find(item => item.id === '10').value)
          const quantity = position.dataComm.find(item => item.id === '6').value

          if (sumPos && quantity && typeof quantity === 'string') sum = sum + sumPos * Number(quantity)
          if (sumPos && quantity && typeof quantity === 'boolean') sum = sum + sumPos * 1
        }
      })
      setSum(sum)
    } catch (err) {
      console.log(err)
    }
  }

  const choicePositionId = async e => {
    const result = await server.getDataFromServer(e, 'trade_position')
    setResultPosition(result)

    // console.log(`RESULT POSITION DATA:::: `, result); // test
    setShow(true)
  }

  // закрываем торг позицию
  const onClosePosition = () => {
    // console.log(`close`) // test
    setShow(false)
  }

  //собираем результаты подтверждений
  const sendResultConfirmationLot = obj => {
    const tradePosition = objConfirmation.tradePosition
    Object.assign(obj, { tradePosition })
    setObjConfirmation(obj)
  }

  // const getPrevData = prevData => {
  //   setPrev(prevData)
  //   // console.log('prevData', prevData)
  // }

  const getCurrData = currData => {
    setCurr(currData)
    // console.log('currData', currData)
  }

  const savedDataOnButton = isSaved => {
    setStateBeensaved(isSaved)
  }

  const compare = () => {
    const isStateChanged = isEqual(prev, curr)
    if (isStateChanged) {
      return false
    } else {
      return true
    }
  }

  const smartWindowFunction = () => {
    const result = compare()
    if (result && !stateBeenSaved) {
      launchQuitModalInParticipantLot()
      // setShowQuitModalInParticipantLot(!showQuitModalInParticipantLot)
    } else {
      toggleConfirmationLot()
      doReLoadTable()
    }
  }

  const toggleChatModal = () => {
    setShowChat(!showChat)
  }

  const empty = () => { }


  const handleClickChat = (text, recipient) => {
    setChatPreMessage(text)
    setRecipient(recipient)

    if (text) {
      setShowChat(true)
    }
  }

  // console.log(dataLot.numberLot); // test

  return (
    <>
      {show && (
        <PositionChoice
          dataPosition={resultPosition}
          onClosePosition={onClosePosition}
          sendPositionData={sendPositionData}
          value={valueConfirmation}
          runChat={handleClickChat}
          organizerName={dataLot.organizerName}
          showDelIcon={true}
          showCheckIcon={true}
          showAddBtn={true}
          asCustomer={false}
          lotNum={dataLot.numberLot}
        />
      )}

      {showChat && (
        <ChatWs
          onChangeVisibleChat={toggleChatModal} //открыть/закрыть модалку чата
          onCreateIdChat={empty}
          idChat={dataLot.chat}
          // forName="all" // для организатора прокинуть 'all'
          forName={dataLot.organizerId} // для участника прокинуть ID организатора
          idCompany={[dataLot.organizerId]}
          filterAll={[String(dataLot.organizerId)]} // обязательный пропс для участника
          idBox={dataLot.id}
          updateQuantityMessage={empty}
          preText={chatPreMessage} //текст элемента, к которому есть вопрос
          recipient={recipient}
          showChat={showChat}
          modalWidth={'width_50'}
        //todo: добавить заглавие сообщения при обращении к чату из позиций просмотра лота и торг.позиций
        />
      )}

      {/* {dataSentToCustomerMsg && (
        <LeaveModal
          closeQuitModal={() => setDataSentToCustomerMsg(false)}
          modalTitle='Отправка данных'
          modalBody='Указанные Вами данные отправлены заказчику.'
          icon={<ThumbUpOutlinedIcon fontSize='large' className='icon_success' />}
          modalFooter={false}
        />
      )} */}

      {
        <div>
          <div
            className='shadow'
            //   onClick={launchQuitModalInParticipantLot}
            onClick={() => smartWindowFunction()}
          ></div>
          <div className='modal_common_1 z_index_19'>
            <Alert
              variant={dataSentToCustomerMsg ? 'success' : 'dark'}
              className='filter_alert'
              //   onClick={launchQuitModalInParticipantLot}
              onClick={() => smartWindowFunction()}
              dismissible
            >
              <Alert.Heading title={dataLot.nameLot}>
                Информация для участника торгов по лоту:{' '}
                {dataLot.nameLot && dataLot.nameLot.length && dataLot.nameLot.length > 70
                  ? `${dataLot.nameLot.slice(0, 30)}...`
                  : dataLot.nameLot}
              </Alert.Heading>
            </Alert>
            <Modal.Body>
              <ParticipantLot
                lot={dataLot}
                productionArray={productionArray}
                choicePositionId={choicePositionId}
                sendResultConfirmationLot={sendResultConfirmationLot}
                value={valueConfirmation}
                sum={sum}
                idFile={nanoid()}
                quitModalFlag={showQuitModalInParticipantLot}
                toggleConfirmationLot={toggleConfirmationLot}
                runChat={runChat} //текст элемента, к которому есть вопрос
                compareConfirmationResult={compareConfirmationResult}
                handleClickChat={handleClickChat}
                toggleChatModal={toggleChatModal}
                showChat={showChat}
                fireSaveFn={fireSaveFn}
                dataSentToCustomerMsg={dataSentToCustomerMsg}
                runSaveResult={show}
                // getPrevData={getPrevData}
                getCurrData={getCurrData}
                smartWindowFunction={smartWindowFunction}
                savedDataOnButton={savedDataOnButton}
                setStateBeensaved={setStateBeensaved}
              />

              {showUnsuitableConditionModal && (
                <QuitModal
                  onQuitWithSave={toggleConfirmationLot} //пока выходит без сохранения закрывая все окна
                  onQuitWithoutSave={() => setShowUnsuitableConditionModal(false)} //временно просто закрывает окно модалки выхода
                  closeQuitModal={() => setShowUnsuitableConditionModal(false)}
                  modalTitle={'Несогласие с условиями'}
                  modalBody={'Укажите причину и (или) пункты условий, с которыми Вы не согласны:'}
                  primaryButton={'Отправить'}
                  secondaryButton={'Отмена'}
                  icon={<GppMaybeOutlinedIcon fontSize='large' className='icon_leave_modal' />}
                  inputF={true}
                  lot={dataLot}
                />
              )}

              {showRejectModal && (
                <QuitModal
                  onQuitWithSave={toggleConfirmationLot} //пока выходит без сохранения закрывая все окна
                  onQuitWithoutSave={() => setRejectModal(false)} //временно просто закрывает окно модалки выхода
                  closeQuitModal={() => setRejectModal(false)}
                  modalTitle={'Отказ от участия'}
                  modalBody={'Укажите причину отказа участия в конкурсе:'}
                  primaryButton={'Подтвердить'}
                  secondaryButton={'Отмена'}
                  inputF={true}
                  //icon - иконка в заголовке
                  lot={dataLot}
                />
              )}

              {showCondirmModal && (
                <QuitModal
                  onQuitWithSave={() => compareConfirmationResult(dataLot.id)} //пока выходит без сохранения закрывая все окна
                  onQuitWithoutSave={() => setShowCondirmModal(false)} //временно просто закрывает окно модалки выхода
                  closeQuitModal={() => setShowCondirmModal(false)}
                  modalTitle={'Отправка данных'}
                  modalBody={'Вы уверены, что хотите отправить заполненные данные заказчику?'}
                  primaryButton={'Подтвердить'}
                  secondaryButton={'Отмена'}
                  inputF={false}
                  icon={
                    <>
                      {'  '}
                      <DriveFileMoveOutlinedIcon fontSize='large' className='icon_leave_modal' />
                    </>
                  }
                  lot={dataLot}
                />
              )}
            </Modal.Body>
      
          </div>
        </div>
      }
    </>
  )
}

export default ConfirmationLot
