import React from 'react'
import { Alert, Button, Row, Form, Col } from 'react-bootstrap'
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined'
import Server from './server/server'
import InputForm from './questionnaire/InputForm'
import PhoneForm from './questionnaire/PhoneForm'
// import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import EmailAlert from './assets/EmailAlert'
import AllModalsFooter from './assets/AllModalsFooter'
import { SaveCurrentDataButton } from './assets/BasicButtons'
import TextareaForm from './questionnaire/TextareaForm'
// import TextareaForm from './TextareaForm'

export default class EmailSend extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      showBtn: false,
      showBlock: true,
      showInvite: true,
      inviteObject: {},
      objectInvite: {},
      errorInn: true,
      errorEmail: true,
      errorAll: true,
      formInvite: 'all', // (all - формирование объекта с ссылкой и отправка, invite_object - формирование объекта с сылкой только)
      showAlert: false,
      savedNotice: false,
      sentNotice: false
    }
    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSend = this.handleClickSend.bind(this)
    this.doStringURL = this.doStringURL.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.doSaveInvite = this.doSaveInvite.bind(this)
    this.doSendInvite = this.doSendInvite.bind(this)
  }

  //!функция когда заходишь с поиска, она только меняет название и цвет кнопки на 2 секунды и закрывает модалку
  doSendInvite() {
    this.setState({
      sentNotice: true
    })

    setTimeout(() => {
      this.setState({
        sentNotice: false
      })
      this.closeModal()
    }, 2000)
  }

  closeModal() {
    this.props.closeModal()
  }

  async doSaveInvite() {
    await this.doStringURL()
  }

  //* Формирование ссылки
  async doStringURL() {
    if (this.state.inviteObject && this.state.inviteObject.inviteURL) {
      // console.log(`ССЫЛКА уже получена`);
    } else if (!this.state.errorInn) {
      this.setState({ show: false })

      const res = await Server.sendInvite(this.state.objectInvite)

      // console.log(`ID INVITE down::: `, res.saveObject._id);
      this.props.onSendIdInvite(res.saveObject._id) // todo: send UP

      // console.log(`RESULT RESPONSE::: `, res.saveObject);

      await this.setState({
        inviteObject: res.saveObject,
        showBtn: true,
        show: true,
        savedNotice: true
      })
    }

    setTimeout(() => {
      this.setState({
        savedNotice: false
      })
      this.closeModal()
    }, 1500)
  }

  //* отправка письма
  handleClickSend() {
    ;(async () => {
      try {
        // console.log(`SEND`); // test
        // console.log(`STATE DATA: `, this.state.objectInvite); // test
        const res = await Server.sendEmail(this.state.inviteObject)
        // console.log(`RESULT send EMAIL:::`, res);
        if (res && res.OK) {
          this.setState({
            showAlert: true
          })
          setTimeout(() => {
            this.setState({
              showAlert: false
            })
          }, 3000)
        }
      } catch (err) {
        console.log(`Ошибка при сохранении приглашения: `, err)
      }
    })()
  }

  //* формирование объекта приглашения
  doChangeValue(e) {
    // console.log(e.id + ' => ' + e.value); // test
    // console.log(e); // test
    let dataD = this.state.objectInvite
    dataD[e.description] = e.value
    dataD['inn'] = localStorage.getItem('idUser')
    dataD['email'] = sessionStorage.getItem('login')
    dataD['lotID'] = this.props.lotID
    this.setState({ objectInvite: dataD })

    let regTen = /^\d\d\d\d\d\d\d\d\d\d$/ // проверка ИНН 10
    let regTwelve = /^\d\d\d\d\d\d\d\d\d\d\d\d$/ // проверка ИНН 12
    let regEmail = /[-.\w]+@([\w-]+\.)+[\w-]+/g // проверка Email

    if (
      this.state.objectInvite['emailInvite'] &&
      regEmail.test(this.state.objectInvite['emailInvite'])
    ) {
      this.setState({ errorEmail: false })
      // console.log(`Email ok`);
    } else {
      console.log(`Ошибка Email`)
      this.setState({ errorEmail: true })
    }

    if (
      this.state.objectInvite['inviteINN'] &&
      (regTen.test(this.state.objectInvite['inviteINN']) ||
        regTwelve.test(this.state.objectInvite['inviteINN']))
    ) {
      // console.log(`ИНН ок: `, this.state.objectInvite['inviteINN'].length); // test
      // console.log(`ИНН: `, this.state.objectInvite['inviteINN']); // test
      this.setState({ errorInn: false })
    } else {
      console.log(`Ошибка ИНН: `) // test
      this.setState({ errorInn: true })
    }

    if (
      this.state.objectInvite['emailInvite'] &&
      this.state.objectInvite['inviteINN'] &&
      this.state.objectInvite['nameCompany'] &&
      !this.state.errorInn &&
      !this.state.errorEmail
    ) {
      this.setState({ errorAll: false })
    } else {
      this.setState({ errorAll: true })
    }
  }

  doShowInvite(invite) {
    if (invite === 'invite_object') {
      this.setState({ showInvite: false })
    } else {
      this.setState({ showInvite: true })
    }
  }

  componentDidMount() {
    this.setState({ formInvite: this.props.formInvite })
    this.doShowInvite(this.props.formInvite)
    // console.log(`PROPS ID LOT::: `, this.props.lotID); // test
  }

  render() {
    const message = this.state.inviteObject.inviteURL
    const show = this.state.show
    const showBtn = this.state.showBtn
    const errorAll = this.state.errorAll

    return (
      <>
        {this.state.showBlock && (
          <div>
            <div className={'shadow_form'} onClick={this.closeModal}></div>

            {/* <Form className='form_main_1'> */}
            <Form className='invite_by_email'>
              <Alert variant='dark' className='form_alert' onClose={this.closeModal} dismissible>
                <Alert.Heading>
                  {/* <ForwardToInboxOutlinedIcon fontSize="large" className='icon_primary' />   */}
                  Пригласить компанию
                </Alert.Heading>
              </Alert>
              <Form.Group className='invite_by_email_content'>
                {errorAll && (
                  <div>
                    <Alert variant='danger'>
                      <ErrorOutlineIcon
                        style={{
                          width: '18px',
                          margin: '-4px 5px 0 0'
                        }}
                      />
                      Необходимо заполнить обязательные поля! (
                      {this.state.errorInn && <b>ИНН не заполнен или введен неверно</b>}
                      {this.state.errorInn && this.state.errorEmail && <b>, </b>}
                      {this.state.errorEmail && <b>email не заполнен или введен неверно</b>}
                      {!this.state.errorInn && !this.state.errorEmail && (
                        <b>Укажите название компании</b>
                      )}
                      )
                    </Alert>
                  </div>
                )}

                {/* <div
                  style={{ padding: '40px', height: '400px' }}
                  // style={{ padding: errorAll ? '30px 0 30px' : '50px 0 30px' }}
                > */}
                {this.state.showAlert && <EmailAlert alertTextSent='Письмо отправлено!' />}
                <Row>&nbsp;</Row>
                <Row>
                  <InputForm
                    id={1}
                    width={4}
                    show={true}
                    label=''
                    placeholder='Введите ИНН предприятия *'
                    description='inviteINN'
                    onChangeValue={this.doChangeValue}
                  />
                  <InputForm
                    id={2}
                    width={4}
                    show={true}
                    // verify="number"
                    label=''
                    placeholder='Введите название предприятия  *'
                    description='nameCompany'
                    onChangeValue={this.doChangeValue}
                  />
                  <InputForm
                    id={3}
                    width={4}
                    show={true}
                    label=''
                    placeholder='Введите форму собственности предприятия'
                    description='inviteOwn'
                    onChangeValue={this.doChangeValue}
                  />
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                  <InputForm
                    id={4}
                    width={4}
                    show={true}
                    // verify="number"
                    label=''
                    placeholder='Введите EMAIL предприятия  *'
                    description='emailInvite'
                    onChangeValue={this.doChangeValue}
                  />

                  <PhoneForm
                    id={5}
                    width={4}
                    show={true}
                    label=''
                    placeholder='Введите телефон руководителя'
                    description='invitePhone'
                    onChangeValue={this.doChangeValue}
                  />
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                  <InputForm
                    id={6}
                    width={4}
                    show={true}
                    label=''
                    placeholder='Имя'
                    description='inviteName'
                    onChangeValue={this.doChangeValue}
                  />
                  <InputForm
                    id={7}
                    width={4}
                    show={true}
                    label=''
                    placeholder='Фамилия'
                    description='inviteSurname'
                    onChangeValue={this.doChangeValue}
                  />
                  <InputForm
                    id={8}
                    width={4}
                    show={true}
                    label=''
                    placeholder='Отчество'
                    description='invitePatronage'
                    onChangeValue={this.doChangeValue}
                  />
                </Row>
                <Row>&nbsp;</Row>
                <Row>
                  {/* <InputForm
                      id={9}
                      width={12}
                      show={true}
                      label=''
                      placeholder='Поле для сообщения'
                      description='text'
                      onChangeValue={this.doChangeValue}
                    /> */}

                  <TextareaForm
                    id={9}
                    width={12}
                    label=''
                    placeholder='Поле для сообщения'
                    show={true}
                    rows={2}
                    onChangeValue={this.doChangeValue}
                  />
                </Row>
                {/* </div> */}

                {this.state.showInvite && (
                  <div>
                    <Row>&nbsp;</Row>
                    <Row>
                      {show && (
                        <Col>
                          {' '}
                          ССЫЛКА: <b> {message} </b>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Button
                        variant={'secondary'}
                        style={{ margin: '15px 15px' }}
                        onClick={this.doStringURL}
                      >
                        Сформировать ссылку
                      </Button>
                    </Row>
                    <Row>&nbsp;</Row>
                    {showBtn && (
                      <Row>
                        <Button
                          variant={'secondary'}
                          style={{ margin: '15px 15px' }}
                          onClick={this.handleClickSend}
                        >
                          Отправить
                        </Button>
                      </Row>
                    )}
                  </div>
                )}
                {/* {!this.state.showInvite && */}
                <AllModalsFooter
                  isPosAbsoluteNedded={true}
                  footerContent={
                    this.props.fromSearch ? (
                      //! это кнопка когда заходишь с поиска
                      <SaveCurrentDataButton
                        handleSaveClick={this.doSendInvite}
                        needDisable={true}
                        disableCondition={!this.state.errorAll ? false : true}
                        savedDataMessage={this.state.sentNotice}
                        buttonTextDefault={'Отправить приглашение'}
                        buttonTextSaved={'Приглашение отправлено'}
                        needCustomIcon={true}
                        customIcon={<ForwardToInboxOutlinedIcon style={{ marginRight: '5px' }} />}
                      />
                    ) : (
                      //! это кнопка когда заходишь с создания лота
                      <SaveCurrentDataButton
                        handleSaveClick={this.doSaveInvite}
                        needDisable={true}
                        disableCondition={!this.state.errorAll ? false : true}
                        savedDataMessage={this.state.savedNotice}
                        buttonTextDefault={'Сохранить приглашение'}
                        buttonTextSaved={'Приглашение сохранено'}
                      />
                    )
                  }
                />
              </Form.Group>
            </Form>
          </div>
        )}
      </>
    )
  }
}
