import React, { useState, useEffect } from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'
import { BasicButton, ClearButton, SaveCurrentDataButton, SearchButton } from './BasicButtons'

// renderedArray={объект в формате [{display: 'Уникальный номер', key: 'STHEid'}, ...]}
// condition={условие по которому вместо input будет отображаться select}
// object={объект со значениями}
// headingText={}
// arrayForSelect={массив для случаев когда используется селект}
// selectIndexes={те индексы, у которых селект а не инпут}
// selectData={данные для селекта (с инфой что будет в селекте и индексы этих селектов) в формате  {
//   select: [
//     { id: '', nameCompany: '', ownership: '' },
//     { id: '', nameCompany: '', ownership: '' },
//     { id: '', nameCompany: '', ownership: '' }
//   ],
//   indexes: [9, 3, 6]
// }}
// buttons={массив строк, соответствующих кнопкам, которые нужны в форме}
// defaultBtnText={если кнопка мигающая, то дефолтный текст кнопки}
// savedBtnText={если кнопка мигающая, то текст кнопки в момент мигания}
// mainFields={[массив тех ключей объекта, которым нужен кастомный стиль]}
//* jsonDataSTHE/ jsonDataTender={json данные, если они нужны в форме}
// autoFields={ключи тех полей, по вводу которых остальные данные заполняются автоматически}
//readOnly={только для чтения или нет}

export default function GeneralForm({
  renderedArray,
  selectData,
  buttons,
  object,
  headingText,
  sendData,
  toggleTechParamsModal,
  defaultBtnText,
  savedBtnText,
  selectIndexes,
  dividingIndex,
  mainFields,
  jsonDataSTHE,
  jsonDataTender,
  readOnly,
  orderNumber,
  doOrderNumber,
  // autoFields
}) {
  const [data, setData] = useState(object)
  const [isDataSaved, setIsDataSaved] = useState(false)
  const [localSTHEData, setLocalSTHEData] = useState(null)
  const [localTenderData, setLocalTenderData] = useState(null)
  const [autoFilledFields, setAutoFilledFields] = useState({})
  const [objOrderNumber, setObjOrderNumber] = useState()

  useEffect(() => {
    setLocalSTHEData(jsonDataSTHE)
    setLocalTenderData(jsonDataTender)
    // console.log(`jsonDataTender >>>>>>> `, jsonDataTender) // test
  }, [jsonDataTender, jsonDataSTHE])

  const setInputType = (paramName) => {
    // if (
    //   paramName.includes('Номер') ||
    //   paramName.includes('номер') ||
    //   paramName.includes('давление')
    // ) {
    //   return 'number'
    // }
    // if (
    //   paramName.includes('Стоимость') ||
    //   paramName.includes('Сумма') ||
    //   paramName.includes('стоимость') ||
    //   paramName.includes('сумма')
    // ) {
    //   return 'number'
    // }
    if (paramName.includes('Дата') || paramName.includes('дата')) {
      return 'date'
    }
    return 'text'
  }

  const onChangeHandler = (e) => {
    const { name, value } = e.target
    console.log(`NAME::: ${name} ==== VALUE: ${value}`); // test

    setData((prevData) => {
      let newData = {
        ...prevData,
        [name]: value
      }
      // console.log(`NEW DATA:::: `, data); // test

      const matchingSTHEData = localSTHEData?.find((item) => item.STHEid === value)
      const matchingTradeData = localTenderData?.find((item) => item.tenderId === value)

      let newAutoFilledFields = { ...autoFilledFields }

      if (name === 'tradePositionName') {
        const selectedTradePosition = autoFilledFields?.tradePosition?.find(
          (pos) => pos.namePosition === value
        )
        if (selectedTradePosition) {
          const autoFillData = {
            numberOfItemsInTradePosition: selectedTradePosition.quantity,
            priceOfTradePosition: selectedTradePosition.price
          }
          newData = { ...newData, ...autoFillData }
          newAutoFilledFields = { ...newAutoFilledFields, ...autoFillData }
          setObjOrderNumber({ idPosition: selectedTradePosition.idPosition, tenderId: data.tenderId, idLot: data.idLot })
          doOrderNumber({ idPosition: selectedTradePosition.idPosition, tenderId: data.tenderId, idLot: data.idLot })
        }
      }

      if (name === 'customerRepresentetive') {
        const customerData = {
          customerRepresentetive: value
        }
        newData = { ...newData, ...customerData }
        newAutoFilledFields = { ...newAutoFilledFields, ...customerData }
      }

      if (name === 'manufacturerRepresentetive') {
        const manufacturerData = {
          manufacturerRepresentetive: value
        }
        newData = { ...newData, ...manufacturerData }
        newAutoFilledFields = { ...newAutoFilledFields, ...manufacturerData }
      }

      // ? Зачем вот это????
      // if (newAutoFilledFields.hasOwnProperty(name)) {
      //   Object.keys(newAutoFilledFields).forEach((key) => {
      //     if (newAutoFilledFields[key] === newData[key]) {
      //       delete newAutoFilledFields[key]
      //     }
      //   })
      // }

      if (matchingSTHEData || matchingTradeData) {
        const matchedData = {
          ...matchingSTHEData,
          ...matchingTradeData
        }

        newData = { ...newData, ...matchedData }
        newAutoFilledFields = { ...newAutoFilledFields, ...matchedData }
      } else {
        const resetData = {}

        const resetKeys =
          name === 'STHEid' && localSTHEData[0]
            ? Object.keys(localSTHEData[0]).filter((key) => key !== 'STHEid')
            : name === 'tenderId' && localTenderData[0]
              ? Object.keys(localTenderData[0]).filter((key) => key !== 'tenderId')
              : []

        resetKeys.forEach((key) => {
          resetData[key] = ''
          if (newAutoFilledFields.hasOwnProperty(key)) {
            delete newAutoFilledFields[key]
          }
        })

        if (name === 'tenderId') {
          const resetTradeKeys = [
            'tradePositionName',
            'numberOfItemsInTradePosition',
            'priceOfTradePosition'
          ]
          resetTradeKeys.forEach((key) => (resetData[key] = ''))
        }

        newData = { ...newData, ...resetData }
      }

      // console.log(`AutoFilledFields >>>> `, newAutoFilledFields); // test
      setAutoFilledFields(newAutoFilledFields)

      return newData
    })
  }

  const handleSave = () => {
    // console.log(`data button save to child >>>>> `, data) // test
    setIsDataSaved(true)
    setTimeout(() => {
      setIsDataSaved(false)
    }, 1500)
    sendData(data)
  }

  const clearFields = () => {
    setData(object)
  }

  const setButton = () => {
    let buttonsArray = []

    if (buttons.includes('open')) {
      buttonsArray.push(
        <BasicButton
          handleSaveClick={toggleTechParamsModal}
          needDisable={false}
          saveButtonText='Заполнить технические параметры'
          buttonVariant='primary'
          icon={<EditNoteOutlinedIcon />}
        />
      )
    }

    if (buttons.includes('search')) {
      buttonsArray.push(
        <SearchButton
          handleSaveClick={handleSave}
          needDisable={false}
          buttonText={'Поиск'}
          buttonVariant={'primary'}
        />
      )
    }

    if (buttons.includes('create')) {
      buttonsArray.push(
        <SearchButton
          handleSaveClick={handleSave}
          needDisable={false}
          buttonText={'Сформировать'}
          buttonVariant={'primary'}
        />
      )
    }

    if (buttons.includes('clear')) {
      buttonsArray.push(
        <ClearButton
          handleSaveClick={clearFields}
          needDisable={false}
          disableCondition={() => { }}
          saveButtonText={'Очистить поля'}
          buttonVariant='secondary'
        />
      )
    }
    //saveCurrentData - мигающая кнопка
    if (buttons.includes('saveCurrentData')) {
      buttonsArray.push(
        <SaveCurrentDataButton
          handleSaveClick={handleSave}
          needDisable={false}
          savedDataMessage={isDataSaved}
          buttonTextDefault={defaultBtnText}
          buttonTextSaved={savedBtnText}
          needCustomIcon={false}
          needCustomStyle={false}
        />
      )
    }

    return buttonsArray
  }

  return (
    // <div style={{ padding: '80px 150px 20px 150px' }}>
    <Form
      // className='box_shadow'
      style={{
        backgroundColor: '#fff',
        padding: '20px 30px',
        //   marginBottom: '20px',
        borderRadius: '5px'
      }}
    >
      <h5 className='filter_box_h5'>{headingText}</h5>

      <h6 className='filter_box_h5'> Номер заказ-наряда: {orderNumber ? orderNumber : object.orderNumber ? object.orderNumber : 'Будет сформирован при сохранении!'} </h6>

      {renderedArray?.map((p, i) => (
        <>
          {' '}
          {i === dividingIndex && (
            <h6 style={{ textAlign: 'center', display: 'block', margin: '20px 0' }}>
              Технические параметры:
            </h6>
          )}
          <Form.Group key={p.key}>
            <Row className='display_flex'>
              <Col>
                <Form.Label style={mainFields.includes(p.key) ? { fontWeight: '500' } : {}}>
                  {i + 1}. {p.display}:{' '}
                </Form.Label>
              </Col>
              <Col>
                {!selectIndexes.includes(i) ||
                  (i === 7 && !autoFilledFields?.employeesCustomer?.length) ||
                  (i === 9 && !autoFilledFields?.employeesManufacturer?.length) ||
                  (i === 2 && !autoFilledFields?.tradePosition?.length) ? (
                  <Form.Control
                    width={10}
                    name={p.key}
                    type={setInputType(p.display)}
                    placeholder={`Введите ${p.display.toLowerCase()}`}
                    // value={data[p.key]}
                    value={
                      p.key === 'priceOfTradePosition' && data[p.key]
                        ? Number(data[p.key]).toLocaleString() + ' руб.'
                        : data[p.key]
                    }
                    onChange={onChangeHandler}
                    style={mainFields.includes(p.key) ? { border: '#007bff 2px solid' } : {}}
                    className={autoFilledFields[p.key] ? 'highlighted' : ''}
                    disabled={readOnly}
                  />
                ) : (
                  <Form.Control
                    width={10}
                    name={p.key}
                    as='select'
                    placeholder={`Введите ${p.display.toLowerCase()}`}
                    // value={data[p.key].toLocalString()}
                    value={
                      p.key === 'priceOfTradePosition'
                        ? Number(data[p.key]).toLocaleString()
                        : data[p.key]
                    }
                    onChange={onChangeHandler}
                    className={autoFilledFields[p.key] ? 'highlighted' : ''}
                    disabled={readOnly}
                  >
                    <option style={{ color: '#ccc' }} value='' disabled selected hidden>
                      Введите {p.display.toLowerCase()}
                    </option>
                    {selectData?.map((sd) =>
                      sd?.indexes?.map((ind) =>
                        ind === i && ind !== 2 && ind !== 7 && ind !== 9 ? (
                          sd?.select?.map((s) => (
                            <option key={s.nameCompany}>
                              {`${s.ownership ? s.ownership : ''} ${s.nameCompany}`}
                            </option>
                          ))
                        ) : ind === 7 && i === 7 && autoFilledFields?.employeesCustomer?.length ? (
                          <>
                            <option> Выбрать сотрудника </option>
                            {autoFilledFields?.employeesCustomer?.map((empl) => (
                              <option key={empl.id}>
                                {`${empl.surname} ${empl.name} ${empl.thirdName}`}
                              </option>
                            ))}
                          </>
                        ) : ind === 9 &&
                          i === 9 &&
                          autoFilledFields?.employeesManufacturer?.length ? (
                          <>
                            <option> Выбрать сотрудника </option>
                            {autoFilledFields?.employeesManufacturer?.map((employe) => (
                              <option key={employe.id}>
                                {`${employe.surname} ${employe.name} ${employe.thirdName}`}
                              </option>
                            ))}
                          </>
                        ) : ind === 2 && i === 2 && autoFilledFields?.tradePosition?.length ? (
                          <>
                            <option> Выбрать позицию </option>
                            {autoFilledFields?.tradePosition?.map((position) => (
                              <option key={position.idPosition}>
                                {`${position.namePosition}`}
                              </option>
                            ))}
                          </>
                        ) : (
                          ''
                        )
                      )
                    )}
                  </Form.Control>
                )}
              </Col>
            </Row>
          </Form.Group>
        </>
      ))}
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>{setButton()}</div>
    </Form>
    // </div>
  )
}
