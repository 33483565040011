import React, { useState } from 'react'
import { Table } from 'react-bootstrap'
import parseTime from '../assets/parseTime'
import ModalWindowMini from '../assets/ModalWindowMini'


const ViewLogger = ({ data }) => {

    const [showError, setShowError] = useState(false)
    const [errContent, setErrContent] = useState()

    const handleClickError = (e) => {
        const idTime = Number(e.target.dataset.id)

        if (e.target.innerText === 'ERROR') {
            const dataError = data.find(item => item.time === idTime)
            console.log(dataError.err.stack); // test
            const arrErr = (dataError.err.stack).split('\n')
            setErrContent(
                arrErr.map((itm, index) => {
                    return (
                        <div
                            style={{
                                color: index === 0 ? 'red' : '',
                                fontSize: index === 0 ? '1.25rem' : '',
                                fontWeight: index === 0 ? '700' : index === 1 ? '700' : '',
                            }}
                        >
                            {itm} </div>
                    )
                })
            )

            setShowError(true)
        }
    }

    const toggleModal = () => {
        setShowError(false)
    }

    return (
        <>
            {showError &&
                <ModalWindowMini
                    toggleModal={toggleModal}
                    alertHeading={`Error`}
                    modalContent={errContent}
                />

            }
            <div
                style={{
                    width: '95%',
                    margin: '0 auto',
                    textAlign: 'center'
                }}
            >
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Log</th>
                            <th>Time</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            data?.map((item, index) => {
                                const date = new Date(item.time)
                                const dateText = parseTime(date)
                                return (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <th
                                                style={{ width: '7%' }}
                                            >
                                                {index + 1}
                                            </th>
                                            <th
                                                style={{
                                                    width: '10%',
                                                    cursor: `${item.level === 50 ? 'pointer' : ''}`
                                                }}
                                                data-id={item.time}
                                                onClick={handleClickError}
                                            >
                                                {
                                                    item.level === 30 ? <span style={{ color: '#007bff' }} > INFO </span> :
                                                        item.level === 40 ? <span style={{ color: '#ffc107' }} > WARN </span> :
                                                            item.level === 50 ?
                                                                <span
                                                                    style={{
                                                                        color: '#dc3545',
                                                                    }}
                                                                    data-id={item.time}
                                                                    onClick={handleClickError}
                                                                >
                                                                    ERROR
                                                                </span> : item.level
                                                }
                                            </th>
                                            <th
                                                style={{
                                                    color: '#6c757d',
                                                    fontSize: '0.75rem'
                                                }}
                                            >
                                                {dateText}
                                            </th>
                                            <th
                                                style={{ textAlign: 'left' }}
                                            >
                                                {item.msg}
                                            </th>
                                        </tr>
                                    </React.Fragment>
                                )
                            })
                        }


                    </tbody>
                </Table>
            </div>
        </>
    )
}

export default ViewLogger