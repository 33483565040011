export const renderObject = [
    { display: 'Уникальный нoмер', key: 'STHEid' },
    { display: 'Нoмер конкурса', key: 'tenderId' },

    { display: 'Название торговой позиции', key: 'tradePositionName' }, //2
    { display: 'Количество изделий в торговой позиции', key: 'numberOfItemsInTradePosition' }, //3
    { display: 'Стоимость (цена) торговой позиции', key: 'priceOfTradePosition' }, //4

    { display: 'Дата формирования', key: 'dateOfCreation' }, //5
    { display: 'Заказчик', key: 'customer' }, //6
    { display: 'Ответственный от заказчика', key: 'customerRepresentetive' }, //7
    { display: 'Изготовитель', key: 'manufacturer' }, //8
    { display: 'Ответственный от изготовителя', key: 'manufacturerRepresentetive' }, //9
    { display: 'Наименование аппарата', key: 'equipmentName' },
    { display: 'Количество, шт', key: 'quantity' },
    { display: 'Сумма заказа, без НДС', key: 'orderPrice' },
    { display: 'Основание для запуска в производство', key: 'launchReasoning' },
    {
      display: 'Дата (основания)',
      key: 'generalDate'
    },
    { display: 'Дата начала исчисления срока изготовления', key: 'commencmentDate' },
    { display: 'Дата поставки в адрес Заказчика по договору', key: 'deliveryDate' },
    { display: 'Желаемая дата получения оборудования Заказчиком', key: 'preferableReceivingDate' },
    { display: 'Вид конструкторской документации (КД)', key: 'CDType' },
    { display: 'Изготовитель КД', key: 'CDManufacturer' },
    { display: 'Ответственный за КД', key: 'CDRepresentetive' },
    { display: 'Дата предоставления КД ', key: 'CDProvidingDate' },
    { display: 'Наименование вида аппарата', key: 'equipmentTypename' },

    { display: 'Наименование аппарата по ТУ', key: 'TUEquipmentName' },
    { display: 'Рабочая среда Трубное', key: 'pipeWorkEnvironment' },
    { display: 'Маркировка Трубное', key: 'pipeMarking' },
    { display: 'Условное давление, МПа Трубное', key: 'pipePressure' },
    { display: 'Количество ходов, шт Трубное', key: 'quantytyOfPipeMethods' },
    { display: 'Рабочая среда Межтрубное', key: 'interPipeWorkEnvironment' },
    { display: 'Маркировка Межтрубное', key: 'interPipeMarking' },
    { display: 'Условное давление, МПа Межтрубное', key: 'interPipePressure' },
    { display: 'Количество ходов, шт Межтрубное', key: 'quantytyOfInterPipeMethods' },
    { display: 'Диаметр аппарата, мм', key: 'equipmentDiametr' },
    { display: 'Тип аппарата по ТУ', key: 'TUEquipmentType' },
    { display: 'Материальное исполнение аппарата', key: 'physicalImplementation' },
    { display: 'Материал теплообменных труб', key: 'pipeMaterial' },
    { display: 'Диаметр теплообменных труб, мм', key: 'pipeDiametr' },
    { display: 'Толщина теплообменных труб, мм', key: 'pipeThickness' },
    { display: 'Длина теплообменных труб, м', key: 'pipeLength' },
    { display: 'Количество корпусов, шт', key: 'quantityOfBlocks' }
  ]