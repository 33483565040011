import React from 'react';
import { Form } from 'react-bootstrap';
// import { disabledCheck } from './ConstListCompany';
// import CheckFormWithDisabled from './CheckFormWithDisabled';

export class CheckFormSearch extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            language: 'rus',
            id: '',
            fid: '',
            description: '',
            information: '',
            value: '',
            colorError: '#fff'
        }

        this.handleChange = this.handleChange.bind(this);
    }


    handleChange(e) {
        this.setState({
            value: e.target.checked,
            id: e.target.dataset.id,
            fid: `${this.props.nameForm}_${this.props.id}`,
            description: e.target.dataset.description,
            information: e.target.dataset.information
        })
        // console.log(e.target.checked);//test     
        setTimeout(() => { this.props.onChangeValue(this.state) })
        this.props.onChangeVisionBlock(e.target.checked, e.target.dataset.id);
        // this.handleBlur()
    }

    componentDidMount() {
        this.setState({ value: this.props.value })
    }

    render() {
        const id = this.props.id;
        const description = this.props.description;
        const placeholder = this.props.placeholder;
        const value = this.state.value;
        // const selected = this.props.selected

        return (
            <React.Fragment key={id}>

                <Form.Check
                    // style={{
                    //     fontSize: '12px'
                    // }}
                    id={this.props.name}
                    type="checkbox"
                    label={placeholder}
                    data-id={id}
                    data-information={placeholder}
                    data-description={description}
                    checked={value}
                    // value={selected}
                    onChange={this.handleChange}
                    className='pt_25'//* положение checkbox вертикально
                />

            </React.Fragment>
        )
    }
}