export const TIMEZONE = [
    'Абакан UTC+7 (MSK+4)',
    'Алма-Ата UTC+6 (MSK+3)',
    'Альметьевск UTC+3 (MSK)',
    'Анадырь UTC+12 (MSK+9)',
    'Анапа UTC+3 (MSK)',
    'Архангельск UTC+3 (MSK)',
    'Астрахань UTC+4 (MSK+1)',
    'Барнаул UTC+7 (MSK+4)',
    'Белгород UTC+3 (MSK)',
    'Биробиджан UTC+10 (MSK+7)',
    'Благовещенск UTC+9 (MSK+6)',
    'Брянск UTC+3 (MSK)',
    'Великие Луки UTC+3 (MSK)',
    'Верхоянск UTC+10 (MSK+7)',
    'Владивосток UTC+10 (MSK+7)',
    'Владикавказ UTC+3 (MSK)',
    'Владимир UTC+3 (MSK)',
    'Волгоград UTC+3 (MSK)',
    'Вологда UTC+3 (MSK)',
    'Воркута UTC+3 (MSK)',
    'Воронеж UTC+3 (MSK)',
    'Геленджик UTC+3 (MSK)',
    'Гомель UTC+3 (MSK)',
    'Горно-Алтайск UTC+7 (MSK+4)',
    'Грозный UTC+3 (MSK)',
    'Дудинка UTC+7 (MSK+4)',
    'Екатеринбург UTC+5 (MSK+2)',
    'Иваново UTC+3 (MSK)',
    'Ижевск UTC+4 (MSK+1)',
    'Иркутск UTC+8 (MSK+5)',
    'Йошкар-Ола UTC+3 (MSK)',
    'Казань UTC+3 (MSK)',
    'Калининград UTC+2 (MSK-1)',
    'Калуга UTC+3 (MSK)',
    'Кемерово UTC+7 (MSK+4)',
    'Киров UTC+3 (MSK)',
    'Кострома UTC+3 (MSK)',
    'Краснодар UTC+3 (MSK)',
    'Красноярск UTC+7 (MSK+4)',
    'Курган UTC+5 (MSK+2)',
    'Курск UTC+3 (MSK)',
    'Кызыл UTC+7 (MSK+4)',
    'Липецк UTC+3 (MSK)',
    'Магадан UTC+11 (MSK+8)',
    'Магнитогорск UTC+5 (MSK+2)',
    'Майкоп UTC+3 (MSK)',
    'Махачкала UTC+3 (MSK)',
    'Минеральные Воды UTC+3 (MSK)',
    'Москва UTC+3 (MSK)',
    'Мурманск UTC+3 (MSK)',
    'Нальчик UTC+3 (MSK)',
    'Нарьян-Мар UTC+3 (MSK)',
    'Нижний Новгород UTC+3 (MSK)',
    'Новгород UTC+3 (MSK)',
    'Новосибирск UTC+7 (MSK+4)',
    'Омск UTC+6 (MSK+3)',
    'Орел UTC+3 (MSK)',
    'Оренбург UTC+5 (MSK+2)',
    'Пенза UTC+3 (MSK)',
    'Пермь UTC+5 (MSK+2)',
    'Петрозаводск UTC+3 (MSK)',
    'Петропавловск-Камчатский UTC+12 (MSK+9)',
    'Псков UTC+3 (MSK)',
    'Ростов-на-Дону UTC+3 (MSK)',
    'Рязань UTC+3 (MSK)',
    'Салехард UTC+5 (MSK+2)',
    'Самара UTC+4 (MSK+1)',
    'Санкт-Петербург UTC+3 (MSK)',
    'Саранск UTC+3 (MSK)',
    'Саратов UTC+4 (MSK+1)',
    'Смоленск UTC+3 (MSK)',
    'Сочи UTC+3 (MSK)',
    'Ставрополь UTC+3 (MSK)',
    'Сургут UTC+5 (MSK+2)',
    'Сызрань UTC+4 (MSK+1)',
    'Сыктывкар UTC+3 (MSK)',
    'Тамбов UTC+3 (MSK)',
    'Тверь UTC+3 (MSK)',
    'Тольятти UTC+4 (MSK+1)',
    'Томск UTC+7 (MSK+4)',
    'Тула UTC+3 (MSK)',
    'Тюмень UTC+5 (MSK+2)',
    'Улан-Удэ UTC+8 (MSK+5)',
    'Ульяновск UTC+4 (MSK+1)',
    'Уфа UTC+5 (MSK+2)',
    'Хабаровск UTC+10 (MSK+7)',
    'Ханты-Мансийск UTC+5 (MSK+2)',
    'Чебоксары UTC+3 (MSK)',
    'Челябинск UTC+5 (MSK+2)',
    'Череповец UTC+3 (MSK)',
    'Черкесск UTC+3 (MSK)',
    'Чита UTC+9 (MSK+6)',
    'Элиста UTC+3 (MSK)',
    'Южно-Сахалинск UTC+11 (MSK+8)',
    'Якутск UTC+9 (MSK+6)',
    'Ярославль UTC+3 (MSK)'
]