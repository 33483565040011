import React from 'react';
import InputMask from 'react-input-mask';

//* https://www.npmjs.com/package/react-input-mask - масска для всего

export default class PhoneMaskFeedback extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            language: 'rus',
            id: '',
            fid: '',
            description: '',
            information: '',
            value: '',
            colorError: '#fff'
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

    }

    handleBlur(e) {
        this.setState({
            id: e.target.dataset.id,
            fid: `${this.props.nameForm}_${this.props.id}`,
            description: e.target.dataset.description,
            information: e.target.dataset.information
        })
        setTimeout(() => { this.props.onChangeValue(this.state) })
    }

    handleChange(e) {
        e.preventDefault();
        console.log(e.target.value);//test
        this.setState({ value: e.target.value })
    }


    componentDidMount() {
        this.setState({ value: this.props.value })
    }

    render() {
        const { value } = this.state
        const { label, width, id, description, placeholder, inputRef } = this.props

        return (
            <React.Fragment key={id}>
                    <InputMask
                        className="form-control"
                        mask="+7(999)999-9999"
                        maskChar="_"
                        data-id={id}
                        data-fid={`${this.props.nameForm}_${id}`}
                        placeholder={placeholder}
                        data-information={placeholder}
                        data-description={description}
                        value={value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        disabled={this.props.readOnly}
                        ref={inputRef}
                    />
            </React.Fragment>
        )
    }
}