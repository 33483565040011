import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import RadioButtonForm from './RadioButtonForm';
import InputMask from 'react-input-mask';
import { InputFormPersonalArea } from './InputFornAdditional';
// import avatar from '../assets/images/avatar.jpg'
import { loadImageURL } from '../settings/loadImageURL'


export class InputNameFormPersonalArea extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: true,
            id: '',
            fid: '',
            description: '',
            information: '',
            value: ['', '', '', ''],
            colorError: '#fff',
            avatar: '',
        }
        this.doChangeValue = this.doChangeValue.bind(this);
        this.doEmpty = this.doEmpty.bind();
    }
    //*пустышка
    doEmpty() {

    }

    doChangeValue(e) {
        this.data = this.state.value;
        if (e.information === 'Фамилия') this.data[0] = e.value;
        if (e.information === 'Имя') this.data[1] = e.value;
        if (e.information === 'Отчество') this.data[2] = e.value;
        if (e.information === 'пол') this.data[3] = e.value;
        this.setState({ value: this.data })
        setTimeout(() => {
            this.props.onChange(this.state)
        })
    }

    async componentDidMount() {
        this.setState({
            id: this.props.id,
            fid: `${this.props.nameForm}_${this.props.id}`,
            description: this.props.description,
            information: this.props.placeholder,
            // value: this.props.value            
        })
        this.setState({ avatar: await loadImageURL(`icon_avatar`, sessionStorage.getItem('login')) }) // возвращает URL строку для icon

        if (this.props.value) this.setState({ value: this.props.value })

    }

    render() {

        const id = this.props.id;
        const description = this.state.description;
        const value = this.props.value;
        const width = this.props.width;

        return (
            <>

                <Form.Group>
                    <Row>
                        <img alt="" src={this.state.avatar} style={{ width: '90px', marginRight: '5px', marginLeft: '5px', borderRadius: '4px' }} />
                        <Col>
                            <Form.Label><b>Фамилия</b></Form.Label>
                            <InputFormPersonalArea
                                placeholder="Фамилия"
                                id={+id}
                                width={width}
                                label="Фамилия"
                                description={description}
                                value={value[0]}
                                onChange={this.doChangeValue}
                                nameForm={this.props.nameForm}
                                readOnly={this.props.readOnly ? true : false}
                            />
                        </Col>
                        <Col>
                            <Form.Label><b>Имя</b></Form.Label>
                            <InputFormPersonalArea
                                style={{ backgroundColor: `${this.state.colorError} ` }}
                                placeholder="Имя"
                                id={+id}
                                width={width}
                                label="Имя"
                                description={description}
                                value={value[1]}
                                onChange={this.doChangeValue}
                                nameForm={this.props.nameForm}
                                readOnly={this.props.readOnly ? true : false}
                            />
                        </Col>
                        <Col>
                            <Form.Label><b>Отчество</b></Form.Label>
                            <InputFormPersonalArea
                                style={{ backgroundColor: `${this.state.colorError} ` }}
                                placeholder="Отчество"
                                id={+id}
                                width={width}
                                label="Отчество"
                                description={description}
                                value={value[2]}
                                onChange={this.doChangeValue}
                                nameForm={this.props.nameForm}
                                readOnly={this.props.readOnly ? true : false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <RadioButtonForm
                            id={+id}
                            width={width}
                            title={<b>Пол:</b>}
                            label1="муж"
                            label2="жен"
                            placeholder="пол"
                            description={description}
                            value={value[3]}
                            onChange={this.doChangeValue}
                            onChangeVisionBlock={this.doEmpty}
                            nameForm={this.props.nameForm}
                        />
                    </Row>

                </Form.Group>


            </>
        )
    }
}



export class PhoneFormPersonalArea extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            language: 'rus',
            id: '',
            fid: '',
            description: '',
            information: '',
            value: '',
            colorError: '#fff'
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);

    }

    handleBlur(e) {
        this.setState({
            id: e.target.dataset.id,
            fid: `${this.props.nameForm}_${this.props.id}`,
            description: e.target.dataset.description,
            information: e.target.dataset.information
        })
        setTimeout(() => { this.props.onChange(this.state) })
    }

    handleChange(e) {
        e.preventDefault();
        console.log(e.target.value);//test
        this.setState({ value: e.target.value })
    }


    componentDidMount() {
        this.setState({ value: this.props.value })
    }

    render() {

        const id = this.props.id;
        const description = this.props.description;
        const placeholder = this.props.placeholder;
        const value = this.state.value;

        return (
            <React.Fragment key={id}>
                <Form.Group >
                    <Row>
                        <Col>
                            <Form.Label style={{ marginBottom: '0', paddingBottom: '0' }}><b>{placeholder}</b></Form.Label>
                            <InputMask
                                style={{ marginTop: '8px' }}
                                className="form-control phone_form"
                                mask="+7(999)999-9999"
                                maskChar="_"
                                data-id={id}
                                data-fid={`${this.props.nameForm}_${id}`}
                                placeholder={placeholder}
                                data-information={placeholder}
                                data-description={description}
                                value={value}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                            />
                        </Col>

                    </Row>
                </Form.Group>

            </React.Fragment>
        )
    }
}


export class InputFormPersonalAreaEmail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            language: 'rus',
            id: '',
            fid: '',
            description: '',
            information: '',
            value: '',
            colorError: '#fff'
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.erasingError = this.erasingError.bind(this);
    }

    async handleBlur(e) {
        await this.setState({
            id: e.target.dataset.id,
            fid: e.target.dataset.fid,
            description: e.target.dataset.description,
            information: e.target.dataset.information
        })
        this.props.onChange(this.state);
    }

    handleChange(e) {
        e.preventDefault();
        // console.log(e.target.value);//test
        if (this.props.verify === 'number') {
            this.verificationNumber(e)
        } else {
            this.setState({ value: e.target.value })
        }
        // console.log(e.target.value);//test
    }

    verificationNumber(e) {
        // todo заменяем запятую на точку .replace(/,/, '.')
        if (isFinite(e.target.value.replace(/,/, '.'))) {
            this.setState({ value: e.target.value.replace(/,/, '.'), colorError: '#fff' })
        } else {

            console.log('error');
            this.setState({ colorError: '#dc3545' })
            this.erasingError();
        }
    }

    erasingError() {
        setTimeout(() => { this.setState({ colorError: '#fff' }) }, 500)
    }

    componentDidMount() {
        this.setState({ value: this.props.value })
    }

    render() {

        const label = this.props.placeholder;
        const id = this.props.id;
        const description = this.props.description;
        const placeholder = this.props.placeholder;
        const value = this.state.value;


        return (
            <React.Fragment key={id}>
                <Form.Group>
                    <Row>
                        <Col >
                            <Form.Label style={{ marginBottom: '0', paddingBottom: '0' }}><b>{label}</b></Form.Label>
                            <Form.Control
                                style={{
                                    backgroundColor: `${this.state.colorError} `,
                                    marginTop: '8px'
                                }}
                                type="text"
                                data-id={id}
                                data-fid={`${this.props.nameForm}_${this.props.id}`}
                                placeholder={placeholder}
                                data-information={placeholder}
                                data-description={description}
                                value={value}
                                onChange={this.handleChange}
                                onBlur={this.handleBlur}
                                readOnly={this.props.readOnly ? true : false}
                            />
                        </Col>
                    </Row>
                </Form.Group>
            </React.Fragment>
        )
    }
}