import React from 'react'
import { Form, Container, Row, Col, Card, Alert } from 'react-bootstrap'
import {
  InputNameFormPersonalArea,
  PhoneFormPersonalArea,
  InputFormPersonalAreaEmail
} from './questionnaire/InputNameFormPersonalArea'
// import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import PhonelinkLockOutlinedIcon from '@mui/icons-material/PhonelinkLockOutlined'
import AccessibleCompany from './AccessibleCompany'
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import SelectFormCity from './questionnaire/SelectForm_City'
import { TIMEZONE } from './assets/timezone'
import InputForm from './questionnaire/InputForm'
import Server from './server/server'
import AllModalsFooter from './assets/AllModalsFooter'
import QuitModal from './QuitModal'
import {
  AddButton,
  ChangePasswordButton,
  SaveCurrentDataButton
} from './assets/BasicButtons'
import checkTimezone from './checkTimezone'

export default class PersonalArea extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      show: false,
      nameForm: 'PersonalArea',
      dataForm: [],
      idCompany: [],
      surname: '',
      name: '',
      patronage: '',
      gender: '',
      phone: '',
      login: '',
      timezone: '',
      oldPass: '',
      newPass: '',
      showPassword: false,
      showInput: true,
      changePasswordModal: false,
      isNewPasswordVisible: false,
      isOldPasswordVisible: false,
      savedDataMessage: false
    }
    this.handleClickAddCompany = this.handleClickAddCompany.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.doChangeValue = this.doChangeValue.bind(this)
    this.doReturnIdCompany = this.doReturnIdCompany.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.doChangeOldPassword = this.doChangeOldPassword.bind(this)
    this.doChangeNewPassword = this.doChangeNewPassword.bind(this)
    this.handleClickUpdatePassword = this.handleClickUpdatePassword.bind(this)
    this.showPasswordNew = this.showPasswordNew.bind(this)
    this.showPasswordOld = this.showPasswordOld.bind(this)
    this.handleCloseSession = this.handleCloseSession.bind(this)
  }

  /**
   * window.location.href = `${window.location.href}cabinet`
   * console.log(`URL =>  `, window.location.href); // test
   */

  // отслеживаем закрытие окна
  async handleCloseSession() {
    await Server.doCloseSession()
  }

  showPasswordNew() {
    this.setState({
      isNewPasswordVisible: !this.state.isNewPasswordVisible
    })
  }

  showPasswordOld() {
    this.setState({
      isOldPasswordVisible: !this.state.isOldPasswordVisible
    })
  }

  doEmpty() { }

  doChangeOldPassword(data) {
    // console.log(`password OLD:::: `, data.value);
    this.setState({ oldPass: data.value })
  }

  doChangeNewPassword(data) {
    // console.log(`password NEW:::: `, data.value);
    this.setState({ newPass: data.value })
  }

  handleClickUpdatePassword() {
    ; (async () => {
      try {
        const result = await Server.updatePassword(this.state.oldPass, this.state.newPass)

        // console.log(`Смена пароля: `, result); // test

        if (result.update === 'UPDATE PASSWORD - OK') {
          // console.log(`Смена пароля произведена`); // test
          await this.setState({
            showInput: false,
            oldPass: '',
            newPass: '',
            showPassword: true
          })
          await this.setState({ showInput: true })
          setTimeout(() => {
            this.setState({ showPassword: false })
          }, 3000)
          this.setState({
            changePasswordModal: false
          })
        }
      } catch (err) {
        console.log(`Ошибка при смене пароля: `, err)
      }
    })()
  }

  /**
   * Записывает ИНН в localStorage и cookie
   * @param {*} id 
   */
  doReturnIdCompany(id) {
    localStorage.setItem('idUser', id)
    // console.log(`doReturnIdCompany INN in Personal Area >>>>>>>>>>>>>>>>>>>>>>>>>>>>> `, id); // test
    checkTimezone() // *: Запуск функции - проверка наличия временных поясов в анкете и у сотрудников
    // cookie
    document.cookie = `inn=${id}; max-age=86400`;
    // document.cookie = `inn=${id}; max-age=300`;
    this.props.onReturnIdCompany(id)
  }

  doChangeValue(data) {
    // console.log('data from input: ', data);//test
    this.setState({ dataForm: [...this.state.dataForm, data] })

    if (data.fid === 'PersonalArea_1')
      this.setState({ surname: data.value[0], name: data.value[1], patronage: data.value[2], gender: data.value[3] }) //фио
    if (data.fid === 'PersonalArea_2') this.setState({ phone: data.value }) //phone
    if (data.fid === 'PersonalArea_3') this.setState({ login: data.value }) //login
    if (data.fid === 'PersonalArea_4') {
      this.setState({ timezone: data.value }) //timezone
      console.log(`TIMEZONE >>>>> `, data.value);  // test      
      //* регулярка для определения разницы часового пояса от UTC (Применима только к данным занчениям)
      let result = data.value.match(/.\d\d/)
      if (result === null) result = data.value.match(/.\d/)
      // console.log(`RESULT REG ---> `, Number(result));
    }
  }

  handleClickSave() {
    // console.log('click - save personal data');//test

    //объект для вывода в db
    this.dataPersonalArea = {
      idCompany: this.state.idCompany,
      surname: this.state.surname,
      name: this.state.name,
      patronage: this.state.patronage,
      gender: this.state.gender,
      phone: this.state.phone,
      login: this.state.login,
      timezone: this.state.timezone
    }
    setTimeout(() => {
      // console.log(this.dataPersonalArea); //test
      this.props.onUpdateRegistration(this.dataPersonalArea) // UPDATE регистрационных данных на сервере
    })
    this.setState({
      savedDataMessage: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }

  handleClickAddCompany() {
    // console.log('click - add company');//test
    this.props.onCreateNewCompany()
  }

  async componentDidMount() {
    await this.setState({
      idCompany: this.props.loginData.idCompany,
      surname: this.props.loginData.surname,
      name: this.props.loginData.name,
      patronage: this.state.patronage,
      gender: this.props.loginData.gender,
      phone: this.props.loginData.phone,
      login: this.props.loginData.login,
      timezone: this.props.loginData.timezone
    })
    // console.log('PERSONAL DATA::: ', this.state);
    window.addEventListener('beforeunload', this.handleCloseSession) // отслеживаем закрытие окна

    // window.location.href = `${window.location.href}cabinet`
    // console.log(`URL =>  `, window.location.href); // test
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleCloseSession)
  }

  render() {
    const data = this.props.loginData
    const fullName = [data.surname, data.name, data.patronage, data.gender]
    const login = data.login
    const phone = data.phone
    const timezone = data.timezone

    // console.log(`idCompany:::: `, data.idCompany);

    return (
      <>
        <Card className='modal_window'>
          <div className='shadow' onClick={this.props.closePersonalArea}></div>
          <div className={this.state.showOff ? 'modal_form_off' : 'modal_common p_40 z_index_1000 min_height_570'}>
            <Alert variant='dark' onClick={this.props.closePersonalArea} dismissible className='header_alert_common'>
              <Alert.Heading>Личный кабинет</Alert.Heading>
            </Alert>

            <Card.Body className='container_common height_500 overflow_x_hidden overflow_y_scroll'>
              <InputNameFormPersonalArea
                id={1}
                width={4}
                label=''
                placeholder='ФИО пользователя'
                description='ФИО Пользователь'
                value={fullName}
                onChange={this.doChangeValue}
                nameForm={this.state.nameForm}
              // readOnly={true}
              />
              <Row> &nbsp;</Row>
              <PhoneFormPersonalArea
                id={2}
                width={10}
                label=''
                placeholder='Телефон пользователя'
                description='Телефон пользователя'
                value={phone}
                onChange={this.doChangeValue}
                nameForm={this.state.nameForm}
              />
              <Row> &nbsp;</Row>
              <InputFormPersonalAreaEmail
                id={3}
                width={8}
                label=''
                placeholder='Email пользователя'
                description='Email пользователя'
                value={login}
                onChange={this.doChangeValue}
                nameForm={this.state.nameForm}
              // readOnly={true}
              />
              <Row> &nbsp;</Row>
              <Row>
                <SelectFormCity
                  id={4}
                  width={12}
                  show={true}
                  label=''
                  placeholder='Часовой пояс'
                  description='Часовой пояс'
                  option={TIMEZONE}
                  value={timezone}
                  // value={this.value[15].value}
                  onChangeValue={this.doChangeValue}
                  onChangeVisionBlock={this.doEmpty}
                  nameForm={this.state.nameForm}
                  labelSmall={false}
                />
              </Row>
              <Row> &nbsp;</Row>

              <Row> &nbsp;</Row>
              {this.state.changePasswordModal && (
                <QuitModal
                  darkVariant={true}
                  differentBtnColor={'secondary'}
                  icon={
                    <>
                      {' '}
                      <PhonelinkLockOutlinedIcon color='action' />
                    </>
                  }
                  onQuitWithSave={this.handleClickUpdatePassword}
                  onQuitWithoutSave={() => this.setState({ changePasswordModal: !this.state.changePasswordModal })}
                  closeQuitModal={() => this.setState({ changePasswordModal: !this.state.changePasswordModal })}
                  modalTitle=' Смена пароля'
                  modalBody={
                    <>
                      {this.state.showInput && (
                        <Form.Group>
                          {/* <Row> */}

                          <Container fluid>
                            <div style={{ position: 'relative' }}>
                              {this.state.oldPass && this.state.oldPass.length && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    right: '40px',
                                    top: '32px',
                                    zIndex: '21',
                                    cursor: 'pointer'
                                  }}
                                  onClick={this.showPasswordNew}
                                >
                                  {this.state.isNewPasswordVisible ? (
                                    <VisibilityOffOutlinedIcon fontSize='small' color='action' />
                                  ) : (
                                    <RemoveRedEyeOutlinedIcon fontSize='small' color='action' />
                                  )}
                                </div>
                              )}
                              <InputForm
                                id={12}
                                width={12}
                                show={true}
                                // verify="number"
                                label=''
                                placeholder='Старый пароль'
                                description='old password'
                                // value={this.value[112] ? this.value[112].value : ''}
                                // value={this.state.oldPass}
                                onChangeValue={this.doChangeOldPassword}
                                nameForm={this.state.nameForm}
                                // readOnly={!inReadMode}
                                password={this.state.isNewPasswordVisible ? false : true}
                              />
                            </div>

                            <div style={{ position: 'relative' }}>
                              {this.state.newPass && this.state.newPass.length && (
                                <div
                                  style={{
                                    position: 'absolute',
                                    right: '40px',
                                    top: '32px',
                                    zIndex: '21',
                                    cursor: 'pointer'
                                  }}
                                  onClick={this.showPasswordOld}
                                >
                                  {this.state.isOldPasswordVisible ? (
                                    <VisibilityOffOutlinedIcon fontSize='small' color='action' />
                                  ) : (
                                    <RemoveRedEyeOutlinedIcon fontSize='small' color='action' />
                                  )}
                                </div>
                              )}

                              <InputForm
                                id={11}
                                width={12}
                                show={true}
                                // verify="number"
                                label=''
                                placeholder='Новый пароль'
                                description='new password'
                                // value={this.state.newPass}
                                // value={this.value[112] ? this.value[112].value : ''}
                                onChangeValue={this.doChangeNewPassword}
                                nameForm={this.state.nameForm}
                                // readOnly={!inReadMode}
                                password={this.state.isOldPasswordVisible ? false : true}
                              />
                            </div>
                          </Container>
                          {/* </Row> */}
                        </Form.Group>
                      )}
                    </>
                  }
                  primaryButton='Изменить пароль'
                  secondaryButton='Отмена'
                />
              )}

              <Form.Group>
                <Row>
                  <Col>Список доступных предприятий:</Col>
                </Row>
                <Row> &nbsp;</Row>
                <AccessibleCompany idCompany={data.idCompany} onReturnIdCompany={this.doReturnIdCompany} />
              </Form.Group>
            </Card.Body>
            <AllModalsFooter
              isPosAbsoluteNedded={true}
              footerContent={
                <>
                  {/* <Button
                    disabled={this.state.showPassword ? true : false}
                    variant={this.state.showPassword ? 'success' : 'secondary'}
                    onClick={() => this.setState({ changePasswordModal: !this.state.changePasswordModal })}
                  >
                    {this.state.showPassword ? 'Пароль был изменен' : 'Изменить пароль'}
                  </Button> */}

                  <ChangePasswordButton
                    handleSaveClick={() => this.setState({ changePasswordModal: !this.state.changePasswordModal })}
                    needDisable={true}
                    disableCondition={this.state.showPassword ? true : false}
                    savedDataMessage={this.state.showPassword}
                    buttonTextDefault={'Изменить пароль'}
                    buttonTextSaved={'Пароль был изменен'}
                  />

                  <AddButton
                    style={{ marginLeft: '6px' }}
                    handleSaveClick={this.handleClickAddCompany}
                    needDisable={false}
                    buttonText={'предприятие'}
                  />

                  {/* <SaveButtonGreen
                    handleSaveClick={this.handleClickSave}
                    needDisable={false}
                    saveButtonText={'Сохранить данные'}
                  /> */}
                  <SaveCurrentDataButton
                    handleSaveClick={this.handleClickSave}
                    needDisable={false}
                    savedDataMessage={this.state.savedDataMessage}
                    buttonTextDefault={'Сохранить данные'}
                    buttonTextSaved={'Данные сохранены'}
                  />
                  {/* <Button variant="success" onClick={this.handleClickSave} >Сохранить данные</Button> */}
                </>
              }
            />
          </div>
        </Card>
      </>
    )
  }
}
