import React from 'react';
import { Alert, Container } from 'react-bootstrap';
import ContentEditCardEquipment from './ContentEditCardEquipment';

// toggleModal = {закрывает модалку на крестик}
// alertHeading = {заглавие модалки}
// modalContent = {содержимое модалки, все что внутри}
// dataCard - данные по карте аппарата

const ModalEditCardEquipment = ({ toggleModal, alertHeading, dataCard, doSaveCard, setHasChanges, showConfirmModal, setShowConfirmModal, onClose }) => {

    return (
        <>
            <div className="shadow_form" onClick={toggleModal} ></div>
            <div className="modal_common_1 z_index_1000" >
                <Alert variant="dark" onClose={toggleModal} dismissible>
                    <Alert.Heading >
                        {alertHeading}
                    </Alert.Heading>
                </Alert>
                <Container style={{ height: '700px', overflowY: 'auto' }}>

                    <ContentEditCardEquipment
                        doSaveCard={doSaveCard}
                        dataCard={dataCard}
                        setHasChanges={setHasChanges}
                        showConfirmModal={showConfirmModal}
                        setShowConfirmModal={setShowConfirmModal}
                        toggleModal={toggleModal}
                        onClose={onClose}
                    />

                </Container>
            </div>
        </>
    )
}
export default ModalEditCardEquipment;