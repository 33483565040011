import React, { useState, useEffect } from 'react'
import { Table, Form, Spinner } from 'react-bootstrap'
import { AddButton, CalcButton, SaveCurrentDataButton } from '../../assets/BasicButtons'

export default function GeneralCharacteristicsTable({ id, doGetTableData, savedData }) {
  const [flashSaved, setFlashSaved] = useState(false)

  console.log(savedData)

  const initialRow = {
    id,
    date: '',
    responsible: '',
    event: '',
    heatExchangeSpace: '',
    deviceWorkResults: '',
    heatPowerCalculation: '',
    heatPressureCalculation: '',
    heatTransferCalculation: ''
  }

  const [tableData, setTableData] = useState(savedData?.length ? savedData : [initialRow])
  const [showLoading, setShowLoading] = useState(
    Array.from({ length: tableData.length }, () => ({
      heatPowerCalculation: false,
      heatPressureCalculation: false,
      heatTransferCalculation: false
    }))
  )

  useEffect(() => {
    setTableData(savedData?.length ? savedData : [initialRow])
  }, [savedData])

  const handleChange = (e, rowIndex, fieldName) => {
    const newTableData = [...tableData]
    newTableData[rowIndex][fieldName] = e.target.value
    setTableData(newTableData)
    if (fieldName !== 'deviceWorkResults') {
      resetcalculations(newTableData, rowIndex, fieldName)
    }
  }

  const resetcalculations = (data, rowIndex, fieldName) => {
    if (
      (data[rowIndex][fieldName] && data[rowIndex].heatPressureCalculation) ||
      data[rowIndex].heatTransferCalculation ||
      data[rowIndex].heatPowerCalculation
    ) {
      setTableData((prevData) =>
        prevData.map((el, i) => {
          if (i === rowIndex) {
            return {
              ...el,
              heatPressureCalculation: null,
              heatTransferCalculation: null,
              heatPowerCalculation: null
            }
          }
          return el
        })
      )
    }
  }

  const addRow = () => {
    setTableData([...tableData, initialRow])
  }

  const flashSaveMessage = () => {
    setFlashSaved(true)
    setTimeout(() => {
      setFlashSaved(false)
    }, 2000)
  }

  const filterEmptyObjects = (data) => {
    const filtered = data.filter((obj) =>
      Object.entries(obj).some(([k, v]) => {
        return k !== 'id' && Boolean(v)
      })
    )

    return filtered
  }

  const handleClickSave = () => {
    flashSaveMessage()
    const newTableData = [...tableData]
    const filteredData = filterEmptyObjects(newTableData)
    doGetTableData(filteredData)
  }

  useEffect(() => {
    setShowLoading(
      Array.from({ length: tableData.length }, () => ({
        heatPowerCalculation: false,
        heatPressureCalculation: false,
        heatTransferCalculation: false
      }))
    )
  }, [tableData.length])

  const calcResultEnvironmentSpeed = (rowIndex, elementName) => {
    const newLoadingState = JSON.parse(JSON.stringify(showLoading))
    newLoadingState[rowIndex][elementName] = true
    setShowLoading(newLoadingState)

    setTimeout(() => {
      const randomNum = Math.ceil(Math.random() * 100000)
      const newTableData = [...tableData]
      newTableData[rowIndex][elementName] = randomNum
      setTableData(newTableData)

      const newLoadingState2 = JSON.parse(JSON.stringify(showLoading))
      newLoadingState2[rowIndex][elementName] = false
      setShowLoading(newLoadingState2)
    }, 1000)
  }

  return (
    <div className='pipe_table_wrap'>
      <Table bordered className='equipment_table_style'>
        <thead>
          <tr>
            <th className='th_table_style'>Дата заполнения</th>
            <th className='th_table_style'>Ответственный за заполнение</th>
            <th className='th_table_style'>Событие</th>
            <th className='th_table_style'>Площадь теплообмена, м2</th>
            <th className='th_table_style'>Тепловая мощность, МВт Расчет</th>
            <th className='th_table_style'>Температурный напор, С Расчет</th>
            <th className='th_table_style'>Коэффициент теплопередачи, Вт/м2 * С Расчет</th>
            <th className='th_table_style'>Вывод о фактической работе аппарата:</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td>
                <Form.Control
                  type='date'
                  value={row.date}
                  onChange={(e) => handleChange(e, index, 'date')}
                />
              </td>
              <td>
                <Form.Control
                  type='text'
                  value={row.responsible}
                  onChange={(e) => handleChange(e, index, 'responsible')}
                />
              </td>
              <td>
                <Form.Control
                  type='text'
                  value={row.event}
                  onChange={(e) => handleChange(e, index, 'event')}
                />
              </td>
              <td>
                <Form.Control
                  type='number'
                  value={row.heatExchangeSpace}
                  onChange={(e) => handleChange(e, index, 'heatExchangeSpace')}
                />
              </td>
              <td>
                {showLoading[index]?.heatPowerCalculation ? (
                  <div className='loader_centered'>
                    <Spinner animation='border' variant='secondary' />
                  </div>
                ) : (
                  <>
                    {row.heatPowerCalculation ? (
                      <Form.Control type='number' value={row.heatPowerCalculation} readOnly />
                    ) : (
                      <CalcButton
                        handleSaveClick={() =>
                          calcResultEnvironmentSpeed(index, 'heatPowerCalculation')
                        }
                      />
                    )}
                  </>
                )}
              </td>
              <td>
                {showLoading[index]?.heatPressureCalculation ? (
                  <div className='loader_centered'>
                    <Spinner animation='border' variant='secondary' />
                  </div>
                ) : (
                  <>
                    {row.heatPressureCalculation ? (
                      <Form.Control type='number' value={row.heatPressureCalculation} readOnly />
                    ) : (
                      <CalcButton
                        handleSaveClick={() =>
                          calcResultEnvironmentSpeed(index, 'heatPressureCalculation')
                        }
                      />
                    )}
                  </>
                )}
              </td>
              <td>
                {showLoading[index]?.heatTransferCalculation ? (
                  <div className='loader_centered'>
                    <Spinner animation='border' variant='secondary' />
                  </div>
                ) : (
                  <>
                    {' '}
                    {row.heatTransferCalculation ? (
                      <Form.Control type='number' value={row.heatTransferCalculation} readOnly />
                    ) : (
                      <CalcButton
                        handleSaveClick={() =>
                          calcResultEnvironmentSpeed(index, 'heatTransferCalculation')
                        }
                      />
                    )}
                  </>
                )}
              </td>
              <td>
                <Form.Control
                  type='text'
                  value={row.deviceWorkResults}
                  onChange={(e) => handleChange(e, index, 'deviceWorkResults')}
                />
              </td>
            </tr>
          ))}
        </tbody>
        <div className='button_group_table'>
          <AddButton handleSaveClick={addRow} />
          <SaveCurrentDataButton
            handleSaveClick={handleClickSave}
            needDisable={false}
            disableCondition={() => {}}
            savedDataMessage={flashSaved}
            buttonTextDefault={'Сохранить'}
            buttonTextSaved={'Данные соранены'}
            needCustomIcon={false}
            customIcon={''}
            needCustomStyle={false}
            customStyle={''}
          />
        </div>
      </Table>
    </div>
  )
}
