

/**
 * Возвращает newObj для рендера
 * @param {*} objChange 
 * @param {*} obj 
 * @param {*} lotFull 
 * @param {*} newObj 
 * @param {*} keyOn 
 * @returns 
 */
const lookingObjectChange = (objChange, obj, lotFull, newObj, keyOn) => {

    if (
        (objChange &&
            Object.keys(objChange).length >= Object.keys(obj).length &&
            objChange[localStorage.getItem('idUser')]) ||
        (objChange &&
            lotFull.data.organizerId === localStorage.getItem('idUser') &&
            Object.keys(objChange).length >= Object.keys(obj).length)
    ) {
        // console.log(`objChange ===============+>>>>>>>>>>> `, objChange)
        // console.log(`objChange => newObj (${keyOn})`)
        return (objChange)

    } else if (Object.keys(newObj).length) {
        // что бы не сбрасывалось при перерендере
        // console.log(`newObj => newObj (${keyOn})`)
        return (newObj)

    } else if (obj) {
        let OBJ = JSON.parse(JSON.stringify(obj)) // создаем клон!!! объекта (копируем объект, а не путь)
        // console.log(`obj => newObj (${keyOn})`)
        return (OBJ)

    }

}

export default lookingObjectChange;