import React from 'react';
import { Alert, Container, Form, Row, Col } from 'react-bootstrap';
import InputForm from '../../questionnaire/InputForm';
import SizeFormBig from '../../questionnaire/SizeFormBig';
// import ContentCutOutlinedIcon from '@mui/icons-material/ContentCutOutlined';
import { CloseBtnForFilter } from '../../assets/CloseBtnForFilter';
import Server from '../../server/server';
import { ButtonGroupForFilterForms } from '../../assets/ButtonGroupForFilterForms';


export default class FormBendingCuttingMetal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            language: 'rus',
            dataValue: [],
            maxId: 15,
            nameForm: 'FormBendingCuttingMetal',
            dataOnServer: [],
            dataFromServer: [],
            lengthDataFromServer: 0,
            showQuit: false,
            show: true,
            showVariants: true
        }

        this.doChangeValue = this.doChangeValue.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.doEmpty = this.doEmpty.bind(this);

        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);
        this.handleClickMaterieals = this.handleClickMaterieals.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
        this.sendProductDataOnServer = this.sendProductDataOnServer.bind(this)
    }

    async clearFields() {
        console.log(this.state.dataValue);
        await this.setState({
            showVariants: false
        })

        this.setState({
            showVariants: true
        })
    }

    //* empty
    doEmpty() {

    }

    handleClickMaterieals() {
        this.props.onRunMaterials('Four_FormBendingCuttingMetal');
    }

    //запуск выхода из формы
    handleClickShadow() {
        this.setState({ showQuit: true })
    }

    //выход с сохранением
    doQuitWithSave() {
        this.setState({ showQuit: false })
        this.handleClickSave();
        this.props.onChangeView('FormBendingCuttingMetal');
    }
    //выход без сохранения
    doQuitWithoutSave() {
        this.setState({ showQuit: false })
        this.props.onChangeView('FormBendingCuttingMetal');
    }

    //*формируем данные в массив объектов для отправки на сервер
    async handleClickSave(e) {
        e.preventDefault()
        let data = this.state.dataOnServer;

        for (let i = 0; i <= (this.state.maxId); i++) {

            //todo создаем позицию с пустым объектом
            data[i] = { id: '', fid: '', description: '', information: '', value: '' }

            //todo заполняем объект данными с сервера
            this.state.dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
            this.state.dataValue.forEach((dataE) => {
                if (+dataE.id === i) {
                    data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                }
            })

        }
        await this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере

        this.props.getChosenFiltersData(data)
        //отправляем родителю имя этой формы
        // this.props.getFormName(this.state.nameForm)
        //берем данные с сервера
        this.props.saveChosenFilters()


        const result = await Server.filterProductionCompany(data, this.state.nameForm)
        await this.props.sendSearchResultData(result)
        this.props.openResultTable()
        this.props.closeModal(e)
    }

    //* данные с полей формы, формируются в массив объектов
    doChangeValue(data) {
        // console.log(data);//test
        this.setState({ dataValue: [...this.state.dataValue, data] })
        // setTimeout(() => { console.log(this.state.dataValue) })//test
    }

    onKeyPressHandler(event) {
        event.target.dataset.position = 27
        if (event.keyCode === 13) {
            this.handleClickSave(event)
            // console.log(event);
        }
    }

    async sendProductDataOnServer() {
        const productData = {
            product: this.props.product,
            formName: this.state.nameForm
        }
        await Server.sendDataOnServer(productData, 'product', localStorage.getItem('idUser'))
    }

    async componentDidMount() {
        // setTimeout(() => {
        this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer });
        // console.log('DATA on dataFromServer');
        // this.forceUpdate();
        // }, 1000);

        document.addEventListener('keydown', this.onKeyPressHandler)

        await this.sendProductDataOnServer()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPressHandler)
    }

    render() {
        // console.log('RENDER');

        const view = this.props.view;
        this.value = this.props.data;
        const viewQuest = this.props.viewQuest;

        // console.log(this.value);//test

        const SIZES = [
            'Длина заготовки, мм',
            'Ширина заготовки, мм',
            'Толщина углеродистой стали, мм',
            'Толщина нержавеющей стали, мм'
        ]

        return (
            <>
                <div className="modal_window" style={{ display: view ? 'block' : 'none' }} >
                    <div className={viewQuest ? "shadow_form" : "shadow_form_off"} onClick={this.props.closeModal}></div>
                    <Form className={this.props.chosenCompaniesFromProductionSearch && this.props.chosenCompaniesFromProductionSearch.length >= 0 ? "form_main" : "form_main_1"} >
                        <Alert
                            variant="dark"
                            className="filter_alert"
                        >
                            <Alert.Heading > Услуги по резке и гибке металла </Alert.Heading>
                            <CloseBtnForFilter dataId={27} closeModal={this.props.closeModal} />
                        </Alert>
                        <div>
                            <Container className="form_container">
                            <Form.Group>

                                <Form.Label className='f_size_1em'>
                                    Максимальная толщина резания металла при различных способах и видах резки
                                </Form.Label>


                                <Row>
                                    <Col>
                                        {/* <span className="span_title" >Ручная резка: </span> */}
                                        Ручная резка:
                                    </Col>
                                </Row>
                                <Row>

                                    {this.state.showVariants && <><InputForm
                                        id={1}
                                        width={3}
                                        show={true}
                                        verify="number"
                                        label=""
                                        placeholder="Толщина, мм(углер.)"
                                        description="Ручная резка"
                                        value={this.value[1] ? this.value[1].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                        <InputForm
                                            id={2}
                                            width={3}
                                            show={true}
                                            verify="number"
                                            label=""
                                            placeholder="Толщина, мм(нерж.)"
                                            description="Ручная резка"
                                            value={this.value[2] ? this.value[2].value : ''}
                                            onChangeValue={this.doChangeValue}
                                            nameForm={this.state.nameForm}
                                        /></>}
                                </Row>
                                <Row> &nbsp; </Row>
                                <Row>
                                    <Col>
                                        {/* <span className="span_title">Автоматизированная огневая резка: </span> */}
                                        Автоматизированная огневая резка:
                                    </Col>
                                </Row>
                                {this.state.showVariants && <><Row>
                                    <InputForm
                                        id={3}
                                        width={3}
                                        show={true}
                                        verify="number"
                                        label=""
                                        placeholder="Длина рабочей зоны, мм"
                                        description="Автоматизированная огневая резка"
                                        value={this.value[3] ? this.value[3].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                    <InputForm
                                        id={4}
                                        width={3}
                                        show={true}
                                        verify="number"
                                        label=""
                                        placeholder="Ширина рабочей зоны, мм"
                                        description="Автоматизированная огневая резка"
                                        value={this.value[4] ? this.value[4].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                    <InputForm
                                        id={5}
                                        width={3}
                                        show={true}
                                        verify="number"
                                        label=""
                                        placeholder="Толщина, мм(углер.)"
                                        description="Автоматизированная огневая резка"
                                        value={this.value[5] ? this.value[5].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                    <InputForm
                                        id={6}
                                        width={3}
                                        show={true}
                                        verify="number"
                                        label=""
                                        placeholder="Толщина, мм(нерж.)"
                                        description="Автоматизированная огневая резка"
                                        value={this.value[6] ? this.value[6].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                </Row></>}
                                <Row> &nbsp;</Row>
                                <Row>
                                    <Col>
                                        {/* <span className="span_title">Лазерная резка: </span> */}
                                        Лазерная резка:
                                    </Col>
                                </Row>
                                {this.state.showVariants && <><Row>
                                    <InputForm
                                        id={7}
                                        width={3}
                                        show={true}
                                        verify="number"
                                        label=""
                                        placeholder="Длина рабочей зоны, мм"
                                        description="Лазерная резка"
                                        value={this.value[7] ? this.value[7].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                    <InputForm
                                        id={8}
                                        width={3}
                                        show={true}
                                        verify="number"
                                        label=""
                                        placeholder="Ширина рабочей зоны, мм"
                                        description="Лазерная резка"
                                        value={this.value[8] ? this.value[8].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                    <InputForm
                                        id={9}
                                        width={3}
                                        show={true}
                                        verify="number"
                                        label=""
                                        placeholder="Толщина, мм(углер.)"
                                        description="Лазерная резка"
                                        value={this.value[9] ? this.value[9].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                    <InputForm
                                        id={10}
                                        width={3}
                                        show={true}
                                        verify="number"
                                        label=""
                                        placeholder="Толщина, мм(нерж.)"
                                        description="Лазерная резка"
                                        value={this.value[10] ? this.value[10].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                </Row></>}
                                <Row> &nbsp;</Row>
                                <Row>
                                    <Col>
                                        {/* <span className="span_title">Гидроабразивная резка: </span> */}
                                        Гидроабразивная резка:
                                    </Col>
                                </Row>
                                {this.state.showVariants && <> <Row>
                                    <InputForm
                                        id={11}
                                        width={3}
                                        show={true}
                                        verify="number"
                                        label=""
                                        placeholder="Длина рабочей зоны, мм"
                                        description="Гидроабразивная резка"
                                        value={this.value[11] ? this.value[11].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                    <InputForm
                                        id={12}
                                        width={3}
                                        show={true}
                                        verify="number"
                                        label=""
                                        placeholder="Ширина рабочей зоны, мм"
                                        description="Гидроабразивная резка"
                                        value={this.value[12] ? this.value[12].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                    <InputForm
                                        id={13}
                                        width={3}
                                        show={true}
                                        verify="number"
                                        label=""
                                        placeholder="Толщина, мм(углер.)"
                                        description="Гидроабразивная резка"
                                        value={this.value[13] ? this.value[13].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                    <InputForm
                                        id={14}
                                        width={3}
                                        show={true}
                                        verify="number"
                                        label=""
                                        placeholder="Толщина, мм(нерж.)"
                                        description="Гидроабразивная резка"
                                        value={this.value[14] ? this.value[14].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        nameForm={this.state.nameForm}
                                    />
                                </Row></>}

                            </Form.Group>

                            <Row>
                                <Col>
                                    {/* <span className="span_title">Гибка металла: </span> */}
                                    Гибка металла:
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {this.state.showVariants &&
                                        <SizeFormBig
                                            id={15}
                                            width={3}
                                            show={this.state.show}
                                            verify="number"
                                            // label={label[2]}
                                            fontSize={'12px'}
                                            placeholder={SIZES}
                                            description={this.props.description}
                                            value={this.value}
                                            onChangeValue={this.doChangeValue}
                                            nameForm={this.props.nameForm} />}

                                </Col>
                            </Row>
                            <Row> &nbsp;</Row>
                            <Row> &nbsp;</Row>
                        </Container>

                            <ButtonGroupForFilterForms
                                dataId={27}
                                handleClickSave={this.handleClickSave}
                                clearFields={this.clearFields}
                                savedFiltersData={this.props.savedFiltersData}
                            />
                        </div>
                        
                    </Form>
                </div>

            </>
        )
    }
}