/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Alert, Container, Form, Row } from 'react-bootstrap';
import CheckForm from '../../questionnaire/CheckForm';
import SizeFormBig from '../../questionnaire/SizeFormBig';
import ModalLoad from '../../ModalLoad';
import FormMaterList from '../../questionnaire/FormMaterList';
import {
    TANK_WITHOUT_MECH,
    TANK_WITH_MECH,
    TANK_WITH_MIXER,
    TANK_BALL,
    TANK_TERMO,
    TANK_SAVE,
    TANK_ROLL
} from '../../questionnaire/ConstDownProduction';
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { HandleAllCheckBoxesBtn } from '../../assets/HandleAllCheckboxesBtn';
import Server from '../../server/server';
import { CloseBtnForFilter } from '../../assets/CloseBtnForFilter';
import { ButtonGroupForFilterForms } from '../../assets/ButtonGroupForFilterForms';

export default class Zero extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: false,
            dataValue: [],
            maxId: 70,
            nameForm: 'Zero',
            dataOnServer: [],
            dataFromServer: [],
            lengthDataFromServer: 0,
            visible: [false, false, false, false, false, false, false],
            showAllCheckBoxes: [true, true, true, true, true, true, true],
            selectCheck: [true, true, true, true, true, true, true],
            idState: [1, 10, 19, 28, 37, 46, 55],
            showLook: false,
            showQuit: false,
            showCarbon: false,
            showVariants: true,
            disableClearBtn: false,
            // test: 'default'
        }

        this.doChangeValue = this.doChangeValue.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.doEmpty = this.doEmpty.bind(this);

        this.doVisible = this.doVisible.bind(this);
        this.reloadDataonState = this.reloadDataonState.bind(this);

        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);

        this.handleClickMaterieals = this.handleClickMaterieals.bind(this);// новое имя формы материалов
        this.handleAllChecked = this.handleAllChecked.bind(this)
        this.clearFields = this.clearFields.bind(this)
        this.onKeyPressHandler = this.onKeyPressHandler.bind(this)
        this.sendProductDataOnServer = this.sendProductDataOnServer.bind(this)
    }

    async clearFields() {

        this.setState({
            showVariants: false
        })

        const res = await this.state.dataValue.map(el => {
            if (typeof el.value === 'object') {
                const selectArray = this.state.selectCheck.fill(true)
                this.setState({ selectCheck: selectArray })
            }
            if (!el) {
                this.setState({
                    showVariants: false
                })
                setTimeout(() => {
                    this.setState({
                        showVariants: true
                    })
                });
            } else {
                return {
                    ...this.state.dataValue,
                    value: ''
                }
            }
        })
        this.setState({
            dataValue: res
        })
        this.setState({
            showVariants: true
        })

    }

    handleAllChecked(e) {
        let arr = this.state.showAllCheckBoxes //массив отображения секции с неосновными чекбоксами (изначально они все true)
        const pos = Number(e.target.dataset.id) //берем dataset у элемента, на который кликаем
        arr[pos] = false //у массива с неосновными чекбоксами меняем одно значение, он становится таким: [false, true, true, ....]

        const posSet = this.state.selectCheck //массив конкретно самих чекбоксов секции неосновных чекбоксов (изначально они все true)
        posSet[pos] = !posSet[pos] //тоглим выбранную позицию у массива в конкретно самих чекбоксов с true на false
        // из всего вышеперечисленного получаем 2 массива: [false, true, true, ....] и [true, true, true...] тоглим на [false, false, false]

        this.setState({
            selectCheck: posSet,
            showAllCheckBoxes: arr
        })
        arr = this.state.showAllCheckBoxes; //отдаем масиву дефолтное значение
        setTimeout(() => {
            arr[pos] = true; // меняем позицию по setTimeout тк иначе он не успевает менять значения
            this.setState({
                showAllCheckBoxes: arr
            })
        })
        // то массив неосновных чекбоксов снова отображается
    }

    //*видимость размеров при выборе позиции
    doVisible(e, id) {

        let visibleState = this.state.visible;
        let idState = this.state.idState;

        idState.forEach((data, index) => {
            if (data === +id) visibleState[index] = e;
        });

        this.setState({ visible: visibleState });
    }

    //*Пустышка
    doEmpty() {
    }

    //запуск выхода из формы
    handleClickShadow() {
        this.setState({ showQuit: true })
    }

    //выход с сохранением
    doQuitWithSave() {
        this.setState({ showQuit: false })
        this.handleClickSave();
        this.props.onChangeView('Zero');
    }
    //выход без сохранения
    doQuitWithoutSave() {
        this.setState({ showQuit: false })
        this.props.onChangeView('Zero');
    }

    //*формируем данные в массив объектов для отправки на сервер
    async handleClickSave(e) {
        e.preventDefault()
        let data = this.state.dataOnServer;

        for (let i = 0; i <= (this.state.maxId); i++) {

            //todo создаем позицию с пустым объектом
            data[i] = { id: '', fid: '', description: '', information: '', value: '' }

            //todo заполняем объект данными с сервера
            this.state.dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
            this.state.dataValue.forEach((dataE) => {
                if (+dataE.id === i) {
                    data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                }
            })
        }
        await this.setState({ dataOnServer: data })
        //получаем данные выбраные пользователем
        this.props.getChosenFiltersData(data)
        //отправляем родителю имя этой формы
        // this.props.getFormName(this.state.nameForm)
        //берем данные с сервера
        this.props.saveChosenFilters()


        const result = await Server.filterProductionCompany(data, this.state.nameForm)
        await this.props.sendSearchResultData(result)
        this.props.openResultTable()
        this.props.closeModal(e)

    }

    //* данные с полей формы, формируются в массив объектов
    doChangeValue(data, e) {
        // console.log(data);//test
        this.setState({ dataValue: [...this.state.dataValue, data] })
        // setTimeout(() => { console.log(this.state.dataValue) })//test
    }

    reloadDataonState() {
        this.props.data.forEach((data) => {
            this.doVisible(data.value, +data.id)
        })
    }
    handleClickMaterieals() {
        this.props.onRunMaterials('Four_Zero');
    }

    onKeyPressHandler(event) {
        event.target.dataset.position = 17
        if (event.keyCode === 13) {
            this.handleClickSave(event)
        }
    }

    async sendProductDataOnServer() {
        const productData = {
            product: this.props.product,
            formName: this.state.nameForm
        }
        await Server.sendDataOnServer(productData, 'product', localStorage.getItem('idUser'))

        // console.log(productData);
    }

    async componentDidMount() {
        this.setState({ showLook: true });
        setTimeout(() => {
            this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer });
            // console.log('DATA on dataFromServer');//test
            this.reloadDataonState();
        }, 1000);

        document.addEventListener('keydown', this.onKeyPressHandler)

        await this.sendProductDataOnServer()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPressHandler)
        // this.setState({test: 'changed'})
        // this.props.sendTest('changed')
    }

    render() {

        const sizes = ["Объем, м3", "Длина, мм", "Ширина, мм", "Высота, мм", "Диаметр, мм", "Рабочее давление, МПа", "Масса, тн"];
        const sizeTermo = ["Длина, мм", "Диаметр, мм", "Давление, МПа", "Температура, C", "Масса, тн"];
        const sizeTank = ["Объем, м3", "Рабочая температура, C", "Рабочее давление, МПа"];
        const visible = this.state.visible;
        const view = this.props.view;
        this.value = this.props.data;
        const viewQuest = this.props.viewQuest;
        const selectCheck = this.state.selectCheck
        const showAllCheckBoxes = this.state.showAllCheckBoxes;

        return (
            <>
                {this.state.showLook ? <ModalLoad
                    stopLooking={1500}
                />
                    : ''}
                <div className="modal_window" style={{ display: view ? 'block' : 'none' }} >
                    <div className={viewQuest ? "shadow_form" : "shadow_form_off"} onClick={this.handleClickShadow}></div>
                    <Form className={this.props.chosenCompaniesFromProductionSearch && this.props.chosenCompaniesFromProductionSearch.length >= 0 ? "form_main" : "form_main_1"} >
                        <Alert
                            variant="dark"
                            className="filter_alert"
                        >
                            <Alert.Heading>Производственное оборудование производимое на предприятии </Alert.Heading>
                            <CloseBtnForFilter dataId={17} closeModal={this.props.closeModal} />
                        </Alert>
                        <div>
                            <Container className="form_container">
                                <Form.Label className='f_size_1em'>
                                    Наименования, максимальные габаритные размеры и максимальное рабочее давление выпускаемого оборудования
                                </Form.Label>

                                <Form.Group>
                                    {this.state.showVariants && <span>
                                        <Form.Group>
                                            <Row>
                                                <CheckForm
                                                    id={1}
                                                    width={12}
                                                    show={true}
                                                    placeholder="Емкости без внутренних устройств"
                                                    description="Производственное оборудование"
                                                    value={this.value[1] ? this.value[1].value : ''}
                                                    onChangeValue={this.doChangeValue}
                                                    onChangeVisionBlock={this.doVisible}
                                                    nameForm={this.state.nameForm}
                                                />
                                            </Row>
                                        </Form.Group>

                                        <Form.Group style={{ display: visible[0] ? 'block' : 'none' }} >

                                            <Form.Group>
                                                {visible[0] &&
                                                    <>
                                                        <SizeFormBig
                                                            id={2}
                                                            width={2}
                                                            fontSize={'12px'}
                                                            show={visible[0]}
                                                            value={this.value}
                                                            description="Емкости без внутренних устройств"
                                                            placeholder={sizes}
                                                            onChangeValue={this.doChangeValue}
                                                            nameForm={this.state.nameForm}
                                                        />
                                                        <Row>&nbsp; </Row>
                                                        {!this.value[9] &&
                                                            <>
                                                                <HandleAllCheckBoxesBtn
                                                                    handleAllChecked={this.handleAllChecked}
                                                                    selectCheck={selectCheck[0]}
                                                                    dataId={0}
                                                                />
                                                            </>
                                                        }
                                                    </>
                                                }

                                                {showAllCheckBoxes[0] &&
                                                    <>
                                                        <Row>
                                                            <FormMaterList
                                                                id={9}
                                                                width={12}
                                                                materials={TANK_WITHOUT_MECH}
                                                                value={this.value[9] ? this.value[9].value : ''}
                                                                description="Емкости без внутренних устройств"
                                                                onChangeValue={this.doChangeValue}
                                                                nameForm={this.state.nameForm}
                                                                selectCheck={selectCheck[0]}
                                                            />
                                                        </Row>
                                                    </>
                                                }
                                                <Row> &nbsp; </Row>

                                            </Form.Group>
                                            <Row> <div className='border_top_topline_100 mb_10 mt_10'></div></Row>
                                        </Form.Group>

                                        {/* 1.2 */}
                                        <Form.Group>
                                            <Row>
                                                <CheckForm
                                                    id={10}
                                                    width={12}
                                                    show={true}
                                                    placeholder="Емкости с внутренними устройствами"
                                                    description="Производственное оборудование"
                                                    value={this.value[10] ? this.value[10].value : ''}
                                                    onChangeValue={this.doChangeValue}
                                                    onChangeVisionBlock={this.doVisible}
                                                    nameForm={this.state.nameForm}
                                                />
                                            </Row>
                                        </Form.Group>
                                        <Form.Group style={{ display: visible[1] ? 'block' : 'none' }} >
                                            <Form.Group>
                                                {visible[1] &&
                                                    <>
                                                        <SizeFormBig
                                                            id={11}
                                                            width={2}
                                                            fontSize={'12px'}
                                                            show={visible[1]}
                                                            value={this.value}
                                                            description="Емкости с внутренними устройствами"
                                                            placeholder={sizes}
                                                            onChangeValue={this.doChangeValue}
                                                            nameForm={this.state.nameForm}
                                                        />
                                                        <Row>&nbsp; </Row>
                                                        {!this.value[18] &&
                                                            <>
                                                                <HandleAllCheckBoxesBtn
                                                                    handleAllChecked={this.handleAllChecked}
                                                                    selectCheck={selectCheck[1]}
                                                                    dataId={1}
                                                                />
                                                            </>
                                                        }
                                                    </>
                                                }
                                                {showAllCheckBoxes[1] &&
                                                    <Row>
                                                        <FormMaterList
                                                            id={18}
                                                            width={12}
                                                            materials={TANK_WITH_MECH}
                                                            value={this.value[18] ? this.value[18].value : ''}
                                                            description="Емкости с внутренними устройствами"
                                                            onChangeValue={this.doChangeValue}
                                                            nameForm={this.state.nameForm}
                                                            selectCheck={selectCheck[1]}
                                                        />
                                                    </Row>
                                                }

                                                <Row> &nbsp; </Row>

                                            </Form.Group>
                                            <Row> <div className='border_top_topline_100 mb_10 mt_10'></div> </Row>
                                        </Form.Group>
                                        {/* 1.3 */}
                                        <Form.Group>
                                            <Row>
                                                <CheckForm
                                                    id={19}
                                                    width={12}
                                                    show={true}
                                                    placeholder="Емкости с перемешивающими устройствами"
                                                    description="Производственное оборудование"
                                                    value={this.value[19] ? this.value[19].value : ''}
                                                    onChangeValue={this.doChangeValue}
                                                    onChangeVisionBlock={this.doVisible}
                                                    nameForm={this.state.nameForm}
                                                />
                                            </Row>
                                        </Form.Group>
                                        <Form.Group style={{ display: visible[2] ? 'block' : 'none' }} >
                                            <Form.Group>
                                                {visible[2] &&
                                                    <>
                                                        <SizeFormBig
                                                            id={20}
                                                            width={2}
                                                            fontSize={'12px'}
                                                            show={visible[2]}
                                                            value={this.value}
                                                            description="Емкости с перемешивающими устройствами"
                                                            placeholder={sizes}
                                                            onChangeValue={this.doChangeValue}
                                                            nameForm={this.state.nameForm}
                                                        />
                                                        <Row>&nbsp; </Row>
                                                        {!this.value[27] &&
                                                            <>
                                                                <HandleAllCheckBoxesBtn
                                                                    handleAllChecked={this.handleAllChecked}
                                                                    selectCheck={selectCheck[2]}
                                                                    dataId={2}
                                                                />
                                                            </>}
                                                    </>}

                                                {showAllCheckBoxes[2] && <Row>
                                                    <FormMaterList
                                                        id={27}
                                                        width={12}
                                                        materials={TANK_WITH_MIXER}
                                                        value={this.value[27] ? this.value[27].value : ''}
                                                        description="Емкости с перемешивающими устройствами"
                                                        onChangeValue={this.doChangeValue}
                                                        nameForm={this.state.nameForm}
                                                        selectCheck={selectCheck[2]}
                                                    />
                                                </Row>}
                                                <Row> &nbsp; </Row>

                                            </Form.Group>


                                            <Row> <div className='border_top_topline_100 mb_10 mt_10'></div> </Row>
                                        </Form.Group>
                                        {/* 1.4 */}
                                        <Form.Group>
                                            <Row>
                                                <CheckForm
                                                    id={28}
                                                    width={12}
                                                    show={true}
                                                    placeholder="Емкости для хранения"
                                                    description="Производственное оборудование"
                                                    value={this.value[28] ? this.value[28].value : ''}
                                                    onChangeValue={this.doChangeValue}
                                                    onChangeVisionBlock={this.doVisible}
                                                    nameForm={this.state.nameForm}
                                                />
                                            </Row>
                                        </Form.Group>
                                        <Form.Group style={{ display: visible[3] ? 'block' : 'none' }} >
                                            <Form.Group>
                                                {visible[3] &&
                                                    <SizeFormBig
                                                        id={29}
                                                        width={2}
                                                        fontSize={'12px'}
                                                        show={visible[3]}
                                                        value={this.value}
                                                        description="Емкости для хранения"
                                                        placeholder={sizes}
                                                        onChangeValue={this.doChangeValue}
                                                        nameForm={this.state.nameForm}
                                                    />
                                                }

                                                <Row>
                                                    <FormMaterList
                                                        id={36}
                                                        width={12}
                                                        materials={TANK_SAVE}
                                                        value={this.value[36] ? this.value[36].value : ''}
                                                        description="Емкости для хранения"
                                                        onChangeValue={this.doChangeValue}
                                                        nameForm={this.state.nameForm}
                                                        selectCheck={selectCheck[3]}
                                                    />
                                                </Row>

                                                <Row> &nbsp; </Row>

                                            </Form.Group>
                                            <Row> <div className='border_top_topline_100 mb_10 mt_10'></div> </Row>
                                        </Form.Group>
                                        {/* 1.5 */}
                                        <Form.Group>
                                            <Row>
                                                <CheckForm
                                                    // name={nanoid()}
                                                    id={37}
                                                    width={12}
                                                    show={true}
                                                    placeholder="Рулонированные сосуды"
                                                    description="Производственное оборудование"
                                                    value={this.value[37] ? this.value[37].value : ''}
                                                    onChangeValue={this.doChangeValue}
                                                    onChangeVisionBlock={this.doVisible}
                                                    nameForm={this.state.nameForm}
                                                />
                                            </Row>
                                        </Form.Group>
                                        <Form.Group style={{ display: visible[4] ? 'block' : 'none' }} >
                                            <Form.Group>
                                                {visible[4] && <SizeFormBig
                                                    id={38}
                                                    width={2}
                                                    fontSize={'12px'}
                                                    show={visible[4]}
                                                    value={this.value}
                                                    description="Рулонированные сосуды"
                                                    placeholder={sizeTank}
                                                    onChangeValue={this.doChangeValue}
                                                    nameForm={this.state.nameForm}
                                                />
                                                }
                                                <Row>
                                                    <FormMaterList
                                                        id={45}
                                                        width={12}
                                                        materials={TANK_ROLL}
                                                        value={this.value[45] ? this.value[45].value : ''}
                                                        description="Рулонированные сосуды"
                                                        onChangeValue={this.doChangeValue}
                                                        nameForm={this.state.nameForm}
                                                        selectCheck={this.state.selectCheck}
                                                    />
                                                </Row>
                                                <Row> &nbsp; </Row>

                                            </Form.Group>


                                            <Row> <div className='border_top_topline_100 mb_10 mt_10'></div> </Row>
                                        </Form.Group>
                                        {/* 1.6 */}
                                        <Form.Group>
                                            <Row>
                                                <CheckForm
                                                    id={46}
                                                    width={12}
                                                    show={true}
                                                    placeholder="Шаровые резервуары"
                                                    description="Производственное оборудование"
                                                    value={this.value[46] ? this.value[46].value : ''}
                                                    onChangeValue={this.doChangeValue}
                                                    onChangeVisionBlock={this.doVisible}
                                                    nameForm={this.state.nameForm}
                                                />
                                            </Row>
                                        </Form.Group>
                                        <Form.Group style={{ display: visible[5] ? 'block' : 'none' }} >
                                            <Form.Group>
                                                {visible[5] && <SizeFormBig
                                                    id={47}
                                                    width={2}
                                                    fontSize={'12px'}
                                                    show={visible[5]}
                                                    value={this.value}
                                                    description="Шаровые резервуары"
                                                    placeholder={sizeTank}
                                                    onChangeValue={this.doChangeValue}
                                                    nameForm={this.state.nameForm}
                                                />}
                                                <Row>
                                                    <FormMaterList
                                                        id={54}
                                                        width={12}
                                                        materials={TANK_BALL}
                                                        value={this.value[54] ? this.value[54].value : ''}
                                                        description="Шаровые резервуары"
                                                        onChangeValue={this.doChangeValue}
                                                        nameForm={this.state.nameForm}
                                                        selectCheck={this.state.selectCheck}
                                                    />
                                                </Row>
                                                <Row> &nbsp; </Row>

                                            </Form.Group>

                                            <Row> <div className='border_top_topline_100 mb_10 mt_10'></div> </Row>
                                        </Form.Group>
                                        {/* 1.7 */}
                                        <Form.Group>
                                            <Row>
                                                <CheckForm
                                                    id={55}
                                                    width={12}
                                                    show={true}
                                                    placeholder="Кожухотрубное теплообменное оборудование"
                                                    description="Производственное оборудование"
                                                    value={this.value[55] ? this.value[55].value : ''}
                                                    onChangeValue={this.doChangeValue}
                                                    onChangeVisionBlock={this.doVisible}
                                                    nameForm={this.state.nameForm}
                                                />
                                            </Row>
                                        </Form.Group>
                                        <Form.Group style={{ display: visible[6] ? 'block' : 'none' }} >
                                            <Form.Group>
                                                {visible[6] && <>
                                                    <SizeFormBig
                                                        id={56}
                                                        width={2}
                                                        fontSize={'12px'}
                                                        show={visible[6]}
                                                        value={this.value}
                                                        description="Кожухотрубное теплообменное оборудование"
                                                        placeholder={sizeTermo}
                                                        onChangeValue={this.doChangeValue}
                                                        nameForm={this.state.nameForm}
                                                    />
                                                    <Row>&nbsp; </Row>


                                                    {!this.value[63] &&

                                                        <HandleAllCheckBoxesBtn
                                                            handleAllChecked={this.handleAllChecked}
                                                            selectCheck={selectCheck[6]}
                                                            dataId={6}
                                                        />
                                                    }
                                                </>}

                                                {showAllCheckBoxes[6] &&
                                                    <Row>
                                                        <FormMaterList
                                                            id={63}
                                                            width={12}
                                                            materials={TANK_TERMO}
                                                            value={this.value[63] ? this.value[63].value : ''}
                                                            description="Кожухотрубное теплообменное оборудование"
                                                            onChangeValue={this.doChangeValue}
                                                            nameForm={this.state.nameForm}
                                                            selectCheck={selectCheck[6]}
                                                        />
                                                    </Row>}
                                                <Row> &nbsp; </Row>

                                            </Form.Group>

                                        </Form.Group>
                                    </span>}
                                    <Row> &nbsp; </Row>
                                    <Row> &nbsp; </Row>


                                </Form.Group>    {/* 1.1 */}

                                <Row> &nbsp;</Row>
                            </Container>
                            <ButtonGroupForFilterForms
                                disableClearBtn={this.state.disableClearBtn}
                                dataId={17}
                                handleClickSave={this.handleClickSave}
                                clearFields={this.clearFields}
                                savedFiltersData={this.props.savedFiltersData}
                            />
                        </div>

                    </Form>
                </div>
            </>
        )
    }
}