import Server from '../server/server'

/**
 * Загружавем с БД реестр аппартов
 * @param {String} inn - ИНН компании
 * @returns  - object
 */
const getRegistry = async (inn) => {
    try {
        const dataRegistry = await Server.getDataFromServer('registry', inn)

        if (dataRegistry === null) throw new Error('Нет Реестра аппартов или ошибка при загрузке')

        return dataRegistry;
    } catch (err) {
        console.log(`Ошибка загрузки реестра оборудования:  `, err);
        try {
            await _createRegistry(inn);
            const dataRegistryNewCreate = await Server.getDataFromServer('registry', inn)

            return dataRegistryNewCreate;
        } catch (err) {
            console.log(`Ошибка при создании реестра: `, err);

            return null
        }
    }

}
export default getRegistry;


/**
 * Создаем реестр при его отсутсвии в ДБ
 * @param {String} inn - ИНН Компании
 */
const _createRegistry = async (inn) => {
    try {
        console.log(`Создаем реестр для ${inn} компании`); // test
        const data = []
        await Server.createDataOnServer(data, 'registry', inn)

    } catch (err) {
        console.log(`Ошибка при создании реестра, реестр не созадн (_createRegistry): `, err);
    }
}