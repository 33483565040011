import React, { useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import UniversalTable from '../assets/UniversalTable'
import Server from '../server/server'
import TradePosition from './TradePosition'
import AllModalsFooter from '../assets/AllModalsFooter'
import { AddButton, CreateButton } from '../assets/BasicButtons'

export const TradePositionModal = ({
  serverData,
  onGetArrayChoice,
  openPositionModal,
  onGetChosenPositions,
  handleClickCreateTradePosition,
  rerenderPosition,
  readFromServerData,
  switchPositionModal,
  openQuestionnairy,
  
}) => {
  const [posId, setPosId] = useState('')
  const [isAnotherBtnPressed, setIsAnotherBtnPressed] = useState(false)
  const [addedPositions, setAddedPositiond] = useState([])

  const doGetArrayChoice = async arr => {
    onGetArrayChoice(arr)
    await Server.sendDataOnServer(arr, 'productionArray', localStorage.getItem('idUser'))
    // console.log(`RESULT send DATA on Server::: `, res);
    // console.log(arr)

  }

  const doGetChoice = array => {
    onGetChosenPositions(array)
    rerenderPosition()
    setAddedPositiond(array)

setTimeout(() => {
   console.log('addedPositions', addedPositions);
}, 1000);
   
  }

  const handleAddNewPosition = () => {
    handleClickCreateTradePosition()
  }

  const setPosIdToState = async id => {
    await setPosId(id)
  }

  const closePosDetailsModal = () => {
    setPosId(null)
  }

  serverData.map((d, index) => {
    const date = new Date(d.createDate)
    const year = date.getFullYear()
    let month
    let dateD

    if (date.getMonth() + 1 < 10) {
      month = `0${date.getMonth() + 1}`
    } else {
      month = date.getMonth() + 1
    }

    if (date.getDate() < 10) {
      dateD = `0${date.getDate()}`
    } else {
      dateD = date.getDate()
    }

    let dateNew = `${year}-${month}-${dateD}`

    if (!d.createDate) {
      dateNew = `no date`
    }

    // ! перемножаем колличество с ценой за штуку
    let newPrice
    if (d.commercial[10] && d.commercial[6]) {
      newPrice = Number(d.commercial[10].value) * Number(d.commercial[6].value)
    } else if (d.commercial[10] && !d.commercial[6]) {
      newPrice = Number(d.commercial[10].value) * 1
    }

    return Object.assign(
      d,
      // { comm: d.commercial[10] && d.commercial[10].value, dateN: dateNew, positionNumber: (serverData.length - index) },
      {
        comm: d.commercial[10] && newPrice,
        dateN: dateNew,
        positionNumber: serverData.length - index
      }
    )
  })

  const addPosition = () => {
    setIsAnotherBtnPressed(true)
  }

  const empty = () => {}

  return (
    <div
    // className="modal_window"
    >
      <div className='shadow z_index_19'></div>

      <Form className='lot_position_modal z_index_19 width_60' size='lg'>
        <Alert dismissible onClick={openPositionModal} variant='dark'>
          <Alert.Heading >Позиции</Alert.Heading>
        </Alert>
        <div
          className='lot_position_modal_content overflow_y_scroll height_350'
          style={{ padding: '10px 35px 15px 35px' }}
        >
          <UniversalTable
            isRowGreen={true}
            selectable={true}
            selectable1={false}
            selectableCheck={true}
            addArchive={true} // TODO: добавления в архив
            mainButtonName='Добавить'
            showMainButton={false}
            tableName='Выбор позиций'
            titleObjectData={{
              positionNumber: 'п/п',
              nameTrade: 'Название',
              dateN: 'Дата созадния',
              comm: 'НМЦ, руб'
            }}
            renderedArray={serverData} //массив, который будет рендерить таблица
            renderedArrayWithAdded={doGetArrayChoice}
            chosenObjectsArray={doGetChoice} //только выбранные объекты
            chosenIdsArray={empty}
            chosenObjectId={setPosIdToState}
            handleCancel={empty}
            handleModalToggle={openPositionModal}
            isAnotherBtnPressed={isAnotherBtnPressed} //если нужно запустить дочернюю ф-ю handleClick которая запускается
            openQuestionnairy={openQuestionnairy}
            test={true}
          />
        </div>
        <div className='all_modals_footer_space_between'>
           <AllModalsFooter
          footerContent={
            <>
              <CreateButton
                // btnWidth='200px'
                handleSaveClick={handleAddNewPosition}
                needDisable={false}
                variant='primary'
                buttonText='Создать позицию'
                iconWithPlus={true}
              />
              {/* <Button variant="primary" onClick={handleAddNewPosition}>
                Создать позицию
              </Button> */}
              <AddButton handleSaveClick={addPosition} 
              needDisable={true} 
              disableCondition={addedPositions.length > 0 ? false : true}
              buttonText='выбранные позиции' />
              {/* <Button variant="success" onClick={addPosition}>
                Добавить выбранные позиции
              </Button> */}
            </>
          }
        />
        </div>
       

        {serverData.map(el => {
          if (el.id === posId) {
            return (
              <TradePosition
                show={true}
                nameTrade={el.nameTrade}
                dataTech={el.tech}
                dataCommerce={el.commercial}
                showTech={true}
                showCommercial={true}
                serverData={serverData}
                keyNameTrade={el.keyNameTrade}
                organizerId={el.organizerId}
                priority={el.priority}
                fileId={el.idFile}
                archive={el.archive}
                createDate={el.createDate}
                onChangeShowTrade={closePosDetailsModal} // команда на закрытие компонента
                readFromServerData={readFromServerData} // указывает, что создает новый файл торговой позиции
                openPositionModal={switchPositionModal} // еще указявка на закрытие компонента ???
              />
            )
          }
        })}
      </Form>
    </div>
  )
}
