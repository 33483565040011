
/**
 * Парсит дату из new Date() в вид 'DD.MM.YYYY'
 * @param {String} dateOld 
 * @returns {String} 'DD.MM.YYYY'
 */
const parseDate = (dateOld) => {

    const dateAll = new Date(dateOld)
    const dateY = dateAll.getFullYear()
    let dateM = dateAll.getMonth() + 1
    let dateD = dateAll.getDate()

    if (dateM < 10) dateM = `0${dateM}`

    if (dateD < 10) dateD = `0${dateD}`

    return `${dateD}.${dateM}.${dateY}`
}

export default parseDate;