/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Container, Form, Row, Button, Col } from 'react-bootstrap'
import CheckForm from './CheckForm'
import Form_Five_Termo from './Form_Five_Termo'
import SizeForm from './SizeForm'
import { TERMO_PRODUCTION } from './ConstProduction'
import FormMaterList from './FormMaterList'
import ModalQuit from '../ModalQuit'
import DeviceThermostatOutlinedIcon from '@mui/icons-material/DeviceThermostatOutlined'
import { nanoid } from 'nanoid'
import CommonAlert from './CommonAlert'
import AllModalsFooter from '../assets/AllModalsFooter'
import { SaveButtonGreen, SaveCurrentDataButton } from '../assets/BasicButtons'
import { createData } from './functionsForm'
import { isEqual } from 'lodash-es'

export default class Form_Five extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'rus',
      dataValue: [],
      id: 11,
      maxId: 36,
      nameForm: 'Five',
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      base: [],
      show: false,
      showMain: false,
      showAut: false,
      showBox: false,
      showNotBox: false,
      showMat: false,
      showMat1: false,
      showBoxAut: false,
      showNotBoxAut: false,
      showMatAut: false,
      showMatAut1: false,
      showQuit: false,
      showContent: true,
      previousState: [],
      savedDataMessage: false,
      dataBeenSaved: false
    }

    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.createBlockForm = this.createBlockForm.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this)
    this.onRunBlock = this.onRunBlock.bind(this)

    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.hideContent = this.hideContent.bind(this)
    this.setCompanyName = this.setCompanyName.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
  }

  saveDataWithNotification() {
    this.handleClickSave()

    this.setState({
      savedDataMessage: true,
      dataBeenSaved: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }

  hideContent() {
    this.setState({ showContent: false })
  }

  setCompanyName() {
    if (this.props.companyName && this.props.companyName.length > 0) {
      this.setState({
        companyName: this.props.companyName
      })
    }
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })
  }

  //*Пустышка
  doEmpty() {}

  //*включаем блоки материалов при загрузке
  onRunBlock(data) {
    data.forEach((elem) => {
      if (elem) this.doChangeVisionBlock(elem.value, +elem.id)
    })
  }

  doChangeVisionBlock(e, id) {
    console.log(e) //test
    console.log(id) //test

    if (+id === 1) this.setState({ showMain: e })
    if (+id === 2) this.setState({ showBox: e })
    if (+id === 8) this.setState({ showNotBox: e })
    // if (+id === 8) this.setState({ showMat: e });
    if (+id === 13) this.setState({ showMat1: e })
    if (+id === 18) this.setState({ showAut: e })
    if (+id === 19) this.setState({ showBoxAut: e })
    if (+id === 25) this.setState({ showNotBoxAut: e })
    // if (+id === 25) this.setState({ showMatAut: e });
    if (+id === 30) this.setState({ showMatAut1: e })
  }
  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  doQuitWithSave() {
    this.setState({ showQuit: false })
    this.handleClickSave()
    this.props.onChangeView('Five')
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView('Five')
  }

  //*формируем данные в массив объектов для отправки на сервер
  handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach((dataD) => {
        if (+dataD.id === i) {
          data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach((dataE) => {
        if (+dataE.id === i) {
          data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
        }
      })
    }
    this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере
    if (this.state.lengthDataFromServer < 1) {
      console.log('CREATE DATA')
      this.props.onCreateDataServer(data, 'Five', localStorage.getItem('idUser'))
    } else {
      console.log('UPDATE DATA')
      this.props.onUpdateDataOnServer(data, 'Five', localStorage.getItem('idUser'))
    }
    setTimeout(() => {
      console.log(this.state.dataOnServer)
    }) //test
    // this.handleClickShadow();
  }

  //* данные с полей формы, формируются в массив объектов
  doChangeValue(data) {
    this.setState({ dataValue: [...this.state.dataValue, data] })
    this.setState({ dataBeenSaved: false })
  }

  //*создаем необходимое количество блоков
  createBlockForm() {}

  compare() {
    const prev = this.state.previousState
    const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)

    const isStateEqual = isEqual(prev, curr)

    if (isStateEqual) {
      return true
    }
    return false
  }

  smartWindowFunction() {
    const isStateEqual = this.compare()
    const { dataBeenSaved, dataValue } = this.state
    if (isStateEqual || dataBeenSaved || dataValue.length <= 1) {
      this.props.onChangeView('Five')
    } else {
      this.handleClickShadow()
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer })
      this.onRunBlock(this.props.data)
    }, 1000)

    this.setCompanyName()

    if (this.props.data.length) {
      const previousState = JSON.parse(JSON.stringify(this.props.data))
      this.setState({
        previousState
      })
    }
  }

  render() {
    console.log('RENDER', this.state.dataValue);

    const view = this.props.view
    this.value = this.props.data
    const viewQuest = this.props.viewQuest

    //условие по которому компонент определяет находится ли он в формате чтения
    const inReadMode = this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined

    return (
      <>
        {inReadMode && <>{this.state.showQuit ? <ModalQuit onQuitWithSave={this.doQuitWithSave} onQuitWithoutSave={this.doQuitWithoutSave} closeQuitModal={this.closeQuitModal} /> : ''}</>}

        {this.state.showContent && (
          <div className='modal_window' style={{ display: view ? 'block' : 'none' }}>
            <div
              className={viewQuest ? 'shadow_form' : 'shadow_form_off'}
              // onClick={this.handleClickShadow}
              onClick={this.smartWindowFunction}
            ></div>
            <Form className={viewQuest ? 'form_main' : 'form_main_view'}>
              <CommonAlert
                inReadMode={inReadMode}
                handleClickHeader={this.handleClickHeader}
                // handleClickShadow={this.handleClickShadow}
                handleClickShadow={this.smartWindowFunction}
                hideContent={this.hideContent}
                icon={<DeviceThermostatOutlinedIcon fontSize='large' className='icon_primary' />}
                companyName={this.state.companyName}
                sectionName='Термическая обработка'
              />
              <div>
                <Container className='form_container'>
                  <Form.Group>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        className={this.state.showMain && 'fw_bolder'}
                        id={1}
                        width={4}
                        // show={true}
                        show={!inReadMode && !this.value[1] ? false : true}
                        placeholder='Собственные возможности'
                        description='Собственная термическая обработка'
                        value={this.value[1] ? this.value[1].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Form.Group style={{ display: this.state.showMain ? 'block' : 'none' }}>
                      <FormMaterList
                        name={nanoid()}
                        id={36}
                        width={4}
                        rowView={false}
                        materials={TERMO_PRODUCTION}
                        value={this.value[36] ? this.value[36].value : ''}
                        description='Термическая обработка'
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />

                      <Row>&nbsp;</Row>
                      <Row>
                        <Col>Максимальные габариты термообрабатываемых изделий:</Col>
                      </Row>

                      <Row>
                        <CheckForm
                          name={nanoid()}
                          id={2}
                          width={4}
                          show={!inReadMode && !this.value[2] ? false : true}
                          // show={true}
                          placeholder='Печная'
                          description='Собственная термическая обработка'
                          value={this.value[2] ? this.value[2].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doChangeVisionBlock}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                      <Form_Five_Termo id={3} show={this.state.showBox} description={'Собственная термическая обработка'} value={this.value} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!inReadMode} />
                      <Row>&nbsp;</Row>
                      <Row>
                        <CheckForm
                          name={nanoid()}
                          id={8}
                          width={4}
                          show={!inReadMode && !this.value[8] ? false : true}
                          // show={true}
                          placeholder='Внепечная'
                          description='Собственная термическая обработка'
                          value={this.value[8] ? this.value[8].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doChangeVisionBlock}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>

                      <SizeForm
                        id={9}
                        width={2}
                        fontSize={'12px'}
                        show={this.state.showNotBox}
                        value={this.value}
                        description='Собственная термическая обработка'
                        placeholder={['Макс длина, мм', 'Макс ширина, мм', 'Макс высота, мм', 'Температура, С']}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <Row>&nbsp;</Row>
                      <Row>
                        {' '}
                        <div className='border_top_topline_100'></div>{' '}
                      </Row>
                    </Form.Group>
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        className={this.state.showAut && 'fw_bolder'}
                        id={18}
                        width={4}
                        show={!inReadMode && !this.value[18] ? false : true}
                        // show={true}
                        placeholder='Аутсорсинг'
                        description='Термическая обработка, аутсорсинг'
                        value={this.value[18] ? this.value[18].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    <Form.Group style={{ display: this.state.showAut ? 'block' : 'none' }}>
                      <Row>&nbsp;</Row>
                      <Row>
                        <Col>Максимальные габариты термообрабатываемых изделий:</Col>
                      </Row>

                      <Row>
                        <CheckForm
                          name={nanoid()}
                          id={19}
                          width={4}
                          show={true}
                          placeholder='Печная'
                          description='Термическая обработка, аутсорсинг'
                          value={this.value[19] ? this.value[19].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doChangeVisionBlock}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>
                      <Form_Five_Termo id={20} show={this.state.showBoxAut} description='Термическая обработка, аутсорсинг' value={this.value} onChangeValue={this.doChangeValue} nameForm={this.state.nameForm} readOnly={!inReadMode} />
                      <Row>&nbsp;</Row>
                      <Row>
                        <CheckForm
                          name={nanoid()}
                          id={25}
                          width={4}
                          show={true}
                          placeholder='Внепечная'
                          description='Термическая обработка, аутсорсинг'
                          value={this.value[25] ? this.value[25].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doChangeVisionBlock}
                          nameForm={this.state.nameForm}
                          readOnly={!inReadMode}
                        />
                      </Row>

                      <SizeForm
                        id={26}
                        width={2}
                        fontSize={'12px'}
                        show={this.state.showNotBoxAut}
                        value={this.value}
                        description='Собственная термическая обработка'
                        placeholder={['Макс длина, мм', 'Макс ширина, мм', 'Макс высота, мм', 'Температура, С']}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                      <Row>&nbsp;</Row>
                      <Row>
                        {' '}
                        <div className='border_top_topline_100'></div>{' '}
                      </Row>
                    </Form.Group>
                  </Form.Group>
                </Container>
                <AllModalsFooter
                  footerContent={
                    inReadMode && (
                      // <SaveButtonGreen handleSaveClick={this.handleClickSave} needDisable={false} saveButtonText='Сохранить' />
                      // <Button variant="success" onClick={this.handleClickSave}>Сохранить</Button>
                      <SaveCurrentDataButton
                        handleSaveClick={this.saveDataWithNotification}
                        needDisable={false}
                        savedDataMessage={this.state.savedDataMessage}
                        buttonTextDefault={'Сохранить'}
                        buttonTextSaved={'Данные сохранены'}
                        needCustomIcon={false}
                      />
                    )
                  }
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}
