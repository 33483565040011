//* работа с сервером


class Server {
    constructor() {
        this.arrDataFromServer = [];
        // менять URL тут (//192.168.1.55:)(//192.168.1.49:)(//81.91.59.38:)(//192.168.1.50:)
        // this.URL = `http://localhost:`
        this.URL = `https://test.api.lotus-uems.ru:` // test server на 50 ip//++
        // this.URL = `https://api.lotus-uems.ru:`
        // this.URL = `http://192.168.1.52:`
        // this.URL = `http://192.168.1.50:` // server
        // this.URL = `http://192.168.1.55:`
        // this.URL = `http://192.168.1.49:`
        // this.URL = `http://81.91.59.38:`
        // this.PORT = `4030`
        this.PORT = `443` //++
        // this.PORT = `3030`

        this.routeURL = `${this.URL}${this.PORT}`;
        this.errorR = false; // false - сброс ошибки аутентификации (true - ошибка)
    }

    /**
     * возвращаем this.routeURL
     * @returns 
     */
    exportRouteURL() {
        return this.routeURL
    }

    /**
     * Возвращает URL Сервера
     * @returns URL
     */
    exportURL() {
        return this.URL
    }

    /**
     * Реакция на ошибку в аутентификации (true - ошибка / false - нет ошибки)
     * @returns 
     */
    errorRequest() {
        return this.errorR
    }

    /**
  * Возвращает cookie с указанным ключем (this.getCookie('inn'))
  * @param {*} name
  * @returns
  */
    getCookie(name) {
        let matches = document.cookie.match(
            new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
        )
        return matches ? decodeURIComponent(matches[1]) : undefined
    }


    //TODO читаем данные о предприятии с сервера
    /**
     * Получаем информацию о компании по указанному ID
     * @param {string} id ID компании
     * @returns object
     */
    async readDataCompany(id) {
        try {
            const urlReg = `${this.routeURL}/server_data/read_data_company`;

            if (!id || typeof (id) !== 'string') throw new Error('НЕ УКАЗАН ID ПРЕДПРИЯТИЯ или НЕ СТРОЧНЫЙ ФОРМАТ');

            const userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                id: id
            };
            const response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            });

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }
            const result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка в получении данных о компании: `, err);
        }
    }

    // todo читаем файл с сервера загруженный ранее в папку
    /**
     * Возварщает файл по указанному URL
     * @param {string} nameFile URL полный путь до файла
     * @returns Bloob
     */
    async readFileFromServer(nameFile) {
        try {
            const urlReg = `${this.routeURL}/file/file_read`;

            if (!nameFile) throw new Error('НЕ УКАЗАН URL к ФАЙЛУ')

            const userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                fileName: nameFile // * Лучше указывать полный путь до файла
            };
            const response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(userBody)
            });

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }
            const result = await response.blob();

            // console.log(`FILE>>> ::: `, result); // test
            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка чтения файла: `, err);
        }
    }


    //todo получаем данные с сервера
    /**
     * Загрузка с сервера данных
     * @param {string} name ID document 
     * @param {string} id collection (может быть массивом)
     * @returns object 
     */
    //order - collection, id заказ-наряда 
    async getDataFromServer(name, id) {
        try {
            let urlReg = `${this.routeURL}/server_data/get`; // получаем

            if (!id || !name) throw new Error('НЕ ПОЛНЫЕ ДАННЫЕ ДЛЯ ЗАГРУЗКИ ФАЙЛА')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                userid: String(id), //*id, _id collection (инн предприятия для форм с данными, registration - колекция  регистрационными данными )
                name: name //* _id document (основной ключ, наименование формы, ключ документа)
            };
            // console.log('SERVER get request : ' + id + ' ' + name); //test

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    // "Accept": "application/json",                    
                    "Content-Type": "application/json",
                    // 'Content-Type': 'application/json;charset=utf-8;Access-Control-Allow-Origin',
                },
                body: JSON.stringify(userBody)
            });

            // console.log(`RESPONSE:::: `, response); // test
            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();
            // console.log('TYPE RESULT: ', typeof(result)); //test

            if (result.ERROR) throw new Error(result.message)

            if (result) {

                if (typeof (result) === 'object') {
                    return result;
                }

                if (typeof (result) === 'string') {
                    return JSON.parse(result);
                }
            } else {

                return null
            }

        } catch (err) {
            console.error(`Ошибка получения данных (getDataFromServer): `, err);

            return null
        }


    }

    //todo отправляем данные на сервер, создаем
    /**
     * Создание данных на сервере 
     * @param {Object} data данные (Object)
     * @param {string} name ID или название документа в коллекции
     * @param {string} id название коллекции
     * @returns result
     */
    async createDataOnServer(data, name, id) {
        try {
            let urlReg = `${this.routeURL}/server_data/post`; // создаем    

            if (!data || !name || !id) throw new Error('НЕТ ПОЛНЫХ ДАННЫХ ДЛЯ ОТПРАВКИ НА САРВЕР')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                userid: id, //* _id collection (ИНН компании, id коллекции)
                name: name, //* имя формы ('Main', 'Zero', ... или login при регистрации)
                data: data //*данные отправляемые на сервер
            };

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    // "Accept": "application/json",
                    "Content-Type": "application/json"
                    // 'Content-Type': 'application/json;charset=utf-8;Access-Control-Allow-Origin',
                },
                body: JSON.stringify(userBody)
            });

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();
            // console.log(`RESULT CREATE DATA:::`, result); //test

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.log(`Ошибка при записи данных на сервер: `, err);
        }
    }

    //todo отправляем данные на сервер, обновление
    /**
     * Сохранение, обновление данных на сервере (обновляет объект целиком)
     * @param {Object} data данные (Object)
     * @param {string} name ID или название документа в коллекции
     * @param {string} collection название коллекции
     * @returns result
     */
    async sendDataOnServer(data, name, collection) {
        try {
            let urlReg = `${this.routeURL}/server_data/update`; // для обновление    

            // console.log('SERVER >>>> ', data, name - _id, id - collection);
            if (!data || !collection || !name) throw new Error('НЕ ВСЕ ДАННЫЕ ДЛЯ ОБНОВЛЕНИЯ');

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                collection: collection,
                name: name,
                data: data
            };

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            });

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }


            let result = await response.json();
            console.log('RESULT send DATA:::', result); //test

            if (result.ERROR) throw new Error(result.message)

            return result

        } catch (err) {
            console.error(`Ошибка сохранения / обнолвения файла: `, err);

            return undefined
        }

    }

    //todo отправляем данные на сервер, обновление registration
    /**
     * Обновление регистрационных данных
     * @param {Object} data данные
     * @returns result
     */
    async updateDataOnServerRegistration(data) {
        try {
            let urlReg = `${this.routeURL}/server_data/login_update`; // для обновление    

            if (!data) throw new Error('НЕТ ОБЪЕКТА ДЛЯ ОБНОВЛЕНИЯ РЕГИСТРАЦИОННЫХ ДАННЫХ')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                login: data.login,
                data: data
            };

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            });

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();
            // console.log('RESULT: ', result); //test

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка обнолвения регистрационных данных: `, err);
        }
    }


    //todo Сервер для входа в систему
    /**
     * Функция для входа в систему UEMS
     * @param {string} login 
     * @param {string} password 
     * @returns result
     */
    async getDataLogin(login, password, sessionCooki) {
        try {
            let urlReg = `${this.routeURL}/server_data/login`;
            console.log(`URL REG::::: `, urlReg); // test

            if (!login || !password || typeof (login) !== 'string' || typeof (password) !== 'string') throw new Error('НЕ ВЕРНЫЕ ДАННЫЕ ВХОДА')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                login: login,
                password: password
            };

            if (sessionCooki) {
                Object.assign(userBody, { seesionCookie: sessionCooki })
            }

            console.log(`BODY:::: `, userBody.login); // test
            // localStorage.setItem(`logIn`, login)

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            });

            let result = await response.json(); //* полученный ID пользователя
            // console.log(result); //* test   ID user

            if (result.ERROR) throw new Error(result.message)

            if (result) {
                console.log('ACCESS'); //test          
                return result;

            } else {
                console.log('NOT ACCESS'); //test
                return result;
            }
        } catch (err) {
            console.error(`Ошибка входа в UEMS: `, err);
        }

    }

    //todo Сервер для регистрации нового пользователя
    /**
     * Регистрация нового пользователя
     * @param {string} eMail 
     * @param {string} password 
     * @returns result
     */
    async sendDataRegistration(eMail, password) {
        try {
            let urlReg = `${this.routeURL}/server_data/registration`; // для регистрации

            if (!eMail || !password || typeof (eMail) !== 'string' || typeof (password) !== 'string') throw new Error('НЕ ВЕРНЫЕ ДАННЫЕ РЕГИСТРАЦИИ')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                login: eMail,
                password: password
            };

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    // "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            });
            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка при регистрации: `, err);

            return undefined
        }
    }

    /**
     * регистрация сотрудника с допуском Админ
     * @param {*} data 
     * @returns 
     */
    async registrationEmployees(data) {
        try {
            let urlReg = `${this.routeURL}/server_data/registration_employees`; // для регистрации

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                inn: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                data: data,
            };

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    // "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            });
            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка при регистрации: `, err);

            return undefined
        }
    }

    //TODO ЗАГРУЖАЕМ(создаем) файл на сервер 
    /**
     * Загружаем файл на сервер
     * @param {Object} data данные файла (файл)
     * @returns result
     */
    async createNewFileOnServer(data) {
        try {
            console.log(`DATA GET FILE::: `, data.get('file')); //test

            let urlReg = `${this.routeURL}/file/file_create`;

            if (!data) throw new Error('НЕТ ДАННЫХ ФАЙЛА')

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    // "Content-Type": "form/multipart, application/json; charset=utf-8"
                    "Accept": "multipart/form-data"
                },
                body: data
            });

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка сохранения файла на сервере: `, err);
        }
    }

    //TODO ЗАГРУЖАЕМ(создаем) файл на сервер 
    /**
     * Сохраняем файл торговых операций на сервере
     * @param {Object} data 
     * @returns result
     */
    async createTradeFileOnServer(data) {
        try {
            console.log(`DATA GET FILE::::::::::::::: `, data.get('file')); //test

            let urlReg = `${this.routeURL}/file/file_trade_save`;

            if (!data) throw new Error('НЕТ ДАННЫХ ФАЙЛА')

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    // "Content-Type": "form/multipart, application/json; charset=utf-8"
                    "Accept": "multipart/form-data"
                },
                body: data
            });
            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка сохранения файла торговых операций на сервере: `, err);
        }

    }

    //TODO ЗАГРУЖАЕМ(создаем) icon на сервер 
    /**
     * Сохраняем файл icon на сервере
     * @param {Object} data 
     * @returns result
     */
    async createIconOnServer(data) {
        try {
            console.log(`DATA GET ICON::::::::::::::: `, data.get('file')); //test

            let urlReg = `${this.routeURL}/file/file_icon`;

            console.log(`URL route::: `, urlReg); // test

            if (!data) throw new Error('НЕТ ДАННЫХ ИКОНКИ')

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    // "Content-Type": "form/multipart, application/json; charset=utf-8"
                    "Accept": "multipart/form-data"
                },
                body: data
            });

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка загрузки иконки на сервер: `, err);
        }
    }

    /**
     * Сохраняем файлы документации на сервер
     * @param {Object} data 
     * @returns 
     */
    async createDocumentationFileOnServer(data) {
        try {
            console.log(`DATA GET FILE DOCUMENTATION::::::::::::::: `, data.get('file')); //test

            let urlReg = `${this.routeURL}/file/file_documentation`;

            if (!data) throw new Error('НЕТ ДАННЫХ ФАЙЛА')

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    // "Content-Type": "form/multipart, application/json; charset=utf-8"
                    "Accept": "multipart/form-data"
                },
                body: data
            });
            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка сохранения файла торговых операций на сервере: `, err);
        }

    }

    //TODO поиск всех предприятий в базе (возвращает: {id-INN, name, city})
    /**
     * поиск всех предприятий в базе соответствующих виду деятельности
     * @param {Array} data - [Array] видов деятельности
     * @returns возвращает: {id-INN, name, city}
     */
    async searchAllCompany(data) {
        try {
            let urlReg = `${this.routeURL}/search/search`;

            if (!data || typeof (data) !== 'object') throw new Error('ОШИБКА ДАННЫХ (отсутсвие или не массив)')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                data: data
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка в получении списка компаний: `, err);
        }

    }

    /**
     * Получаем название всех компаний в базе
     * @returns {Array} массив объектов
     */
    async searchAllNameCompany() {
        try {
            let urlReg = `${this.routeURL}/search/all_name_company`;

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка в получении списка компаний: `, err);
        }

    }

    //todo загружаем с сервера шаблоны тех требований
    /**
     * Загрузка шаблона тех требований
     * @returns Object
     */
    async searchAllTemplate() {
        try {
            let urlReg = `${this.routeURL}/search/load_all_template`;
            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                userid: localStorage.getItem('idUser')
            }

            console.log(`BODY:::::::: `, userBody); // test

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка при загруке шаблона: `, err);
        }

    }

    //todo загружаем с сервера все торги
    /**
     * Загрузка всех торгов
     * @returns Object
     */
    async searchAllTrade() {
        try {
            let urlReg = `${this.routeURL}/search/load_all_trade`;
            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                userid: localStorage.getItem('idUser')
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка загрузки торгов: `, err);
        }
    }

    //todo Поиск на сервере в указанной библиотеке, в указанном документе, указанную позицию
    // коллекция, _id = документ, позиция в документе, данные позиции
    /**
     * Поиск на сервере в указанной библиотеке, в указанном документе, указанную позицию 
     *  (коллекция, _id = документ, позиция в документе, данные позиции)
     * @param {string} collection наименование коллекции
     * @param {string} document Наименование документа
     * @param {string} position позиция в документе, для поиска
     * @param {string} data поиск указанных данных
     * @returns Object
     */
    async searchPosition(collection, document, position, data) {
        try {
            let urlReg = `${this.routeURL}/search/search_position`;

            if (!collection || !document || !position || !data) throw new Error('НЕ ВСЕ ДАННЫЕ')

            if (typeof (collection) !== 'string' || typeof (document) !== 'string' || typeof (position) !== 'string' || typeof (data) !== 'string') throw new Error('ОШИБКА ФОРМАТА ДАННЫХ (должны быть -> string)')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                searchCollection: collection,
                searchDocument: document,
                searchPosition: position,
                searchData: data
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result; // возвращает объект

        } catch (err) {
            console.error(`Ошибка получения данных: `, err);
        }

    }

    //todo загружаем с сервера все торговые позиции данной компании
    /**
     * загружаем с сервера все торговые позиции данной компании
     * @returns 
     */
    async searchAllPositions() {
        try {
            let urlReg = `${this.routeURL}/search/search_all_positions`;
            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                userid: localStorage.getItem('idUser')
            }
            // console.log(`BODY:::::::: `, userBody); // test

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка поиска всех торговых позиций: `, err);
        }

    }

    //todo отправляем новое сообщение в чат
    //TODO: Вероятней всего данная функция не будет использоваться.
    async writeNewMessage(data, name, id) {
        let urlReg = `${this.routeURL}/chat/chat_new_message`; // для обновление    

        // console.log('SERVER >>>> ', data, name, id);

        let userBody = {
            key: 'weifeiph7Pie',
            session: sessionStorage.getItem('sessionId'),
            login: sessionStorage.getItem('login'),
            sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
            userid: id,
            name: name,
            data: data
        };

        let response = await fetch(urlReg, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(userBody)
        });

        if (response.status === 401) {
            console.log(`STATUS CODE: ${response.status}`)
            this.errorR = true;
        }


        let result = await response.json();
        // console.log('RESULT', result); //test
        // console.log(result); //test
        return result
    }

    //TODO поиск всех активных видов деятелности
    /**
     * поиск всех активных видов деятелности
     * @returns 
     */
    async searchProduction() {
        try {
            let urlReg = `${this.routeURL}/search/search_production`;
            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка поиска видов деятельности: `, err);
        }

    }

    //TODO получение массива приглашений   
    /**
     * получение массива приглашений   
     * @param {string} idLot ID лота
     * @returns 
     */
    async getParticipantInvite(idLot) {
        try {
            let urlReg = `${this.routeURL}/info/get_participant_invite`;

            if (!idLot) throw new Error('НЕ УКАЗАН ID')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                inn: localStorage.getItem('idUser'),
                idLot: idLot
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка при получении списка приглашений: `, err);
        }

    }

    //TODO Получаем массив ЛОТов данного участника
    /**
     * Получаем массив ЛОТов данного участника
     * @returns {Array} - Array{trade}
     */
    async getParticipantTrade(active) {
        try {
            let urlReg = `${this.routeURL}/participant/trade`;

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                active: active,
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка при получении списка ЛОТов данного участника: `, err);
        }
    }

    //TODO Универсальный поисковик, возвращает массив объектов
    /**
     * Универсальный поисковик, возвращает массив объектов
     * (<коллекция>, <строка для поиска>, <что ищем>)
     * @param {string} collection 
     * @param {string} search 
     * @param {string} data 
     * @returns Object
     */
    async searchUniversal(collection, search, data) {
        try {
            let urlReg = `${this.routeURL}/search/search_universal`;

            if (!collection || !search || !data) throw new Error('УКАЗАНЫ НЕ ВСЕ ДАННЫЕ')

            if (typeof (collection) !== 'string' || typeof (search) !== 'string' || typeof (data) !== 'string') throw new Error('НЕ ВЕРНЫЙ ТИП ДАННЫХ')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                collection: collection, // коллекция
                search: search, // где ищем (строка поиска -> 'data.data.participant')
                data: data // что ищем
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка при поиске: `, err);
        }

    }

    //TODO поиск предприятий в базе по фильтру
    /**
     * поиск предприятий в базе по фильтру
     * @param {{Object} data
     *  {string} region: город, 
     *  {string} nameCompany: назване компании, 
     *  {string} inn: ИНН,
     *  {string} counter: страна,
     *  {string} occupation: вид деятельности,
     *  {number} pageNumber: номер страницы
     * }
     * @returns [Object {Main}]
     */
    async filterSearchCompany(data) {
        try {
            let urlReg = `${this.routeURL}/filter/company`;

            if (!data || typeof (data) !== 'object') throw new Error('НЕТ ДАННЫХ ИЛИ ТИП ДАННЫХ НЕ ВЕРНЫЙ')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                data: data
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка поиска предприятий: `, err);
        }

    }


    //TODO поиск возможностей компании
    /**
     * поиск возможностей компании
     * @param {object} data
     *  {key: string}     
     * {nameForm: string}
     * @returns {array} [result]
     */
    async filterProductionCompany(data, nameForm) {
        try {
            let urlReg = `${this.routeURL}/filter/production`;

            if (!data || !nameForm) throw new Error('НЕ ВСЕ ДАННЫЕ')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                data: data,
                name: nameForm
            }

            // console.log(`nameForm >>>>> `, nameForm); // test
            // console.log(`filter DATA >>>>>> `, data); // test

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            // console.log(`RESULT SERVER filter:::::: `, result); // test
            return result;

        } catch (err) {
            console.error(`Ошибка поиска возможностей компании: `, err);
        }

    }

    /**
     * Определяем коэффициент заполнения форм
     * @param {array} arrForm массив форм
     * @returns {array} массив коэффициентов заполнения
     */
    async fillingVolumeForms(arrForm) {
        try {
            let urlReg = `${this.routeURL}/search/filling`;

            if (!arrForm || typeof (arrForm) !== 'object') throw new Error('НЕ ВЕРНЫЕ ДАННЫЕ ИЛИ ОНИ ОТСУТСВУЮТ')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                inn: localStorage.getItem('idUser'),
                data: arrForm
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка при определении заполнения форм: `, err);
        }

    }

    /**
     * Получаем ЛОТЫ по массиву ID(лотов)
     * @param {array} arrID массив ID лотов
     * @returns {array} массив объектов Лотов
     */
    async getAllLot(arrID) {
        try {
            let urlReg = `${this.routeURL}/search/search_all_lot`;

            if (!arrID || typeof (arrID) !== 'object') throw new Error('НЕ ВЕРНЫЕ ДАННЫЕ ИЛИ ОНИ ОТСУТСВУЮТ')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                inn: localStorage.getItem('idUser'),
                id: arrID
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка получения ЛОТов: `, err);
        }

    }


    /**
     * Отправка приглашение ДЛЯ E-mail
     * @param {object} objectInvite объект приглашения
     * objectInvite: {
     * id: id приглашения,
     * text: краткий текст приглашения,
     * }
     * @returns {result} объект приглашения
     */
    async sendInvite(objectInvite) {
        try {
            let urlReg = `${this.routeURL}/email/invite`;

            if (!objectInvite || typeof (objectInvite) !== 'object') throw new Error('НЕТ ОБЪЕКТА ПРИГЛАШЕНИЙ')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                inn: localStorage.getItem('idUser'),
                data: objectInvite
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка отрпавки приглашения: `, err);
        }

    }

    /**
     * Отправка Email
     * @param {object} objectInvite объект приглашения
     * @returns {result} результат отправки письма
     */
    async sendEmail(objectInvite) {
        try {
            let urlReg = `${this.routeURL}/email/send_email`;

            if (!objectInvite || typeof (objectInvite) !== 'object') throw new Error('НЕТ ОБЪЕКТА ПРИГЛАШЕНИЙ')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                inn: localStorage.getItem('idUser'),
                data: objectInvite
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка отправки Email: `, err);
        }

    }

    /**
     * Отправка по почте уведомления о смене доступа модератором
     * @param {Object} objectInvite - {text, emailInvite, subjectInvite}
     * @returns result
     */
    async sendEmailKey(objectInvite) {
        try {
            let urlReg = `${this.routeURL}/email/send_email_key`;

            if (!objectInvite || typeof (objectInvite) !== 'object') throw new Error('НЕТ ОБЪЕКТА ПРИГЛАШЕНИЙ')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                inn: localStorage.getItem('idUser'),
                data: objectInvite
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка отправки Email: `, err);
        }

    }

    /**
     * Отправка письма обратной связи
     * @param {Object} objectInvite 
     * @returns 
     */
    async sendFeedBack(objectInvite) {
        try {
            let urlReg = `${this.routeURL}/email/send_feed_back`;

            if (!objectInvite || typeof (objectInvite) !== 'object') throw new Error('НЕТ ОБЪЕКТА ПРИГЛАШЕНИЙ')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                inn: localStorage.getItem('idUser'),
                data: objectInvite
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка отправки Email: `, err);
        }

    }


    /**
     * Смена пароля
     * @param {string} oldPassword старый пароль, строка
     * @param {string} newPassword новый пароль строка
     * @returns {result} объект результат смена пароля
     */
    async updatePassword(oldPassword, newPassword) {
        try {
            let urlReg = `${this.routeURL}/server_data/update_password`;

            if (!oldPassword || !newPassword) throw new Error('НЕ ВСЕ ДАННЫЕ УКАЗАНЫ')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                oldPassword: oldPassword,
                newPassword: newPassword
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка смены пароля: `, err);
        }

    }

    /**
     * получаем данные предварительной регистрации по E-mail
     * @param {string} idDocum отправляем ID приглашения
     * @returns  получаем данные заполненные при формировании приглашения по E-mail
     */
    async getRegistartionInvite(idDocum) {
        try {
            let urlReg = `${this.routeURL}/email/get_invite_registration`;

            if (!idDocum) throw new Error(' НЕ УКАЗАН ID')

            let userBody = {
                key: 'weifeiph7Pie',
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                idDocum: idDocum,
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка получения данных предварительно регистрации: `, err);
        }

    }

    /**
     * Регистрационные данные отправляем на почту
     * @param {string} email E-mail, логин для входа 
     * @param {string} password  
     * @returns result
     */
    async sendRegistrationData(email, password) {
        try {
            let urlReg = `${this.routeURL}/email/send_registration_data`;

            if (!email || !password) throw new Error('НЕ ВСЕ ДАННЫЕ')

            let userBody = {
                key: 'weifeiph7Pie',
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                email: email,
                password: password
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка отправки на почту: `, err);
        }

    }

    /**
     * Удаление документов
     * @param {string} idDocum id документа
     * @returns void
     */
    async deleteDocument(idDocum) {
        try {
            let urlReg = `${this.routeURL}/server_data/delete`;

            if (!idDocum) throw new Error('НЕ УКАЗАН ID')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                idDocument: idDocum
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.log(`Ошибка при удалении: `, err);
        }
    }

    /**
     * Запуск процедуры: старт лота (извещение участников, отправка Email и т.д.)
     * @param {Object} lot  Объект ЛОТа
     * @returns {Object} void -> info 
     */
    async runTrade(lot) {
        try {
            let urlReg = `${this.routeURL}/info/run_trade`;

            if (!lot || typeof (lot) !== 'object') throw new Error('НЕ ВЕРНЫЕ ДАННЫЕ')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                data: lot // todo: в лот добавить массив ID приглашений - participantInvite
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка при старте лота: `, err);
        }
    }

    /**
     * Останавливаем торги по указанному ID(убираем ID из global->current_trade->active)
     * @param {string} idLot  - ID лота для остановки торгов
     * @returns Object result
     */
    async stopTrade(idLot) {
        try {
            let urlReg = `${this.routeURL}/info/stop_trade`;

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                id: idLot // todo: в лот добавить массив ID приглашений - participantInvite
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка при остановке торгов: `, err);
        }
    }

    /**
     * Получаем массив ЛОТов данной компании (по ИНН)
     * @returns {Array} Массив объектов (ЛОТов)
     */
    async lookInviteTrade() {
        try {
            let urlReg = `${this.routeURL}/info/look_invite_trade`;
            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка при получении массива ЛОТов: `, err);
        }

    }

    /**
     * Получаем массив документов по массиву _id, в указанной коллекции
     * @param {string} collection название коллекции
     * @param {Array} arrDocuments массив _id документов
     * @returns {Array} массив объектов
     */
    async getArrayDocuments(collection, arrDocuments) {
        try {
            let urlReg = `${this.routeURL}/server_data/get_array_documents`;

            if (!collection || !arrDocuments || typeof (collection) !== 'string' || typeof (arrDocuments) !== 'object') throw new Error('ОШИБКА ДАННЫХ')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                collection: collection,
                arrDocuments: arrDocuments
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка получения документов: `, err);
        }

    }

    /**
     * Возвращает два массива. Массив лотов, где выступает организатором и второй, где как участник
     * @param {Array} data массив объектов ID чатов
     * @returns Объект массивов
     */
    async getCollectChat(data) {
        try {
            let urlReg = `${this.routeURL}/chat/data`;

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                data: data,
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();
            // console.log(`RESULT SERVER::: `, result); // test
            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка получения документов: `, err);
        }
    }

    /**
     * Запуск сравнения результата подтверждения участником лота
     * Сборка сводной таблицы
     * @param {string} id  ID объекта, результата ответа участника по лоту
     * @returns в объект 'trade_confirmation' будет добавлен 'status' сравнения
     */
    async compareConfirmationResult(id) {
        try {
            let urlReg = `${this.routeURL}/participant/compare`;

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                id: id,
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();
            console.log(`RESULT SERVER::: `, result); // test
            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка получения документов: `, err);
        }
    }

    /**
     * Обновляет данные по _id (была написана для обновления сводной таблицы)
     * @param {String} id String ID документа
     * @param {String} collection Коллекция документов в DB
     * @param {Object} obj объект с новыми данными
     * @returns {Object} result выполнения обновлений
     */
    async updateTradeTable(id, collection, obj) {
        try {
            console.log(`COLLECTION in SERVER: `, collection); // test
            console.log(`DATA obj:::: `, obj); // test
            let urlReg = `${this.routeURL}/participant/update_table`;

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                id: id,
                collection: collection,
                data: obj
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401 || response.status === 400) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();
            console.log(`RESULT SERVER::: `, result); // test
            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка обновления сводной таблицы: `, err);
        }
    }

    /**
     * Процесс перезаписи данных для Перетожки
     * @param {String} idOld ID базового лота
     * @param {String} idNew ID лота Перетожки
     * @returns Result
     */

    async startPeretogka(idOld, idNew) {
        try {
            let urlReg = `${this.routeURL}/participant/start_peretogka`;

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                idOld: idOld,
                idNew: idNew
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401 || response.status === 400) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();
            console.log(`RESULT SERVER::: `, result); // test
            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка запуска Перетожки (перезапись данных: tarde_confirmation, trade_table, trade_table_new): `, err);
        }
    }

    /**
     * Закрывает сессию при закрытии вкладки, барузера
     * @returns 
     */
    async doCloseSession() {
        try {
            let urlReg = `${this.routeURL}/server_data/close_session/?logIn=${sessionStorage.getItem('login')}`;

            let response = await fetch(urlReg)

            // localStorage.removeItem('nano')
            // localStorage.removeItem('idUser')
            // localStorage.removeItem('loginReg')
            // sessionStorage.removeItem('sessionId')
            sessionStorage.removeItem('login')

            if (response.status === 401 || response.status === 400) {
                console.log(`STATUS CODE: ${response.status}`)
            }

            let result = await response.json();
            console.log(`RESULT SERVER::: `, result); // test



            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка при выходе: `, err);
        }
    }

    /**
     * Обновление выбранного поля 
     * @param {*} collection Выбранная коллекция
     * @param {*} id Объект для обновления
     * @param {*} nameField Название поля которое нужно обноыить
     * @param {*} dataField Данные дял выбранного поля , если поля нет, то создается
     * @returns 
     */
    async updateField(collection, id, nameField, dataField) {
        try {
            let urlReg = `${this.routeURL}/server_data/update_fild`;

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                dataField: dataField,
                nameField: nameField,
                id: id,
                collection: collection
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401 || response.status === 400) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();
            console.log(`RESULT SERVER::: `, result); // test
            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка запуска Перетожки (перезапись данных: tarde_confirmation, trade_table, trade_table_new): `, err);
        }
    }

    /**
     * Проверяет по ID лот на активность в торгах
     * @param {*} id ID лота
     * @returns true - есть в активных торгах торгах / false - нет в активных торгах
     */
    async lookRunTrade(id) {
        try {
            let urlReg = `${this.routeURL}/info/look_trade`;

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                id: id,
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401 || response.status === 400) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();
            console.log(`RESULT SERVER::: `, result); // test

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка проверки: `, err);
        }
    }

    /**
     * Получение документов из коллекции
     * @param {string} collection 
     * @returns 
     */
    async getDataAllCollection(collection) {
        try {
            let urlReg = `${this.routeURL}/server_data/get_collection`; // получаем

            if (!collection) throw new Error('НЕ ПОЛНЫЕ ДАННЫЕ ДЛЯ ЗАГРУЗКИ ФАЙЛА')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                collection: collection // название коллекции из которой получаем все документы
            };

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    // "Accept": "application/json",
                    "Content-Type": "application/json"
                    // 'Content-Type': 'application/json;charset=utf-8;Access-Control-Allow-Origin',
                },
                body: JSON.stringify(userBody)
            });

            // console.log(`RESPONSE:::: `, response); // test
            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();
            // console.log('TYPE RESULT: ', typeof(result)); //test

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка получения данных: `, err);

            return null
        }


    }


    /**
     * Получение пользователя зарегестрировавшего компанию по ИНН компании
     * @param {String} id  ИНН компании
     * @returns 
     */
    async getUserOnINN(id) {
        try {
            let urlReg = `${this.routeURL}/search/get-user`; // получаем

            if (!id) throw new Error('НЕ ПОЛНЫЕ ДАННЫЕ ДЛЯ ЗАГРУЗКИ')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                userid: String(id), // ИНН компании

            };
            // console.log('SERVER get request : ' + id + ' ' + name); //test
            console.log(userBody);

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    // "Accept": "application/json",
                    "Content-Type": "application/json"
                    // 'Content-Type': 'application/json;charset=utf-8;Access-Control-Allow-Origin',
                },
                body: JSON.stringify(userBody)
            });

            // console.log(`RESPONSE:::: `, response); // test
            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                console.log('test');
                this.errorR = true;
            }

            let result = await response.json();
            // console.log('TYPE RESULT: ', typeof(result)); //test
            // console.log('RESULT: /', result); //test
            console.log('test');

            if (result.ERROR) throw new Error(result.message)

            if (result) {
                console.log('test');
                if (typeof (result) === 'object') {
                    return result;
                }

                if (typeof (result) === 'string') {
                    console.log('test');
                    return JSON.parse(result);
                }
            } else {
                // console.log('else: ', result); //test
                console.log('test');
                return null
            }

        } catch (err) {
            console.error(`Ошибка получения данных (getDataFromServer): `, err);
            console.log('test');
            return null
        }
    }

    /**
     * Получение данных логг файла
     * @returns 
     */
    async getLogger() {
        try {
            let urlReg = `${this.routeURL}/info/logger`; // получаем


            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
            };

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    // "Accept": "application/json",
                    "Content-Type": "application/json"
                    // 'Content-Type': 'application/json;charset=utf-8;Access-Control-Allow-Origin',
                },
                body: JSON.stringify(userBody)
            });

            // console.log(`RESPONSE:::: `, response); // test
            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.text();
            // console.log('TYPE RESULT: ', typeof(result)); //test

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.error(`Ошибка получения данных: `, err);

            return null
        }
    }

    /**
     * Удаление файла из DB и с диска
     * @param {Object} data Объект содержит путь к файлу, ID документа с файлом, индекс в массиве
     * @returns 
     */
    async deleteFile(data) {
        try {
            let urlReg = `${this.routeURL}/server_data/delete_file`;

            if (!data) throw new Error('НЕ УКАЗАН ID')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                data: data
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.log(`Ошибка при удалении: `, err);
        }
    }


    /**
     * Поиск оборудования по параметрам
     * @param {Object} data 
     * @returns 
     */
    async searchEquipmentCardData(data) {
        try {
            let urlReg = `${this.routeURL}/search/search_equipment`;

            if (!data) throw new Error('НЕ УКАЗАН ID')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                data: data
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.log(`Ошибка : `, err);
        }
    }

    /**
     * Обновление ключей у сотрудников компании, запускается при смене ключей доступа компании
     * в модуле Administartion
     * @param {String} idCompany ИНН компании
     * @param {String} keyAccess новый ключ доступа
     * @returns 
     */
    async changeKeyAccess(idCompany, keyAccess) {
        try {
            let urlReg = `${this.routeURL}/info/change_keys`;

            if (!idCompany || !keyAccess) throw new Error('Не все данные!')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                idCompany: idCompany,
                keyAccess: keyAccess,
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.log(`Ошибка при смене ключей: `, err);
        }
    }


    /**
     * Формируем массив объектов по торгам для заказ-наряда
     * @returns 
     */
    async getTradeData() {
        try {
            let urlReg = `${this.routeURL}/search/trade_data`;

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.log(`Ошибка : `, err);
        }
    }


    /**
     * Сохранение данных победителя
     * @param {String} idLot ID ЛОТа
     * @param {String} inn ИНН победителя
     * @returns result
     */
    async saveWinnerCompany(idLot, inn) {
        try {
            let urlReg = `${this.routeURL}/server_data/winner`;

            if (!idLot || !inn) throw new Error('Не все данные!')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                idLot: idLot,
                inn: inn,
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.log(`Ошибка при сохранении: `, err);
        }
    }

    /**
     * Оповещение сотрудников компаний о событиях. Конец торгов.
     * @param {String} idLot ID Лота
     * @param {Array} participant массив ИНН компаний участниц торгов     
     * @returns 
     */
    async inviteAlertEmployeesEndTrade(idLot, participant) {
        try {
            let urlReg = `${this.routeURL}/alert/end_trade`;

            if (!idLot || !participant) throw new Error('Не все данные!')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                idLot: idLot,
                participant: participant,

            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.log(`Ошибка при оповещении: `, err);
        }
    }


    /**
  * Оповещение сотрудников компаний о событиях. Результат торгов.
  * @param {String} idLot ID Лота
  * @param {Array} participant массив ИНН компаний участниц торгов
  * @param {String} innWinner ИНН компании победителя
  * @returns 
  */
    async inviteAlertEmployeesSendResultTrade(idLot, participant, innWinner) {
        try {
            let urlReg = `${this.routeURL}/alert/result_trade`;

            if (!idLot || !participant) throw new Error('Не все данные!')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                idLot: idLot,
                participant: participant,
                innWinner: innWinner,
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.log(`Ошибка при оповещении: `, err);
        }
    }

    /**
     * Работа с часовым поясом
     * @param {*} timezone 
     * @returns 
     */
    async checkTimezone(timezone) {
        try {
            let urlReg = `${this.routeURL}/alert/timezone`;

            if (!timezone) throw new Error('Не все данные!')

            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                timezone: timezone,
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.log(`Ошибка при процедуре работы с временными зонами: `, err);
        }
    }

    /**
     * Формирование номера заказ-наряда
     * @param {Object} obj объект, необходимые данные для формирования номера
     * @returns {String} номер заказ-наряда
     */
    async doOrderNumber(obj) {
        try {
            let urlReg = `${this.routeURL}/info/order_number`;

            if (!obj) throw new Error('Не все данные!')
            console.log(`SATRT do order number`); // test
            let userBody = {
                key: 'weifeiph7Pie',
                session: sessionStorage.getItem('sessionId'),
                login: sessionStorage.getItem('login'),
                userid: localStorage.getItem('idUser'),
                sessionCookie: { login: this.getCookie('login'), session: this.getCookie('session') },
                obj: obj,
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
                this.errorR = true;
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.log(`Ошибка при процедуре формирования уникального номера заказ-наряда: `, err);
        }
    }

    /**
     * Отправка объекта с расчетом стоимости теплообменника в приложение STHE
     * @param {Object} obj 
     * @returns 
     */
    async apiSTHEpost_send(data) {
        try {
            let urlReg = `https://21.api3.lotus-uems.ru/sthe/api/v1.0/production_calculation`;

            if (!data) throw new Error('Не все данные!')
            console.log(`SATRT do order number`); // test

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.log(`Ошибка при отправке POST запроса к STHE: `, err);
        }
    }


    /**
     * Запуск процедуры сброса пароля
     * @param {String} log EMail для сброса пароля
     * @returns 
     */
    async erasePassword(log) {
        try {
            let urlReg = `${this.routeURL}/email/erase_password`;

            if (!log) throw new Error('Не все данные!')

            let userBody = {
                key: 'weifeiph7Pie',
                // session: sessionStorage.getItem('sessionId'),
                // login: sessionStorage.getItem('login'),
                // userid: localStorage.getItem('idUser'),
                log: log,
            }

            let response = await fetch(urlReg, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(userBody)
            })

            if (response.status === 401) {
                console.log(`STATUS CODE: ${response.status}`)
            }

            let result = await response.json();

            if (result.ERROR) throw new Error(result.message)

            return result;

        } catch (err) {
            console.log(`Ошибка при запуске процедуры сброса пароля: `, err);
        }
    }
}

export default new Server();