import React, { useEffect, useState } from 'react'
import getRegistry from '../getRegistry'
import TableRegistry from './TableRegistry'
import { Button, Row } from 'react-bootstrap'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import ModalAddNewEquipment from './ModalAddNewEquipment'
import addCardToRegistry from './addCardToRegistry'
import getCardEquipment from '../getCardEquipment'
import getOneCardDataEquipment from '../getOneCardDataEquipment'
import ModalEditCardEquipment from './ModalEditCardEquipment'
import ModalSelectCardEdit from './ModalSelectCardEdit'
import updateCard from './updateCard'
import ModalLoadFiles from './ModalLoadFiles'
import ModalEquipmentLifeCycleInUse from '../equipmentWorkControl/ModalEquipmentLifeCycleInUse'
import PipeStateModal from '../equipmentWorkControl/PipeStateModal'
import RostechRegistrationEdit from '../equipmentWorkControl/RostechRegistrationEdit'
import updateRostech from './updateRostech'
import server from '../../server/server'
import LeftAndRightArrowsButtons from '../../assets/LeftAndRightArrowsButtons'
import LoaderPage from '../../assets/loaderPage'
import { usePagination } from '../../assets/usePagination'

/**
 * Модуль жизненного цикла аппарата
 * @returns
 */
const LifeCycle = () => {
  // console.log(`Component: Life Cycle - run`) // test

  const [cardRegistry, setCardRegistry] = useState([])
  const [showToggle, setShowToggle] = useState(false)
  const [showEditEquipment, setShowEditEquipment] = useState(false)
  const [showCardEdit, setShowCardEdit] = useState(false)
  const [showLoadFiles, setShowLoadFiles] = useState(false)
  const [showLifeCycleInUse, setShowLifeCycleInUse] = useState(false)
  const [showHeatExchangeTypeTable, setShowHeatExchangeTypeTable] = useState(false)
  const [showRostechReg, setShowRostechReg] = useState(false)
  const [pipeHeatType, setPipeHeatType] = useState('')
  const [card, setCard] = useState({})
  const [cardEquipment, setCardEquipment] = useState([])
  const [oneCardEquipment, setOneCardEquipment] = useState({})
  const [equipmentId, setEquipmentId] = useState('')
  const [savedRostechData, setSavedRostechData] = useState({})

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [showTable, setShowTable] = useState(true)
  const [triggerRender, setTriggerRender] = useState(false)

  const {
    currentPage,
    pageSize,
    getPagedData,
    goToNextPage,
    goToPreviousPage
  } = usePagination(12, cardEquipment);

  const offset = currentPage * pageSize

  // * 1: Загрузка реестра аппаратов
  useEffect(() => {
    ;(async () => {
      try {
        const dataRegistry = await getRegistry(localStorage.getItem('idUser'))
        setCardRegistry(dataRegistry)
        // console.log(`LOAD registry::: `, dataRegistry) // test

        const cardEquipmentArray = await getCardEquipment(dataRegistry)
        setCardEquipment(cardEquipmentArray)
      } catch (err) {
        console.log(`Ошибка при загрузке данных реестра и карточек аппаратов: `, err)
        setCardEquipment([])
      }
    })()
  }, [card])

  // модалка ввода данных в карту
  const handleClickButton = () => {
    setShowToggle(!showToggle)
  }

  // модалка выбора редактирования данных аппарата
  const handleClickCardEdit = () => {
    setShowCardEdit(!showCardEdit)
  }

  // модалка редактирования карточки аппарата
  const handleClickEditEquipment = () => {
    setShowEditEquipment(!showEditEquipment)
  }

  const handleClickEditEquipment2 = () => {
    if (hasChanges) {
      setShowConfirmModal(true)
    } else {
      setShowEditEquipment(false)
    }
  }

  // модалка загрузки файлов
  const handleClickLoadFiles = () => {
    setShowLoadFiles(!showLoadFiles)
  }

  const handleClickShowRostechReg = () => {
    setShowRostechReg(!showRostechReg)
  }

  // модалка выбора работы аппарата в период эксплуатации
  const handleClickChooseLifeCycleInUse = () => {
    setShowLifeCycleInUse(!showLifeCycleInUse)
  }

  const handleClickHeatExchange = (type) => {
    setPipeHeatType(type)
    setShowHeatExchangeTypeTable(!showHeatExchangeTypeTable)
  }

  /**
   * Выбранный ID аппарата
   * @param {String} id
   */
  const selectedIdEquipment = (id) => {
    // console.log(`SELECTED ID::: `, id) // test
    setEquipmentId(id)
    ;(async () => {
      try {
        const result = await getOneCardDataEquipment(`card_${id}`)
        setOneCardEquipment(result)
        setShowCardEdit(!showCardEdit)
      } catch (err) {
        console.log(`Ошибка при загрузке: `, err)
      }
    })()
  }

  /**
   * Получаем для сохранения объект - Карточка аппарата
   * @param {Object} data
   */
  const doSaveCard = (data) => {
    ;(async () => {
      try {
        await addCardToRegistry(data, cardRegistry)
        setCard(data)
        setTriggerRender((prev) => !prev)
        // console.log(`CARD::: `, data) // test
        // setShowToggle(false)
      } catch (err) {
        console.log(`Ошибка при сохранении карточки аппарата: `, err)
      }
    })()
  }

  /**
   * Обновление в БД карточки аппарата
   * @param {*} data
   */
  const doUpdateCard = (data) => {
    ;(async () => {
      try {
        // console.log(`DATA::: `, data) // test
        await updateCard(data, data.id)
        setCard(data)
        // setShowEditEquipment(false)
      } catch (err) {
        console.log(`Ошибка при обновлении данных карты аппарата: `, err)
      }
    })()
  }

  /**
   * Сохранение данных карточки Ростехнадзора
   * @param {*} newData
   */
  const saveRostech = (newData) => {
    ;(async () => {
      try {
        // console.log(`newData in parent::: `, newData); // test
        await updateRostech(newData, newData.id)
      } catch (err) {
        console.log(`Ошибка сохранения карты данных Ростехнадзора: `, err)
      }
    })()
  }

  const getRostechDataFromServer = async (id) => {
    try {
      const data = await server.getDataFromServer(id, localStorage.getItem('idUser'))
      // здесь обновите состояние своего компонента с полученными данными, если требуется
      // todo: Требуется обработка ошибок
      // console.log(data);
      setSavedRostechData(data)
    } catch (err) {
      console.log(`Ошибка получения данных с сервера: `, err)
    }
  }

  return (
    <>
      <div className='wrapper_lot_participant'>
        {/* <div>Реестр теплообменных аппаратов:</div> */}

        {showCardEdit && (
          <ModalSelectCardEdit
            alertHeading='Выбор данных аппарата для редактирования'
            toggleModal={handleClickCardEdit}
            doEditCardEquipment={handleClickEditEquipment}
            doLoadFiles={handleClickLoadFiles}
            doShowEquipmentInUse={handleClickChooseLifeCycleInUse}
            doShowRosTechReg={handleClickShowRostechReg}
          />
        )}

        {showToggle && (
          <ModalAddNewEquipment
            alertHeading='Добавление карты нового аппарата'
            toggleModal={handleClickButton}
            doSaveCard={doSaveCard}
            setShowTable={setShowTable}
          />
        )}

        {showEditEquipment && (
          <ModalEditCardEquipment
            alertHeading='Редактирование карты аппарата'
            toggleModal={handleClickEditEquipment2}
            onClose={handleClickEditEquipment}
            dataCard={oneCardEquipment}
            doSaveCard={doUpdateCard}
            setHasChanges={setHasChanges}
            showConfirmModal={showConfirmModal}
            setShowConfirmModal={setShowConfirmModal}
          />
        )}

        {showLoadFiles && (
          <ModalLoadFiles
            alertHeading='Загрузка файлов документации'
            toggleModal={handleClickLoadFiles}
            dataCard={oneCardEquipment}
          />
        )}

        {showLifeCycleInUse && (
          <ModalEquipmentLifeCycleInUse
            alertHeading='Работа аппарата в период эксплуатации'
            toggleModal={handleClickChooseLifeCycleInUse}
            handleClickHeatExchange={handleClickHeatExchange}
          />
        )}

        {showHeatExchangeTypeTable && (
          <PipeStateModal
            alertHeading={pipeHeatType}
            toggleModal={handleClickHeatExchange}
            content={pipeHeatType}
            id={equipmentId}
          />
        )}

        {showRostechReg && (
          <RostechRegistrationEdit
            toggleModal={handleClickShowRostechReg}
            alertHeading='Редактирование данных по регистрации в Ростехнадзоре'
            id={equipmentId}
            saveRostech={saveRostech}
            getRostechDataFromServer={getRostechDataFromServer}
            savedData={savedRostechData}
          />
        )}

        <Row> &nbsp; </Row>
        {cardEquipment.length ? (
          <TableRegistry
            showTable={showTable}
            cardEquipment={getPagedData()}
            selectedIdEquipment={selectedIdEquipment}
            triggerRender={triggerRender}
            setShowTable={setShowTable}
            offset={offset}
            tableKey={currentPage}
          />
        ) : (
          <LoaderPage />
        )}

        <div
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', gap: '10px' }}
        >
          <LeftAndRightArrowsButtons
            nextPageClick={goToNextPage}
            prevPageClick={goToPreviousPage}
            needDisableButtons={true}
            disableNextButtonCondition={
              currentPage >= Math.ceil(cardEquipment.length / pageSize) - 1
            }
            disablePrevButtonCondition={currentPage === 0}
          />
          <Button onClick={handleClickButton} variant={showToggle ? 'secondary' : 'primary'}>
            <AddBoxOutlinedIcon /> Добавить новый аппарат
          </Button>
        </div>
      </div>
    </>
  )
}
export default LifeCycle
