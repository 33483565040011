import Server from '../server/server';

/**
 * Возварщает URL на иконку
 * @param {string} nameFile определенный корень названия файла
 * @returns URL загрузки
 */
export async function loadImageURL(nameFile, login) {
    try {
        // console.log(`Загрузка иконок -> `); // test
        let URL = ''

        const urlServer = Server.exportURL()

        let user = 'leonid'

        if (urlServer !== 'http://localhost:') {
            user = 'leo'
        }


        if (login) {
            if (urlServer === 'http://localhost:') {
                URL = `D:/github/uems_backend/uems-uploads/icons/${login}_${nameFile}.jpg`
            } else {
                URL = `/home/${user}/uems-uploads/icons/${login}_${nameFile}.jpg`
                // URL = `${urlServer}/home/${user}/uems-uploads/icons/${login}_${nameFile}.jpg`
            }
        } else {
            if (urlServer === 'http://localhost:') {
                URL = `D:/github/uems_backend/uems-uploads/icons/${localStorage.getItem('idUser')}_${nameFile}.jpg`
                // URL = `D:/github/uems_backend/server_nodejs/serverapp/no-image.png`
            } else {
                // URL = `${urlServer}/home/${user}/uems-uploads/icons/${localStorage.getItem('idUser')}_${nameFile}.jpg`
                URL = `/home/${user}/uems-uploads/icons/${localStorage.getItem('idUser')}_${nameFile}.jpg`
            }

        }

        // URL = `${urlServer}:443/home/leo/uems-uploads/icons/${login}_${nameFile}.jpg` // test для проверки

        // console.log(`URL ICON: `, URL); // test

        const result = await Server.readFileFromServer(URL);

        // console.log(`RESULT:::> `, result); // test

        if (result.ERROR) throw new Error(result.massage)

        if (result) {
            const linkBlob = global.URL.createObjectURL(result);

            // console.log(`LINK >>>>> `, linkBlob); // test

            // return URL
            return linkBlob
        }
    } catch (err) {
        console.log(`Ошибка при загрузке иконки (LoadImageURL.js) : `, err);
    }
}