import React from 'react';
import { Row } from 'react-bootstrap';
import InputForm from './InputForm';
import { WELDING } from './ConstWelding';
import SelectFormArr from './SelectFormArr';

export default class Form_Three_Add extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            language: 'rus'

        }

        this.doChangeValue = this.doChangeValue.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
        this.doChoiceOption = this.doChoiceOption.bind(this);
    }

    doChoiceOption(choice) {
        this.props.onChoiceOption(choice);
    }

    //* Пустышка
    doEmpty() {

    }

    //* данные с полей формы, формируются в массив объектов
    doChangeValue(data) {
        this.props.onChangeValue(data);
    }

    componentDidMount() {

    }

    render() {

        const id = this.props.id;
        this.value = this.props.value;

        return (

            <React.Fragment>
                <Row className='m_0_10'>

                    <SelectFormArr
                        id={+id}
                        width={8}
                        show={true}
                        fontSize={'12px'}
                        label={(+id) === 3 ? 'Вид сварки' : ''}
                        placeholder="Вид сварки"
                        description="Сварочное производство"
                        option={WELDING}
                        information={this.value[+id] ? this.value[+id].information : ''}
                        value={this.value[+id] ? this.value[+id].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doEmpty}
                        arrayChoice={this.props.arrayChoice}
                        onChoiceOption={this.doChoiceOption}
                        showOn={true}
                        nameForm={this.props.nameForm}
                        readOnly={this.props.readOnly}
                    />
                    <InputForm
                        id={+id + 1}
                        width={3}
                        show={true}
                        verify="number"
                        fontSize={'12px'}
                        label={(+id + 1) === 4 ? 'Макс. толщина, мм' : ''}
                        placeholder="Макс. толщина, мм"
                        description="Сварочное производство"
                        value={this.value[+id + 1] ? this.value[+id + 1].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.props.nameForm}
                        readOnly={this.props.readOnly}
                    />
                </Row>
            </React.Fragment>

        )
    }
}