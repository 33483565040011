import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'

/**
 * Ввод строчных данных для карточки аппарата
 * @param {*} param0
 * @returns
 */
const InputDataText = ({ index, label, placeholder, text, keyData, handleChangeInput, value, name, idx }) => {
  return (
    <React.Fragment key={index}>
      <Form>
        <Form.Group>
          <Row>
            <Col>
              <Form.Label>
                <b>{label}</b>
              </Form.Label>
            </Col>
            <Col>
              <Form.Control
                type='text'
                placeholder={placeholder}
                data-key={keyData}
                onChange={handleChangeInput}
                value={value}
                name={name}
                // className={idx === 0 ? 'unique_number_input' : ''}
                style={{  border: idx === 0 ? '#007bff 2px solid' : '' }}
              />
              {/* <Form.Text className='text-muted'>{text}</Form.Text> */}
            </Col>
          </Row>
        </Form.Group>
      </Form>
    </React.Fragment>
  )
}
export default InputDataText
