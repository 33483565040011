/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Container, Form, Row, Col } from 'react-bootstrap'
import CheckForm from './CheckForm'
import FormMaterList from './FormMaterList'
import ModalQuit from '../ModalQuit'
import { CARBON, LOWALLOY, CHROMIUM, MARTENSITIC, FERRITIC, AUSTENITIC, AUST_FERR, AUST_MARTENSITIC, IRON_NICKEL, TITANIUM, COOPER_NICKEL, NICKEL } from './ConstMaterial'
import LocalGroceryStoreOutlinedIcon from '@mui/icons-material/LocalGroceryStoreOutlined'
import { HandleAllCheckBoxesBtn } from '../assets/HandleAllCheckboxesBtn'
import { nanoid } from 'nanoid'
import CommonAlert from './CommonAlert'
import AllModalsFooter from '../assets/AllModalsFooter'
import { SaveCurrentDataButton } from '../assets/BasicButtons'
import { createData } from './functionsForm'
import { isEqual } from 'lodash-es'

export default class Form_Four extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'rus',
      dataValue: [],
      // id: 3,
      maxId: 25,
      nameForm: 'Four',
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      base: [],
      showQuit: false,
      show1: false,
      materialsArr: [false, false, false, false, false, false, false, false, false, false, false, false],
      subMaterialsArr: [true, true, true, true, true, true, true, true, true, true, true, true],
      selectCheck: [true, true, true, true, true, true, true, true, true, true, true, true],
      idState: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23],
      showContent: true,
      previousState: [],
      savedDataMessage: false,
      dataBeenSaved: false
    }

    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this)
    this.onRunBlock = this.onRunBlock.bind(this)
    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)
    this.handleAllCheck = this.handleAllCheck.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.hideContent = this.hideContent.bind(this)
    this.setCompanyName = this.setCompanyName.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
  }

  saveDataWithNotification() {
    this.handleClickSave()

    this.setState({
      savedDataMessage: true,
      dataBeenSaved: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }

  hideContent() {
    this.setState({ showContent: false })
  }

  setCompanyName() {
    if (this.props.companyName && this.props.companyName.length > 0) {
      this.setState({
        companyName: this.props.companyName
      })
    }
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })
  }

  handleAllCheck(e) {
    let arr = this.state.subMaterialsArr //массив отображения секции с неосновными чекбоксами (изначально они все true)
    const pos = Number(e.target.dataset.id) //берем dataset у элемента, на который кликаем
    arr[pos] = false //у массива с неосновными чекбоксами меняем одно значение, он становится таким: [false, true, true, ....]
    const posSet = this.state.selectCheck //массив конкретно самих чекбоксов секции неосновных чекбоксов (изначально они все true)
    posSet[pos] = !posSet[pos] //тоглим выбранную позицию у массива в конкретно самих чекбоксов с true на false
    // из всего вышеперечисленного получаем 2 массива: [false, true, true, ....] и [true, true, true...] тоглим на [false, false, false]
    this.setState({
      selectCheck: posSet,
      subMaterialsArr: arr
    })
    arr = this.state.subMaterialsArr //отдаем масиву дефолтное значение
    setTimeout(() => {
      arr[pos] = true // меняем позицию по setTimeout тк иначе он не успевает менять значения
      this.setState({
        subMaterialsArr: arr
      })
    })
    // то массив неосновных чекбоксов снова отображается
  }

  //*Пустышка
  doEmpty() { }

  doChangeVisionBlock(e, id) {
    // console.log(e + ' - ' + id);//test
    let visibleState = this.state.materialsArr
    let idState = this.state.idState
    idState.forEach((data, index) => {
      if (data === +id) visibleState[index] = e
    })
    this.setState({ materialsArr: visibleState })
  }

  //*включаем блоки материалов при загрузке
  onRunBlock(data) {
    data.forEach((elem) => {
      if (elem) this.doChangeVisionBlock(elem.value, +elem.id)
    })
  }

  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  doQuitWithSave() {
    this.setState({ showQuit: false })
    this.handleClickSave()
    this.props.onChangeView('Four')
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({
      showQuit: false,
      nameForm: ''
    })
    this.props.onChangeView('Four')
  }

  //*формируем данные в массив объектов для отправки на сервер
  handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach((dataD) => {
        if (+dataD.id === i) {
          data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach((dataE) => {
        if (+dataE.id === i) {
          data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
        }
      })
    }
    this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере
    if (this.state.lengthDataFromServer < 1) {
      console.log('CREATE DATA', this.state.nameForm, data)
      this.props.onCreateDataServer(data, this.state.nameForm, localStorage.getItem('idUser'))
      // this.props.onCreateDataServer(data, this.state.nameForm, (localStorage.getItem('idUser')));
    } else {
      console.log('UPDATE DATA', this.state.nameForm, data)
      this.props.onUpdateDataOnServer(data, this.state.nameForm, localStorage.getItem('idUser'))
      // this.props.onUpdateDataOnServer(data, this.state.nameForm, (localStorage.getItem('idUser')));
    }
    setTimeout(() => {
      // this.setState({ nameForm: '' })
      console.log(this.state.dataOnServer) //test
    })
    // this.handleClickShadow();
  }

  //* данные с полей формы, формируются в массив объектов
  doChangeValue(data) {
    // console.log(data) //test
    this.setState({ dataValue: [...this.state.dataValue, data] })
    this.setState({
      dataBeenSaved: false
    })
  }

  compare() {
    const prev = this.state.previousState
    const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)

    const isStateEqual = isEqual(prev, curr)
    if (isStateEqual) {
      return true
    }
    return false
  }

  smartWindowFunction() {
    const isStateEqual = this.compare()
    const { dataBeenSaved, dataValue } = this.state
    if (isStateEqual || dataBeenSaved || dataValue.length === 0) {
      this.props.onChangeView('Four')
    } else {
      this.handleClickShadow()
    }
  }

  async componentDidMount() {
    //nameFormMaterials
    // setTimeout(() => {
    // console.log(this.props.nameFormMaterials);
    await this.setState({
      dataFromServer: this.props.data,
      lengthDataFromServer: this.props.lengthDataFromServer,
      // nameForm: `${this.state.nameForm}${this.props.nameFormMaterials}`// новое имя формы, откл
      nameForm: this.props.nameFormMaterials
      // nameForm: 'Four'
    })
    this.onRunBlock(this.props.data)
    // console.log('FORMA -> ', this.state.nameForm);         //test
    // }, 1000);

    this.setCompanyName()

    if (this.props.data.length) {
      const previousState = JSON.parse(JSON.stringify(this.props.data))
      this.setState({ previousState })
    }
  }

  render() {
    const view = this.props.view
    this.value = this.props.data

    const viewQuest = this.props.viewQuest
    const materialsArr = this.state.materialsArr
    const subMaterialsArr = this.state.subMaterialsArr
    const selectCheck = this.state.selectCheck

    //условие по которому компонент определяет находится ли он в формате чтения
    const inReadMode = this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined

    // console.log(this.state.dataValue);

    return (
      <>
        {inReadMode && <>{this.state.showQuit ? <ModalQuit closeQuitModal={this.closeQuitModal} onQuitWithSave={this.doQuitWithSave} onQuitWithoutSave={this.doQuitWithoutSave} /> : ''}</>}

        {this.state.showContent && (
          <div className='modal_window z_index_1000' style={{ display: view ? 'block' : 'none' }}>
            <div
              className={viewQuest ? 'shadow_form z_index_1001' : 'shadow_form_off'}
              // onClick={this.handleClickShadow}
              onClick={this.smartWindowFunction}
            ></div>
            <Form className={viewQuest ? 'form_main z_index_extra_big' : 'form_main_view'}>
              <CommonAlert
                inReadMode={inReadMode}
                handleClickHeader={this.handleClickHeader}
                // handleClickShadow={this.handleClickShadow}
                handleClickShadow={this.smartWindowFunction}
                hideContent={this.hideContent}
                icon={<LocalGroceryStoreOutlinedIcon fontSize='large' className='icon_primary' />}
                companyName={this.state.companyName}
                sectionName='Материалы'
              />
              <div>
                <Container className='form_container'>
                  <Form.Label className='f_size_1em'>Марка стали и сплава используемая на предприятии</Form.Label>

                  <Row> &nbsp; </Row>
                  <Row>
                    <Col>Материалы:</Col>
                  </Row>
                  <Form.Group>
                    {/* 1 */}
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={1}
                        width={6}
                        show={true}
                        placeholder='Углеродистый' //* подпись LABEL для чекбокса
                        description='Материалы'
                        value={this.value[1] ? this.value[1].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>

                    {materialsArr[0] && (
                      <>
                        {!this.value[2] && <HandleAllCheckBoxesBtn handleAllChecked={this.handleAllCheck} selectCheck={selectCheck[0]} dataId={0} />}
                        <Row>
                          {subMaterialsArr[0] && (
                            <>
                              <FormMaterList
                                name={nanoid()}
                                id={2}
                                width={2}
                                rowView={true}
                                materials={CARBON}
                                value={this.value[2] ? this.value[2].value : ''}
                                description='Углеродистый'
                                onChangeValue={this.doChangeValue}
                                nameForm={this.state.nameForm}
                                selectCheck={selectCheck[0]}
                                readOnly={!inReadMode}
                              />
                            </>
                          )}
                        </Row>
                      </>
                    )}

                    {materialsArr[0] && <Row className='border_bottom_underline'>&nbsp;</Row>}
                    {/* 2 */}
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={3}
                        width={6}
                        show={true}
                        placeholder='Низколегированный марганцовистый, марганцево-кремнистый '
                        description='Материалы'
                        value={this.value[3] ? this.value[3].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    {materialsArr[1] && (
                      <>
                        {!this.value[4] && <HandleAllCheckBoxesBtn handleAllChecked={this.handleAllCheck} selectCheck={selectCheck[1]} dataId={1} />}
                        <Row>
                          {subMaterialsArr[1] && (
                            <FormMaterList
                              name={nanoid()}
                              id={4}
                              width={2}
                              rowView={true}
                              materials={LOWALLOY}
                              value={this.value[4] ? this.value[4].value : ''}
                              description='Низколегированный марганцовистый, марганцево-кремнистый'
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                              selectCheck={selectCheck[1]}
                              readOnly={!inReadMode}
                            />
                          )}
                        </Row>
                      </>
                    )}

                    {materialsArr[1] && <Row className='border_bottom_underline'>&nbsp;</Row>}

                    {/* 3 */}
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={5}
                        width={6}
                        show={true}
                        placeholder='Хромомолибденовый, хромомолибденованадиевый'
                        description='Материалы'
                        value={this.value[5] ? this.value[5].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    {materialsArr[2] && (
                      <>
                        {!this.value[6] && <HandleAllCheckBoxesBtn handleAllChecked={this.handleAllCheck} selectCheck={selectCheck[2]} dataId={2} />}
                        <Row>
                          {subMaterialsArr[2] && (
                            <FormMaterList
                              name={nanoid()}
                              id={6}
                              width={2}
                              rowView={true}
                              materials={CHROMIUM}
                              value={this.value[6] ? this.value[6].value : ''}
                              description='Хромомолибденовый, хромомолибденованадиевый'
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                              selectCheck={selectCheck[2]}
                              readOnly={!inReadMode}
                            />
                          )}
                        </Row>
                      </>
                    )}

                    {materialsArr[2] && <Row className='border_bottom_underline'>&nbsp;</Row>}
                    {/* 4 */}

                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={7}
                        width={6}
                        show={true}
                        placeholder='Мартенситный'
                        description='Материалы'
                        value={this.value[7] ? this.value[7].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    {materialsArr[3] && (
                      <>
                        {!this.value[8] && <HandleAllCheckBoxesBtn handleAllChecked={this.handleAllCheck} selectCheck={selectCheck[3]} dataId={3} />}
                        <Row>
                          {subMaterialsArr[3] && (
                            <FormMaterList
                              name={nanoid()}
                              id={8}
                              width={2}
                              rowView={true}
                              materials={MARTENSITIC}
                              value={this.value[8] ? this.value[8].value : ''}
                              description='Мартенситный'
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                              selectCheck={selectCheck[3]}
                              readOnly={!inReadMode}
                            />
                          )}
                        </Row>
                      </>
                    )}

                    {materialsArr[3] && <Row className='border_bottom_underline'>&nbsp;</Row>}
                    {/* 5 */}
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={9}
                        width={6}
                        show={true}
                        placeholder='Ферритный '
                        description='Материалы'
                        value={this.value[9] ? this.value[9].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    {materialsArr[4] && (
                      <Row>
                        {subMaterialsArr[4] && (
                          <FormMaterList
                            name={nanoid()}
                            id={10}
                            width={2}
                            rowView={true}
                            materials={FERRITIC}
                            value={this.value[10] ? this.value[10].value : ''}
                            description='Ферритный'
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            selectCheck={selectCheck[4]}
                            readOnly={!inReadMode}
                          />
                        )}
                      </Row>
                    )}

                    {materialsArr[4] && <Row className='border_bottom_underline'>&nbsp;</Row>}

                    {/* 6 */}
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={11}
                        width={6}
                        show={true}
                        placeholder='Аустенитный '
                        description='Материалы'
                        value={this.value[11] ? this.value[11].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    {materialsArr[5] && (
                      <>
                        {!this.value[12] && <HandleAllCheckBoxesBtn handleAllChecked={this.handleAllCheck} selectCheck={selectCheck[5]} dataId={5} />}
                        <Row>
                          {subMaterialsArr[5] && (
                            <FormMaterList
                              name={nanoid()}
                              id={12}
                              width={2}
                              rowView={true}
                              materials={AUSTENITIC}
                              value={this.value[12] ? this.value[12].value : ''}
                              description='Аустенитный'
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                              selectCheck={selectCheck[5]}
                              readOnly={!inReadMode}
                            />
                          )}
                        </Row>
                      </>
                    )}

                    {materialsArr[5] && <Row className='border_bottom_underline'>&nbsp;</Row>}

                    {/* 7 */}
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={13}
                        width={6}
                        show={true}
                        placeholder='Аустенитно-ферритный '
                        description='Материалы'
                        value={this.value[13] ? this.value[13].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>

                    {materialsArr[6] && (
                      <Row>
                        {subMaterialsArr[6] && (
                          <FormMaterList
                            name={nanoid()}
                            id={14}
                            width={2}
                            rowView={true}
                            materials={AUST_FERR}
                            value={this.value[14] ? this.value[14].value : ''}
                            description='Аустенитно-ферритный'
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            selectCheck={selectCheck[6]}
                            readOnly={!inReadMode}
                          />
                        )}
                      </Row>
                    )}
                    {materialsArr[6] && <Row className='border_bottom_underline'>&nbsp;</Row>}
                    {/* 8 */}
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={15}
                        width={6}
                        show={true}
                        placeholder='Аустенитно-мартенситный '
                        description='Материалы'
                        value={this.value[15] ? this.value[15].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    {materialsArr[7] && (
                      <Row>
                        {subMaterialsArr[7] && (
                          <FormMaterList
                            name={nanoid()}
                            id={16}
                            width={2}
                            rowView={true}
                            materials={AUST_MARTENSITIC}
                            value={this.value[16] ? this.value[16].value : ''}
                            description='Аустенитно-мартенситный'
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            selectCheck={selectCheck[7]}
                            readOnly={!inReadMode}
                          />
                        )}
                      </Row>
                    )}
                    {materialsArr[7] && <Row className='border_bottom_underline'>&nbsp;</Row>}
                    {/* 9 */}
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={17}
                        width={6}
                        show={true}
                        placeholder='Сплавы на железоникелевой основе'
                        description='Материалы'
                        value={this.value[17] ? this.value[17].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    {materialsArr[8] && (
                      <Row>
                        {subMaterialsArr[8] && (
                          <FormMaterList
                            name={nanoid()}
                            id={18}
                            width={2}
                            rowView={true}
                            materials={IRON_NICKEL}
                            value={this.value[18] ? this.value[18].value : ''}
                            description='Сплавы на железоникелевой основе'
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                            selectCheck={selectCheck[8]}
                            readOnly={!inReadMode}
                          />
                        )}
                      </Row>
                    )}
                    {materialsArr[8] && <Row className='border_bottom_underline'>&nbsp;</Row>}
                    {/* 10 */}
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={19}
                        width={6}
                        show={true}
                        placeholder='Титановые сплавы'
                        description='Материалы'
                        value={this.value[19] ? this.value[19].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    {materialsArr[9] && (
                      <>
                        {/* {!this.value[20] &&
                                            <HandleAllCheckBoxesBtn
                                                handleAllChecked={this.handleAllCheck}
                                                selectCheck={selectCheck[9]}
                                                dataId={9}
                                            />} */}

                        <Row>
                          {subMaterialsArr[9] && (
                            <FormMaterList
                              name={nanoid()}
                              id={20}
                              width={2}
                              rowView={true}
                              materials={TITANIUM}
                              value={this.value[20] ? this.value[20].value : ''}
                              description='Титановые сплавы'
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                              selectCheck={selectCheck[9]}
                              readOnly={!inReadMode}
                            />
                          )}
                        </Row>
                      </>
                    )}
                    {materialsArr[9] && <Row className='border_bottom_underline'>&nbsp;</Row>}
                    {/* 11 */}
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={21}
                        width={6}
                        show={true}
                        placeholder='Медно-никелевые сплавы'
                        description='Материалы'
                        value={this.value[21] ? this.value[21].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    {materialsArr[10] && (
                      <>
                        {/* {!this.value[22] &&
                                            <HandleAllCheckBoxesBtn
                                                handleAllChecked={this.handleAllCheck}
                                                selectCheck={selectCheck[10]}
                                                dataId={10}
                                            />} */}
                        <Row>
                          {subMaterialsArr[10] && (
                            <FormMaterList
                              name={nanoid()}
                              id={22}
                              width={2}
                              rowView={true}
                              materials={COOPER_NICKEL}
                              value={this.value[22] ? this.value[22].value : ''}
                              description='Медно-никелевые сплавы'
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                              selectCheck={selectCheck[10]}
                              readOnly={!inReadMode}
                            />
                          )}
                        </Row>
                      </>
                    )}
                    {materialsArr[10] && <Row className='border_bottom_underline'>&nbsp;</Row>}
                    {/* 12 */}
                    <Row>
                      <CheckForm
                        name={nanoid()}
                        id={23}
                        width={6}
                        show={true}
                        placeholder='Никелевые сплавы'
                        description='Материалы'
                        value={this.value[23] ? this.value[23].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.state.nameForm}
                        readOnly={!inReadMode}
                      />
                    </Row>
                    {materialsArr[11] && (
                      <>
                        <Row>
                          {subMaterialsArr[11] && (
                            <FormMaterList
                              name={nanoid()}
                              id={24}
                              width={3}
                              rowView={true} //расположение на экране
                              materials={NICKEL}
                              value={this.value[24] ? this.value[24].value : ''}
                              description='Никелевые сплавы'
                              onChangeValue={this.doChangeValue}
                              nameForm={this.state.nameForm}
                              selectCheck={selectCheck[11]}
                              readOnly={!inReadMode}
                            />
                          )}
                        </Row>
                      </>
                    )}
                    {materialsArr[11] && <Row className='border_bottom_underline'>&nbsp;</Row>}
                    <Row> &nbsp; </Row>
                  </Form.Group>
                </Container>
                <AllModalsFooter
                  footerContent={
                    inReadMode && (
                      <SaveCurrentDataButton
                        handleSaveClick={this.saveDataWithNotification}
                        needDisable={false}
                        savedDataMessage={this.state.savedDataMessage}
                        buttonTextDefault={'Сохранить'}
                        buttonTextSaved={'Данные сохранены'}
                        needCustomIcon={false}
                      />
                    )
                    //     <SaveButtonGreen
                    //     handleSaveClick={this.handleClickSave}
                    //     needDisable={false}
                    //     saveButtonText='Сохранить'
                    //   />
                    // <Button
                    //     variant="success"
                    //     onClick={this.handleClickSave}
                    // >
                    //     Сохранить
                    // </Button>
                  }
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}
