/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Container, Row } from 'react-bootstrap';
// import InputForm from './InputForm';
// import SelectForm from './SelectForm';
import Server from '../server/server';
import Form_Fifteen_Files from './Form_Fifteen_Files';
import ModalQuit from '../ModalQuit';
import MovingOutlinedIcon from '@mui/icons-material/MovingOutlined';
import CommonAlert from './CommonAlert';

export default class Form_Fifteen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            dataValue: [],
            maxId: 16,
            nameForm: 'Fifteen',
            dataOnServer: [],
            dataFromServer: [],
            lengthDataFromServer: 0,
            showQuit: false
        }

        this.handleClickShadow = this.handleClickShadow.bind(this);
        // this.onChangeFile = this.onChangeFile.bind(this);
        this.dosendDataFile = this.dosendDataFile.bind(this);
        this.createNewDataOnServer = this.createNewDataOnServer.bind(this);
        this.updateDataOnServer = this.updateDataOnServer.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.writeNewData = this.writeNewData.bind(this);
        this.sendFileOnServer = this.sendFileOnServer.bind(this);

        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);
        this.closeQuitModal = this.closeQuitModal.bind(this);
        this.hideContent = this.hideContent.bind(this);
        this.setCompanyName = this.setCompanyName.bind(this);

    }

    hideContent() {
        this.setState({ showContent: false })
    }


    setCompanyName() {
        if (this.props.companyName && this.props.companyName.length > 0) {
            this.setState({
                companyName: this.props.companyName
            })
        }
    }

    closeQuitModal() {
        this.setState({
            showQuit: false
        })
    }


    handleClickSave() {
        console.log('save click');
    }

    // получает пропс от child (форма, объект, maxID)
    async dosendDataFile(data, dataF, maxId) {
        await this.setState({ maxId: maxId });
        this.sendFileOnServer(data, dataF);//todo  Сохранение файлов на сервере (форма, объект, maxID). Сформировать объект в этом файле!
    }

    //* ОТПРАВКА(СОЗДАНИЕ) файла на сервер
    async sendFileOnServer(newDataForm, dataF) {
        try {
            const result = await Server.createNewFileOnServer(newDataForm);
            console.log('ALL OK, load file on server');
            console.log(result);//todo получаем ответ в виде URL сохраненного файла            
            this.writeNewData(result, dataF) //результат - URL, объект
        } catch (err) {
            console.log('ERROR: not load file on server (frontend)');
            console.log(err);
        }
    }

    async writeNewData(result, dataF) {
        let data = this.state.dataOnServer;

        try {
            dataF.information = result.path;
            console.log(`PATH :::::::::::: >>>> `, dataF.information);//test
            await this.setState({ dataValue: [...this.state.dataValue, dataF] });

            setTimeout(() => {
                for (let i = 0; i <= (this.state.maxId); i++) {

                    //todo создаем позицию с пустым объектом
                    data[i] = { id: '', fid: '', description: '', information: '', value: '' }

                    //todo заполняем объект данными с сервера
                    this.state.dataFromServer.forEach((dataD) => {
                        if (+dataD.id === i) {
                            data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                        }
                    })
                    //todo заполняем объект данными с формы
                    this.state.dataValue.forEach((dataE) => {
                        if (+dataE.id === i) {
                            data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                        }
                    })

                }
                this.setState({ dataOnServer: data })

                setTimeout(() => {
                    if (this.props.lengthDataFromServer > 0) {
                        this.updateDataOnServer(this.state.dataOnServer, 'Fifteen', localStorage.getItem('idUser'))//todo отключено на момент написания
                        console.log('UPDATE');
                        console.log(this.state.dataOnServer);//test
                    } else {
                        this.createNewDataOnServer(this.state.dataOnServer, 'Fifteen', localStorage.getItem('idUser'))//todo отключено на момент написания
                        console.log('CREATE');
                        console.log(this.state.dataOnServer);//test
                    }
                })
            })
        } catch (err) {
            console.log(`Ошибка (Form_Fifteen.jsx): `, err);
        }
    }

    //* Создаем новый файл
    createNewDataOnServer(data, name, id) {
        this.props.onCreateDataServer(data, name, id);
    }

    //* Обновляем существующий файл
    updateDataOnServer(data, name, id) {
        this.props.onUpdateDataOnServer(data, name, id);
    }


    //запуск выхода из формы
    handleClickShadow() {
        //! Изменения только для этой формы
        // this.setState({ showQuit: true });
        this.props.onChangeView('Fifteen');
    }

    //выход с сохранением
    doQuitWithSave() {
        this.setState({ showQuit: false })
        this.handleClickSave();
        this.props.onChangeView('Fifteen');
    }
    //выход без сохранения
    doQuitWithoutSave() {
        this.setState({ showQuit: false })
        this.props.onChangeView('Fifteen');
    }

    async componentDidMount() {
        await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer });
        console.log('DATA on dataFromServer');//test
        this.setCompanyName()

    }

    render() {

        const view = this.props.view;
        // const acceptData = 'application/msword, application/pdf, text/plain, image/jpeg, image/tiff, application/excel, application/x-excel, application/msword'
        const value = this.props.data;
        const viewQuest = this.props.viewQuest;

        return (
            <>
                {this.state.showQuit ?
                    <ModalQuit
                        onQuitWithSave={this.doQuitWithSave}
                        onQuitWithoutSave={this.doQuitWithoutSave}
                        closeQuitModal={this.closeQuitModal}
                    />
                    : ''}

                <div className="modal_window" style={{ display: view ? 'block' : 'none' }} >
                    <div className={viewQuest ? "shadow_form" : "shadow_form_off"} onClick={this.handleClickShadow}></div>
                    <div className={viewQuest ? "form_main" : "form_main_view"} >
                        <CommonAlert
                            // inReadMode={inReadMode}
                            handleClickHeader={this.handleClickHeader}
                            handleClickShadow={this.handleClickShadow}
                            hideContent={this.handleClickShadow}
                            icon={<MovingOutlinedIcon fontSize="large" className='icon_primary' />}
                            companyName={this.state.companyName}
                            sectionName='Квалификация поставщика'

                        />
                        <Container className="form_container">
                            <Row className='flex_space_around' >

                                <Form_Fifteen_Files
                                    onsendDataFile={this.dosendDataFile}
                                    value={value}
                                    nameForm={this.state.nameForm}
                                />

                            </Row>
                            <Row> &nbsp;</Row>

                            <Row> &nbsp;</Row>

                        </Container>
                    </div>
                </div>
            </>
        )
    }
}
