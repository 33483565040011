import React, { useEffect, useState } from 'react'


const RenderResultSTHE = (obj) => {

    const [resultObj, setResultObj] = useState()
    const [renderObj, setRenderObj] = useState(
        <>
            <div
                style={{
                    backgroundColor: '#e87c86',
                    textAlign: 'center',
                    height: '45px',
                    padding: '10px',
                    fontWeight: '700'
                }}
            >
                <span> Данные выдают ошибочный результат вычислений! Проверте данные! </span>
            </div>
        </>
    )

    useEffect(() => {
        setResultObj(obj.obj[1])
        console.log(`RESULT STHE >>>>> `, resultObj); // test


        if (typeof (resultObj) === 'object') {
            const renderObj = Object.keys(resultObj).map((itm, index) => {

                if (!resultObj[itm].length) return ('')

                return (
                    <React.Fragment key={index}>
                        <div
                            style={{ backgroundColor: '#fff' }}
                        >
                            <span>
                                {`=>`} &nbsp;
                            </span>
                            <span>
                                {itm} &nbsp;
                            </span>
                            <span>
                                sum: {resultObj[itm]?.map((item, index) => (
                                    <React.Fragment key={`${item}${index}`}>
                                        <span> &nbsp; +{item.sum} &nbsp;</span>
                                    </React.Fragment>
                                ))}
                            </span>
                        </div>
                    </React.Fragment>
                )

            })

            setRenderObj(renderObj)
        } else {
            setRenderObj(
                <>
                    <div
                        style={{
                            backgroundColor: '#e87c86',
                            textAlign: 'center',
                            height: '45px',
                            padding: '10px',
                            fontWeight: '700'
                        }}
                    >
                        <span> Данные выдают ошибочный результат вычислений! Проверте данные! </span>
                    </div>
                </>
            )
        }

    }, [obj, resultObj])



    return (
        <>
            <div>
                {renderObj}
            </div>
        </>
    )
}

export default RenderResultSTHE;