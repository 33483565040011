import React from 'react';
import { Col } from 'react-bootstrap';
import InputMask from 'react-input-mask';

//* https://www.npmjs.com/package/react-input-mask - масска для всего

export default class TimeForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            language: 'rus',
            id: '',
            description: '',
            information: '',
            value: '',
            colorError: '#fff'
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        // this.getMoscowTime = this.getMoscowTime.bind(this)

    }

    // getMoscowTime(timeUral) {
    //     const arr = timeUral.split(":")
    //     let hour = +arr[0] - 2

    //     if (hour === -1) hour = 23
    //     if (hour === - 2) hour = 22

    //     if (hour < 10) hour = `0${hour}`

    //     return `${hour}:${arr[1]}`

    // }

    handleBlur(e) {
        const timer = this.state.value.split(':');

        if (timer[0] <= 23 && timer[1] <= 59) {
            this.setState({
                id: e.target.dataset.id,
                description: e.target.dataset.description,
                information: e.target.dataset.information
            })
            setTimeout(() => { this.props.onChangeValue(this.state) })

        } else {
            setTimeout(() => { this.setState({ colorError: '#e87c86' }) })

            setTimeout(() => {
                this.setState({
                    value: '',
                    colorError: '#fff'
                })
            }, 3000)
        }
    }

    handleChange(e) {
        e.preventDefault();
        this.setState({ value: e.target.value })
    }

    componentDidMount() {
        this.setState({ value: (this.props.value) })
    }

    render() {

        const width = this.props.width;
        const id = this.props.id;
        const description = this.props.description;
        const placeholder = this.props.placeholder;
        const value = this.state.value;
        const show = this.props.show;
        const classError = this.props.classMask;
        const classMask = `form-control phone_form ${classError}`

        return (
            <React.Fragment key={id}>
                <Col sm={width} style={{ visibility: show ? 'visible' : 'collapse' }} >

                    <InputMask
                        style={{ backgroundColor: this.state.colorError }}
                        className={classMask}
                        mask="99:99"
                        maskChar="_"
                        data-id={id}
                        placeholder={placeholder}
                        data-information={placeholder}
                        data-description={description}
                        value={value}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                    />
                </Col>
            </React.Fragment>
        )
    }
}