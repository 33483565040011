import React from 'react'
// import { Button } from 'react-bootstrap'
import { EmployeesList } from './EmployeesList'
import { EmployeesModalBig } from './EmployeesModalBig'
// import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import Server from '../server/server'
import ModalSave from '../ModalSave'
import { EmployeesModalSmall } from './EmployeesModalSmall'
import { AddButton } from '../assets/BasicButtons'
import ModalError from '../ModalError'

export default class Employees extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lengthDataFromServer: 0,
      showLook: false,
      showSave: false,
      show_errorRequest: false,
      nameForm: 'employees',
      language: 'rus',
      show: true,
      showModal: false,
      resultData: {},
      alertId: null,
      DB: [],
      showOptionsModal: false,
      access: [],
      alert: []
    }

    //server
    this.getDataFromServer = this.getDataFromServer.bind(this)
    this.sendDataServer = this.sendDataServer.bind(this)
    this.lookingSave = this.lookingSave.bind(this)
    //UI
    this.removeEmployee = this.removeEmployee.bind(this)
    this.onModalToggle = this.onModalToggle.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doSendData = this.doSendData.bind(this)
    this.doDataIdSend = this.doDataIdSend.bind(this)
    this.onModalOpenUnfilled = this.onModalOpenUnfilled.bind(this)
    this.rejectDelete = this.rejectDelete.bind(this)
    this.openConfirmationModal = this.openConfirmationModal.bind(this)
    this.toggleOptionsModal = this.toggleOptionsModal.bind(this)
    this.doChangeAccess = this.doChangeAccess.bind(this)
    this.sendAlert = this.sendAlert.bind(this);
    this.changeResultData = this.changeResultData.bind(this);
  }

  changeResultData(data) {
    this.setState({ resultData: data })
  }

  sendAlert(data) {
    console.log(`DATA in Employees: `, data); // test
    this.setState({ alert: data })
    const arr = this.state.resultData;
    Object.assign(arr, { alert: data })
    console.log(`STATE >>>>> `, this.state.resultData); // test
  }

  doChangeAccess(data) {
    this.setState({ access: data })
  }

  toggleOptionsModal() {
    this.setState({
      showOptionsModal: !this.state.showOptionsModal
    })
  }

  async doDataIdSend(id) {
    const result = await this.state.DB.find((employee) => employee.id == id)
    await this.setState({ resultData: result })
    this.onModalToggle()
  }

  // async doSendData(data) {
  //     if (this.state.DB.find(item => item.id === data.id)) {
  //         const newData = this.state.DB.filter(item => item.id !== data.id);
  //         await this.setState({ DB: newData });
  //         await this.setState({ DB: [...this.state.DB, data] })

  //     }
  //     else {
  //         await this.setState({ DB: [...this.state.DB, data] })

  //     }
  // }

  async doSendData(data) {
    let newData
    if (this.state.DB.find((item) => item.id === data.id)) {
      newData = this.state.DB.filter((item) => item.id !== data.id)
      newData = [...newData, data]
    } else {
      newData = [...this.state.DB, data]
    }
    await this.setState({ DB: newData })
  }

  handleClickShadow() {
    this.setState({ showModal: false })
  }

  rejectDelete() {
    this.setState({
      alertId: null
    })
  }

  openConfirmationModal(employee) {
    if (this.state.DB.find((item) => item.id === employee.id)) {
      this.setState({
        alertId: employee.id
      })
    }
  }

  async removeEmployee() {
    if (this.state.alertId) {
      const newArray = this.state.DB.filter((el) => el.id !== this.state.alertId)
      await this.setState({
        DB: newArray,
        alertId: null
      })
    }
    await this.sendDataServer(this.state.nameForm, localStorage.getItem('idUser'))
  }

  onModalToggle() {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  async onModalOpenUnfilled() {
    await this.setState({
      resultData: {}
    })
    this.setState({
      showModal: true
    })
  }

  //server

  //POST
  lookingSave() {
    this.setState({ showSave: true })
    setTimeout(() => {
      this.setState({ showSave: false })
    }, 2000)
  }

  /**
   * сохранение данных на сервере
   * @param {*} name
   * @param {*} id
   */
  async sendDataServer(name, id, dataState) {
    this.lookingSave()
    try {
      const result = await Server.sendDataOnServer(this.state.DB, name, id)

      if (!result) {
        throw new Error(`Ошибка при сохранении данных сотрудника на сервере`)
      }
      // *: сделать point на сервере для регистрации нового пользователя с доступом - Админ
      const resultSave = await Server.registrationEmployees(dataState)

    } catch (err) {
      if (Server.errorRequest()) {
        console.log(`ERROR AUTH -> Employees`);
        this.setState({ show_errorRequest: true })
        setTimeout(() => { this.setState({ show_errorRequest: false }) }, 3000)
      }

      console.log(`Ошибка регистрации сотрудника: `, err)
    }
  }

  //GET
  async getDataFromServer(name, id) {
    try {
      const result = await Server.getDataFromServer(name, id)
      await this.setState({
        DB: [...result]
      })
    } catch (err) {
      this.setState({ lengthDataFromServer: 0 })

      if (Server.errorRequest()) {
        console.log(`ERROR AUTH -> Employees`);
        this.setState({ show_errorRequest: true })
        setTimeout(() => { this.setState({ show_errorRequest: false }) }, 3000)
      }

      console.log(`ERROR: `, err);
    }
  }

  async componentDidMount() {
    await this.getDataFromServer(this.state.nameForm, localStorage.getItem('idUser'))
  }

  render() {
    console.log(this.state.DB)
    const show = this.state.show
    return (
      <>
        {this.state.show_errorRequest ?
          <ModalError />
          : ''
        }
        <div className='container_height_overflow'>
          <ModalSave show={this.state.showSave} />

          {show ? (
            <EmployeesList
              removeEmployee={this.removeEmployee}
              employeesArray={this.state.DB}
              onDataIdSend={this.doDataIdSend}
              openConfirmationModal={this.openConfirmationModal}
              rejectDelete={this.rejectDelete}
              alertId={this.state.alertId}
            // access={this.state.access}
            />
          ) : (
            ''
          )}
          {/* <Button onClick={this.onModalOpenUnfilled} variant='success' className="mb_20"><PersonAddAltOutlinedIcon /></Button> */}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <AddButton
              handleSaveClick={this.onModalOpenUnfilled}
              needDisable={false}
              buttonText='сотрудника'
            />
          </div>

          {this.state.showModal && (
            <EmployeesModalBig
              onSendData={this.doSendData}
              resultData={this.state.resultData}
              handleClickShadow={this.handleClickShadow}
              onModalToggle={this.onModalToggle}
              sendDataServer={this.sendDataServer}
              DB={this.state.DB}
              toggleOptionsModal={this.toggleOptionsModal}
              access={this.state.access}
              alert={this.state.alert}
              data={this.changeResultData}
            />
          )}

          {this.state.showOptionsModal && (
            <EmployeesModalSmall
              toggleOptionsModal={this.toggleOptionsModal}
              resultData={this.state.resultData}
              onChangeAccess={this.doChangeAccess}
              sendAlert={this.sendAlert}
            />
          )}
        </div>
      </>
    )
  }
}
