import React from 'react'
import { Button, Row } from 'react-bootstrap'
import TechDataTradeAdd from './TechDataTradeAdd'
import CreateNewTemplate from './CreateNewTemplate'
import LoadAllTemplate from './LoadAllTemplate'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AllModalsFooter from '../assets/AllModalsFooter'
import { AddButton, CreateButton, LoadButton, SaveButtonGreen } from '../assets/BasicButtons'

export default class TechDataTrade extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      id: 1,
      base: [],
      value: [],
      data: [],
      errData: false,
      showTemplate: false,
      showLoadTemplate: false,
      techData: [],
      templateData: '',
      isAddBtnDisabled: true,
      isNextBtnDisabled: true
    }

    this.handleClickAdd = this.handleClickAdd.bind(this)
    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.verificationData = this.verificationData.bind(this)
    this.renderBlock = this.renderBlock.bind(this)

    this.handleClickNewTemplate = this.handleClickNewTemplate.bind(this)
    this.doChangeView = this.doChangeView.bind(this)
    this.handleClickLoadTemplate = this.handleClickLoadTemplate.bind(this)
    this.doLoadTemplate = this.doLoadTemplate.bind(this)
    this.eventKeyOn = this.eventKeyOn.bind(this)
    this.getTemplateData = this.getTemplateData.bind(this)
    this.saveInReadMode = this.saveInReadMode.bind(this)
  }

  getTemplateData(templ) {
    this.setState({
      templateData: templ
    })
  }

  //отправляем CreateTrade полученный шаблон
  doLoadTemplate(data) {
    // console.log(`>>>>>>>>>>>>>>>>>>> `, data); // test
    this.props.onLoadTemplate(data.data)
    // console.log(`PROPS DATA UP`, data.data); // test
  }

  // загружаем ранее созданные шаблоны
  async handleClickLoadTemplate() {
    this.setState({ showLoadTemplate: true })
  }

  // отключаем TechCreateTemplate
  doChangeView(e) {
    if (e === 'CreateNewTemplate') {
      this.setState({ showTemplate: false })
    }
    if (e === 'LoadAllTemplate') {
      this.setState({ showLoadTemplate: false })
    }
  }

  // созадть новый шаблон
  handleClickNewTemplate() {
    // console.log('Click new template');// test
    this.setState({ showTemplate: !this.state.showTemplate })
  }

  eventKeyOn(e) {
    // console.log(e);
  }

  //* кнопка SAVE
  handleClickSave(e) {
    this.setState({ value: this.state.data })
    // setTimeout(() => { this.verificationData() })
    this.verificationData()
    setTimeout(() => {
      if (this.state.errData) {
        this.props.onSaveDataTrade(this.state.data)
      }
    }, 500)
    setTimeout(() => {
      this.props.onSendDataFromTech(this.state.data)
    }, 1000)
    // this.props.handleClick(e)
    this.props.toNextTab(e)
  }

  saveInReadMode() {
    this.setState({ value: this.state.data })
    // setTimeout(() => { this.verificationData() })
    this.verificationData()
    setTimeout(() => {
      if (this.state.errData) {
        this.props.onSaveDataTrade(this.state.data)
      }
    }, 500)
    setTimeout(() => {
      this.props.onSendDataFromTech(this.state.data)
    }, 1000)
  }

  //* проверка и отправка данных в PARENT
  doChangeValue(data) {
    // console.log(data);//test
    this.setState({ data: [...this.state.data, data] })

    setTimeout(() => {
      let arrData = [{ id: 0 }]

      this.state.data.forEach(data => {
        if (data && typeof +data.id !== undefined) arrData[+data.id] = data
      })
      this.setState({ data: arrData })
      this.props.onSaveDataTrade(this.state.data)
    })

    //раздизейбливаем кнопку "добавить позиции"
    if (data.value && data.value !== '') {
      this.setState({
        isAddBtnDisabled: false
      })
    }

    //раздизейбливаем кнопку "далее"
    const res = this.state.data.filter(el => el && el.id !== 0).filter(el => el.value !== '')
    res.map((el, idx) => {
      // console.log(res[idx]);
      if (el.value && el.value !== '') {
        this.setState({
          isNextBtnDisabled: false
        })
      }
    })
  }

  verificationData() {
    // console.log(` >>>>> `, this.state.data.length); // test
    if ((this.state.data.length - 1) % 2 === 0 && this.state.data.length > 2) {
      this.setState({ errData: true })
    } else {
      this.setState({ errData: false })
    }
    // setTimeout(() => { console.log(`>>>>>>> `, this.state.errData); })
  }

  handleClickAdd() {
    this.setState({
      base: [
        ...this.state.base,
        <React.Fragment key={this.state.id}>
          <TechDataTradeAdd onChangeValue={this.doChangeValue} id={this.state.id} value={this.props.value} />
        </React.Fragment>
      ],
      id: +this.state.id + 2
    })
    // setTimeout(() => { console.log(this.state.id + ' <- ADD ID'); }) // test

    this.setState({
      isAddBtnDisabled: true
    })
  }

  renderBlock() {
    this.handleClickAdd()

    let lengthData = this.props.value.length

    if (lengthData > 3) {
      for (let i = 0; i < lengthData - 3; i = i + 2) {
        this.handleClickAdd()
      }
    }
  }

  async componentDidMount() {
    if (this.props.disableBtn === false) {
      await this.setState({
        isNextBtnDisabled: false,
        isAddBtnDisabled: false
      })
    }

    if (this.props.inReadMode) {
      this.saveInReadMode()
    }

    setTimeout(() => {
      this.renderBlock()
      this.setState({ data: this.props.value }) // загружаем данные от родителя
      this.verificationData()
    }, 500)
    // console.log(`VALUE::::: `, this.props.value);
  }

  render() {
    this.value = this.props.value

    return (
      <>
        {this.state.showTemplate ? (
          <CreateNewTemplate
            view={true}
            onChangeView={this.doChangeView}
            viewQuest={this.state.showTemplate}
            handleClickNewTemplate={this.handleClickNewTemplate}
          />
        ) : (
          ''
        )}

        {this.state.showLoadTemplate ? (
          <LoadAllTemplate
            view={true}
            onChangeView={this.doChangeView}
            viewQuest={this.state.showLoadTemplate}
            onLoadTemplate={this.doLoadTemplate}
            getTemplateData={this.getTemplateData}
          />
        ) : (
          ''
        )}

        <Row>&nbsp;</Row>

        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <div style={{ display: 'flex', gap: '10px', height: '40px', marginTop: '10px', flexWrap: 'wrap' }}>
            <LoadButton
            handleSaveClick={this.handleClickLoadTemplate} 
            needDisable={false} 
            buttonText='Загрузить шаблон'
            />
            {/* <Button onClick={this.handleClickLoadTemplate}>
              <DownloadOutlinedIcon /> Загрузить шаблон
            </Button> */}
            {/* <Button onClick={this.handleClickNewTemplate}>
              <AddCircleOutlineOutlinedIcon color='disabled' fontSize='small' /> Создать новый шаблон
            </Button> */}
            <CreateButton
             handleSaveClick={this.handleClickNewTemplate}
             needDisable={false}
             variant='primary'
             buttonText='Создать новый шаблон'
             dataIdx=''
             btnWidth=''
             iconWithPlus={true}
            />
          </div>
        </div>

        <div className='overflow_y_auto overflow_x_hidden height_300 pr_10' style={{ marginBottom: '72px' }}>
          <div className='mt_20'>{this.state.base}</div>
        </div>
        <AllModalsFooter
          isPosAbsoluteNedded={true}
          footerContent={
            <>
              <AddButton
                handleSaveClick={this.handleClickAdd}
                needDisable={true}
                disableCondition={this.props.disableBtn === false ? this.props.disableBtn : this.state.isAddBtnDisabled}
                buttonText='параметр'
              />

              <SaveButtonGreen
                handleSaveClick={this.handleClickSave}
                needDisable={false}
                saveButtonText='Далее'
                dataIdx={'commercial'}
              />

              {/* <Button
                // disabled={this.props.disableBtn ? this.props.disableBtn : this.state.isNextBtnDisabled}
                data-index={'commercial'}
                variant='success'
                onClick={this.handleClickSave}
                className='m_0'
              >
                Далее
              </Button> */}
            </>
          }
        />
      </>
    )
  }
}
