import React, { useEffect, useState } from "react";
import { Form, Table } from "react-bootstrap";
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';

const AddListForFile = ({ listForFiles, addList, deletePosition }) => {

    const [value, setValue] = useState()

    useEffect(() => {
        setValue(listForFiles)
        // console.log(`CHANGE VALUE`);
    }, [listForFiles])

    const handleChange = (event) => {
        // console.log(`DATA::::: `, value); // test
        const result = value.map(item => {

            if (item.id === event.target.dataset.id) {
                return (
                    {
                        id: item.id, description: event.target.value, archive: item.archive
                    }
                )
            } else {
                return (
                    { id: item.id, description: item.description, archive: item.archive }
                )
            }

        })

        setValue(result)
        addList(result)
    }

    const handleClickDelete = (id) => {
        // console.log(`Delete pos::: `, id);
        deletePosition(id)
    }

    return (
        <>
            {
                value && value.map((item, index) => {
                    if (!item.archive) {
                        return (
                            <React.Fragment key={index}>
                                <Table bordered
                                    style={{
                                        backgroundColor: '#fff',
                                        marginBottom: '-1px',
                                    }}>
                                    <tbody>
                                        <tr>
                                            <td
                                                style={{
                                                    width: '70px',
                                                    textAlign: 'center',
                                                    padding: '18px'
                                                }}
                                            >
                                                {index + 1}
                                            </td>
                                            <td>
                                                <Form.Control
                                                    type="text"
                                                    // placeholder={item.description}
                                                    data-id={item.id}
                                                    data-description={item.description}
                                                    onChange={handleChange}
                                                    value={item.description}
                                                />
                                            </td>
                                            <td
                                                className='width_50 text_align_center'
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => handleClickDelete(item.id)}
                                                title="Удалить позицию"
                                            >
                                                <DeleteForeverOutlinedIcon
                                                    sx={{ color: '#ED4880' }}

                                                />
                                            </td>

                                        </tr>
                                    </tbody>
                                </Table>
                            </React.Fragment>
                        )
                    }
                })
            }

        </>
    )
}

export default AddListForFile;