import React from 'react'


const SearchQr = () => {

    console.log(`Component: Search Of QR - run`);

    return (
        <>
            <div> Поиск по QR </div>
        </>
    )
}

export default SearchQr;