import {
    CARBON,
    LOWALLOY,
    CHROMIUM,
    MARTENSITIC,
    FERRITIC,
    AUSTENITIC,
    AUST_FERR,
    AUST_MARTENSITIC,
    IRON_NICKEL,
    TITANIUM,
    COOPER_NICKEL,
    NICKEL,
} from '../../questionnaire/ConstMaterial'


export const COMBINING = CARBON.concat(LOWALLOY, CHROMIUM, MARTENSITIC, FERRITIC, AUSTENITIC, AUST_FERR, AUST_MARTENSITIC, IRON_NICKEL, TITANIUM, COOPER_NICKEL, NICKEL)
