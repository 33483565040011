import React, { useState, useEffect } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { CheckFormSearch } from './CheckFormSearch'
import { HandleAllCheckBoxesBtn } from '../assets/HandleAllCheckboxesBtn'
import { Alert } from 'react-bootstrap';
import { COMPANY, occupationTypes } from '../questionnaire/ConstListCompany';
import Server from '../server/server';
import { nanoid } from 'nanoid'


export const FilteredCompanies = (props) => {
    const [profiles] = useState(COMPANY)
    const [selected, setSelected] = useState([])
    const [selectCheck, setSelectCheck] = useState(true)
    const [showChecked, setShowChecked] = useState(true)
    const [resultProf, setProfiles] = useState([]); // загруженно с сервера, только существующие виды деятельности

    const handleAllCheck = () => {
        setSelectCheck(!selectCheck)
        if (selectCheck) {
            setSelected(resultProf) // выводим только существующие виды деятельности
            // setSelected(profiles)
        }
        if (!selectCheck) {
            setSelected([])
        }
        setShowChecked(false)

        setTimeout(() => {
            setShowChecked(true)
        });
    }

    // загружаем существующие виды деятельности с сервера
    useEffect(() => {
        (async () => {
            try {
                const response = await Server.searchProduction();

                await setProfiles(response);
                // console.log(response.length);
            } catch (err) {
                console.log(`Ошибка при получении данных с сервера: `, err);
            }
        })()
    }, [setProfiles])

    useEffect(() => {
        props.onChoice(selected)
        // console.log(`SELECTED >>>>>>>>>>>>>>>>> `, selected); // test
    }, [selected])


    const onChange = async (e) => {
        if (e.value === false && selected.find(c => c === e.id)) {

            const resultFilter = selected.filter(elem => elem !== e.id);
            setSelected(resultFilter)
        }
        if (e.value === true && !selected.find(c => c === e.id))
            await setSelected([
                ...selected, e.id
            ])
    }

    const onSelected = () => {
        props.onChoice(selected)
    }

    const object = Object.assign({}, ...profiles.map((k, i) => ({ [k]: occupationTypes[i] }))) // объект ключ-значение

    const empty1 = () => { }

    // console.log(object) // test
    // console.log(selected) // test

    const show = props.show
    return (
        <div className='height_350 overlay_y_scroll_on'>
            <Container className='mb_20 '>
                {show &&
                    <Alert
                        as='h6'
                        className='width_100pr text-center'
                        dismissible
                    >
                        <FilterAltOutlinedIcon color="disabled" className='pb_3' />Фильтр компаний по роду деятельности
                    </Alert>}

                <HandleAllCheckBoxesBtn
                    handleAllChecked={handleAllCheck}
                    selectCheck={selectCheck}
                />

                {showChecked &&

                    <Form.Group className=''>
                        {resultProf.map((p, idx) => (
                            <React.Fragment key={idx} >
                                <CheckFormSearch
                                    name={nanoid()}
                                    show={true}
                                    placeholder={object[p]}
                                    id={p}
                                    onChangeValue={onChange}
                                    onChangeVisionBlock={empty1}
                                    value={!selectCheck}
                                // selected={selected}
                                />

                            </React.Fragment>
                        ))}

                    </Form.Group>
                }

                <div className='display_flex container_flex_end mb_20'>
                    {props.showConfirmBtn && <Button
                        variant='primary'
                        onClick={onSelected}
                        disabled={selected.length > 0 ? false : true}
                        size='sm'
                    >
                        Применить
                    </Button>}
                </div>

            </Container>
        </div>
    )
}
