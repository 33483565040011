/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Alert, Container, Form, Row, Col } from 'react-bootstrap';
import { MECH_PRODUCTION } from '../../questionnaire/ConstProduction';
import CheckForm from '../../questionnaire/CheckForm';
import SizeFormBig from '../../questionnaire/SizeFormBig';
import RadioButtonForm from '../../questionnaire/RadioButtonForm';
// import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import { CloseBtnForFilter } from '../../assets/CloseBtnForFilter';
import Server from '../../server/server';
import { ButtonGroupForFilterForms } from '../../assets/ButtonGroupForFilterForms';
import { nanoid } from 'nanoid'

export default class Two extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            language: 'rus',
            dataValue: [],
            id: 1,
            nameForm: 'Two',
            maxId: 32,
            dataOnServer: [],
            dataFromServer: [],
            lengthDataFromServer: 0,
            base: [],
            sizes: {
                0: ["Диаметр, мм", "Длина, мм", "Масса заготовки, тн"],
                1: ["Диаметр, мм", "Высота, мм", "Масса заготовки, тн"],
                2: ["Диаметр отверстия, мм", "Длина заготовки, мм", "Ширина заготовки, мм", "Высота заготовки, мм", "Толщина заготовки, мм", "Масса заготовки, тн"],
                3: ["Длина, мм", "Высота, мм", "Ширина, мм", "Масса заготовки, тн"],
                4: ["Длина, мм", "Высота, мм", "Ширина, мм", "Масса заготовки, тн"]
            },
            show: [false, false, false, false, false],
            showRender: true,
            showQuit: false,
            showVariants: true
        }

        this.doChangeValue = this.doChangeValue.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this);
        this.renderCheckData = this.renderCheckData.bind(this);
        this.runRenderData = this.runRenderData.bind(this);
        this.doEmpty = this.doEmpty.bind(this);

        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);
        this.handleClickMaterieals = this.handleClickMaterieals.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
        this.sendProductDataOnServer = this.sendProductDataOnServer.bind(this)
    }

    async clearFields() {
        await this.setState({
            showVariants: false
        })

        this.setState({
            showVariants: true
        })

    }
    //*empty
    doEmpty() {

    }

    handleClickMaterieals() {
        this.props.onRunMaterials('Four_Two');
    }

    doChangeVisionBlock(data, id) {
        this.show = this.state.show;
        // console.log(data);//test
        // console.log(id);//test
        if (+id === 1) this.show[0] = data;
        if (+id === 6) this.show[1] = data;
        if (+id === 11) this.show[2] = data;
        if (+id === 19) this.show[3] = data;
        if (+id === 25) this.show[4] = data;
        this.setState({ show: this.show });
        setTimeout(() => { this.renderCheckData() })
    }

    //запуск выхода из формы
    handleClickShadow() {
        this.setState({ showQuit: true })
    }

    //выход с сохранением
    doQuitWithSave() {
        this.setState({ showQuit: false })
        this.handleClickSave();
        this.props.onChangeView('Two');
    }
    //выход без сохранения
    doQuitWithoutSave() {
        this.setState({ showQuit: false })
        this.props.onChangeView('Two');
    }

    //*формируем данные в массив объектов для отправки на сервер
    async handleClickSave(e) {
        e.preventDefault()
        let data = this.state.dataOnServer;

        for (let i = 0; i <= (this.state.maxId); i++) {

            //todo создаем позицию с пустым объектом
            data[i] = { id: '', fid: '', description: '', information: '', value: '' }

            //todo заполняем объект данными с сервера
            this.state.dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
            this.state.dataValue.forEach((dataE) => {
                if (+dataE.id === i) {
                    data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                }
            })

        }
        await this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере

        this.props.getChosenFiltersData(data)
        //отправляем родителю имя этой формы
        // this.props.getFormName(this.state.nameForm)
        //берем данные с сервера
        this.props.saveChosenFilters()

        const result = await Server.filterProductionCompany(data, this.state.nameForm)
        await this.props.sendSearchResultData(result)
        this.props.openResultTable()
        this.props.closeModal(e)
    }

    //* данные с полей формы, формируются в массив объектов
    doChangeValue(data) {
        console.log(data);//test
        this.setState({ dataValue: [...this.state.dataValue, data] })
        setTimeout(() => { console.log(this.state.dataValue) })//test
    }

    renderCheckData() {

        this.dataD = [];
        this.idRealy = this.state.id;
        this.sizes = this.state.sizes;
        this.show = this.state.show;

        MECH_PRODUCTION.forEach((data, index) => {
            this.dataD = [...this.dataD,
            <React.Fragment key={+this.id + index}>
                <CheckForm
                    name={nanoid()}
                    id={+this.idRealy}
                    width={4}
                    show={true}
                    placeholder={data}
                    description="Механическая обработка"
                    value={this.value[+this.idRealy] ? this.value[+this.idRealy].value : ''}
                    onChangeValue={this.doChangeValue}
                    onChangeVisionBlock={this.doChangeVisionBlock}
                    nameForm={this.state.nameForm}
                />

                {this.show[index] ?
                    <>
                        <Form.Group>
                            <Row>
                                <RadioButtonForm
                                    id={+this.idRealy + 1}
                                    width={12}
                                    show={true}
                                    title="Обработка ЧПУ? "
                                    label1="Да"
                                    label2="Нет"
                                    placeholder="Обработка ЧПУ"
                                    description="Обработка ЧПУ"
                                    value={this.value[2] ? this.value[2].value : ''}
                                    onChangeValue={this.doChangeValue}
                                    onChangeVisionBlock={this.doEmpty}
                                    nameForm={this.state.nameForm}
                                />
                            </Row>
                        </Form.Group>
                        <SizeFormBig
                            id={+this.idRealy + 2}
                            width={2}
                            fontSize={'12px'}
                            show={this.show[index]}
                            value={this.value}
                            description="Механическая обработка"
                            placeholder={this.sizes[index]}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                        />
                    </> : ''}
            </React.Fragment>
            ]
            this.idRealy = this.idRealy + 2 + this.sizes[index].length;
        })
        this.setState({ base: this.dataD });
    }

    runRenderData() {

        this.props.data.forEach(elem => {
            this.doChangeVisionBlock(elem.value, elem.id)
            // console.log(elem.value + ' ==== ' + elem.id);//test
        })
        setTimeout(() => { this.renderCheckData() })
    }

    onKeyPressHandler(event) {
        event.target.dataset.position = 1
        if (event.keyCode === 13) {
            this.handleClickSave(event)
            console.log(event);
        }
    }

    async sendProductDataOnServer() {
        const productData = {
            product: this.props.product,
            formName: this.state.nameForm
        }
        await Server.sendDataOnServer(productData, 'product', localStorage.getItem('idUser'))
    }

    async componentDidMount() {
        if (!this.props.data && this.props.data.length === 0) {
            this.handleClickSave()
        }
        await this.setState({
            dataFromServer: this.props.data,
            lengthDataFromServer: this.props.lengthDataFromServer
        });

        await this.sendProductDataOnServer()


        setTimeout(() => { this.runRenderData() }, 1000)

        document.addEventListener('keydown', this.onKeyPressHandler)
    }

    async componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPressHandler)
    }


    render() {
        // console.log('RENDER');//test

        this.id = this.state.id;
        const view = this.props.view;
        this.value = this.props.data;
        const viewQuest = this.props.viewQuest;

        return (
            <>
                <div className="modal_window" style={{ display: view ? 'block' : 'none' }} >
                    <div className={viewQuest ? "shadow_form" : "shadow_form_off"} onClick={this.props.closeModal}></div>
                    <Form className={this.props.chosenCompaniesFromProductionSearch && this.props.chosenCompaniesFromProductionSearch.length >= 0 ? "form_main" : "form_main_1"}  >
                        <Alert
                            variant="dark"
                            className="filter_alert"
                        >
                            <Alert.Heading >Механическая обработка </Alert.Heading>
                            <CloseBtnForFilter dataId={1} closeModal={this.props.closeModal} />
                        </Alert>
                        <div>
                             <Container className="form_container">
                            <Row>
                                <Col>
                                    Максимальные размеры обрабатываемой детали:
                                </Col>
                            </Row>

                            <Form.Group>
                                {this.state.showVariants && <>{this.state.showRender ? this.state.base : ''}</>}
                            </Form.Group>

                            <Row>&nbsp;</Row>
                            <Row>&nbsp;</Row>

                        </Container>

                            <ButtonGroupForFilterForms
                                dataId={1}
                                handleClickSave={this.handleClickSave}
                                clearFields={this.clearFields}
                                savedFiltersData={this.props.savedFiltersData}
                            />
                        </div>
                       
                    </Form>
                </div>

            </>
        )
    }
}