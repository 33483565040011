import React, { Component } from 'react'
import { Table, Card, Badge, Spinner } from 'react-bootstrap'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'
// import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import QuitModal from '../QuitModal'
import LoaderPage from '../assets/loaderPage'

export class EmployeesList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
    this.handleClickTable = this.handleClickTable.bind(this)
    this.onKeyPressHandler = this.onKeyPressHandler.bind(this)
    this.setLoading = this.setLoading.bind(this)
  }

  handleClickTable(e) {
    this.props.onDataIdSend(e.target.dataset.id)
  }

  setLoading() {
    this.setState({
      loading: true
    })

    setTimeout(() => {
      this.setState({
        loading: false
      })
    }, 1000)
  }

  onKeyPressHandler(event) {
    if (event.keyCode === 13) {
      this.props.removeEmployee()
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyPressHandler)
    this.setLoading()
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyPressHandler)
  }

  render() {
    return (
      <div>
        {this.state.loading ? (
          <LoaderPage w='100%' minH='100vh'/>
        ) : (
          <>
            {' '}
            <Card.Header className='mt_10' as='h5'>
              {' '}
              Список сотрудников
            </Card.Header>
            <Table bordered hover size='sm' className='employees_table_list'>
              <thead>
                <tr>
                  <th className='fw_bolder' style={{ width: '30%' }}>
                    <span>ФИО</span>{' '}
                  </th>
                  <th className='fw_bolder' style={{ width: '20%' }}>
                    <span>Телефон</span>{' '}
                  </th>
                  <th className='employee_table_header_email fw_bolder' style={{ width: '20%' }}>
                    <span>Email</span>{' '}
                  </th>

                  <th className='employee_table_header_position fw_bolder' style={{ width: '15%' }}>
                    <span>Должность</span>{' '}
                  </th>

                  <th className='fw_bolder' style={{ width: '15%' }}>
                    <span>Доступ</span>{' '}
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.props.employeesArray.map((employee, index) => {
                  // console.log(employee);
                  return (
                    <React.Fragment key={index}>
                      <tr key={employee.id} className='cursor_pointer'>
                        <td
                          className='employee_name'
                          data-id={employee.id}
                          onClick={this.handleClickTable}
                        >
                          {employee.surname} {employee.name} {employee.thirdName}
                        </td>
                        <td
                          className='employee_tel'
                          data-id={employee.id}
                          onClick={this.handleClickTable}
                        >
                          {employee.tel}
                        </td>
                        <td
                          className='employee_email'
                          data-id={employee.id}
                          onClick={this.handleClickTable}
                        >
                          {employee.email}
                        </td>
                        <td
                          className='employee_position'
                          data-id={employee.id}
                          onClick={this.handleClickTable}
                        >
                          {employee.position}
                        </td>

                        <td
                          className='employee_access'
                          data-id={employee.id}
                          onClick={this.handleClickTable}
                        >
                          {employee.access &&
                            employee.access.map((a) => {
                              return (
                                <>
                                  <Badge
                                    pill
                                    variant='info'
                                    data-id={employee.id}
                                    onClick={this.handleClickTable}
                                  >
                                    {' '}
                                    {a}{' '}
                                  </Badge>{' '}
                                </>
                              )
                            })}
                          {!employee.access && (
                            <>
                              <Badge
                                pill
                                variant='secondary'
                                data-id={employee.id}
                                onClick={this.handleClickTable}
                              >
                                Вид доступа не указан
                              </Badge>{' '}
                            </>
                          )}
                        </td>

                        <td
                          className='width_50'
                          onClick={() => this.props.openConfirmationModal(employee)}
                        >
                          <button className='btn_delete'>
                            <DeleteForeverOutlinedIcon sx={{ color: '#ED4880' }} />
                          </button>
                        </td>

                        {this.props.alertId === employee.id && (
                          <QuitModal
                            onQuitWithSave={this.props.removeEmployee}
                            onQuitWithoutSave={this.props.rejectDelete}
                            closeQuitModal={this.props.rejectDelete}
                            modalTitle={'Удаление'}
                            modalBody={
                              <p>
                                Вы действительно хотите удалить{' '}
                                <b>
                                  {employee.surname} {employee.name} {employee.thirdName}
                                </b>{' '}
                                ?
                              </p>
                            }
                            primaryButton={'Удалить'}
                            secondaryButton='Отмена'
                            icon={
                              <>
                                <DeleteForeverOutlinedIcon sx={{ color: '#ED4880' }} />{' '}
                              </>
                            }
                          />
                        )}
                      </tr>
                    </React.Fragment>
                  )
                })}
              </tbody>
            </Table>
          </>
        )}
      </div>
    )
  }
}
