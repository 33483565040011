import React from 'react';
import Main from '../Main';
import NaviBar from './NaviBar';
import { loadImageURL } from '../settings/loadImageURL';

export default class RightMenuBlock extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            viewQuest: true,
            pointPosition: [],
            infoBlock: {},
            showSettings: false, // вкл окна настроек
            changeLogo: 0,
            icon: '',
        }

        this.doChangeShow = this.doChangeShow.bind(this);
        this.doUpInfoBlock = this.doUpInfoBlock.bind(this);
        this.doCreateTrade = this.doCreateTrade.bind(this);
        this.doChangeViewQuest = this.doChangeViewQuest.bind(this);
        this.getPositionButtons = this.getPositionButtons.bind(this);
        this.doChangeURL = this.doChangeURL.bind(this);
        this.doClickSettings = this.doClickSettings.bind(this);
        this.doChangeLogo = this.doChangeLogo.bind(this);
        // this.openSettings = this.openSettings.bind(this)
    }



    async doChangeLogo() {
        this.setState({ icon: await loadImageURL(`icon_logo`) }) // возвращает URL строку для icon

    }

    // todo: вкл / выкл модалки настроек ***************
    doClickSettings() {
        this.setState({ showSettings: !this.state.showSettings })
    }

    doChangeURL() {
        this.props.onChangeURL()
    }

    //* меняем вид анкеты viewQuest
    doChangeViewQuest(data) {
        this.setState({ viewQuest: data })
    }

    //* получаем позицию кнопок top
    getPositionButtons(element) {
        this.point = [];

        // console.log(element);//test
        if (element)
            element.forEach(data => {
                let elem = document.getElementsByName(data);
                if (elem)
                    this.point = [...this.point, elem[0].offsetTop];
            })
        // console.log(this.point);//test
        this.setState({ pointPosition: this.point })
        // setTimeout(() => { console.log(this.state.pointPosition); })

        // //* получение координат кнопки Main        
        //     let elem = document.getElementsByName("Main");
        //     // console.log(elem.offsetTop);
        //     console.log(elem[0].offsetTop + ' < top Main');
        //     console.log(elem[0].offsetLeft + ' < left Main');        
    }

    doCreateTrade(data) {
        this.props.onCreateTrade(data);
    }

    doUpInfoBlock(data) {
        this.props.onUpInfoBlock(data);
        // console.log(`INFOBLOCK !!!!!!`); // test
    }

    doChangeShow() {
        this.props.onChangeShow();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.openSettings !== this.props.openSettings) {
            this.doClickSettings()
        }

    }

    async componentDidMount() {
        this.setState({ infoBlock: this.props.infoBlock })
        await this.doChangeLogo();
    }




    render() {


        return (
            <>
                <div className="right-menu-block">
                    <NaviBar
                        nameCompany={this.props.nameCompany}
                        onChangeShow={this.doChangeShow}
                        show={this.props.show}
                        onChangeViewQuest={this.doChangeViewQuest}
                        onStartPersonalArea={this.props.onStartPersonalArea}
                        onClickSettings={this.doClickSettings}
                        changeLogoURL={this.state.icon}
                        chatMessage={this.props.chatMessage}
                        onUpInfoBlock={this.doUpInfoBlock}
                        viewQuest={this.state.viewQuest}
                        infoBlock={this.props.infoBlock}
                        updateQuantityMessage={this.props.updateQuantityMessage}
                    />

                    <Main
                        urlPosition={this.props.urlPosition} // позиция для перехода
                        closeAdminPanel={this.props.closeAdminPanel}
                        infoBlock={this.props.infoBlock}
                        onUpInfoBlock={this.doUpInfoBlock}
                        onCreateTrade={this.doCreateTrade}
                        keyNameTrade={this.props.keyNameTrade}
                        keyOtherNumberTrade={this.props.keyOtherNumberTrade}
                        viewQuest={this.state.viewQuest}
                        onChangeURL={this.doChangeURL}
                        viewShowSettings={this.state.showSettings}
                        // onShow={() => { this.setState({ showSettings: !this.state.showSettings }) }}
                        onShow={this.doClickSettings}
                        onChangeLogo={this.doChangeLogo}
                        setNewIcon={this.props.setNewIcon}
                    // openSettings={this.doClickSettings}

                    />
                </div>
            </>
        )
    }
}