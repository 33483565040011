import { nanoid } from 'nanoid';
import Server from '../../server/server'

/**
 * Сохраняем карточку аппарата и обновляем реестр аппаратов
 * @param {Object} card - Карточка аппарата
 * @param {Array} registry - Массив объектов, реестр аппаратов
 */
const addCardToRegistry = async (card, registry) => {
    try {
        const id = nanoid()
        const idCard = `card_${id}`
        const idDocument = `document_${id}`
        const idRegistration = `registration_${id}`
        const idWorkControl = `workControl_${id}`
        const dateCard = Date.now()

        Object.assign(card, { id: id })
        await Server.createDataOnServer(card, idCard, localStorage.getItem('idUser'))

        const data = {
            id: id,
            idCard: idCard,
            idDocument: idDocument,
            idRegistration: idRegistration,
            idWorkControl: idWorkControl,
            dateCard: dateCard,
        }
        registry.push(data)

        await Server.sendDataOnServer(registry, 'registry', localStorage.getItem('idUser'))

    } catch (err) {
        console.log(`Ошибка при добавлении карточки аппарата в реестр: `, err);
    }

}
export default addCardToRegistry;