import React, { useEffect, useState } from 'react'
import { Alert, Col, Container, Form, Row } from 'react-bootstrap'
import { SaveCurrentDataButton } from '../../assets/BasicButtons'

export default function RostechRegistrationEdit({
  toggleModal,
  alertHeading,
  id,
  saveRostech,
  getRostechDataFromServer,
  savedData
}) {
  const registrationObject = {
    id,
    registrationDate: '',
    serviceLife: '',
    endOfServiceLifeDate: '',
    prolongationOfServiceLife: '',
    endOfProlongation: ''
  }

  const [regData, setRegData] = useState(savedData ? savedData : registrationObject)
  const [messageSent, setMessageSent] = useState(false)

  const flashSaveButtonMessage = () => {
    setMessageSent(true)
    setTimeout(() => {
      setMessageSent(false)
    }, 1500)
  }



  useEffect(() => {
    setRegData(savedData ? savedData : registrationObject)
  }, [savedData])

  const currentId = `registration_${id}`
  console.log(currentId)

  const handlChange = (e, fieldName) => {
    setRegData({
      ...regData,
      [fieldName]: e.target.value
    })
  }

  /**
   * Сохранение данных на сервере
   */
  const handleSave = () => {
    flashSaveButtonMessage()
    const newData = {
      ...regData
    }
    saveRostech(newData)
  }

  useEffect(() => {
    getRostechDataFromServer(currentId)
  }, [currentId])

  console.log(savedData)

  return (
    <>
      <div className='shadow_form' onClick={toggleModal}></div>
      <div className='modal_common_0 z_index_1000'>
        <Alert variant='dark' onClose={toggleModal} dismissible>
          <Alert.Heading>{alertHeading}</Alert.Heading>
        </Alert>
        <Container style={{ height: '380px', overflowY: 'auto', padding: '10px 40px' }}>
          <Form style={{ fontWeight: 'bolder' }}>
            <Form.Group>
              <Row>
                <Col>
                  <Form.Label>1. Дата регестрации в Ростехнадзоре:</Form.Label>
                </Col>
                <Col>
                  {' '}
                  <Form.Control
                    type='date'
                    value={regData.registrationDate}
                    onChange={(e) => handlChange(e, 'registrationDate')}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col>
                  <Form.Label>2. Срок службы аппарата:</Form.Label>
                </Col>
                <Col>
                  {' '}
                  <Form.Control
                    type='number'
                    placeholder='Срок службы аппарата'
                    value={regData.serviceLife}
                    onChange={(e) => handlChange(e, 'serviceLife')}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col>
                  <Form.Label>3. Дата окончания срока службы:</Form.Label>
                </Col>
                <Col>
                  {' '}
                  <Form.Control
                    type='date'
                    value={regData.endOfServiceLifeDate}
                    onChange={(e) => handlChange(e, 'endOfServiceLifeDate')}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col>
                  <Form.Label>4. Срок продления службы аппарата:</Form.Label>
                </Col>
                <Col>
                  {' '}
                  <Form.Control
                    type='number'
                    value={regData.prolongationOfServiceLife}
                    onChange={(e) => handlChange(e, 'prolongationOfServiceLife')}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Form.Group>
              <Row>
                <Col>
                  <Form.Label>5. Дата окончания продленного срока службы:</Form.Label>
                </Col>
                <Col>
                  {' '}
                  <Form.Control
                    type='date'
                    value={regData.endOfProlongation}
                    onChange={(e) => handlChange(e, 'endOfProlongation')}
                  />
                </Col>
              </Row>
            </Form.Group>

            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <SaveCurrentDataButton
                handleSaveClick={handleSave}
                needDisable={false}
                savedDataMessage={messageSent}
                buttonTextDefault={'Сохранить'}
                buttonTextSaved={'Данные сохранены'}
              />
            </div>
          </Form>
        </Container>
      </div>
    </>
  )
}
