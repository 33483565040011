import React from 'react'


/**
 * Рисует модалку по HOVER, над таблицей - реестром аппратов
 * @param {*} param0 
 * @returns 
 */
const ModalHoverMouse = ({ equipmentObject, coordinates }) => {
    let { pageX, pageY } = coordinates
    pageX = pageX + 15
    pageY = pageY + 15

    if (pageX > 1000) {
        pageX = pageX - 275;
    }

    if (pageY > 450) {
        pageY = pageY - 235;
    }

    return (
        <>
            <div
                style={{
                    width: '250px',
                    minHeight: '200px',
                    position: 'absolute',
                    top: pageY,
                    left: pageX,
                    zIndex: '99999999',
                    backgroundColor: '#FFD65E',
                    borderRadius: '10px',
                    padding: '10px',
                    border: '1px solid #ccc',
                }}
            >
                <div> Заказчик: </div>
                <div> <b>{equipmentObject.consumerEnterpriseName} </b> </div>
                <div> Производитель: </div>
                <div> <b>{equipmentObject.manufacturerName} </b> </div>
                <div> Трубное пространство: </div>
                <div> <b>{equipmentObject.tubeEnvironmentName} </b> </div>
                <div> Межтрубное пространство: </div>
                <div> <b>{equipmentObject.intertubeEnvironmentName} </b> </div>
            </div>
        </>
    )
}

export default ModalHoverMouse;
