import { nanoid } from 'nanoid'
import React from 'react'
import { Button, Form, Modal } from 'react-bootstrap'

// radioResult={value for each radio variant; string} 
// formName={name of form which renders this component; string} 
// handleOnChange={onChange fn for input with type 'radio-button'; fn} 
// handleClick={fn for btn which will do a transfer to lot creation; fn}

export default function TransitionToLot({radioResult, formName, handleOnChange, handleClick}) {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      <div
        style={{
          width: '250px',
          border: '1px #ccc solid',
          borderRadius: '5px',
          marginBottom: '20px',
          backgroundColor: '#fff'
        }}
      >
        <Modal.Header>
          <Modal.Title as='h6'>Сформировать лот с выбранными компаниями?</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form.Check 
            style={{ paddingBottom: '8px', paddingTop: '20px' }} 
            id={`${nanoid()}_`} 
            type='radio' 
            label={'На закуп'}
            name={formName} 
            onChange={handleOnChange} 
            value={radioResult} 
          />
          <Form.Check 
            style={{ paddingBottom: '12px' }} 
            id={`${nanoid()}_`} type='radio' 
            label={'На опрос рынка'} 
            name={formName} 
            onChange={handleOnChange} 
            value={radioResult} 
          />
        </Modal.Body>

        <Modal.Footer>
          <Button disabled={radioResult ? false : true} onClick={handleClick} size='sm'>
            Запустить
          </Button>
        </Modal.Footer>
      </div>
    </div>
  )
}
