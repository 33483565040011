/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Container, Form, Row } from 'react-bootstrap';
import RadioButtonForm from './RadioButtonForm';
import ModalQuit from '../ModalQuit';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import CommonAlert from './CommonAlert';
import AllModalsFooter from '../assets/AllModalsFooter';
import { SaveCurrentDataButton } from '../assets/BasicButtons';
import { isEqual } from 'lodash-es';
import { createData } from './functionsForm';


export default class Form_Twelve extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            language: 'rus',
            dataValue: [],
            id: 1,
            maxId: 1,
            nameForm: 'Twelve',
            dataOnServer: [],
            dataFromServer: [],
            lengthDataFromServer: 0,
            base: [],
            show: false,
            showQuit: false,
            showContent: true,
            previousState: [],
            savedDataMessage: false,
            dataBeenSaved: false
        }

        this.doChangeValue = this.doChangeValue.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this);
        this.doEmpty = this.doEmpty.bind(this);

        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);
        this.closeQuitModal = this.closeQuitModal.bind(this);
        this.hideContent = this.hideContent.bind(this);
        this.setCompanyName = this.setCompanyName.bind(this);
        this.compare = this.compare.bind(this)
        this.smartWindowFunction = this.smartWindowFunction.bind(this)
        this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
    }

    saveDataWithNotification(){
        this.handleClickSave()
    
        this.setState({
          savedDataMessage: true,
          dataBeenSaved: true
        })
        setTimeout(() => {
          this.setState({
            savedDataMessage: false
          })
        }, 2000)
      }

    hideContent() {
        this.setState({ showContent: false })
    }


    setCompanyName() {
        if (this.props.companyName && this.props.companyName.length > 0) {
            this.setState({
                companyName: this.props.companyName
            })
        }
    }


    closeQuitModal() {
        this.setState({
            showQuit: false
        })
    }

    //*Пустышка
    doEmpty() {

    }

    //* меняем видимость блока при выборе ДА / НЕТ
    doChangeVisionBlock(data) {

    }


    //запуск выхода из формы
    handleClickShadow() {
        this.setState({ showQuit: true })
    }

    //выход с сохранением
    doQuitWithSave() {
        this.setState({ showQuit: false })
        this.handleClickSave();
        this.props.onChangeView('Twelve');
    }
    //выход без сохранения
    doQuitWithoutSave() {
        this.setState({ showQuit: false })
        this.props.onChangeView('Twelve');
    }

    //*формируем данные в массив объектов для отправки на сервер
    handleClickSave() {
        let data = this.state.dataOnServer;

        for (let i = 0; i <= (this.state.maxId); i++) {

            //todo создаем позицию с пустым объектом
            data[i] = { id: '', fid: '', description: '', information: '', value: '' }

            //todo заполняем объект данными с сервера
            this.state.dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
            this.state.dataValue.forEach((dataE) => {
                if (+dataE.id === i) {
                    data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                }
            })
        }
        this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере
        if (this.state.lengthDataFromServer < 1) {
            console.log('CREATE DATA');
            this.props.onCreateDataServer(data, 'Twelve', localStorage.getItem('idUser'));
        } else {
            console.log('UPDATE DATA');
            this.props.onUpdateDataOnServer(data, 'Twelve', localStorage.getItem('idUser'));
        }
        setTimeout(() => { console.log(this.state.dataOnServer) })//test
        // this.handleClickShadow();
    }

    //* данные с полей формы, формируются в массив объектов
    doChangeValue(data) {
        console.log(data);//test
        this.setState({ dataValue: [...this.state.dataValue, data] })
        setTimeout(() => { console.log(this.state.dataValue) })//test

        this.setState({
            dataBeenSaved: false
        })
    }

    compare() {
        const prev = this.state.previousState
        const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)
    
        const isStateEqual = isEqual(prev, curr)
        if (isStateEqual) {
          return true
        }
        return false
      }
    
      smartWindowFunction() {
        const isStateEqual = this.compare()
    
        if (isStateEqual || this.state.dataBeenSaved || this.state.dataValue.length === 0) {
            this.props.onChangeView('Twelve');
        } else {
          this.handleClickShadow()
        }
      }


    componentDidMount() {
        setTimeout(() => {
            this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer });
            // console.log('DATA on dataFromServer');
            // console.log(this.props.data);
        }, 1000);
        this.setCompanyName()

        if (this.props.data.length) {
            const previousState = JSON.parse(JSON.stringify(this.props.data))
            this.setState({ previousState })
          }
    }

    render() {

        const view = this.props.view;
        this.value = this.props.data;
        const viewQuest = this.props.viewQuest;

        // console.log(this.state.dataValue);


        //условие по которому компонент определяет находится ли он в формате чтения
        const inReadMode = this.props.innFromUniversalTable === null ||
            this.props.innFromUniversalTable === '' ||
            this.props.innFromUniversalTable === undefined

        return (
            <>
                {inReadMode &&
                    <>{this.state.showQuit ?
                        <ModalQuit
                            onQuitWithSave={this.doQuitWithSave}
                            onQuitWithoutSave={this.doQuitWithoutSave}
                            closeQuitModal={this.closeQuitModal}
                        />
                        : ''}</>}

                {this.state.showContent &&
                    <div className="modal_window" style={{ display: view ? 'block' : 'none' }} >
                        <div className={viewQuest ? "shadow_form" : "shadow_form_off"} 
                        // onClick={this.handleClickShadow}
                        onClick={this.smartWindowFunction}
                        ></div>
                        <Form className={viewQuest ? "form_main" : "form_main_view"} >

                            <CommonAlert
                                inReadMode={inReadMode}
                                handleClickHeader={this.handleClickHeader}
                                // handleClickShadow={this.handleClickShadow}
                                handleClickShadow={this.smartWindowFunction}
                                hideContent={this.hideContent}
                                icon={<BorderColorOutlinedIcon fontSize="large" className='icon_primary' />}
                                companyName={this.state.companyName}
                                sectionName='Система технологического контроля'

                            />
                            <div>
                                <Container className="form_container">
                                    <Form.Group>
                                        <Row>
                                            <RadioButtonForm
                                                id={1}
                                                width={12}
                                                show={true}
                                                title={'Наличие системы технологического контроля изготовления оборудования, начиная от входного контроля материалов до момента отгрузки готового изделия: '}
                                                label1="Да"
                                                label2="Нет"
                                                placeholder="наличие системы технологического контроля"
                                                description="Система технологического контроля"
                                                value={this.value[1] ? this.value[1].value : ''}
                                                onChangeValue={this.doChangeValue}
                                                onChangeVisionBlock={this.doChangeVisionBlock}
                                                nameForm={this.state.nameForm}
                                                readOnly={!inReadMode}
                                            />
                                        </Row>

                                    </Form.Group>
                                </Container>
                                <AllModalsFooter
                                    footerContent={inReadMode &&
                                    //     <SaveButtonGreen
                                    //     handleSaveClick={this.handleClickSave}
                                    //     needDisable={false}
                                    //     saveButtonText='Сохранить'
                                    //   />
                                    <SaveCurrentDataButton
                                    handleSaveClick={this.saveDataWithNotification}
                                    needDisable={false}
                                    savedDataMessage={this.state.savedDataMessage}
                                    buttonTextDefault={'Сохранить'}
                                    buttonTextSaved={'Данные сохранены'}
                                    needCustomIcon={false}
                                    
                                    />
                  
                                        // <Button variant="success" onClick={this.handleClickSave}>Сохранить </Button>
                                    }
                                />
                            </div>

                        </Form>
                    </div>}


            </>
        )
    }
}