
/**
 * Динамически сравниваем название торг.позиции
 * @param {String} name вводимое название
 * @param {Array} dataTrade массив объектов торговых позиций
 * @returns 
 */
const checkTradeName = async (name, dataTrade) => {
    try {
        const result = dataTrade.find(item => item.nameTrade === name)

        if (result) {
            console.log(`Такое название торговой позиции уже есть!`);
            return true
        }
        return false

    } catch (err) {
        console.log(`Ошика при проверке торгового названия: `, err);
        return null
    }
}

export default checkTradeName;