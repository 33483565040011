import React from "react";
import { Form, Row, Col, Image } from 'react-bootstrap';
import { ACCEPT_IMAGE } from '../questionnaire/ConstAccept';
import { loadImageURL } from './loadImageURL';
import { appendDataForm } from './appendDataForm';

/**
 * onChangeLogo - используется только для динамичной смены лого в NaviBar
 */
export default class LoadIcons extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: true,
            newNameFile: '',
            fileData: '',
            oldNameFile: '',
            typeFile: '',
            ext: '',
            size: 0,
            choiceFile: true,
            icon: '',
            avatar: '',
        }
        this.onChangeIconLogo = this.onChangeIconLogo.bind(this);
        this.onChangeIconAvatar = this.onChangeIconAvatar.bind(this);
    }

    onChangeIconLogo(e) {
        (async () => {
            try {
                this.file = e.target.files[0]; // из массива один файл, объект
                // console.log(`ICON:::: `, this.file); // test

                // const extArr = this.file.name.split('.');
                // const ext = extArr[extArr.length - 1]
                const ext = 'jpg'

                this.setState({
                    fileData: this.file,
                    oldNameFile: this.file.name,
                    typeFile: this.file.type,
                    size: this.file.size,
                    ext: ext,
                    show: false,
                    choiceFile: true,
                });

                // TODO: новое имя файла, уникальное
                const newNameFile = `${localStorage.getItem('idUser')}_icon_logo.${ext}`;
                this.setState({
                    newNameFile: newNameFile,
                })

                // console.log(`new name file:: `, newNameFile); // test
                const result = await appendDataForm(this.file, newNameFile);

                if (result.ERROR) throw new Error(result.message)

                // console.log(`RESULT SAVE ICON ON SERVER:::::::: `, result); // test
                this.setState({ icon: await loadImageURL(`icon_logo`) })
                this.props.onChangeLogo();


                this.props.setNewIcon(this.state.icon)
                // console.log(this.state.icon);

            } catch (err) {
                console.log(`Ошибка при обработке ИКОНКИ (LoadIcons.jsx): `, err);
            }
        })();
    }

    onChangeIconAvatar(e) {
        (async () => {
            try {
                this.file = e.target.files[0]; // из массива один файл, объект
                // console.log(`ICON:::: `, this.file); // test

                // const extArr = this.file.name.split('.');
                // const ext = extArr[extArr.length - 1]
                const ext = 'jpg'

                this.setState({
                    fileData: this.file,
                    oldNameFile: this.file.name,
                    typeFile: this.file.type,
                    size: this.file.size,
                    ext: ext,
                    show: false,
                    choiceFile: true,
                });

                // TODO: новое имя файла, уникальное - sessionStorage.setItem('login', result.login)
                // const newNameFile = `${localStorage.getItem('idUser')}_icon_avatar.${ext}`;
                const newNameFile = `${sessionStorage.getItem('login')}_icon_avatar.${ext}`;
                this.setState({
                    newNameFile: newNameFile,
                })

                // console.log(`new name file:: `, newNameFile); // test
                const result = await appendDataForm(this.file, newNameFile);

                if (result.ERROR) throw new Error(result.message)

                // console.log(`RESULT SAVE ICON ON SERVER:::::::: `, result); // test
                this.setState({ avatar: await loadImageURL(`icon_avatar`, sessionStorage.getItem('login')) })

            } catch (err) {
                console.log(`Ошибка при обработке ИКОНКИ AVATAR (LoadIcons.jsx): `, err);
            }
        })();
    }

    async componentDidMount() {
        this.setState({ icon: await loadImageURL(`icon_logo`) }) // возвращает URL строку для icon
        this.setState({ avatar: await loadImageURL(`icon_avatar`, sessionStorage.getItem('login')) }) // возвращает URL строку для AVATAR

        setTimeout(() => { console.log(`icon::: ${this.state.icon}   avatar::: ${this.state.avatar}`) }) // test
    }

    render() {
        const index = 0;


        return (
            <>
                <Form.Group>
                    <div style={{ padding: '20px', border: '#eee solid 1px', borderRadius: '5px' }}>
                        <Row >
                            <Col>
                                Логотип компании (.jpg)
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form action="/file/file_icon" method="post" encType="multipart/form-data">
                                    <Form.Control
                                        id={index}
                                        onChange={this.onChangeIconLogo}
                                        accept={ACCEPT_IMAGE}
                                        data-id={index}
                                        data-fid={`fid_${index}`}
                                        type="file"
                                        name="file"
                                        style={{ margin: '30px 0 0 0' }}
                                    />
                                </Form>
                            </Col>
                            <Col>
                                <Image src={this.state.icon} style={{ width: '100px', height: '100px', margin: '-20px 0 0 0' }} roundedCircle />
                            </Col>
                        </Row>
                    </div>
                </Form.Group>
                <Row> &nbsp; </Row>
                <Form.Group>
                    <div style={{ padding: '20px', border: '#eee solid 1px', borderRadius: '5px' }}>
                        <Row>
                            <Col>
                                Фото представителя (.jpg)
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form action="/file/file_icon" method="post" encType="multipart/form-data">
                                    <Form.Control
                                        id={index}
                                        onChange={this.onChangeIconAvatar}
                                        accept={ACCEPT_IMAGE}
                                        data-id={index}
                                        data-fid={`fid_${index}`}
                                        type="file"
                                        name="file"
                                        style={{ margin: '30px 0 0 0' }}
                                    />
                                </Form>
                            </Col>
                            <Col>
                                <Image src={this.state.avatar} style={{ width: '100px', height: '100px', margin: '-20px 0 0 0' }} rounded />
                            </Col>
                        </Row>
                    </div>
                </Form.Group>
            </>
        )
    }
}