export const DOWN_MECH_PROD = [
    'Машиностроительный завод, выпускающий сосуды и аппараты, работающие под давлением, под наливом и вакуумом',
    'next 1',
    'next 2'
];

export const TANK_WITHOUT_MECH = [
    'Емкости дренажные',
    'Ресиверы',
    "Емкости подземные дренажные",
    "Аппараты емкостные вертикальные для жидкостей",
    "Аппараты емкостные вертикальные для газовых",
    "Сосуды цилиндрические горизонтальные",
    "Горизонтальные цельносварные аппараты с эллиптическими днищами",
    "Вертикальные цельносварные аппараты с эллиптическими днищами",
    "Вертикальные цельносварные аппараты с плоскими днищами",
    "Вертикальные цельносварные аппараты с нижними коническим неотбортованным и верхним плоским днищами",
    "Горизонтальные цельносварные аппараты с коническими неотбортованным днищами",
    "Вертикальные цельносварные аппараты с нижними коническим отбортованным и верхним плоским днищами",
    "Вертикальные цельносварные аппараты с нижними коническим неотбортованным и верхним эллиптическим днищами",
    "Сборники вертикальные с эллиптическими днищами и плоской съемной крышкой",
    "Сборники вертикальные с эллиптическими днищами и плоской съемной крышкой и приварной рубашкой",
    "Сборники горизонтальные с эллиптическим днищем, съемной эллиптической крышкой",
    "Вертикальные аппараты с эллиптическим днищем и съемной крышкой, гуммированные",
    "Горизонтальные аппараты с эллиптическим днищем и съемной крышкой, гуммированные",
    "Вертикальные аппараты с плоским днищем и съемной крышкой, гуммированные"
];

export const TANK_WITH_MECH = [
    'Сепараторы',
    'Фильтры промышленные',
    "Вертикальные цельносварные аппараты с нижними коническими неотбортованным и верхним плоским днищами со змеевиком",
    "Сепараторы нефтегазовые",
    "Сепараторы нефтегазовые со сбросом воды",
    "Сепаратор-каплеуловитель",
    "Нефтегазоводоразделитель",
    "Сепаратор трехфазный",
    "Газосепараторы сетчатые",
    "Сепаратор центробежный вертикальный",
    "Сепаратор факельный",
    "Сепараторы факельные с накоплением жидкости",
    "Газосепаратор факельный",
    "Электродегидратор",
    "Отстойник воды",
    "Отстойник пескоотделитель",
    "Отстойник нефти"
];

export const TANK_WITH_MIXER = [
    'Реакторы',
    "Аппараты с эллиптическим днищем и съемной эллиптической крышкой",
    "Аппараты с эллиптическим днищем и съемной эллиптической крышкой с гладкой приварной рубашкой",
    "Аппараты цельносварные с эллиптическим днищем и крышкой",
    "Аппараты цельносварные с эллиптическим днищем и крышкой с гладкой приварной рубашкой",
    "Аппараты цельносварные с эллиптическим днищем и крышкой с рубашкой из полутруб",
    "Аппараты с плоским днищем и съемной плоской крышкой",
    "Аппараты с плоским днищем и крышкой",
    "Вертикальные аппараты с эллиптическим днищем и съемной крышкой, гуммированные, с перемешивающим устройством",
    "Вертикальные аппараты с плоским днищем и съемной крышкой, гуммированные, с перемешивающим устройством"
];

export const TANK_SAVE = [
    "Сосуды подземные для хранения",
    "Резервуары",
    "Подземный резервуары для нефтепродуктов",
    "Наземный резервуары для нефтепродуктов",
    "Резервуары двустенные однокамерные"
];

export const TANK_ROLL = [
    "Резервуар вертикальный стальной со стационарной крышей без понтона",
    "Резервуар вертикальный стальной со стационарной крышей с понтоном",
    "Резервуар вертикальный стальной с плавающей крышей"
]

export const TANK_BALL = [
    'Газгольдеры',
    "Шаровые газгольдеры",
    "Шаровые резервуары",
    "Шаровые регенерационные цистерны"
];
export const TANK_TERMO = [
    'Кожухотрубчатый  теплообменный аппарат с неподвижными трубными решетками',
    'Кожухотрубчатый  теплообменный аппарат с неподвижными трубными решетками и температурным компенсатором на кожухе',
    'Кожухотрубчатый  теплообменный аппарат с плавающей головкой',
    'Кожухотрубчатый  теплообменный аппарат с плавающей головкой и компенсатором на ней',
    'Кожухотрубчатый  теплообменный аппарат с U- образными трубами',
    'Кожухотрубчатый  теплообменный аппарат с паровым пространством (ребойлер)',
    'Теплообменник',
    'Холодильник',
    'Конденсатор',
    'Испаритель',
    'Вакуумный конденсатор',
    'Термосифонный испаритель',
    'Испаритель с паровым пространством',
    'Дефлегматор',
    'Теплообменники «труба в трубе»'
]