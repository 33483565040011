import React from 'react'
import { Accordion, Card } from 'react-bootstrap'
// import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Server from '../server/server'
import { FORM_ } from './ConstListCompany'
import QuestionnaireButton from './QuestionnaireButton'
// import SvgAngleDown from '../../icon/Svg_Angle_down'
// import SvgAngleLeft from '../../icon/Svg_Angle_left'
import ArrowsAnimation from '../assets/ArrowsAnimation'
import ModalError from '../ModalError'

export default class ProductionAccordion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      show: true,
      show_errorRequest: false,
      dataForm: [],
      base: [],
      inReadMode: this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined,
      percent: null,
      dataArray: [],
      accordArray: [],
      arrResult: [],
      arrLength: new Array(32).fill(false)
    }
    this.renderAccordion = this.renderAccordion.bind(this)
    this.handleClickView = this.handleClickView.bind(this)
    this.checkProcent = this.checkProcent.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(idx) {
    const newArrLength = [...this.state.arrLength];
    newArrLength[idx] = !newArrLength[idx];
    this.setState({
      arrLength: newArrLength
    })

    // this.setState({
    //   arrLength: [...this.state.arrLength].map((val, i) => (i === idx ? !val : val))
    // })
  }

  handleClickView(e) {
    this.props.onClickView(e)
  }

  async checkProcent(arrButton, bool) {
    try {
      const filling = await Server.fillingVolumeForms(arrButton)
      let sum = 0

      if (filling !== null) {
        const arrPr = filling.map((data) => {
          if (data) {
            return Object.values(data)
          } else {
            return [0]
          }
        })

        arrPr.forEach((data) => {
          sum = sum + Number(data[0])
        })
      }

      if (sum > 0 && bool) {
        return true
      } else {
        return false
      }
    } catch (err) {
      if (Server.errorRequest()) {
        console.log(`ERROR AUTH -> ProductionAccordion`);
        this.setState({ show_errorRequest: true })
        setTimeout(() => { this.setState({ show_errorRequest: false }) }, 3000)
      }
      console.log(`ERROR: `, err);
    }

  }



  async renderAccordion(data) {
    this.renderData = []
    if (data && data.ERROR !== 'Ошибка GET запроса!!!') {
      const resultCheck = await data.map((mem, inx) => {
        return this.checkProcent(FORM_[inx], mem.value)
      })
      Promise.all(resultCheck)
        .then((result) => {
          this.setState({ arrResult: result })
        })

        .then(() => {
          data.forEach((member, index) => {
            if (!localStorage.getItem('idAlien')) {
              if (member.value) {
                this.renderData = [
                  ...this.renderData,
                  <React.Fragment key={index}>
                    <Card className='production-accordion__card'>
                      <Card.Header className='production-accordion__card__header'>
                        <Accordion.Toggle className='transition_05s' as={Card.Header} variant='link' eventKey={index + 1} onClick={() => this.handleClick(index)} style={{ position: 'relative' }}>
                          {member.information}
                          {/* this.state.arrLength[index] === true  */}
                          <ArrowsAnimation isOpen={this.state.arrLength[index] === true} />

                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={index + 1}>
                        <Card.Body className={'quest_btn_container'}>
                          <QuestionnaireButton
                            onClickView={this.handleClickView}
                            classEmpty={this.props.classEmpty}
                            choice={FORM_[index]}
                            innFromUniversalTable={this.props.innFromUniversalTable}
                            getPercentAndFormName={this.props.getPercentAndFormName}
                            percent={this.state.percent}
                            formNames={this.props.formNames}
                            inReadMode={this.state.inReadMode}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </React.Fragment>
                ]
              }
            } else {
              if (this.state.arrResult[index] && member.value) {
                this.renderData = [
                  ...this.renderData,
                  <React.Fragment key={index}>
                    <Card className='production-accordion__card'>
                      <Card.Header className='production-accordion__card__header'>
                        <Accordion.Toggle as={Card.Header} variant='link' eventKey={index + 1} onClick={() => this.handleClick(index)}>
                          {member.information}
                          <ArrowsAnimation isOpen={this.state.arrLength[index] === true} />
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={index + 1}>
                        <Card.Body>
                          <QuestionnaireButton
                            onClickView={this.handleClickView}
                            classEmpty={this.props.classEmpty}
                            choice={FORM_[index]}
                            innFromUniversalTable={this.props.innFromUniversalTable}
                            getPercentAndFormName={this.props.getPercentAndFormName}
                            percent={this.state.percent}
                            formNames={this.props.formNames}
                            inReadMode={this.state.inReadMode}
                          />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </React.Fragment>
                ]
              }
            }
          })

          this.setState({ base: this.renderData })
        })
        .catch((err) => {
          console.log(`ОШИБКА, в промисах. (ProductionAccordion, str 131): `, err)
        })
    }
    setTimeout(() => {
      // console.log(this.state.base);
    }, 1000)
  }

  async componentDidMount() {
    await this.renderAccordion(this.props.data)
    // console.log(`this.props.data >>>>>> `, this.props.data); // test
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.arrLength !== this.state.arrLength) {
      this.renderAccordion(this.props.data)
    }
  }

  render() {
    // console.log(this.state.arrLength)
    return (
      <>
        {this.state.show_errorRequest ?
          <ModalError />
          : ''
        }
        <div style={{ width: '90%' }}>
          <Accordion defaultActiveKey='0' className='production-accordion'>
            {this.state.base}
          </Accordion>
        </div>
      </>
    )
  }
}
