

/**
 * Перевод времени из Московского в локальное
 * @param {String} timeMSK  Московское время , строка
 * @param {String} timezone Часовой пояс локальный, строка
 * @returns {String} Результат перевода времени
 */
const changeTimeToLocal = (timeMSK, timezone) => {
    if (typeof (timezone) === 'object') timezone = timezone?.timezone

    let regExp1 = /\d\d/
    let regExp2 = /\d/

    let stepHour = -3;
    let localTime = '00:00'

    // console.log(`timeMCK: ${timeMSK} === timezone: ${timezone}`); // test

    if (String(timezone).match(regExp1)) {
        stepHour = stepHour + Number(String(timezone).match(regExp1)[0])
    } else if (String(timezone).match(regExp2)) {
        stepHour = stepHour + Number(String(timezone).match(regExp2)[0])
    }

    // console.log(`stepHour 3 :::: `, stepHour); // test
    const arrTime = timeMSK.split(':')
    let newHour = Number(arrTime[0]) + stepHour

    if (newHour < 0) newHour = 24 + newHour
    if (newHour === 24) newHour = 0;

    if (newHour >= 0 && newHour < 24) {
        localTime = `${newHour === 0 ? '00' : newHour < 10 ? '0' + String(newHour) : newHour}:${arrTime[1]}`
    }

    // console.log(`localTime:::: `, localTime); // test
    return localTime
}

export default changeTimeToLocal;