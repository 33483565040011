import React, { useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import AllModalsFooter from '../assets/AllModalsFooter'
import { AddButton } from '../assets/BasicButtons'
import { ProductionSearch } from '../filter/ProductionSearch'


export const TradeCompanyModal = ({
    handleCompanyModalQuit,
    infoBlock,
    onUpInfoBlock,
    viewQuest,
    doSendDataChoice,
    sendCompaniesArray,
    getAllCompaniesFromSearch,
    // productFromProductionSearch,
    serverCompanies,
    doSendIdInvite,
    idLot,
    onGetChosenIdies,
    onGetChosenCompanies,
    sendProduct,
    getEconomicData,
    toggleLotModal,
    // dataChoiceProductions,
    formName,
    product,
    alterProduct
}) => {

    const [openedInModal] = useState(true)
    const [isAnotherBtnPressed, setIsAnotherBtnPressed] = useState(false)

    // console.log(serverCompanies);
    const d = serverCompanies.map(el => el.added)
    console.log(d);

    return (

        <div 
        // className="modal_window" 
        >
            <div className={"shadow_form"} ></div>
            <Form 
            // className={"form_main"}
            className='lot_participant_modal z_index_20'
             >
                <Alert
                    variant="dark"
                    className="filter_alert"
                    dismissible
                    onClick={handleCompanyModalQuit}
                >
                    <Alert.Heading >Список всех компаний</Alert.Heading>
                </Alert>

                <div 
                // className="form_container" style={{ paddingBottom: '40px' }}
                className='lot_participant_modal_content'
                >
                    <ProductionSearch
                        infoBlock={infoBlock}
                        onUpInfoBlock={onUpInfoBlock}
                        viewQuest={viewQuest}
                        sendDataChoice={doSendDataChoice}
                        handleCompanyModalQuit={handleCompanyModalQuit}
                        sendCompaniesArray={sendCompaniesArray}
                        getAllCompaniesFromSearch={getAllCompaniesFromSearch}
                        openedInModal={openedInModal}
                        serverCompanies={serverCompanies}
                        onSendIdInvite={doSendIdInvite}
                        idLot={idLot}
                        onGetChosenIdies={onGetChosenIdies}
                        onGetChosenCompanies={onGetChosenCompanies}
                        sendProduct={sendProduct}
                        getEconomicData={getEconomicData}
                        toggleLotModal={toggleLotModal}
                        product={product}
                        formName={formName}
                        isAnotherBtnPressed={isAnotherBtnPressed}
                        alterProduct={alterProduct}
                        needTextInButtons={false}
                    />
                </div>
                <AllModalsFooter
                    footerContent={
                        <AddButton handleSaveClick={() => setIsAnotherBtnPressed(!isAnotherBtnPressed)} needDisable={false} buttonText='выбранные компании' />
                    // <Button 
                    //     variant='success' 
                    //     onClick={() => setIsAnotherBtnPressed(!isAnotherBtnPressed)}
                    //     >
                    //         Добавить
                    //         </Button>
                            }
                />
            </Form>
        </div>
    )
}



