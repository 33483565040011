import React, { useState, useEffect } from 'react'
import { Button, Card, Row, Table } from 'react-bootstrap'
// import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
// import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined'
import PriorityHighOutlinedIcon from '@mui/icons-material/PriorityHighOutlined'
import AllModalsFooter from '../assets/AllModalsFooter'
import { CreateButton } from '../assets/BasicButtons'
// import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'

export const GeneralDataTrade = ({ dataFromTech, dataFromCommerce, name, handleClickCreateTrade, filesNamesArr }) => {
  const [baseCommerce, setBaseCommerce] = useState('')
  const [baseTech, setBaseTech] = useState('')

  useEffect(() => {
    if (dataFromCommerce.length !== 0 && dataFromTech.length !== 0) {
      loadGeneralData(dataFromCommerce, 11)
      loadGeneralData(dataFromTech, 1)
    }
  }, [dataFromTech, dataFromCommerce])

  const loadGeneralData = (array, sliceIdx) => {
    if (array) {
      const result = array.slice(sliceIdx)
      let renderedArray = []
      let data = {}

      result.forEach((el, index) => {
        if (el) {
          if (+el.id % 2 !== 0) {
            data = {
              key: el.value,
              value: result[index + 1] && result[index + 1].value !== '' ? result[index + 1].value : <i>no data</i>
            }

            renderedArray.push(data)
          }

          if (+el.id % 2 === 0) {
            data = {
              key: result[index - 1] ? result[index - 1].value : 1,
              value: el.value
            }
            renderedArray.push(data)

            const uniqueArray = renderedArray.filter((element, index) => {
              return (
                index ===
                renderedArray.findIndex(obj => {
                  return JSON.stringify(obj) === JSON.stringify(element)
                })
              )
            })

            renderedArray = uniqueArray
          }
        }
      })
      if (sliceIdx === 11) {
        setBaseCommerce(renderedArray)
      } else {
        setBaseTech(renderedArray)
      }
    }
  }

  return (
    <>
      <div
        // className='overflow_y_hidden overflow_x_hidden height_500'
        className='overflow_y_auto overflow_x_hidden height_370 pr_10'
      >
        <div className='mt_20'>
          <Card.Header className='box_shadow text_align_center trade_card_header fw_bold_color_dark_grey'>
            Название позиции: {name}
          </Card.Header>
          <Row> &nbsp;</Row>
          <Card className='box_shadow'>
            <Card.Header className='width_100pr text_align_center trade_card_header fw_bold_color_dark_grey'>
              Технические требования
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
              <Table hover style={{ backgroundColor: '#fff' }} bordered size='sm'>
                <thead>
                  <tr>
                    <th className='fw_bolder' style={{ width: '50%' }}>
                      Параметры
                    </th>
                    <th className='fw_bolder' style={{ width: '50%' }}>
                      Требование к параметрам
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {baseTech &&
                    baseTech.map((el, idx) => (
                      <tr key={idx}>
                        <td>{el.key}</td>
                        <td>{el.value}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          <Row> &nbsp;</Row>

          <Card className='box_shadow'>
            <Card.Header className='width_100pr text_align_center trade_card_header fw_bold_color_dark_grey'>
              {' '}
              Коммерческие требования
            </Card.Header>
            <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
              <Table hover style={{ backgroundColor: '#fff' }} bordered size='sm'>
                <thead>
                  <tr>
                    <th className='fw_bolder' style={{ width: '50%' }}>
                      Параметры
                    </th>
                    <th className='fw_bolder' style={{ width: '50%' }}>
                      Требования к параметрам
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {dataFromCommerce && dataFromCommerce.length !== 0 && (
                    <>
                      <tr>
                        <td>Количество штук: </td>
                        <td>{dataFromCommerce[6] ? dataFromCommerce[6].value : <i>1</i>}</td>
                      </tr>
                      <tr>
                        <td>Начальная минимальная цена за штуку, руб.: </td>
                        <td>{dataFromCommerce[10] ? dataFromCommerce[10].value : <i>Не указано</i>}</td>
                      </tr>
                    </>
                  )}

                  {baseCommerce &&
                    baseCommerce.map((el, idx) => (
                      <tr key={idx}>
                        <td>{el.key}</td>
                        <td>{el.value}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>

          <Row>&nbsp;</Row>

          {filesNamesArr && filesNamesArr.length !== 0 ? (
            <>
              <p style={{ fontWeight: 'bolder' }}>Прикрепленные файлы: </p>
              <ol>
                {filesNamesArr.map((el, idx) => (
                  <li key={idx}>{`${el.descriptionFile ? el.descriptionFile : 'Название файла отсутствует'} (${
                    el.oldNameFile
                  })`}</li>
                ))}
              </ol>
            </>
          ) : (
            <p style={{ fontWeight: 'bolder' }}>
              {' '}
              <PriorityHighOutlinedIcon className='pb_3' fontSize='small' color='action' />
              Нет прикрепленных файлов
            </p>
          )}
        </div>
      </div>
      <AllModalsFooter
        isPosAbsoluteNedded={true}
        footerContent={
          <CreateButton
          handleSaveClick={handleClickCreateTrade}
          needDisable={false}
          variant='success'
          buttonText='Создать торговую позицию'
          dataIdx=''
          btnWidth=''
          iconWithPlus={true}
          />
          // <Button variant='success' onClick={handleClickCreateTrade}>
          //   Создать торговую позицию
          // </Button>
        }
      />
    </>
  )
}
