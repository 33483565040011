import React from 'react'
import { Alert, Badge } from 'react-bootstrap'

export default function CommonAlert({ inReadMode, handleClickHeader, handleClickShadow, hideContent, companyName, sectionName }) {
    return (
        <Alert
            variant="dark"
            className="form_alert"
            onClick={handleClickHeader}
            onClose={inReadMode ? () => handleClickShadow() : hideContent}
            dismissible>
            <Alert.Heading style={{ position: 'relative' }}>
                {sectionName}
                <Badge variant='info' className={'company_name_in_alert'}>{companyName && companyName}</Badge>
            </Alert.Heading>
        </Alert>
    )
}
