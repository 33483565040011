import React from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { DOCUMENTS } from './ConstDocum';
import { ACCEPT } from './ConstAccept';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import Server from '../server/server';

export default class Form_Fifteen_Files extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            base: [],
            fileName: '',
            ext: '',
            renderFile: []
        }

        this.writeViewButton = this.writeViewButton.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.makeArrayDataFile = this.makeArrayDataFile.bind(this);

        this.handleClickReadFile = this.handleClickReadFile.bind(this);
    }

    // читаем ранее загруженный файл
    async handleClickReadFile(fileName) {
        // console.log(`FILE NAME >>>>>>>>>>>> `, fileName);//test
        const result = await Server.readFileFromServer(fileName);

        const arrFileName = fileName.split('/');
        const arrFileNameNext = (arrFileName[arrFileName.length - 1]).split('_-')
        // console.log(`arr FILE NAME >>>>>>>>> `, arrFileNameNext); // test
        const fifteenFileName = arrFileNameNext[arrFileNameNext.length - 1]


        if (result) {
            const linkBlob = window.URL.createObjectURL(result);

            const link = document.createElement('a');
            link.href = linkBlob
            // link.target = "_blank"
            link.download = fifteenFileName;
            // link.download = fileName
            // link.download = `test.jpg` //! подставное имя файла при загрузке
            document.body.appendChild(link)
            link.click()
            link.remove()

        }
    }

    onChangeFile(e) {
        (async () => {
            try {
                // console.log(e.target.dataset.description);//test
                // console.log(e.target.value.split('\\')[2]);// test - имя файла
                // console.log(e.target.dataset.id);// dataset ID

                this.file = e.target.files[0];//приводим к blob значению
                // console.log(`SEARCH DATA FILE >>>>>>>>>>> : `, this.file);//test


                if (this.file) {
                    // this.fileName = this.file.name;// имя файла
                    this.fileName = (this.file.name.split('.'))[0] //разделение по точке
                    this.fileExt = this.file.type;// тип файла / расширение файла
                    this.fileExc = (this.file.name.split('.')).pop() //разделение по точке
                    if (this.file.size > 1024) console.log('SIZE file: ' + this.file.size);
                    if (!this.fileName) this.fileExc = ''; //????????

                    console.log(this.fileName + ' --- ' + this.fileExc);//получаем fileName и ext(расширение) // ! fileExt -> fileExc
                    await this.setState({ fileName: this.fileName, ext: this.fileExc })// ! fileExt -> fileExc
                }

                // формируем новое имя файла для хранения в DB и на сервере
                if (this.fileExt) {
                    this.newNameFile = `Fifteen_-${localStorage.getItem('idUser')}_-${e.target.dataset.id}_-${this.state.fileName}.${this.fileExc}`//уникальное имя файла 
                } else {
                    this.newNameFile = `Fifteen_-${localStorage.getItem('idUser')}_-${e.target.dataset.id}_-${this.state.fileName}`//уникальное имя файла 

                }

                // наполняем запрос к серверу
                let newDataForm = new FormData();
                newDataForm.append("key", "weifeiph7Pie");//todo kye - constanta
                newDataForm.append("filename", this.newNameFile);//todo создать уникальный
                newDataForm.append("ext", this.state.ext);
                newDataForm.append("session", sessionStorage.getItem('sessionId'));
                newDataForm.append("login", sessionStorage.getItem('login'));
                newDataForm.append("userid", sessionStorage.getItem('idUser'));
                // newDataForm.append("file", document.getElementById(idInput).files[0], e.target.files[0].name);//в тело объекта новой формы добавили INPUT
                newDataForm.append("file", this.file, this.file.name);//в тело объекта новой формы добавили INPUT

                this.makeArrayDataFile(e.target.dataset.id, this.file.name, e.target.dataset.description, newDataForm)

            } catch (err) {
                console.log(`Ошибка в чтении -Ю записи файла: `, err);
            }
        })();


    }

    //* формируем объект загруженного файла
    makeArrayDataFile(id, oldNameFile, description, newDataForm) {
        let newData = { id: id, fid: `${this.props.nameForm}_${id}`, description: description, information: '', value: oldNameFile }

        //! ************************        
        this.props.onsendDataFile(newDataForm, newData, DOCUMENTS.length);//todo отправляем объект с файлом SEND DATA (форма, объект, maxID)
    }

    writeViewButton() {
        this.dataD = [];
        this.value = this.props.value;
        console.log('VALUE :::::::::::::::: ', this.value);
        const acceptData = ACCEPT.join(', ');

        if (!this.value) {
            console.log(`RETURN NULL!!!!!!!!!!!!!!!!!!!!`);
            this.value = DOCUMENTS.map(name => {
                return null
            })
        }
        DOCUMENTS.forEach((data, index) => {
            this.dataD = [...this.dataD,
            <React.Fragment key={index}>
                <Form action="/server_data/file_create" method="post" encType="multipart/form-data">
                    <Card
                        className="mb-2 fw_bolder height_300 file_body_style">
                        <Card.Header><NoteAddOutlinedIcon className='m_0_5' /> {data}</Card.Header>
                        <Card.Body className='height_100' >
                            <Card.Text>

                                <span className='display_block'><b>Файл: </b> {this.value[index + 1] ? this.value[index + 1].value : ''}</span>


                                {this.value[index + 1] && this.value[index + 1].value ?
                                    <>
                                        <Button
                                            onClick={() => { this.handleClickReadFile(this.value[index + 1].information) }}
                                            variant='outline-light'
                                            className='mt_10'
                                        >
                                            Смотреть загруженный файл
                                        </Button>
                                    </>
                                    : ''}

                            </Card.Text>

                        </Card.Body>
                        <Card.Footer>
                            <Form.Control
                                id={index + 1}
                                onChange={this.onChangeFile}
                                accept={acceptData}
                                data-id={index + 1}
                                data-fid={`${this.props.nameForm}_${index + 1}`}
                                type="file"
                                name="file"
                                data-description={data}
                            // value={this.value[index + 1] ? this.value[index + 1].value : ''}
                            />
                        </Card.Footer>
                    </Card >
                </Form>
            </React.Fragment>
            ]
        })

        this.setState({ base: this.dataD })
    }

    async componentDidMount() {
        await setTimeout(() => { this.writeViewButton(); }, 1000)
        // console.log(this.props.value);//test
    }

    render() {
        console.log(this.value);
        return (
            <>
                {this.state.base}
                {this.state.renderFile}
            </>
        )
    }
}