import React from 'react'
import { Col, Form, Row } from 'react-bootstrap'
import { shortenName } from '../../assets/shortenStringsFunction'

/**
 * Ввод данных из списка, массива
 * @param {*} param0
 * @returns
 */
const SelectDataShortInput = ({
  index,
  keyData,
  text,
  handleChangeInput,
  label,
  arr,
  value,
  name,
  disabled
}) => {
  const nameLength = 40
  return (
    <React.Fragment key={index}>
      {/* <Form.Group> */}
      <Row>
        <Col>
          <Form.Label>
            <b> {label} </b>
          </Form.Label>
        </Col>
        {/* <Col> */}
        <Form.Control
          as='select'
          data-key={keyData}
          onChange={handleChangeInput}
          value={value}
          name={name}
          disabled={disabled}
          style={{ width: '250px' }}
        >
          <option> Выбрать Компанию... </option>
        
            {arr?.map((item) => {
              // const processedName = shortenName(copy, 40)
              return (
                <React.Fragment key={item.id}>
                  <option>
                    {' '}
                    {`${item.ownership ? item.ownership : ''} ${
                      // item.nameCompany ? shortenName(copy, 40) : ''
                      item.nameCompany
                    }`}{' '}
                  </option>
                </React.Fragment>
              )
            })}
        
        </Form.Control>
        {/* <Form.Text className='text-muted'>{text}</Form.Text> */}
        {/* </Col> */}
      </Row>
      {/* </Form.Group> */}
    </React.Fragment>
  )
}
export default SelectDataShortInput
