import React from 'react'
import { Modal } from 'react-bootstrap'

export default function AllModalsFooter({ footerContent, isPosAbsoluteNedded }) {

    return (
        <Modal.Footer className={isPosAbsoluteNedded && 'absolute_pos_for_modal_footer'} style={{ backgroundColor: '#F0F1F2' }}>
            {footerContent}
        </Modal.Footer>
    )
}
