import React from 'react'

import { Col, Row } from 'react-bootstrap'
import Server from './server/server'
import { doDateData } from './trade-participant/doDateData'

export default class MainContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: true,
      tradeLot: [],
      showTrade: '',
      showTrade2: '',
      text: 'Необходимо в разделе, Данные компании, заполнить анкету и отправить на модерацию!',
      showModeration: true,
      key: 'zero'
    }
    this.doStartContent = this.doStartContent.bind(this)
    this.doModeration = this.doModeration.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  async doStartContent() {
    this.setState({ show: false })
    const result = await Server.getParticipantTrade('active') // все лоты в которых участник
    if (result) {
      const tradeLot = result.map((lot) => {
        return lot.data.data
      })
      this.setState({ tradeLot: tradeLot })

      const timezone = await Server.getDataFromServer('timezone', localStorage.getItem('idUser'))
      const resultDate = doDateData(tradeLot, null, timezone)
      console.log(`TEST ---------------------> `, resultDate); // test
      // resultDate.dateTechStart.length || - согласование не началось
      if (resultDate && (resultDate.dateTechOld.length || resultDate.dateTech.length)) {
        // console.log(`TRUE`); // test
        this.setState({
          showTrade: <h4 style={{ color: '#dc3545' }}> У Вас есть приглашения на торги! </h4>,
          show: true
        })
      }

      if (resultDate && resultDate.dateTrade.length) { //resultDate && resultDate.dateTrade.length
        this.setState({
          showTrade2: <h3 style={{ color: '#dc3545' }}><b> В ближайшие сутки пройдут торги! </b></h3>,
          show: true
        })
      } else {
        this.setState({
          showTrade: <h5> Сейчас у Вас нет приглашений на торги. </h5>,
          show: true
        })
        // console.log(`FALSE`); // test
      }
    }
  }

  async doModeration() {
    try {
      const result = await Server.getDataFromServer(sessionStorage.getItem('login'), 'registration')
      const key = result.key[localStorage.getItem('idUser')]

      if (key === 'zero' || key === undefined) {
        this.setState({ showModeration: true })

        const resultGet = await Server.getDataFromServer(localStorage.getItem('idUser'), 'moderation')
        console.log(`RESULT KEY:::: `, resultGet) //test

        if (resultGet) {
          const { text } = result
          this.setState({ text: text })
        }
      } else if (key === 'one' || key === 'two' || key === 'full') {
        this.setState({
          showModeration: false,
          text: 'Модерация пройдена'
        })
      }

      // console.log(`RESULT KEY:::: `, key) //test
    } catch (err) {
      console.log(`Ошибка при получении данных модерации (MainContent.jsx): `, err)
      localStorage.removeItem('idUser')
      window.location.reload()
    }
  }

  // на модерацию
  handleClick() {
    ; (async () => {
      try {
        const data = {
          login: sessionStorage.getItem('login'),
          loginReg: localStorage.getItem('loginReg'),
          inn: localStorage.getItem('idUser'),
          text: this.state.text,
          key: this.state.key,
          sentOnModertaion: true,
        }
        const resultSend = await Server.sendDataOnServer(data, localStorage.getItem('idUser'), 'moderation')

        console.log(`resultSend:::: `, resultSend) // test
      } catch (err) {
        console.log(`Ошибка записи запроса на модерацию(MainContent.jsx): `, err)
      }
    })()
    console.log(`MODERATION click`) // test
  }

  async componentDidMount() {
    await this.doStartContent()
    await this.doModeration()
  }

  componentDidUpdate(prevProps) {
    if (this.props.toModerate !== prevProps.toModerate) {
      this.handleClick()
    }
  }

  render() {
    return (
      <>
        <div fluid className='p_40' style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginTop: '50px' }}>
          <h1>Приветствую Вас!</h1>

          {this.state.show && !this.state.showModeration && this.state.showTrade}
          {this.state.show && !this.state.showModeration && this.state.showTrade2}

          {this.state.showModeration && (
            <>
              <Row
                style={{
                  fontSize: '20px',
                  color: '#dc3545'
                }}
              >
                <Col>{this.state.text}</Col>
              </Row>
              <Row> &nbsp; </Row>
              <Row>
                {/* <Col>
                  <Button onClick={this.handleClick}>Отправить на модерацию</Button>
                </Col> */}
              </Row>
            </>
          )}
        </div>
        {/* <FeedBack/> */}
      </>
    )
  }
}
