import React from 'react'
import { Alert } from 'react-bootstrap'
import ModalOverlay from './ModalOverlay'

const Modal = ({ size, isVisible, children, onClose, alertText }) => {
  return (
    isVisible && (
      <div className={`base_modal modal_${size}`}>
        {/* <ModalOverlay isVisible={isVisible} /> */}
        <Alert variant='dark' dismissible onClose={onClose}>
          <Alert.Heading>{alertText}</Alert.Heading>
        </Alert>
        <div className='modal_content'>{children}</div>
      </div>
    )
  )
}

export default Modal
