import React from 'react';
import InputForm from './InputForm';
import RadioButtonForm from './RadioButtonForm';

export default class InputNameForrm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: true,
            id: '',
            fid: '',
            description: '',
            information: '',
            value: ['', '', '', '']
        }
        this.doChangeValue = this.doChangeValue.bind(this);
        this.doEmpty = this.doEmpty.bind();
    }
    //*пустышка
    doEmpty() {

    }

    doChangeValue(e) {
        this.data = this.state.value;
        // console.log(typeof (this.state.value));
        if (e.information === 'Фамилия') this.data[0] = e.value;
        if (e.information === 'Имя') this.data[1] = e.value;
        if (e.information === 'Отчество') this.data[2] = e.value;
        if (e.information === 'пол') this.data[3] = e.value;
        this.setState({ value: this.data })
        setTimeout(() => {
            this.props.onChangeValue(this.state)
        })
    }

    componentDidMount() {
        this.setState({
            id: this.props.id,
            fid: `${this.props.nameForm}_${this.props.id}`,
            description: this.props.description,
            information: this.props.placeholder,
            // value: this.props.value
        })
        if (this.props.value) this.setState({ value: this.props.value })

    }

    render() {

        const id = this.props.id;
        const description = this.state.description;
        const value = this.props.value;
        const width = this.props.width;
        const show = this.state.show;

        return (
            <>

                <InputForm
                    id={+id}
                    width={width}
                    show={show}
                    // verify="number"
                    label=""
                    placeholder="Фамилия"
                    description={description}
                    value={value[0]}
                    // value={this.value[2].value}
                    onChangeValue={this.doChangeValue}
                    nameForm={this.props.nameForm}
                    readOnly={this.props.readOnly}
                />

                <InputForm
                    id={+id}
                    width={width}
                    show={show}
                    // verify="number"
                    label=""
                    placeholder="Имя"
                    description={description}
                    value={value[1]}
                    // value={this.value[2].value}
                    onChangeValue={this.doChangeValue}
                    nameForm={this.props.nameForm}
                    readOnly={this.props.readOnly}
                />

                <InputForm
                    id={+id}
                    width={width}
                    show={show}
                    // verify="number"
                    label=""
                    placeholder="Отчество"
                    description={description}
                    value={value[2]}
                    // value={this.value[2].value}
                    onChangeValue={this.doChangeValue}
                    nameForm={this.props.nameForm}
                    readOnly={this.props.readOnly}
                />

                <RadioButtonForm
                    id={+id}
                    width={width}
                    show={show}
                    title="Пол:"
                    label1="муж"
                    label2="жен"
                    placeholder="пол"
                    description={description}
                    value={value[3]}
                    onChangeValue={this.doChangeValue}
                    onChangeVisionBlock={this.doEmpty}
                    nameForm={this.props.nameForm}
                />

            </>
        )
    }
}