import React from 'react'
import { Button } from 'react-bootstrap'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined'
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined'
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined'
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined'
import PresentToAllOutlinedIcon from '@mui/icons-material/PresentToAllOutlined'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined'
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined'
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined'
import HowToRegIcon from '@mui/icons-material/HowToReg'
import LoginIcon from '@mui/icons-material/Login'
import PasswordIcon from '@mui/icons-material/Password'
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import ManageSearchOutlinedIcon from '@mui/icons-material/ManageSearchOutlined'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import SwapVerticalCircleOutlinedIcon from '@mui/icons-material/SwapVerticalCircleOutlined'
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined'

//***INCOMING PROPS***
// handleSaveClick={}
// needDisable={}
// disableCondition={}
// saveButtonText={}
// buttonVariant={}
// icon={}
// savedDataMessage={бул для ExitButton, определяет текст, цвет и иконку этой кнопки}

//***BUTTONS***
// AddButton - синяя кнопка добавления чего либо с иконкой плюсика в квадрате
// BasicButton - кнопка полностью зависит от входящих пропсов
// ChatButton
//ClearButton
// ChangePasswordButton - кнопка изменения пароля
// ChooseAccessTypeButton - кнопка выбора вида доступа (в 1ом месте - в разделе "сотрудники")
// CreateButton - кнопка создания чего то с нуля
// ExitButton - с доп. функционалом (меняет цвет на неск-ко секунд, чтобы показать юзеру что его действие выполнено)
// GoToTradeButton - перейти к созданию лота
// InviteByEmailButton
// LoginButton
// LaunchButton - кнопка запуска
// LaunchWithMessageButton - кнопка запуска с доп. функционалом (меняет цвет на неск-ко секунд, чтобы показать юзеру что его действие выполнено)
// MaterialsButton - используемые материалы
// ReTradeButton - переторжка
// RegistrationButton
// SaveButton - кнопка на сохранение данных с иконкой дискеты синего цвета
// SaveButtonGreen - кнопка на сохранение данных с иконкой дискеты зеленого цвета
// StopButton
// SendButton - кнопка отправки
// SaveCurrentDataButton - кнопка сохранения с доп. функционалом (меняет цвет на неск-ко секунд, чтобы показать юзеру что его действие выполнено)
//SearchButton
// DeleteButton - кнопка для удаления

const marginRight = () => {
  return { marginRight: '5px' }
}

export function BasicButton({
  handleSaveClick,
  needDisable,
  disableCondition,
  saveButtonText,
  buttonVariant,
  icon
}) {
  return (
    <div className='button_container'>
      <Button
        onClick={handleSaveClick}
        disabled={needDisable && disableCondition}
        variant={buttonVariant}
      >
        {icon}
        {saveButtonText}
      </Button>
    </div>
  )
}

export function SearchButton({
  handleSaveClick,
  needDisable,
  disableCondition,
  buttonText,
  buttonVariant
}) {
  return (
    <div className='button_container'>
      <Button
        onClick={handleSaveClick}
        disabled={needDisable && disableCondition}
        variant={buttonVariant}
      >
        <span style={{ whiteSpace: 'nowrap' }}>
          <ManageSearchOutlinedIcon style={marginRight()} />
          {buttonText}
        </span>
      </Button>
    </div>
  )
}

export function ClearButton({
  handleSaveClick,
  needDisable,
  disableCondition,
  saveButtonText,
  buttonVariant
}) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={buttonVariant}
    >
      <HighlightOffOutlinedIcon style={marginRight()} />
      {saveButtonText}
    </Button>
  )
}

export function SaveButton({ handleSaveClick, needDisable, disableCondition, saveButtonText }) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={'primary'}
    >
      <SaveOutlinedIcon style={marginRight()} />
      {saveButtonText}
    </Button>
  )
}

export function SaveButtonGreen({
  handleSaveClick,
  needDisable,
  disableCondition,
  saveButtonText,
  dataIdx
}) {
  return (
    <Button
      data-index={dataIdx && dataIdx}
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={'success'}
    >
      <SaveOutlinedIcon style={marginRight()} />
      {saveButtonText}
    </Button>
  )
}

export function LaunchButton({ handleSaveClick, needDisable, disableCondition }) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={'success'}
    >
      <LaunchOutlinedIcon style={marginRight()} />
      Пуск
    </Button>
  )
}

export function LaunchWithMessageButton({
  handleSaveClick,
  needDisable,
  disableCondition,
  savedDataMessage
}) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={savedDataMessage ? 'success' : 'primary'}
    >
      {savedDataMessage ? (
        <CheckCircleOutlineOutlinedIcon style={marginRight()} />
      ) : (
        <LaunchOutlinedIcon style={marginRight()} />
      )}
      {savedDataMessage ? 'Лот запущен!' : 'Пуск'}
    </Button>
  )
}

export function ChatButton({ handleSaveClick, needDisable, disableCondition }) {
  return (
    <Button onClick={handleSaveClick} disabled={needDisable && disableCondition} variant={'info'}>
      <SmsOutlinedIcon style={marginRight()} />
      Открыть чат
    </Button>
  )
}

export function StopButton({ handleSaveClick, needDisable, disableCondition }) {
  return (
    <Button onClick={handleSaveClick} disabled={needDisable && disableCondition} variant={'danger'}>
      <CancelPresentationOutlinedIcon style={marginRight()} />
      Остановить
    </Button>
  )
}

export function ReTradeButton({ handleSaveClick, needDisable, disableCondition }) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={'success'}
    >
      <RedoOutlinedIcon style={marginRight()} />
      Переторжка
    </Button>
  )
}

export function CalcButton({ handleSaveClick, needDisable, disableCondition }) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={'success'}
    >
      <SwapVerticalCircleOutlinedIcon style={marginRight()} />
      Рассчитать
    </Button>
  )
}

export function SendButton({
  handleSaveClick,
  needDisable,
  disableCondition,
  variant,
  additionalText
}) {
  return (
    <div className='button_container'>
      <Button
        onClick={handleSaveClick}
        disabled={needDisable && disableCondition}
        variant={variant ? variant : 'success'}
      >
        <PresentToAllOutlinedIcon style={marginRight()} />
        Отправить {additionalText ? additionalText : ''}
      </Button>
    </div>
  )
}

export function ExitButton({ handleSaveClick, needDisable, disableCondition, savedDataMessage }) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={savedDataMessage ? 'success' : 'primary'}
    >
      {savedDataMessage ? (
        <CheckCircleOutlineOutlinedIcon style={marginRight()} />
      ) : (
        <ExitToAppOutlinedIcon style={marginRight()} />
      )}
      {savedDataMessage ? 'Данные сохранены' : 'Выйти из таблицы'}
    </Button>
  )
}

export function SaveCurrentDataButton({
  handleSaveClick,
  needDisable,
  disableCondition,
  savedDataMessage,
  buttonTextDefault,
  buttonTextSaved,
  needCustomIcon,
  customIcon,
  needCustomStyle,
  customStyle
}) {
  return (
    <Button
      className={needCustomStyle && customStyle}
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={savedDataMessage ? 'success' : 'primary'}
    >
      {savedDataMessage ? (
        <CheckCircleOutlineOutlinedIcon style={marginRight()} />
      ) : needCustomIcon ? (
        customIcon
      ) : (
        <SaveOutlinedIcon style={marginRight()} />
      )}
      {savedDataMessage ? buttonTextSaved : buttonTextDefault}
    </Button>
  )
}

export function AddButton({ handleSaveClick, needDisable, disableCondition, buttonText }) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={'primary'}
    >
      <AddBoxOutlinedIcon style={marginRight()} />
      Добавить {buttonText ? buttonText : ''}
    </Button>
  )
}

export function InviteByEmailButton({ handleSaveClick, needDisable, disableCondition }) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={'primary'}
      title='Пригласить компании по email'
    >
      <ForwardToInboxOutlinedIcon style={marginRight()} />
      Пригласить компании
    </Button>
  )
}

export function ChangePasswordButton({
  handleSaveClick,
  needDisable,
  disableCondition,
  savedDataMessage,
  buttonTextDefault,
  buttonTextSaved
}) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={savedDataMessage ? 'success' : 'primary'}
    >
      {savedDataMessage ? (
        <CheckCircleOutlineOutlinedIcon style={marginRight()} />
      ) : (
        <PasswordIcon style={marginRight()} />
      )}
      {savedDataMessage ? buttonTextSaved : buttonTextDefault}
    </Button>
  )
}

export function ChooseAccessTypeButton({ handleSaveClick, needDisable, disableCondition }) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={'primary'}
    >
      <BadgeOutlinedIcon style={marginRight()} />
      Выбрать вид доступа
    </Button>
  )
}

export function GoToTradeButton({ handleSaveClick, needDisable, disableCondition }) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={'primary'}
    >
      <AssessmentOutlinedIcon style={marginRight()} />
      Переход в торги
    </Button>
  )
}

export function CreateButton({
  handleSaveClick,
  needDisable,
  disableCondition,
  variant,
  buttonText,
  dataIdx,
  btnWidth,
  iconWithPlus
}) {
  return (
    <Button
      style={{ width: btnWidth ? btnWidth : '' }}
      data-index={dataIdx && dataIdx}
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={variant}
    >
      {iconWithPlus ? (
        <NoteAddOutlinedIcon style={marginRight()} />
      ) : (
        <InsertDriveFileOutlinedIcon style={marginRight()} />
      )}

      {buttonText}
    </Button>
  )
}

export function MaterialsButton({ handleSaveClick, needDisable, disableCondition }) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={'primary'}
    >
      <DesignServicesOutlinedIcon style={marginRight()} />
      Используемые материалы
    </Button>
  )
}

export function RegistrationButton({ handleSaveClick, needDisable, disableCondition, variant }) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={variant ? variant : 'secondary'}
    >
      <HowToRegIcon style={marginRight()} />
      Регистрация
    </Button>
  )
}

export function LoginButton({ handleSaveClick, needDisable, disableCondition }) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={'primary'}
    >
      <LoginIcon style={marginRight()} />
      Войти
    </Button>
  )
}

export function LoadButton({ handleSaveClick, needDisable, disableCondition, buttonText }) {
  return (
    <Button
      onClick={handleSaveClick}
      disabled={needDisable && disableCondition}
      variant={'primary'}
    >
      <DownloadOutlinedIcon style={marginRight()} />
      {buttonText}
    </Button>
  )
}

export function DeleteButton({ handleSaveClick, needDisable, disableCondition, buttonText }) {
  return (
    <Button onClick={handleSaveClick} disabled={needDisable && disableCondition} variant={''}>
      <div style={{ whiteSpace: 'nowrap', color: '#e87c86' }}>
        <DeleteForeverOutlinedIcon style={marginRight()} />
        {buttonText}
      </div>
    </Button>
  )
}
