/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import {
  Form,
  Row,
  Col,
  Badge,
  Alert,
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton
} from 'react-bootstrap'
import InputForm from '../questionnaire/InputForm'
import Server from '../server/server'
import TradePosition from './TradePosition'
import ChatWs from '../chat/ChatWs'
import { Card } from 'react-bootstrap'
import GeneralLotPriceComponent from './GeneralLotPriceComponent'
import { TradeCompanyModal } from './TradeCompanyModal'
import { TradePositionModal } from './TradePositionModal'
import { nanoid } from 'nanoid'
import UniversalTable from '../assets/UniversalTable'
import { TableSkeleton } from '../assets/TableSkeleton'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import PanToolOutlinedIcon from '@mui/icons-material/PanToolOutlined'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import TimeForm from './TimeForm'
import { CalendarFormTrade } from '../questionnaire/CalendarFormTrade'
import { InviteByEmail } from '../InviteCompanies/InviteByEmail'
import { QuestionnairyModal } from '../QuestionnairyModal/QuestionnairyModal'
import LookInviteEmail from '../InviteCompanies/LookInviteEmail'
import { EconomicComponent } from '../Economic/EconomicComponent'
import InputFormEconomic from '../Economic/InputFormEconomic'
import { CURRENCY } from '../assets/currency'
import { Bankguarantees } from './BankGuarantees'
import FileNoModal from '../file/FileNoModal'
import { stopTrade } from './stopTrade'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp'
import AddListForFile from './AddListForFIle'
// import conditionsDisableButtons from './conditionsDisableButtons' // возвращает результат сравнения
import addNumberPeretojka from './addNumberPeretojka'
import QuitModal from '../QuitModal'
import ParticipantConfirmationLot from './ParticipantConfirmationLot'
import AllModalsFooter from '../assets/AllModalsFooter'
import {
  AddButton,
  ChatButton,
  LaunchButton,
  ReTradeButton,
  SaveCurrentDataButton,
  StopButton
} from '../assets/BasicButtons'
import { isEqual } from 'lodash-es'
import LoaderPage from '../assets/loaderPage'
// import changeTimeToMoscow from '../assets/changeTimeToMoscow'
// import SelectForm from '../questionnaire/SelectForm'
import { incotermsList } from './incoterms'
import SelectFormIncoterms from './SelectFormIncoterms'
import { setDaysDifference } from '../assets/calcDaysDifference'

export default class CreateLot extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'rus',
      show: false,
      showTable: true,
      showTradePosition: false,
      showChat: false,
      showPrice: true,
      showInvite: false,
      showFile: true,
      showStopButton: false,
      showPeretojka: false, // видимость перетожки
      showAddList: true,
      showStopLotModal: false, //модалка для остановки лота
      nameForm: 'CreateLot',
      changeTime: false, // перевод времени в МСК
      errorOff: '',
      company: [],
      companyModal: false,
      positionModal: false,
      chosenIdies: [],
      serverData: [], //все торговые позиции с сервера
      chosenPositions: [],
      newPosName: '',
      tradeObject: {},
      disableLot: true,
      chosenCompanies: [],
      emptyArray: [],
      rerenderCompany: false,
      rerenderPosition: false,
      radioResult: '',
      dataChoiceProductions: [],
      finishBeforeStartError: false,
      finishBeforeTradeError: false,
      tradeBeforeStartError: false,
      deliveryBeforeAgreementError: false,
      stopLotModal: false,
      serverCompanies: [],
      idUser: localStorage.getItem('idUser'),
      productsFromServer: 'productionArray',
      product: '',
      formName: '',
      innFromUniversalTable: '',
      companyQuestionnairieId: '',
      companyName: '',
      showQuestionnairy: false,
      idsArrayToCheck: [
        'guaranteeAfterLaunch',
        'guaranteeAfterShipment',
        'prepayFromServer',
        'beforePayFromServer',
        'afterPayFromServer'
      ],
      economicData: [],
      totalProcent: '',
      filesNamesArr: [],
      renderTradePosition: [],
      showRenderTradePosition: false,
      daysDifference: null,
      lotStatus: '',
      showQuit: false,
      // isSuccessfullySaved: false,
      chosenCompaniesWithMoreInfo: [],
      showModalOfParticipantForms: false,
      filledFormCompanyId: '',
      isLotLaunched: false,
      isStateChanged: false,
      previousState: {},
      savedDataMessage: false,
      showQuitExit: false,
      saveFromClose: false,
      stateBeenSaved: false,
      data: {
        id: '',
        numberLot: '',
        nameLot: '',
        tradeKind: '',
        typeTrade: 'ordinary', //тип торгов (ordinary / reduction) обычные / редукцион
        tradeStep: 0.01, // шаг торгов при редукцоние
        dateTechStart: '', //дата начала согласований
        dateTech: '', //дата окончания согласований
        dateTrade: '', //дата торгов
        dateArchive: false, // архивные
        timeTrade: '',//время проведения торгов
        timer: '15',
        garant: '',
        priceConditions: '',
        deliveryTime: '', //ориентировочный срок поставки
        organizerId: '',
        organizerName: '',
        keyNameTrade: '',
        price: '',
        position: [],
        participant: [],
        // participantInvite: [], // отключено из шаблона, добавляется при обращении
        participantConditions: [],
        chat: '', // test
        log: [],
        listForFiles: [], // * список файлов от участника торгов
        fileIdTradePosition: '', //здесь создаю id чтобы прокинуть пропсом в TradePosition ann

        guaranteeAfterLaunch: '', // гарантия после пуска
        guaranteeAfterShipment: '', // гарантия после отгрузки
        prepayFromServer: '', // предоплата
        beforePayFromServer: '', // доплата перед отгрузкой
        afterPayFromServer: '', //доплата после отгрузки
        termsOfAfterpayment: '', //срок доплаты после отгрузки

        bankContractExecutionGuarantee: false, // банковская гарантия на исполнение договора (bool)
        bankContractExecutionGuaranteeInput: '', //(str)

        bankPrepayGuarantee: false, //банк-я гарантия на предоплату (bool)
        // bankPrepayGuaranteeInput: '',//(str)

        bankGuaranteeExecution: false, //банк-я гарантия на исполнение гар-х обязательств (bool)
        bankGuaranteeExecutionInput: '', //(str)

        contractAgreement: false, //согласие с условиями договора

        agreementConclusionDate: '', //планируемая дата заключения договора

        deliveryCondition: '', //условия поставки

        currency: 'rub', // добавили валюту - Leo
        nds: 0.2,
        ndsPrice: '',

        idFile: '',
        filesChanged: false, //флаг для умного окна, дает понять что из списка файлов запрашиваемого заказчиком была удалена позиция (или позиции, 13й пункт лота)
        // isLaunched: false
      }
    }
    this.runCreate = this.runCreate.bind(this)
    this.createNumberLot = this.createNumberLot.bind(this)
    this.handleClickCreate = this.handleClickCreate.bind(this)
    this.doChangeValue = this.doChangeValue.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.handleClickCreateTradePosition = this.handleClickCreateTradePosition.bind(this)
    this.doChangeShowTrade = this.doChangeShowTrade.bind(this)
    this.doChangeVisibleChat = this.doChangeVisibleChat.bind(this)
    this.doCreateIdChat = this.doCreateIdChat.bind(this)
    this.searchChoiceCompany = this.searchChoiceCompany.bind(this)
    this.openCompanyModal = this.openCompanyModal.bind(this)
    this.openPositionModal = this.openPositionModal.bind(this)
    this.handleCompanyModalQuit = this.handleCompanyModalQuit.bind(this)
    this.doGetChosenIdies = this.doGetChosenIdies.bind(this)
    this.readFromServerData = this.readFromServerData.bind(this)
    this.doGetChosenPositions = this.doGetChosenPositions.bind(this)
    this.addPriceCompany = this.addPriceCompany.bind(this)
    this.doGetNewPosName = this.doGetNewPosName.bind(this)
    this.doGetTradeObject = this.doGetTradeObject.bind(this)
    this.doGetChosenCompanies = this.doGetChosenCompanies.bind(this)
    this.rerenderCompany = this.rerenderCompany.bind(this)
    this.rerenderPosition = this.rerenderPosition.bind(this)
    this.doGetArrayChoice = this.doGetArrayChoice.bind(this)
    this.createChat = this.createChat.bind(this)
    this.doChangeValueWithVerification = this.doChangeValueWithVerification.bind(this)
    this.confirmLotStop = this.confirmLotStop.bind(this)
    this.handleClickTrade = this.handleClickTrade.bind(this)
    this.sendCompaniesArray = this.sendCompaniesArray.bind(this)
    this.getChosenCompaniesFromSearch = this.getChosenCompaniesFromSearch.bind(this)
    this.checkCompaniesDataSource = this.checkCompaniesDataSource.bind(this)
    this.doSendIdInvite = this.doSendIdInvite.bind(this)
    this.openQuestionnairy = this.openQuestionnairy.bind(this)
    this.switchPositionModal = this.switchPositionModal.bind(this)
    this.getEconomicData = this.getEconomicData.bind(this)
    this.doChangeValueBank = this.doChangeValueBank.bind(this)
    this.returnId = this.returnId.bind(this)
    this.getFilesNames = this.getFilesNames.bind(this)
    this.doIdFile = this.doIdFile.bind(this)
    this.startTradePosition = this.startTradePosition.bind(this)
    this.closeTradePosition = this.closeTradePosition.bind(this)
    this.createNewTradePosition = this.createNewTradePosition.bind(this)
    this.checkupRunTrade = this.checkupRunTrade.bind(this)
    this.setDaysDifference = this.setDaysDifference.bind(this)
    this.addParticipantConfirmation = this.addParticipantConfirmation.bind(this)
    this.setLotStatus = this.setLotStatus.bind(this)
    this.changePeretojka = this.changePeretojka.bind(this)
    this.handleClickPeretojka = this.handleClickPeretojka.bind(this)
    this.runCreatePeretojka = this.runCreatePeretojka.bind(this)
    // this.addNumberPeretojka = this.addNumberPeretojka.bind(this); // todo переведен в .js
    this.setCorrectWordForm = this.setCorrectWordForm.bind(this)
    this.setProductionArrayToState = this.setProductionArrayToState.bind(this)
    this.setDataFromProductionSearch = this.setDataFromProductionSearch.bind(this)
    this.setAllCompaniesFromServerToCompanyModal =
      this.setAllCompaniesFromServerToCompanyModal.bind(this)
    this.handleQuitModal = this.handleQuitModal.bind(this)
    this.handleLotModal = this.handleLotModal.bind(this)
    // this.showSuccesfullySavedMsg = this.showSuccesfullySavedMsg.bind(this)
    this.setProductKind = this.setProductKind.bind(this)
    this.handleClickAddPosition = this.handleClickAddPosition.bind(this)
    this.addNewList = this.addNewList.bind(this)
    this.deletePosition = this.deletePosition.bind(this)
    this.addInfoToChosenCompanies = this.addInfoToChosenCompanies.bind(this)
    this.toggleFormsFilledByParticipant = this.toggleFormsFilledByParticipant.bind(this)
    this.toggleShowStopLotModal = this.toggleShowStopLotModal.bind(this)
    this.clearParticipantCondition = this.clearParticipantCondition.bind(this)
    // this.setLotIsLaunched = this.setLotIsLaunched.bind(this)
    this.closeWithModal = this.closeWithModal.bind(this)
    this.closeWithoutModal = this.closeWithoutModal.bind(this)
    this.compare = this.compare.bind(this)
    this.handleClickOnSaveButton = this.handleClickOnSaveButton.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.toggleSaveButton = this.toggleSaveButton.bind(this)
    this.handleClickCreateNoClose = this.handleClickCreateNoClose.bind(this)
    this.handleChangeButtonTrade = this.handleChangeButtonTrade.bind(this)
    this.disableConditionForLaunch = this.disableConditionForLaunch.bind(this)
  }

  disableConditionForLaunch(data) {
    if (
      //отсутствие данных
      !data.nameLot ||
      !data.deliveryTime ||
      !data.organizerId ||
      !data.keyNameTrade ||
      !data.price ||
      !data.dateTechStart ||
      !data.dateTech ||
      !data.dateTrade ||
      !data.timeTrade ||
      !data.timer ||
      //ошибки дат
      this.state.finishBeforeStartError ||
      this.state.finishBeforeTradeError ||
      this.state.tradeBeforeStartError ||
      this.state.deliveryBeforeAgreementError
    ) {
      // console.log('true', data);
      return true
    } else {
      // console.log('false', data);
      return false
    }


  }

  //выбор типа торгов
  handleChangeButtonTrade(e) {
    let dataTrade = this.state.data
    dataTrade.typeTrade = e
    this.setState({ data: dataTrade })
    // setTimeout(() => {
    //   console.log((`TYPE TRADE::: `, this.state.data.typeTrade))
    // }) // test
  }

  toggleSaveButton() {
    this.setState({
      savedDataMessage: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }

  async handleClickOnSaveButton() {

    const wasLaunched = await Server.lookRunTrade(this.state.data.id)
    const areStatesEqual = this.compare()
    this.setState({
      saveFromClose: false,
      stateBeenSaved: true
    })

    if (wasLaunched && !areStatesEqual) {
      this.setState({
        showQuit: true
      })
    } else {
      this.toggleSaveButton()
      this.handleClickCreateNoClose()
      // this.props.onTradeSaved();
    }
  }

  closeWithModal() {
    this.setState({
      showQuitExit: true
    })
  }

  closeWithoutModal() {
    this.props.handleLotModal()
  }

  // setLotIsLaunched() {
  //   this.setState({
  //     isLotLaunched: true
  //   })

  //   setTimeout(() => {
  //     this.setState({
  //       isLotLaunched: false
  //     })
  //     this.props.handleLotModal()
  //   }, 2000)
  // }

  toggleShowStopLotModal() {
    this.setState({
      showStopLotModal: !this.state.showStopLotModal
    })
  }

  // удаление позиции в списке нужных файлов
  deletePosition(id) {
    const dataD = Object.assign({}, this.state.data)
    const newData = dataD.listForFiles.map((item) => {
      if (item.id == id) {
        item.archive = true
      }
      return item
    })
    dataD.listForFiles = newData
    dataD.filesFromParticipanChanged = !this.state.data.filesFromParticipanChanged
    this.setState({ data: dataD })
  }

  //новый список необъодимых файлов
  addNewList(list) {
    const dataD = Object.assign({}, this.state.data)
    dataD.listForFiles = list
    this.setState({ data: dataD })
  }

  // добавление описания нового файла для получения от участника
  handleClickAddPosition() {
    this.setState({ showAddList: false })
    try {
      // console.log(`CLICK - handleClickAddPosition`);
      // data.listForFiles []
      const lengthPos = this.state.data.listForFiles.length
      const obj = { id: String(lengthPos), description: '', archive: false }
      let dataD = this.state.data
      dataD.listForFiles.push(obj)
      this.setState({ data: dataD })
      this.setState({ showAddList: true })
      // console.log(`STATE:::: `, this.state.data.listForFiles); // test
    } catch (err) {
      console.log(`старый STATE, ERROR!`)
      const dataD = this.state.data
      const obj = { id: '0', description: '' }
      Object.assign(dataD, { listForFiles: [obj] })
      this.setState({ showAddList: true })
    }
  }

  // showSuccesfullySavedMsg() {
  //   this.setState({
  //     isSuccessfullySaved: true
  //   })
  //   setTimeout(() => {
  //     this.setState({
  //       isSuccessfullySaved: false
  //     })
  //   }, 2000)
  // }

  handleQuitModal() {
    this.setState({
      showQuit: !this.state.showQuit
    })
  }

  handleLotModal() {
    this.props.handleLotModal()
    this.setState({
      showQuit: false
    })
  }

  async setProductionArrayToState() {
    const LotDataWithProductionArr = Object.assign(
      { dataChoiceProductions: this.state.dataChoiceProductions },
      this.state.data
    )
    await this.setState({
      data: LotDataWithProductionArr
    })
  }

  // запуск Перетожки
  async handleClickPeretojka() {
    ; (async () => {
      try {
        this.setState({ show: false })

        // todo: 0. Сохранить старый ID лота (localStorage)
        localStorage.setItem('oldIdLot', this.state.data.id)
        // console.log(`oldIdLot::: `, this.state.data.id) // test

        // *: 1. сгенерировать новый ID для ЛОТа
        const newId = nanoid()
        const data = this.state.data
        data.id = newId
        data.keyNameTrade = newId
        // console.log(`TEST ID:::: `, data.id); // test

        // todo: A. Сохранить новый ID лота (localStorage)
        localStorage.setItem('newIdLot', newId)
        // console.log(`newIdLot::: `, newId) // test

        // *: 2. добавить последовательное число через / к номеру лота
        data.numberLot = await addNumberPeretojka(this.state.data.numberLot)

        // *: 3. убрать все даты и время из ЛОТа
        data.dateTechStart = ''
        data.dateTech = ''
        data.dateTrade = ''
        data.timeTrade = ''
        data.timer = ''
        data.deliveryTime = ''
        data.agreementConclusionDate = ''
        data.chat = nanoid()

        // *: 4. сохранить обновленный ЛОТ и вывесвти его на экран
        this.setState({ data: data })

        await this.handleClickCreate() // сохраняем
        await this.runCreatePeretojka() // загружаем по новой
        this.setState({ show: true })

        // ? Наверно все же сделать этот процесс на бэкенде (перетожка) **************************************************************************
        // todo: B. Отправить на BACK старый и новый ID, для создания новой сводной таблицы из коллекции: trade_table_new
        // todo: Необходимо внести информацию в 'trade_confirmation'
        const resultPeretogka = await Server.startPeretogka(
          localStorage.getItem('oldIdLot'),
          localStorage.getItem('newIdLot')
        )

        // console.log(`resultPeretogka:::: `, resultPeretogka) // test

        // todo: X. Удалить все ID в localStorage
        setTimeout(() => {
          localStorage.removeItem('oldIdLot')
          localStorage.removeItem('newIdLot')
        }, 2000)
        // ? *************************************************************************************************************************
      } catch (err) {
        console.log(`Ошибка при запуске ПЕРЕТОРЖКИ: `, err)
      }
    })()
  }

  changePeretojka() {
    const date = Date.parse(this.state.data.dateTrade)
    const boolDate = date < Date.now()

    if (this.state.data.dateTrade && boolDate) {
      this.setState({ showPeretojka: true })
    } else {
      this.setState({ showPeretojka: false })
    }
  }

  async getFilesNames(files) {
    const stateCopy = Object.assign({}, this.state.data)
    stateCopy.filesChanged = !this.state.data.filesChanged

    await this.setState({
      filesNamesArr: files,
      data: stateCopy
    })
  }

  returnId(id) {
    // console.log(`Вернувшийся ID:::: `, id);
  }

  doChangeValueBank(e) {
    const res = {
      ...this.state.data,
      [e.target.name]: e.target.checked
    }
    this.setState({
      data: res
    })

    this.setState({
      stateBeenSaved: false
    })
  }

  closeTradePosition() {
    this.setState({ showRenderTradePosition: false })
  }

  async createNewTradePosition(id) {
    try {
      const resultTP = await Server.getDataFromServer(id, 'trade_position')

      if (resultTP.ERROR) throw new Error(resultTP.message)

      Object.assign(
        resultTP,
        { added: true },
        { id: resultTP.keyNameTrade },
        { comm: Number(resultTP.commercial[10].value) }
      )

      const dataTP = this.state.chosenPositions
      dataTP.push(resultTP)
      this.doGetChosenPositions(dataTP)
      this.rerenderPosition()
    } catch (err) {
      console.log(`Ошибка при изменении торг.поз.: `, err)
    }
  }

  async startTradePosition(id) {
    try {
      this.setState({ showRenderTradePosition: false })
      const result = await Server.getDataFromServer(id, 'trade_position')

      if (result.ERROR) throw new Error(result.message)

      const renderTradePosition = (
        <>
          <TradePosition
            show={true}
            nameTrade={result.nameTrade}
            dataTech={result.tech}
            dataCommerce={result.commercial}
            showTech={true}
            showCommercial={true}
            serverData={result}
            keyNameTrade={result.keyNameTrade}
            organizerId={result.organizerId}
            priority={result.priority}
            fileId={result.idFile}
            fileList={result.fileList}
            archive={result.archive}
            createDate={result.createDate}
            onChangeShowTrade={this.closeTradePosition} // команда на закрытие компонента
            readFromServerData={this.createNewTradePosition} // указывает, что создает новый файл торговой позиции, возвращает ID новой позиции
            openPositionModal={this.closeTradePosition}
          />
        </>
      )

      this.setState({
        renderTradePosition: renderTradePosition,
        showRenderTradePosition: true
      })
    } catch (err) {
      console.log(`Ошибка при загрузке торговой позиции: `, err)
    }
  }

  async openQuestionnairy(inn) {
    // console.log(`INN / ID >>>>> `, inn);
    //проверка ID на пренадлежность к компании или торговой позиции
    if (isNaN(Number(inn))) {
      await this.startTradePosition(inn)
    }
    await this.setState({
      innFromUniversalTable: inn
    })

    // заменил map на forEach
    this.state.chosenCompanies.forEach((el) => {
      if (el.id === inn) {
        // console.log(el);
        this.setState({
          companyQuestionnairieId: el.id,
          companyName: `${el.nameCompany}`
        })
      }
    })

    await this.setState({
      showQuestionnairy: true
    })
    localStorage.setItem('idAlien', this.state.companyQuestionnairieId)
  }

  async getEconomicData(data_) {
    const names = [
      'guaranteeAfterLaunch',
      'guaranteeAfterShipment',
      'prepayFromServer',
      'beforePayFromServer',
      'afterPayFromServer'
    ]

    data_.forEach((el) => {
      names.forEach((item) => {
        ; (async () => {
          if (el.id === item) {
            const res = await Object.assign({}, this.state.data)
            // const res = this.state.data
            res[item] = el.value
            this.setState({
              data: res
            })
          }
        })()
      })
    })

    const s = this.state.data
    const state = [s.prepayFromServer, s.afterPayFromServer, s.beforePayFromServer]

    state.forEach((el) => {
      names.forEach((name) => {
        Object.keys(s).forEach((key) => {
          if (!el && name === key) {
            const resN = Object.assign({}, this.state.data)
            resN[name] = 0
            this.setState({ data: resN })
          }
        })
      })
    })

    this.setState({
      stateBeenSaved: false
    })
  }

  //* получаем ID приглашения из EmailSend
  doSendIdInvite(id) {
    this.setState({ showInvite: false })
    // console.log(`ID INVITE up:::: `, id); // test
    const dataD = this.state.data

    if (!dataD.participantInvite) {
      dataD.participantInvite = [id]
    }

    if (dataD.participantInvite && !dataD.participantInvite.includes(id)) {
      dataD.participantInvite.push(id)
      this.setState({ data: dataD })
      setTimeout(() => {
        this.handleClickCreate()
        this.setState({ showInvite: true })
      }) // сохранение ЛОТа, ставим в очередь после обновления STATE
    } else {
      this.setState({ showInvite: true })
    }
  }

  //! ************************************************************ LEO chosenCompanies
  async sendCompaniesArray(arr) {
    await this.addInfoToChosenCompanies(arr)

    // console.log(`chosenCompanies : `, arr)

    this.setState({
      chosenCompanies: arr
    })

    this.setState({
      showTable: false
    })
    setTimeout(() => {
      this.setState({
        showTable: true
      })
    }, 600)
  }

  confirmLotStop() {
    ; (async () => {
      try {
        this.setState({
          stopLotModal: true,
          show: false
        })
        const resultStop = await stopTrade(this.state.data.keyNameTrade)

        if (resultStop.ERROR) throw new Error(resultStop.message)

        // console.log(`RESULT STOP TRADE ---> `, resultStop); // test
        // console.log(`STOP LOT with ID:::: `, this.state.data.keyNameTrade); // test

        if (resultStop) {
          this.setState({ showStopButton: true })
        } else {
          this.setState({ showStopButton: false })
        }
        this.setState({
          // showStopButton: false,
          show: true
        })
      } catch (err) {
        console.log(`Ошибка при остановке торгов: `, err)
      }
    })()
  }

  // * нажатие кнопки ПУСК, запуск процедуры торгов
  handleClickTrade() {
    ; (async () => {
      try {
        // await this.setState({
        //   isLaunched: true
        // })
        await this.addParticipantConfirmation()
        this.handleClickCreate() // сохранение лота
        // console.log(`CONFIRMATION>>>>>>>>>>> `, this.state.data);
        await Server.runTrade(this.state)


        // console.log(`ЗАПИСЬ ДАННЫХ УЧАСТИНКАМ ТОРГОВ::: `, result); // test
      } catch (err) {
        console.log(`Ошибка при внесении данных о торгах в БД участинков торгов: `, err)
      } finally {
        this.props.updateTable()
      }
    })()
    this.props.handleLotModal()
    this.props.showSuccesfullyLaunchedMsg()
  }

  doChangeValueWithVerification() {
    //todo оповестить пользователя (для бекэнда)

    // start 2021 - 12 - 29T11: 36: 01.598Z
    const dataNew = this.state.data
    const start = +dataNew.dateTechStart
    const finish = +dataNew.dateTech
    const trade = +dataNew.dateTrade
    const deliveryDate = +dataNew.deliveryTime
    const agreementDate = +dataNew.agreementConclusionDate

    if (dataNew.dateTechStart && dataNew.dateTech) {
      if (finish < start) {
        this.setState({
          finishBeforeStartError: true
        })
      } else {
        this.setState({
          finishBeforeStartError: false
        })
      }
    }

    if (dataNew.dateTech && dataNew.dateTrade) {
      if (finish > trade) {
        this.setState({
          finishBeforeTradeError: true
        })
      } else {
        this.setState({
          finishBeforeTradeError: false
        })
      }
    }

    if (dataNew.dateTechStart && dataNew.dateTrade) {
      if (start > trade) {
        this.setState({
          tradeBeforeStartError: true
        })
      } else {
        this.setState({
          tradeBeforeStartError: false
        })
      }
    }

    if (dataNew.agreementConclusionDate && dataNew.deliveryTime) {
      if (deliveryDate < agreementDate) {
        this.setState({
          deliveryBeforeAgreementError: true
        })
      } else {
        this.setState({
          deliveryBeforeAgreementError: false
        })
      }
    }
  }

  doGetArrayChoice(arr) {
    this.setState({ serverData: arr })
    // console.log('ARR >>>>>>>>>>>>>>> ', arr);
  }

  rerenderCompany() {
    this.setState({
      rerenderCompany: false
    })
    setTimeout(() => {
      this.setState({
        rerenderCompany: true
      })
    })
  }

  rerenderPosition() {
    this.setState({
      rerenderPosition: false
    })
    setTimeout(() => {
      this.setState({
        rerenderPosition: true
      })
    })
  }

  // формирование массива выбранных торговых позиций
  doGetChosenPositions(positionsArr) {
    ; (async () => {
      try {
        await this.setState({ chosenPositions: positionsArr })
        this.rerenderPosition()

        //вносим позиции в DATA
        const DATA = Object.assign({}, this.state.data)
        DATA.position = positionsArr
        this.setState({ data: DATA })

        // функция сумму торговых позиций
        this.setState({ showPrice: false })
        this.addPriceCompany(positionsArr)
        this.setState({ showPrice: true })
      } catch (err) {
        console.log(`Ошибка при получении выбранных торговых позиций: `, err)
      }
    })()
  }

  //получение chosenCompanies выбранных находясь внутри создния лота
  doGetChosenCompanies(companiesArr) {
    ; (async () => {
      try {
        // console.log(`chosenCompanies: `, companiesArr) // test

        await this.setState({
          chosenCompanies: companiesArr
        })
        this.rerenderCompany()
      } catch (err) {
        console.log(`Ошибка при получении выбранных компаний: `, err)
      }
    })()
  }

  //подгружает список компаний, выбранных в поиске
  getChosenCompaniesFromSearch() {
    const companies = this.props.chosenCompaniesFromProductionSearch

    if (companies && companies.length) {
      // console.log(`chosenCompanies:: `, companies); // test
      this.setState({
        chosenCompanies: companies
      })
    }
  }

  doGetTradeObject(obj) {
    this.setState({
      tradeObject: obj
    })
  }

  doGetNewPosName(posName) {
    this.setState({
      newPosName: posName
    })
  }

  async addPriceCompany(data) {
    let resultAdd = 0
    data.forEach((elem) => {
      if (elem.comm && Number(elem.comm) === 0) {
        resultAdd = resultAdd + 'zero'
      } else {
        // console.log(`COMM::: ${elem.comm} => `); // test
        resultAdd = resultAdd + Number(elem.comm)
      }
    })
    // console.log(`RESULT PRICE:::::::: `, resultAdd); // test

    if (!Number(resultAdd) > 0) {
      resultAdd = 'Общая стоимость лота не определена, т.к. в лоте присутствуют позиции с ценой 0'
    }

    if (!resultAdd) {
      resultAdd = 'Общая стоимость лота не определена'
    }

    const DATA = this.state.data
    DATA.price = resultAdd

    if (Number(resultAdd) > 0) {
      DATA.ndsPrice = resultAdd * this.state.data.nds + resultAdd
    } else {
      DATA.ndsPrice = resultAdd
    }

    await this.setState({ data: DATA })
  }

  doGetChosenIdies(idiesArr) {
    this.setState({
      chosenIdies: idiesArr
    })
    const newData = Object.assign({}, this.state.data)
    newData.participant = idiesArr
    this.setState({ data: newData })

    // console.log(`ID COMPANY :::: `, this.state.data.participant);
  }

  handleCompanyModalQuit() {
    this.setState({
      companyModal: false
    })
    this.setProductKind()
  }

  async searchChoiceCompany(data) {
    // console.log(`DATA CREATE LOT !!!!!! --------- >>>> `, data); // test
    try {
      const result = await Server.searchAllCompany(data)
      if (result.err) {
        throw new Error(result.message)
      }
      // исключаем свою компанию из списка
      let newResult = result.filter((item) => {
        return item.idCompany !== this.state.idUser
      })

      await this.setState({ company: newResult })
    } catch (err) {
      console.log(`Ошибка получения массива компаний с сервера: `, err)
    }
  }

  async readFromServerData() {
    try {
      const data = await Server.searchAllPositions()

      if (data.ERROR) {
        throw new Error(data.message)
      }

      // * фильтруем торг.поз. по полю - 'archive'
      const result = data.filter((pos) => pos.archive !== true)
      this.setState({ serverData: result })
    } catch (err) {
      console.log(`Ошибка при получении массива торговых позиций с сервера: `, err)
    }
  }

  async openCompanyModal() {
    try {
      await this.setAllCompaniesFromServerToCompanyModal()

      if (this.state.serverCompanies && this.state.serverCompanies.length) {
        this.setState({
          companyModal: true
        })
      } else {
        await this.setAllCompaniesFromServerToCompanyModal()
        this.setState({
          companyModal: true
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  async openPositionModal() {
    try {
      const resultAllProducts = await Server.getDataFromServer(
        'productionArray',
        localStorage.getItem('idUser')
      )

      if (resultAllProducts && resultAllProducts.length && !resultAllProducts.ERROR) {
        this.setState({
          serverData: resultAllProducts
        })

        setTimeout(() => {
          this.setState({
            positionModal: !this.state.positionModal
          })
        })
      } else {
        setTimeout(() => {
          this.setState({
            positionModal: !this.state.positionModal
          })
        })
      }

      if (resultAllProducts.ERROR) {
        throw new Error(resultAllProducts.message)
      }
    } catch (err) {
      console.log(err)
    }
  }

  switchPositionModal() {
    this.setState({
      positionModal: false
    })

    this.setState({
      positionModal: true
    })
  }

  doCreateIdChat(id) {
    let dataNew = this.state.data
    dataNew.chat = id
    this.setState({ data: dataNew })
  }

  // вкл/выкл чата
  doChangeVisibleChat() {
    this.setState({ showChat: !this.state.showChat })
  }

  doChangeShowTrade() {
    this.setState({ showTradePosition: false })
  }

  async handleClickCreateTradePosition() {
    const fileId = nanoid()
    const dataD = this.state.data
    dataD.fileIdTradePosition = fileId
    this.setState({ data: dataD })
    this.setState({ showTradePosition: true, positionModal: false })
  }

  doEmpty(e) {
    // console.log(`doEmpty click`, e); //test
  }

  //* данные с полей формы, формируются в массив объектов
  doChangeValue(data) {
    ; (async () => {
      try {
        // const dataNew = this.state.data

        // dataNew[data.id] = data.value
        // this.setState({ data: dataNew })

        // * отключено. Перевод локального времени в московское
        // if (data.id === 'timeTrade') {
        //   console.log(`time go to in this area >>>>>> `, data.value) // test
        //   // *: перевести данные в МСК согласно данной timezone (timezone -> MSK)
        //   const resultMCK = await changeTimeToMoscow(data.value, localStorage.getItem('idUser'))
        //   console.log(`Time MSK: `, resultMCK) // test
        //   this.setState({ changeTime: true })
        //   data.value = resultMCK
        // }

        //********************* */

        const stateCopy = Object.assign({}, this.state.data)
        stateCopy[data.id] = data.value
        this.setState({ data: stateCopy })

        //********************* */

        if (
          data.description === 'Планируемая дата заключения договора' ||
          data.description === 'Срок поставки'
        ) {
          this.doChangeValueWithVerification()
        }

        if (data.id === 'deliveryTime') this.setDaysDifference()

        this.setState({
          stateBeenSaved: false
        })
      } catch (err) {
        console.log(`Ошибка при изменении value: `, err)
      }
    })()
  }

  // создаем ID чата, созадем чат в DB
  async createChat() {
    try {
      const DATA = this.state.data
      DATA.chat = nanoid()
      await this.setState({ data: DATA })

      const dataChat = [
        {
          timeChat: Date.now(),
          name: this.state.idUser,
          forName: 'all',
          filterAll: ['all'], // сообщение всем / не всем (ID company)
          filterQuestion: '', // место для вопроса, запроса (доп. фильтр)
          message: 'Новый чат'
        }
      ]
      await Server.sendDataOnServer(dataChat, this.state.data.chat, 'trade_chat')
      // console.log(`Чат созадн: `, resultNewChat);
    } catch (err) {
      console.log(`Ошибка при создании чата: `, err)
    }
  }

  // todo: переместить в .js
  async addParticipantConfirmation() {
    try {
      let arrConditions = this.state.data.participant.map((id) => {
        return {
          id: id,
          confirmation: '',
          idConfirmation: ''
        }
      })

      if (this.state.data.participantInvite && this.state.data.participantInvite.length) {
        const arrId = this.state.data.participantInvite
        // console.log(`ID PARTICIPANT:::::::: `, arrId); // test

        const promisesArr = arrId.map((id) => {
          return (async () => {
            const result = await Server.searchUniversal('invite', '_id', id)
            return result
          })()
        })

        const arrInvite = await Promise.all(promisesArr)
        // console.log(`ARRAY INVITE in CONDITIONS:::: `, arrInvite) // test

        arrInvite.forEach((data, index) => {
          data.forEach((item) => {
            // console.log(`INVITE INN:::: `, item.inviteINN) // test

            arrConditions.push({
              id: item.inviteINN,
              confirmation: '',
              idConfirmation: ''
            })
          })
        })
      }

      const arrData = this.state.data
      arrData.participantConditions = arrConditions
      this.setState({ data: arrData })
    } catch (err) {
      console.log(`Ошибка при формировании массива - participantConditions: `, err)
    }
  }

  async clearParticipantCondition() {
    const data = this.state.data
    const arrData = data.participantConditions

    const participantConditions = arrData.map((item) => {
      if (item.confirmation === 'Отказ') {
        return item
      } else {
        item.confirmation = ''
        return item
      }
    })
    // console.log(`participantCondition:::::: `, arrData); // test
    // console.log(`new condition:::::: `, participantConditions); // test
  }

  // промежуточное сохранение лота
  async handleClickCreate() {
    ; (async () => {
      try {
        await this.setAllCompaniesFromServerToCompanyModal()
        if (!this.state.data.chat) await this.createChat() // запускаем создание чата, если не был создан

        // TODO: функция сброса 'paticipantCondition'
        await this.clearParticipantCondition()

        await Server.sendDataOnServer(this.state, this.state.data.id, 'trade') // сохраняем весь STATE
        // console.log(this.state); // test
        this.props.rerenderLotsTable && this.props.rerenderLotsTable()
      } catch (err) {
        console.log(`Ошибка сохранения лота: `, err)
      }
    })()
    // this.showSuccesfullySavedMsg()
    await this.setProductKind()

    if (this.state.showQuitExit) {
      await this.setState({
        showQuitExit: false
      })
      this.props.handleLotModal()
    }

    if (this.state.showQuit) {
      await this.setState({
        showQuit: false
      })
      this.props.handleLotModal()
    }
  }

  async handleClickCreateNoClose() {
    ; (async () => {
      try {
        await this.setAllCompaniesFromServerToCompanyModal()
        if (!this.state.data.chat) await this.createChat() // запускаем создание чата, если не был создан

        // TODO: функция сброса 'paticipantCondition'
        await this.clearParticipantCondition()

        await Server.sendDataOnServer(this.state, this.state.data.id, 'trade') // сохраняем весь STATE
        // console.log(this.state); // test
        this.props.rerenderLotsTable && this.props.rerenderLotsTable()
      } catch (err) {
        console.log(`Ошибка сохранения лота: `, err)
      }
    })()
    // this.showSuccesfullySavedMsg()
    await this.setProductKind()
    if (this.state.showQuit) {
      this.setState({
        showQuit: false
      })
    }

    this.toggleSaveButton()
  }

  // todo: переместить в .js
  // создаем нумерацию лота
  async createNumberLot() {
    try {
      // получаем год
      const date = String(new Date())
      const arrDate = date.split(' ')[3]
      const year = arrDate.split('')[2] + arrDate.split('')[3] // год

      //получаем порядковый номер компании
      const numberSerial = localStorage.getItem('serial')
      const lengthSerial = String(numberSerial).split('').length

      if (lengthSerial >= 4) {
        this.serial = numberSerial
      } else if (lengthSerial === 3) {
        this.serial = `0${numberSerial}`
      } else if (lengthSerial === 2) {
        this.serial = `00${numberSerial}`
      } else if (lengthSerial === 1) {
        this.serial = `000${numberSerial}`
      }

      //получаем порядковый номер торгов
      const allTradeLots = await Server.searchAllTrade()
      if (!allTradeLots.err) {
        const lengthLotsFirst = allTradeLots.length
        const lengthLots = String(lengthLotsFirst).split('').length

        if (lengthLots >= 4) {
          this.lots = allTradeLots.length
        } else if (lengthLots === 3) {
          this.lots = `0${allTradeLots.length}`
        } else if (lengthLots === 2) {
          this.lots = `00${allTradeLots.length}`
        } else if (lengthLots === 1) {
          this.lots = `000${allTradeLots.length}`
        }
      }
      const numLot = this.state.data
      numLot.numberLot = `${year}_${this.serial}_${this.lots}` // нумерация лота
      numLot.organizerId = this.state.idUser // организатор
      numLot.keyNameTrade = nanoid() // ID ЛОТА
      numLot.id = numLot.keyNameTrade // дублирование в >ID
      await this.setState({ data: numLot })
    } catch (err) {
      console.log(`Ошибка создания номера лота: `, err)
    }
  }

  async runCreatePeretojka() {
    try {
      if (this.state.data.id) {
        // загружаем весь STATE с сервера
        // console.log(`RUN LOT with DATA :::: `, this.props.id);// test

        const allState = await Server.getDataFromServer(this.state.data.id, 'trade')

        if (allState.ERROR) {
          throw new Error(allState.message)
        }
        this.setState((state) => {
          return allState
        })
        this.changePeretojka()
        this.setState({ show: true })

        await this.readFromServerData()
      } else {
        //сбор данных при первом входе (загрузка в STATE данных о виде торгов и виде деятельности)
        const tradeDATA = this.state.data
        tradeDATA.tradeKind = this.props.radioResult //* вид торгов
        await this.setState({
          data: tradeDATA,
          radioResult: this.props.radioResult, //* вид деятелности
          dataChoiceProductions: this.props.dataChoiceProductions
        })
        await this.createNumberLot()
        await this.searchChoiceCompany(this.props.dataChoiceProductions)
        await this.readFromServerData(this.state.serverData)

        this.setState({ show: true })
      }
    } catch (err) {
      console.log(`Ошибка при запуске CreateLot: `, err)
      // console.log(err);
    }
  }

  async runCreate() {
    try {
      if (this.props.id) {
        // загружаем весь STATE с сервера
        const allState = await Server.getDataFromServer(this.props.id, 'trade')

        if (allState.ERROR) {
          throw new Error(allState.message)
        }
        this.setState((state) => {
          return allState
        })
        this.setState({ showQuit: false, savedDataMessage: false, showQuitExit: false })
        this.changePeretojka()
        this.setState({ show: true })

        await this.readFromServerData()
      } else {
        //сбор данных при первом входе (загрузка в STATE данных о виде торгов и виде деятельности)
        const tradeDATA = this.state.data
        tradeDATA.tradeKind = this.props.radioResult //* вид торгов
        await this.setState({
          data: tradeDATA,
          radioResult: this.props.radioResult, //* вид деятелности
          dataChoiceProductions: this.props.dataChoiceProductions
        })
        await this.setProductionArrayToState()
        await this.createNumberLot()
        await this.searchChoiceCompany(this.props.dataChoiceProductions)
        await this.readFromServerData(this.state.serverData)

        this.setState({ show: true })
      }
    } catch (err) {
      console.log(`Ошибка при запуске CreateLot: `, err)
      // console.log(err);
    }
  }

  //проверяем откуда пришли данные
  async checkCompaniesDataSource() {
    try {
      const result = await Server.getDataFromServer('updatedChosenCompanies', this.state.idUser)

      //если данные есть на сервере, то сетаем в стейт их
      if (result && result.length > 0 && !result.ERROR) {
        // console.log(`chosenCompanies::: `, result); // test
        await this.setState({
          chosenCompanies: result
        })
      }
      //если данные пришли с пропсов, то сетаем в стейт их
      if (
        this.props.chosenCompaniesFromProductionSearch &&
        this.props.chosenCompaniesFromProductionSearch.length > 0
      ) {
        // console.log(`chosenCompanies::: `, this.props.chosenCompaniesFromProductionSearch); // test
        await this.setState({
          chosenCompanies: this.props.chosenCompaniesFromProductionSearch
        })
      }
      //если в стейте есть participant, то пробегаемся по списку со всеми компаниями, и в стейт сетаем лишь те, чьи инн совпадают с participant
      if (this.state.data.participant.length !== 0) {
        const res1 = await Server.getDataFromServer('Main', this.state.data.participant)

        const companies = res1.map((el) => {
          return {
            nameCompany: `${el[100].value} ${el[1].value}`,
            ownership: el[100].value,
            country: el[14].value,
            region: el[15].value,
            inn: el[6].value,
            id: el[6].value
          }
        })

        // console.log(`chosenCompanies: `, companies); // test
        this.setState({
          chosenCompanies: companies
        })
      }

      if (result.ERROR) {
        throw new Error(result.message)
      }
    } catch (err) {
      console.log(`Ошибка в проверке пути данных: `, err)
    }
  }

  //comment for test sake
  doIdFile() {
    if (!this.state.data.idFile) {
      this.setState({ showFile: false })
      const id = nanoid()
      const dataD = this.state.data
      dataD.idFile = id

      this.setState({
        data: dataD,
        showFile: true
      })
    }
  }

  // todo: перенести в .js
  async checkupRunTrade() {
    try {
      const resultRunTrade = await Server.searchUniversal('global', '_id', 'current_trade')
      // console.log(`resultRunTrade::::: `, resultRunTrade);

      const id = this.state.data.id
      const arr = resultRunTrade[0].active

      const result = arr.includes(id)
      // console.log(`RESULT RUN TRADE: `, result); // test

      this.setState({ showStopButton: result })

      console.log(resultRunTrade);
    } catch (err) {
      console.log(`Ошибка получения данных по запущенным торгам: `, err)
    }
  }

  // todo: перенести в .js
  setCorrectWordForm(num) {
    let module = Math.abs(num) % 100
    if (module >= 5 && module <= 20) {
      return num + ' дней'
    }

    module = Math.abs(num) % 10
    if (module === 1) {
      return num + ' день'
    }
    if (module > 1 && module < 5) {
      return num + ' дня'
    } else {
      return num + ' дней'
    }
  }

  setDaysDifference() {
    const d = this.state.data
    const difference = setDaysDifference(d.deliveryTime, d.agreementConclusionDate, this.state.deliveryBeforeAgreementError)
    this.setState({
      daysDifference: difference?.stringMessage
    })
    // const d = this.state.data
    // const agreement = new Date(d.agreementConclusionDate)
    // const delivery = new Date(d.deliveryTime)

    // const timeDiff = Math.abs(delivery.getTime() - agreement.getTime())
    // const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))

    // if (agreement && delivery) {
    //   const d = delivery.getTime() - agreement.getTime()
    //   if (d > 0) {
    //     this.setState({
    //       daysDifference: `Итого разница: ${this.setCorrectWordForm(daysDiff)}`
    //     })
    //   }
    // }

    // if (this.state.deliveryBeforeAgreementError) {
    //   this.setState({
    //     daysDifference: ''
    //   })
    // }

    // if (!d.agreementConclusionDate || !d.deliveryTime) {
    //   this.setState({
    //     daysDifference: 'Итого разница: ___ дней'
    //   })
    // }

    // if (timeDiff === 0) {
    //   this.setState({
    //     daysDifference: `Итого разница: 0 дней`
    //   })
    // }
  }

  //todo: перенести в .js
  setLotStatus() {
    const names = [
      'Черновики',
      'Для согласования',
      'На согласовании',
      'Требуется дата торгов',
      'Плановые торги',
      'Текущие торги',
      'Идущие торги',
      'Завершенные торги'
    ]

    names.forEach((el) => {
      if (el === this.props.nameTable) {
        this.setState({
          lotStatus: this.props.nameTable
        })
      }
    })
  }

  async setDataFromProductionSearch() {
    if (this.props.dataChoiceProductions && this.props.dataChoiceProductions.length !== 0) {
      await this.setState({
        dataChoiceProductions: this.props.dataChoiceProductions
      })
    }

    if (this.props.radioResult) {
      await this.setState({
        radioResult: this.props.radioResult
      })
    }
  }

  // todo: перенести в .js
  async setAllCompaniesFromServerToCompanyModal() {
    const companiesArrayFromServer = await Server.getDataFromServer(
      'companiesArrayFromServer',
      this.state.idUser
    )
    // const searchProduct = await Server.getDataFromServer('product', this.state.idUser)
    try {
      if (
        companiesArrayFromServer &&
        companiesArrayFromServer.length > 0 &&
        !companiesArrayFromServer.ERROR
      ) {
        this.setState({
          serverCompanies: companiesArrayFromServer
        })
      }

      if (companiesArrayFromServer.err) {
        throw new Error(companiesArrayFromServer.message)
      }
    } catch (err) {
      console.log('Ошибка (нет данных): ', err)
    }
  }

  // todo: перенести в .js
  async setProductKind() {
    const res = await Server.getDataFromServer('product', this.state.idUser)
    if (res) {
      if (res.product && res.formName) {
        this.setState({
          product: res.product,
          formName: res.formName
        })
      }
    }
  }

  async addInfoToChosenCompanies(arr) {
    // console.log(`COMPANI after chosen >>>>> `, arr); // ! test **********************************
    let chosenCompaniesWithMoreInfo = []

    arr.forEach((el) => {
      this.state.data.participantConditions.forEach((item) => {
        if (el.inn === item.id) {
          if (!item.confirmation) {
            item.confirmation = 'На подтверждении'
          }
          chosenCompaniesWithMoreInfo.push(Object.assign(el, item))
        }
      })
    })

    // console.log(`chosenCompanies::: `, chosenCompaniesWithMoreInfo);
    await this.setState({
      chosenCompanies: chosenCompaniesWithMoreInfo
    })

    return chosenCompaniesWithMoreInfo
  }

  toggleFormsFilledByParticipant(id) {
    this.setState({
      showModalOfParticipantForms: !this.state.showModalOfParticipantForms,
      filledFormCompanyId: id
    })
  }

  // проверка на окончание времени согласований
  lookTimeTechOut() {
    if (Date.parse(this.state.data.dateTech) <= Date.now()) {
      return true
    } else {
      return false
    }
  }

  compare() {
    const prev = this.state.previousState
    const curr = this.state.data
    const isStateChanged = isEqual(prev, curr)
    if (isStateChanged) {
      return true
    } else {
      return false
    }
  }

  async smartWindowFunction() {
    this.props.updateTable()
    const areStatesEqual = this.compare()
    const wasLaunched = await Server.lookRunTrade(this.state.data.id)

    if (areStatesEqual || this.state.stateBeenSaved) {
      this.closeWithoutModal()
    }
    if (!areStatesEqual && wasLaunched) {
      this.setState({
        showQuit: true,
        saveFromClose: true
      })
    } else {
      this.closeWithModal()
    }
  }

  async componentDidMount() {
    // this.setState({
    //   isLotLaunched: false
    // })
    await this.setProductKind()
    // добавляем назване компании организатора в ЛОТ
    const organizer = await Server.getDataFromServer('Main', localStorage.getItem('idUser'))
    const dataD = this.state.data
    dataD.organizerName = organizer[1].value
    this.setState({ data: dataD })

    await this.runCreate()

    if (this.props.id || this.state.data.id) {
      // const prev = JSON.parse(JSON.stringify(this.state.data))
      const prev = Object.assign({}, this.state.data)
      this.setState({
        previousState: prev
      })
      // console.log('here', this.state.previousState)
    }
    this.rerenderCompany()
    this.getChosenCompaniesFromSearch()
    this.doIdFile()
    await this.addInfoToChosenCompanies(this.state.chosenCompanies)

    await this.checkupRunTrade() // проверка на запуск лота

    //перед тем как проверить откуда пришли данные, проверяем, если они к открытию компонента уже есть, то в participant сетаем инн этих компаний
    if (this.state.chosenCompanies && this.state.chosenCompanies.length !== 0) {
      // console.log('0900---------', this.state.chosenCompanies);
      const res = this.state.chosenCompanies.map((el) => el.inn)
      const dataD = this.state.data

      // console.log(`!!!!! res in participant::: `, res); // test
      dataD.participant = res

      this.setState({
        data: dataD
      })
    }

    if (this.state.data.participantInvite && this.state.data.participantInvite.length > 0) {
      this.setState({ showInvite: true })
    }

    setTimeout(async () => {
      await this.checkCompaniesDataSource()
    })

    const d = this.state.data

    await this.setState({
      totalProcent: +d.prepayFromServer + +d.afterPayFromServer + +d.beforePayFromServer
    })

    if (d.bankContractExecutionGuaranteeInput !== '') {
      this.setState({
        bankContractExecutionGuarantee: true
      })
    }

    this.setDaysDifference()
    this.setLotStatus()
    await this.setDataFromProductionSearch()
    await this.setAllCompaniesFromServerToCompanyModal()
    await this.setState({
      showQuit: false
    })
  }

  componentWillUnmount() {
    Server.deleteDocument('product') //нужен чтобы прокидывать в SearchInputGroup (чтобы отрисовывать кнопку фильтра и приглашения по email)
    Server.deleteDocument('companiesArrayFromServer') //весь результат поиска вместе с актуальными полями added
    Server.deleteDocument('updatedChosenCompanies') //нужен когда спиок ТОЛЬКО выбранных компаний обновляется в модалке
    Server.deleteDocument('productionArray')
    localStorage.removeItem('idAlien')
  }

  componentDidUpdate(prevProps, prevState) {
    const data = this.state
    if (
      data.finishBeforeStartError !== prevState.finishBeforeStartError ||
      data.finishBeforeTradeError !== prevState.finishBeforeTradeError ||
      data.tradeBeforeStartError !== prevState.tradeBeforeStartError
    ) {
      this.disableConditionForLaunch(this.state.data)
    }
  }

  render() {
    // console.log(`DATA from server:::::::: `, this.state.data.dateTechStart); // test

    // const isStatesEqual = isEqual(this.state.previousState, this.state.data)
    // console.log('timer', this.state.data);
    const saveFromClose = this.state.saveFromClose
    const data = this.state.data
    const numberLot = this.state.data.numberLot
    let curr = ''
    let nds = ''

    if (data.currency) {
      curr = CURRENCY[data.currency][0]
    }

    if (data.ndsPrice) {
      nds = data.ndsPrice.toLocaleString('ru')
    }

    const currencyPlaceholder = `Итоговая стоимость, ${curr}`

    return (
      <>
        <div
          className='shadow_form'
          // onClick={'
          //   this.compare(this.state.previousState, this.state.data) ? this.closeWithModal : this.closeWithoutModal
          // }
          onClick={this.smartWindowFunction}
        ></div>

        {this.state.showModalOfParticipantForms && (
          <ParticipantConfirmationLot
            idLot={this.state.data.id}
            toggleConfirmationLot={this.toggleFormsFilledByParticipant}
            inn={this.state.filledFormCompanyId}
            companies={this.state.chosenCompanies}
          />
        )}

        <div className='z_index_19 wrapper_lot_content_modal'>
          <Alert
            variant='dark'
            dismissible
            // onClose={this.handleQuitModal}
            onClose={this.smartWindowFunction}
          >
            <Alert.Heading>Создание торгов</Alert.Heading>
          </Alert>

          <div>
            {this.state.show ? (
              <>
                {this.state.showTradePosition && (
                  <TradePosition
                    show={true}
                    onChangeShowTrade={this.doChangeShowTrade}
                    onGetNewPosName={this.doGetNewPosName}
                    onGetTradeObject={this.doGetTradeObject}
                    readFromServerData={this.readFromServerData}
                    openPositionModal={this.openPositionModal}
                    fileId={this.state.data.fileIdTradePosition} //прокидываю до таблицы вне модалки ann
                  />
                )}

                {this.state.showChat && (
                  <ChatWs
                    onChangeVisibleChat={this.doChangeVisibleChat}
                    onCreateIdChat={this.doCreateIdChat}
                    idChat={this.state.data.chat}
                    forName='all' // test
                    idCompany={this.state.data.participant}
                    idBox={this.state.data.id}
                    updateQuantityMessage={this.doEmpty}
                    modalWidth={'width_90'}
                  />
                )}

                {this.state.showRenderTradePosition && this.state.renderTradePosition}

                <Form className='wrapper_lot_content_modal_content'>
                  {this.state.showQuit && (
                    <QuitModal
                      onQuitWithSave={
                        saveFromClose ? this.handleClickCreate : this.handleClickCreateNoClose
                      }
                      // onQuitWithSave={this.handleClickOnSaveButton}
                      onQuitWithoutSave={saveFromClose ? this.handleLotModal : this.handleQuitModal}
                      closeQuitModal={this.handleQuitModal}
                      modalTitle={saveFromClose ? 'Выход' : 'Сохранение'}
                      modalBody={
                        'Данный лот находится на стадии согласования с участниками, при сохранении изменений, всем участникам придется заново зайти в лот и подтвердить новые требования.'
                      }
                      primaryButton={saveFromClose ? 'Сохранить и выйти' : 'Сохранить'}
                      secondaryButton={saveFromClose ? 'Выйти без сохранения' : 'Не сохранять'}
                      icon={
                        saveFromClose ? (
                          <ExitToAppSharpIcon fontSize='large' className='icon_leave_modal' />
                        ) : (
                          <SaveOutlinedIcon fontSize='large' color='primary' />
                        )
                      }
                    />
                  )}

                  {this.state.showQuitExit && (
                    <QuitModal
                      onQuitWithSave={this.handleClickCreate}
                      onQuitWithoutSave={this.handleLotModal}
                      closeQuitModal={() => this.setState({ showQuitExit: false })}
                      modalTitle={'Выход'}
                      modalBody={'Сохранить изменения?'}
                      primaryButton={'Сохранить'}
                      secondaryButton={'Выйти без сохранения'}
                      icon={<ExitToAppSharpIcon fontSize='large' className='icon_leave_modal' />}
                    />
                  )}

                  <Row>
                    <Col style={{ textAlign: 'center', marginTop: '20px' }}>
                      <span>
                        <h5 style={{ display: 'inline', marginTop: '5px' }}>Лот №: {numberLot} </h5>
                        {
                          <h5 style={{ display: 'inline', paddingBottom: '5px' }}>
                            {' '}
                            <Badge pill variant='primary'>
                              {this.state.radioResult}
                            </Badge>{' '}
                          </h5>
                        }
                      </span>
                      <Badge variant='secondary'>
                        {this.state.lotStatus === '' ? 'Черновики' : this.state.lotStatus}
                      </Badge>
                    </Col>
                  </Row>

                  <Row> &nbsp; </Row>
                  {/* ПЕРВЫЙ БЛОК --- ОСНОВНАЯ ИНФОРМАЦИЯ */}
                  <Card style={{ border: '1px #ccc solid' }} className='box_shadow card_block'>
                    <Card.Header as='h6' className='trade_card_header'>
                      1. Наименование лота{' '}
                    </Card.Header>

                    <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                      <Row> &nbsp; </Row>
                      <Row>
                        <InputForm
                          id={'nameLot'}
                          width={12}
                          show={true}
                          color={'#ccc'}
                          fontSize={'12px'}
                          placeholder='Введите название лота'
                          description='Торги, название лота'
                          value={data.nameLot}
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                        />
                      </Row>
                      <Row> &nbsp; </Row>
                      <Row>
                        <Col>
                          {
                            <h5>
                              Категории лота:
                              {this.state.dataChoiceProductions.map((d) => (
                                <span key={d}>
                                  {' '}
                                  <Badge pill variant='secondary'>
                                    {d}
                                  </Badge>{' '}
                                </span>
                              ))}
                            </h5>
                          }
                        </Col>
                      </Row>
                      <Row> &nbsp; </Row>
                    </Card.Body>
                  </Card>

                  <Row> &nbsp; </Row>

                  {/* ВТОРОЙ и ТРЕТИЙ БЛОК --- УЧАСТНИКИ ЛОТА / ПОЗИЦИИ ЛОТА */}
                  <Card
                    style={{ border: '1px #ccc solid' }}
                    className='box_shadow card_block_no_position'
                  >
                    <Card.Header as='h6' className='trade_card_header'>
                      {' '}
                      2. Участники лота
                    </Card.Header>

                    <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                      {this.state.companyModal && (
                        <TradeCompanyModal
                          handleCompanyModalQuit={this.handleCompanyModalQuit}
                          onGetChosenIdies={this.doGetChosenIdies}
                          onGetChosenCompanies={this.doGetChosenCompanies}
                          dataChoiceProductions={this.props.dataChoiceProductions}
                          rerenderCompany={this.rerenderCompany}
                          infoBlock={this.props.infoBlock}
                          onUpInfoBlock={this.props.onUpInfoBlock}
                          viewQuest={this.props.viewQuest}
                          doSendDataChoice={this.props.doSendDataChoice}
                          sendCompaniesArray={this.sendCompaniesArray}
                          getAllCompaniesFromSearch={this.props.getAllCompaniesFromSearch}
                          serverCompanies={this.state.serverCompanies}
                          doSendIdInvite={this.doSendIdInvite}
                          idLot={this.state.data.id}
                          getEconomicData={this.props.getEconomicData}
                          toggleLotModal={this.props.toggleLotModal}
                          formName={this.state.formName}
                          product={this.state.product}
                        />
                      )}
                      {(this.state.chosenCompanies.length === 0 || !this.state.showTable) && (
                        <TableSkeleton firstTh='Название' secondTh='Город' />
                      )}

                      {this.state.showTable &&
                        this.state.rerenderCompany &&
                        this.state.chosenCompanies.length > 0 && (
                          <UniversalTable
                            favourite={true}
                            rate={true}
                            isRowGreen={false}
                            removable={true}
                            selectable1={false}
                            selectable2={true}
                            tableName={
                              <>
                                <CheckOutlinedIcon className='pb_3' color='action' /> Компании лота
                              </>
                            }
                            titleObjectData={{
                              numbered: 'п/п',
                              nameCompany: 'Название',
                              confirmation: 'Статус',
                              country: 'Страна',
                              region: 'Город',
                              rate: 'Рейтинг'
                            }}
                            openQuestionnairy={this.openQuestionnairy}
                            renderedArray={this.state.chosenCompanies}
                            renderedArrayWithAdded={this.doEmpty}
                            chosenObjectsArray={this.doGetChosenCompanies}
                            chosenIdsArray={this.doGetChosenIdies}
                            chosenObjectId={this.doEmpty}
                            handleCancel={this.doEmpty}
                            handleModalToggle={this.doEmpty}
                            showHowMuchFilled={true}
                            toggleFormsFilledByParticipant={this.toggleFormsFilledByParticipant}
                          />
                        )}
                      <Row> &nbsp; </Row>
                      {this.state.showInvite && (
                        <LookInviteEmail participantInvite={this.state.data.participantInvite} />
                      )}
                    </Card.Body>
                    <AllModalsFooter
                      footerContent={
                        <>
                          <AddButton
                            handleSaveClick={this.openCompanyModal}
                            needDisable={false}
                            buttonText='компании'
                          />

                          <InviteByEmail
                            doSendIdInvite={this.doSendIdInvite}
                            idLot={this.state.data.id}
                            btnText={'Пригласить компании'}
                            icon={true}
                            fromSearch={false}
                          />
                        </>
                      }
                    />
                  </Card>
                  <Row> &nbsp; </Row>
                  <Card
                    style={{ border: '1px #ccc solid' }}
                    className='box_shadow card_block_no_position'
                  >
                    <Card.Header as='h6' className='trade_card_header'>
                      {' '}
                      3. Позиции лота
                    </Card.Header>

                    <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                      <Row> &nbsp; </Row>

                      {this.state.positionModal && (
                        <TradePositionModal
                          positionModal={this.state.positionModal}
                          serverData={this.state.serverData}
                          openPositionModal={this.openPositionModal}
                          onGetChosenPositions={this.doGetChosenPositions}
                          tradeObject={this.state.tradeObject}
                          handleClickCreateTradePosition={this.handleClickCreateTradePosition}
                          rerenderPosition={this.rerenderPosition}
                          onGetArrayChoice={this.doGetArrayChoice}
                          test={this.state.test}
                          readFromServerData={this.readFromServerData}
                          switchPositionModal={this.switchPositionModal}
                          openQuestionnairy={this.openQuestionnairy}
                        />
                      )}

                      {!this.state.chosenPositions.length > 0 && (
                        <TableSkeleton firstTh='Название' secondTh='Стоимость' />
                      )}

                      {this.state.chosenPositions.length > 0 && this.state.rerenderPosition && (
                        <UniversalTable
                          isRowGreen={false}
                          selectable1={false}
                          selectable2={true}
                          removable={true}
                          selectable={false}
                          favourite={true}
                          tableName={
                            <>
                              <CheckOutlinedIcon className='pb_3' color='action' /> Позиции лота{' '}
                            </>
                          }
                          titleObjectData={{
                            numbered: 'п/п',
                            nameTrade: 'Название',
                            comm: 'НМЦ, руб.'
                          }}
                          renderedArray={this.state.chosenPositions}
                          renderedArrayWithAdded={this.doEmpty}
                          chosenObjectsArray={this.doGetChosenPositions}
                          chosenIdsArray={this.doEmpty}
                          chosenObjectId={this.doEmpty}
                          handleCancel={this.doEmpty}
                          handleModalToggle={this.doEmpty}
                          openQuestionnairy={this.openQuestionnairy}
                        />
                      )}
                      <Row> &nbsp; </Row>
                    </Card.Body>
                    <AllModalsFooter
                      footerContent={
                        <AddButton
                          handleSaveClick={this.openPositionModal}
                          needDisable={false}
                          buttonText='позиции'
                        />
                        // <Button
                        //   variant="primary"
                        //   onClick={this.openPositionModal}
                        // // className='display_block mb_10'
                        // >
                        //   Добавить позиции
                        // </Button>
                      }
                    />
                  </Card>

                  <Row> &nbsp; </Row>

                  {/* <Card
                    style={{
                      border: "1px #ccc solid",
                      backgroundColor: "#F8F9FA",
                    }}
                    className="box_shadow card_block"
                  >
                    <Card.Body>
                      <GeneralLotPriceComponent
                        curr={curr}
                        currencyPlaceholder={currencyPlaceholder}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.state.nameForm}
                        value={data.price.toLocaleString("ru")}
                      />
                    </Card.Body>
                  </Card>

                  <Row> &nbsp; </Row> */}

                  {/* ЧЕТВЕРТЫЙ И ПЯТЫЙ БЛОКИ--- КОММЕРЧЕСКИЕ ДАННЫЕ */}

                  <EconomicComponent
                    idsArrayToCheck={this.state.idsArrayToCheck}
                    firstBlockNumber={'4.'}
                    secondBlockNumber={'5.'}
                    guaranteeAfterLaunch={data.guaranteeAfterLaunch}
                    guaranteeAfterShipment={data.guaranteeAfterShipment}
                    prepayFromServer={data.prepayFromServer}
                    beforePayFromServer={data.beforePayFromServer}
                    afterPayFromServer={data.afterPayFromServer}
                    data={data.data}
                    getEconomicData={this.getEconomicData}
                    totalProcent={this.state.totalProcent}
                    isLotNew={data.id ? true : false}
                  />

                  <Row> &nbsp;</Row>

                  {/* ШЕСТОЙ БЛОК --- СРОКИ ДОПЛАТЫ */}

                  <Card style={{ border: '1px #ccc solid' }} className='box_shadow card_block'>
                    <Card.Header as='h6' className='trade_card_header'>
                      6. Срок оплаты{' '}
                    </Card.Header>

                    <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                      <InputFormEconomic
                        id={'termsOfAfterpayment'}
                        width={10}
                        show={true}
                        label='Срок оплаты после отгрузки: '
                        placeholder='Срок оплаты после отгрузки'
                        description='Экономическая информация'
                        value={data.termsOfAfterpayment}
                        onChangeValue={this.doChangeValue}
                        nameForm={'EconomicData'}
                        verify='number'
                        upperLabel={'Срок оплаты после отгрузки, дней'}
                      />

                      <Row> &nbsp;</Row>
                    </Card.Body>
                  </Card>
                  <Row> &nbsp;</Row>

                  {/* СЕДЬМОЙ БЛОК --- СРОКИ ПОСТАВКИ */}

                  <Card style={{ border: '1px #ccc solid' }} className='box_shadow card_block'>
                    {this.state.deliveryBeforeAgreementError ? (
                      <Card.Header className='trade_card_header_error' as='h6'>
                        <div>7. Условия поставки</div>
                        <div style={{ fontSize: '12px' }}>
                          <ErrorOutlineOutlinedIcon style={{ fontSize: '15px' }} className='pb_3' />{' '}
                          <span>
                            <b>Дата доставки</b> не может быть раньше{' '}
                            <b>даты заключения договора</b>
                          </span>
                        </div>
                      </Card.Header>
                    ) : (
                      <Card.Header className='trade_card_header' as='h6'>
                        7. Условия поставки
                      </Card.Header>
                    )}

                    <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                      <Row>
                        <Col className='date_trade_text' style={{ margin: '0 -300px 0 0' }}>
                          Планируемая дата заключения договора:
                        </Col>
                        <Col>
                          <CalendarFormTrade
                            name={'Планируемая дата заключения договора:'}
                            id={'agreementConclusionDate'}
                            show={true}
                            placeholder='Планируемая дата заключения договора'
                            description='Планируемая дата заключения договора'
                            value={
                              data.agreementConclusionDate === ''
                                ? data.agreementConclusionDate
                                : new Date(data.agreementConclusionDate)
                            }
                            onChangeValue={this.doChangeValue}
                            getEmptyDate={this.getEmptyDate}
                            // onBlur={this.doChangeValueWithVerification}
                            onBlur={this.doEmpty}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className='date_trade_text' style={{ margin: '0 -300px 0 0' }}>
                          Желаемый срок поставки:
                        </Col>
                        <Col>
                          <CalendarFormTrade
                            name={'Срок поставки:'}
                            id={'deliveryTime'}
                            show={true}
                            placeholder='Срок поставки'
                            description='Срок поставки'
                            value={
                              data.deliveryTime === ''
                                ? data.deliveryTime
                                : new Date(data.deliveryTime)
                            }
                            onChangeValue={this.doChangeValue}
                            getEmptyDate={this.getEmptyDate}
                            // onBlur={this.doChangeValueWithVerification}
                            onBlur={this.doEmpty}
                          />
                        </Col>
                      </Row>
                      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {this.state.daysDifference}
                      </div>
                      <Row> &nbsp;</Row>
                    </Card.Body>
                  </Card>
                  <Row> &nbsp;</Row>

                  {/* ВОСЬМОЙ БЛОК --- СОГЛАСОВАНИЕ */}
                  <Card style={{ border: '1px #ccc solid' }} className='box_shadow card_block'>
                    {this.state.finishBeforeStartError ? (
                      <Card.Header as='h6' className='trade_card_header_error' variant='danger'>
                        <div> 8. Период сбора заявок на торги </div>
                        <div style={{ fontSize: '12px' }}>
                          <ErrorOutlineOutlinedIcon style={{ fontSize: '15px' }} className='pb_3' />{' '}
                          <span>
                            <b>Дата начала согласований</b> превышает{' '}
                            <b>дату окончания согласований</b>
                          </span>
                        </div>
                      </Card.Header>
                    ) : (
                      <Card.Header className='trade_card_header' as='h6'>
                        8. Период сбора заявок на торги
                      </Card.Header>
                    )}
                    <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                      <Row>
                        <Col className='date_trade_text' style={{ margin: '0 -300px 0 0' }}>
                          Дата начала и <b>оповещения участников</b>:
                        </Col>
                        <Col>
                          <CalendarFormTrade
                            name={'Дата начала согласований:'}
                            id={'dateTechStart'}
                            show={true}
                            placeholder='Дата начала проведения согласований'
                            description='Дата начала проведения согласований'
                            value={
                              data.dateTechStart === ''
                                ? data.dateTechStart
                                : new Date(data.dateTechStart)
                            }
                            onChangeValue={this.doChangeValue}
                            getEmptyDate={this.getEmptyDate}
                            onBlur={this.doChangeValueWithVerification}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col className='date_trade_text' style={{ margin: '0 -300px 0 0' }}>
                          Дата окончания:
                        </Col>
                        <Col>
                          <CalendarFormTrade
                            id={'dateTech'}
                            show={true}
                            placeholder='Дата окончания согласований'
                            description='Дата окончания согласований'
                            value={data.dateTech === '' ? data.dateTech : new Date(data.dateTech)}
                            onChangeValue={this.doChangeValue}
                            onBlur={this.doChangeValueWithVerification}
                          />
                        </Col>
                      </Row>
                      <Row> &nbsp;</Row>
                    </Card.Body>
                  </Card>

                  <Row> &nbsp; </Row>

                  {/* ДЕВЯТЫЙ БЛОК --- ДАТЫ ЛОТА */}

                  <Card style={{ border: '1px #ccc solid' }} className='box_shadow card_block'>
                    {!this.state.finishBeforeTradeError && !this.state.tradeBeforeStartError && (
                      <Card.Header className='trade_card_header' as='h6'>
                        9. Параметры торгов
                      </Card.Header>
                    )}

                    {!this.state.tradeBeforeStartError && this.state.finishBeforeTradeError && (
                      <Card.Header as='h6' className='trade_card_header_error' variant='danger'>
                        <div> 9. Параметры торгов</div>
                        <div style={{ fontSize: '12px' }}>
                          <ErrorOutlineOutlinedIcon style={{ fontSize: '15px' }} className='pb_3' />{' '}
                          <span>
                            <b>Дата окончания согласований</b> превышает <b>дату начала торгов</b>
                          </span>
                        </div>
                      </Card.Header>
                    )}

                    {!this.state.finishBeforeTradeError && this.state.tradeBeforeStartError && (
                      <Card.Header as='h6' className='trade_card_header_error' variant='danger'>
                        <div> 9. Параметры торгов</div>
                        <div style={{ fontSize: '12px' }}>
                          <ErrorOutlineOutlinedIcon style={{ fontSize: '15px' }} className='pb_3' />{' '}
                          <span>
                            <b>Дата проведения торгов</b> не может быть раньше{' '}
                            <b>даты начала оповещения о торгах</b>
                          </span>
                        </div>
                      </Card.Header>
                    )}

                    {this.state.finishBeforeTradeError && this.state.tradeBeforeStartError && (
                      <Card.Header as='h6' className='trade_card_header_error' variant='danger'>
                        <div> 9. Параметры торгов</div>
                        <div style={{ fontSize: '12px' }}>
                          <ErrorOutlineOutlinedIcon style={{ fontSize: '15px' }} className='pb_3' />{' '}
                          <span>
                            <b>Дата проведения торгов</b> не может быть раньше{' '}
                            <b>даты начала оповещения о торгах</b> и{' '}
                            <b>даты окончания оповещения о торгах</b>
                          </span>
                        </div>
                      </Card.Header>
                    )}

                    <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                      <Row>
                        <Col className='date_trade_text' style={{ margin: '0 -170px 0 0' }}>
                          Дата проведения торгов:
                        </Col>
                        <Col>
                          <CalendarFormTrade
                            id={'dateTrade'}
                            show={true}
                            placeholder='Дата проведения торгов'
                            description='Дата проведения торгов'
                            value={
                              data.dateTrade === '' ? data.dateTrade : new Date(data.dateTrade)
                            }
                            onChangeValue={this.doChangeValue}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col className='date_trade_text' style={{ margin: '0 -200px 0 0' }}>
                          {/* Время проведения торгов {this.state.changeTime ? '(МСК)' : ''}: */}
                          Время проведения торгов (МСК):
                        </Col>
                        <Col>
                          <TimeForm
                            id={'timeTrade'}
                            width={4}
                            show={true}
                            label=''
                            placeholder='ЧЧ:ММ'
                            description='Время начала торгов'
                            value={data.timeTrade}
                            onChangeValue={this.doChangeValue}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col className='date_trade_text' style={{ margin: '0 -200px 0 0' }}>
                          Время на принятие решений, мин:
                        </Col>
                        <Col>
                          <InputForm
                            id={'timer'}
                            width={4}
                            show={true}
                            verify='number'
                            color={'#ccc'}
                            fontSize={'12px'}
                            placeholder=''
                            description='Время на принятие решений'
                            // value={this.value[2] ? this.value[2].value : ''}
                            value={data.timer}
                            onChangeValue={this.doChangeValue}
                          // nameForm={nameForm}
                          />
                        </Col>
                      </Row>
                      <Row> &nbsp;</Row>
                    </Card.Body>
                  </Card>
                  <Row> &nbsp;</Row>

                  {/* ДЕСЯТЫЙ БЛОК --- БАНКОВСКИЕ ГАРАНТИИ */}
                  <Bankguarantees
                    bankContractExecutionGuarantee={data.bankContractExecutionGuarantee}
                    bankContractExecutionGuaranteeInput={data.bankContractExecutionGuaranteeInput}
                    bankPrepayGuarantee={data.bankPrepayGuarantee}
                    // bankPrepayGuaranteeInput={data.bankPrepayGuaranteeInput}
                    bankGuaranteeExecution={data.bankGuaranteeExecution}
                    bankGuaranteeExecutionInput={data.bankGuaranteeExecutionInput}
                    contractAgreement={data.contractAgreement}
                    doChangeValueBank={this.doChangeValueBank}
                    doChangeValue={this.doChangeValue}
                  />
                  <Row> &nbsp;</Row>

                  {/* ОДИНАДЦАТЫЙ БЛОК --- ФАЙЛЫ */}

                  <Card style={{ border: '1px #ccc solid' }} className='box_shadow card_block'>
                    <Card.Header className='trade_card_header' as='h6'>
                      11. Условия доставки по Инкотермс
                    </Card.Header>

                    <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                      {/* <InputFormEconomic
                        id={'deliveryCondition'}
                        width={10}
                        show={true}
                        label='Условия доставки по Инкотермс: '
                        placeholder='Условия доставки по Инкотермс'
                        description='Условия доставки по Инкотермс'
                        value={data.deliveryCondition}
                        onChangeValue={this.doChangeValue}
                        // nameForm={'EconomicData'}
                        // verify='number'
                        upperLabel={'Условия доставки по Инкотермс'}
                      /> */}

                      <SelectFormIncoterms
                        id={'deliveryCondition'}
                        width={6}
                        show={true}
                        label='Условия доставки по Инкотермс:'
                        placeholder='Условия доставки по Инкотермс'
                        description='Условия доставки по Инкотермс'
                        option={incotermsList}
                        value={data.deliveryCondition}
                        // value={this.value[3].value}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doEmpty}
                        disabledOption={'Выберите условия доставки по Инкотермс'}
                      />

                      <Row> &nbsp;</Row>
                    </Card.Body>
                  </Card>

                  <Row> &nbsp;</Row>
                  {/* ДВЕНАДЦАТЫЙ БЛОК --- ФАЙЛЫ */}

                  {this.state.showFile && (
                    <Card style={{ border: '1px #ccc solid' }} className='box_shadow'>
                      <Card.Header className='trade_card_header' as='h6'>
                        12. Файлы от заказчика
                      </Card.Header>

                      <Card.Body style={{ backgroundColor: '#F8F9FA', padding: '10px' }}>
                        <FileNoModal
                          onClose={this.doEmpty} // закрытие окна
                          newName={this.state.data.numberLot} // номер ЛОТа или торговой позиции
                          id={data.idFile} // ID масива объектов загруженных файлов
                          // id={this.props.fileId} // ann
                          returnId={this.returnId} // возвращает ID, если его не было при запуске компонента
                          getFilesNames={this.getFilesNames}
                          toNextTab={this.doEmpty}
                          disableBtn={false}
                          showNextBtn={false}
                          showDelIcon={true}
                          showCheckIcon={true}
                          showAddBtn={true}
                          asCustomer={false}
                          isMarginBottomNeeded={true}
                        />

                        {/* <Row> &nbsp;</Row> */}
                      </Card.Body>
                    </Card>
                  )}
                  <Row> &nbsp;</Row>

                  {/* 13 БЛОК ---  */}

                  {this.state.showAddList && (
                    <>
                      <Card style={{ border: '1px #ccc solid' }} className='box_shadow card_block'>
                        <Card.Header className='trade_card_header' as='h6'>
                          13. Перечень файлов для получения от участника
                        </Card.Header>

                        <Card.Body style={{ backgroundColor: '#F8F9FA' }}>
                          <Row> &nbsp;</Row>
                          {this.state.data.listForFiles && (
                            <AddListForFile
                              listForFiles={this.state.data.listForFiles}
                              addList={this.addNewList}
                              deletePosition={this.deletePosition}
                            />
                          )}
                        </Card.Body>
                        <AllModalsFooter
                          footerContent={
                            <AddButton
                              handleSaveClick={this.handleClickAddPosition}
                              needDisable={false}
                              buttonText='новый документ'
                            />
                            // <Button
                            //   variant="primary"
                            //   onClick={this.handleClickAddPosition}
                            // // disabled={}
                            // >
                            //   {/* <AddOutlinedIcon className='pb_3' /> */}
                            //   Добавить новый документ
                            // </Button>
                          }
                        />
                      </Card>
                    </>
                  )}
                  <Row> &nbsp;</Row>

                  {/* 14 БЛОК ---  */}
                  <Card
                    style={{
                      border: '1px #ccc solid',
                      backgroundColor: '#F8F9FA'
                    }}
                    className='box_shadow'
                  >
                    <Card.Header className='trade_card_header' as='h6'>
                      14. Начальная максимальная цена лота
                    </Card.Header>

                    <Card.Body
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        backgroundColor: '#F8F9FA'
                      }}
                    >
                      {this.state.showPrice && (
                        <GeneralLotPriceComponent
                          id={'price'}
                          curr={curr}
                          width={4}
                          show={true}
                          verify='number'
                          color={'#ccc'}
                          fontSize={'18px'}
                          readOnly={true}
                          placeholder={currencyPlaceholder} // с учетом валюты
                          description='Общая стоимость лота'
                          value={data.price.toLocaleString('ru')}
                          onChangeValue={this.doChangeValue}
                          withNds={'без'} //"С" ндс или "БЕЗ"
                          nameForm={this.state.nameForm}
                        />
                      )}

                      {this.state.showPrice && (
                        <GeneralLotPriceComponent
                          id={'price'}
                          curr={curr}
                          width={4}
                          show={true}
                          verify='number'
                          color={'#ccc'}
                          fontSize={'18px'}
                          readOnly={true}
                          placeholder={currencyPlaceholder} // с учетом валюты
                          description='Общая стоимость лота'
                          value={data.price.toLocaleString('ru')}
                          onChangeValue={this.doChangeValue}
                          nameForm={this.state.nameForm}
                          withNds={'с'} //"С" ндс или "БЕЗ"
                          nds={nds} // НДС коэффициент
                        />
                      )}

                      <Row> &nbsp;</Row>
                    </Card.Body>
                  </Card>
                  <Row> &nbsp;</Row>
                </Form>
              </>
            ) : (
              <LoaderPage w='100%' minH='50vh' />
            )}

            {this.state.showQuestionnairy &&
              this.state.innFromUniversalTable === this.state.companyQuestionnairieId && (
                <QuestionnairyModal
                  closeCompanyDetailsModal={() => this.setState({ showQuestionnairy: false })}
                  companyName={this.state.companyName}
                  infoBlock={this.props.infoBlock}
                  onUpInfoBlock={this.props.doUpInfoBlock}
                  viewQuest={this.props.viewQuest}
                  innFromUniversalTable={this.state.innFromUniversalTable}
                  getEconomicData={this.props.getEconomicData}
                />
              )}

            {this.state.showStopLotModal && (
              <QuitModal
                onQuitWithSave={this.confirmLotStop}
                onQuitWithoutSave={this.toggleShowStopLotModal}
                closeQuitModal={this.toggleShowStopLotModal}
                modalTitle='Отмена лота'
                modalBody='Вы действительно хотите остановить лот?'
                primaryButton='Остановить'
                secondaryButton='Отменить'
                icon={<PanToolOutlinedIcon className='icon_leave_modal' />}
              />
            )}
          </div>

          <AllModalsFooter
            footerContent={
              <>
                <div>
                  <ButtonToolbar>
                    <ToggleButtonGroup
                      type='radio'
                      name='options'
                      defaultValue={'ordinary'}
                      onChange={this.handleChangeButtonTrade}
                      value={this.state.data.typeTrade}
                    >
                      <ToggleButton value={'ordinary'} variant='outline-success'>
                        Обычные торги
                      </ToggleButton>
                      <ToggleButton value={'reduction'} variant='outline-success'>
                        Редукцион
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </ButtonToolbar>
                </div>
                <ChatButton handleSaveClick={this.doChangeVisibleChat} needDisable={false} />
                {this.state.showStopButton && (
                  <StopButton handleSaveClick={this.toggleShowStopLotModal} needDisable={false} />
                )}

                {this.state.showPeretojka && (
                  <ReTradeButton handleSaveClick={this.handleClickPeretojka} needDisable={false} />
                )}

                {!this.state.showPeretojka && (
                  <>
                    <SaveCurrentDataButton
                      // handleSaveClick={() => this.setState({ showQuit: !this.state.showQuit })}
                      handleSaveClick={this.handleClickOnSaveButton}
                      needDisable={true}
                      disableCondition={
                        this.state.finishBeforeStartError ||
                        this.state.finishBeforeTradeError ||
                        this.state.tradeBeforeStartError ||
                        this.state.deliveryBeforeAgreementError
                      }
                      savedDataMessage={this.state.savedDataMessage}
                      buttonTextDefault={'Сохранить'}
                      buttonTextSaved={'Данные сохранены'}
                    />

                    <LaunchButton
                      handleSaveClick={this.handleClickTrade}
                      needDisable={true}
                      disableCondition={
                        this.disableConditionForLaunch(this.state.data)
                      }
                    />
                  </>
                )}
              </>
            }
          />
        </div>
      </>
    )
  }
}
