import React from 'react'
import { Button, Row, Col } from 'react-bootstrap'
import CommercialTradeAdd from '../trade/CommercialTradeAdd'
import InputForm from '../questionnaire/InputForm'
import PriorityModal from './PriorityModal'
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AllModalsFooter from '../assets/AllModalsFooter'
import { AddButton, SaveButtonGreen } from '../assets/BasicButtons'

export default class CommercialTrade extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      showPriority: false,
      id: 11,
      base: [],
      base_0: [],
      value: [],
      data: [
        { id: 0 },
        {
          language: 'rus',
          id: '1',
          description: 'Коммерческие параметры торгов',
          information: 'Название параметра',
          value: 'Дата подписания договора по результатам данных торгов'
        },
        {
          language: 'rus',
          id: '3',
          description: 'Коммерческие параметры торгов',
          information: 'Название параметра',
          value: 'Срок изготовления, дней'
        },
        {
          language: 'rus',
          id: '5',
          description: 'Коммерческие параметры торгов',
          information: 'Название параметра',
          value: 'Гарантийные обязательства, мес'
        },
        {
          language: 'rus',
          id: '7',
          description: 'Коммерческие параметры торгов',
          information: 'Название параметра',
          value: 'Условия оплаты'
        },

        {
          language: 'rus',
          id: '9',
          description: 'Коммерческие параметры торгов',
          information: 'Название параметра',
          value: 'НМЦ (начальная максимальная цена)'
        }
      ],
      errData: false,
      isAddBtnDisabled: true,
      isNextBtnDisabled: true
    }

    this.handleClickAdd = this.handleClickAdd.bind(this)
    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.verificationData = this.verificationData.bind(this)
    this.renderBlock = this.renderBlock.bind(this)

    this.handleClickPriority = this.handleClickPriority.bind(this)
    this.doChangeView = this.doChangeView.bind(this)
    this.doSavePriority = this.doSavePriority.bind(this)
    this.handleClickAdd_0 = this.handleClickAdd_0.bind(this)
    this.saveInReadMode = this.saveInReadMode.bind(this)
  }

  // пробрасываем данные о приоритетах выше
  doSavePriority(data) {
    this.props.onSavePriority(data)
    // console.log(`PRIORITY in commercial >>>>>>> `, data);
  }

  doChangeView(e) {
    // console.log(`E >>>> `, e);
    this.setState({ showPriority: false })
  }

  //проставление приоритетов
  handleClickPriority() {
    // console.log(`PRIORITY`);
    this.setState({ showPriority: true })
  }

  handleClickSave(e) {
    e.preventDefault()
    this.setState({ value: this.state.data })
    this.verificationData()

    setTimeout(() => {
      if (this.state.errData) {
        this.props.onSaveDataCommercial(this.state.data)
      }
    }, 500)
    setTimeout(() => {
      this.props.onSendDataFromCommerce(this.state.data)
    }, 1000)
    // this.props.handleClick(e)
    this.props.toNextTab(e)
  }

  saveInReadMode() {
    this.setState({ value: this.state.data })
    this.verificationData()

    setTimeout(() => {
      if (this.state.errData) {
        this.props.onSaveDataCommercial(this.state.data)
      }
    }, 500)
    setTimeout(() => {
      this.props.onSendDataFromCommerce(this.state.data)
    }, 1000)
  }

  //* проверка и отправка данных в PARENT
  doChangeValue(data) {
    console.log(data) //test
    this.setState({ data: [...this.state.data, data] })

    setTimeout(() => {
      let arrData = [{ id: 0 }]

      this.state.data.forEach(data => {
        if (data) arrData[+data.id] = data
      })
      this.setState({ data: arrData })
      this.props.onSaveDataCommercial(this.state.data)
    })
    // setTimeout(() => { this.verificationData() })

    //проверяем на наличие четных id - если они есть, значит пользователь внес какую-то инф и кнопка становится активной. В противном случае она задизейблена
    if (data && data.id !== 0 && data.id % 2 === 0)
      this.setState({
        isAddBtnDisabled: false
      })

    //раздизейбливаем кнопку "далее"

    if (this.state.data && this.state.data.length !== 0) {
      const res = this.state.data
        .filter(el => el !== undefined && el !== null)
        .filter(el => el.id !== 0)
        .filter(el => el.id % 2 === 0)
      res.map(el => {
        if (el.value && el.value !== '') {
          this.setState({
            isNextBtnDisabled: false
          })
        }
      })
    }
  }

  verificationData() {
    if (this.state.data.length % 2 !== 0) {
      this.setState({ errData: true })
    } else {
      this.setState({ errData: false })
    }
    // console.log(this.state.data.length);//test
  }

  handleClickAdd() {
    this.setState({
      base: [
        ...this.state.base,
        <React.Fragment key={this.state.id}>
          <CommercialTradeAdd
            onChangeValue={this.doChangeValue}
            id={this.state.id}
            value={this.props.value}
          // readOnly={true}
          />
        </React.Fragment>
      ],
      id: +this.state.id + 2
    })
    // setTimeout(() => { console.log(this.state.id + ' <- ADD ID'); })
    this.setState({
      isAddBtnDisabled: true
    })
  }

  handleClickAdd_0() {
    const result = (
      <>
        <Row>
          <Col
            sm={1}
            style={{
              marginTop: '40px',
              marginRight: '-60px'
            }}
          >
            1.
          </Col>
          <Col style={{ padding: '10px 0px 0px 0px' }}>
            <InputForm
              id={5}
              // width={12}
              show={true}
              label=''
              placeholder='Название параметра'
              description='Коммерческие параметры торгов'
              value='Количество штук в позиции, шт:'
              readOnly={true}
              onChangeValue={this.doChangeValue}
            />
          </Col>
          <Col style={{ padding: '10px 0px 0px 0px' }}>
            <InputForm
              id={6}
              width={12}
              show={true}
              verify='number'
              label=''
              placeholder='Требование к параметру'
              description='Коммерческие параметры торгов'
              value={this.value[6] ? this.value[6].value : 1}
              onChangeValue={this.doChangeValue}
            // readOnly={true}
            />
          </Col>
        </Row>

        <Row>
          <Col
            sm={1}
            style={{
              marginTop: '40px',
              marginRight: '-60px'
            }}
          >
            2.
          </Col>
          <Col style={{ padding: '10px 0px 0px 0px' }}>
            <InputForm
              id={9}
              // width={12}
              show={true}
              label=''
              placeholder='Название параметра'
              description='Коммерческие параметры торгов'
              value='Начальная минимальная цена за штуку, руб.:'
              readOnly={true}
              onChangeValue={this.doChangeValue}
            />
          </Col>
          <Col style={{ padding: '10px 0px 0px 0px' }}>
            <InputForm
              id={10}
              width={12}
              show={true}
              verify='number'
              label=''
              placeholder='Требование к параметру'
              description='Коммерческие параметры торгов'
              value={this.value[10] ? this.value[10].value : ''}
              onChangeValue={this.doChangeValue}
            // readOnly={true}
            />
          </Col>
        </Row>
      </>
    )

    this.setState({
      base_0: result
    })
  }

  renderBlock() {
    this.handleClickAdd()
    this.handleClickAdd_0()

    let lengthData = this.props.value.length

    if (lengthData > 3) {
      for (let i = 0; i < lengthData - 13; i = i + 2) {
        this.handleClickAdd()
      }
    }
  }

  async componentDidMount() {
    if (this.props.disableBtn === false) {
      await this.setState({
        isNextBtnDisabled: false,
        isAddBtnDisabled: false
      })
    }

    if (this.props.inReadMode) {
      this.saveInReadMode()
    }

    setTimeout(() => {
      this.renderBlock()
      if (this.props.value.length > 0) this.setState({ data: this.props.value }) // загружаем данные от родителя
      this.verificationData()
    }, 500)
  }

  render() {
    this.value = this.props.value
    // console.log(this.props.value);
    // const errShow = this.state.errData;
    // console.log('value', this.props.value[6]);

    return (
      <>
        {this.state.showPriority ? (
          <PriorityModal
            view={true}
            onSavePriority={this.doSavePriority}
            onChangeView={this.doChangeView}
            viewQuest={this.state.showPriority}
          />
        ) : (
          ''
        )}

        <Row>&nbsp;</Row>

        {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <div className='display_flex container_flex_start flex_gap_8 mt_10' >
                        <Button
                            disabled={this.props.disableBtn === false ? this.props.disableBtn : this.state.isAddBtnDisabled}
                            onClick={this.handleClickAdd}
                            variant='info' >
                            <AddOutlinedIcon className='p_3' />Добавить параметр
                        </Button>

                        <Button
                            // disabled={this.state.isNextBtnDisabled}
                            data-index='general_data'
                            variant='success'
                            onClick={this.handleClickSave}
                            className='m_0'
                        >
                            Далее
                        </Button>
                    </div>
                </div> */}

        <Row>&nbsp;</Row>

        <div
          className='display_flex overflow_y_auto overflow_x_hidden height_300 pr_10'
          style={{ marginBottom: '72px' }}
        >
          <div className='width_100pr p_0'>
            {/* <Row>
                            <InputForm
                                id={5}
                                width={6}
                                show={true}
                                label=""
                                placeholder="Название параметра"
                                description="Коммерческие параметры торгов"
                                value="Количество штук в позиции, шт:"
                                readOnly={true}
                                onChangeValue={this.doChangeValue}
                            />

                            <InputForm
                                id={6}
                                width={6}
                                show={true}
                                verify="number"
                                label=""
                                placeholder="Требование к параметру"
                                description="Коммерческие параметры торгов"
                                value={this.value[6] && this.value[6].value}
                                onChangeValue={this.doChangeValue}
                            />
                        </Row>

                        <Row>
                            <InputForm
                                id={9}
                                width={6}
                                show={true}
                                label=""
                                placeholder="Название параметра"
                                description="Коммерческие параметры торгов"
                                value="Стоимость позиции, руб. (без НДС)"
                                readOnly={true}
                                onChangeValue={this.doChangeValue}
                            />

                            <InputForm
                                id={10}
                                width={6}
                                show={true}
                                verify="number"
                                label=""
                                placeholder="Требование к параметру"
                                description="Коммерческие параметры торгов"
                                value={this.value[10] ? this.value[10].value : ''}
                                onChangeValue={this.doChangeValue}
                            />
                        </Row> */}

            {this.state.base_0}
            {this.state.base}

            <Row>&nbsp;</Row>
          </div>
        </div>
        <Row>&nbsp;</Row>
        <AllModalsFooter
          isPosAbsoluteNedded={true}
          footerContent={
            <>
              <AddButton
                handleSaveClick={this.handleClickAdd}
                needDisable={true}
                disableCondition={this.props.disableBtn === false ? this.props.disableBtn : this.state.isAddBtnDisabled}
                buttonText='параметр'
              />

              <SaveButtonGreen
                handleSaveClick={this.handleClickSave}
                needDisable={false}
                // disableCondition,
                saveButtonText='Далее'
              />

              {/* <Button
                // disabled={this.state.isNextBtnDisabled}
                data-index='general_data'
                variant='success'
                onClick={this.handleClickSave}
                className='m_0'
              >
                Далее
              </Button> */}
            </>
          }
        />
      </>
    )
  }
}
