import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import '../src/style/main.css';
import Block from './components/Block';
import LoaderPage from './components/assets/loaderPage';



function App() {

    const [show, setShow] = useState(false)
    const [showIframe, setShowIframe] = useState(false)
    const [url, setUrl] = useState(window.location.href)
    // const [time, setTime] = useState()

    useEffect(() => {
        if (url === 'https://lotus-uems.ru/') {
            setShow(false);
            setShowIframe(true)
        } else {
            setShow(true);
            setShowIframe(false)
        }
    }, [])

    // ждем полную загрузку iframe
    const handlerOnLoad = (e) => {
        console.log(`URL :::: `, e.target.baseURI); // test
        console.log(`target >>>> `, e.target.src); // test

        const url = e.target.baseURI;

        if (url !== 'https://lotus-uems.ru/') {
            setShow(true);
            setShowIframe(false)
        } else {
            setShow(true)
            setShowIframe(true)
        }
        setShow(true) // на время теста test
    }

    // если при работе iframe произошла ошибка
    const handlerError = (e) => {
        console.log(`error::: `, e);
        setShowIframe(false)
        setShow(true);
    }

    // сообщения из iframe
    // window.onmessage = (e) => {
    //     if (e.data.URL) {
    //         setTime(e.data)
    //         setTimeout(() => { console.log(`event.origin::: `, e.data) }, 2000)
    //     }
    // }

    return (
        <>
            {showIframe ?
                // eslint-disable-next-line jsx-a11y/iframe-has-title
                <iframe
                    src="https://sthe.lotus1.org/lotus-uems/"
                    style={{ height: '100%', width: '100%', position: 'absolute', border: 'none', zIndex: '9999' }}
                    onLoad={(e) => {
                        handlerOnLoad(e);
                        // window.top.postMessage({ URL: Date.now() }, '*') // сообщение из iframe
                    }
                    }
                    onError={handlerError}
                >
                    <p>Твой браузер устарел для отображения элемента iframe</p>
                </iframe> : ''}

            <div>
                {show ? <Block /> : ''}
            </div>

            {show ? '' :
                <div
                    style={{ position: 'absolute', top: '50%', left: '50%' }}
                >
                    <LoaderPage />
                </div>}

        </>
    );
}

export default App;