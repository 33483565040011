import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { InviteByEmail } from '../InviteCompanies/InviteByEmail'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
// import server from '../server/server';
// import Server from '../server/server';

export function SearchInputGroup({
  onProductionChoice,
  product,
  arrayForFirstSelectRender,
  getChosenFilters,
  chosenCompaniesFromProductionSearch,
  onSendIdInvite,
  idLot,
  needTextInButtons // распознает откуда рендерится select с создания лота или с поиска
}) {
  return (
    <div style={{ width: '100%', margin: '0 auto' }}>
      <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <div style={{ width: 'min-content', whiteSpace: 'nowrap' }}>Вид деятельности:</div>
        <div style={{ width: '70%' }}>
          <Form.Control as='select' onChange={onProductionChoice} value={product} focus={true}>
            <option></option>
            {arrayForFirstSelectRender.map(el => {
              return <option key={el}>{el}</option>
            })}
          </Form.Control>
        </div>
        <div
          style={{ width: 'min-content', whiteSpace: 'nowrap' }}
          //   style={{ width: product ? '30%' : '20%' }}
          //   style={{ margin: '0', padding: '0' }} className='card_block_no_position'
        >
          <div style={{ display: 'flex', gap: '8px' }} className='card_block_no_position'>
            {product && (
              <div style={{ margin: '0', padding: '0' }}>
                <Button onClick={getChosenFilters} title='Вернуться к выбранным фильтрам'>
                  <FilterAltOutlinedIcon /> {needTextInButtons ? 'Фильтр' : ''}
                </Button>
              </div>
            )}
            <InviteByEmail
              chosenCompaniesFromProductionSearch={chosenCompaniesFromProductionSearch}
              doSendIdInvite={onSendIdInvite}
              idLot={idLot}
              icon={true}
              fromSearch={true}
              btnText={needTextInButtons ? 'Пригласить компании по email' : ''}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
