import React, { useEffect, useState } from 'react'
import {
  FILES_CALCULATION,
  FILES_CALCULATION_KEY,
  FILES_ENGINEERING,
  FILES_ENGINEERING_KEY,
  FILES_PRODUCTION,
  FILES_PRODUCTION_KEY,
  FILES_INSTALLATION,
  FILES_INSTALLATION_KEY,
  FILES_START,
  FILES_START_KEY
} from './constCardEquipment'
import LoadFile from './LoadFile'
import { Accordion, Card } from 'react-bootstrap'
import Server from '../../server/server'
import LoadFilesMultiple from './LoadFilesMultiple'
import ArrowsAnimation from '../../assets/ArrowsAnimation'

const ContentLoadFiles = ({ dataCard }) => {
  const [showCalculation, setShowCalculation] = useState(false)
  const [showEngineering, setShowEngineering] = useState(false)
  const [showProduction, setShowProduction] = useState(false)
  const [showInstallation, setShowInstallation] = useState(false)
  const [showStart, setShowStart] = useState(false)
  const [arrDataDocum, setArrDataDocum] = useState([])

  // console.log(`DATA CARD ID: `, dataCard.id) // test

  //*: Загрузка документа с информацией по ранее сохраненным файлам
  const nameDocum = `documents_${dataCard.id}` // id documents to DB

  useEffect(() => {
    ; (async () => {
      try {
        const arrData = await Server.getDataFromServer(nameDocum, localStorage.getItem('idUser'))
        setArrDataDocum(arrData)
      } catch (err) {
        console.log(`Ошибка загрузки данных о файлах в DB: `, err)
      }
    })()
  }, [])

  const handleToggleShowColculation = () => {
    setShowCalculation(!showCalculation)
    setShowEngineering(false)
    setShowProduction(false)
    setShowInstallation(false)
    setShowStart(false)
  }

  const handleToggleShowEngineering = () => {
    setShowEngineering(!showEngineering)
    setShowCalculation(false)
    setShowProduction(false)
    setShowInstallation(false)
    setShowStart(false)
  }

  const handleToggleShowProduction = () => {
    setShowProduction(!showProduction)
    setShowCalculation(false)
    setShowEngineering(false)
    setShowInstallation(false)
    setShowStart(false)
  }

  const handleToggleShowInstallation = () => {
    setShowInstallation(!showInstallation)
    setShowCalculation(false)
    setShowEngineering(false)
    setShowProduction(false)
    setShowStart(false)
  }

  const handleToggleShowStart = () => {
    setShowStart(!showStart)
    setShowCalculation(false)
    setShowEngineering(false)
    setShowProduction(false)
    setShowInstallation(false)
  }

  /**
   * Загрузка на сервер одного файла
   * @param {*} newDataForm
   * @param {*} newData
   */
  const onSendDataFile = (newDataForm, newData) => {
    // console.log(`SEND FILE::: `, newDataForm); // test
    // console.log(`DATA file::: `, newData); // test
    ; (async () => {
      try {
        const result = await Server.createDocumentationFileOnServer(newDataForm)
        // console.log(`RESULT::: `, result) // test

        //*: Функция сохранения данных файла
        const objDataFile = {
          path: result.path,
          fileName: result.filename,
          mimeType: result.mimetype,
          originalName: result.originalname,
          description: newData.description,
          idIndex: newData.id,
          idCard: newData.idCard,
          dateOfCreation: Date.now()
        }
        // console.log(`OBJECT FILE >>>>> `, objDataFile); // test

        let resultFind = arrDataDocum?.filter((item) => item.description !== newData.description)

        if (!resultFind) resultFind = []

        resultFind.push(objDataFile)
        setArrDataDocum(resultFind)
        // console.log(`RESULT FIND FILTER:::: `, resultFind); // test

        const resultSave = await Server.sendDataOnServer(
          resultFind,
          nameDocum,
          localStorage.getItem('idUser')
        )
        // console.log(`RESULT SAVE: `, resultSave); // test
      } catch (err) {
        console.log(`Ошибка записи файла на сервер (ContentLoadFiles.js): `, err)
      }
    })()
  }

  /**
   * Загрузка на сервер несколько файлов
   * @param {Array} newDataForm массив DataForm
   * @param {Object} newData объект данных по файлу
   */
  const onSendDataFilesMultyple = (newDataForm, newData) => {
    // console.log(`SEND FILE::: `, newDataForm); // test
    // console.log(`DATA file::: `, newData); // test
    ; (async () => {
      try {
        const promiseDocumentation = await newDataForm.map((item) => {
          return (async () => {
            return await Server.createDocumentationFileOnServer(item)
          })()
        })

        const result = await Promise.all(promiseDocumentation)
        // console.log(`RESULT::: `, result); // test

        // console.log(`OBJECT FILE  result >>>>> `, result); // test

        // todo: Написать новую функцию с дозаписыванием файлов

        let resultFind = arrDataDocum?.filter((item) => item.description !== newData.description) // массив без нужного объекта
        let resultObjectFile = arrDataDocum?.filter((item) => item.description === newData.description)

        if (resultObjectFile.length < 1) {
          resultObjectFile[0] = {
            path: [],
            fileName: [],
            mimeType: [],
            originalName: [],
            fileDate: [],
            description: newData.description,
            idIndex: newData.id,
            idCard: newData.idCard,
            dateOfCreation: Date.now(),
          }
        }

        let resultDataFile = resultObjectFile[0]

        if (!resultDataFile.fileDate) {
          Object.assign(resultDataFile, { fileDate: [] })
        }

        result.forEach(item => {
          resultDataFile.path.push(item.path)
          resultDataFile.fileName.push(item.filename)
          resultDataFile.mimeType.push(item.mimetype)
          resultDataFile.originalName.push(item.originalname)
          resultDataFile.fileDate.push(item.filedate)
        })

        resultDataFile.description = newData.description;
        resultDataFile.idIndex = newData.id;
        resultDataFile.idCard = newData.idCard;
        resultDataFile.dateOfCreation = Date.now();

        if (!resultFind) resultFind = []

        resultFind.push(resultDataFile)
        setArrDataDocum(resultFind)
        console.log(`RESULT FIND FILTER:::: `, resultDataFile); // test

        const resultSave = await Server.sendDataOnServer(
          resultFind,
          nameDocum,
          localStorage.getItem('idUser')
        )
        // console.log(`RESULT SAVE: `, resultSave); // test
      } catch (err) {
        console.log(`Ошибка записи файла на сервер (ContentLoadFiles.js): `, err)
      }
    })()
  }

  /**
   * Загрузка файла с сервера
   * @param {*} info - полный путь до файла на сервере
   */
  const readFileDocument = (info) => {
    // console.log(`PATH FILE::::: `, info); // test
    ; (async () => {
      try {
        const result = await Server.readFileFromServer(info)

        const arrFileName = info.split('/')
        const arrFileNameNext = arrFileName[arrFileName.length - 1].split('_-')
        const fileNameLoad = arrFileNameNext[arrFileNameNext.length - 1]

        if (result) {
          const linkBlob = window.URL.createObjectURL(result)
          const link = document.createElement('a')
          link.href = linkBlob
          link.download = fileNameLoad //* подставное имя файла при загрузке
          document.body.appendChild(link)
          link.click()
          link.remove()
        }
      } catch (err) {
        console.log(`Ошибка загрузки файла: `, err)
      }
    })()
  }

  return (
    <>
      <Accordion>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='0'
            onClick={handleToggleShowColculation}
            className={`unique-header `}
          >
            <b>Документация при расчете</b>
            <ArrowsAnimation isOpen={showCalculation} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Card.Body>
              {' '}
              <div>
                {showCalculation &&
                  FILES_CALCULATION.map((item, index) => {
                    return (
                      <React.Fragment key={FILES_CALCULATION_KEY[index]}>
                        <div>
                          <LoadFilesMultiple
                            keyConst={FILES_CALCULATION_KEY[index]}
                            index={index}
                            item={item}
                            onSendDataFile={onSendDataFilesMultyple}
                            idCard={dataCard.id}
                            dataDocum={arrDataDocum?.find(
                              (item) => item.description === FILES_CALCULATION_KEY[index]
                            )}
                            readFileDocument={readFileDocument}
                          />
                        </div>
                      </React.Fragment>
                    )
                  })}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='1'
            className='unique-header'
            onClick={handleToggleShowEngineering}
          >
            <b>Документация при проектировании</b>
            <ArrowsAnimation isOpen={showEngineering} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='1'>
            <Card.Body>
              {' '}
              <div>
                {showEngineering &&
                  FILES_ENGINEERING.map((item, index) => {
                    return (
                      <React.Fragment key={FILES_ENGINEERING_KEY[index]}>
                        <div>
                          <LoadFilesMultiple
                            keyConst={FILES_ENGINEERING_KEY[index]}
                            index={index}
                            item={item}
                            onSendDataFile={onSendDataFilesMultyple}
                            idCard={dataCard.id}
                            dataDocum={arrDataDocum?.find(
                              (item) => item.description === FILES_ENGINEERING_KEY[index]
                            )}
                            readFileDocument={readFileDocument}
                          />
                        </div>
                      </React.Fragment>
                    )
                  })}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='2'
            className='unique-header'
            onClick={handleToggleShowProduction}
          >
            <b>Документация при изготовлении</b>
            <ArrowsAnimation isOpen={showProduction} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='2'>
            <Card.Body>
              <div>
                {showProduction &&
                  FILES_PRODUCTION.map((item, index) => {
                    return (
                      <React.Fragment key={FILES_PRODUCTION_KEY[index]}>
                        <div>
                          <LoadFilesMultiple
                            keyConst={FILES_PRODUCTION_KEY[index]}
                            index={index}
                            item={item}
                            onSendDataFile={onSendDataFilesMultyple}
                            idCard={dataCard.id}
                            dataDocum={arrDataDocum?.find(
                              (item) => item.description === FILES_PRODUCTION_KEY[index]
                            )}
                            readFileDocument={readFileDocument}
                          />
                        </div>
                      </React.Fragment>
                    )
                  })}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='3'
            className='unique-header'
            onClick={handleToggleShowInstallation}
          >
            <b>Документация при монтаже</b>
            <ArrowsAnimation isOpen={showInstallation} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='3'>
            <Card.Body>
              {' '}
              <div>
                {showInstallation &&
                  FILES_INSTALLATION.map((item, index) => {
                    return (
                      <React.Fragment key={FILES_INSTALLATION_KEY[index]}>
                        <div>
                          <LoadFilesMultiple
                            keyConst={FILES_INSTALLATION_KEY[index]}
                            index={index}
                            item={item}
                            onSendDataFile={onSendDataFilesMultyple}
                            idCard={dataCard.id}
                            dataDocum={arrDataDocum?.find(
                              (item) => item.description === FILES_INSTALLATION_KEY[index]
                            )}
                            readFileDocument={readFileDocument}
                          />
                        </div>
                      </React.Fragment>
                    )
                  })}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey='4'
            className='unique-header'
            onClick={handleToggleShowStart}
          >
            <b>Документация при пуске</b>
            <ArrowsAnimation isOpen={showStart} />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='4'>
            <Card.Body>
              <div>
                {showStart &&
                  FILES_START.map((item, index) => {
                    return (
                      <React.Fragment key={FILES_START_KEY[index]}>
                        <div>
                          <LoadFilesMultiple
                            keyConst={FILES_START_KEY[index]}
                            index={index}
                            item={item}
                            onSendDataFile={onSendDataFilesMultyple}
                            idCard={dataCard.id}
                            dataDocum={arrDataDocum?.find(
                              (item) => item.description === FILES_START_KEY[index]
                            )}
                            readFileDocument={readFileDocument}
                          />
                        </div>
                      </React.Fragment>
                    )
                  })}
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <div>

      </div>
    </>
  )
}
export default ContentLoadFiles
