import React, { useEffect } from 'react'
import { useState } from 'react'
import { Badge } from 'react-bootstrap'

// dateTradeTimer: Этот параметр, скорее всего, представляет время в миллисекундах, оставшееся до окончания торгов. Он используется для расчета обратного отсчета времени.

// dateTradeMs: Этот параметр представляет собой начальную точку отсчета времени в миллисекундах (например, время начала торгов).

// doStopTrade: Это функция, которая вызывается, когда таймер достигает нуля (торги завершены). Функция, скорее всего, предназначена для выполнения действий, связанных с остановкой или завершением торгов.

// doStepTime: Функция, вызываемая на каждом шаге таймера (в данном случае, каждую секунду). Может использоваться для обновления статуса или выполнения других действий, связанных с таймером.

export default function Timer({ dateTradeTimer, dateTradeMs, doStopTrade, doStepTime }) {
  const [timerNow, setTimerNow] = useState(0)
  const [time, setTime] = useState(0)
  const [stopped, setStopped] = useState(false)
  const step = 1 // шаг опроса в секундах

  useEffect(() => {
    if (stopped) {
      // *: запуск процедуры остановки торгов
      doStopTrade()
    }
  }, [stopped])

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setTimerNow(Date.now())
    }, 1000)

    const newTime = dateTradeMs + dateTradeTimer - timerNow

    let hour = Math.floor(newTime / (1000 * 60 * 60))
    const hm = newTime - hour * 1000 * 60 * 60
    let minutes = Math.floor(hm / (1000 * 60))

    if (minutes < 10) {
      minutes = `0${minutes}`
    }
    const ms = hm - minutes * 1000 * 60
    let seconds = Math.floor(ms / 1000)

    if (!(seconds % step)) {
      doStepTime(seconds)
    }

    if (seconds < 10) {
      seconds = `0${seconds}`
    }

    setTime(`${hour}:${minutes}:${seconds}`)

    if (newTime <= 0) {
      setTime('Торги завершены')
      setStopped(true)

      return () => {
        clearInterval(timerInterval)
      }
    }

    return () => {
      clearInterval(timerInterval)
    }
  }, [timerNow, dateTradeMs, dateTradeTimer])

  return (
    <>
      <div>
        <span style={{ fontWeight: 'bolder' }}>До окончания торгов: &nbsp;</span>

        <h5 style={{ display: 'inline' }}>
          <Badge variant={time === 'Торги завершены' ? 'secondary' : 'info'}>{time}</Badge>
        </h5>
      </div>
    </>
  )
}
