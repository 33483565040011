export const DOCUMENTS = [
    "Реквизиты на фирменном бланке",
    "Устав",
    "Выписка из ЕГРЮЛ",
    "Свидетельство о государственной регистрации",
    "Свидетельство о постановке на налоговый учет",
    "Документ, подтверждающий полномочия единоличного исполнительного органа",
    "Сведения о всей цепочке собственников, включая конечных бенефициаров",
    "Копия реестра акционеров (только для акционерных обществ)",
    "Информационная справка о специализации Поставщика (краткое описание профиля компании)",
    "Сведения из единого реестра субъектов МСП - 3 стр",
    "Приказ о назначении главного бухгалтера",
    "Бухгалтерская отчетность (за последние 2 года), заверенная печатью и подписью руководителя и с отметкой налоговых органов и принятии",
    "Сведения о среднесписочной численности работников за последние 2 календарных года, заверенные печатью и подписью Руководителя",
    "Справка о исполнении налогоплательщиком обязанности по уплате налогов, сборов, пеней, штрафов / Справка о состоянии расчетов по налогам, пеням, штрафам",
    "Договор аренды помещений / Свидетельство собсвенности, подтверждающие фактическое местонахождение организации",
    "Информационное письмо о возможности применения упрощенной системы налогообложения (при наличии)"
]