import React from 'react'
import MainContent from './MainContent'
import Questionnaire from './questionnaire/Questionnaire'
import Search from './search/Search'
import TradeCustomer from './trade/TradeCustomer'
import TradeParticipant from './trade-participant/TradeParticipant'
import Laboratory from './laboratory/Laboratory'
import Coach from './coach/Coach'
import ListField from './production/ListField'
import Employees from './Employees/Employees'
import { CompanySearch } from './filter/CompanySearch'
import { ProductionSearch } from './filter/ProductionSearch'
import { SearchByMatch } from './filter/SearchByMatch'
import SettingsModal from './settings/SettingsModal'
import Calculations from './calculations/Calculations'
import Footer from './Footer'
import Server from './server/server'

import Admin from './admin/Admin'
import FeedBack from './assets/FeedBack'
import SearchOptions from './registryOfEquipment/searchOfOptions/SearchOptions'
import SearchQr from './registryOfEquipment/searchOfQR/SearchQr'
import LifeCycle from './registryOfEquipment/lifeCycleOfEquipment/LifeCycle'
import Logger from './admin/Logger'
import CreatePurchaseOrder from './purchaseOrder/CreatePurchaseOrder'
import PurchaseOrderList from './purchaseOrder/PurchaseOrderList'
import Opportunities from './Opportunities/Opportunities'
import CalculationHeatExchagers from './sthe/CalculationHeatExchangers'
import CoastHeatExchangers from './sthe/CoastHeatExchangers'


// import Server from './server/server'

export default class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      showTrade: false,
      chosenCompaniesFromProductionSearch: [], //выбраннык компании когда заходишь сразу с поиска
      // companiesArray: [], //выбрвнные компании когда заходишь с лота и открываешь поисковик уже там
      radioResultFromProductionSearch: '',
      productFromProductionSearch: [],
      urlPosition: '',
      show: true,
      economicData: [],
      allCompaniesFromSearch: [],
      showLot: false,
      isModerationButtonClicked: false,
      savedDataMessage: false,
      moderClicked: false,
      key: '',
      toModerate: false,
      formName: 'Main'
    }

    this.doUpInfoBlock = this.doUpInfoBlock.bind(this)
    this.doCreateTrade = this.doCreateTrade.bind(this)
    this.lookAvailableTrade = this.lookAvailableTrade.bind(this)

    this.doSendDataChoice = this.doSendDataChoice.bind(this)

    this.getEconomicData = this.getEconomicData.bind(this)
    this.getAllCompaniesFromSearch = this.getAllCompaniesFromSearch.bind(this)
    this.doShow = this.doShow.bind(this)
    this.doChangeLogo = this.doChangeLogo.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.toggleLotModal = this.toggleLotModal.bind(this)

    this.doSendIdInvite = this.doSendIdInvite.bind(this)

    // this.handleClickModerationWithNotification = this.handleClickModerationWithNotification.bind(this)
  }

  // async handleClickModerationWithNotification() {
  //   this.setState({ toModerate: true })
  //   this.setState({ savedDataMessage: true })
  // }

  doSendIdInvite(e) {
    console.log(
      `doSendEmail  (отключен путь для пропсов от поиска, но объект приглашения сохранаяется) :::::: `,
      e
    )
    console.log(`Необходимо отправить приглашение и выдать уведомление в поисковике`)
  }

  toggleLotModal() {
    this.setState({
      showLot: !this.state.showLot
    })
  }

  doEmpty() { }

  doChangeLogo() {
    this.props.onChangeLogo()
  }

  // отключение модалки настроек
  doShow() {
    this.props.onShow()
  }

  getAllCompaniesFromSearch(array) {
    this.setState({
      allCompaniesFromSearch: array
    })
  }

  getEconomicData(data) {
    this.setState({
      economicData: data
    })
  }

  //TODO:  props from ProductionSearch **********************
  doSendDataChoice(radio, company, production) {
    this.setState({
      radioResultFromProductionSearch: radio,
      chosenCompaniesFromProductionSearch: company,
      productFromProductionSearch: production
    })
    this.props.onChangeURL()
    // console.log(company)
  }

  doCreateTrade(data) {
    this.props.onCreateTrade(data)
  }

  doUpInfoBlock(data) {
    this.props.onUpInfoBlock(data)
    // console.log(`INFOBLOCK in Main !!!!!!!!!!!!!!!!!`); // test
  }

  //*смотрим наличие доступных торгов как участник
  lookAvailableTrade(data) {
    if (data.otherNumberTrade) {
      let lengthArray = data.otherNumberTrade.length
      if (lengthArray === 0) {
        this.setState({ showTrade: false })
      } else {
        this.setState({ showTrade: true })
      }
    }
  }

  async componentDidMount() {
    const result = await Server.getDataFromServer(sessionStorage.getItem('login'), 'registration')
    const key = result.key[localStorage.getItem('idUser')]

    const resultGet = await Server.getDataFromServer(localStorage.getItem('idUser'), 'moderation')
    const dataSentOnModeration = resultGet?.sentOnModertaion ? resultGet.sentOnModertaion : false
    // const dataSentOnModeration = resultGet.sentOnModertaion
    this.setState({
      savedDataMessage: dataSentOnModeration
    })

    this.setState({
      urlPosition: this.props.urlPosition,
      show: false,
      key: key, // исправлено 03.11.2023
    })

    setTimeout(() => {
      this.lookAvailableTrade(this.props.infoBlock)
      this.setState({ show: true })
    }, 500)
  }

  render() {
    //* пропсы с URL по которому следует загрузить блок
    const urlPosition = this.props.urlPosition
    // console.log(this.state.toModerate);

    return (
      <div className='app'>
        <div className='content'>
          {/* {this.state.key === 'zero' && (
            <div className='quest_moderation_btn' style={{ left: urlPosition === '/questionnaire' ? '490px' : '330px' }}>
              <SaveCurrentDataButton
                handleSaveClick={this.handleClickModerationWithNotification}
                needDisable={true}
                disableCondition={this.state.savedDataMessage || this.state.toModerate ? true : false}
                savedDataMessage={this.state.savedDataMessage}
                buttonTextDefault={'Отправить на модерацию'}
                buttonTextSaved={'Заполненные данные отправлены на модерацию'}
                needCustomIcon={true}
                needCustomStyle={false}
                // customStyle='quest_moderation_btn'
                customIcon={<PublishedWithChangesOutlinedIcon style={{ marginRight: '5px' }} />}
              />
            </div>
          )} */}
          {urlPosition === '' ? <MainContent toModerate={this.state.toModerate} /> : ''}

          {urlPosition === '/company_search' ? (
            <CompanySearch
              infoBlock={this.props.infoBlock}
              onUpInfoBlock={this.doUpInfoBlock}
              viewQuest={this.props.viewQuest}
              sendDataChoice={this.doSendDataChoice}
              toggleLotModal={this.toggleLotModal}
            />
          ) : (
            ''
          )}

          {urlPosition === '/production_search' ? (
            <ProductionSearch
              infoBlock={this.props.infoBlock}
              onUpInfoBlock={this.doUpInfoBlock}
              viewQuest={this.props.viewQuest}
              sendDataChoice={this.doSendDataChoice}
              economicData={this.state.economicData}
              chosenCompaniesFromProductionSearch={this.state.chosenCompaniesFromProductionSearch}
              getEconomicData={this.getEconomicData}
              getAllCompaniesFromSearch={this.getAllCompaniesFromSearch}
              toggleLotModal={this.toggleLotModal}
              onSendIdInvite={this.doSendIdInvite}
              needTextInButtons={true}
            />
          ) : (
            ''
          )}

          {urlPosition === '/search_by_match' ? <SearchByMatch /> : ''}

          {urlPosition === '/questionnaire' && (
            <>
              <Questionnaire
                infoBlock={this.props.infoBlock}
                onUpInfoBlock={this.doUpInfoBlock}
                viewQuest={this.props.viewQuest}
                getEconomicData={this.getEconomicData}
                handleClickModerationWithNotification={this.handleClickModerationWithNotification}
                chosenCompaniesFromProductionSearch={this.state.chosenCompaniesFromProductionSearch}
              />
            </>
          )}

          {urlPosition === '/employees' ? <Employees showTrade={this.state.showTrade} /> : ''}

          {urlPosition === '/tech_others' ? <Search /> : ''}
          {urlPosition === '/production' ? <ListField /> : ''}
          {urlPosition === '/customer' && (
            <TradeCustomer
              onCreateTrade={this.doCreateTrade}
              keyNameTrade={this.props.keyNameTrade}
              chosenCompaniesFromProductionSearch={this.state.chosenCompaniesFromProductionSearch}
              radioResultFromProductionSearch={this.state.radioResultFromProductionSearch}
              productFromProductionSearch={this.state.productFromProductionSearch}
              doSendDataChoice={this.doSendDataChoice}
              infoBlock={this.props.infoBlock}
              onUpInfoBlock={this.doUpInfoBlock}
              viewQuest={this.props.viewQuest}
              getEconomicData={this.getEconomicData}
              allCompaniesFromSearch={this.state.allCompaniesFromSearch}
              getAllCompaniesFromSearch={this.getAllCompaniesFromSearch}
              toggleLotModal={this.toggleLotModal}
              showLot={this.state.showLot}
            />
          )}

          {urlPosition === '/participant' ? <TradeParticipant /> : ''}
          {urlPosition === '/laboratory' ? <Laboratory /> : ''}

          {urlPosition === '/coach' ? <Coach /> : ''}

          {urlPosition === '/calculations' ? <Calculations /> : ''}

          {urlPosition === '/admin' ? (
            <Admin clickClose={() => this.props.closeAdminPanel()} />
          ) : (
            ''
          )}

          {urlPosition === '/logger' ? <Logger /> : ''}

          {urlPosition === '/search_options' ? <SearchOptions /> : ''}

          {urlPosition === '/search_qr' ? <SearchQr /> : ''}

          {urlPosition === '/life_cycle' ? <LifeCycle /> : ''}

          {urlPosition === '/purchase_order' ? <CreatePurchaseOrder /> : ''}

          {urlPosition === '/purchase_order_list' ? (
            <PurchaseOrderList
              infoBlock={this.props.infoBlock}
              onUpInfoBlock={this.doUpInfoBlock}
              viewQuest={this.props.viewQuest}
            />
          ) : (
            ''
          )}

          {this.props.viewShowSettings && (
            <SettingsModal
              setNewIcon={this.props.setNewIcon}
              onShow={this.doShow}
              onChangeLogo={this.doChangeLogo}
              openSettings={this.props.openSettings}
            />
          )}

          {urlPosition === '/opportunities' ? <Opportunities /> : ''}

          {urlPosition === '/calculation_heat_exchangers' ? <CalculationHeatExchagers /> : ''}

          {urlPosition === '/coast_heat_exchengers' ? <CoastHeatExchangers /> : ''}
        </div>

        {/* <FeedBack /> */}
        <Footer />
      </div>
    )
  }
}
