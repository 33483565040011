/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Navbar, DropdownButton, Dropdown, Badge } from 'react-bootstrap'
import Svg_Angle_double_left from '../../icon/Svg_Angle_double_left'
import Svg_Angle_double_right from '../../icon/Svg_Angle_double_right'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined'
import Server from '../server/server'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined'
import { ExitConfirmationModal } from './ExitConfirmationModal'
import { CapacityButton } from '../../CapacityButton/CapacityButton'
import ChatIdLots from './ChatIdLots'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
import ChatWs from '../chat/ChatWs'
import CreateLot from '../trade/CreateLot'
import ConfirmationLot from '../trade-participant/ConfirmationLot'
import Timezone from './Timezone'
import { shortenName } from '../assets/shortenStringsFunction'
export default class NaviBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      viewQuest: true,
      showExitModal: false,
      showCapacity: false,
      showChat: false,
      showLot: false,
      icon: '',
      changeLogo: 0,
      arrayIdLots: [], // массив ID лотов
      showArrayIdLots: false,
      arrLots: [], // массив ЛОТов
      idLot: '',
      idChat: '',
      numberLot: '',
      statusPerson: '',
      participant: [],
      isSuccessfullyLaunched: false,
      showLotParticipant: false
    }

    this.handleClickNavbar = this.handleClickNavbar.bind(this)
    this.handleClickExit = this.handleClickExit.bind(this)
    this.handleChangeViewQuest = this.handleChangeViewQuest.bind(this)
    this.handleClickOnPersonalArea = this.handleClickOnPersonalArea.bind(this)
    this.toggleExitModal = this.toggleExitModal.bind(this)
    this.handleClickSettings = this.handleClickSettings.bind(this)
    this.handleClickChat = this.handleClickChat.bind(this)
    this.doResultClickId = this.doResultClickId.bind(this)
    this.doChangeVisibleChat = this.doChangeVisibleChat.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.doIdLot = this.doIdLot.bind(this)

    this.showSuccesfullyLaunchedMsg = this.showSuccesfullyLaunchedMsg.bind(this)
    this.handleLotModal = this.handleLotModal.bind(this)
    this.doClose = this.doClose.bind(this)

    this.updateTable = this.updateTable.bind(this)

    this.doReLoadTable = this.doReLoadTable.bind(this)
  }

  doReLoadTable() {
    console.log(`Empty function`)
  }

  updateTable() {
    console.log(`Close LOT. Empty function`) // test
  }

  doEmpty() { }

  doClose() {
    this.setState({ showLotParticipant: !this.state.showLotParticipant })
  }

  showSuccesfullyLaunchedMsg() {
    this.setState({
      isSuccessfullyLaunched: true
    })
    setTimeout(() => {
      this.setState({
        isSuccessfullyLaunched: false
      })
    }, 2000)

    // console.log(`!!!!!!!!!!! isSuccessfullyLaunched do change`);
  }

  handleLotModal() {
    // console.log(`OPEN - CLOSE `);
    // console.log(`STATE:::: `, this.state); // test
    this.setState({
      showLot: !this.state.showLot
    })
    // setTimeout(() => { console.log(`STATE :::: `, this.sate) }, 3000)
  }

  doIdLot(id) {
    if (this.state.statusPerson === 'organizer') {
      // console.log(`PARENT ID LOT organizer: `, id);

      this.setState({
        showChat: !this.state.showChat,
        idLot: id,
        showLot: true
      })
    }

    if (this.state.statusPerson === 'participant') {
      // console.log(`PARENT ID LOT participant: `, id);
      this.setState({
        showChat: !this.state.showChat,
        idLot: id,
        showLotParticipant: true
      })
    }
  }

  doChangeVisibleChat() {
    this.setState({ showChat: false })
  }

  async getLotData(id) {
    const result = await Server.getDataFromServer(id, 'trade')

    // console.log(`RESULT >>> `, result); // test

    return result
  }

  doResultClickId(e) {
    ; (async () => {
      try {
        let statusPerson = 'not found'
        let idChat = ''

        if (
          this.state.arrLots.organizer &&
          this.state.arrLots.organizer.find((item) => item && item._id === e)
        ) {
          statusPerson = 'organizer'
          const data = this.state.arrLots.organizer.find((item) => item && item._id === e)
          // console.log(`idChat organizer >>>>>>>>>>>. `, data.data.data.chat); // test
          idChat = data.data.data.chat
        }

        if (
          this.state.arrLots.participant &&
          this.state.arrLots.participant.find((item) => item && item._id === e)
        ) {
          statusPerson = 'participant'
          const data = this.state.arrLots.participant.find((item) => item && item._id === e)
          // console.log(`idChat participant >>>>>>>>>>>. `, data.data.data.chat); // test
          idChat = data.data.data.chat
        }

        // console.log(`STATUS >>>>>>>>> `, [statusPerson, e, idChat]); // [status, idLot, idChat] test
        // console.log(`STATUS >>>>>>> [status, idLot, idChat]`); // test
        this.setState({ showArrayIdLots: false })

        const resultLot = await this.getLotData(e)

        this.setState({
          idLot: e,
          idChat: idChat,
          numberLot: resultLot.data.numberLot,
          statusPerson: statusPerson,
          participant: resultLot.data.participant,
          showChat: true,
          filterAll: [resultLot.data.organizerId]
        })
      } catch (err) {
        console.log(`Ошибка запуска чата: `, err)
      }
    })()
  }

  /**
   * Из полученного объекта массивов, собирает массив ID лотов
   * @param {Object} obj  Объект массивов ЛОТов
   * @returns {Array} Массив ID ЛОТов
   */
  doArrayIdLot(obj) {
    let arrayIdLot = []
    let arrayNameLot = []

    if (obj.organizer.length) {
      obj.organizer.forEach((el) => {
        if (el) {
          arrayIdLot.push(el._id)
          arrayNameLot.push(el.data.data.numberLot)
        }
      })
    }

    if (obj.participant.length) {
      obj.participant.forEach((el) => {
        if (el) {
          arrayIdLot.push(el._id)
          arrayNameLot.push(el.data.data.numberLot)
        }
      })
    }

    return { arrayIdLot: arrayIdLot, arrayNameLot: arrayNameLot }
  }

  handleClickChat() {
    ; (async () => {
      try {
        // console.log(`chat message:::::: `, this.props.chatMessage); // test
        const result = await Server.getCollectChat(this.props.chatMessage)
        // console.log(`RESULT CHAT ARRAY::::::::: `, result); // test

        if (result.ERROR) throw new Error(result.message)

        this.setState({ arrLots: result })
        const arrayAll = this.doArrayIdLot(result)

        // console.log(`REESULT ID::: `, arrayAll); // test
        // console.log(
        //   `CHAT:::: `,
        //   this.state.showArrayIdLots,
        //   this.props.chatMessage.length
        // ); // test
        this.setState({
          arrayIdLots: arrayAll,
          showArrayIdLots: !this.state.showArrayIdLots
        })
      } catch (err) {
        console.log(`Ошибка получения массивов лотов: `, err)
      }
    })()
  }

  // модалка настройки
  handleClickSettings() {
    this.props.onClickSettings()
  }

  async handleClickOnPersonalArea() {
    const data = await Server.getDataFromServer(sessionStorage.getItem('login'), 'registration')
    data.password = 'password'

    this.props.onStartPersonalArea(data)
  }

  toggleExitModal() {
    this.setState({
      showExitModal: !this.state.showExitModal
    })
  }

  //*меняем вид анкеты
  handleChangeViewQuest() {
    this.setState({ viewQuest: !this.state.viewQuest })
    setTimeout(() => {
      this.props.onChangeViewQuest(this.state.viewQuest)
    })
  }

  //* Выход из системы
  handleClickExit(e) {
    ; (async () => {
      // e.preventDefault();
      // STOP INTERVAL - заложить тут остановку setInterval запущенную в BLOCK для получения обновлений динамически

      // delete cookies
      document.cookie = `inn=${localStorage.getItem('idUser')}; max-age=-1`
      document.cookie = `session=${sessionStorage.getItem('sessionId')}; max-age=-1`
      // document.cookie = `login=${sessionStorage.getItem("login")}; max-age=-1`

      await Server.doCloseSession()
      localStorage.removeItem('nano')
      localStorage.removeItem('idUser')
      localStorage.removeItem('loginReg')
      sessionStorage.removeItem('sessionId')
      // sessionStorage.removeItem('login')
    })()

    setTimeout(() => {
      window.location.href = '/'
    })
  }

  handleClickNavbar() {
    this.props.onChangeShow()
  }

  componentDidMount() { }

  render() {
    // console.log(this.props.chatMessage);

    const show = this.props.show
    this.nameCompany = this.props.nameCompany
    const statusPerson = this.state.statusPerson
    const processedName = shortenName(this.nameCompany?.value, 40)

    return (
      <div className='display_flex right_navbar'>
        <Navbar
          collapseOnSelect
          expand='lg'
          className={show ? 'navigation__navbar_long' : 'navigation__navbar'}
        >
          {/* child 1 */}
          <div className='collapse_btn'>
            <Navbar.Brand href='#home' onClick={this.handleClickNavbar}>
              {show ? (
                <Svg_Angle_double_right colorSvg='rgba(25, 25, 25, 0.51)' />
              ) : (
                <Svg_Angle_double_left colorSvg='rgba(25, 25, 25, 0.51)' />
              )}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='basic-navbar-nav' />
            <Navbar.Collapse id='basic-navbar-nav'></Navbar.Collapse>
          </div>

          {/* child 2 */}
          <div className='left_side_buttons'>
            <div className='three_btns_group'>
              <Timezone />

              <CapacityButton
                buttonName='Загрузка'
                id='capacityDate'
                headerText={'Выберете дату, до которой предприятие загружено:'}
              />
              <CapacityButton
                buttonName='Масса'
                id='requiredWeight'
                headerText={
                  'Укажите минимальную массу оборудования, по которому вы хотите получать заявки:'
                }
              />

              {/* <div style={{ position: 'relative' }} title='Ваш рейтинг'>
                <TrendingUpOutlinedIcon color='action' />
                <Badge className='navbar_info_span navbar_info_span_rating'>4</Badge>
              </div> */}
              <div
                style={{ position: 'relative', cursor: 'pointer' }}
                onClick={this.handleClickChat}
                title='Непрочитанные сообщения чата'
              >
                <ForumOutlinedIcon color='action' />
                {this.props.chatMessage.length !== 0 && (
                  <Badge className='navbar_info_span navbar_info_span_msg'>
                    {this.props.chatMessage.length}
                  </Badge>
                )}
              </div>
            </div>

            <div className='icon_top_navbar' title={this.nameCompany?.value}>
              <DropdownButton
                menuAlign={{ lg: 'left' }}
                drop='left'
                variant='primary'
                title={
                  <>
                    <span className='right_menu_company_name'>
                      {this.nameCompany && processedName}
                    </span>{' '}
                    <img className={'company_logo_img'} src={this.props.changeLogoURL} alt='' />
                  </>
                }
              >
                <div>
                  <Dropdown.Item eventKey='1' onClick={this.handleClickOnPersonalArea}>
                    <span className='text_decoration_none'>
                      <AdminPanelSettingsOutlinedIcon
                        color='disabled'
                        fontSize='small'
                        style={{ marginRight: '5px' }}
                      />
                      Личный кабинет
                    </span>
                  </Dropdown.Item>

                  <Dropdown.Item eventKey='2' onClick={this.handleClickSettings}>
                    <SettingsOutlinedIcon
                      color='disabled'
                      fontSize='small'
                      style={{ marginRight: '5px' }}
                    />
                    Настройки
                  </Dropdown.Item>

                  <Dropdown.Item eventKey='3'>
                    <NotificationsOutlinedIcon
                      color='disabled'
                      fontSize='small'
                      style={{ marginRight: '2px' }}
                    />{' '}
                    Уведомления
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  <Dropdown.Item onClick={this.toggleExitModal} eventKey='4'>
                    <span>
                      <ExitToAppOutlinedIcon
                        color='disabled'
                        fontSize='small'
                        style={{ marginRight: '5px' }}
                      />{' '}
                      Выход
                    </span>
                  </Dropdown.Item>
                </div>
              </DropdownButton>
            </div>
          </div>
        </Navbar>

        {this.state.showExitModal && (
          <ExitConfirmationModal
            handleClickExit={this.handleClickExit}
            toggleExitModal={this.toggleExitModal}
          />
        )}
        {this.state.showArrayIdLots && this.props.chatMessage.length && (
          <ChatIdLots arrayAll={this.state.arrayIdLots} resultClickId={this.doResultClickId} />
        )}

        {this.state.showChat && (
          <ChatWs
            onChangeVisibleChat={this.doChangeVisibleChat} // chat to close
            onCreateIdChat={this.doEmpty}
            idChat={this.state.idChat}
            forName='all'
            idCompany={statusPerson === 'organizer' ? this.state.participant : this.state.filterAll}
            filterAll={statusPerson === 'participant' && this.state.filterAll} // ID organizer
            onIdLot={this.doIdLot} // start LOT
            numberLot={this.state.numberLot}
            updateQuantityMessage={this.props.updateQuantityMessage} //обновление кол-ва сообщений в BLOCK
            // idBox={this.state.data.id}
            modalWidth={'width_50'}
          />
        )}

        {this.state.showLot && (
          <CreateLot
            dataChoiceProductions={this.state.dataChoiceProductions}
            radioResult={this.state.radioResult}
            id={this.state.idLot}
            chosenCompaniesFromProductionSearch={this.props.chosenCompaniesFromProductionSearch}
            economicData={this.props.economicData}
            infoBlock={this.props.infoBlock}
            onUpInfoBlock={this.props.onUpInfoBlock}
            viewQuest={this.props.viewQuest}
            getEconomicData={this.props.getEconomicData}
            doSendDataChoice={this.props.doSendDataChoice}
            allCompaniesFromSearch={this.props.allCompaniesFromSearch}
            getAllCompaniesFromSearch={this.props.getAllCompaniesFromSearch}
            nameTable={this.state.nameTable}
            handleLotModal={this.handleLotModal}
            showQuit_test={this.state.showQuit_test}
            showSuccesfullyLaunchedMsg={this.showSuccesfullyLaunchedMsg}
            updateTable={this.updateTable}
          />
        )}

        {this.state.showLotParticipant && (
          <ConfirmationLot
            idLot={this.state.idLot}
            toggleConfirmationLot={this.doClose}
            doReLoadTable={this.doReLoadTable}
          />
        )}
      </div>
    )
  }
}
