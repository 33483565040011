import React from 'react'
import { Alert, Card, Table } from 'react-bootstrap'
import LocationCityOutlinedIcon from '@mui/icons-material/LocationCityOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import DeveloperBoardOutlinedIcon from '@mui/icons-material/DeveloperBoardOutlined';

export const SearchCompanyData = ({ closeCompanyDataModal, resultCompany }) => {

    return (
        <>
            <Card className="modal_window" >
                <div className="shadow" onClick={closeCompanyDataModal}></div>
                <div className={"modal_common p_40 width_40  z_index_1000"}>
                    <Alert
                        variant="dark"
                        className="header_alert_common"
                        onClick={closeCompanyDataModal}
                        dismissible>
                        <Alert.Heading><LocationCityOutlinedIcon fontSize="large" color="primary" /> Данные о компании</Alert.Heading>
                    </Alert>
                    <Card.Body className="container_common height_500 overflow_y_scroll overflow_x_hidden">
                        <Card.Text>
                            <Table>
                                <thead>
                                    <tr>
                                        <th className='fw_bolder'><DateRangeOutlinedIcon color="disabled" fontSize="small" className='pb_2' /> Наименования данных</th>
                                        <th className='fw_bolder'><DeveloperBoardOutlinedIcon color="disabled" fontSize="small" className='pb_2'/> Данные</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {resultCompany.map(company => (
                                        <tr key={company.id}>
                                            <td>{company.description}</td>
                                            <td>{company.value}</td>
                                        </tr>
                                    ))
                                    }
                                </tbody>
                            </Table>
                        </Card.Text>
                    </Card.Body>
                </div>
            </Card>
        </>
    )

}