/* eslint-disable default-case */

import React from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import { FilteredCompanies } from './FilteredCompanies';
import SearchCompany1 from './SearchCompany1';
import RotateLeftOutlinedIcon from '@mui/icons-material/RotateLeftOutlined';


export default class Search extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
            showStartCompany: false,
            nameCompany: '',
            data: [],
            dataSearch: [],
            viewDataTable: [],
            showAllCompanies: false,
            dataChoiceProductions: []
        }


        this.handleClickShow = this.handleClickShow.bind(this);
        this.doChoiceCompany = this.doChoiceCompany.bind(this);
        // this.doTableChoice = this.doTableChoice.bind(this);
        this.doChangeShow = this.doChangeShow.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
        this.toggleTables = this.toggleTables.bind(this);

        this.doChoice = this.doChoice.bind(this);
    }

    doChoice(data) {
        // console.log(`DATA ++++++++>>> `, data); // test
        this.setState({ dataChoiceProductions: data });
        this.toggleTables() //this.toggleTables();
    }


    toggleTables() {
        this.setState({
            showAllCompanies: !this.state.showAllCompanies
        })
    }

    doEmpty() { };


    //запуск формы по поиску предприятий
    doChangeShow() {
        this.setState({ showStartCompany: false })
    }

    // doTableChoice(renderTable) {
    //Рендер строк компаний
    //     console.log(renderTable);//test
    // }

    doChoiceCompany(data) {
        //ID выбранных компаний
        console.log(data);//test
    }

    handleClickShow() {
        this.setState({
            // show: !this.state.show,
            showStartCompany: true
        })

    }

    handleClickShadow() {
        this.setState({ show: false })
    }

    componentDidMount() {
        this.setState({
            show: true,
            showStartCompany: true,
        });
    }

    render() {

        return (
            <div style={{ marginTop: '50px', marginBottom: '80px' }}>

                {this.state.showAllCompanies && <>
                    <Container style={{ paddingLeft: '0' }}>
                        <Row>&nbsp;</Row>
                        <Button onClick={this.toggleTables}><RotateLeftOutlinedIcon className='pb_3' /> Назад к фильтрам</Button>
                    </Container>

                    <SearchCompany1
                        onChangeShow={this.doChangeShow}
                        onChoiceCompany={this.doEmpty}
                        onTableChoice={this.doTableChoice}
                        toggleTables={this.toggleTables}
                        handleClickShow={this.handleClickShow}
                        dataChoiceProductions={this.state.dataChoiceProductions}

                    />
                </>

                }

                {!this.state.showAllCompanies &&
                    <FilteredCompanies
                        toggleTables={this.toggleTables}
                        onChoice={this.doChoice}
                    />}
            </div>
        )
    }
}

{/** Отключен для реконструкции. Будет вклчать в себя направления поиска */ }
{/* <SearchModal
                    show={show}
                    onChangeShow={this.handleClickShow}
                    onChoiceCompany={this.doChoiceCompany}
                    onTableChoice={this.doTableChoice}
                /> */}

{/** Временно размещен тут. Далее должен переехать в <SearchModal/> */ }
{/* {this.state.showStartCompany ?
                    <SearchCompany
                        onChangeShow={this.doChangeShow}
                        onChoiceCompany={this.doEmpty}
                        onTableChoice={this.doTableChoice}
                    /> : ''} */}




{/* <Container className='container_search' >
                    <Card className="text-center">
                        <Card.Header>Поиск <SearchOutlinedIcon color="disabled" className='pb_2' /></Card.Header>
                        <Card.Body className='container_height_overflow container_search'>
                            <Card.Title>Поиск по предприятиям в базе LOTUS</Card.Title>
                            <Card.Text>
                            </Card.Text>
                            <Button onClick={this.handleClickShow} variant="primary">Запустить форму поиска</Button>
                        </Card.Body>
                        <Card.Footer className="text-muted"></Card.Footer>
                    </Card>
                </Container> */}