/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import { Alert, Container, Form, Row } from 'react-bootstrap'
import RadioButtonForm from './RadioButtonForm'
import CheckForm from './CheckForm'
import ModalQuit from '../ModalQuit'
import { nanoid } from 'nanoid'
import AllModalsFooter from '../assets/AllModalsFooter'
import { SaveCurrentDataButton } from '../assets/BasicButtons'
import { createData } from './functionsForm'
import { isEqual } from 'lodash-es'

export default class Form_Sixteen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      language: 'rus',
      dataValue: [],
      id: 1,
      maxId: 15,
      nameForm: 'Sixteen',
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      base: [],
      show: false,
      showTrailer: false,
      showSuper: false,
      showQuit: false,
      previousState: [],
      savedDataMessage: false,
      dataBeenSaved: false
    }

    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.renderViewPosition = this.renderViewPosition.bind(this)

    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
  }

  saveDataWithNotification() {
    this.handleClickSave()

    this.setState({
      savedDataMessage: true,
      dataBeenSaved: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })
  }

  //*Пустышка
  doEmpty() {}

  //* меняем видимость блока при выборе ДА / НЕТ
  doChangeVisionBlock(data, id) {
    // console.log(data + ' <=> ' + id);//test

    if (data === 'Да' && +id === 3) this.setState({ show: true })
    if (data === 'Нет' && +id === 3) this.setState({ show: false })
    if (+id === 8) this.setState({ showTrailer: data })
    if (+id === 11) this.setState({ showSuper: data })
  }

  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  doQuitWithSave() {
    this.setState({ showQuit: false })
    this.handleClickSave()
    this.props.onChangeView('Sixteen')
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView('Sixteen')
  }

  //*формируем данные в массив объектов для отправки на сервер
  handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach((dataD) => {
        if (+dataD.id === i) {
          data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach((dataE) => {
        if (+dataE.id === i) {
          data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
        }
      })
    }
    this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере
    if (this.state.lengthDataFromServer < 1) {
      console.log('CREATE DATA')
      this.props.onCreateDataServer(data, 'Sixteen', localStorage.getItem('idUser'))
    } else {
      console.log('UPDATE DATA')
      this.props.onUpdateDataOnServer(data, 'Sixteen', localStorage.getItem('idUser'))
    }
    setTimeout(() => {
      console.log(this.state.dataOnServer)
    }) //test
    // this.handleClickShadow();
  }

  //* данные с полей формы, формируются в массив объектов
  doChangeValue(data) {
    console.log(data) //test
    this.setState({ dataValue: [...this.state.dataValue, data] })
    this.setState({
      dataBeenSaved: false
    })
  }

  renderViewPosition() {
    this.props.data.forEach((data) => {
      this.doChangeVisionBlock(data.value, +data.id)
    })
  }

  compare() {
    const prev = this.state.previousState
    const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)

    const isStateEqual = isEqual(prev, curr)
    if (isStateEqual) {
      return true
    }
    return false
  }

  smartWindowFunction() {
    const isStateEqual = this.compare()
    const { dataBeenSaved, dataValue } = this.state
    if (isStateEqual || dataBeenSaved || dataValue.length === 0) {
      this.props.onChangeView('Sixteen')
    } else {
      this.handleClickShadow()
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer })
      this.renderViewPosition()
    }, 1000)

    if (this.props.data.length) {
      const previousState = JSON.parse(JSON.stringify(this.props.data))
      this.setState({ previousState: this.props.data })
    }
  }

  render() {
    const view = this.props.view
    this.value = this.props.data
    const viewQuest = this.props.viewQuest

    console.log(this.state.dataValue)

    return (
      <>
        {this.state.showQuit ? <ModalQuit onQuitWithSave={this.doQuitWithSave} onQuitWithoutSave={this.doQuitWithoutSave} closeQuitModal={this.closeQuitModal} /> : ''}

        <div className='modal_window' style={{ display: view ? 'block' : 'none' }}>
          <div
            className={viewQuest ? 'shadow_form' : 'shadow_form_off'}
            // onClick={this.handleClickShadow}
            onClick={this.smartWindowFunction}
          ></div>
          <Form className={viewQuest ? 'form_main' : 'form_main_view'}>
            <Alert
              variant='dark'
              className='form_alert'
              onClick={this.handleClickHeader}
              // onClose={() => this.handleClickShadow()}
              onClose={this.smartWindowFunction}
              dismissible
            >
              <Alert.Heading>Грузоперевозки </Alert.Heading>
            </Alert>
            <div>
              <Container className='form_container'>
                <Form.Group>
                  <Row>
                    <RadioButtonForm
                      id={1}
                      width={12}
                      show={true}
                      title='Наличие подъездных Ж/Д путей'
                      label1='Да'
                      label2='Нет'
                      placeholder='Наличие подъездных ЖД путей'
                      description='Логистика'
                      value={this.value[1] ? this.value[1].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                    />
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <RadioButtonForm
                      id={2}
                      width={12}
                      show={true}
                      title='Возможность отгрузки водным транспортом'
                      label1='Да'
                      label2='Нет'
                      placeholder='Возможность отгрузки водным транспортом'
                      description='Логистика'
                      value={this.value[2] ? this.value[2].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doEmpty}
                      nameForm={this.state.nameForm}
                    />
                  </Row>
                </Form.Group>

                <Form.Group>
                  <Row>
                    <RadioButtonForm
                      id={3}
                      width={12}
                      show={true}
                      title='Оказываете ли Вы услуги перевозки грузов своим автотранспортом сторонним организациям?'
                      label1='Да'
                      label2='Нет'
                      placeholder='оказывать услуги перевозки грузов'
                      description='Логистика'
                      value={this.value[3] ? this.value[3].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                    />
                  </Row>
                </Form.Group>
                <Form.Group style={{ display: this.state.show ? 'block' : 'none' }}>
                  <Row>
                    <CheckForm
                      name={nanoid()}
                      id={4}
                      width={6}
                      show={true}
                      placeholder='Грузоподъемность до 0,5тн'
                      description='Логистика'
                      value={this.value[4] ? this.value[4].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                    />
                  </Row>
                  <Row>
                    <CheckForm
                      name={nanoid()}
                      id={5}
                      width={6}
                      show={true}
                      placeholder='Грузоподъемность до 1,5тн'
                      description='Логистика'
                      value={this.value[5] ? this.value[5].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                    />
                  </Row>
                  <Row>
                    <CheckForm
                      name={nanoid()}
                      id={6}
                      width={6}
                      show={true}
                      placeholder='Грузоподъемность до 5тн'
                      description='Логистика'
                      value={this.value[6] ? this.value[6].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                    />
                  </Row>
                  <Row>
                    <CheckForm
                      name={nanoid()}
                      id={7}
                      width={6}
                      show={true}
                      placeholder='Грузоподъемность до 10тн'
                      description='Логистика'
                      value={this.value[7] ? this.value[7].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                    />
                  </Row>
                  <Row>
                    <CheckForm
                      name={nanoid()}
                      id={8}
                      width={6}
                      show={true}
                      placeholder='Грузоподъемность до 15тн'
                      description='Логистика'
                      value={this.value[8] ? this.value[8].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                    />
                  </Row>
                </Form.Group>
                <Form.Group style={{ display: this.state.showTrailer && this.state.show ? 'block' : 'none' }}>
                  <Row>
                    <CheckForm
                      name={nanoid()}
                      id={9}
                      width={6}
                      show={true}
                      placeholder='Длина полуприцепа - 9м'
                      description='Логистика'
                      value={this.value[9] ? this.value[9].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                    />
                  </Row>
                  <Row>
                    <CheckForm
                      name={nanoid()}
                      id={10}
                      width={6}
                      show={true}
                      placeholder='Длина полуприцепа - 12м'
                      description='Логистика'
                      value={this.value[10] ? this.value[10].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                    />
                  </Row>
                  <Row>
                    <CheckForm
                      name={nanoid()}
                      id={11}
                      width={6}
                      show={true}
                      placeholder='Длина полуприцепа - свыше 12м'
                      description='Логистика'
                      value={this.value[11] ? this.value[11].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                    />
                  </Row>
                </Form.Group>
                <Form.Group style={{ display: this.state.showTrailer && this.state.showSuper && this.state.show ? 'block' : 'none' }}>
                  <Row>
                    <CheckForm
                      name={nanoid()}
                      id={12}
                      width={6}
                      show={true}
                      placeholder='Платформа'
                      description='Логистика'
                      value={this.value[12] ? this.value[12].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                    />
                  </Row>
                  <Row>
                    <CheckForm
                      name={nanoid()}
                      id={13}
                      width={6}
                      show={true}
                      placeholder='Бортовой'
                      description='Логистика'
                      value={this.value[13] ? this.value[13].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                    />
                  </Row>
                  <Row>
                    <CheckForm
                      name={nanoid()}
                      id={14}
                      width={6}
                      show={true}
                      placeholder='Низкорамник'
                      description='Логистика'
                      value={this.value[14] ? this.value[14].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                    />
                  </Row>
                  <Row>
                    <CheckForm
                      name={nanoid()}
                      id={15}
                      width={6}
                      show={true}
                      placeholder='Не габаритная перевозка'
                      description='Логистика'
                      value={this.value[15] ? this.value[15].value : ''}
                      onChangeValue={this.doChangeValue}
                      onChangeVisionBlock={this.doChangeVisionBlock}
                      nameForm={this.state.nameForm}
                    />
                  </Row>
                  <Row> &nbsp; </Row>
                </Form.Group>

                {/* <Container> */}
                <Row> &nbsp; </Row>

                <Row> &nbsp; </Row>
              </Container>
              <AllModalsFooter
                footerContent={
                  //     <SaveButtonGreen
                  //     handleSaveClick={this.handleClickSave}
                  //     needDisable={false}
                  //     saveButtonText='Сохранить'
                  //   />

                  <SaveCurrentDataButton handleSaveClick={this.saveDataWithNotification} needDisable={false} savedDataMessage={this.state.savedDataMessage} buttonTextDefault={'Сохранить'} buttonTextSaved={'Данные сохранены'} needCustomIcon={false} />

                  // <Button
                  //     variant="success"
                  //     onClick={this.handleClickSave}
                  // >
                  //     Сохранить
                  // </Button>
                }
              />
            </div>
          </Form>
        </div>
      </>
    )
  }
}
