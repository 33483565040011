import React from 'react'
import { Container, Row, Modal, Form, Card, Alert, Spinner } from 'react-bootstrap'
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CreateLot from './CreateLot'
import RadioButtonThree from '../questionnaire/RadioButtonThree'
import { FilteredCompanies } from '../search/FilteredCompanies'
import Server from '../server/server'
import { BackBtn } from '../assets/BackBtn'
import UniversalTable from '../assets/UniversalTable'
import { CURRENCY } from '../assets/currency'
import { loadTradeTable } from './loadTradeTable'
import { RenderModalBody } from './RenderModalBody'
import ModalWindowTrade from '../assets/ModalWindowTrade'
import QuitModalUniversal from '../QuitModalUniversal'
import { BasicButton, CreateButton, GoToTradeButton } from '../assets/BasicButtons'
import { RenderModalBodyReduction } from './RenderModalBodyReduction'
import LeftAndRightArrowsButtons from '../assets/LeftAndRightArrowsButtons'
import changeTimeToLocal from '../assets/changeTimeToLocal'
import { categorizeTrades, processTabledata } from './processTradeObject'
import ModalError from '../ModalError'

export default class TradeCustomer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      show: false,
      showLot: false,
      timezone: '',
      chowView: false,
      nameTable: '',
      show_errorRequest: false,
      showTable: false,
      removeble: false,
      dataTable: [],
      dataOnTrade: [],
      base: [],
      insidePreLotModal: false,
      profiles: [],
      selectCheck: false,
      showChecked: true,
      dataChoiceProductions: [],
      radioResult: '',
      choisenIdTrade: '',
      selectValue: '',
      selectOptionsArray: [
        { text: 'Все торги', value: 'all' },
        { text: 'Черновики', value: 'drafts' },
        { text: 'Прием предложений', value: 'incomingOffers' },
        { text: 'Торги', value: 'current' },
        { text: 'Подведение итогов', value: 'finishedNoWinner' },
        { text: 'Объявлен победитель', value: 'finishedWinner' },
        { text: 'Отменены', value: 'canceled' }
      ],
      typeModal: false,
      productModal: false,
      isSuccessfullyLaunched: false,
      lotLengths: [],
      showTradesModal: false,
      modalContent: 'Содержимое модального окна',
      isQiutModalOpen: false,
      idTradeTable: '',
      dateTradeTimer: 0,
      dateTradeMs: 0,
      objTable: {},
      objLot: {},
      count: 450,
      percentErrorPlus: false,
      percentErrorMinus: false,
      showLoader: false,
      currentPage: 0,
      pageSize: 10,
      allTrades: [],
      tableKey: 0,
      name: 'all',
      refreshKey: 0
    }

    this.handleClickBtn = this.handleClickBtn.bind(this)
    this.doCreateTrade = this.doCreateTrade.bind(this)
    this.handleProductModal = this.handleProductModal.bind(this)
    this.closeInsidePreLotModal = this.closeInsidePreLotModal.bind(this)
    this.getProfiles = this.getProfiles.bind(this)
    this.handleAllChecked = this.handleAllChecked.bind(this)
    this.doChoice = this.doChoice.bind(this)
    this.onRadioChange = this.onRadioChange.bind(this)
    this.loadTradeAll = this.loadTradeAll.bind(this)
    this.onGetChosenIdies = this.onGetChosenIdies.bind(this)
    this.doChosenIdOnlyOne = this.doChosenIdOnlyOne.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
    this.handleLotLaunch = this.handleLotLaunch.bind(this)
    this.handleTypeModal = this.handleTypeModal.bind(this)
    this.handleLotModal = this.handleLotModal.bind(this)
    this.showSuccesfullyLaunchedMsg = this.showSuccesfullyLaunchedMsg.bind(this)
    this.rerenderLotsTable = this.rerenderLotsTable.bind(this)
    this.setLotLength = this.setLotLength.bind(this)
    this.updateTable = this.updateTable.bind(this)
    this.toggleTradeModal = this.toggleTradeModal.bind(this)
    this.assignTradeButton = this.assignTradeButton.bind(this)
    this.getTradeModalsData = this.getTradeModalsData.bind(this)
    this.doReRender = this.doReRender.bind(this)
    this.doStopTrade = this.doStopTrade.bind(this)
    this.setPercentErrorPlus = this.setPercentErrorPlus.bind(this)
    this.setPercentErrorMinus = this.setPercentErrorMinus.bind(this)
    this.getPagedData = this.getPagedData.bind(this)
    this.goToNextPage = this.goToNextPage.bind(this)
    this.goToPreviousPage = this.goToPreviousPage.bind(this)
    this.rerenderOnBtnClick = this.rerenderOnBtnClick.bind(this)
    this.filterNotStartedTrades = this.filterNotStartedTrades.bind(this)
    this.getCategories = this.getCategories.bind(this)
    this.setLengthToAllCategories = this.setLengthToAllCategories.bind(this)
    this.incrementTableKey = this.incrementTableKey.bind(this)
    this.setName = this.setName.bind(this)

    this.refreshTable = this.refreshTable.bind(this)
    this.matchingActiveTrade = this.matchingActiveTrade.bind(this)
  }

  setName(name) {
    this.setState({
      name: name
    })
  }

  incrementTableKey = () => {
    this.setState((prevState) => ({ tableKey: prevState.tableKey + 1 }))
  }

  filterNotStartedTrades(trades) {
    const currentDate = new Date()

    return trades.filter((trade) => {
      if (trade.dateTrade === '') {
        return false
      }

      const tradeDate = new Date(trade.dateTrade)
      return tradeDate > currentDate
    })
  }

  /**
   * Перерендер по нажатию кнопки - Стать первым
   * @param {*} lot Объект лота
   */
  rerenderOnBtnClick(lot) {
    this.doReRender(lot)
  }

  getPagedData() {
    const { dataTable, currentPage, pageSize } = this.state
    // console.log(dataTable);

    const processedDataTable = dataTable ? processTabledata(dataTable) : []
    // console.log('copy', dataTable);

    // Копируем и переворачиваем массив
    const reversedData = processedDataTable?.slice().reverse()

    // Вырезаем нужный для текущей страницы кусок массива
    const start = currentPage * pageSize
    const end = start + pageSize

    // this.processTabledata()
    // Снова переворачиваем массив для корректного отображения на странице
    return reversedData?.slice(start, end).reverse()
  }

  goToNextPage = () => {
    this.setState(
      (prevState) => {
        const maxPages = Math.ceil(this.state.dataTable.length / this.state.pageSize) - 1
        if (prevState.currentPage < maxPages) {
          return { currentPage: prevState.currentPage + 1, showTable: false }
        }
        return null // Если текущая страница последняя, не делаем обновления состояния
      },
      () => this.setState({ showTable: true })
    )
  }

  goToPreviousPage = () => {
    this.setState(
      (prevState) => {
        if (prevState.currentPage > 0) {
          return { currentPage: prevState.currentPage - 1, showTable: false }
        }
        return null // Если текущая страница первая, не делаем обновления состояния
      },
      () => this.setState({ showTable: true })
    )
  }

  setPercentErrorMinus(isLess) {
    this.setState({
      percentErrorMinus: isLess
    })
  }

  setPercentErrorPlus(isMore) {
    this.setState({
      percentErrorPlus: isMore
    })
  }

  // todo: Процедура остановки торгов по окончании времени торгов
  doStopTrade(lot) {
    // todo: подключить endpoint "окончание торгов"
    ; (async () => {
      try {
        console.log(`STOP TRADE`) // test
        // * процедура переведена в бэкенд - checkOldTrade.js
        // const resultEntTrade = await Server.inviteAlertEmployeesEndTrade(lot.id, lot.participant)
        // console.log(`RESULT end trade: `, resultEntTrade); // test
      } catch (err) {
        console.log(`Ошибка при запуске процедуры окончания торгов: `, err)
      }
    })()
  }

  toggleTradeModal() {
    // console.log(`Выход без сохранения`); // test
    this.setState({ isQiutModalOpen: false, showTradesModal: false })
  }

  doReRender() {
    ; (async () => {
      try {
        await this.getTradeModalsData(this.state.idTradeTable, this.state.objLot)
        setTimeout(async () => {
          await this.getTradeModalsData(this.state.idTradeTable, this.state.objLot)
        })
      } catch (err) {
        console.log(`Ошибка перерендеринга: `, err)
      }
    })()
  }

  /**
   * формирует контент для модалки
   * @param {*} id
   * @param {*} lot
   */
  async getTradeModalsData(id, lot) {
    // console.log(`ID LOT::: `, id); // test
    // console.log(`Выход с сохранением`); // test

    try {
      window.scrollTo(0, 0)
      let { objTable, objLot, objChange } = await loadTradeTable(id)

      // console.log(`look DATA objTable: `, objTable); // test
      // console.log(`look DATA objLot: `, objLot); // test
      // console.log(`look DATA objChange: `, objChange); // test

      // *** заполнение objChange при отсутствии данных в нем
      if (!objChange && objChange === false) {
        objChange = JSON.parse(JSON.stringify(objTable)) // клонируем объект
      }

      let dateTradeTimer
      let dateTradeMs

      if (objLot) {
        await this.setState({
          idTradeTable: id,
          objTable,
          objLot: objLot.data
        })

        const lot = objLot.data
        const dateT = Date.parse(lot.dateTrade)
        // todo: перевести lot.timeTrade к локальному времени клиента
        const localTime = changeTimeToLocal(lot.timeTrade, this.state.timezone)
        // console.log(`TIME result MSK ---> LOCAL::: `, localTime) // test
        const arrDate = localTime.split(':')
        // const arrDate = lot.timeTrade.split(':') // todo: отдать время переведенное из МСК в локальное
        const dateHourMs = Number(arrDate[0]) * 60 * 60 * 1000
        const dateMinutMs = Number(arrDate[1]) * 60 * 1000
        dateTradeTimer = Number(lot.timer) * 60 * 1000 // время торгов мс
        dateTradeMs = dateT + dateHourMs + dateMinutMs // время начала торгов мс
      }

      if (!objLot) {
        await this.setState({
          idTradeTable: id,
          objTable,
          objLot: lot
        })
      }

      // console.log(`type trade:::: `, objLot?.data?.typeTrade) // test

      if (objLot?.data?.typeTrade === 'reduction') {
        this.setState({
          dateTradeTimer: dateTradeTimer,
          dateTradeMs: dateTradeMs,
          modalContent: (
            <RenderModalBodyReduction
              obj={objTable} // объект Confirmation
              lotFull={objLot} // объект ЛОТа
              objChange={objChange} // новый объект участника с изменениями
              member={true} // disable input в ячейках (true / false)
              keyBlock={false} // включает отображение (всех или одного) (false / true)
              dataStart={true} // * отображение занчений при входе участника у других участников (true по умолчанию)
              count={this.state.count} // смещение по оси Х
              setPercentErrorPlus={this.setPercentErrorPlus}
              setPercentErrorMinus={this.setPercentErrorMinus}
              rerenderOnBtnClick={this.rerenderOnBtnClick}
            />
          ),
          showTradesModal: !this.state.showTradesModal
        })
      } else {
        this.setState({
          dateTradeTimer: dateTradeTimer,
          dateTradeMs: dateTradeMs,
          modalContent: (
            <RenderModalBody
              obj={objTable} // объект Confirmation
              lotFull={objLot} // объект ЛОТа
              objChange={objChange} // новый объект участника с изменениями
              member={true} // disable input в ячейках (true / false)
              keyBlock={false} // включает отображение (всех или одного) (false / true)
              dataStart={true} // * отображение занчений при входе участника у других участников (true по умолчанию)
              count={this.state.count} // смещение по оси Х
              setPercentErrorPlus={this.setPercentErrorPlus}
              setPercentErrorMinus={this.setPercentErrorMinus}
            />
          ),
          showTradesModal: !this.state.showTradesModal
        })
      }

      this.setState({ isQiutModalOpen: false })
      // console.log(`TOGGLE in TradeCustomer`); // test
    } catch (err) {
      console.log(err)
    }
  }

  rerenderLotsTable() {
    setTimeout(() => {
      this.setState({
        showTable: false
      })
    }, 500)

    setTimeout(() => {
      this.setState({
        showTable: true
      })
    }, 700)
  }

  showSuccesfullyLaunchedMsg() {
    this.setState({
      isSuccessfullyLaunched: true
    })
    setTimeout(() => {
      this.setState({
        isSuccessfullyLaunched: false
      })
    }, 2000)
  }

  handleLotModal() {
    this.setState({
      showLot: !this.state.showLot
    })
  }

  handleTypeModal() {
    this.setState({
      typeModal: !this.state.typeModal
    })
  }

  handleProductModal() {
    this.setState({
      productModal: !this.state.productModal,
      typeModal: false
    })
  }

  async handleSelect(e) {
    await this.setState({
      selectValue: e.target.value
    })
    this.handleClickBtn(this.state.selectValue)
  }

  handleChange(e) {
    // console.log(`HANDLECHANGE >>>> `, e.target.dataset.description, e.target.dataset.index, e.target.checked); // for checked
    // console.log(`RADIO BUTTON >>>> `, e.value);
    // console.log(`TARGET >>>> `, e);
  }

  //обрабатываем получение ID выбранной позиции в табилце
  doChosenIdOnlyOne(e) {
    ; (async () => {
      try {
        await this.setState({
          showLot: false,
          // showTable: false,
          // showTrade: '',
          choisenIdTrade: e
        })
        // console.log('E >>>> ', e);
        await this.setState({
          showLot: true
        }) // showLot - запускает компонент
        window.scrollTo(0, 0)
      } catch (err) {
        console.log(`Ошибка при загрузке ранее сохраненного лота: `, err)
      }
    })()
  }

  //массив ID //! необходимо подтверждать удаление
  onGetChosenIdies(e, item) {
    ; (async () => {
      try {
        // console.log(`RESULT:::::::::: `, e); // test
        // console.log(`RESULT>>>>>>>  `, item.id); // test

        //todo загрузить выбранный объект
        const result = await Server.getDataFromServer(item.id, 'trade')

        //todo добавить переменную (dateArchive: true)
        Object.assign(result.data, { dateArchive: true })

        //todo сохранить объект
        const responseServer = await Server.sendDataOnServer(result, item.id, 'trade')
        console.log(`responseServer:: `, responseServer) // test

        // if (this.state.nameTable === 'Черновики') this.loadDraftTrade()
        // if (this.state.nameTable === 'Все Прошедшие') this.loadOldTrade()
      } catch (err) {
        if (Server.errorRequest()) {
          console.log(`ERROR AUTH -> TradeCustomer`);
          this.setState({ show_errorRequest: true })
          setTimeout(() => { this.setState({ show_errorRequest: false }) }, 3000)
        }

        console.log(`Ошибка при перемещении ЛОТа в архив: `, err)
      }
    })()
  }

  onRadioChange(e) {
    this.setState({
      radioResult: e.value
    })
  }

  doChoice(data) {
    ; (async () => {
      await this.setState({ dataChoiceProductions: data })
    })()
  }

  handleAllChecked() {
    this.setState({
      selectCheck: !this.state.selectCheck,
      showChecked: false
    })
    setTimeout(() => {
      this.setState({
        showChecked: true
      })
    })
  }

  async getProfiles() {
    try {
      let response = await Server.searchProduction()
      this.setState({
        profiles: response
      })

    } catch (err) {
      if (Server.errorRequest()) {
        console.log(`ERROR AUTH -> TradeCustomer`);
        this.setState({ show_errorRequest: true })
        setTimeout(() => { this.setState({ show_errorRequest: false }) }, 3000)
      }

      console.log(`ERROR: `, err);
    }

  }

  // передает в BLOCK данные для infoBlock
  doCreateTrade(data) {
    this.props.onCreateTrade(data)
  }

  getCategories = (val) => {
    const allTrades = this.state.allTrades
    const arr = this.state.selectOptionsArray.slice()
    arr.forEach((category, idx) => {
      if (category.value === val) {
        // this.setLotLength(allTrades?.[val], idx)
        this.assignTradeButton(allTrades?.[val])
        // console.log(newArray);
        this.setState({
          dataTable: allTrades?.[val]
        })
      }
    })
    console.log(this.state.dataTable)
  }

  setLengthToAllCategories() {
    const allTrades = this.state.allTrades
    for (let i = 0; i < this.state.selectOptionsArray.length; i++) {
      this.setLotLength(allTrades?.[this.state.selectOptionsArray[i].value], i)
    }
  }

  async handleClickBtn(val) {
    // console.log(`VAL:::: `, val) // test
    this.getCategories(val)
    this.setName(val)
  }

  handleLotLaunch(e) {
    ; (async () => {
      try {
        if (e.target.dataset.index === 'trade') {
          // await this.togglePreLotModal();
        } else if (e.target.dataset.index === 'trade_run') {
          await this.setState({
            choisenIdTrade: '',
            showLot: true
          })
          // await this.togglePreLotModal();
          await this.handleProductModal()
        }
        // else {
        //     await this.setState({ showTrade: e.target.dataset.index })
        // }
        // await this.setState({ showTrade: e.target.dataset.index })

        Server.deleteDocument('product')
        Server.deleteDocument('companiesArrayFromServer')
        Server.deleteDocument('updatedChosenCompanies')
      } catch (err) {
        if (Server.errorRequest()) {
          console.log(`ERROR AUTH -> TradeCustomer`);
          this.setState({ show_errorRequest: true })
          setTimeout(() => { this.setState({ show_errorRequest: false }) }, 3000)
        }

        console.log(`Ошибка в выборе торгов: `, err)
      }
    })()
  }

  // todo: проверка активных торгов
  /**
   * проверяет активность торгов
   * @param {Array} allTrade массив объектов всех торгов данной компании
   * @param {Array} activeTrade массив ID активных торгов (после ПУСКа)
   * @returns {Array} массив объекто ЛОТов с добавленным полем: active: true / false (true - активные торги false - не активные торги)
   */
  async matchingActiveTrade(allTrade, arrActive) {
    let resBool
    const result = allTrade?.map((elem) => {
      resBool = false
      arrActive.forEach((active) => {
        if (elem.id === active) {
          // console.log('active', elem);
          resBool = true
        }
      })
      return Object.assign(elem, { active: resBool })
    })
    return result
  }

  //todo: парсим данные для таблицы
  /**
   * парсим данные для таблицы
   * @param {Array} data Массив объектов для рендера
   * @param {number} lengthData длина массива для рендера
   * @param {string} nameTable  заглавие таблицы
   * @returns {void}
   */
  // parseDataTable(data, lengthData, nameTable) {
  //   const arr = data.map((data, index) => {
  //     return Object.assign(data, { positionNumber: lengthData - index })
  //   })

  //   this.setState({
  //     dataTable: arr,
  //     showView: false,
  //     nameTable: nameTable,
  //     removeble: false
  //   })
  //   this.setState({ showTable: true })
  // }

  assignTradeButton(array) {
    const newArr = []
    array?.forEach((el, index) => {
      newArr.push(
        Object.assign(el, {
          tradeBtn: (
            <GoToTradeButton
              handleSaveClick={async () => {
                await this.getTradeModalsData(array[index].id, array[index])
              }}
              needDisable={false}
            />
          )
        })
      )
    })
    return newArr
  }

  setLotLength(array, idx) {
    const arr = this.state.lotLengths
    arr[idx] = array?.length
    this.setState({
      lotLengths: arr
    })
  }

  async loadTradeAll() {
    try {
      await this.setState({ showTable: false })
      const result = await Server.searchAllTrade()
      const newResult = result.map((el) => el.data).map((el) => el.data)

      const resultActiveTrade = await Server.searchUniversal('global', '_id', 'current_trade') // загружаем массив  ID активных торгов
      const arrActive = resultActiveTrade[0].past
      const tradeWithActiveField = await this.matchingActiveTrade(newResult, arrActive)

      // console.log( arr);

      // Await the categorizeTrades function
      const trades = await categorizeTrades(tradeWithActiveField)

      console.log(trades)

      // console.log(result);

      if (result.ERROR) throw new Error(result.message)

      if (result.length) {
        this.setState({
          allTrades: trades
        })
        return trades
      }
    } catch (err) {
      if (Server.errorRequest()) {
        console.log(`ERROR AUTH -> TradeCustomer`);
        this.setState({ show_errorRequest: true })
        setTimeout(() => { this.setState({ show_errorRequest: false }) }, 3000)
      }

      console.log(`Ошибка в получении всех торгов данной компании: `, err)
    }
  }

  closeInsidePreLotModal() {
    this.setState({
      productModal: false,
      typeModal: true
    })
  }

  async updateTable() {
    this.setState({
      showTable: false,
      showLoader: true
    })

    this.loadTradeAll()

    setTimeout(() => {
      this.setState({
        showTable: true,
        showLoader: false
      })
    }, 1500)
  }

  refreshTable() {
    this.setState(
      (prevState) => ({
        refreshKey: prevState.refreshKey + 1
      }),
      async () => {
        await this.loadTradeAll()
      }
    )
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.showLot !== prevProps.showLot) {
      this.setState({ showLot: !this.state.showLot })
    }

    if (this.state.dataTable?.length !== prevState.dataTable?.length) {
      // console.log('this.state', this.state.dataTable?.length );
      // console.log('prevState', prevState.dataTable?.length);
      this.updateTable()
      this.incrementTableKey()
    }

    if (this.state.allTrades?.length !== prevState.allTrades?.length) {
      // console.log('fire');
      this.updateTable()
      this.incrementTableKey()
    }

    if (this.state.name !== prevState.name) {
      this.updateTable()
      this.incrementTableKey()
    }
  }

  async componentDidMount() {
    try {
      await this.getProfiles()
      const timezone = await Server.getDataFromServer('timezone', localStorage.getItem('idUser'))
      this.setState({ timezone: timezone.timezone })

      //подгружаем все торги для дальнейшего фильтра по категориям
      await this.loadTradeAll()

      //устанавливаем категорию "все торги" по умолчанию
      this.getCategories('all')

      //устанавливаем кол-во торгов в каждой из категорий
      this.setLengthToAllCategories()

      //проверяем отикуда пришел вид и тип лота (с поиска или с кнопки "создать лот")
      if (
        this.props.radioResultFromProductionSearch &&
        this.props.radioResultFromProductionSearch.length !== 0
      ) {
        this.setState({
          radioResult: this.props.radioResultFromProductionSearch
        })
      }

      if (
        this.props.productFromProductionSearch &&
        this.props.productFromProductionSearch.length !== 0
      ) {
        this.setState({
          dataChoiceProductions: [this.props.productFromProductionSearch]
        })
      }
    } catch (err) {
      if (Server.errorRequest()) {
        console.log(`ERROR AUTH -> TradeCustomer`);
        this.setState({ show_errorRequest: true })
        setTimeout(() => { this.setState({ show_errorRequest: false }) }, 3000)
      }
      console.log(`ERROR: `, err);
    }

  }

  render() {
    const participant = this.state.objLot && this.state.objLot.participant
    const offset = this.state.currentPage * this.state.pageSize

    // console.log(this.state.name);

    return (
      <>
        {this.state.show_errorRequest ?
          <ModalError />
          : ''
        }
        <div
          // className="wrapper"
          className='wrapper_lot'
        >
          {this.state.showTradesModal && (
            <ModalWindowTrade
              asParticipant={false}
              toggleModal={() => this.setState({ isQiutModalOpen: !this.state.isQiutModalOpen })}
              alertHeading='Сводная таблица данных участников торгов'
              modalContent={this.state.modalContent}
              reRender={this.doReRender}
              // reRender={this.doReRenderTest}
              buttonOn={true}
              dateTradeTimer={this.state.dateTradeTimer}
              dateTradeMs={this.state.dateTradeMs}
              lot={this.state.objLot && this.state.objLot}
              forName={'all'}
              idCompany={participant}
              filterAll={participant}
              doStopTrade={this.doStopTrade}
              isOpen={this.state.showTradesModal}
            />
          )}

          {this.state.isQiutModalOpen && (
            <QuitModalUniversal
              onQuitWithSave={this.getTradeModalsData}
              // onQuitWithoutSave={this.toggleTradeModal}
              onQuitWithoutSave={() =>
                this.setState({ isQiutModalOpen: !this.state.isQiutModalOpen })
              }
              closeQuitModal={() => this.setState({ isQiutModalOpen: !this.state.isQiutModalOpen })}
              modalTitle='Выход'
              modalBody='Вы действительно хотите выйти из формы?'
              primaryButton='Выйти'
              secondaryButton='Не выходить'
            />
          )}

          <Container
            fluid
            // className="content"
            className='wrapper_lot_content'
          >
            {/* <Row> &nbsp; </Row> */}
            {/* 
          
          
          */}
            {!this.state.isSuccessfullyLaunched ? (
              ''
            ) : (
              <Card className='text-center'>
                <Card.Header>Площадка</Card.Header>
                <Card.Body className='lot_succesfully_saved_msg'>
                  <Card.Title>Площадка торгов</Card.Title>
                  <Card.Text>
                    <div>Лот успешно сохранен!</div>
                    <Row> &nbsp; </Row>
                    <ThumbUpAltOutlinedIcon />
                  </Card.Text>
                </Card.Body>
                {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
              </Card>
            )}

            <Row> &nbsp; </Row>
            <Card className='text-center box_shadow' style={{ backgroundColor: '#fff' }}>
              <Card.Header>
                <Card.Title style={{ display: 'flex' }}>
                  <div style={{ width: '100%' }}>Ваши торги</div>
                </Card.Title>

                <Form.Group style={{ display: 'flex', justifyContent: 'center' }}>
                  <Row style={{ width: '100%' }}>
                    <Form.Control onClick={this.handleSelect} as='select'>
                      {/* <option value='' disabled selected hidden></option> */}
                      {this.state.selectOptionsArray.map((el, i) => (
                        <option key={el.value} value={el.value}>
                          {el.text}
                          {this.state.lotLengths[i] || this.state.lotLengths[i] === 0
                            ? ` (${this.state.lotLengths[i]})`
                            : `(0)`}
                          {/* {`(${this.state.allTrades?.[el.value].length ? this.state.allTrades?.[el.value].length : 0})`} */}
                        </option>
                      ))}
                    </Form.Control>
                  </Row>
                </Form.Group>
              </Card.Header>
              <Card.Body>
                <Card.Text></Card.Text>
                {this.state.showLoader ? (
                  <div className='loader_centered'>
                    <Spinner animation='border' variant='secondary' />
                  </div>
                ) : (
                  <>
                    {this.state.showTable && (
                      <UniversalTable
                        key={`${this.state.dataTable.length}_${this.state.tableKey}`}
                        offset={offset}
                        removable={this.state.removeble}
                        selectable={this.state.showView}
                        selectable1={false}
                        selectable3={false}
                        buttomName='Далее'
                        tableName={this.state.nameTable}
                        titleObjectData={{
                          numbered: 'п/п',
                          numberLot: 'Номер',
                          nameLot: 'Название',
                          tradeStatus: 'Статус торгов',
                          startDate: 'Дата проведения торгов',
                          startTime: 'Время начала торгов (МСК)',
                          finishTime: 'Время окончания торгов (МСК)',
                          price: `Начальная максимальная цена, ${CURRENCY.rub[0]}`,
                          tradeBtn: this.state.dataTable?.some((el) => el.tradeBtn)
                            ? 'Сводная таблица лота'
                            : ''
                        }}
                        renderedArray={this.getPagedData()}
                        renderedArrayWithAdded={() => { }}
                        chosenObjectsArray={() => { }} //только выбранные объекты
                        chosenIdsArray={this.onGetChosenIdies} //массив инн
                        chosenObjectId={() => { }}
                        handleModalToggle={() => { }}
                        isRowGreen={true}
                        viewID={true}
                        onChosenIdOnlyOne={this.doChosenIdOnlyOne}
                        editLot={this.doChosenIdOnlyOne}
                        showStatus={true}
                        showTradeButtons={true}
                      />
                    )}
                  </>
                )}
              </Card.Body>
              {/* <Card.Footer className="text-muted">2 days ago</Card.Footer> */}
            </Card>

            {this.state.typeModal && (
              <>
                <div className='shadow z_index_extra_big'></div>
                <div className='z_index_extra_big lot_type_modal'>
                  <Alert
                    style={{ backgroundColor: '#f8f9fa' }}
                    dismissible
                    onClick={this.handleTypeModal}
                  >
                    <h5>Основная информация о лоте</h5>
                  </Alert>
                  <Modal.Body>
                    <b> Выберите тип лота:</b>

                    <RadioButtonThree
                      id={1}
                      width={12}
                      show={true}
                      title=''
                      label1='Опрос рынка'
                      label2='Закуп'
                      label3='Аукцион'
                      placeholder='Категории лота'
                      description='Категории лота'
                      value={this.state.radioResult}
                      onChangeValue={this.onRadioChange}
                      onChangeVisionBlock={() => { }}
                    />
                  </Modal.Body>
                  <Modal.Footer style={{ backgroundColor: '#f8f9fa' }}>
                    <BasicButton
                      handleSaveClick={this.handleProductModal}
                      needDisable={true}
                      disableCondition={!this.state.radioResult}
                      saveButtonText='Далее'
                      icon={<ChevronRightIcon style={{ marginBottom: '3px' }} />}
                    />
                  </Modal.Footer>
                </div>
              </>
            )}

            {this.state.productModal && (
              <>
                <div className='shadow z_index_extra_big'></div>
                <div className='z_index_extra_big lot_type_modal' style={{ width: '600px' }}>
                  <Alert
                    style={{ backgroundColor: '#f8f9fa' }}
                    dismissible
                    onClick={this.handleProductModal}
                  >
                    <h5>Категория лота</h5>
                  </Alert>
                  <Modal.Body>
                    <>
                      <BackBtn handleSwitch={this.closeInsidePreLotModal} additionalClass='mb_20' />

                      <FilteredCompanies onChoice={this.doChoice} showConfirmBtn={false} />
                    </>
                  </Modal.Body>
                  <Modal.Footer style={{ backgroundColor: '#f8f9fa' }}>
                    <CreateButton
                      handleSaveClick={this.handleLotLaunch}
                      needDisable={true}
                      disableCondition={this.state.dataChoiceProductions.length > 0 ? false : true}
                      variant='primary'
                      buttonText=' Перейти к созданию лота'
                      dataIdx='trade_run'
                    />
                  </Modal.Footer>
                </div>
              </>
            )}

            {this.state.showLot && (
              <>
                {
                  <CreateLot
                    dataChoiceProductions={this.state.dataChoiceProductions}
                    radioResult={this.state.radioResult}
                    id={this.state.choisenIdTrade} // ? Проверить, перед созданием лота сбрасывать *************************
                    chosenCompaniesFromProductionSearch={
                      this.props.chosenCompaniesFromProductionSearch
                    }
                    economicData={this.props.economicData}
                    infoBlock={this.props.infoBlock}
                    onUpInfoBlock={this.props.onUpInfoBlock}
                    viewQuest={this.props.viewQuest}
                    getEconomicData={this.props.getEconomicData}
                    doSendDataChoice={this.props.doSendDataChoice}
                    allCompaniesFromSearch={this.props.allCompaniesFromSearch}
                    getAllCompaniesFromSearch={this.props.getAllCompaniesFromSearch}
                    nameTable={this.state.nameTable}
                    handleLotModal={this.handleLotModal}
                    showSuccesfullyLaunchedMsg={this.showSuccesfullyLaunchedMsg}
                    toggleLotModal={this.props.toggleLotModal}
                    rerenderLotsTable={this.rerenderLotsTable}
                    updateTable={this.updateTable}
                  />
                }
              </>
            )}
            <div
              style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '10px' }}
            >
              <LeftAndRightArrowsButtons
                nextPageClick={this.goToNextPage}
                prevPageClick={this.goToPreviousPage}
                needDisableButtons={true}
                disableNextButtonCondition={
                  this.state.currentPage >=
                  Math.ceil(this.state.dataTable?.length / this.state.pageSize) - 1
                }
                disablePrevButtonCondition={this.state.currentPage === 0}
              />

              <CreateButton
                btnWidth='200px'
                handleSaveClick={this.handleTypeModal}
                needDisable={false}
                variant='primary'
                buttonText='Создать лот'
                dataIdx='trade'
                iconWithPlus={true}
              />
            </div>
          </Container>
          {/* <Footer /> */}
        </div>
      </>
    )
  }
}
