import React from "react";
import { Container } from "react-bootstrap";
// import { AllFiltersModal } from "./AllFiltersModal";
// import Window from "../assets/Window";
// import { PaymentValidationSection } from "../assets/PaymentValidationSection";

export class SearchByMatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      test: false,
      view: false,
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  openModal() {
    this.setState({
      showModal: true,
    });
  }

  closeModal() {
    this.setState({
      showModal: false,
    });
  }

  toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  render() {
    return (
      <Container fluid style={{ marginTop: "50px", marginBottom: "80px" }}>
        В разработке

        {/* <Button style={{ margin: "20px" }} onClick={this.openModal}>
          Click to open modal
        </Button>

        <Button style={{ margin: "20px" }} onClick={this.openModal}>
          Click to open modal
        </Button>
        {this.state.showModal && (
          <Window 
          view={this.state.showModal} 
          content={"ffoffoofof"} 
          handleClickToggle={this.toggleModal}
          />
        )} */}

        {/* <PaymentValidationSection /> */}

      </Container>
    );
  }
}
