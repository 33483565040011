/* eslint-disable react/jsx-pascal-case */
import React from 'react'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined'
import { Alert, Button, Container, Spinner } from 'react-bootstrap'
import Form_Main from './Form_Main'
import Form_One from './Form_One'
import Form_Two from './Form_Two'
import Form_Three from './Form_Three'
import Form_Four from './Form_Four'
import Form_Five from './Form_Five'
import Form_Six from './Form_Six'
import Form_Seven from './Form_Seven'
import Form_Eight from './Form_Eight'
import Form_Nine from './Form_Nine'
import Form_Ten from './Form_Ten'
import Form_Eleven from './Form_Eleven'
import Form_Twelve from './Form_Twelve'
import Form_Thirteen from './Form_Thirteen'
import Form_Fourteen from './Form_Fourteen'
import Form_Fifteen from './Form_Fifteen'
import Form_Zero from './Form_Zero'
import Form_Sixteen from './Form_Sixteen'
import Form_Seventeen from './Form_Seventeen'
import QuestionnaireButton from './QuestionnaireButton'
import ModalSave from '../ModalSave'
import ModalLoad from '../ModalLoad'
import FormBendPipe from './FormBendPipe'
import FormBottoms from './FormBottoms'
import FormLensCompensators from './FormLensCompensators'
import FormFlanges from './FormFlanges'
import FormHeatTreatment from './FormHeatTreatment'

import Server from '../server/server'
import QuestionnaireProduction from './questionnaireProduction'
import ProductionAccordion from './ProductionAccordion'
import FormForgings from './FormForgings'
import FormBends from './FormBends'
import FormHardware from './FormHardware'
import FormRolling from './FormRolling'
import FormProductColoration from './FormProductColoration'
import FormBendingCuttingMetal from './FormBendingCuttingMetal'
import FormWelding from './FormWelding'
import FormRolled from './FormRolled'
import FormPipesSupply from './FormPipesSupply'
import FormSheetMetalSupply from './FormSheetMetalSupply'

import { EconomicData } from '../Economic/EconomicData'
import ModalInformation from '../ModalInformation'
import { SaveCurrentDataButton } from '../assets/BasicButtons'
import LoaderPage from '../assets/loaderPage'
import ModalError from '../ModalError'

export default class Questionnaire extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      economicDataArray: [],
      accessType: '',
      selectedProduction: [], //массив выбранной предприятием продукции
      companyProduction: [], //массив всей продукции
      innForSearch: '', //инн той компании, информацию о которой хочет просмотреть пользователь

      showFillFormMain: false,
      showFillFormProduction: false,
      isMainFilled: false,
      isProductionFilled: false,
      sendOnModeration: false,
      sentOnModeration: false,
      language: 'rus',
      show: false,
      showSave: false,
      show_errorRequest: false,
      // _isMounted: false,
      pointPosition: [],
      lengthDataFromServer_: [], // new
      //! следующие переменные идут в строгом соответствии с кол-вом форм и позицией имен форм в массиве
      data_: [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        []
      ],
      view_: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      viewForm: [
        'Main',
        'One',
        'Two',
        'Three',
        'Four',
        'Five',
        'Six',
        'Seven',
        'Eight',
        'Nine',
        'Ten',
        'Eleven',
        'Twelve',
        'Thirteen',
        'Fourteen',
        'Fifteen',
        'Sixteen',
        'Seventeen',
        'Zero',
        'Production',
        'FormBendPipe',
        'FormBottoms',
        'FormLensCompensators',
        'FormFlanges',
        'FormForgings',
        'FormBends',
        'FormHardware',
        'FormRolling',
        'FormProductColoration',
        'FormBendingCuttingMetal',
        'FormHeatTreatment',
        'FormWelding',
        'FormRolled',
        'FormPipesSupply',
        'FormSheetMetalSupply',
        'EconomicData'
      ],
      infoBlock: {
        language: 'rus',
        classQuestBlock: [
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form',
          'btn_form'
        ],
        nameQuestBlock: [
          'Main',
          'One',
          'Two',
          'Three',
          'Four',
          'Five',
          'Six',
          'Seven',
          'Eight',
          'Nine',
          'Ten',
          'Eleven',
          'Twelve',
          'Thirteen',
          'Fourteen',
          'Fifteen',
          'Sixteen',
          'Seventeen',
          'Zero',
          'Production',
          'FormBendPipe',
          'FormBottoms',
          'FormLensCompensators',
          'FormFlanges',
          'FormForgings',
          'FormBends',
          'FormHardware',
          'FormRolling',
          'FormProductColoration',
          'FormBendingCuttingMetal',
          'FormHeatTreatment',
          'FormWelding',
          'FormRolled',
          'FormPipesSupply',
          'FormSheetMetalSupply',
          'EconomicData'
        ]
      },
      showLook: false,
      choice: ['main'], // выбор вывода кнопок в форму, по производству
      showBtn: true, // выкл/вкл блок кнопок (перегрузка),
      dataAccordion: [],
      showAccordion: false,
      nameFormMaterials: '',
      dataForm_One: 'one', //test
      percent: null,
      formNameForAccordion: '',
      inReadMode:
        this.props.innFromUniversalTable === null ||
        this.props.innFromUniversalTable === '' ||
        this.props.innFromUniversalTable === undefined,
      companyName: '',
      fillEconomic: 0,
      savedDataMessage: false,
      text: 'Необходимо в разделе, Данные компании, заполнить анкету и отправить на модерацию!',
      key: 'zero',
      minFillingProcentMain: 8,
      minFillingProcentProduction: 6,
      loading: true
    }

    /**
     *   Памятка, позиций форм в массиве view_, viewForm, data_, lengthDataFromServer_ :
     * Main = 0
     * One = 1
     * Two = 2
     * Three = 3
     * Four = 4
     * Five = 5
     * Six = 6
     * Seven = 7
     * Eight = 8
     * Nine = 9
     * Ten = 10
     * Eleven = 11
     * Twelve = 12
     * Thirteen = 13
     * Fourteen = 14
     * Fifteen = 15
     * Sixteen = 16
     * Seventeen = 17
     * Zero = 18
     * Production = 19
     * FormBendPipe = 20
     * FormBottoms = 21
     * FormLensCompensators = 22
     * FormFlanges = 23
     * FormForgings = 24
     * FormBends = 25
     * FormHardware = 26
     * FormRolling = 27
     * FormProductColoration = 28
     * FormBendingCuttingMrtal = 29
     * FormHeatTreatment = 30
     * FormWelding = 31
     * FormRolled = 32
     * FormPipesSupply = 33
     * FormSheetMetalSupply = 34
     * EconomicData = 35
     */

    this.createDataServer = this.createDataServer.bind(this)
    this.sendDataServer = this.sendDataServer.bind(this)
    this.doCreateDataServer = this.doCreateDataServer.bind(this)
    this.doUpdateDataOnServer = this.doUpdateDataOnServer.bind(this)
    this.writeParseData = this.writeParseData.bind(this)
    this.handleClickView = this.handleClickView.bind(this)
    this.doChangeView = this.doChangeView.bind(this)
    this.changeColorButton = this.changeColorButton.bind(this)
    this.lookingSave = this.lookingSave.bind(this)
    this.lookLoad = this.lookLoad.bind(this)
    this.choiceProduction = this.choiceProduction.bind(this)
    this.getDataForAccordion = this.getDataForAccordion.bind(this)
    this.doRunMaterials = this.doRunMaterials.bind(this) //materirals run
    this.doRunAccordion = this.doRunAccordion.bind(this)
    this.getCompanyProductionArray = this.getCompanyProductionArray.bind(this)
    this.getPercentAndFormName = this.getPercentAndFormName.bind(this)
    this.refreshQuestionnairy = this.refreshQuestionnairy.bind(this)

    this.doSendEconomicFilling = this.doSendEconomicFilling.bind(this)
    this.handleClickModerationWithNotification =
      this.handleClickModerationWithNotification.bind(this)
    this.handleClickModeration = this.handleClickModeration.bind(this)
    this.getFillingData = this.getFillingData.bind(this)
    this.setFillingGuide = this.setFillingGuide.bind(this)
    this.reRenderQuestBtn = this.reRenderQuestBtn.bind(this)
  }

  reRenderQuestBtn() {
    this.setState(
      {
        showBtn: false
      },
      () => {
        this.setState({
          showBtn: true
        })
      }
    )
  }

  async setFillingGuide() {
    try {
      const procent = await this.getFillingData()
      const formMain = procent[0] && procent[0].Main
      const formProduction = procent[1] && procent[1].Production

      const { minFillingProcentMain, minFillingProcentProduction } = this.state

      this.setState({
        showFillFormMain: formMain < minFillingProcentMain,

        showFillFormProduction:
          formMain >= minFillingProcentMain && formProduction < minFillingProcentProduction,

        sendOnModeration:
          this.state.accessType === 'zero' &&
          formMain >= minFillingProcentMain &&
          formProduction >= minFillingProcentProduction
      })
    } catch (err) {
      if (Server.errorRequest()) {
        console.log(`ERROR AUTH -> CompanySearch`);
        this.setState({ show_errorRequest: true })
        setTimeout(() => { this.setState({ show_errorRequest: false }) }, 3000)
      }
      console.log(`ERROR: `, err);
    }

  }

  async getFillingData() {
    try {
      const filling = await Server.fillingVolumeForms(this.state.choice)
      return filling
    } catch (err) {
      if (Server.errorRequest()) {
        console.log(`ERROR AUTH -> CompanySearch`);
        this.setState({ show_errorRequest: true })
        setTimeout(() => { this.setState({ show_errorRequest: false }) }, 3000)
      }
      console.log(`ERROR: `, err);
    }

  }

  handleClickModeration() {
    ; (async () => {
      try {
        const data = {
          login: sessionStorage.getItem('login'),
          loginReg: localStorage.getItem('loginReg'),
          inn: localStorage.getItem('idUser'),
          text: this.state.text,
          key: this.state.key,

          sentOnModertaion: true
        }
        const resultSend = await Server.sendDataOnServer(
          data,
          localStorage.getItem('idUser'),
          'moderation'
        )

        // console.log(`resultSend:::: `, resultSend)
      } catch (err) {
        console.log(`Ошибка записи запроса на модерацию(MainContent.jsx): `, err)
      }
    })()
    console.log(`MODERATION click`) // test
  }

  async handleClickModerationWithNotification() {
    this.handleClickModeration()

    this.setState({
      savedDataMessage: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false,
        sendOnModeration: !this.state.sendOnModeration,
        sentOnModeration: true
      })
    }, 2000)
  }

  // получаем процент заполнения для EconomicData
  doSendEconomicFilling(fill) {
    this.setState({ fillEconomic: fill })
    // console.log(`FILL::::::>> `, fill); // test
  }

  getPercentAndFormName(per, name) {
    this.setState({
      percent: per,
      formNameForAccordion: name
    })
  }

  //возвращает массив только той деятельности предприятия, которая была выбрана в QuestionnaireProduction
  async getCompanyProductionArray(productionArray) {
    await this.setState({
      companyProduction: productionArray
    })

    const inf = this.state.companyProduction.map((el) => el.information)
    const val = this.state.companyProduction.map((el) => el.value)

    // console.log('information', inf[0]);
    // console.log('value', val[0]);
    const arr = []
    // change map to forEach
    inf[0].forEach((el, idx) => {
      if (val[0][idx]) {
        arr.push(el)
      }
    })

    this.setState({
      selectedProduction: arr
    })

    const inn = this.state.infoBlock.cardCompany[6].value

    this.setState({
      innForSearch: inn
    })
  }

  //*запукск формы материалов (планируется сделать дополнительной формой в ряде форм)
  async doRunMaterials(name) {
    await this.setState({ nameFormMaterials: name })

    //загрузка данных формы с сервера
    let idINN = 0

    if (localStorage.getItem('idAlien')) {
      idINN = localStorage.getItem('idAlien')
      // idINN = Number(localStorage.getItem('idAlien'))
    } else {
      idINN = localStorage.getItem('idUser')
      // idINN = Number(localStorage.getItem('idUser'))
      // console.log(`ELSE !!!!!!!!!!!!!!!`); // test
    }
    await this.getDataFromServerFour(this.state.nameFormMaterials, idINN)
    // console.log(`FORM NAME FOR MAERIALS::::::: `, this.state.nameFormMaterials);
  }

  //* форма загрузки
  lookLoad(time) {
    this.setState({ showLook: true })
    setTimeout(() => {
      this.setState({ showLook: false })
    }, time)
  }

  //*определяем деятельность предприятия и формируем анкету(массив форм)
  choiceProduction(nameForm) {
    this.setState({ choice: [...this.state.choice, nameForm] })
  }

  //* информационная иконка о сохранении
  lookingSave() {
    this.setState({ showSave: true })
    setTimeout(() => {
      this.setState({ showSave: false })
    }, 2000)
  }

  //*меняет видимость формы
  doChangeView(name) {
    // console.log('change view form - ', name)

    let showForm = this.state.view_
    this.state.viewForm.forEach((nameForm, index) => {
      if (name === nameForm) {
        showForm[index] = !showForm[index]
      }
    })
    this.setState({ view_: showForm })
  }

  //* загружает для выбранной формы данные с сервера и открывает форму
  async handleClickView(e) {
    this.lookLoad(1750)
    window.scrollTo(0, 0)

    const name = e && e.target.dataset.name // getDataFromServer

    // console.log(name);
    let idINN = 0

    if (localStorage.getItem('idAlien')) {
      idINN = localStorage.getItem('idAlien')
      // idINN = Number(localStorage.getItem('idAlien'))
    } else {
      idINN = localStorage.getItem('idUser')
      // idINN = Number(localStorage.getItem('idUser'))
      // console.log(`ELSE !!!!!!!!!!!!!!!`); // test
    }
    await this.getDataFromServer(name, idINN)
  }

  //* меняем цвет кнопки при выходе через SAVE
  changeColorButton(name) {
    console.log('here ch color')
    let position = 0

    //todo для дописывания имен блоков в infoBlock
    this.nameBlock = this.state.infoBlock
    this.nameBlock.nameQuestBlock = [
      'Main',
      'One',
      'Two',
      'Three',
      'Four',
      'Five',
      'Six',
      'Seven',
      'Eight',
      'Nine',
      'Ten',
      'Eleven',
      'Twelve',
      'Thirteen',
      'Fourteen',
      'Fifteen',
      'Sixteen',
      'Seventeen',
      'Zero',
      'Production',
      'FormBendPipe',
      'FormBottoms',
      'FormLensCompensators',
      'FormFlanges',
      'FormForgings',
      'FormBends',
      'FormHardware',
      'FormRolling',
      'FormProductColoration',
      'FormBendingCuttingMetal',
      'FormHeatTreatment',
      'FormWelding',
      'FormRolled',
      'FormPipesSupply',
      'FormSheetMetalSupply',
      'EconomicData'
    ]

    this.setState({ infoBlock: this.nameBlock })

    setTimeout(() => {
      this.state.infoBlock.nameQuestBlock.forEach((member, index) => {
        if (member === name) position = index
      })
      let arrEmpty = this.state.infoBlock.classQuestBlock
      arrEmpty[position] = 'btn_form active'
      this.setState({ classQuestBlock: arrEmpty, showBtn: false }) // перезапуск вида кнопок
      setTimeout(() => {
        if (!localStorage.getItem('idAlien')) {
          // test
          this.props.onUpInfoBlock && this.props.onUpInfoBlock(this.state.infoBlock)
        }
        this.setState({ showBtn: true }) // перезапуск вида кнопок
      })
    })
  }

  doCreateDataServer(data, name, id) {
    // todo подключен, работает
    this.createDataServer(data, name, id)
    this.changeColorButton(name)
  }

  doUpdateDataOnServer(data, name, id) {
    // todo подключен, работает
    this.sendDataServer(data, name, id)
    this.changeColorButton(name)
  }

  //*Создаем новые данные на сервере
  async createDataServer(data, name, id) {
    this.lookingSave()

    if (!localStorage.getItem('idAlien')) {
      new Promise((resolve) => {
        // resolve(Server.createDataOnServer(JSON.stringify(data), name, id))
        resolve(Server.createDataOnServer(data, name, id))
      })
        .then((result) => {
          if (result.ERROR) {
            throw new Error(result.message)
          }
          console.log('ALL OK: CREATE data on Server')
          // console.log(result); // test
        })
        .catch((err) => {
          console.log(`ERROR: NOT create data on Server: ${err}`)
        })
    }
  }

  //*Обновляем данные на сервере
  async sendDataServer(data, name, id) {
    this.lookingSave()

    if (!localStorage.getItem('idAlien')) {
      new Promise((resolve) => {
        // resolve(Server.sendDataOnServer(JSON.stringify(data), name, id))
        resolve(Server.sendDataOnServer(data, name, id))
      })
        .then((result) => {
          if (result.ERROR) {
            throw new Error(result.message)
          }
          console.log('ALL OK: UPDATE data on Server')
          console.log(result) // test
        })
        .catch((err) => {
          console.log(`ERROR, NOT update data on Server:  ${err}`)
          console.log(err)
        })
    }
  }

  //*парсинг данных с сервера
  writeParseData(dataJson, name) {
    this.setState({
      economicDataArray: dataJson
    })
    // console.log(dataJson); // test
    // console.log('PARSER > ', name);//test
    // console.log(dataJson);//test
    let data_ = this.state.data_
    let lengthDataFromServer_ = this.state.lengthDataFromServer_
    try {
      let dataNew = dataJson.map((data) => {
        return {
          id: data.id,
          fid: data.fid,
          description: data.description,
          information: data.information,
          value: data.value
        }
      })

      // распределение данных выбранной формы
      this.state.viewForm.forEach((nameForm, index) => {
        if (nameForm === name) {
          data_[index] = dataNew
          lengthDataFromServer_[index] = dataNew.length
        }
      })

      this.setState({
        data_: data_,
        lengthDataFromServer_: lengthDataFromServer_
      })
      this.doChangeView(name) // todo запускаем форму
    } catch {
      this.doChangeView(name) // todo запускаем форму
    }
  }

  //*получаем данные для аккардиона
  async getDataForAccordion() {
    let idINN = 0

    if (localStorage.getItem('idAlien')) {
      idINN = localStorage.getItem('idAlien')
      // idINN = Number(localStorage.getItem('idAlien'));
    } else {
      idINN = localStorage.getItem('idUser')
      // idINN = Number(localStorage.getItem('idUser'));
      // console.log(`ELSE !!!!!!!!!!!!!!!`); // test
    }
    new Promise((resolve) => {
      resolve(Server.getDataFromServer('data_form', idINN))
    })
      .then((result) => {
        if (result.ERROR) {
          throw new Error(result.message)
        }
        this.setState({ dataAccordion: result })
      })
      .then(() => {
        this.setState({ showAccordion: true })
      })
      .catch((err) => {
        console.log(`ERROR, data for accordion: ${err} `)
      })
    // console.log('LOAD DATA FROM SERVER');//test
  }

  //*перезапускаем аккардион
  doRunAccordion() {
    this.setState({ showAccordion: false })
    setTimeout(() => {
      this.getDataForAccordion()
    }, 1000)
  }

  //* получаем данные с сервера
  async getDataFromServer(name, id) {
    new Promise((resolve) => {
      resolve(Server.getDataFromServer(name, id))
    })
      .then((result) => {
        // console.log('RESULT data ------ ', result);//test
        this.writeParseData(result, name)
        this.setState({ lengthDataFromServer: result.length })

        if (result.ERROR) {
          throw new Error(result.message)
        }
      })
      .catch((err) => {
        this.setState({ lengthDataFromServer: 0 })
        console.log(`ERROR, not data on server: ${err} `)
      })
    // console.log('LOAD DATA FROM SERVER', name);//test
  }

  // //* получаем данные с сервера для формы Four (Материалы)
  async getDataFromServerFour(nameForm, id) {
    new Promise((resolve) => {
      resolve(Server.getDataFromServer(nameForm, id))
    })
      .then((result) => {
        this.writeParseData(result, 'Four')
        this.setState({ lengthDataFromServer: result.length })
        // console.log('DATA for FORM  -> ', nameForm); // test
      })
      .then(() => {
        let view_ = this.state.view_
        view_[4] = true
        // открываем форму с поступившими данными
        this.setState({ view_: view_ })
        // console.log('FORM NAME MATERIALS -> ', this.state.nameFormMaterials);//test
      })
      .catch((err) => {
        //сбрасываем данные, что бы не передались в другую форму
        let data_four = this.state.data_
        data_four[4] = []
        this.setState({
          lengthDataFromServer: 0,
          data_: data_four
        })

        console.log(`сработал catch(err): `, err)
      })
    // console.log('LOAD DATA FROM SERVER');//test
  }

  async componentDidMount() {
    localStorage.removeItem('first') // отключаем первый вход до анкеты

    if (this.props.infoBlock) {
      // console.log(`ВХОД с инфоблоком`); // test
      this.setState({
        infoBlock: this.props.infoBlock,
        pointPosition: this.props.pointPosition,
        choice: ['Main', 'Production', 'Fifteen', 'EconomicData'], //! задаются поля для обязательного вывода
        showBtn: false
      })
    } else {
      // console.log(`ВХОД без инфоблока`); // test
      this.setState({
        infoBlock: this.props.infoBlock,
        pointPosition: this.props.pointPosition,
        choice: ['Main', 'Production', 'Fifteen', 'EconomicData'], //! задаются поля для обязательного вывода
        showBtn: false
      })
    }

    setTimeout(() => {
      this.setState({ show: true, showBtn: true })
    }, 5)

    setTimeout(() => {
      this.getDataForAccordion()

      // console.log('CHOICE > ', this.state.choice);//test
    }, 10)

    const result = await Server.getDataFromServer(sessionStorage.getItem('login'), 'registration')
    const key = result.key[localStorage.getItem('idUser')]
    if (key) {
      this.setState({
        accessType: key
      })
    }
    const resultGet = await Server.getDataFromServer(localStorage.getItem('idUser'), 'moderation')

    if (resultGet) {
      this.setState({
        sentOnModeration: resultGet.sentOnModertaion
      })
    }

    // console.log(this.state.sentOnModeration)
    this.setFillingGuide(this.state.sentOnModeration)

    setTimeout(() => {
      this.setState({
        loading: false
      })
    }, 3000)
  }

  refreshQuestionnairy(e) {
    e.preventDefault()
    window.location.reload()
  }

  render() {
    const viewQuest = this.props.viewQuest //меняем вид анкеты
    const show = this.state.show
    const showAccordion = this.state.showAccordion
    // console.log(`infoblock in render: `, this.state.infoBlock);
    const classEmpty = this.state.infoBlock.classQuestBlock
    const view_ = this.state.view_

    // console.log('this.state.data_[25]:', this.state.data_);

    // console.log(this.state.data_[35]);

    /**
        * Памятка, позиций форм в массиве view_, viewForm и др:
        * Main = 0
        * One = 1
        * Two = 2
        * Three = 3
        * Four = 4
        * Five = 5
        * Six = 6
        * Seven = 7
        * Eight = 8
        * Nine = 9
        * Ten = 10
        * Eleven = 11
        * Twelve = 12
        * Thirteen = 13
        * Fourteen = 14
        * Fifteen = 15
        * Sixteen = 16
        * Seventeen = 17
        * Zero = 18
        * Production = 19
        * FormBendPipe = 20
        * FormBottoms = 21
        * FormLensCompensators = 22
        * FormFlanges = 23
        * FormForgings = 24
        * FormBends = 25

        */

    return (
      <>
        {this.state.show_errorRequest ?
          <ModalError />
          : ''
        }
        {this.state.accessType === 'zero' && this.state.sentOnModeration && (
          <div className='quest_main_alert'>
            <Alert className='quest_alert' variant='success'>
              Ваши данные находятся на модерации. Уведомление о регистрации придет на Вашу почту{' '}
              {sessionStorage.getItem('login')}
              {/* <button onClick={this.doRunAccordion}>gyuyuyrtyuryutryut</button> */}
            </Alert>
            {/* <Row> &nbsp; </Row> */}
            {/* <Row> &nbsp; </Row> */}
          </div>
        )}

        <div className='transition_075s' style={{ opacity: show ? '1' : '0' }}>
          <ModalSave show={this.state.showSave} />

          {this.state.showLook ? <ModalLoad stopLooking={1500} /> : ''}

          {/* {this.props.searchMode && this.props.companyQuestionnairie.inn === this.state.innForSearch &&  */}
          <>
            {this.state.accessType === 'zero' && (
              <Button
                variant='primary'
                className='quest_refresh_btn'
                onClick={this.refreshQuestionnairy}
              >
                <ArrowBackOutlinedIcon />
                Вернуться
              </Button>
            )}

            {this.state.showFillFormMain && (
              <ModalInformation
                closeModal={() => this.setState({ showFillFormMain: false })}
                modalBodyText={'Необходимо заполнить раздел "Контактная информация"'}
              />
            )}

            {this.state.showFillFormProduction && (
              <ModalInformation
                closeModal={() => this.setState({ showFillFormProduction: false })}
                modalBodyText={'Необходимо заполнить раздел "Профиль деятельности"'}
              />
            )}

            {this.state.sendOnModeration &&
              !this.state.sentOnModeration && ( //1е условие откр-т модалку, 2е с сервера(данные были отправлены на мрдерацию)
                <ModalInformation
                  closeModal={() => this.setState({ sendOnModeration: false })}
                  modalBodyText={'Все необходимые данные заполнены. Отправить данные на модерацию?'}
                  needCustomBtn={true}
                  customBtn={
                    <SaveCurrentDataButton
                      handleSaveClick={this.handleClickModerationWithNotification}
                      needDisable={false}
                      // disableCondition={this.state.savedDataMessage || this.state.toModerate ? true : false}
                      savedDataMessage={this.state.savedDataMessage}
                      buttonTextDefault={'Отправить на модерацию'}
                      buttonTextSaved={'Заполненные данные отправлены на модерацию'}
                      needCustomIcon={true}
                      needCustomStyle={false}
                      // customStyle='quest_moderation_btn'
                      customIcon={
                        <PublishedWithChangesOutlinedIcon style={{ marginRight: '5px' }} />
                      }
                    />
                  }
                />
              )}

            {viewQuest ? (
              <div fluid className='anketa_true'>
                {view_[0] ? (
                  <Form_Main
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[0]}
                    data={this.state.data_[0]}
                    // view={view_Main}
                    view={view_[0]}
                    viewQuest={viewQuest}
                    onRunFormZero={this.doRunFormZero} //off
                    onAddNewForm={this.choiceProduction}
                    // showButtonInQuestionnairy={this.props.showButtonInQuestionnairy}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                    // sendFilligInfoMain={this.sendFilligInfoMain}
                    // setSendOnModerationModal={this.setSendOnModerationModal}
                    setFillingGuide={this.setFillingGuide}
                  />
                ) : (
                  ''
                )}

                {view_[18] ? (
                  <Form_Zero
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[18]}
                    data={this.state.data_[18]}
                    // view={view_Zero}
                    view={view_[18]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[1] ? (
                  <Form_One
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[1]}
                    data={this.state.data_[1]}
                    // view={view_One}
                    view={view_[1]}
                    viewQuest={viewQuest}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[2] ? (
                  <Form_Two
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[2]}
                    data={this.state.data_[2]}
                    // view={view_Two}
                    view={view_[2]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    // showButtonInQuestionnairy={this.props.showButtonInQuestionnairy}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[3] ? (
                  <Form_Three
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[3]}
                    data={this.state.data_[3]}
                    // view={view_Three}
                    view={view_[3]}
                    viewQuest={viewQuest}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[4] ? (
                  <Form_Four
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[4]}
                    data={this.state.data_[4]}
                    // view={view_Four}
                    view={view_[4]}
                    viewQuest={viewQuest}
                    nameFormMaterials={this.state.nameFormMaterials} // новое имя формы
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[5] ? (
                  <Form_Five
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[5]}
                    data={this.state.data_[5]}
                    // view={view_Five}
                    view={view_[5]}
                    viewQuest={viewQuest}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[6] ? (
                  <Form_Six
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[6]}
                    data={this.state.data_[6]}
                    // view={view_Six}
                    view={view_[6]}
                    viewQuest={viewQuest}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[7] ? (
                  <Form_Seven
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[7]}
                    data={this.state.data_[7]}
                    // view={view_Seven}
                    view={view_[7]}
                    viewQuest={viewQuest}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[8] ? (
                  <Form_Eight
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[8]}
                    data={this.state.data_[8]}
                    // view={view_Eight}
                    view={view_[8]}
                    viewQuest={viewQuest}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[9] ? (
                  <Form_Nine
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[9]}
                    data={this.state.data_[9]}
                    // view={view_Nine}
                    view={view_[9]}
                    viewQuest={viewQuest}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[10] ? (
                  <Form_Ten //!не сделано в режиме чтения
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[10]}
                    data={this.state.data_[10]}
                    // view={view_Ten}
                    view={view_[10]}
                    viewQuest={viewQuest}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[11] ? (
                  <Form_Eleven
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[11]}
                    data={this.state.data_[11]}
                    // view={view_Eleven}
                    view={view_[11]}
                    viewQuest={viewQuest}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[12] ? (
                  <Form_Twelve
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[12]}
                    data={this.state.data_[12]}
                    // view={view_Twelve}
                    view={view_[12]}
                    viewQuest={viewQuest}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[13] ? (
                  <Form_Thirteen
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[13]}
                    data={this.state.data_[13]}
                    // view={view_Thirteen}
                    view={view_[13]}
                    viewQuest={viewQuest}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[14] ? (
                  <Form_Fourteen
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[14]}
                    data={this.state.data_[14]}
                    // view={view_Fourteen}
                    view={view_[14]}
                    viewQuest={viewQuest}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[15] ? (
                  <Form_Fifteen
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[15]}
                    data={this.state.data_[15]}
                    // view={view_Fifteen}
                    view={view_[15]}
                    viewQuest={viewQuest}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[16] ? (
                  <Form_Sixteen //!не сделано в режиме чтения
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[16]}
                    data={this.state.data_[16]}
                    // view={view_Sixteen}
                    view={view_[16]}
                    viewQuest={viewQuest}
                  />
                ) : (
                  ''
                )}

                {view_[17] ? (
                  <Form_Seventeen //!не сделано в режиме чтения
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[17]}
                    data={this.state.data_[17]}
                    // view={view_Seventeen}
                    view={view_[17]}
                    viewQuest={viewQuest}
                  />
                ) : (
                  ''
                )}

                {view_[19] ? (
                  <QuestionnaireProduction
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[19]}
                    data={this.state.data_[19]}
                    // view={view_Production}
                    view={view_[19]}
                    viewQuest={viewQuest}
                    onRunAccordion={this.doRunAccordion}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                    setFillingGuide={this.setFillingGuide}
                  // setSendOnModerationModal={this.setSendOnModerationModal}
                  />
                ) : (
                  ''
                )}

                {view_[20] ? (
                  <FormBendPipe
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[20]}
                    data={this.state.data_[20]}
                    // view={view_Production}
                    view={view_[20]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    // showButtonInQuestionnairy={this.props.showButtonInQuestionnairy}
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[21] ? (
                  <FormBottoms
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[21]}
                    data={this.state.data_[21]}
                    // view={view_Production}
                    view={view_[21]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[22] ? (
                  <FormLensCompensators
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[22]}
                    data={this.state.data_[22]}
                    // view={view_Production}
                    view={view_[22]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[23] ? (
                  <FormFlanges
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[23]}
                    data={this.state.data_[23]}
                    // view={view_Production}
                    view={view_[23]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[24] ? (
                  <FormForgings
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[24]}
                    data={this.state.data_[24]}
                    // view={view_Production}
                    view={view_[24]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[25] ? (
                  <FormBends
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[25]}
                    data={this.state.data_[25]}
                    // view={view_Production}
                    view={view_[25]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}
                {view_[26] ? (
                  <FormHardware
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[26]}
                    data={this.state.data_[26]}
                    // view={view_Production}
                    view={view_[26]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[27] ? (
                  <FormRolling //!не сделано в режиме чтения
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[27]}
                    data={this.state.data_[27]}
                    // view={view_Production}
                    view={view_[27]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    innFromUniversalTable={this.props.innFromUniversalTable}
                  />
                ) : (
                  ''
                )}

                {view_[28] ? (
                  <FormProductColoration
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[28]}
                    data={this.state.data_[28]}
                    // view={view_Production}
                    view={view_[28]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[29] ? (
                  <FormBendingCuttingMetal
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[29]}
                    data={this.state.data_[29]}
                    // view={view_Production}
                    view={view_[29]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[30] ? (
                  <FormHeatTreatment
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[30]}
                    data={this.state.data_[30]}
                    // view={view_Production}
                    view={view_[30]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[31] ? (
                  <FormWelding
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[31]}
                    data={this.state.data_[31]}
                    // view={view_Production}
                    view={view_[31]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[32] ? (
                  <FormRolled
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[32]}
                    data={this.state.data_[32]}
                    // view={view_Production}
                    view={view_[32]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[33] ? (
                  <FormPipesSupply
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[33]}
                    data={this.state.data_[33]}
                    // view={view_Production}
                    view={view_[33]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {view_[34] ? (
                  <FormSheetMetalSupply
                    onCreateDataServer={this.doCreateDataServer}
                    onUpdateDataOnServer={this.doUpdateDataOnServer}
                    onChangeView={this.doChangeView}
                    lengthDataFromServer={this.state.lengthDataFromServer_[34]}
                    data={this.state.data_[34]}
                    // view={view_Production}
                    view={view_[34]}
                    viewQuest={viewQuest}
                    onRunMaterials={this.doRunMaterials} //запуск формы материалов
                    innFromUniversalTable={this.props.innFromUniversalTable}
                    companyName={this.props.companyName}
                  />
                ) : (
                  ''
                )}

                {
                  this.state.showBtn && (
                    <>
                      {this.state.loading ? (
                        <LoaderPage w={'100%'} minH={'100vh'} />
                      ) : (
                        <>
                          <QuestionnaireButton
                            onClickView={this.handleClickView}
                            classEmpty={classEmpty}
                            choice={this.state.choice}
                            getPercentAndFormName={this.getPercentAndFormName}
                            formNames={this.state.infoBlock.nameQuestBlock}
                            formNameForAccordion={this.state.formNameForAccordion}
                            onSendEconomicFilling={this.doSendEconomicFilling}
                          />
                          {!this.props.innFromUniversalTable && (
                            <div
                              style={{
                                display: 'flex',
                                // flexWrap: "wrap",
                                justifyContent: 'center'
                              }}
                            >
                              <EconomicData
                                data={this.state.economicDataArray}
                                onCreateDataServer={this.doCreateDataServer}
                                onUpdateDataOnServer={this.doUpdateDataOnServer}
                                onChangeView={this.doChangeView}
                                lengthDataFromServer={this.state.lengthDataFromServer_[35]}
                                onRunMaterials={this.doRunMaterials}
                                viewQuest={viewQuest}
                                getDataFromServer={this.getDataFromServer}
                                onClickView={this.handleClickView}
                                data-name='EconomicData'
                                getEconomicData={this.props.getEconomicData}
                                lookingSave={this.lookingSave}
                                fill={this.state.fillEconomic}
                                classEmpty={classEmpty}
                                // setSendOnModerationModal={this.setSendOnModerationModal}
                                setFillingGuide={this.setFillingGuide}
                                reRenderQuestBtn={this.reRenderQuestBtn}
                                // test={this.test}
                                changeColorButton={this.changeColorButton}
                              />
                            </div>
                          )}
                          {showAccordion ? (
                            <ProductionAccordion
                              classEmpty={classEmpty}
                              data={this.state.dataAccordion}
                              onClickView={this.handleClickView}
                              innFromUniversalTable={this.props.innFromUniversalTable}
                              getPercentAndFormName={this.getPercentAndFormName}
                              formNames={this.state.infoBlock.nameQuestBlock}
                              percent={this.state.percent}
                              formNameForAccordion={this.state.formNameForAccordion}
                            />
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </>
                  )

                  // </div>
                }
              </div>
            ) : (
              <Container fluid className='anketa_new'>
                <h5 onClick={this.handleClickView} data-name='Main' className='h5_questionaire'>
                  {' '}
                  1. Контактная информация
                </h5>
              </Container>
            )}
          </>
          {/* } */}
        </div>
      </>
    )
  }
}
