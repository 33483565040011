import React from 'react';
import { shortenName } from '../assets/shortenStringsFunction';

export default class SideBarLogo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            icon: '',
            showIcon: true,
            newIcon: '',
        }
        this.handleClickLogo = this.handleClickLogo.bind(this);
    }

    handleClickLogo() {
        // this.props.openSettings()
        window.location.reload()
    }


    async componentDidMount() {
        await this.props.doChangeLogo()
        await this.setState({
            icon: this.props.icon
        })
        this.setState({ newIcon: this.props.newIcon })
    }

    componentDidUpdate(prevState) {
        if (prevState.newIcon !== this.props.newIcon) {
            this.setState({ icon: this.props.newIcon })
        }
    }

    componentWillUnmount() {

    }

    render() {

        const show = this.props.show;
        this.nameCompany = this.props.nameCompany;
        const processedName = shortenName(this.nameCompany?.value, 70)


        return (

            <div
                className={show ? 'side_bar_logo-vert' : 'side_bar_logo'}
                onClick={this.handleClickLogo}
            >
                {this.state.showIcon &&
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            gap: '15px',
                            alignItems: 'center'
                        }}
                        title={this.nameCompany?.value}
                    >
                        <img
                            src={this.state.icon}
                            alt=''
                            className={show ? 'side_bar_logo_img_vert' : 'side_bar_logo_img'}
                        />

                        <div
                            className={show ? 'side_bar_logo_company_name_vert' : 'side_bar_logo_company_name_vert_null'}
                        // title={this.nameCompany.value}
                        >
                            {this.nameCompany && processedName}
                        </div>
                    </div>}

            </div>

        )
    }
}