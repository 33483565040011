import React from 'react'
import { Form, Card, Alert, Row } from 'react-bootstrap'
import InputMask from 'react-input-mask'
import Server from './server/server'
// import GppGoodOutlinedIcon from '@mui/icons-material/GppGoodOutlined';
// import WrongLocationOutlinedIcon from '@mui/icons-material/WrongLocationOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined'
import AllModalsFooter from './assets/AllModalsFooter'
import RadioButtonForm from './questionnaire/RadioButtonForm'
import { CreateButton } from './assets/BasicButtons'
import RadioButtonThree from './questionnaire/RadioButtonThree'
// import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

export default class CheckINN extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      show: true,
      inn: '',
      error: false,
      showUr: false,
      showIp: false,
      innAnother: '',
      nameAnotherCompany: '',
      activeButtonUr: true,
      activeButtonIp: false,
      showInnAlreadyExistError: false,
      specialization: ''
    }

    this.handleChangeINN = this.handleChangeINN.bind(this)
    this.handleClickINN = this.handleClickINN.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.getSerialNumber = this.getSerialNumber.bind(this)
    this.sendSerialNumber = this.sendSerialNumber.bind(this)
    this.setAlreadyExistInnError = this.setAlreadyExistInnError.bind(this)
    this.onRadioChange = this.onRadioChange.bind(this)
  }

  onRadioChange(object) {
    this.setState({
      specialization: object.value
    })
  }

  handleClick(e) {
    if (e.value === 'Юридическое лицо') this.setState({ showUr: true, showIp: false, inn: '', activeButtonUr: true, activeButtonIp: false })
    if (e.value === 'Индивидуальный предприниматель') this.setState({ showUr: false, showIp: true, inn: '', activeButtonIp: true, activeButtonUr: false })
  }

  async handleChangeINN(e) {
    await this.setState({ inn: String(e.target.value) })
    this.setState({
      showInnAlreadyExistError: false
    })
  }

  async getSerialNumber() {
    try {
      const arrSerial = await Server.getDataFromServer('serial', 'global')
      return arrSerial
    } catch (err) {
      console.log(`Ошибка получения массива порядковых комеров: `, err)
    }
  }

  // записываем на сервер новый массив серийных номеров
  async sendSerialNumber(arr) {
    try {
      if (arr) {
        const lastObject = arr[arr.length - 1]
        const newNumber = Number(lastObject.serial) + 1
        const newSerialObject = { inn: this.state.inn, serial: Number(newNumber) }
        arr.push(newSerialObject)
        await Server.sendDataOnServer(arr, 'serial', 'global')
        // console.log(result); // test
        return newNumber
      } else {
        console.log(`Массив серийных номеров пуст`)
        throw new Error('Массив серийных номеров пуст') // repair
      }
    } catch (err) {
      // console.log(`Ошибка записи серийного номера на сервер: `, err);
      const newSerialObject = { inn: this.state.inn, serial: 1 } // в случае если массив серийных номеров - undefined (пуст)
      await Server.sendDataOnServer([newSerialObject], 'serial', 'global')
      // console.log(`Создана новая библиотека: `, result); // test
    }
  }

  setAlreadyExistInnError() {}

  async handleClickINN(e) {
    e.preventDefault()
    //todo вставить проверку ИНН
    try {
      const result = await Server.getDataFromServer('Main', this.state.inn)
      result.forEach((data, index) => {
        if (index === 1) {
          this.setState({ nameAnotherCompany: data.value })
          this.setState({
            showInnAlreadyExistError: true
          })
        }
        if (index === 6) {
          this.setState({ innAnother: data.value })
          this.setState({
            showInnAlreadyExistError: true
          })
        }
      })
    } catch (err) {
      // console.log(`Наличие ИНН в базе -> ${err}`);//!наличие ошибки подтверждает отсутсвие ИНН в базе
      // console.log(`ALL OK, place clean!`);
      //todo отправить ИНН после проверки, если он не занят
      ;(async () => {
        const arrSerial = await this.getSerialNumber()
        await this.sendSerialNumber(arrSerial)
        // console.log(`Serial Number: `, serialNumber); // test
        this.props.onSendINN(this.state.inn) //*отправляет ИНН в компонент Block.jsx
      })()
    }
    // }
  }

  componentDidMount() {
    this.setState({
      showUr: true
    })
  }

  render() {
    // const error = this.state.error
    const showUr = this.state.showUr
    // const showIp = this.state.showIp
    console.log(this.state.specialization);
    return (
      <>
        <Card className='modal_window' style={{ zIndex: '19' }}>
          <div className='shadow'></div>
          <Form className={this.state.showOff ? 'modal_form_off' : 'modal_common p_40 z_index_1000 width_50'}>
            <Alert dismissible onClose={this.props.toggleCheckInnModal} variant='dark' className={this.state.showInnAlreadyExistError ? 'header_alert_common background_danger color_fff' : 'header_alert_common'}>
              {this.state.showInnAlreadyExistError && (
                <>
                  <ErrorOutlineOutlinedIcon /> Компания с данным ИНН уже зарегистрирована
                </>
              )}

              {!this.state.showInnAlreadyExistError && <Alert.Heading>Проверка ИНН предприятия</Alert.Heading>}
            </Alert>

            <Card.Body>
              <RadioButtonForm
                id={1}
                width={12}
                show={true}
                // title='Проверка ИНН:'
                title='Выберите Ваш юридический статус:'
                label1='Юридическое лицо'
                label2='Индивидуальный предприниматель'
                placeholder=''
                description=''
                value={this.state.inn}
                onChangeValue={this.handleClick}
                onChangeVisionBlock={this.doEmpty}
                test={false}
              />
              <Row> &nbsp; </Row>
              <RadioButtonThree
                id={2}
                width={12}
                show={true}
                title='Выберите Вашу специализацию:'
                label1='Машиностроительный завод выпускающий сосуды и аппараты работающие под давлением и/или поставщик комплектующих и металлопроката для машиностроительных заводов, выпускающих сосуды и аппараты работающие под давлением'
                label2='Проектный институт или инжиниринговая компания, использующая в своих проектах сосуды и аппараты работающие под давлением'
                label3='Генеральный подрядчик строящий объекты, с использованием аппаратов и сосудов, работающих под давлением'
                label4='НИИ и лицензиар, разрабатывающий технологии с применением аппаратов и сосудов, работающих под давлением'
                label5='Предприятие, эксплуатирующее аппараты и сосуды, работающих под давлением'
                placeholder='Категории лота'
                description='Категории лота'
                value={this.state.radioResult}
                onChangeValue={this.onRadioChange}
                onChangeVisionBlock={() => {}}
                // nameForm={this.state.nameForm}
              />
              <Row> &nbsp; </Row>
              <div style={{ position: 'relative' }}>
                <div className={'upper_label_check_inn'}>ИНН</div>
                <InputMask style={{ marginBottom: '15px' }} className='form-control phone_form' mask={showUr ? '9999999999' : '999999999999'} maskChar='_' placeholder='Введите Ваш ИНН' value={this.state.inn} onChange={this.handleChangeINN} />
              </div>
            </Card.Body>
            <Row> &nbsp; </Row>
            <Row> &nbsp; </Row>
            <AllModalsFooter
              isPosAbsoluteNedded={true}
              footerContent={
                <CreateButton
                  handleSaveClick={this.handleClickINN}
                  needDisable={true}
                  disableCondition={this.state.inn.includes('_') || !this.state.inn.length ? true : false}
                  variant={'success'}
                  buttonText={'Создать карточку предприятия'}
                  iconWithPlus={true}
                />
              }
            />
          </Form>
        </Card>
      </>
    )
  }
}
