


const CalculationHeatExchangers = () => {
    console.log(`calculation heat exchagers`); // test

    return (
        <>
            <div style={{ padding: '80px 150px 20px 150px' }}>

            </div>
        </>
    )
}

export default CalculationHeatExchangers;