import React from "react";
import { Alert, Modal, Row } from 'react-bootstrap';
// import AddOutlinedIcon from '@mui/icons-material/AddOutlined'
import LoadIcons from "./LoadIcons";

export default class SettingsModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: true
        }
        this.doClose = this.doClose.bind(this);
        this.doChangeLogo = this.doChangeLogo.bind(this);
    }

    // используется только для динамичной смены лого в NaviBar
    doChangeLogo() {
        this.props.onChangeLogo();
    }

    // закрываем модалку настроек
    doClose() {
        // this.props.openSettings(false) 
        this.props.onShow()
    }





    render() {
        return (
            <>
                <div div className="modal_window" >
                    <div className="shadow z_index_extra_big" onClick={this.doClose}></div>
                    <Modal.Dialog className='modal_common z_index_extra_big width_60' size='lg'>
                        <Alert dismissible onClick={this.doClose} variant='dark'>
                            <Alert.Heading>Настройки</Alert.Heading>
                        </Alert>
                        <Modal.Body className='overflow_y_scroll p_40' >
                            
                            <LoadIcons
                                setNewIcon={this.props.setNewIcon}
                                newIcon={this.props.newIcon}
                                onChangeLogo={this.doChangeLogo} // используется только для динамичной смены лого в NaviBar
                            />
                            <Row> &nbsp; </Row>
                        </Modal.Body>
                    </Modal.Dialog>

                </div>
            </>
        )
    }
}