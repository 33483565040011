export const WELDING = [
    'РД - Ручная дуговая сварка покрытыми электродами',
    'РАД - Ручная аргонодуговая сварка неплавящимеся электродом',
    'Г - Газовая сварка',
    'РДН - Ручная дуговая наплавка покрытыми электродами',
    'РДВ - Ванная ручная дуговая сварка покрытыми электродами',
    'МП - Механизированная сварка плавящимеся электродом в среде активных газов и смесях',
    'КТС - Контактная точечная сварка',
    'КСО - Контактная стыковая сварка оплавлением',
    'МАДП - Механизированная аргонодуговая сварка плавящимся электродом',
    'МФ - Механизированная сварка под флюсом',
    'МПГ - Механизированная сварка порошковой проволокой в среде активных газов',
    'МПС - Механизированная сварка самозащитной порошковой проволокой',
    'МПСВ - Ванная механизированная сварка самозащитной порошковой проволокой',
    'МФН - Ванная механизированная сварка под флюсом',
    'АФ - Автоматическая сварка под флюсом',
    'ААД - Автоматическая аргонодуговая сварка неплавящимся электродом',
    'ААДП - Автоматическая аргонодуговая сварка плавящимся электродом',
    'АПГ - Автоматическая сварка плавящимся электродом в среде активных газов и смесях',
    'АППГ - Автоматическая сварка порошковой проволокой в среде активных газов и смесях',
    'АПИ - Автоматическая сварка порошковой проволокой в среде инертных газов и смесях',
    'АПС - Автоматическая сварка самозащитной порошковой проволокой',
    'П - плазменная сварка',
    'НИ - Сварка нагретым инструментом полимерных материалов',
    'ЗН - Сварка с закладными нагревателями полимерных материалов'
]