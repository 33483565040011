/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import { Container, Form, Button, Row, Col, Table, Card } from 'react-bootstrap';
import Server from '../server/server';
import ModalQuit from '../ModalQuit';
import { SearchCompanyData } from './SearchCompanyData';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ZoomInOutlinedIcon from '@mui/icons-material/ZoomInOutlined';


export default class SearchCompany1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true,
            showQuitModal: false,
            base: [], // рендер таблицы выбранных компаний
            baseRender: [],
            company: [], // полученные данные от сервера
            nameCompany: [], // занчение наименований компании для отображение в select
            showCity: false,
            dataChangeCompany: [], // состояние для дальнейшего использования
            alertId: null,
            showCompanyData: null,
            errorMessage: false,
            successMessage: false,
            resultCompany: []
        }

        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
        this.handleClickSearch = this.handleClickSearch.bind(this);
        this.handleClickChoice = this.handleClickChoice.bind(this);
        // this.renderListCompany = this.renderListCompany.bind(this);
        this.doChangeValue = this.doChangeValue.bind(this);
        this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this);
        this.writeChoiceCompany = this.writeChoiceCompany.bind(this);
        this.openCompanyDataModal = this.openCompanyDataModal.bind(this);
        this.closeCompanyDataModal = this.closeCompanyDataModal.bind(this);
        this.readDataCompany = this.readDataCompany.bind(this);
        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);
        this.openQuitModal = this.openQuitModal.bind(this);

        this.renderTableOut = this.renderTableOut.bind(this);
        this.addCompany = this.addCompany.bind(this);

        // this.getListProduction = this.getListProduction.bind(this);
        this.searchChoiceCompany = this.searchChoiceCompany.bind(this);
    }

    renderTableOut() {
        this.renderData = [];
        console.log(`ARRAY render ====> `, this.state.company);// test
        const dataTableRender = this.state.company.map(company => {

            if (company.added == true) {
                return (
                    <React.Fragment key={company.name + company.idCompany}>
                        <tbody>
                            <tr className='cursor_auto' >
                                <td> {company.nameCompany === '' ? 'Имя не выбрано' : company.nameCompany}  </td>
                                <td> {company.cityCompany} </td>
                            </tr>
                        </tbody>

                    </React.Fragment>
                )
            }
        });
        this.renderData.push(
            <>
                <Card.Header className='mt_10' as="h6" > Ваши компании <CheckOutlinedIcon color="success" className='pb_2' /></Card.Header>
                <Table responsive bordered size="sm" variant='light' >
                    <thead style={{ border: '1px #000 solid' }}>
                        <tr>
                            <th className='fw_bolder'>Название</th>
                            <th className='fw_bolder'>Город</th>
                        </tr>
                    </thead>
                    {dataTableRender}

                </Table >
            </>
        );

        this.setState({ baseRender: this.renderData })

    }


    openQuitModal() {
        this.setState({
            showQuitModal: true
        })
    }

    doQuitWithSave() {
        //запустить модал сохранения
        this.setState({
            showQuitModal: false,
            show: false
        })
    }

    doQuitWithoutSave() {
        this.setState({
            showQuitModal: false,
            show: false
        })
    }


    writeChoiceCompany() {

        const arrayInnCompanys = this.state.dataChangeCompany.map(data => {
            return data.idCompany;
        })

        console.log(`ARRAY >> `, arrayInnCompanys) //test


        // this.props.onChoiceCompany(arrayInnCompanys);
        // this.props.onChangeShow();
        this.props.handleClickShow()
        // this.props.onTableChoice(this.state.base); // ! ****************
        // this.props.onTableChoice(this.state.base, this.state.baseRender); // ! ****************

    }

    //vision
    doChangeVisionBlock(e, id) {
        // console.log(e); // test (text)
        // console.log('id>>> ', id.target.dataset.id); //test (object)
        this.addCompany(e);
    }

    //render for table
    async addCompany(company) {
        // console.log(`COMPANY >>>> `, this.state.company);
        let dataC = this.state.company.map(data => {

            if (data.idCompany !== company.idCompany) {
                return data
            }

            if (!data.added) {

                if (!this.state.dataChangeCompany.find(item => item.idCompany === data.idCompany)) {
                    this.setState({
                        dataChangeCompany: [...this.state.dataChangeCompany, data]
                    })
                }
                return {
                    idCompany: data.idCompany,
                    nameCompany: data.nameCompany,
                    cityCompany: data.cityCompany,
                    added: true
                }

            } else {

                if (!this.state.dataChangeCompany.find(item => item.idCompany === data.idCompany)) {
                    this.setState({
                        dataChangeCompany: [...this.state.dataChangeCompany, data]
                    })
                }

                const dataToggle = !data.added;
                return {
                    idCompany: data.idCompany,
                    nameCompany: data.nameCompany,
                    cityCompany: data.cityCompany,
                    added: dataToggle
                }
            }
        })
        await this.setState({ company: dataC })
        setTimeout(() => { this.renderTableOut() })
    }


    // читаем данные о выбранной компании
    async readDataCompany(id) {
        const dataCompany = await Server.readDataCompany(id);

        // console.log(`DATA COMPANY >>>> `, dataCompany);
        return dataCompany;
    }

    async openCompanyDataModal(id) {
        // e.preventDefault()
        const result = await this.readDataCompany(id);
        await this.setState({ resultCompany: result })

        if (this.state.company.find(item => item.idCompany === id)) {
            this.setState({
                showCompanyData: id
            })
        }
    }

    closeCompanyDataModal() {
        this.setState({
            showCompanyData: null
        })
    }

    //данные с select
    doChangeValue(e) {
        // console.log('CHANGE ', e);
    }

    //кнопка выбора
    handleClickChoice() {
        // console.log(`click Choice`);
        // console.log(this.state.company);
        this.writeChoiceCompany();
        // this.renderTableOut()
        // this.setState({
        //     showCity: false
        // })

    }

    //кнопка поиска
    handleClickSearch() {
        // console.log(`click Search`);
    }

    //пустышка
    doEmpty() {
    };

    //закрывает форму
    handleClickShadow() {
        this.props.onChangeShow();
    }

    // поиск всех предприятий в ДБ, получение объекта: {id, name, phone}
    async runSearch() {

        try {
            await this.searchChoiceCompany(this.props.dataChoiceProductions); // TODO перенести в getListProduction, запускать по готовности, после получения данных

        } catch (err) {
            console.log('ERROR SEARCh COMPANY in frontend ', err)
        }
    };

    //TODO поиск всех компаний (переделать BACKEND, работать со списком полученным от getListProduction)
    async searchChoiceCompany(data) {
        const result = await Server.searchAllCompany(data);
        // console.log('RESULT ALL SEARCH ', result);

        // исключаем свою компанию из списка
        let newResult = result.filter(item => {
            return item.idCompany !== localStorage.getItem('idUser');
        })

        this.setState({ company: newResult });
        this.renderListCompany(newResult);
    }

    async showCity(result) {
        await this.setState({
            nameCompany: result.map(data => {
                return (
                    data.nameCompany
                )
            }),
            showCity: true
        })
    }


    async renderListCompany(result) {
        await this.setState({
            nameCompany: result.map(data => {
                return (
                    data.nameCompany
                )
            }),
            showCity: true
        })
    }

    async componentDidMount() {
        await this.runSearch();
    }

    render() {
        const disabled = this.state.company.map(c => c.added)
        return (
            <>
                {this.state.showQuitModal &&
                    <ModalQuit
                        onQuitWithoutSave={this.doQuitWithoutSave}
                        onQuitWithSave={this.doQuitWithSave}
                    />
                }
                <Form >
                    <Container style={{ marginTop: '50px', marginBottom: '80px' }}>

                        <Row>
                            {this.state.showCity &&
                                <>

                                    <Card.Header className='width_100pr mt_10' as="h6" >Список всех компаний </Card.Header>
                                    <Table bordered size="sm" className='' >
                                        <thead>
                                            <tr>
                                                <th className='fw_bolder'>Название</th>

                                                <th className='fw_bolder'>Город</th>
                                                <th className='fw_bolder text_align_center'>Данные</th>
                                                <th className='fw_bolder text_align_center'>Добавить</th>
                                            </tr>

                                        </thead>

                                        {this.state.company.map(company => (
                                            <>
                                                <tbody>
                                                    <tr
                                                        key={company.idCompany}
                                                        className={company.added ? 'addedToTable cursor_pointer' : 'cursor_pointer'}

                                                    >

                                                        <>
                                                            <td title='Просмотреть данные о компании' className='cursor_auto'>
                                                                {company.nameCompany === '' ? 'Имя не выбрано' : company.nameCompany}
                                                            </td>

                                                            <td className='cursor_auto'>
                                                                {company.cityCompany ? <>г. {company.cityCompany}</> : <i>г. Город не указан</i>}
                                                            </td>

                                                            <td className='width_100px text_align_center' onClick={() => this.openCompanyDataModal(company.idCompany)}>
                                                                <ZoomInOutlinedIcon sx={{ color: company.added ? '#28a745' : '#17a2b8' }} />
                                                            </td>


                                                            {!company.added ?
                                                                <td
                                                                    onClick={() => this.addCompany(company)}
                                                                    className='width_100px text_align_center'
                                                                    title='Добавить компанию в список'
                                                                >
                                                                    <AddOutlinedIcon sx={{ color: '#28a745' }} />
                                                                </td>
                                                                :
                                                                <td
                                                                    className='width_100px text_align_center'
                                                                    onClick={() => this.addCompany(company)}
                                                                    title='Чтобы удалить компанию, нажмите на галочку'
                                                                >
                                                                    <CheckOutlinedIcon sx={{ color: '#28a745' }} />
                                                                </td>
                                                            }
                                                        </>
                                                    </tr>
                                                </tbody>
                                                {this.state.showCompanyData === company.idCompany &&
                                                    <SearchCompanyData
                                                        resultCompany={this.state.resultCompany}
                                                        closeCompanyDataModal={this.closeCompanyDataModal}
                                                    />
                                                }
                                            </>
                                        ))}
                                    </Table>
                                </>
                            }

                            <Col>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    variant="success"
                                    onClick={this.handleClickChoice}
                                    style={{ float: 'right', margin: '10px' }}
                                    disabled={disabled.find(c => c === true) ? false : true}
                                >
                                    Добавить в лот
                                </Button>

                            </Col>
                        </Row>
                    </Container>
                </Form>
            </>
        )
    }
}