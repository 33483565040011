import React from 'react'
import { Form, Card, Col, Row, Alert, Button } from 'react-bootstrap'
import Server from './server/server'
import WrongLocationOutlinedIcon from '@mui/icons-material/WrongLocationOutlined'
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined'
import { customAlphabet } from 'nanoid'
import AllModalsFooter from './assets/AllModalsFooter'
import { LoginButton, RegistrationButton } from './assets/BasicButtons'
import sendSerialToLocalStorage from './sendSerialToLocalStorage'
import checkForErasePassword from './checkForErasePassword'

export default class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      showOff: false,
      eMail: '',
      password: '',
      error: false,
      login: '',
      idCompany: [],
      phone: '',
      name: '',
      surname: '',
      patronage: '',
      gender: '',
      showStoped: false,
      key: {},
      showLooking: false, // запуск модалки загрузки, при входе оп пргилашению
      isPasswordVisible: false,
      disableLoginButton: false,
      error2: false, //ошибка сессии
      showErase: false,
      eraseEmail: ''
    }

    this.handleClickLogIn = this.handleClickLogIn.bind(this)
    this.handleClickRegistration = this.handleClickRegistration.bind(this)
    this.handleChangeEmail = this.handleChangeEmail.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.getDataFromServer = this.getDataFromServer.bind(this)
    this.processingError = this.processingError.bind(this)
    this.goInSystem = this.goInSystem.bind(this)
    this.onKeyPressHandler = this.onKeyPressHandler.bind(this)
    this.runInvite = this.runInvite.bind(this)
    this.sendDataForRegistration = this.sendDataForRegistration.bind(this)
    this.writeDataCompany = this.writeDataCompany.bind(this)
    this.getSerialNumber = this.getSerialNumber.bind(this)
    this.sendSerialNumber = this.sendSerialNumber.bind(this)

    this.lookUrl = this.lookUrl.bind(this)
    this.showPassword = this.showPassword.bind(this)

    this.handleClickErasePassword = this.handleClickErasePassword.bind(this)
  }

  /**
   * Запуск процедуры сброса пароля
   */
  handleClickErasePassword() {
    ; (async () => {
      try {
        // console.log(`Erase password CLICK: `, this.state.eMail); // test
        await this.setState({ eraseEmail: this.state.eMail })

        setTimeout(() => {
          if (this.state.eraseEmail === this.state.eMail) {
            this.setState({ showErase: false })
          }
        }, 100)

        //*: Запуск end-point на backend для запуска процедуры сброса пароля
        const resultErase = await Server.erasePassword(this.state.eraseEmail)
        console.log(`RESULT erase password: `, resultErase) // test

        if (resultErase.result === 'ok') {
          // todo: ЗАПУСТИТЬ УВЕДОМЛЕНИЕ о ПРОСМОТРЕ ПОЧТЫ для СБРОСА ПАРОЛЯ
          console.log(`ЗАПУСТИТЬ УВЕДОМЛЕНИЕ о ПРОСМОТРЕ ПОЧТЫ для СБРОСА ПАРОЛЯ`)
        }
      } catch (err) {
        console.log(`Ошибка процедуры сброса пароля: `, err)
      }
    })()
  }

  showPassword() {
    this.setState({
      isPasswordVisible: !this.state.isPasswordVisible
    })
  }

  /**
   * Запуск регистрации и входа в систему до анкеты
   * при первом входе по приглашению
   * @param {String} idInvite ID приглашение по почте
   */
  async runInvite(idInvite) {
    // * : Запустить модалку для скрытия перехода по компанентам при первом входе по приглашению
    this.setState({ showLooking: true }) // запуск модалки

    // * 1: получение предварительных регистрационных данных
    const resRegistration = await Server.getRegistartionInvite(idInvite)
    // console.log(`RESULT INVITE::: `, resRegistration); // test

    // проверяем, что бы вход был первый: поле отсутствует: (archive: true)
    if (resRegistration && !resRegistration.archive) {
      // console.log(`ВХОД first!!!!`); // test

      //* 1.1: сформировать пароль
      let pass
      if (!localStorage.getItem('nano')) {
        const nanoidCastomer = customAlphabet('ABCDEFGHIJKLMNOPQRSTUVWXYZ', 6) // символы и длина генерируемого пароля
        pass = nanoidCastomer()
        // localStorage.setItem('nano', pass)
        // console.log(`PASSWORD test: `, pass); // test
      } else {
        pass = localStorage.getItem('nano')
      }

      //* 1.2: в LocalStorage поместить информацию о первом входе, для проваливания до анкеты
      localStorage.setItem('first', true)
      localStorage.setItem('loginReg', resRegistration.emailInvite)

      //* 2: заполнить регистрацию в 'registration'
      await Server.sendDataRegistration(resRegistration.emailInvite, pass)
      // console.log(`RESULT registration: `); // test

      //* 2.1: отправить письмо с данными для входа (login, password)

      // todo: исключить повторную отправку письма, если уже есть пароль (запись паролья в localStorage переместить ниже)
      if (!localStorage.getItem('nano')) {
        await Server.sendRegistrationData(resRegistration.emailInvite, pass)
        console.log(`Отправка письма с данными регистрации`)
      }

      localStorage.setItem('nano', pass) // todo: тут добавлен

      //* 3: внести предварительные данные в формы и в личный кабинет
      const { inviteINN, nameCompany } = resRegistration

      if (inviteINN && nameCompany) {
        console.log(
          `Есть данные для промежуточной регистрации::: `,
          inviteINN,
          resRegistration.emailInvite,
          pass,
          nameCompany
        ) // test
        const result = await Server.getDataLogin(resRegistration.emailInvite, pass)
        console.log(`result:::: `, result) // test

        await this.sendDataForRegistration(result, resRegistration, pass) // создание сессии и обновление персональных данных

        this.setState({ showLooking: false }) // остановка модалки
      } else {
        console.log('Нет данных для дальнейшей регистрации') //! останавливаемся на личном кабинете
        await this.getDataFromServer(resRegistration.emailInvite, pass) // вход в систему, личный кабинет
        this.setState({ showLooking: false }) // остановка модалки
      }
    } else {
      this.setState({ showLooking: false }) // остановка модалки
    }
  }

  /**
   * создание сессии и обновление персональных данных
   * @param {Boolean} result
   * @param {Object} resRegistration
   * @param {String} pass
   */
  async sendDataForRegistration(result, resRegistration, pass) {
    if (result.result) {
      //* запись HASH в sessionStorage => ( sessionId: result.sessionId )
      sessionStorage.setItem('sessionId', result.sessionId)
      sessionStorage.setItem('login', result.login)

      const dataPersonalArea = {
        idCompany: [resRegistration.inviteINN],
        surname: resRegistration.inviteSurname,
        name: resRegistration.inviteName,
        patronage: resRegistration.invitePatronage,
        gender: '',
        phone: resRegistration.invitePhone,
        login: resRegistration.emailInvite,
        key: { [resRegistration.inviteINN]: 'zero' },
        timezone: ''
      }
      await Server.updateDataOnServerRegistration(dataPersonalArea)
      // console.log(`RESULT UPDATE REGISTRATION DATA: `, resUpdate);

      //* : внести данные о предприятии
      await this.writeDataCompany(resRegistration)

      await this.getDataFromServer(resRegistration.emailInvite, pass) //! вход в систему, личный кабинет
    } else {
      console.log(`Отказано с сервера!!!`)
    }
  }

  // записать данные о компании
  async writeDataCompany(objectData) {
    //*: проверка ИНН на наличи в DB
    try {
      const result = await Server.getDataFromServer('Main', objectData.inviteINN)
      result.forEach((data, index) => {
        if (index === 1) {
          console.log(`ИНН занят предприятием: ${data.value}`)
        }
        if (index === 6) {
          console.log(`ИНН предприятия: ${data.value}`)
        }
      })
    } catch (err) {
      console.log(`Наличие ИНН в базе -> ${err}`) //!наличие ошибки подтверждает отсутсвие ИНН в базе
      console.log(`ALL OK, place clean!`)
        //* отправить ИНН после проверки, если он не занят
        ; (async () => {
          const arrSerial = await this.getSerialNumber()
          await this.sendSerialNumber(arrSerial, objectData)
          // console.log(`Serial Number: `, serialNumber); // test
          // this.props.onSendINN(this.state.inn)//*отправляет ИНН в компонент Block.jsx
        })()
    }

    //*: Создать 'Main' в коллекции компании и внести предварительные регистрационные данные
    await this.parserMainForWriteINN(objectData)
  }

  //*: Парсим Main, для внесения ИНН
  async parserMainForWriteINN(data) {
    let dataNew = []

    for (let i = 0; i <= 123; i++) {
      if (i === 1) {
        dataNew[i] = {
          id: '1',
          fid: 'Main_1',
          description: 'Предприятие',
          information: 'Полное наименование предприятия без формы собственности',
          value: data.nameCompany
        }
      } else if (i === 2) {
        dataNew[i] = {
          id: '2',
          fid: 'Main_2',
          description: 'Предприятие',
          information: 'ФИО руководителя',
          value: [data.inviteSurname, data.inviteName, data.invitePatronage, '']
        }
      } else if (i === 100) {
        dataNew[i] = {
          id: '100',
          fid: 'Main_100',
          description: 'Форма собственности компании',
          information: 'Форма собстевнности компании',
          value: data.inviteOwn
        }
      } else if (i === 111) {
        dataNew[i] = {
          id: '111',
          fid: 'Main_111',
          description: 'Телефон руководителя компании',
          information: 'Телефон',
          value: data.invitePhone
        }
      } else if (i === 112) {
        dataNew[i] = {
          id: '112',
          fid: 'Main_112',
          description: 'Email руководителя компании',
          information: 'Email',
          value: data.emailInvite
        }
      } else if (i === 6) {
        dataNew[i] = {
          id: '6',
          fid: 'Main_6',
          description: 'Предприятие',
          information: 'ИНН',
          value: String(data.inviteINN)
        }
      } else {
        dataNew[i] = { id: '', fid: '', description: '', information: '', value: '' }
      }
    }

    await Server.createDataOnServer(dataNew, 'Main', data.inviteINN)
    // console.log(`Создана форма Main с полем ИНН: ${result}`);
  }

  // получаем массив серийных номеров
  async getSerialNumber() {
    try {
      const arrSerial = await Server.getDataFromServer('serial', 'global')
      return arrSerial
    } catch (err) {
      console.log(`Ошибка получения массива порядковых комеров: `, err)
    }
  }

  // записываем на сервер новый массив серийных номеров
  async sendSerialNumber(arr, data) {
    try {
      if (arr) {
        const lastObject = arr[arr.length - 1]
        const newNumber = lastObject.serial + 1
        const newSerialObject = { inn: data.inviteINN, serial: newNumber }
        arr.push(newSerialObject)
        await Server.sendDataOnServer(arr, 'serial', 'global')
        return newNumber
      } else {
        console.log(`Массив серийных номеров пуст`)
        throw new Error(`Массив серийных номеров пуст`) // repair
      }
    } catch (err) {
      console.log(`Ошибка записи серийного номера на сервер: `, err)
      const newSerialObject = { inn: data.inviteINN, serial: 1 } // в случае если массив серийных номеров - undefined (пуст)
      await Server.sendDataOnServer([newSerialObject], 'serial', 'global')
    }
  }

  handleChangePassword(e) {
    this.setState({ password: e.target.value })
  }

  handleChangeEmail(e) {
    ; (async () => {
      try {
        this.setState({ eMail: e.target.value })
        const re = /^[\w]{1}[\w-.]*@[\w-]+\.[a-z]{2,4}$/i
        const valid = re.test(e.target.value)
        console.log(`EMAIL valid::: `, valid) // test

        if (valid) {
          //*: функцию проверки наличия данного логина в базе
          const result = await checkForErasePassword(e.target.value)
          this.setState({ showErase: result })
        } else {
          this.setState({ showErase: false })
        }
      } catch (err) {
        console.log(`Ошибка при проверке логина: `, err)
      }
    })()
  }

  //* обработка перехода к регистрации
  handleClickRegistration(e) {
    e.preventDefault()
    // console.log('REGISTRATION');//test

    this.setState({ showOff: true })

    setTimeout(() => {
      this.props.onChangeShowLoginPage()
      this.props.onChangeShowRegistration()
    }, 750) // время так же завазано в css
  }

  /**
   * Обрабатываем ввход в ситстему.
   * idUser  в локальное хранилище
   * отключение модалок, переход к осн.блоку
   * @param {*} result
   */
  goInSystem(result) {
    this.setState({
      showOff: true,
      login: result.login,
      idCompany: result.id,
      phone: result.phone,
      name: result.name,
      surname: result.surname,
      patronage: result.patronage,
      gender: result.gender,
      timezone: result.timezone,
      key: result.key
    })
    const loginData = {
      login: result.login,
      idCompany: result.id,
      phone: result.phone,
      name: result.name,
      surname: result.surname,
      patronage: result.patronage,
      gender: result.gender,
      timezone: result.timezone,
      key: result.key
    }
    console.log(' <<<< loginData >>>>>>>>>>>>>> ', loginData) // test

    this.props.onStartPersonalArea(loginData)

    console.log(`login page !!!!!!!!!!!!!!!!!! >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> `, result.id) // test

    //*: если -> result.id - массив с одним значением, то запустить функцию записывания 'serial' в LocalStorage
    if (result.id.length === 1) {
      console.log(`Одно предприятие, записываем serial`)
      sendSerialToLocalStorage(result.id)
    } else {
      console.log(`Несколько предприятий, вход через личный кабинет`)
    }

    localStorage.setItem('idUser', result.id)
    this.props.onLoadStart(result.id)

    setTimeout(() => {
      this.props.onChangeShowLoginPage(false)
      console.log(result, ' <<<<< result from server') //test  //*
    }, 750) // время так же завазано в css
  }

  /**
   * Вход в систему uems
   * @param {*} login
   * @param {*} password
   */
  async getDataFromServer(login, password) {
    try {
      const result = await Server.getDataLogin(login, password, this.getCookie('session'))
      if (result.result) {
        //todo запись HASH в sessionStorage => ( sessionId: result.sessionId )
        sessionStorage.setItem('sessionId', result.sessionId)
        sessionStorage.setItem('login', result.login)

        //cookies
        document.cookie = `login=${result.login}; max-age=86400`
        document.cookie = `session=${result.sessionId}; max-age=86400`

        // document.cookie = `login=${result.login}; max-age=300`
        // document.cookie = `session=${result.sessionId}; max-age=300`

        this.goInSystem(result)
      }
      if (result.message === 'available session') {
        console.log('session is already exists')
        this.setState({
          error2: true
        })
        this.processingError()
      } else {
        console.log('ОТКАЗАНО с СЕРВЕРА:::: ', result) //test

        /**
         * В случае отказа приходит объект: { result: false, message: text }
         * text = 'no password' - если не верный пароль
         *  text = 'available session' - если сессия уже есть
         * text = 'other' - иные ошибки входа
         */

        this.setState({ error: true })
        this.processingError()
      }
    } catch (err) {
      console.log(err)
      console.log('ERROR LOG IN')
    }
  }

  //* сброс данных, вывод информации об ошибке, перезапуск ввода
  processingError() {
    setTimeout(() => {
      this.setState({ error: false, eMail: '', password: '' })
      this.setState({ error2: false, eMail: '', password: '' })
    }, 2000)
  }

  onKeyPressHandler(event) {
    if (event.keyCode === 13) {
      this.handleClickLogIn()
      // console.log('13');
    }
  }

  handleClickLogIn(e) {
    const re = /^[\w]{1}[\w-.]*@[\w-]+\.[a-z]{2,4}$/i
    const valid = re.test(this.state.eMail)
    // console.log(`EMAIL valid::: `, valid);

    if (this.state.eMail.length > 0 && this.state.password.length > 0 && valid) {
      this.getDataFromServer(this.state.eMail, this.state.password)
      console.log('LOG IN', e)
    } else {
      console.log('ОТКАЗАНО') //test
      // this.props.onChangeShowLoginPage();
      this.setState({ error: true })
      this.processingError()
    }
  }

  // проверяем URL  на /registration
  async lookUrl(link) {
    let url = new URL(link)

    if (url.pathname === '/registration') {
      this.setState({ showOff: true })
      setTimeout(() => {
        this.props.onChangeShowLoginPage()
        this.props.onChangeShowRegistration()
      }, 750) // время так же завазано в css
    }
  }

  /**
   * Возвращает cookie с указанным ключем
   * @param {*} name
   * @returns
   */
  getCookie(name) {
    let matches = document.cookie.match(
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
    )
    return matches ? decodeURIComponent(matches[1]) : undefined
  }

  async componentDidMount() {
    //*сразу вход, если не было совершено выхода, смотрим наличие cookies
    console.log(`Ожидаем данных в cookies`)
    if (this.getCookie('inn')) {
      localStorage.setItem('idUser', String(this.getCookie('inn')))
      sessionStorage.setItem('sessionId', String(this.getCookie('session')))
      sessionStorage.setItem('login', String(this.getCookie('login')))
    }

    if (localStorage.getItem('idUser')) {
      this.props.onChangeShowLoginPage()
    }

    //* вход по приглашению через E-mail
    const url = new URL(document.URL)
    const searchId = url.searchParams.get('id') // * получаем ID зашитый в строке обращения по ссылке
    // console.log(`URL: search::: `, searchId); // test

    if (!sessionStorage.getItem('login') && searchId) {
      // console.log(`RUN INVITE>>>> searchID::::  `, searchId); // test
      await this.runInvite(searchId)
    }

    await this.lookUrl(window.location.href) // проверяем URL  на /registration
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.eMail !== this.state.eMail || prevState.password !== this.state.password) {
      if (!this.state.eMail || !this.state.password) {
        this.setState({
          disableLoginButton: true
        })
      } else {
        this.setState({
          disableLoginButton: false
        })
      }
    }
  }

  render() {
    const error = this.state.error
    const sessionError = this.state.error2

    return (
      <>
        <Card>
          <div className='shadow'></div>
          <div
            className={
              this.state.showOff ? 'modal_form_off' : 'modal_common p_40 width_590 z_index_1000 '
            }
          >
            <Alert
              variant='dark'
              className={
                error || sessionError
                  ? 'header_alert_common background_danger color_fff'
                  : 'header_alert_common'
              }
            >
              {sessionError && !error && (
                <>
                  <WrongLocationOutlinedIcon color='disabled' className='pb_5' /> Ошибка сессии
                </>
              )}

              {error && !sessionError && (
                <>
                  <WrongLocationOutlinedIcon color='disabled' className='pb_5' /> Неверный логин или
                  пароль
                </>
              )}

              {!error && !sessionError && <Alert.Heading>Войдите в систему</Alert.Heading>}

              {/* {error ? (
                <>
                  <WrongLocationOutlinedIcon color='disabled' className='pb_5' /> Неверный логин или пароль
                </>
              ) : (
                <Alert.Heading>Войдите в систему</Alert.Heading>
              )} */}
            </Alert>

            <Card.Body className='container_common height_160'>
              <Form.Group as={Row} controlId='formHorizontalEmail'>
                <Form.Label column sm={2}>
                  <b>Email</b>
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type='email'
                    onKeyDown={this.onKeyPressHandler}
                    placeholder='Email'
                    onChange={this.handleChangeEmail}
                    value={this.state.eMail}
                  />
                  <Form.Text className='text-muted'>
                    *Мы никогда не будем делиться вашей электронной почтой ни с кем другим.
                  </Form.Text>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId='formHorizontalPassword'>
                <Form.Label column sm={2}>
                  <b>Пароль</b>
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type={this.state.isPasswordVisible ? 'text' : 'password'}
                    onKeyDown={this.onKeyPressHandler}
                    placeholder='Пароль'
                    onChange={this.handleChangePassword}
                    value={this.state.password}
                  />
                  {this.state.password && this.state.password.length && (
                    <div
                      style={{
                        position: 'absolute',
                        right: '25px',
                        top: '6px',
                        zIndex: '21',
                        cursor: 'pointer'
                      }}
                      onClick={this.showPassword}
                    >
                      {this.state.isPasswordVisible ? (
                        <VisibilityOffOutlinedIcon fontSize='small' color='action' />
                      ) : (
                        <RemoveRedEyeOutlinedIcon fontSize='small' color='action' />
                      )}
                    </div>
                  )}
                  <Form.Text className='text-muted'></Form.Text>
                </Col>
              </Form.Group>
            </Card.Body>

            {!error && !sessionError && (
              <div className='all_modals_footer_space_between'>
                <AllModalsFooter
                  isPosAbsoluteNedded={true}
                  footerContent={
                    <>
                      <span style={{ display: 'flex', gap: '5px' }}>
                        <RegistrationButton
                          handleSaveClick={this.handleClickRegistration}
                          needDisable={false}
                          variant='success'
                        />
                        <Button
                          variant='secondary'
                          onClick={this.handleClickErasePassword}
                          disabled={!this.state.showErase}
                          title='Для сброса пароля введите логин: E-Mail'
                        >
                          <LockResetOutlinedIcon /> Сброс пароля
                        </Button>
                      </span>

                      <LoginButton
                        handleSaveClick={this.handleClickLogIn}
                        needDisable={true}
                        disableCondition={this.state.disableLoginButton}
                      />
                      {/* <Button variant='success' type='submit' onClick={this.handleClickLogIn}>
                        Войти
                      </Button> */}
                    </>
                  }
                />
              </div>
            )}
          </div>
        </Card>

        {this.state.showLooking && (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: '0',
              left: '0',
              backgroundColor: '#343a40',
              zIndex: 100000
            }}
          ></div>
        )}
      </>
    )
  }
}

// onClick = {(e) => this.handleClickLogIn(e)}
