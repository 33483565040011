import server from '../server/server'


/**
 * Возвращает массив объектов компании {id, nameCompany, ownership}
 * @param {Array} arrINN массив ИНН
 * @returns {Array} массив объектов {id, nameCompany, ownership}
 */
const getMainCompany = async (arrINN) => {
    try {
        let result = [];
        const main = await server.searchAllNameCompany();

        if (!arrINN || arrINN.length < 1) {
            return main
        }

        result = arrINN.map(item => {
            return (
                main.find(itm => itm.id === item)
            )
        })

        return result

    } catch (err) {
        console.log(`Ошибка получения данных с сервера: `, err);
        return null
    }

}
export default getMainCompany;