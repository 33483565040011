import React from 'react';
import SizeFormBig from './SizeFormBig';
import CheckFormGostMetal from './CheckFormGostMetal';

export default class FormRolledDimensions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            showBase: true,
            showSize: [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false],
            arrayCheckId: [2, 8, 14, 20, 32, 38, 44, 50, 62, 68, 74, 80, 92, 98, 104, 110, 122, 128, 134, 140, 152, 158, 164, 170, 182, 188, 194, 200],
            base: [],
            showSquare: false,
            readOnly: false // false - поле включено / true - выключено
        }

        this.doChangeValue = this.doChangeValue.bind(this);
        this.renderDataForm = this.renderDataForm.bind(this);
        this.doEnpty = this.doEnpty.bind(this);
        this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this);
    }
    doEnpty() {
        //пустая функция
    }

    async doChangeVisionBlock(e, id) {
        // console.log(`DATA click - ${e} - ${id}`);//test

        // const position = this.state.arrayCheckId.indexOf(Number(id));
        // let showS = this.state.showSize;
        // showS[position] = e;
        // this.setState({ showSize: showS });

        // // console.log('ARRAY -> ', this.state.showSize);//test
        // this.renderDataForm(this.props.forgings, this.props.id);
    }

    doChangeValue(e) {
        this.props.onChangeValue(e);
    }

    //* рендер размеры из массива
    async renderDataForm(DATA, id) {
        this.showSize = this.state.showSize
        this.baseData = [
            <>
                <SizeFormBig
                    id={id}
                    width={2}
                    fontSize={'12px'}
                    show={true}
                    value={this.value}
                    description={`Сортовой фассонный прокат: форма ${DATA.name}`}
                    placeholder={DATA.form.dimensions}
                    onChangeValue={this.doChangeValue}
                    nameForm={this.props.nameForm}
                    // readOnly={this.state.readOnly}
                    readOnly={this.props.readOnly}
                />
            </>
        ];

        const renderCheck = DATA.normDoc.gost.map((data, index) => {
            return (
                <React.Fragment key={index + data + DATA.name} >
                    <CheckFormGostMetal
                        name={`${this.props.name}_${id}_${index}`}
                        inline={true}
                        id={+id + 6 + index}
                        width={4}
                        // marR={'10px'}
                        show={true}
                        placeholder={data}
                        description={`Сортовой прокат, форма : ${DATA.name}`}
                        value={this.value[+id + 1 + index] ? this.value[+id + 1 + index].value : ''}
                        onChangeValue={this.doChangeValue}
                        onChangeVisionBlock={this.doChangeVisionBlock}
                        nameForm={this.props.nameForm}
                        readOnly={this.props.readOnly}
                    />
                </React.Fragment>
            )

        })

        await this.baseData.push(
            <div className='display_flex container_flex_start_wrap'>
                {renderCheck}
            </div>);

        this.setState({
            base: this.baseData
        })
    }
    componentDidMount() {
        this.value = this.props.value;
        this.value.forEach((data, index) => { this.doChangeVisionBlock(data.value, index) })
        this.renderDataForm(this.props.arrayConst, this.props.id);

        // description = { "Сортовой прокат"}
        // nameForm = { this.state.nameForm }
    }

    render() {

        const showBase = this.state.showBase;

        return (
            <>
                {showBase ? this.state.base : ''}
            </>
        )
    }
}