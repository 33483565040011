import React from 'react';
import { Alert, Container, Form, Row, Col } from 'react-bootstrap';
import SizeFormBig from '../../questionnaire/SizeFormBig';
import RadioButtonForm from '../../questionnaire/RadioButtonForm';
import FormMaterList from '../../questionnaire/FormMaterList';
import { CloseBtnForFilter } from '../../assets/CloseBtnForFilter';
import Server from '../../server/server';
import { ButtonGroupForFilterForms } from '../../assets/ButtonGroupForFilterForms';

export default class FormBendPipe extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            language: 'rus',
            show: false,
            showZmey: false,
            showFason: false,
            nameForm: 'FormBendPipe',
            maxId: 18,
            dataValue: [],
            dataOnServer: [],
            dataFromServer: [],
            lengthDataFromServer: 0,
            showQuit: false,
            selectCheck: true,
            showVariants: true
        }

        this.doChangeValue = this.doChangeValue.bind(this);
        this.handleClickSave = this.handleClickSave.bind(this);
        this.handleClickShadow = this.handleClickShadow.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
        this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this);
        this.doChangeVisionBlockFason = this.doChangeVisionBlockFason.bind(this);
        this.handleClickMaterieals = this.handleClickMaterieals.bind(this);

        this.doQuitWithSave = this.doQuitWithSave.bind(this);
        this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this);
        this.clearFields = this.clearFields.bind(this);
        this.onKeyPressHandler = this.onKeyPressHandler.bind(this);
        this.sendProductDataOnServer = this.sendProductDataOnServer.bind(this)
    }
    async clearFields() {
        await this.setState({
            showVariants: false
        })

        this.setState({
            showVariants: true
        })

    }

    handleClickMaterieals() {
        this.props.onRunMaterials('Four_FormBendPipe');
    }


    //* empty
    doEmpty() {
    }

    //*
    doChangeVisionBlock(e) {
        if (e === 'Да') {
            this.setState({ showZmey: true })
        }
        if (e === 'Нет') {
            this.setState({ showZmey: false })
        }
    }

    //*
    doChangeVisionBlockFason(e) {
        if (e === 'Да') {
            this.setState({ showFason: true })
        }
        if (e === 'Нет') {
            this.setState({ showFason: false })
        }
    }

    //запуск выхода из формы
    handleClickShadow() {
        this.setState({ showQuit: true })
    }

    //выход с сохранением
    doQuitWithSave() {
        this.setState({ showQuit: false })
        this.handleClickSave();
        this.props.onChangeView(this.state.nameForm);
    }
    //выход без сохранения
    doQuitWithoutSave() {
        this.setState({ showQuit: false })
        this.props.onChangeView(this.state.nameForm);
    }

    //*формируем данные в массив объектов для отправки на сервер
    async handleClickSave(e) {
        e.preventDefault()
        let data = this.state.dataOnServer;

        for (let i = 0; i <= (this.state.maxId); i++) {

            //todo создаем позицию с пустым объектом
            data[i] = { id: '', fid: '', description: '', information: '', value: '' }

            //todo заполняем объект данными с сервера
            this.state.dataFromServer.forEach((dataD) => {
                if (+dataD.id === i) {
                    data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
                }
            })
            //todo заполняем объект данными с формы
            this.state.dataValue.forEach((dataE) => {
                if (+dataE.id === i) {
                    data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
                }
            })

        }
        await this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере

        this.props.getChosenFiltersData(data)
        //отправляем родителю имя этой формы
        // this.props.getFormName(this.state.nameForm)
        //берем данные с сервера
        this.props.saveChosenFilters()


        const result = await Server.filterProductionCompany(data, this.state.nameForm)
        await this.props.sendSearchResultData(result)
        this.props.openResultTable()
        this.props.closeModal(e)
    }

    //* данные с полей формы, формируются в массив объектов
    doChangeValue(data) {
        // console.log(data);//test
        this.setState({ dataValue: [...this.state.dataValue, data] })
        // setTimeout(() => { console.log(this.state.dataValue) })//test
    }

    onKeyPressHandler(event) {
        event.target.dataset.position = 18
        if (event.keyCode === 13) {
            this.handleClickSave(event)
        }
    }

    async sendProductDataOnServer() {
        const productData = {
            product: this.props.product,
            formName: this.state.nameForm
        }
        await Server.sendDataOnServer(productData, 'product', localStorage.getItem('idUser'))
    }

    async componentDidMount() {
        // setTimeout(() => {
        await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer });
        if (this.props.value) {
            this.doChangeVisionBlock(this.props.data[7].value);
            this.doChangeVisionBlockFason(this.props.data[15].value);
        }
        // console.log('DATA on dataFromServer > ', this.props.data);//test
        // }, 1000);

        document.addEventListener('keydown', this.onKeyPressHandler)

        await this.sendProductDataOnServer()
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyPressHandler)
    }

    render() {
        const view = this.props.view;
        this.value = this.props.data;
        const viewQuest = this.props.viewQuest;
        const placeholder = ['Диаметр трубы, мм', 'Радиус гиба, мм', 'Толщина стенки трубы, мм'];
        const placeholderZmey = ['Диаметр трубы, мм', 'Диаметр змеевика, мм', 'Толщина стенки трубы, мм'];
        const FASON = ['Уголок', 'Швелер', 'Двутавр'];

        return (
            <>

                <div className="modal_window" style={{ display: view ? 'block' : 'none' }} >
                    <div className={viewQuest ? "shadow_form" : "shadow_form_off"} onClick={this.props.closeModal}  ></div>
                    <Form className={this.props.chosenCompaniesFromProductionSearch && this.props.chosenCompaniesFromProductionSearch.length >= 0 ? "form_main" : "form_main_1"}  >
                        <Alert
                            variant="dark"
                            className="filter_alert"
                        >
                            <Alert.Heading>Поставка гнутых труб и/или змеевиков</Alert.Heading>
                            <CloseBtnForFilter dataId={18} closeModal={this.props.closeModal} />
                        </Alert>
                        <div>
                            <Container className="form_container">
                            <Form.Group>
                                <Row>
                                    <Col>
                                        Размеры гнутых труб:
                                    </Col>
                                </Row>
                            </Form.Group>
                            {this.state.showVariants &&
                                <SizeFormBig
                                    id={1}
                                    width={2}
                                    show={true}
                                    value={this.value}
                                    placeholder={placeholder}//кол-во позиций согласно длине массива
                                    description="Гнутые трубы и змеевики"
                                    fontSize={12}
                                    onChangeValue={this.doChangeValue}
                                    nameForm={this.state.nameForm}
                                />}
                            <Form.Group>

                            </Form.Group>
                            {this.state.showVariants && <> <Form.Group>
                                <RadioButtonForm
                                    id={6}
                                    width={12}
                                    show={true}
                                    title="Возможность термической обработки гиба труб: "
                                    label1="Да"
                                    label2="Нет"
                                    placeholder="возможность термической обработки гиба труб"
                                    description="Гнутые труби и змеевики"
                                    value={this.value[6] ? this.value[6].value : ''}
                                    onChangeValue={this.doChangeValue}
                                    onChangeVisionBlock={this.doEmpty}
                                    nameForm={this.state.nameForm}
                                />
                            </Form.Group>
                                <Row className='border_bottom_underline' >&nbsp;</Row>
                                <Form.Group>
                                    <RadioButtonForm
                                        id={7}
                                        width={12}
                                        show={true}
                                        title="Возможность изготовления змеевиков: "
                                        label1="Да"
                                        label2="Нет"
                                        placeholder="Изготовление змеевиков"
                                        description="Изготовление змеевиков"
                                        value={this.value[7] ? this.value[7].value : ''}
                                        onChangeValue={this.doChangeValue}
                                        onChangeVisionBlock={this.doChangeVisionBlock}
                                        nameForm={this.state.nameForm}
                                    />
                                </Form.Group></>}
                            {this.state.showZmey ?

                                <Container>
                                    {this.state.showVariants && <><Form.Group>
                                        <Row>
                                            <Col>
                                                Размеры змеевиков:
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                        <Form.Group>
                                            <SizeFormBig
                                                id={8}
                                                width={2}
                                                show={true}
                                                value={this.value}
                                                placeholder={placeholderZmey}
                                                description="Гнутые трубы и змеевики"
                                                fontSize={12}
                                                onChangeValue={this.doChangeValue}
                                                nameForm={this.state.nameForm}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <RadioButtonForm
                                                className='pl_0'
                                                id={13}
                                                width={12}
                                                show={true}
                                                title="Возможность термической обработки змеевиков?"
                                                label1="Да"
                                                label2="Нет"
                                                placeholder="возможность термической обработки змеевиков"
                                                description="Гнутые труби и змеевики"
                                                value={this.value[13] ? this.value[13].value : ''}
                                                onChangeValue={this.doChangeValue}
                                                onChangeVisionBlock={this.doEmpty}
                                                nameForm={this.state.nameForm}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <RadioButtonForm
                                                className='pl_0'
                                                id={14}
                                                width={12}
                                                show={true}
                                                title="Проверка материалов, сварных соединений, готовых изделий методами РМК и НРМК: "
                                                label1="Да"
                                                label2="Нет"
                                                placeholder="Проверка материалов, сварных соединений, готовых изделий методами РМК и НРМК"
                                                description="Проверка материалов, сварных соединений, готовых изделий методами РМК и НРМК"
                                                value={this.value[14] ? this.value[14].value : ''}
                                                onChangeValue={this.doChangeValue}
                                                onChangeVisionBlock={this.doEmpty}
                                                nameForm={this.state.nameForm}
                                            />
                                        </Form.Group>
                                    </>}
                                </Container>
                                : ''}
                            <Row className='border_bottom_underline' >&nbsp;</Row>
                            {this.state.showVariants && <><Form.Group>
                                <RadioButtonForm

                                    id={15}
                                    width={12}
                                    show={true}
                                    title="Возможность гибки фасонного проката:"
                                    label1="Да"
                                    label2="Нет"
                                    placeholder="Гибка фасонного проката"
                                    description="Гибка фасонного проката"
                                    value={this.value[15] ? this.value[15].value : ''}
                                    onChangeValue={this.doChangeValue}
                                    onChangeVisionBlock={this.doChangeVisionBlockFason}
                                    nameForm={this.state.nameForm}
                                />
                            </Form.Group>
                                {this.state.showFason ?
                                    <Container>
                                        <FormMaterList
                                            className='p_0'
                                            id={16}
                                            width={2}
                                            rowView={false}
                                            materials={FASON}
                                            value={this.value[16] ? this.value[16].value : ''}
                                            description="Фасонный прокат"
                                            onChangeValue={this.doChangeValue}
                                            nameForm={this.state.nameForm}
                                            selectCheck={this.state.selectCheck}
                                        />

                                    </Container>
                                    : ''}</>}
                            <Row> &nbsp;</Row>
                            <Row>&nbsp;</Row>

                        </Container>
                         <ButtonGroupForFilterForms
                                    dataId={18}
                                    handleClickSave={this.handleClickSave}
                                    clearFields={this.clearFields}
                                    savedFiltersData={this.props.savedFiltersData}
                                />
                        </div>
                        
                    </Form>
                </div>
            </>
        )
    }
}