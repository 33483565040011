import React from 'react';
import { Form } from 'react-bootstrap';
import InputFormFocus from '../questionnaire/InputFormFocus';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

export default class TechDataTradeAdd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showInputs: true,
            text: 'нет параметров'
        }

        this.doChangeValue = this.doChangeValue.bind(this);
        this.doFocus = this.doFocus.bind(this);
        this.doEmpty = this.doEmpty.bind(this);
    }

    doEmpty() {
        // console.log(`EMPTY >>>`); // test
    }

    doFocus() {
        if (this.state.text) {
            this.setState({ showInputs: false })
            this.setState({ text: '' })
            setTimeout(() => { this.setState({ showInputs: true }) })
        }
    }

    doChangeValue(data) {
        this.props.onChangeValue(data);
    }

    render() {
        const id = this.props.id;
        this.value = this.props.value;
        let text = this.state.text;

        if (this.props.id !== 1) text = '';

        const result = Math.ceil(id / 2) // получаем позицию

        return (


            <Form.Group >
                {this.state.showInputs &&
                    <Row>
                        <Col
                            sm={1}
                            style={{
                                marginTop: '30px',
                                marginRight: '-60px',
                            }}
                        >
                            {result}.
                        </Col>
                        <Col style={{ padding: '0px' }}>

                            <InputFormFocus
                                className='display_inline mb_0'
                                id={+id}
                                // width={12}
                                show={true}
                                fontSize={'12px'}
                                label=""
                                placeholder="Название параметра"
                                description="Технические параметры торгов"
                                value={this.value[+id] ? this.value[+id].value : text}
                                onChangeValue={this.doChangeValue}
                                onFocus={text ? this.doFocus : this.doEmpty}
                                readOnly={this.props.readOnly}
                            />
                        </Col>

                        <Col style={{ padding: '0px' }}>
                            <InputFormFocus
                                style={{ paddingLeft: '0px' }}
                                className='display_inline mb_0'
                                id={+id + 1}
                                width={12}
                                show={true}
                                fontSize={'12px'}
                                label=""
                                placeholder="Требование к параметру"
                                description="Технические параметры торгов"
                                value={this.value[+id + 1] ? this.value[+id + 1].value : text}
                                onChangeValue={this.doChangeValue}
                                onFocus={text ? this.doFocus : this.doEmpty}
                                readOnly={this.props.readOnly}
                            />

                        </Col>
                    </Row>}
            </Form.Group>
        )
    }
}