
/**
 * Возвращает массив значений TOTAL исключая значение своей компании
 * @param {Object} newTableTrade 
 * @returns - Массив объектов {inn, total}
 */
const getValueTable = (newTableTrade) => {
    const keyTable = Object.keys(newTableTrade)
    const valueTable = keyTable.map(key => {
        return (
            {
                companyInn: key,
                total: newTableTrade[key].total
            }
        )
    })

    const inn = localStorage.getItem('idUser')
    const result = valueTable.filter(item => item.companyInn !== inn)
    // console.log(`value TOTAL::: `, result); // test

    return result
}

export default getValueTable;