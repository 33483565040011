import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { CircularProgressWithLabel } from '../assets/CircularProgressWithLabel'
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import Server from '../server/server';
import { EconomicContent } from './EconomicContent';


export function EconomicData({ onChangeView, onClickView, lookingSave, data, fill, setFillingGuide, reRenderQuestBtn, changeColorButton }) {


    const [procent, setProcent] = useState(0)
    const [dataFromServer, setDataFromServer] = useState([])
    const [totalPercent, setTotalPercent] = useState(null)
    const [launchGuarantee, setLaunchGuarantee] = useState(null)
    const [afterGuarantee, setAfterGuarantee] = useState(null)
    const [showContentModal, setShowContentModal] = useState(false)

    const [prepay, setPrepay] = useState(null)
    const [beforePay, setBeforePay] = useState(null)
    const [afterPay, setAfterPay] = useState(null)

    useEffect(()=> {
        setProcent(fill)
    }, [fill])


    const setFieldsToState = (economicObj) => {

        //эти 2 переменные в строгом соответствии с индексами др друга
        const ids = ['4', '5', '6', '7', '8']
        const funcs = [setLaunchGuarantee, setAfterGuarantee, setPrepay, setBeforePay, setAfterPay]


        ids.forEach((id, ind) => {
            funcs.forEach((f, idx) => {
                if (id === economicObj.id && ind === idx) {
                    f(+economicObj.value)
                }
            })
        })
    }


    const getData = async () => {

        try {
            const res = await Server.getDataFromServer('EconomicData', localStorage.getItem('idUser'))

            if (res && res.length !== 0 && !res.ERROR) {
                await setDataFromServer(res)

                res.forEach(el => {
                    setFieldsToState(el)
                })
            }
            setTotalPercent(+res[6].value + +res[7].value + +res[8].value)
        } catch (err) {
            console.log(err);
        }

    }

    const handleModalToggle = async (e) => {
        await getData()
        setTimeout(() => {
            setShowContentModal(!showContentModal)
        }, 600);

        onClickView(e)
        
    }

    return (
        <><Card
            onClick={handleModalToggle}
            // className={'btn_form btn_card_style active'} // active
            className={`${procent > 0 ? 'btn_form btn_card_style active' : 'btn_form btn_card_style'}`}
            data-name={'EconomicData'}
        >
            <Card.Header
                // className={'btn_form_header active'} // active
                className={`${procent > 0 ? 'btn_form_header active' : 'btn_form_header'}`}
                data-name={'EconomicData'}
            >

                <div style={{ display: 'flex' }} data-name={'EconomicData'}>
                    <span data-name={'EconomicData'} style={{ marginRight: 'auto' }}><PointOfSaleOutlinedIcon style={{ color: '#fff' }} /></span>

                </div>

            </Card.Header>

            <Card.Body
                className={'btn_form_body active'} // active
                data-name={'EconomicData'}
            >

                <span
                    className='btn_form_title'
                    data-name={'EconomicData'}
                >
                    Экономическая информация для изготовителей оборудования
                </span>


                <div data-name={'EconomicData'} >
                    <span
                        className='check'
                        data-name={'EconomicData'}
                    >
                        <CircularProgressWithLabel
                            size={35}
                            value={procent}
                        />
                    </span>
                </div>
            </Card.Body>

        </Card>

            <EconomicContent
                setShowContentModal={setShowContentModal}
                showContentModal={showContentModal}
                dataFromServer={dataFromServer}
                lookingSave={lookingSave}
                setTotalPercent={setTotalPercent}
                onChangeView={onChangeView}
                totalPercent={totalPercent}
                data={data}
                setDataFromServer={setDataFromServer}
                handleModalToggle={handleModalToggle}

                prepay={prepay}
                beforePay={beforePay}
                afterPay={afterPay}

                setPrepay={setPrepay}
                setBeforePay={setBeforePay}
                setAfterPay={setAfterPay}

                launchGuarantee={launchGuarantee}
                afterGuarantee={afterGuarantee}

                setFieldsToState={setFieldsToState}
                setFillingGuide={setFillingGuide}
                reRenderQuestBtn={reRenderQuestBtn}
                // test={test}
                // setSendOnModerationModal={setSendOnModerationModal}
                changeColorButton={changeColorButton}
            />
        </>
    )
}
