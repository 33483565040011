import server from '../server/server'

/**
 * Сохранение данных победителя торгов
 * @param {Object} idLot объект лота
 * @param {String} inn ИНН
 */
const saveWinnerData = async (idLot, inn) => {
    try {
        const result = await server.saveWinnerCompany(idLot, inn)

        console.log(`Сохранение данных победителя: `, result);

    } catch (err) {
        console.log(`Ошибка при сохранении данных победителя: `, err);
    }
}

export default saveWinnerData;