import React, { useState, useEffect } from 'react'
import { Form, Spinner, Table } from 'react-bootstrap'

import { AddButton, CalcButton, SaveCurrentDataButton } from '../../assets/BasicButtons'

export default function GeneralTable({ id, doGetTableData, savedData }) {
  const [flashSaved, setFlashSaved] = useState(false)

  // console.log(savedData)

  const initialRow = {
    id,
    date: '',
    responsible: '',
    event: '',
    environmentName: '',
    environmentConsumption: '',
    inputTemperature: '',
    outputTemperature: '',
    workPressure: '',
    pressureDrop: '',
    environmentSpeedCalculation: null,
    heatTransferCalculation: null
  }

  const [tableData, setTableData] = useState(savedData?.length ? savedData : [initialRow])
  const [showLoading, setShowLoading] = useState(
    Array.from({ length: tableData?.length }, () => ({
      environmentSpeedCalculation: false,
      heatTransferCalculation: false
    }))
  )

  useEffect(() => {
    setTableData(savedData?.length ? savedData : [initialRow])
  }, [savedData])

  useEffect(() => {
    setShowLoading(
      Array.from({ length: tableData.length }, () => ({
        heatPowerCalculation: false,
        heatPressureCalculation: false,
        heatTransferCalculation: false
      }))
    )
  }, [tableData.length, tableData])

  const inputTypes = {
    date: 'date',
    responsible: 'text',
    event: 'text',
    environmentName: 'text',
    environmentConsumption: 'number',
    inputTemperature: 'number',
    outputTemperature: 'number',
    workPressure: 'number',
    pressureDrop: 'number'
  }

  const handleChange = (e, rowIndex, fieldName) => {
    const newTableData = [...tableData]
    newTableData[rowIndex][fieldName] = e.target.value
    setTableData(newTableData)
    resetCalculations(newTableData, rowIndex, fieldName)
  }

  const resetCalculations = (data, rowIndex, fieldName) => {
    if (
      (data[rowIndex][fieldName] && data[rowIndex].environmentSpeedCalculation) ||
      data[rowIndex].heatTransferCalculation
    ) {
      setTableData((prevData) =>
        prevData.map((item, i) => {
          if (i === rowIndex) {
            return {
              ...item,
              environmentSpeedCalculation: null,
              heatTransferCalculation: null
            }
          }
          return item
        })
      )
    }
  }

  const addRow = () => {
    setTableData([...tableData, initialRow])
  }

  const filterEmptyObjects = (data) => {
    const newTableData = [...data]
    const filteredData = newTableData.filter(obj => {
      return Object.entries(obj).some(([key, val]) => {
        return key !== 'id' && Boolean(val)
      })
    })
    return filteredData
  }

  const flashSaveMessage = () => {
    setFlashSaved(true)
    setTimeout(() => {
      setFlashSaved(false)
    }, 2000)
  }

  const handleClickSave = () => {
    flashSaveMessage()
    // console.log(tableData)
    const filtered = filterEmptyObjects(tableData)
    doGetTableData(filtered)
  }

  const calcResult = (rowIndex, propName) => {
    const newLoadingState = JSON.parse(JSON.stringify(showLoading))
    newLoadingState[rowIndex][propName] = true
    setShowLoading(newLoadingState)

    setTimeout(() => {
      const randomNum = Math.ceil(Math.random() * 100000)
      const newTableData = [...tableData]
      newTableData[rowIndex][propName] = randomNum
      setTableData(newTableData)

      const newLoadingState2 = JSON.parse(JSON.stringify(showLoading))
      newLoadingState2[rowIndex][propName] = false
      setShowLoading(newLoadingState2)
    }, 1000)
  }

  // console.log(tableData)

  return (
    <div className='pipe_table_wrap'>
      <Table bordered className='equipment_table_style'>
        <thead>
          <tr>
            <th className='th_table_style'>Дата заполнения</th>
            <th className='th_table_style'>Ответственный за заполнение</th>
            <th className='th_table_style'>Событие</th>
            <th className='th_table_style'>Наименование среды</th>
            <th className='th_table_style'>Расход среды, (кг/ч)</th>
            <th className='th_table_style'>Рабочая температура, С° (Вход)</th>
            <th className='th_table_style'>Рабочая температура, С° (Выход)</th>
            <th className='th_table_style'>Рабочее давление, мПа</th>
            <th className='th_table_style'>Перепад давления, кПа</th>
            <th className='th_table_style' style={{ width: '170px' }}>
              Скорость среды, м/с Расчет
            </th>
            <th className='th_table_style' style={{ width: '170px' }}>
              Коэффициент теплоотдачи, Вт/м2*С Расчет
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {Object.entries(row).map(
                ([fieldName, value]) =>
                  fieldName !== 'id' &&
                  fieldName !== 'environmentSpeedCalculation' &&
                  fieldName !== 'heatTransferCalculation' && (
                    <td key={fieldName}>
                      <Form.Control
                        style={{ cursor: inputTypes[fieldName] === 'date' ? 'pointer' : 'auto' }}
                        type={inputTypes[fieldName]}
                        value={value}
                        onChange={(e) => handleChange(e, rowIndex, fieldName)}
                      />
                    </td>
                  )
              )}
              <td style={{ width: '170px' }}>
                {showLoading[rowIndex]?.environmentSpeedCalculation ? (
                  <div className='loader_centered'>
                    <Spinner animation='border' variant='secondary' />
                  </div>
                ) : (
                  <>
                    {row.environmentSpeedCalculation ? (
                      <Form.Control value={row.environmentSpeedCalculation} readOnly />
                    ) : (
                      <CalcButton
                        handleSaveClick={() => calcResult(rowIndex, 'environmentSpeedCalculation')}
                      />
                    )}
                  </>
                )}
              </td>
              <td style={{ width: '170px' }}>
                {showLoading[rowIndex]?.heatTransferCalculation ? (
                  <div className='loader_centered'>
                    <Spinner animation='border' variant='secondary' />
                  </div>
                ) : (
                  <>
                    {row.heatTransferCalculation ? (
                      <Form.Control value={row.heatTransferCalculation} readOnly />
                    ) : (
                      <CalcButton
                        handleSaveClick={() => calcResult(rowIndex, 'heatTransferCalculation')}
                      />
                    )}
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
        <div className='button_group_table'>
          <AddButton
            handleSaveClick={addRow}
            needDisable={false}
            // disableCondition={isTableDataEmpty()}
          />
          <SaveCurrentDataButton
            handleSaveClick={handleClickSave}
            needDisable={false}
            // disableCondition={}
            savedDataMessage={flashSaved}
            buttonTextDefault={'Сохранить'}
            buttonTextSaved={'Данные соранены'}
            needCustomIcon={false}
            customIcon={''}
            needCustomStyle={false}
            customStyle={''}
          />
        </div>
      </Table>
    </div>
  )
}
