import React from 'react';
import { Row } from 'react-bootstrap';
import InputForm from './InputForm';

export default class SizeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }

        this.doChangeValue = this.doChangeValue.bind(this);
    }

    doChangeValue(e) {
        this.props.onChangeValue(e);
    }

    render() {


        const show = this.props.show;
        const placeholder = this.props.placeholder;
        const width = this.props.width;
        this.value = this.props.value;
        const id = this.props.id;
        const label = this.props.placeholder;
        const fontSize = this.props.fontSize;


        return (
            <>
                <Row >
                    <InputForm
                        id={+id}
                        width={width}
                        // show={show}
                        show={show}
                        verify="number"
                        label={label[0]}
                        fontSize={fontSize}
                        placeholder={placeholder[0]}
                        description={this.props.description}
                        value={this.value[+id] ? this.value[+id].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.props.nameForm}
                        readOnly={this.props.readOnly}
                    />
                    <InputForm
                        id={+id + 1}
                        width={width}
                        show={show}
                        verify="number"
                        label={label[1]}
                        fontSize={fontSize}
                        placeholder={placeholder[1]}
                        description={this.props.description}
                        value={this.value[+id + 1] ? this.value[+id + 1].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.props.nameForm}
                        readOnly={this.props.readOnly}
                    />
                    <InputForm
                        id={+id + 2}
                        width={width}
                        show={label[2] ? true : false}
                        verify="number"
                        label={label[2]}
                        fontSize={fontSize}
                        placeholder={placeholder[2]}
                        description={this.props.description}
                        value={this.value[+id + 2] ? this.value[+id + 2].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.props.nameForm}
                        readOnly={this.props.readOnly}
                    />
                    <InputForm
                        id={+id + 3}
                        width={width}
                        show={label[3] ? true : false}
                        verify="number"
                        label={label[3]}
                        fontSize={fontSize}
                        placeholder={placeholder[3]}
                        description={this.props.description}
                        value={this.value[+id + 3] ? this.value[+id + 3].value : ''}
                        onChangeValue={this.doChangeValue}
                        nameForm={this.props.nameForm}
                        readOnly={this.props.readOnly}
                    />

                </Row>
            </>
        )
    }
}