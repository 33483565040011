import Server from '../server/server'

/**
 * Создает форму для файла ICON , отправка на сервер
 * @param {Object} file полученный объект файла
 * @param {string} newName новое имя файла
 * @returns 
 */
export async function appendDataForm(file, newName) {

    let newDataForm = new FormData();
    newDataForm.append("key", "weifeiph7Pie"); //todo kye - constanta
    newDataForm.append("filename", newName); //todo создать уникальный
    newDataForm.append("session", sessionStorage.getItem('sessionId'));
    newDataForm.append("login", sessionStorage.getItem('login'));
    newDataForm.append("file", file, file.name); //в тело объекта новой формы добавили INPUT

    const result = await Server.createIconOnServer(newDataForm); // сохраняем файл на сервере
    return result;
}