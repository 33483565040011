import React, { useEffect } from 'react'
import ExitToAppSharpIcon from '@mui/icons-material/ExitToAppSharp'
import { Alert, Button, Modal } from 'react-bootstrap'
import { LeaveIcon } from './CommonIcons'

//***INCOMING elements:***
//onQuitWithSave - выход с сохранением (сохраняет, закрывает все модалки)
//onQuitWithoutSave - выход без сохранения (не сохраняет, закрывает все модалки)
//closeQuitModal - закрывает только модалку выхода
//modalTitle - заголовок модалки выхода, например "Выход"
//modalBody - основной текст, например "Вы действительно хотите выйти из формы"
//primaryButton - гдавная синяя кнопка слева, например, "Сохранить"
//secondaryButton - второстепенная кнопка справа, например "Отменить"
//icon - иконка в заголовке
// lot - данные по ЛОТу
//inputF - (bool) если нужены инпуты в теле модалки (изначально создано под кнопки "несогласие с условием" и "Отказ")

export default function QuitModalUniversal({
  onQuitWithSave,
  onQuitWithoutSave,
  closeQuitModal,
  darkVariant,
  icon,
  modalBody,
  primaryButton,
  // secondaryButton,
  modalTitle
}) {
  useEffect(() => {
    const onKeyPressHandler = event => {
      if (event.keyCode === 13) {
        handleClickQuitWithSave()
      }
    }
    document.addEventListener('keydown', onKeyPressHandler)
    return () => {
      document.removeEventListener('keydown', onKeyPressHandler)
    }
  }, [])

  // console.log('Component --> QuiteModalUniversal.jsx'); // test

  const handleClickQuitWithSave = () => {
    onQuitWithSave()
  }

  // const handleClickQuitWithoutSave = () => {
  //   onQuitWithoutSave()
  // }

  return (
    <>
      <div className='modal_window'>
        <div className='shadow z_index_extra_big' style={{ zIndex: '20002111' }} onClick={closeQuitModal}></div>

        <Modal.Dialog className='modal_common z_index_extra_big width_500' style={{ zIndex: '20002111' }}>
          <Alert
            variant={darkVariant}
            style={{ borderBottom: '1px #dee2e6 solid', textAlign: 'start' }}
            dismissible
            onClose={closeQuitModal}
          >
            <Modal.Title>
              {icon ? icon : <ExitToAppSharpIcon fontSize='large' className='icon_leave_modal' />} {modalTitle}
            </Modal.Title>
          </Alert>

          <Modal.Body style={{ textAlign: 'start' }}>
            <p>{modalBody}</p>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant={'primary'}
              onClick={() => {
                handleClickQuitWithSave()
              }}
            >
              <LeaveIcon />
              {primaryButton} 
            </Button>

            {/* <Button variant='secondary' onClick={handleClickQuitWithoutSave}> */}
              {/* <LeaveIcon /> */}
              {/* <ArrowBackOutlinedIcon style={{ marginRight: '5px' }}/> */}
              {/* {secondaryButton} */}
            {/* </Button> */}
          </Modal.Footer>
        </Modal.Dialog>
      </div>
    </>
  )
}
