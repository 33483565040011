import React from 'react'
import { Accordion, Card } from 'react-bootstrap'
import SvgAngleLeft from '../../icon/Svg_Angle_left'
import SvgAngleDown from '../../icon/Svg_Angle_down'

export default class PositionChaild extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      viewData: [],
      show1: false,
      url: '',
      showPosChild: true
    }

    this.renderMenu = this.renderMenu.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.openSectionMenue = this.openSectionMenue.bind(this)
  }

  //нажатие на заголовки меню
async  openSectionMenue(elem) {
    await this.props.onAccordionOpen(elem) //ищем какую именно секцию развернуть
      this.setState({ show1: !this.state.show1 }) //меняем положение стрелочки рядом с заголовком меню
    //   this.renderMenu(this.props.nameMenu, this.props.urlMenu)
    // console.log(this.props.eventKey)
  }

  async componentDidMount() {
    this.renderMenu(this.props.nameMenu, this.props.urlMenu)
  }

  //нажатие на подзаголовоки меню
  async handleClick(e) {
    e.preventDefault()
    // if (this.state.show1) {
    if (typeof e === 'object') {
      e.preventDefault()

      await this.setState({
        url: e.target.dataset.url
      })

      //! Защита от входа в КОУЧ
      if (sessionStorage.getItem('login') === '123@mail.ru' && e.target.dataset.url === '/coach') {
        this.props.onTransferUrl(e.target.dataset.url)
      } else if (e.target.dataset.url !== '/coach') {
        this.props.onTransferUrl(e.target.dataset.url)
      } else {
        this.props.onTransferUrl('/questionnaire')
      }
    } else {
      this.props.onTransferUrl(e)
    }
    this.renderMenu(this.props.nameMenu, this.props.urlMenu)
  }

  //отрисовка подзаголовков меню
  renderMenu(data, url) {
    let viewMenu = []
    data.forEach((el, idx) => {
      const res = (
        <div key={url[idx]} className='chaild-block'>
          <a
            href='/'
            data-url={url[idx]}
            className={
              this.state.url === url[idx] ? 'chaild-link chaild_link_active' : 'chaild-link'
            }
            onClick={this.handleClick}
          >
            {/* <SvgPointIcon colorSvg={'#ccc'} url={url[idx]} /> */}
            {el}
          </a>
        </div>
      )
      viewMenu.push(res)
    })
    this.setState({ viewData: viewMenu })
  }

  async componentDidMount() {
    this.renderMenu(this.props.nameMenu, this.props.urlMenu)
  }

  render() {
    const show1 = this.state.show1
    const { show, name, icon, eventKey } = this.props
    return (
      <>
        <Accordion
          // defaultActiveKey="1"
          className='transition_05s'
          style={{ width: show ? '0px' : '240px' }}
        >
          <Card className='transition_05s' style={{ width: show ? '40px' : '240px' }}>
            {/* <Card.Header style={{ padding: '0px', width: '240px' }}> */}
            <Accordion.Toggle
              // variant="link"
              eventKey={'0'}
              className='card-my'
              data-position={this.props.numberOfSection}
              onClick={() => this.openSectionMenue(this.props.numberOfSection)}
            >
              {icon}
              {name}
              {
                this.props.isSectionOpen &&
                show1 ? <SvgAngleDown colorSvg='#ccc' /> : <SvgAngleLeft colorSvg='#ccc' />
              }
            </Accordion.Toggle>
            {/* </Card.Header> */}

            <Accordion.Collapse eventKey={'0'}>
              <>{this.props.isSectionOpen && <Card.Body>{this.state.viewData}</Card.Body>}</>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    )
  }
}
