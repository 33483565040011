import React from 'react'
import { Alert, Container, Form, Row, Col } from 'react-bootstrap'
import ModalQuit from '../ModalQuit'
import SizeFormBig from './SizeFormBig'
import TextareaForm from './TextareaForm'
import InputForm from './InputForm'
import RadioButtonForm from './RadioButtonForm'
import AllModalsFooter from '../assets/AllModalsFooter'
import { MaterialsButton, SaveCurrentDataButton } from '../assets/BasicButtons'
import { createData } from './functionsForm'
import { isEqual } from 'lodash-es'

export default class FormRolling extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      language: 'rus',
      show: true,
      nameForm: 'FormRolling',
      maxId: 15,
      dataValue: [],
      dataOnServer: [],
      dataFromServer: [],
      lengthDataFromServer: 0,
      showContent: true,
      previousState: [],
      savedDataMessage: false,
      dataBeenSaved: false
    }

    this.doChangeValue = this.doChangeValue.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
    this.handleClickShadow = this.handleClickShadow.bind(this)
    this.doEmpty = this.doEmpty.bind(this)
    this.doQuitWithSave = this.doQuitWithSave.bind(this)
    this.doQuitWithoutSave = this.doQuitWithoutSave.bind(this)
    // this.doChangeVisionBlock = this.doChangeVisionBlock.bind(this);
    this.handleClickMaterieals = this.handleClickMaterieals.bind(this)
    this.closeQuitModal = this.closeQuitModal.bind(this)
    this.compare = this.compare.bind(this)
    this.smartWindowFunction = this.smartWindowFunction.bind(this)
    this.saveDataWithNotification = this.saveDataWithNotification.bind(this)
  }

  saveDataWithNotification() {
    this.handleClickSave()

    this.setState({
      savedDataMessage: true,
      dataBeenSaved: true
    })
    setTimeout(() => {
      this.setState({
        savedDataMessage: false
      })
    }, 2000)
  }

  closeQuitModal() {
    this.setState({
      showQuit: false
    })
  }

  handleClickMaterieals() {
    this.props.onRunMaterials('Four_FormRolling')
  }

  //* empty
  doEmpty() {}

  //запуск выхода из формы
  handleClickShadow() {
    this.setState({ showQuit: true })
  }

  //выход с сохранением
  doQuitWithSave() {
    this.setState({ showQuit: false })
    this.handleClickSave()
    this.props.onChangeView(this.state.nameForm)
  }
  //выход без сохранения
  doQuitWithoutSave() {
    this.setState({ showQuit: false })
    this.props.onChangeView(this.state.nameForm)
  }

  //*формируем данные в массив объектов для отправки на сервер
  handleClickSave() {
    let data = this.state.dataOnServer

    for (let i = 0; i <= this.state.maxId; i++) {
      //todo создаем позицию с пустым объектом
      data[i] = { id: '', fid: '', description: '', information: '', value: '' }

      //todo заполняем объект данными с сервера
      this.state.dataFromServer.forEach((dataD) => {
        if (+dataD.id === i) {
          data[i] = { id: dataD.id, fid: dataD.fid, description: dataD.description, information: dataD.information, value: dataD.value }
        }
      })
      //todo заполняем объект данными с формы
      this.state.dataValue.forEach((dataE) => {
        if (+dataE.id === i) {
          data[i] = { id: dataE.id, fid: dataE.fid, description: dataE.description, information: dataE.information, value: dataE.value }
        }
      })
    }
    this.setState({ dataOnServer: data }) //todo проверка на наличие данных на сервере
    if (this.state.lengthDataFromServer < 1) {
      console.log('CREATE DATA', data)
      this.props.onCreateDataServer(data, this.state.nameForm, localStorage.getItem('idUser'))
    } else {
      console.log('UPDATE DATA', data)
      this.props.onUpdateDataOnServer(data, this.state.nameForm, localStorage.getItem('idUser'))
    }
    setTimeout(() => {
      console.log(this.state.dataOnServer)
    }) //test
    // this.handleClickShadow();
  }

  //* данные с полей формы, формируются в массив объектов
  async doChangeValue(data) {
    await this.setState({ dataValue: [...this.state.dataValue, data] })
    this.setState({
      dataBeenSaved: false
    })
  }

  compare() {
    const prev = this.state.previousState
    const curr = createData(this.state.dataOnServer, this.state.maxId, this.state.dataFromServer, this.state.dataValue)

    const isStateEqual = isEqual(prev, curr)
    if (isStateEqual) {
      return true
    }
    return false
  }

  smartWindowFunction() {
    const isStateEqual = this.compare()
    const { dataBeenSaved, dataValue } = this.state
    if (isStateEqual || dataBeenSaved || dataValue.length === 0) {
      this.props.onChangeView(this.state.nameForm)
    } else {
      this.handleClickShadow()
    }
  }

  async componentDidMount() {
    await this.setState({ dataFromServer: this.props.data, lengthDataFromServer: this.props.lengthDataFromServer })
    if (this.props.data.length) {
      const previousState = JSON.parse(JSON.stringify(this.props.data))
      this.setState({ previousState })
    }
  }

  render() {
    const view = this.props.view
    this.value = this.props.data
    const viewQuest = this.props.viewQuest
    const VALZOVKA = ['Макс толщ листа, мм', 'Мин толщ листа, мм', 'Мин диаметр обечайки, мм', 'Макс диаметр обечайки, мм', 'Макс ширина заготовки, мм']

    //условие по которому компонент определяет находится ли он в формате чтения
    const inReadMode = this.props.innFromUniversalTable === null || this.props.innFromUniversalTable === '' || this.props.innFromUniversalTable === undefined

    // console.log(this.state.dataValue);

    return (
      <>
        {inReadMode && <>{this.state.showQuit ? <ModalQuit onQuitWithSave={this.doQuitWithSave} onQuitWithoutSave={this.doQuitWithoutSave} closeQuitModal={this.closeQuitModal} /> : ''}</>}

        {this.state.showContent && (
          <div className='modal_window' style={{ display: view ? 'block' : 'none' }}>
            <div
              className={viewQuest ? 'shadow_form' : 'shadow_form_off'}
              // onClick={this.handleClickShadow}
              onClick={this.smartWindowFunction}
            ></div>
            <Form className={viewQuest ? 'form_main' : 'form_main_view'}>
              <Alert variant='dark' className='form_alert' onClick={this.handleClickHeader} onClose={inReadMode ? this.smartWindowFunction : () => this.setState({ showContent: false })} dismissible>
                <Alert.Heading>Услуги по вальцовке</Alert.Heading>
              </Alert>
              <div>
                <Container className='form_container'>
                  <Form.Group>
                    <Row>
                      <Col>Размеры углеродистой стали:</Col>
                    </Row>
                    <Form.Group>
                      <Row>
                        <Col>
                          <SizeFormBig
                            id={1}
                            width={2}
                            show={this.state.show}
                            verify='number'
                            // label="Толщина, мм"
                            fontSize={'12px'}
                            placeholder={VALZOVKA}
                            description='Вальцовка'
                            value={this.value}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                          />
                        </Col>
                      </Row>
                    </Form.Group>

                    <Form.Group>
                      <Row>
                        <Col>Размеры нержавеющей стали:</Col>
                      </Row>

                      <Row>
                        <Col>
                          <SizeFormBig
                            id={6}
                            width={2}
                            show={this.state.show}
                            verify='number'
                            // label="Толщина, мм"
                            fontSize={'12px'}
                            placeholder={VALZOVKA}
                            description='Вальцовка'
                            value={this.value}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <RadioButtonForm
                          id={11}
                          width={12}
                          show={true}
                          title='Возможность изготовления конусных обечеек: '
                          label1='Да'
                          label2='Нет'
                          placeholder='Имеется ли возможность изготовления конусных обечеек?'
                          description='Вальцовка'
                          value={this.value[11] ? this.value[11].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doEmpty}
                          nameForm={this.state.nameForm}
                        />
                      </Row>
                    </Form.Group>

                    <Form.Group>
                      <Row>
                        <RadioButtonForm
                          id={12}
                          width={12}
                          show={true}
                          title='Возмжность термической обработки после вальцовки и сварки: '
                          label1='Да'
                          label2='Нет'
                          placeholder='Термическая обработка после вальцовки и сварки?'
                          description='Вальцовка'
                          value={this.value[12] ? this.value[12].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doEmpty}
                          nameForm={this.state.nameForm}
                        />
                      </Row>
                    </Form.Group>

                    <Form.Group>
                      <Row>
                        <RadioButtonForm
                          id={13}
                          width={12}
                          show={true}
                          title='Возможность механических испытаний материалов сварного шва после термической обработки: '
                          label1='Да'
                          label2='Нет'
                          placeholder='Механические испытания материалов сварного шва после термической обработки?'
                          description='Вальцовка'
                          value={this.value[13] ? this.value[13].value : ''}
                          onChangeValue={this.doChangeValue}
                          onChangeVisionBlock={this.doEmpty}
                          nameForm={this.state.nameForm}
                        />
                      </Row>
                    </Form.Group>
                    <Form.Group>
                      {inReadMode && (
                        <>
                          <Row> &nbsp;</Row>
                          <Row> &nbsp;</Row>
                          <InputForm
                            id={14}
                            width={12}
                            show={true}
                            label={''}
                            fontSize={12}
                            placeholder='Комментарий:'
                            description={this.props.description}
                            value={this.value[14] ? this.value[14].value : ''}
                            onChangeValue={this.doChangeValue}
                            nameForm={this.state.nameForm}
                          />

                          <TextareaForm
                            id={15}
                            width={12}
                            label=''
                            description='Дополнения к данному разделу'
                            placeholder='Дополнительная информация по данному виду деятельности (оборудование, технологии, автоматизация и др.'
                            value={this.value[15] ? this.value[15].value : ''}
                            show={true}
                            rows={3}
                            onChangeValue={this.doChangeValue}
                          />
                        </>
                      )}
                      <Row> &nbsp;</Row>
                    </Form.Group>
                  </Form.Group>
                </Container>
                <AllModalsFooter
                  footerContent={
                    <>
                      {/* <Button variant="primary" onClick={this.handleClickMaterieals} >Используемые материалы</Button> */}
                      <MaterialsButton handleSaveClick={this.handleClickMaterieals} needDisable={false} />
                      {
                        inReadMode && (
                          //   <SaveButtonGreen
                          //   handleSaveClick={this.handleClickSave}
                          //   needDisable={false}
                          //   saveButtonText='Сохранить'
                          // />

                          <SaveCurrentDataButton
                            handleSaveClick={this.saveDataWithNotification}
                            needDisable={false}
                            savedDataMessage={this.state.savedDataMessage}
                            buttonTextDefault={'Сохранить'}
                            buttonTextSaved={'Данные сохранены'}
                            needCustomIcon={false}
                          />
                        )

                        // <Button variant="success" onClick={this.handleClickSave} >Сохранить</Button>
                      }
                    </>
                  }
                />
              </div>
            </Form>
          </div>
        )}
      </>
    )
  }
}
