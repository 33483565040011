import React, { useEffect, useState } from "react";
// import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import { Table } from "react-bootstrap";
import FileModal from "../file/FileModal";
import QuestionToChatBtn from "./QuestionToChatBtn";


const AddDocuments = ({ listForFiles, addList, idLot, sendObj, valueFileList, runChat, organizerName, showDelIcon, showCheckIcon, showAddBtn, asCustomer, name, participant }) => {

    const [value, setValue] = useState()
    const [data, setData] = useState([])
    const [show, setShow] = useState(true)

    let df = []

    const numberOfLot = idLot ? `лот № ${idLot};` : ''
    const nameOfPosition = name ? `название позиции - "${name}";` : ''

    useEffect(() => {
        setValue(listForFiles)
        // console.log(`CHANGE value :`, listForFiles); // test
    }, [listForFiles])

    useEffect(() => {
        setShow(false)
        setData(valueFileList)
        // console.log(`valueFileList:::: `, valueFileList); // test
        setTimeout(() => { setShow(true) }, 1500)
    }, [valueFileList])

    const handleChange = (event) => {
        // console.log(`VALUE::::: `, value); // test
        const result = value.map(item => {

            if (item.id === event.target.dataset.id) {
                return (
                    {
                        id: item.id, description: event.target.value
                    }
                )
            } else {
                return (
                    { id: item.id, description: item.description }
                )
            }

        })

        setValue(result)
        addList(result)
    }

    const doSaveFile = async (dataD, index) => {

        if (data) {
            let d = data;
            d[index] = dataD;
            setData(d)
            sendObj(d)
        } else {
            df[index] = dataD;
            sendObj(df)
        }
    }

    const deletePosition = (e) => {
        setShow(false)
        let d = data;
        if (d[e]) {
            delete d[e]
        }
        setData(d)
        // console.log(`DATA DELETE :::: `, d); // test
        sendObj(d)
        setTimeout(() => { setShow(true) }, 250)
    }

    const handleClickChat = (text, recipient) => {
        // console.log(`TEXT CHAT::: `, text);
        runChat(text, recipient);
    }

    const doEmpty = (e) => {
        // console.log(`doEmpty - Run >>>> `, e);
    }

    return (
        <>
            {show &&
                <Table bordered hover style={{ backgroundColor: '#fff' }}>
                    <thead>
                        <tr style={{ textAlign: 'center' }}>
                            <td style={{ width: '50px' }}>п/п</td>
                            <td>Наименование документа</td>
                            <td> Загрузка файлов </td>
                        </tr>
                    </thead>
                    <tbody>
                        {value && value.map((item, index) => {

                            if (item.archive) return <React.Fragment key={index}></React.Fragment>

                            return <React.Fragment key={index}>
                                <tr>
                                    <td>{index + 1}</td>
                                    <td data-id={item.id}
                                        data-description={item.description}
                                        onChange={handleChange}
                                    >
                                        {item.description}
                                    </td>
                                    <td>

                                        <FileModal
                                            index={index}
                                            oldNameFile={(data && data[index]) && data[index].oldNameFile}
                                            newName={idLot}
                                            newNameFile={(data && data[index]) && data[index].newNameFile}
                                            copyNameFile={(data && data[index]) && data[index].copyNameFile}
                                            pathFile={(data && data[index]) && data[index].pathFile}
                                            typeFile={(data && data[index]) && data[index].typeFile}
                                            size={(data && data[index]) && data[index].size}
                                            descriptionFile={(data && data[index]) && data[index].descriptionFile}
                                            onSaveFile={doSaveFile}
                                            deletePosition={deletePosition}
                                            // toNextTab={this.props.toNextTab}
                                            getDisableDownload={doEmpty}
                                            table={true} // при TRUE меняет верстку
                                            showDelIcon={showDelIcon}
                                            showCheckIcon={showCheckIcon}
                                            showAddBtn={showAddBtn}
                                            asCustomer={asCustomer}
                                            participant={participant}
                                        />
                                    </td>
                                    <td >
                                        <div
                                            style={{ display: 'flex', height: '100%', justifyContent: 'center' }}
                                        >
                                            <QuestionToChatBtn handleClick={handleClickChat}
                                                details={{
                                                    param: `
                                                    ${numberOfLot}
                                                    ${nameOfPosition}
                                                    пункт № ${index + 1}; 
                                                    к документу ${item.description ? `"${item.description}"` : `${data && data[index] && data[index].oldNameFile}`}`,

                                                    recipient: organizerName
                                                }}
                                            />
                                        </div>

                                    </td>
                                </tr>
                            </React.Fragment>
                        })}
                    </tbody>
                </Table>

            }

        </>
    )
}

export default AddDocuments;