import React from 'react'
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import SmsOutlinedIcon from "@mui/icons-material/SmsOutlined";
import { Button } from 'react-bootstrap'


//details - это объект: {param: 'текст сообщения', recipient: 'кому адресовано сообщение'}

export default function QuestionToChatBtn({ handleClick }) {

  // console.log('details.param', details.param);
  // console.log('details.recipient', details.recipient);

  return (
    <Button
      variant='info'
      className='chat_question_flex'
      onClick={handleClick}

    >
      <SmsOutlinedIcon style={{ color: '#fff' }} fontSize="small" />
    </Button>
  )
}
